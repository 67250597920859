@charset "UTF-8";
/*------------------------------------
  Default Styles
------------------------------------*/
html {
  font-size: 16px;
}

body {
  font-weight: 400;
  font-size: 1rem;
  font-family: "Source Sans Pro", sans-serif;
  line-height: 1.6;
  color: #999;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -moz-font-feature-settings: "liga", "kern";
  text-rendering: optimizelegibility;
  background-color: #fff;
}

a {
  color: #226f1c;
  outline: none;
}

a:focus,
a:hover {
  color: #1c5b17;
}

.nav-link {
  color: #999;
}

.nav-link:focus,
.nav-link:hover {
  color: #999;
}

figure {
  margin-bottom: 0;
}

/*------------------------------------
  Headings
------------------------------------*/
.h1, .h2, .h3, .h4, .h5, .h6, .h7,
h1, h2, h3, h4, h5, h6 {
  line-height: 1.4;
}

.h7 {
  font-size: .75rem;
}

/*------------------------------------
  Displays
------------------------------------*/
.display-5 {
  font-size: 3rem;
  font-weight: 300;
  line-height: 1.1;
}

/*------------------------------------
  Highlight Color
------------------------------------*/
::-moz-selection {
  color: #fff;
  background-color: #226f1c;
}

::selection {
  color: #fff;
  background-color: #226f1c;
}

.g-bg-primary ::-moz-selection {
  color: #226f1c;
  background-color: #fff;
}

.g-bg-primary ::selection {
  color: #226f1c;
  background-color: #fff;
}

/*------------------------------------
  Components
------------------------------------*/
/*------------------------------------
  Alerts
------------------------------------*/
/* Alert Close Button */
.u-alert-close--light {
  font-weight: 300;
  color: #000;
  opacity: .7;
  text-shadow: none;
  transition: color .3s;
  font-size: 18px;
  cursor: pointer;
}

.u-alert-close--light:hover {
  opacity: 1;
}

.u-alert-close--light:focus, .u-alert-close--light:active:focus {
  outline: 0 none;
  box-shadow: none;
}

/* Alert Lists */
.u-alert-list {
  margin-left: -20px;
}

/* Alert Lists with Font Awesome Icons */
.alert__icon {
  margin-left: 20px;
}

.alert__icon-list {
  font-size: 12px;
  line-height: 1.5;
}

/*------------------------------------
  Arrows
------------------------------------*/
.u-arrow-v1::before {
  display: inline-block;
  position: relative;
  top: 50%;
  left: 50%;
  vertical-align: top;
  -ms-transform: translateX(-50%) translateY(-50%);
      transform: translateX(-50%) translateY(-50%);
}

.u-arrow-v1[class*="abs"]::before {
  position: absolute;
}

.u-arrow-custom-v1 .u-arrow-icon {
  display: block;
}

.u-arrow-custom-v1 .u-arrow-text {
  position: absolute;
  top: 50%;
  transition: transform .3s;
}

.u-arrow-custom-v1.js-prev .u-arrow-text {
  -ms-transform: translateY(-50%) translateX(-100%);
      transform: translateY(-50%) translateX(-100%);
}

.u-arrow-custom-v1.js-next .u-arrow-text {
  -ms-transform: translateY(-50%) translateX(100%);
      transform: translateY(-50%) translateX(100%);
}

.u-arrow-custom-v1:hover .u-arrow-text {
  -ms-transform: translateY(-50%) translateX(0);
      transform: translateY(-50%) translateX(0);
}

.u-arrow-custom-v2 {
  overflow: hidden;
  transition: background-color 0.3s;
}

.u-arrow-custom-v2 .u-arrow-icon {
  transition: transform 0.3s;
}

.u-arrow-custom-v2 .u-arrow-img {
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.3s, transform 0.3s;
}

.u-arrow-custom-v2.js-prev .u-arrow-icon {
  -ms-transform: translateY(-50%) translateX(10px);
      transform: translateY(-50%) translateX(10px);
}

.u-arrow-custom-v2.js-prev .u-arrow-img {
  -ms-transform: translateX(100%);
      transform: translateX(100%);
}

.u-arrow-custom-v2.js-next .u-arrow-icon {
  -ms-transform: translateY(-50%) translateX(-10px);
      transform: translateY(-50%) translateX(-10px);
}

.u-arrow-custom-v2.js-next .u-arrow-img {
  -ms-transform: translateX(-100%);
      transform: translateX(-100%);
}

.u-arrow-custom-v2:hover .u-arrow-icon {
  -ms-transform: translateY(-50%) translateX(0);
      transform: translateY(-50%) translateX(0);
}

.u-arrow-custom-v2:hover .u-arrow-img {
  opacity: .6;
  -ms-transform: translateX(0);
      transform: translateX(0);
}

.u-arrow-custom-v3 .u-arrow-helper {
  content: "";
  opacity: 0;
  transition: transform 0.3s, opacity 0.3s;
  -ms-transform: scale(0.9);
      transform: scale(0.9);
}

.u-arrow-custom-v3 .u-arrow-icon {
  display: block;
}

.u-arrow-custom-v3 .u-arrow-icon-before, .u-arrow-custom-v3 .u-arrow-icon-after {
  position: absolute;
  left: 25%;
  transition: transform 0.3s, background-color 0.3s;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

.u-arrow-custom-v3 .u-arrow-icon-before {
  -ms-transform: translateX(-50%) rotate(30deg);
      transform: translateX(-50%) rotate(30deg);
  -ms-transform-origin: 0 100%;
      transform-origin: 0 100%;
}

.u-arrow-custom-v3 .u-arrow-icon-after {
  top: 50%;
  -ms-transform: translateX(-50%) rotate(-30deg);
      transform: translateX(-50%) rotate(-30deg);
  -ms-transform-origin: 0 0;
      transform-origin: 0 0;
}

.u-arrow-custom-v3.js-next .u-arrow-icon {
  -ms-transform: rotate(180deg);
      transform: rotate(180deg);
}

.u-arrow-custom-v3:hover .u-arrow-helper {
  background-color: #fff;
  opacity: 1;
  -ms-transform: scale(1);
      transform: scale(1);
}

.u-arrow-custom-v3:hover .u-arrow-icon-before, .u-arrow-custom-v3:hover .u-arrow-icon-after {
  background-color: #226f1c;
}

.u-arrow-custom-v3:hover .u-arrow-icon-before {
  -ms-transform: translateX(-50%) rotate(45deg);
      transform: translateX(-50%) rotate(45deg);
}

.u-arrow-custom-v3:hover .u-arrow-icon-after {
  -ms-transform: translateX(-50%) rotate(-45deg);
      transform: translateX(-50%) rotate(-45deg);
}

.u-arrow-custom-v4 {
  width: 62px;
  height: 62px;
  transition: width 0.3s, background-color 0.3s;
}

.u-arrow-custom-v4 h4 {
  opacity: 0;
  transition: opacity 0.3s, transform 0.3s;
}

.u-arrow-custom-v4.js-prev h4 {
  -ms-transform: translateX(100%);
      transform: translateX(100%);
}

.u-arrow-custom-v4.js-next h4 {
  -ms-transform: translateX(-100%);
      transform: translateX(-100%);
}

.u-arrow-custom-v4:hover {
  width: 200px;
}

.u-arrow-custom-v4:hover .u-arrow-icon {
  color: #226f1c;
}

.u-arrow-custom-v4:hover h4 {
  opacity: 1;
  transition-delay: 0.1s;
  -ms-transform: translateX(0);
      transform: translateX(0);
}

.u-arrow-custom-v5 .u-arrow-icon::before {
  transition: transform .3s .3s;
}

.u-arrow-custom-v5 .u-arrow-text {
  transition: transform .3s .3s;
  perspective: 1000px;
}

.u-arrow-custom-v5 .u-arrow-title {
  position: absolute;
  top: 100%;
  width: 100%;
  height: 30%;
  transition: transform 0.3s;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -ms-transform-origin: 50% 0;
      transform-origin: 50% 0;
  transform: rotateX(-90deg);
  margin: 0;
}

.u-arrow-custom-v5 .u-arrow-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.u-arrow-custom-v5.js-prev .u-arrow-text {
  -ms-transform: translateX(-100%);
      transform: translateX(-100%);
}

.u-arrow-custom-v5.js-next .u-arrow-text {
  -ms-transform: translateX(100%);
      transform: translateX(100%);
}

.u-arrow-custom-v5:hover .u-arrow-text {
  -ms-transform: translateX(0);
      transform: translateX(0);
}

.u-arrow-custom-v5:hover .u-arrow-title {
  transition-delay: .6s;
  transform: rotateX(0deg);
}

.u-arrow-custom-v5:hover .u-arrow-icon::before {
  transition-delay: 0s;
}

.u-arrow-custom-v5:hover.js-prev .u-arrow-icon::before {
  -ms-transform: translateX(-100%);
      transform: translateX(-100%);
}

.u-arrow-custom-v5:hover.js-next .u-arrow-icon::before {
  -ms-transform: translateX(100%);
      transform: translateX(100%);
}

.u-arrow-custom-v6 {
  overflow: hidden;
  width: 40px;
  transition: width .4s, background-color .4s, z-index .4s;
  transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
}

.u-arrow-custom-v6 .u-arrow-text {
  width: 400px;
}

.u-arrow-custom-v6:hover {
  width: 400px;
  z-index: 11;
}

.u-arrow-custom-v7 {
  perspective: 1000px;
}

.u-arrow-custom-v7 .u-arrow-icon::before {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translateX(-50%) translateY(-50%);
      transform: translateX(-50%) translateY(-50%);
}

.u-arrow-custom-v7 .u-arrow-img {
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transition: transform 0.3s;
}

.u-arrow-custom-v7.js-prev {
  perspective-origin: 100% 50%;
}

.u-arrow-custom-v7.js-prev .u-arrow-img {
  -ms-transform-origin: 0 50%;
      transform-origin: 0 50%;
  transform: rotateY(90deg);
}

.u-arrow-custom-v7.js-next {
  perspective-origin: 0 50%;
}

.u-arrow-custom-v7.js-next .u-arrow-img {
  -ms-transform-origin: 100% 50%;
      transform-origin: 100% 50%;
  transform: rotateY(-90deg);
}

.u-arrow-custom-v7:hover .u-arrow-icon {
  color: #226f1c;
}

.u-arrow-custom-v7:hover .u-arrow-img {
  transform: rotateY(0deg);
}

.u-arrow-custom-v8 {
  perspective: 1000px;
}

.u-arrow-custom-v8 .u-arrow-icon::before {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translateX(-50%) translateY(-50%);
      transform: translateX(-50%) translateY(-50%);
}

.u-arrow-custom-v8 .u-arrow-title,
.u-arrow-custom-v8 .u-arrow-img {
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

.u-arrow-custom-v8 .u-arrow-title {
  transition: transform 0.3s;
}

.u-arrow-custom-v8 .u-arrow-text {
  transition: transform 0.3s 0.3s;
  transform-style: preserve-3d;
}

.u-arrow-custom-v8.js-prev {
  perspective-origin: 100% 50%;
}

.u-arrow-custom-v8.js-prev .u-arrow-text,
.u-arrow-custom-v8.js-prev .u-arrow-title {
  -ms-transform-origin: 0 50%;
      transform-origin: 0 50%;
  transform: rotateY(90deg);
}

.u-arrow-custom-v8.js-next {
  perspective-origin: 0 50%;
}

.u-arrow-custom-v8.js-next .u-arrow-text,
.u-arrow-custom-v8.js-next .u-arrow-title {
  -ms-transform-origin: 100% 50%;
      transform-origin: 100% 50%;
  transform: rotateY(-90deg);
}

.u-arrow-custom-v8:hover .u-arrow-icon {
  color: #226f1c;
  transition-delay: 0s;
}

.u-arrow-custom-v8:hover .u-arrow-icon::before {
  transition-delay: 0s;
}

.u-arrow-custom-v8:hover .u-arrow-text,
.u-arrow-custom-v8:hover .u-arrow-title {
  transform: rotateY(0deg);
}

.u-arrow-custom-v8:hover .u-arrow-text {
  transition-delay: 0s;
}

.u-arrow-custom-v8:hover .u-arrow-title {
  transition-delay: .3s;
}

.u-arrow-custom-v9 {
  width: 60px;
}

.u-arrow-custom-v9 .u-arrow-icon::after {
  content: "";
  position: absolute;
  top: 50%;
  width: 20px;
  height: 1px;
  background: #fff;
  transition: width .3s .2s;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

.u-arrow-custom-v9 .u-arrow-text::after {
  content: attr(data-title);
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  color: #fff;
  text-transform: uppercase;
  opacity: 0;
  transition: transform 0.3s, opacity 0.3s;
  margin: 20px 5px 0;
}

.u-arrow-custom-v9 .u-arrow-img {
  opacity: 0;
  box-shadow: 0 1px 0 #fff, 0 -1px 0 #fff;
  transition: transform .3s, opacity .3s;
  -ms-transform: scale(0.3);
      transform: scale(0.3);
}

.u-arrow-custom-v9.js-prev .u-arrow-icon::after {
  left: calc(100% - 25px);
}

.u-arrow-custom-v9.js-next .u-arrow-icon::after {
  right: calc(100% - 25px);
}

.u-arrow-custom-v9.js-next .u-arrow-text::after {
  text-align: right;
}

.u-arrow-custom-v9:hover {
  width: 200px;
}

.u-arrow-custom-v9:hover .u-arrow-icon::after {
  width: 200px;
  transition-delay: 0s;
}

.u-arrow-custom-v9:hover .u-arrow-text {
  pointer-events: auto;
}

.u-arrow-custom-v9:hover .u-arrow-text::after {
  opacity: 1;
  transition-delay: .2s;
  -ms-transform: translateY(0);
      transform: translateY(0);
}

.u-arrow-custom-v9:hover .u-arrow-img {
  opacity: 1;
  -ms-transform: scale(1);
      transform: scale(1);
}

.u-arrow-custom-v9:hover .u-arrow-img:first-child {
  transition-delay: 0.3s;
}

.u-arrow-custom-v9:hover .u-arrow-img:nth-child(2) {
  transition-delay: 0.35s;
}

.u-arrow-custom-v9:hover .u-arrow-img:nth-child(3) {
  transition-delay: 0.4s;
}

.u-arrow-custom-v10 .u-arrow-text {
  opacity: 0;
  overflow: hidden;
  -ms-transform: scale(0.7);
      transform: scale(0.7);
  transition: transform .3s, opacity .3s, background-color .1s 0s;
}

.u-arrow-custom-v10 .u-arrow-img {
  transition: transform .3s;
}

.u-arrow-custom-v10.js-prev .u-arrow-img {
  -ms-transform: translateX(-100%);
      transform: translateX(-100%);
}

.u-arrow-custom-v10.js-next .u-arrow-img {
  -ms-transform: translateX(100%);
      transform: translateX(100%);
}

.u-arrow-custom-v10:hover .u-arrow-text {
  opacity: 1;
  background-color: transparent;
  -ms-transform: scale(1);
      transform: scale(1);
  transition: transform .3s, opacity .3s, background-color .1s .2s;
}

.u-arrow-custom-v10:hover .u-arrow-img {
  -ms-transform: translateX(0);
      transform: translateX(0);
}

.u-arrow-custom-v11 .u-arrow-icon {
  border-width: 0;
  transition: border-width .3s .15s;
}

.u-arrow-custom-v11 .u-arrow-text {
  opacity: 0;
  transition: transform .3s, opacity .3s;
}

.u-arrow-custom-v11.js-prev .u-arrow-text {
  -ms-transform: translateY(-50%) translateX(-100%) scale(0.75);
      transform: translateY(-50%) translateX(-100%) scale(0.75);
}

.u-arrow-custom-v11.js-next .u-arrow-text {
  -ms-transform: translateY(-50%) translateX(100%) scale(0.75);
      transform: translateY(-50%) translateX(100%) scale(0.75);
}

.u-arrow-custom-v11:hover .u-arrow-icon {
  border-top-width: 40px;
  border-bottom-width: 40px;
  transition-delay: 0s;
}

.u-arrow-custom-v11:hover .u-arrow-text {
  opacity: 1;
  transition-delay: .3s;
  -ms-transform: translateY(-50%) translateX(0) scale(1);
      transform: translateY(-50%) translateX(0) scale(1);
}

.u-arrow-custom-v12 .u-arrow-icon {
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
      transform: translateY(-50%);
}

.u-arrow-custom-v12 .u-arrow-icon::before {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translateX(-50%) translateY(-50%);
      transform: translateX(-50%) translateY(-50%);
}

.u-arrow-custom-v12 .u-arrow-text {
  overflow: hidden;
  transition: transform .3s;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -ms-transform: scale(0.6);
      transform: scale(0.6);
}

.u-arrow-custom-v12 .u-arrow-img {
  opacity: 0;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transition: opacity .3s, transform .3s;
}

.u-arrow-custom-v12:hover .u-arrow-img {
  opacity: .8;
}

.u-arrow-custom-v12.js-prev .u-arrow-img {
  -ms-transform: translateX(-50%) translateY(-50%) rotate(45deg) scale(2);
      transform: translateX(-50%) translateY(-50%) rotate(45deg) scale(2);
}

.u-arrow-custom-v12.js-prev:hover .u-arrow-text {
  -ms-transform: scale(1) rotate(-45deg);
      transform: scale(1) rotate(-45deg);
}

.u-arrow-custom-v12.js-prev:hover .u-arrow-img {
  -ms-transform: translateX(-50%) translateY(-50%) rotate(45deg) scale(1);
      transform: translateX(-50%) translateY(-50%) rotate(45deg) scale(1);
}

.u-arrow-custom-v12.js-next .u-arrow-img {
  -ms-transform: translateX(-50%) translateY(-50%) rotate(-45deg) scale(2);
      transform: translateX(-50%) translateY(-50%) rotate(-45deg) scale(2);
}

.u-arrow-custom-v12.js-next:hover .u-arrow-text {
  -ms-transform: scale(1) rotate(45deg);
      transform: scale(1) rotate(45deg);
}

.u-arrow-custom-v12.js-next:hover .u-arrow-img {
  -ms-transform: translateX(-50%) translateY(-50%) rotate(-45deg) scale(1);
      transform: translateX(-50%) translateY(-50%) rotate(-45deg) scale(1);
}

.u-arrow-custom-v13 .u-arrow-icon {
  overflow: hidden;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

.u-arrow-custom-v13 .u-arrow-icon::before {
  position: relative;
  z-index: 2;
}

.u-arrow-custom-v13 .u-arrow-icon::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 110%;
  background: #fff;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transition: transform .3s .3s;
}

.u-arrow-custom-v13 .u-arrow-text {
  transition: transform .3s;
}

.u-arrow-custom-v13.js-prev .u-arrow-icon::after {
  -ms-transform: translateX(-100%);
      transform: translateX(-100%);
}

.u-arrow-custom-v13.js-prev .u-arrow-text {
  -ms-transform: translateX(-100%);
      transform: translateX(-100%);
}

.u-arrow-custom-v13.js-next .u-arrow-icon::after {
  -ms-transform: translateX(100%);
      transform: translateX(100%);
}

.u-arrow-custom-v13.js-next .u-arrow-text {
  -ms-transform: translateX(100%);
      transform: translateX(100%);
}

.u-arrow-custom-v13:hover .u-arrow-icon::after {
  -ms-transform: translateX(0);
      transform: translateX(0);
  transition-delay: 0s;
}

.u-arrow-custom-v13:hover .u-arrow-text {
  transition-delay: .3s;
  -ms-transform: translateX(0);
      transform: translateX(0);
}

.u-arrow-custom-v14::before, .u-arrow-custom-v14::after,
.u-arrow-custom-v14 .u-arrow-icon::before,
.u-arrow-custom-v14 .u-arrow-icon::after {
  content: "";
  position: absolute;
  left: 50%;
  width: 3px;
  height: 50%;
  background: #226f1c;
  transition: transform .3s;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

.u-arrow-custom-v14::before,
.u-arrow-custom-v14 .u-arrow-icon::before {
  top: 50%;
  -ms-transform: translateX(-50%) rotate(-135deg);
      transform: translateX(-50%) rotate(-135deg);
  -ms-transform-origin: 50% 0;
      transform-origin: 50% 0;
}

.u-arrow-custom-v14::after,
.u-arrow-custom-v14 .u-arrow-icon::after {
  top: 50%;
  -ms-transform: translateX(-50%) rotate(-45deg);
      transform: translateX(-50%) rotate(-45deg);
  -ms-transform-origin: 0 0;
      transform-origin: 0 0;
}

.u-arrow-custom-v14 .u-arrow-icon::before, .u-arrow-custom-v14 .u-arrow-icon::after {
  z-index: 2;
  height: 0;
  background: #fff;
  transition: height .3s, transform .3s;
}

.u-arrow-custom-v14 .u-arrow-title {
  position: absolute;
  top: 50%;
  opacity: 0;
  transition: transform .3s, opacity .3s;
  margin: 0;
}

.u-arrow-custom-v14.js-prev .u-arrow-title {
  left: 100%;
  -ms-transform: translateY(-50%) translateX(-50%);
      transform: translateY(-50%) translateX(-50%);
}

.u-arrow-custom-v14.js-next::before,
.u-arrow-custom-v14.js-next .u-arrow-icon::before {
  -ms-transform: translateX(-50%) rotate(135deg);
      transform: translateX(-50%) rotate(135deg);
  -ms-transform-origin: 50% 0;
      transform-origin: 50% 0;
}

.u-arrow-custom-v14.js-next::after,
.u-arrow-custom-v14.js-next .u-arrow-icon::after {
  -ms-transform: translateX(-50%) rotate(45deg);
      transform: translateX(-50%) rotate(45deg);
  -ms-transform-origin: 100% 0;
      transform-origin: 100% 0;
}

.u-arrow-custom-v14.js-next .u-arrow-title {
  right: 100%;
  text-align: right;
  -ms-transform: translateY(-50%) translateX(50%);
      transform: translateY(-50%) translateX(50%);
}

.u-arrow-custom-v14:hover::before,
.u-arrow-custom-v14:hover .u-arrow-icon::before {
  -ms-transform: translateX(-50%) rotate(-125deg);
      transform: translateX(-50%) rotate(-125deg);
}

.u-arrow-custom-v14:hover::after,
.u-arrow-custom-v14:hover .u-arrow-icon::after {
  -ms-transform: translateX(-50%) rotate(-55deg);
      transform: translateX(-50%) rotate(-55deg);
}

.u-arrow-custom-v14:hover .u-arrow-icon::before, .u-arrow-custom-v14:hover .u-arrow-icon::after {
  height: 50%;
}

.u-arrow-custom-v14:hover .u-arrow-title {
  opacity: 1;
  -ms-transform: translateY(-50%) translateX(0);
      transform: translateY(-50%) translateX(0);
}

.u-arrow-custom-v14:hover.js-next::before,
.u-arrow-custom-v14:hover.js-next .u-arrow-icon::before {
  -ms-transform: translateX(-50%) rotate(125deg);
      transform: translateX(-50%) rotate(125deg);
}

.u-arrow-custom-v14:hover.js-next::after,
.u-arrow-custom-v14:hover.js-next .u-arrow-icon::after {
  -ms-transform: translateX(-50%) rotate(55deg);
      transform: translateX(-50%) rotate(55deg);
}

hr {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

/*------------------------------------
  Go To v1
------------------------------------*/
[class*="u-go-to"] {
  display: none;
}

.u-go-to-v1 {
  width: 3.57143rem;
  height: 3.57143rem;
  display: block;
  background-color: rgba(255, 255, 255, 0.7);
  color: #999;
  border-radius: 50%;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
  transition: .3s ease-out;
  z-index: 11;
}

.u-go-to-v1 i {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
}

.u-go-to-v1:hover, .u-go-to-v1:focus:hover {
  text-decoration: none;
  color: #fff;
  background-color: #226f1c;
}

.u-go-to-v1:focus {
  text-decoration: none;
  color: #999;
  background-color: rgba(255, 255, 255, 0.7);
}

@media (max-width: 576px - 1) {
  .u-go-to-v1 {
    -ms-transform: scale(0.8, 0.8);
        transform: scale(0.8, 0.8);
  }
}

/*------------------------------------
  Go To v2
------------------------------------*/
[class*="u-go-to"] {
  display: none;
}

.u-go-to-v2 {
  display: block;
  background-color: rgba(0, 0, 0, 0.3);
  color: #fff;
  border-radius: 3px;
  transition: .3s ease-out;
  z-index: 11;
}

.u-go-to-v2 i {
  width: 2.85714rem;
  height: 2.85714rem;
  display: block;
  text-align: center;
  line-height: 2.85714rem;
}

.u-go-to-v2:hover, .u-go-to-v2:focus:hover {
  text-decoration: none;
  color: #fff;
  background-color: #226f1c;
}

.u-go-to-v2:focus {
  text-decoration: none;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.3);
}

@media (max-width: 576px - 1) {
  .u-go-to-v2 {
    -ms-transform: scale(0.8, 0.8);
        transform: scale(0.8, 0.8);
  }
}

/*------------------------------------
  Go To v3
------------------------------------*/
[class*="u-go-to"] {
  display: none;
}

.u-go-to-v3 {
  padding: 5px;
  display: block;
  background-color: rgba(255, 255, 255, 0.7);
  color: #999;
  border-radius: 50%;
  transition: .3s ease-out;
  z-index: 11;
}

.u-go-to-v3 i {
  width: 3.57143rem;
  height: 3.57143rem;
  display: block;
  text-align: center;
  border-radius: 50%;
  font-size: 1.14286rem;
  line-height: 3.28571rem;
  border: solid 1px #999;
  transition: .3s ease-out;
}

.u-go-to-v3:hover, .u-go-to-v3:focus:hover {
  text-decoration: none;
  color: #226f1c;
  background-color: white;
}

.u-go-to-v3:hover i, .u-go-to-v3:focus:hover i {
  border-color: #226f1c;
}

.u-go-to-v3:focus {
  text-decoration: none;
  color: #999;
  background-color: rgba(255, 255, 255, 0.7);
}

.u-go-to-v3:focus i {
  border-color: #999;
}

@media (max-width: 576px - 1) {
  .u-go-to-v3 {
    -ms-transform: scale(0.8, 0.8);
        transform: scale(0.8, 0.8);
  }
}

/*------------------------------------
  Go To v4
------------------------------------*/
.u-go-to-v4 {
  position: relative;
  width: 22px;
  height: 35px;
  border: 2px solid #226f1c;
  border-radius: 15px;
}

.u-go-to-v4::before {
  width: 7px;
  height: 7px;
  background-color: #fff;
  border-radius: 50%;
  content: " ";
  position: absolute !important;
  left: 50% !important;
  -ms-transform: translateX(-50%);
      transform: translateX(-50%);
  -webkit-backface-visibility: hidden;
  animation-duration: 2s;
  animation-name: u-go-to-v4;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes u-go-to-v4 {
  0% {
    top: 20%;
    bottom: 0;
  }
  50% {
    top: 50%;
    bottom: 0;
  }
  100% {
    top: 20%;
    bottom: 100%;
  }
}

/*------------------------------------
  Headers
------------------------------------*/
.u-header {
  position: relative;
  left: 0;
  right: 0;
  width: 100%;
  font-size: 0.92857rem;
  z-index: 501;
}

.u-header [aria-labelledby] {
  opacity: 0;
}

.u-header [aria-labelledby][role="tabpanel"] {
  opacity: 1;
}

.u-header [aria-labelledby].u-dropdown--css-animation,
.u-header [aria-labelledby].u-dropdown--jquery-slide {
  opacity: 1;
}

.u-header--abs-top, .u-header--abs-bottom, .u-header--abs-top-2nd-screen {
  position: absolute;
}

.u-header--abs-top-2nd-screen {
  top: 100%;
  bottom: auto;
}

.u-header--abs-top {
  top: 0;
  bottom: auto;
}

.u-header--abs-bottom {
  top: auto;
  bottom: 0;
}

.u-header--abs-bottom .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: .125rem;
}

.u-header--sticky-top, .u-header--sticky-bottom {
  position: fixed;
}

.u-header--sticky-top {
  bottom: auto;
  top: 0;
}

.u-header--sticky-top.u-negative-top {
  bottom: 100%;
  top: auto;
}

.u-header--sticky-bottom {
  top: auto;
  bottom: 0;
}

.u-header--sticky-bottom .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: .125rem;
}

.u-header--invisible {
  display: none;
}

.u-header--moved-up {
  transform: translate3d(0, -100%, 0);
}

.u-header--moved-up.u-negative-top {
  transform: translate3d(0, 100%, 0);
}

.u-header--faded {
  opacity: 0;
  visibility: hidden;
}

.u-header--untransitioned {
  transition: none !important;
}

.u-header__section--hidden {
  position: relative;
}

.u-header--floating {
  position: absolute;
  left: 0;
  right: 0;
}

.u-header--floating.js-header-fix-moment {
  margin-top: 0 !important;
}

.u-header--floating.js-header-fix-moment .navbar {
  padding-left: 0;
  padding-right: 0;
}

.u-header[data-header-fix-effect] {
  transition: .3s ease;
}

.u-header-reduced--shift.js-header-change-moment {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.u-header.js-header-fix-moment {
  position: fixed;
  top: 0;
  bottom: auto;
}

.u-header__section {
  position: relative;
  z-index: 1;
}

.u-header__section:nth-child(1) {
  z-index: 5;
}

.u-header__section:nth-child(2) {
  z-index: 4;
}

.u-header__section:nth-child(3) {
  z-index: 3;
}

.u-header__section:nth-child(4) {
  z-index: 2;
}

.u-header__section:nth-child(5) {
  z-index: 1;
}

.u-header__sections-container {
  position: relative;
  z-index: 2;
}

.u-header__logo {
  position: relative;
  z-index: 1;
}

.u-header__logo-img {
  top: 0;
  left: 0;
  /*transition: .3s ease;*/
}

.u-header__logo-img:not(.u-header__logo-img--main) {
  position: absolute;
  opacity: 0;
}

.dropdown-menu.u-dropdown--reverse-position {
  left: auto;
  right: 0;
}

.js-header-change-moment.g-bg-white--shift {
  background-color: #fff;
}

.js-header-change-moment.g-bg-light-semi-transparent--shift {
  background-color: rgba(255, 255, 255, 0.9);
}

.js-header-change-moment.g-bg-black--shift {
  background-color: #000;
}

.js-header-change-moment.g-bg-dark-semi-transparent--shift {
  background-color: rgba(0, 0, 0, 0.9);
}

.js-header-change-moment.g-bg-primary--shift {
  background-color: #226f1c;
}

.js-header-change-moment.g-bg-primary-semi-transparent--shift {
  background-color: rgba(34, 111, 28, 0.9);
}

.u-header__section--light .navbar-brand,
.u-header__section--light .navbar-toggler,
.u-header__section--light--shift.js-header-change-moment .navbar-brand,
.u-header__section--light--shift.js-header-change-moment .navbar-toggler {
  color: #999999;
}

.u-header__section--light .navbar-brand:focus,
.u-header__section--light .navbar-brand:hover,
.u-header__section--light .navbar-toggler:focus,
.u-header__section--light .navbar-toggler:hover,
.u-header__section--light--shift.js-header-change-moment .navbar-brand:focus,
.u-header__section--light--shift.js-header-change-moment .navbar-brand:hover,
.u-header__section--light--shift.js-header-change-moment .navbar-toggler:focus,
.u-header__section--light--shift.js-header-change-moment .navbar-toggler:hover {
  color: #999999;
}

.u-header__section--light .navbar-nav:not([class*="u-main-nav-v"]) .nav-link,
.u-header__section--light--shift.js-header-change-moment .navbar-nav:not([class*="u-main-nav-v"]) .nav-link {
  color: #999999;
}

.u-header__section--light .navbar-nav:not([class*="u-main-nav-v"]) .nav-link:focus,
.u-header__section--light .navbar-nav:not([class*="u-main-nav-v"]) .nav-link:hover,
.u-header__section--light--shift.js-header-change-moment .navbar-nav:not([class*="u-main-nav-v"]) .nav-link:focus,
.u-header__section--light--shift.js-header-change-moment .navbar-nav:not([class*="u-main-nav-v"]) .nav-link:hover {
  color: #999999;
}

.u-header__section--light .navbar-nav:not([class*="u-main-nav-v"]) .nav-link.disabled,
.u-header__section--light--shift.js-header-change-moment .navbar-nav:not([class*="u-main-nav-v"]) .nav-link.disabled {
  color: rgba(153, 153, 153, 0.5);
}

.u-header__section--light .navbar-nav:not([class*="u-main-nav-v"]) .open > .nav-link,
.u-header__section--light .navbar-nav:not([class*="u-main-nav-v"]) .active > .nav-link,
.u-header__section--light .navbar-nav:not([class*="u-main-nav-v"]) .nav-link.open,
.u-header__section--light .navbar-nav:not([class*="u-main-nav-v"]) .nav-link.active,
.u-header__section--light--shift.js-header-change-moment .navbar-nav:not([class*="u-main-nav-v"]) .open > .nav-link,
.u-header__section--light--shift.js-header-change-moment .navbar-nav:not([class*="u-main-nav-v"]) .active > .nav-link,
.u-header__section--light--shift.js-header-change-moment .navbar-nav:not([class*="u-main-nav-v"]) .nav-link.open,
.u-header__section--light--shift.js-header-change-moment .navbar-nav:not([class*="u-main-nav-v"]) .nav-link.active {
  color: #226f1c;
}

.u-header__section--light .navbar-toggler,
.u-header__section--light--shift.js-header-change-moment .navbar-toggler {
  border-color: rgba(153, 153, 153, 0.3);
}

.u-header__section--light .navbar-toggler-icon,
.u-header__section--light--shift.js-header-change-moment .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba($g-color-main, .5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.u-header__section--light .navbar-text,
.u-header__section--light--shift.js-header-change-moment .navbar-text {
  color: rgba(153, 153, 153, 0.7);
}

.u-header__section--dark .navbar-brand,
.u-header__section--dark .navbar-toggler,
.u-header__section--dark--shift.js-header-change-moment .navbar-brand,
.u-header__section--dark--shift.js-header-change-moment .navbar-toggler,
.u-header__section--primary .navbar-brand,
.u-header__section--primary .navbar-toggler,
.u-header__section--primary--shift.js-header-change-moment .navbar-brand,
.u-header__section--primary--shift.js-header-change-moment .navbar-toggler {
  color: #fff;
}

.u-header__section--dark .navbar-brand:focus,
.u-header__section--dark .navbar-brand:hover,
.u-header__section--dark .navbar-toggler:focus,
.u-header__section--dark .navbar-toggler:hover,
.u-header__section--dark--shift.js-header-change-moment .navbar-brand:focus,
.u-header__section--dark--shift.js-header-change-moment .navbar-brand:hover,
.u-header__section--dark--shift.js-header-change-moment .navbar-toggler:focus,
.u-header__section--dark--shift.js-header-change-moment .navbar-toggler:hover,
.u-header__section--primary .navbar-brand:focus,
.u-header__section--primary .navbar-brand:hover,
.u-header__section--primary .navbar-toggler:focus,
.u-header__section--primary .navbar-toggler:hover,
.u-header__section--primary--shift.js-header-change-moment .navbar-brand:focus,
.u-header__section--primary--shift.js-header-change-moment .navbar-brand:hover,
.u-header__section--primary--shift.js-header-change-moment .navbar-toggler:focus,
.u-header__section--primary--shift.js-header-change-moment .navbar-toggler:hover {
  color: #fff;
}

.u-header__section--dark .navbar-nav:not([class*="u-main-nav-v"]) .nav-link,
.u-header__section--dark--shift.js-header-change-moment .navbar-nav:not([class*="u-main-nav-v"]) .nav-link,
.u-header__section--primary .navbar-nav:not([class*="u-main-nav-v"]) .nav-link,
.u-header__section--primary--shift.js-header-change-moment .navbar-nav:not([class*="u-main-nav-v"]) .nav-link {
  color: rgba(255, 255, 255, 0.8);
}

.u-header__section--dark .navbar-nav:not([class*="u-main-nav-v"]) .nav-link:focus,
.u-header__section--dark .navbar-nav:not([class*="u-main-nav-v"]) .nav-link:hover,
.u-header__section--dark--shift.js-header-change-moment .navbar-nav:not([class*="u-main-nav-v"]) .nav-link:focus,
.u-header__section--dark--shift.js-header-change-moment .navbar-nav:not([class*="u-main-nav-v"]) .nav-link:hover,
.u-header__section--primary .navbar-nav:not([class*="u-main-nav-v"]) .nav-link:focus,
.u-header__section--primary .navbar-nav:not([class*="u-main-nav-v"]) .nav-link:hover,
.u-header__section--primary--shift.js-header-change-moment .navbar-nav:not([class*="u-main-nav-v"]) .nav-link:focus,
.u-header__section--primary--shift.js-header-change-moment .navbar-nav:not([class*="u-main-nav-v"]) .nav-link:hover {
  color: #fff;
}

.u-header__section--dark .navbar-nav:not([class*="u-main-nav-v"]) .nav-link.disabled,
.u-header__section--dark--shift.js-header-change-moment .navbar-nav:not([class*="u-main-nav-v"]) .nav-link.disabled,
.u-header__section--primary .navbar-nav:not([class*="u-main-nav-v"]) .nav-link.disabled,
.u-header__section--primary--shift.js-header-change-moment .navbar-nav:not([class*="u-main-nav-v"]) .nav-link.disabled {
  color: rgba(255, 255, 255, 0.5);
}

.u-header__section--dark .navbar-nav:not([class*="u-main-nav-v"]) .open > .nav-link,
.u-header__section--dark .navbar-nav:not([class*="u-main-nav-v"]) .active > .nav-link,
.u-header__section--dark .navbar-nav:not([class*="u-main-nav-v"]) .nav-link.open,
.u-header__section--dark .navbar-nav:not([class*="u-main-nav-v"]) .nav-link.active,
.u-header__section--dark--shift.js-header-change-moment .navbar-nav:not([class*="u-main-nav-v"]) .open > .nav-link,
.u-header__section--dark--shift.js-header-change-moment .navbar-nav:not([class*="u-main-nav-v"]) .active > .nav-link,
.u-header__section--dark--shift.js-header-change-moment .navbar-nav:not([class*="u-main-nav-v"]) .nav-link.open,
.u-header__section--dark--shift.js-header-change-moment .navbar-nav:not([class*="u-main-nav-v"]) .nav-link.active,
.u-header__section--primary .navbar-nav:not([class*="u-main-nav-v"]) .open > .nav-link,
.u-header__section--primary .navbar-nav:not([class*="u-main-nav-v"]) .active > .nav-link,
.u-header__section--primary .navbar-nav:not([class*="u-main-nav-v"]) .nav-link.open,
.u-header__section--primary .navbar-nav:not([class*="u-main-nav-v"]) .nav-link.active,
.u-header__section--primary--shift.js-header-change-moment .navbar-nav:not([class*="u-main-nav-v"]) .open > .nav-link,
.u-header__section--primary--shift.js-header-change-moment .navbar-nav:not([class*="u-main-nav-v"]) .active > .nav-link,
.u-header__section--primary--shift.js-header-change-moment .navbar-nav:not([class*="u-main-nav-v"]) .nav-link.open,
.u-header__section--primary--shift.js-header-change-moment .navbar-nav:not([class*="u-main-nav-v"]) .nav-link.active {
  color: #fff;
}

.u-header__section--dark .navbar-toggler,
.u-header__section--dark--shift.js-header-change-moment .navbar-toggler,
.u-header__section--primary .navbar-toggler,
.u-header__section--primary--shift.js-header-change-moment .navbar-toggler {
  border-color: rgba(255, 255, 255, 0.3);
}

.u-header__section--dark .navbar-toggler-icon,
.u-header__section--dark--shift.js-header-change-moment .navbar-toggler-icon,
.u-header__section--primary .navbar-toggler-icon,
.u-header__section--primary--shift.js-header-change-moment .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba($g-color-white, .5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.u-header__section--dark .navbar-text,
.u-header__section--dark--shift.js-header-change-moment .navbar-text,
.u-header__section--primary .navbar-text,
.u-header__section--primary--shift.js-header-change-moment .navbar-text {
  color: rgba(255, 255, 255, 0.7);
}

.navbar > .container {
  position: relative;
}

@media all and (min-width: 576px) {
  .u-header--abs-top--sm, .u-header--abs-bottom--sm, .u-header--abs-top-2nd-screen--sm {
    position: absolute;
  }
  .u-header--abs-top-2nd-screen--sm {
    top: 100%;
  }
  .u-header--abs-top--sm {
    bottom: auto;
    top: 0;
  }
  .u-header--abs-bottom--sm {
    top: auto;
    bottom: 0;
  }
  .u-header--sticky-top--sm, .u-header--sticky-bottom--sm {
    position: fixed;
  }
  .u-header--sticky-top--sm {
    bottom: auto;
    top: 0;
  }
  .u-header--sticky-bottom--sm {
    top: auto;
    bottom: 0;
  }
  .u-header--floating--sm {
    position: absolute;
    left: 0;
    right: 0;
  }
  .u-header--floating--sm.js-header-fix-moment {
    margin-top: 0 !important;
  }
  .u-header--floating--sm.js-header-fix-moment .navbar {
    padding-left: 0;
    padding-right: 0;
  }
}

@media all and (min-width: 768px) {
  .u-header--abs-top--md, .u-header--abs-bottom--md, .u-header--abs-top-2nd-screen--md {
    position: absolute;
  }
  .u-header--abs-top-2nd-screen--md {
    top: 100%;
  }
  .u-header--abs-top--md {
    bottom: auto;
    top: 0;
  }
  .u-header--abs-bottom--md {
    top: auto;
    bottom: 0;
  }
  .u-header--sticky-top--md, .u-header--sticky-bottom--md {
    position: fixed;
  }
  .u-header--sticky-top--md {
    bottom: auto;
    top: 0;
  }
  .u-header--sticky-bottom--md {
    top: auto;
    bottom: 0;
  }
  .u-header--floating--md {
    position: absolute;
    left: 0;
    right: 0;
  }
  .u-header--floating--md.js-header-fix-moment {
    margin-top: 0 !important;
  }
  .u-header--floating--md.js-header-fix-moment .navbar {
    padding-left: 0;
    padding-right: 0;
  }
}

@media all and (min-width: 992px) {
  .u-header--abs-top--lg, .u-header--abs-bottom--lg, .u-header--abs-top-2nd-screen--lg {
    position: absolute;
  }
  .u-header--abs-top-2nd-screen--lg {
    top: 100%;
  }
  .u-header--abs-top--lg {
    bottom: auto;
    top: 0;
  }
  .u-header--abs-bottom--lg {
    top: auto;
    bottom: 0;
  }
  .u-header--sticky-top--lg, .u-header--sticky-bottom--lg {
    position: fixed;
  }
  .u-header--sticky-top--lg {
    bottom: auto;
    top: 0;
  }
  .u-header--sticky-bottom--lg {
    top: auto;
    bottom: 0;
  }
  .u-header--floating--lg {
    position: absolute;
    left: 0;
    right: 0;
  }
  .u-header--floating--lg.js-header-fix-moment {
    margin-top: 0 !important;
  }
  .u-header--floating--lg.js-header-fix-moment .navbar {
    padding-left: 0;
    padding-right: 0;
  }
}

@media all and (min-width: 1200px) {
  .u-header--abs-top--xl, .u-header--abs-bottom--xl, .u-header--abs-top-2nd-screen--xl {
    position: absolute;
  }
  .u-header--abs-top-2nd-screen--xl {
    top: 100%;
  }
  .u-header--abs-top--xl {
    bottom: auto;
    top: 0;
  }
  .u-header--abs-bottom--xl {
    top: auto;
    bottom: 0;
  }
  .u-header--sticky-top--xl, .u-header--sticky-bottom--xl {
    position: fixed;
  }
  .u-header--sticky-top--xl {
    bottom: auto;
    top: 0;
  }
  .u-header--sticky-bottom--xl {
    top: auto;
    bottom: 0;
  }
  .u-header--floating--xl {
    position: absolute;
    left: 0;
    right: 0;
  }
  .u-header--floating--xl.js-header-fix-moment {
    margin-top: 0 !important;
  }
  .u-header--floating--xl.js-header-fix-moment .navbar {
    padding-left: 0;
    padding-right: 0;
  }
}

@media all and (max-width: 1200px - 1) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    -ms-flex-pack: start;
        justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-collapse {
    width: 100%;
    -ms-flex-order: 10;
        order: 10;
  }
}

@media all and (max-width: 992px - 1) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    -ms-flex-pack: start;
        justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-collapse {
    width: 100%;
    -ms-flex-order: 10;
        order: 10;
  }
}

@media all and (max-width: 768px - 1) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    -ms-flex-pack: start;
        justify-content: flex-start;
  }
  .navbar-expand-md .navbar-collapse {
    width: 100%;
    -ms-flex-order: 10;
        order: 10;
  }
}

@media all and (max-width: 576px - 1) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    -ms-flex-pack: start;
        justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-collapse {
    width: 100%;
    -ms-flex-order: 10;
        order: 10;
  }
}

/*------------------------------------
	Header Togglers
------------------------------------*/
.u-header-toggler {
  display: block;
  width: 5rem;
  height: 3.57143rem;
  z-index: 502;
  /* Hamburgers */
}

.u-header-toggler .hamburger {
  line-height: 1;
}

/* Toggler Positioning */
@media all and (min-width: 0) {
  .u-header-toggler--top-right, .u-header-toggler--top-right.btn, .u-header-toggler--top-left, .u-header-toggler--top-left.btn, .u-header-toggler--bottom-right, .u-header-toggler--bottom-right.btn, .u-header-toggler--bottom-left, .u-header-toggler--bottom-left.btn {
    position: fixed;
  }
  .u-header-toggler--top-right, .u-header-toggler--top-right.btn, .u-header-toggler--top-left, .u-header-toggler--top-left.btn {
    top: 1.42857rem;
  }
  .u-header-toggler--bottom-right, .u-header-toggler--bottom-right.btn, .u-header-toggler--bottom-left, .u-header-toggler--bottom-left.btn {
    bottom: 1.42857rem;
  }
  .u-header-toggler--top-left, .u-header-toggler--bottom-left {
    left: 1.42857rem;
  }
  .u-header-toggler--top-right, .u-header-toggler--bottom-right {
    right: 1.42857rem;
  }
}

/* Toggler Positioning (sm) */
@media all and (min-width: 576px) {
  .u-header-toggler--top-right--sm, .u-header-toggler--top-right--sm.btn, .u-header-toggler--top-left--sm, .u-header-toggler--top-left--sm.btn, .u-header-toggler--bottom-right--sm, .u-header-toggler--bottom-right--sm.btn, .u-header-toggler--bottom-left--sm, .u-header-toggler--bottom-left--sm.btn {
    position: fixed;
  }
  .u-header-toggler--top-right--sm, .u-header-toggler--top-right--sm.btn, .u-header-toggler--top-left--sm, .u-header-toggler--top-left--sm.btn {
    top: 1.42857rem;
  }
  .u-header-toggler--bottom-right--sm, .u-header-toggler--bottom-right--sm.btn, .u-header-toggler--bottom-left--sm, .u-header-toggler--bottom-left--sm.btn {
    bottom: 1.42857rem;
  }
  .u-header-toggler--top-left--sm, .u-header-toggler--bottom-left--sm {
    left: 1.42857rem;
  }
  .u-header-toggler--top-right--sm, .u-header-toggler--bottom-right--sm {
    right: 1.42857rem;
  }
}

/* Toggler Positioning (md) */
@media all and (min-width: 768px) {
  .u-header-toggler--top-right--md, .u-header-toggler--top-right--md.btn, .u-header-toggler--top-left--md, .u-header-toggler--top-left--md.btn, .u-header-toggler--bottom-right--md, .u-header-toggler--bottom-right--md.btn, .u-header-toggler--bottom-left--md, .u-header-toggler--bottom-left--md.btn {
    position: fixed;
  }
  .u-header-toggler--top-right--md, .u-header-toggler--top-right--md.btn, .u-header-toggler--top-left--md, .u-header-toggler--top-left--md.btn {
    top: 1.42857rem;
  }
  .u-header-toggler--bottom-right--md, .u-header-toggler--bottom-right--md.btn, .u-header-toggler--bottom-left--md, .u-header-toggler--bottom-left--md.btn {
    bottom: 1.42857rem;
  }
  .u-header-toggler--top-left--md, .u-header-toggler--bottom-left--md {
    left: 1.42857rem;
  }
  .u-header-toggler--top-right--md, .u-header-toggler--bottom-right--md {
    right: 1.42857rem;
  }
}

/* Toggler Positioning (lg) */
@media all and (min-width: 992px) {
  .u-header-toggler--top-right--lg, .u-header-toggler--top-right--lg.btn, .u-header-toggler--top-left--lg, .u-header-toggler--top-left--lg.btn, .u-header-toggler--bottom-right--lg, .u-header-toggler--bottom-right--lg.btn, .u-header-toggler--bottom-left--lg, .u-header-toggler--bottom-left--lg.btn {
    position: fixed;
  }
  .u-header-toggler--top-right--lg, .u-header-toggler--top-right--lg.btn, .u-header-toggler--top-left--lg, .u-header-toggler--top-left--lg.btn {
    top: 1.42857rem;
  }
  .u-header-toggler--bottom-right--lg, .u-header-toggler--bottom-right--lg.btn, .u-header-toggler--bottom-left--lg, .u-header-toggler--bottom-left--lg.btn {
    bottom: 1.42857rem;
  }
  .u-header-toggler--top-left--lg, .u-header-toggler--bottom-left--lg {
    left: 1.42857rem;
  }
  .u-header-toggler--top-right--lg, .u-header-toggler--bottom-right--lg {
    right: 1.42857rem;
  }
}

/* Toggler Positioning (xl) */
@media all and (min-width: 1200px) {
  .u-header-toggler--top-right--xl, .u-header-toggler--top-right--xl.btn, .u-header-toggler--top-left--xl, .u-header-toggler--top-left--xl.btn, .u-header-toggler--bottom-right--xl, .u-header-toggler--bottom-right--xl.btn, .u-header-toggler--bottom-left--xl, .u-header-toggler--bottom-left--xl.btn {
    position: fixed;
  }
  .u-header-toggler--top-right--xl, .u-header-toggler--top-right--xl.btn, .u-header-toggler--top-left--xl, .u-header-toggler--top-left--xl.btn {
    top: 1.42857rem;
  }
  .u-header-toggler--bottom-right--xl, .u-header-toggler--bottom-right--xl.btn, .u-header-toggler--bottom-left--xl, .u-header-toggler--bottom-left--xl.btn {
    bottom: 1.42857rem;
  }
  .u-header-toggler--top-left--xl, .u-header-toggler--bottom-left--xl {
    left: 1.42857rem;
  }
  .u-header-toggler--top-right--xl, .u-header-toggler--bottom-right--xl {
    right: 1.42857rem;
  }
}

/*------------------------------------
  Fullscreen Header
------------------------------------*/
[class*="u-header--fullscreen"] {
  text-align: center;
  visibility: hidden;
}

[class*="u-header--fullscreen"] .u-header__sections-container {
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity .5s ease, visibility .5s ease;
}

[class*="u-header--fullscreen"] .mCustomScrollBox {
  width: 100%;
  height: auto;
}

[class*="u-header--fullscreen"] .u-header__section {
  width: 100%;
}

[class*="u-header--fullscreen"] .navbar-nav[class*="u-main-nav-v"] > li > a {
  display: inline-block;
}

[class*="u-header--fullscreen"] .u-header__overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  opacity: 0;
  visibility: hidden;
  border-radius: 50%;
  transition: transform .5s ease, opacity .5s ease, visibility .5s ease;
}

.u-header.u-header--fullscreen--top-left, .u-header.u-header--fullscreen--top-right, .u-header.u-header--fullscreen--bottom-left, .u-header.u-header--fullscreen--bottom-right {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.u-header.u-header--fullscreen--top-left .u-header__overlay, .u-header.u-header--fullscreen--top-right .u-header__overlay, .u-header.u-header--fullscreen--bottom-left .u-header__overlay, .u-header.u-header--fullscreen--bottom-right .u-header__overlay {
  display: block;
}

.u-header.u-header--fullscreen--top-left.u-header--fullscreen-showed, .u-header.u-header--fullscreen--top-right.u-header--fullscreen-showed, .u-header.u-header--fullscreen--bottom-left.u-header--fullscreen-showed, .u-header.u-header--fullscreen--bottom-right.u-header--fullscreen-showed {
  visibility: visible;
}

.u-header.u-header--fullscreen--top-left.u-header--fullscreen-showed .u-header__overlay,
.u-header.u-header--fullscreen--top-left.u-header--fullscreen-showed .u-header__sections-container, .u-header.u-header--fullscreen--top-right.u-header--fullscreen-showed .u-header__overlay,
.u-header.u-header--fullscreen--top-right.u-header--fullscreen-showed .u-header__sections-container, .u-header.u-header--fullscreen--bottom-left.u-header--fullscreen-showed .u-header__overlay,
.u-header.u-header--fullscreen--bottom-left.u-header--fullscreen-showed .u-header__sections-container, .u-header.u-header--fullscreen--bottom-right.u-header--fullscreen-showed .u-header__overlay,
.u-header.u-header--fullscreen--bottom-right.u-header--fullscreen-showed .u-header__sections-container {
  opacity: 1;
  visibility: visible;
}

.u-header.u-header--fullscreen--top-left.u-header--fullscreen-showed .u-header__sections-container, .u-header.u-header--fullscreen--top-right.u-header--fullscreen-showed .u-header__sections-container, .u-header.u-header--fullscreen--bottom-left.u-header--fullscreen-showed .u-header__sections-container, .u-header.u-header--fullscreen--bottom-right.u-header--fullscreen-showed .u-header__sections-container {
  transition-delay: .5s;
}

.u-header.u-header--fullscreen--top-left .container, .u-header.u-header--fullscreen--top-right .container, .u-header.u-header--fullscreen--bottom-left .container, .u-header.u-header--fullscreen--bottom-right .container {
  width: 100%;
}

.u-header.u-header--fullscreen--top-left .navbar, .u-header.u-header--fullscreen--top-right .navbar, .u-header.u-header--fullscreen--bottom-left .navbar, .u-header.u-header--fullscreen--bottom-right .navbar {
  min-width: 22.85714rem;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

.u-header.u-header--fullscreen--top-left .dropdown-menu, .u-header.u-header--fullscreen--top-right .dropdown-menu, .u-header.u-header--fullscreen--bottom-left .dropdown-menu, .u-header.u-header--fullscreen--bottom-right .dropdown-menu {
  text-align: inherit;
  position: static;
  width: 100%;
}

.u-header.u-header--fullscreen--top-left .u-header__overlay {
  -ms-transform-origin: 0% 0%;
      transform-origin: 0% 0%;
  transform: scale3d(0.1, 0.1, 0.1) translate3d(-20%, -20%, 0);
}

.u-header.u-header--fullscreen--top-left.u-header--fullscreen-showed .u-header__overlay {
  transform: scale3d(1, 1, 1) translate3d(-20%, -20%, 0);
}

.u-header.u-header--fullscreen--top-right .u-header__overlay {
  left: auto;
  -ms-transform-origin: 100% 0%;
      transform-origin: 100% 0%;
  transform: scale3d(0.1, 0.1, 0.1) translate3d(20%, -20%, 0);
}

.u-header.u-header--fullscreen--top-right.u-header--fullscreen-showed .u-header__overlay {
  transform: scale3d(1, 1, 1) translate3d(20%, -20%, 0);
}

.u-header.u-header--fullscreen--bottom-left .u-header__overlay {
  top: auto;
  -ms-transform-origin: 0% 100%;
      transform-origin: 0% 100%;
  transform: scale3d(0.1, 0.1, 0.1) translate3d(-20%, 20%, 0);
}

.u-header.u-header--fullscreen--bottom-left.u-header--fullscreen-showed .u-header__overlay {
  transform: scale3d(1, 1, 1) translate3d(-20%, 20%, 0);
}

.u-header.u-header--fullscreen--bottom-right .u-header__overlay {
  top: auto;
  left: auto;
  -ms-transform-origin: 100% 100%;
      transform-origin: 100% 100%;
  transform: scale3d(0.1, 0.1, 0.1) translate3d(20%, 20%, 0);
}

.u-header.u-header--fullscreen--bottom-right.u-header--fullscreen-showed .u-header__overlay {
  transform: scale3d(1, 1, 1) translate3d(20%, 20%, 0);
}

/*------------------------------------
  Side Header
------------------------------------*/
/* Common styles of Side Header */
body[class*="u-body--header-side"] .u-header.u-header--side {
  position: fixed;
  top: 0;
  height: 100%;
  width: 21.42857rem;
}

body[class*="u-body--header-side"] .u-header.u-header--side .navbar {
  -ms-flex-direction: column;
      flex-direction: column;
}

body[class*="u-body--header-side"] .u-header.u-header--side .navbar .container {
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  -ms-flex-direction: column;
      flex-direction: column;
}

body[class*="u-body--header-side"] .u-header.u-header--side .navbar .navbar-collapse {
  -ms-flex-direction: column;
      flex-direction: column;
}

body[class*="u-body--header-side"] .u-header.u-header--side .navbar .navbar-nav {
  width: 100%;
  -ms-flex-direction: column;
      flex-direction: column;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

body[class*="u-body--header-side"] .u-header.u-header--side .u-header__sections-container {
  height: 100%;
}

body[class*="u-body--header-side"] .u-header.u-header--side .dropdown-menu {
  top: 0;
}

body[class*="u-body--header-side"] .u-header.u-header--side .mCSB_container,
body[class*="u-body--header-side"] .u-header.u-header--side .mCustomScrollBox {
  overflow: visible;
}

/* Overlay */
.u-header--side .u-header__overlay {
  position: absolute;
  top: 0;
  height: 100%;
  width: 2000%;
  opacity: 0;
  visibility: hidden;
}

body[class*="u-body--header-side"].u-body--header-side-opened .u-header__overlay {
  opacity: 1;
  visibility: visible;
}

.u-body--header-side-static-left .u-header--side .dropdown-menu, .u-body--header-side-overlay-left .u-header--side .dropdown-menu, .u-body--header-side-push-left .u-header--side .dropdown-menu {
  left: 100%;
  margin-left: 1.07143rem;
}

.u-body--header-side-static-left .u-header--side .hs-menu-vertical .hs-mega-menu,
.u-body--header-side-static-left .u-header--side .hs-menu-vertical .hs-sub-menu, .u-body--header-side-overlay-left .u-header--side .hs-menu-vertical .hs-mega-menu,
.u-body--header-side-overlay-left .u-header--side .hs-menu-vertical .hs-sub-menu, .u-body--header-side-push-left .u-header--side .hs-menu-vertical .hs-mega-menu,
.u-body--header-side-push-left .u-header--side .hs-menu-vertical .hs-sub-menu {
  margin-left: 1.07143rem;
}

.u-body--header-side-static-left .u-header--side .hs-menu-vertical .hs-mega-menu .hs-mega-menu,
.u-body--header-side-static-left .u-header--side .hs-menu-vertical .hs-mega-menu .hs-sub-menu,
.u-body--header-side-static-left .u-header--side .hs-menu-vertical .hs-sub-menu .hs-mega-menu,
.u-body--header-side-static-left .u-header--side .hs-menu-vertical .hs-sub-menu .hs-sub-menu, .u-body--header-side-overlay-left .u-header--side .hs-menu-vertical .hs-mega-menu .hs-mega-menu,
.u-body--header-side-overlay-left .u-header--side .hs-menu-vertical .hs-mega-menu .hs-sub-menu,
.u-body--header-side-overlay-left .u-header--side .hs-menu-vertical .hs-sub-menu .hs-mega-menu,
.u-body--header-side-overlay-left .u-header--side .hs-menu-vertical .hs-sub-menu .hs-sub-menu, .u-body--header-side-push-left .u-header--side .hs-menu-vertical .hs-mega-menu .hs-mega-menu,
.u-body--header-side-push-left .u-header--side .hs-menu-vertical .hs-mega-menu .hs-sub-menu,
.u-body--header-side-push-left .u-header--side .hs-menu-vertical .hs-sub-menu .hs-mega-menu,
.u-body--header-side-push-left .u-header--side .hs-menu-vertical .hs-sub-menu .hs-sub-menu {
  margin-left: 0;
}

.u-body--header-side-static-left .u-header--side .hs-menu-vertical.hs-rtl .hs-mega-menu,
.u-body--header-side-static-left .u-header--side .hs-menu-vertical.hs-rtl .hs-sub-menu, .u-body--header-side-overlay-left .u-header--side .hs-menu-vertical.hs-rtl .hs-mega-menu,
.u-body--header-side-overlay-left .u-header--side .hs-menu-vertical.hs-rtl .hs-sub-menu, .u-body--header-side-push-left .u-header--side .hs-menu-vertical.hs-rtl .hs-mega-menu,
.u-body--header-side-push-left .u-header--side .hs-menu-vertical.hs-rtl .hs-sub-menu {
  margin-left: 0;
  margin-right: 1.07143rem;
}

.u-body--header-side-static-left .u-header--side .hs-menu-vertical.hs-rtl .hs-mega-menu .hs-mega-menu,
.u-body--header-side-static-left .u-header--side .hs-menu-vertical.hs-rtl .hs-mega-menu .hs-sub-menu,
.u-body--header-side-static-left .u-header--side .hs-menu-vertical.hs-rtl .hs-sub-menu .hs-mega-menu,
.u-body--header-side-static-left .u-header--side .hs-menu-vertical.hs-rtl .hs-sub-menu .hs-sub-menu, .u-body--header-side-overlay-left .u-header--side .hs-menu-vertical.hs-rtl .hs-mega-menu .hs-mega-menu,
.u-body--header-side-overlay-left .u-header--side .hs-menu-vertical.hs-rtl .hs-mega-menu .hs-sub-menu,
.u-body--header-side-overlay-left .u-header--side .hs-menu-vertical.hs-rtl .hs-sub-menu .hs-mega-menu,
.u-body--header-side-overlay-left .u-header--side .hs-menu-vertical.hs-rtl .hs-sub-menu .hs-sub-menu, .u-body--header-side-push-left .u-header--side .hs-menu-vertical.hs-rtl .hs-mega-menu .hs-mega-menu,
.u-body--header-side-push-left .u-header--side .hs-menu-vertical.hs-rtl .hs-mega-menu .hs-sub-menu,
.u-body--header-side-push-left .u-header--side .hs-menu-vertical.hs-rtl .hs-sub-menu .hs-mega-menu,
.u-body--header-side-push-left .u-header--side .hs-menu-vertical.hs-rtl .hs-sub-menu .hs-sub-menu {
  margin-left: 0;
  margin-right: 0;
}

.u-body--header-side-static-left {
  margin-left: 21.42857rem;
}

.u-body--header-side-static-left .u-header--side {
  right: auto;
  left: 0;
}

.u-body--header-side-static-right {
  margin-right: 21.42857rem;
}

.u-body--header-side-static-right .u-header--side {
  left: auto;
  right: 0;
}

.u-body--header-side-overlay-left .u-header--side, .u-body--header-side-push-left .u-header--side {
  right: auto;
  left: -21.42857rem;
}

.u-body--header-side-overlay-left .u-header--side .u-header__overlay, .u-body--header-side-push-left .u-header--side .u-header__overlay {
  left: 100%;
}

.u-body--header-side-overlay-left.u-body--header-side-opened .u-header--side, .u-body--header-side-push-left.u-body--header-side-opened .u-header--side {
  left: 0;
}

.u-body--header-side-push-left.u-body--header-side-opened {
  margin-left: 21.42857rem;
}

.u-body--header-side-overlay-right .u-header--side, .u-body--header-side-push-right .u-header--side {
  left: auto;
  right: -21.42857rem;
}

.u-body--header-side-overlay-right .u-header--side .u-header__overlay, .u-body--header-side-push-right .u-header--side .u-header__overlay {
  right: 100%;
}

.u-body--header-side-overlay-right.u-body--header-side-opened .u-header--side, .u-body--header-side-push-right.u-body--header-side-opened .u-header--side {
  right: 0;
}

.u-body--header-side-push-right.u-body--header-side-opened {
  margin-right: 21.42857rem;
}

.u-body--header-side-static-right .u-header--side .dropdown-menu, .u-body--header-side-overlay-right .u-header--side .dropdown-menu, .u-body--header-side-push-right .u-header--side .dropdown-menu {
  left: auto;
  right: 100%;
  margin-right: 1.07143rem;
}

.u-body--header-side-static-right .u-header--side .hs-menu-vertical .hs-mega-menu,
.u-body--header-side-static-right .u-header--side .hs-menu-vertical .hs-sub-menu, .u-body--header-side-overlay-right .u-header--side .hs-menu-vertical .hs-mega-menu,
.u-body--header-side-overlay-right .u-header--side .hs-menu-vertical .hs-sub-menu, .u-body--header-side-push-right .u-header--side .hs-menu-vertical .hs-mega-menu,
.u-body--header-side-push-right .u-header--side .hs-menu-vertical .hs-sub-menu {
  left: auto;
  right: 100%;
  margin-right: 1.07143rem;
}

.u-body--header-side-static-right .u-header--side .hs-menu-vertical .hs-mega-menu .hs-mega-menu,
.u-body--header-side-static-right .u-header--side .hs-menu-vertical .hs-mega-menu .hs-sub-menu,
.u-body--header-side-static-right .u-header--side .hs-menu-vertical .hs-sub-menu .hs-mega-menu,
.u-body--header-side-static-right .u-header--side .hs-menu-vertical .hs-sub-menu .hs-sub-menu, .u-body--header-side-overlay-right .u-header--side .hs-menu-vertical .hs-mega-menu .hs-mega-menu,
.u-body--header-side-overlay-right .u-header--side .hs-menu-vertical .hs-mega-menu .hs-sub-menu,
.u-body--header-side-overlay-right .u-header--side .hs-menu-vertical .hs-sub-menu .hs-mega-menu,
.u-body--header-side-overlay-right .u-header--side .hs-menu-vertical .hs-sub-menu .hs-sub-menu, .u-body--header-side-push-right .u-header--side .hs-menu-vertical .hs-mega-menu .hs-mega-menu,
.u-body--header-side-push-right .u-header--side .hs-menu-vertical .hs-mega-menu .hs-sub-menu,
.u-body--header-side-push-right .u-header--side .hs-menu-vertical .hs-sub-menu .hs-mega-menu,
.u-body--header-side-push-right .u-header--side .hs-menu-vertical .hs-sub-menu .hs-sub-menu {
  margin-right: 0;
}

.u-body--header-side-static-right .u-header--side .hs-menu-vertical.hs-rtl .hs-mega-menu,
.u-body--header-side-static-right .u-header--side .hs-menu-vertical.hs-rtl .hs-sub-menu, .u-body--header-side-overlay-right .u-header--side .hs-menu-vertical.hs-rtl .hs-mega-menu,
.u-body--header-side-overlay-right .u-header--side .hs-menu-vertical.hs-rtl .hs-sub-menu, .u-body--header-side-push-right .u-header--side .hs-menu-vertical.hs-rtl .hs-mega-menu,
.u-body--header-side-push-right .u-header--side .hs-menu-vertical.hs-rtl .hs-sub-menu {
  margin-right: 0;
  margin-left: 1.07143rem;
}

.u-body--header-side-static-right .u-header--side .hs-menu-vertical.hs-rtl .hs-mega-menu .hs-mega-menu,
.u-body--header-side-static-right .u-header--side .hs-menu-vertical.hs-rtl .hs-mega-menu .hs-sub-menu,
.u-body--header-side-static-right .u-header--side .hs-menu-vertical.hs-rtl .hs-sub-menu .hs-mega-menu,
.u-body--header-side-static-right .u-header--side .hs-menu-vertical.hs-rtl .hs-sub-menu .hs-sub-menu, .u-body--header-side-overlay-right .u-header--side .hs-menu-vertical.hs-rtl .hs-mega-menu .hs-mega-menu,
.u-body--header-side-overlay-right .u-header--side .hs-menu-vertical.hs-rtl .hs-mega-menu .hs-sub-menu,
.u-body--header-side-overlay-right .u-header--side .hs-menu-vertical.hs-rtl .hs-sub-menu .hs-mega-menu,
.u-body--header-side-overlay-right .u-header--side .hs-menu-vertical.hs-rtl .hs-sub-menu .hs-sub-menu, .u-body--header-side-push-right .u-header--side .hs-menu-vertical.hs-rtl .hs-mega-menu .hs-mega-menu,
.u-body--header-side-push-right .u-header--side .hs-menu-vertical.hs-rtl .hs-mega-menu .hs-sub-menu,
.u-body--header-side-push-right .u-header--side .hs-menu-vertical.hs-rtl .hs-sub-menu .hs-mega-menu,
.u-body--header-side-push-right .u-header--side .hs-menu-vertical.hs-rtl .hs-sub-menu .hs-sub-menu {
  margin-left: 0;
  margin-right: 0;
}

/* Button Styles
------------------------------------*/
/* General Button Styles */
.btn {
  position: relative;
  transition: .2s ease;
  cursor: pointer;
}

.btn:focus, .btn:active:focus, .btn.active:focus {
  outline: 0 none;
  box-shadow: none;
}

/* Button Content
------------------------------------*/
.u-btn-content {
  white-space: normal;
}

/* Buttons Only Icon (O)
------------------------------------*/
.u-btn-only-icon {
  position: relative;
}

.u-btn-only-icon i {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translateX(-50%) translateY(-50%);
      transform: translateX(-50%) translateY(-50%);
}

/* Button Sizes
------------------------------------*/
/* Extra Small */
.btn-xs {
  line-height: 1.4;
  padding: 0.14286rem 0.5rem;
  font-size: 0.78571rem;
}

/* Medium */
.btn-md {
  line-height: 1.4;
  padding: 0.57143rem 1.42857rem;
  font-size: 1.07143rem;
}

/* Extra Large */
.btn-xl {
  line-height: 1.4;
  padding: 0.92857rem 1.85714rem;
  font-size: 1.28571rem;
}

/* Extramly Large */
.btn-xxl {
  line-height: 1.4;
  padding: 1.07143rem 2.14286rem;
  font-size: 1.5rem;
}

/* Button Types
------------------------------------*/
/* Inset Buttons */
.u-btn-inset {
  position: relative;
}

.u-btn-inset::before {
  position: absolute;
  top: 0.14286rem;
  right: 0.14286rem;
  bottom: 0.14286rem;
  left: 0.14286rem;
  content: "";
  border: solid 1px #fff;
  border-radius: 1px;
}

.u-btn-inset--rounded::before {
  border-radius: 50px;
}

/* 3d Buttons */
.u-btn-3d {
  border-bottom: solid 3px rgba(0, 0, 0, 0.2);
}

.u-btn-3d:hover {
  border-bottom-color: rgba(0, 0, 0, 0.3);
}

/* Skew Button */
.u-btn-skew {
  -ms-transform: skewX(-20deg);
      transform: skewX(-20deg);
}

.u-btn-skew__inner {
  -ms-transform: skewX(20deg);
      transform: skewX(20deg);
  display: block;
}

/* Button Hovers
------------------------------------*/
[class*="u-btn-hover"] {
  z-index: 1;
}

[class*="u-btn-hover"]:hover {
  text-decoration: none;
}

[class*="u-btn-hover"]:focus {
  text-decoration: none;
}

[class*="u-btn-hover"]::after {
  position: absolute;
  content: "";
  z-index: -1;
  transition: all .3s;
}

/* Hover v1-1 */
.u-btn-hover-v1-1::after {
  top: 0;
  left: 0;
  height: 0;
  width: 100%;
}

.u-btn-hover-v1-1:hover::after {
  height: 100%;
}

/* Hover v1-2 */
.u-btn-hover-v1-2::after {
  top: 0;
  right: 0;
  width: 0;
  height: 100%;
}

.u-btn-hover-v1-2:hover::after {
  width: 100%;
}

/* Hover v1-3 */
.u-btn-hover-v1-3::after {
  bottom: 0;
  left: 0;
  height: 0;
  width: 100%;
}

.u-btn-hover-v1-3:hover::after {
  height: 100%;
}

/* Hover v1-4 */
.u-btn-hover-v1-4::after {
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
}

.u-btn-hover-v1-4:hover::after {
  width: 100%;
}

/* Hover v2-1 */
.u-btn-hover-v2-1::after {
  top: 0;
  right: 0;
  width: 0;
  height: 100%;
}

.u-btn-hover-v2-1:hover::after {
  left: 0;
  width: 100%;
}

/* Hover v2-2 */
.u-btn-hover-v2-2::after {
  left: 0;
  bottom: 0;
  height: 0;
  width: 100%;
}

.u-btn-hover-v2-2:hover::after {
  top: 0;
  height: 100%;
}

/* Button Primary */
.u-btn-primary {
  color: #fff;
  background-color: #226f1c;
}

.u-btn-primary:hover, .u-btn-primary.active {
  border-color: #2e9826;
  background-color: #2e9826;
}

.u-btn-primary:hover, .u-btn-primary:focus, .u-btn-primary.active {
  color: #fff;
}

.u-btn-primary.g-btn-hover-reset:hover, .u-btn-primary.g-btn-hover-reset.active {
  background-color: #226f1c;
  border-color: #226f1c;
}

.u-btn-primary.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-primary.u-btn-hover-v1-1::after, .u-btn-primary.u-btn-hover-v1-1:hover::after {
  background-color: #288321;
}

.u-btn-primary.u-btn-hover-v1-1:hover {
  background-color: #226f1c;
  overflow: hidden;
}

.u-btn-primary.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-primary.u-btn-hover-v1-2::after, .u-btn-primary.u-btn-hover-v1-2:hover::after {
  background-color: #288321;
}

.u-btn-primary.u-btn-hover-v1-2:hover {
  background-color: #226f1c;
  overflow: hidden;
}

.u-btn-primary.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-primary.u-btn-hover-v1-3::after, .u-btn-primary.u-btn-hover-v1-3:hover::after {
  background-color: #288321;
}

.u-btn-primary.u-btn-hover-v1-3:hover {
  background-color: #226f1c;
  overflow: hidden;
}

.u-btn-primary.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-primary.u-btn-hover-v1-4::after, .u-btn-primary.u-btn-hover-v1-4:hover::after {
  background-color: #288321;
}

.u-btn-primary.u-btn-hover-v1-4:hover {
  background-color: #226f1c;
  overflow: hidden;
}

.u-btn-primary.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-primary.u-btn-hover-v2-1::after, .u-btn-primary.u-btn-hover-v2-1:hover::after {
  background-color: #288321;
}

.u-btn-primary.u-btn-hover-v2-1:hover {
  background-color: #226f1c;
  overflow: hidden;
}

.u-btn-primary.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-primary.u-btn-hover-v2-2::after, .u-btn-primary.u-btn-hover-v2-2:hover::after {
  background-color: #288321;
}

.u-btn-primary.u-btn-hover-v2-2:hover {
  background-color: #226f1c;
  overflow: hidden;
}

/* Button White */
.u-btn-white {
  color: #555;
  background-color: #fff;
}

.u-btn-white:hover, .u-btn-white.active {
  border-color: white;
  background-color: white;
}

.u-btn-white:hover, .u-btn-white:focus, .u-btn-white.active {
  color: #555;
}

.u-btn-white.g-btn-hover-reset:hover, .u-btn-white.g-btn-hover-reset.active {
  background-color: #fff;
  border-color: #fff;
}

.u-btn-white.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-white.u-btn-hover-v1-1::after, .u-btn-white.u-btn-hover-v1-1:hover::after {
  background-color: white;
}

.u-btn-white.u-btn-hover-v1-1:hover {
  background-color: #fff;
  overflow: hidden;
}

.u-btn-white.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-white.u-btn-hover-v1-2::after, .u-btn-white.u-btn-hover-v1-2:hover::after {
  background-color: white;
}

.u-btn-white.u-btn-hover-v1-2:hover {
  background-color: #fff;
  overflow: hidden;
}

.u-btn-white.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-white.u-btn-hover-v1-3::after, .u-btn-white.u-btn-hover-v1-3:hover::after {
  background-color: white;
}

.u-btn-white.u-btn-hover-v1-3:hover {
  background-color: #fff;
  overflow: hidden;
}

.u-btn-white.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-white.u-btn-hover-v1-4::after, .u-btn-white.u-btn-hover-v1-4:hover::after {
  background-color: white;
}

.u-btn-white.u-btn-hover-v1-4:hover {
  background-color: #fff;
  overflow: hidden;
}

.u-btn-white.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-white.u-btn-hover-v2-1::after, .u-btn-white.u-btn-hover-v2-1:hover::after {
  background-color: white;
}

.u-btn-white.u-btn-hover-v2-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-white.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-white.u-btn-hover-v2-2::after, .u-btn-white.u-btn-hover-v2-2:hover::after {
  background-color: white;
}

.u-btn-white.u-btn-hover-v2-2:hover {
  overflow: hidden;
  background-color: transparent;
}

/* Button Black */
.u-btn-black {
  color: #fff;
  background-color: #000;
}

.u-btn-black:hover, .u-btn-black.active {
  border-color: #1a1a1a;
  background-color: #1a1a1a;
}

.u-btn-black:hover, .u-btn-black:focus, .u-btn-black.active {
  color: #fff;
}

.u-btn-black.g-btn-hover-reset:hover, .u-btn-black.g-btn-hover-reset.active {
  background-color: #000;
  border-color: #000;
}

.u-btn-black.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-black.u-btn-hover-v1-1::after, .u-btn-black.u-btn-hover-v1-1:hover::after {
  background-color: #0d0d0d;
}

.u-btn-black.u-btn-hover-v1-1:hover {
  background-color: #000;
  overflow: hidden;
}

.u-btn-black.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-black.u-btn-hover-v1-2::after, .u-btn-black.u-btn-hover-v1-2:hover::after {
  background-color: #0d0d0d;
}

.u-btn-black.u-btn-hover-v1-2:hover {
  background-color: #000;
  overflow: hidden;
}

.u-btn-black.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-black.u-btn-hover-v1-3::after, .u-btn-black.u-btn-hover-v1-3:hover::after {
  background-color: #0d0d0d;
}

.u-btn-black.u-btn-hover-v1-3:hover {
  background-color: #000;
  overflow: hidden;
}

.u-btn-black.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-black.u-btn-hover-v1-4::after, .u-btn-black.u-btn-hover-v1-4:hover::after {
  background-color: #0d0d0d;
}

.u-btn-black.u-btn-hover-v1-4:hover {
  background-color: #000;
  overflow: hidden;
}

.u-btn-black.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-black.u-btn-hover-v2-1::after, .u-btn-black.u-btn-hover-v2-1:hover::after {
  background-color: #0d0d0d;
}

.u-btn-black.u-btn-hover-v2-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-black.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-black.u-btn-hover-v2-2::after, .u-btn-black.u-btn-hover-v2-2:hover::after {
  background-color: #0d0d0d;
}

.u-btn-black.u-btn-hover-v2-2:hover {
  overflow: hidden;
  background-color: transparent;
}

/* Button Dark Gray */
.u-btn-darkgray {
  color: #fff;
  background-color: #333;
}

.u-btn-darkgray:hover, .u-btn-darkgray.active {
  border-color: #4d4d4d;
  background-color: #4d4d4d;
}

.u-btn-darkgray:hover, .u-btn-darkgray:focus, .u-btn-darkgray.active {
  color: #fff;
}

.u-btn-darkgray.g-btn-hover-reset:hover, .u-btn-darkgray.g-btn-hover-reset.active {
  background-color: #333;
  border-color: #333;
}

.u-btn-darkgray.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-darkgray.u-btn-hover-v1-1::after, .u-btn-darkgray.u-btn-hover-v1-1:hover::after {
  background-color: #404040;
}

.u-btn-darkgray.u-btn-hover-v1-1:hover {
  background-color: #333;
  overflow: hidden;
}

.u-btn-darkgray.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-darkgray.u-btn-hover-v1-2::after, .u-btn-darkgray.u-btn-hover-v1-2:hover::after {
  background-color: #404040;
}

.u-btn-darkgray.u-btn-hover-v1-2:hover {
  background-color: #333;
  overflow: hidden;
}

.u-btn-darkgray.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-darkgray.u-btn-hover-v1-3::after, .u-btn-darkgray.u-btn-hover-v1-3:hover::after {
  background-color: #404040;
}

.u-btn-darkgray.u-btn-hover-v1-3:hover {
  background-color: #333;
  overflow: hidden;
}

.u-btn-darkgray.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-darkgray.u-btn-hover-v1-4::after, .u-btn-darkgray.u-btn-hover-v1-4:hover::after {
  background-color: #404040;
}

.u-btn-darkgray.u-btn-hover-v1-4:hover {
  background-color: #333;
  overflow: hidden;
}

.u-btn-darkgray.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-darkgray.u-btn-hover-v2-1::after, .u-btn-darkgray.u-btn-hover-v2-1:hover::after {
  background-color: #404040;
}

.u-btn-darkgray.u-btn-hover-v2-1:hover {
  background-color: #333;
  overflow: hidden;
}

.u-btn-darkgray.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-darkgray.u-btn-hover-v2-2::after, .u-btn-darkgray.u-btn-hover-v2-2:hover::after {
  background-color: #404040;
}

.u-btn-darkgray.u-btn-hover-v2-2:hover {
  background-color: #333;
  overflow: hidden;
}

/* Button Red */
.u-btn-red {
  color: #fff;
  background-color: #f00;
}

.u-btn-red:hover, .u-btn-red.active {
  border-color: #ff3333;
  background-color: #ff3333;
}

.u-btn-red:hover, .u-btn-red:focus, .u-btn-red.active {
  color: #fff;
}

.u-btn-red.g-btn-hover-reset:hover, .u-btn-red.g-btn-hover-reset.active {
  background-color: #f00;
  border-color: #f00;
}

.u-btn-red.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-red.u-btn-hover-v1-1::after, .u-btn-red.u-btn-hover-v1-1:hover::after {
  background-color: #ff1a1a;
}

.u-btn-red.u-btn-hover-v1-1:hover {
  background-color: #f00;
  overflow: hidden;
}

.u-btn-red.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-red.u-btn-hover-v1-2::after, .u-btn-red.u-btn-hover-v1-2:hover::after {
  background-color: #ff1a1a;
}

.u-btn-red.u-btn-hover-v1-2:hover {
  background-color: #f00;
  overflow: hidden;
}

.u-btn-red.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-red.u-btn-hover-v1-3::after, .u-btn-red.u-btn-hover-v1-3:hover::after {
  background-color: #ff1a1a;
}

.u-btn-red.u-btn-hover-v1-3:hover {
  background-color: #f00;
  overflow: hidden;
}

.u-btn-red.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-red.u-btn-hover-v1-4::after, .u-btn-red.u-btn-hover-v1-4:hover::after {
  background-color: #ff1a1a;
}

.u-btn-red.u-btn-hover-v1-4:hover {
  background-color: #f00;
  overflow: hidden;
}

.u-btn-red.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-red.u-btn-hover-v2-1::after, .u-btn-red.u-btn-hover-v2-1:hover::after {
  background-color: #ff1a1a;
}

.u-btn-red.u-btn-hover-v2-1:hover {
  background-color: #f00;
  overflow: hidden;
}

.u-btn-red.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-red.u-btn-hover-v2-2::after, .u-btn-red.u-btn-hover-v2-2:hover::after {
  background-color: #ff1a1a;
}

.u-btn-red.u-btn-hover-v2-2:hover {
  background-color: #f00;
  overflow: hidden;
}

/* Button Red Tomato */
.u-btn-lightred {
  color: #fff;
  background-color: #e64b3b;
}

.u-btn-lightred:hover, .u-btn-lightred.active {
  border-color: #ec7568;
  background-color: #ec7568;
}

.u-btn-lightred:hover, .u-btn-lightred:focus, .u-btn-lightred.active {
  color: #fff;
}

.u-btn-lightred.g-btn-hover-reset:hover, .u-btn-lightred.g-btn-hover-reset.active {
  background-color: #e64b3b;
  border-color: #e64b3b;
}

.u-btn-lightred.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-lightred.u-btn-hover-v1-1::after, .u-btn-lightred.u-btn-hover-v1-1:hover::after {
  background-color: #e96052;
}

.u-btn-lightred.u-btn-hover-v1-1:hover {
  background-color: #e64b3b;
  overflow: hidden;
}

.u-btn-lightred.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-lightred.u-btn-hover-v1-2::after, .u-btn-lightred.u-btn-hover-v1-2:hover::after {
  background-color: #e96052;
}

.u-btn-lightred.u-btn-hover-v1-2:hover {
  background-color: #e64b3b;
  overflow: hidden;
}

.u-btn-lightred.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-lightred.u-btn-hover-v1-3::after, .u-btn-lightred.u-btn-hover-v1-3:hover::after {
  background-color: #e96052;
}

.u-btn-lightred.u-btn-hover-v1-3:hover {
  background-color: #e64b3b;
  overflow: hidden;
}

.u-btn-lightred.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-lightred.u-btn-hover-v1-4::after, .u-btn-lightred.u-btn-hover-v1-4:hover::after {
  background-color: #e96052;
}

.u-btn-lightred.u-btn-hover-v1-4:hover {
  background-color: #e64b3b;
  overflow: hidden;
}

.u-btn-lightred.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-lightred.u-btn-hover-v2-1::after, .u-btn-lightred.u-btn-hover-v2-1:hover::after {
  background-color: #e96052;
}

.u-btn-lightred.u-btn-hover-v2-1:hover {
  background-color: #e64b3b;
  overflow: hidden;
}

.u-btn-lightred.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-lightred.u-btn-hover-v2-2::after, .u-btn-lightred.u-btn-hover-v2-2:hover::after {
  background-color: #e96052;
}

.u-btn-lightred.u-btn-hover-v2-2:hover {
  background-color: #e64b3b;
  overflow: hidden;
}

/* Button Dark Red */
.u-btn-darkred {
  color: #fff;
  background-color: #a10f2b;
}

.u-btn-darkred:hover, .u-btn-darkred.active {
  border-color: #d01337;
  background-color: #d01337;
}

.u-btn-darkred:hover, .u-btn-darkred:focus, .u-btn-darkred.active {
  color: #fff;
}

.u-btn-darkred.g-btn-hover-reset:hover, .u-btn-darkred.g-btn-hover-reset.active {
  background-color: #a10f2b;
  border-color: #a10f2b;
}

.u-btn-darkred.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-darkred.u-btn-hover-v1-1::after, .u-btn-darkred.u-btn-hover-v1-1:hover::after {
  background-color: #b81131;
}

.u-btn-darkred.u-btn-hover-v1-1:hover {
  background-color: #a10f2b;
  overflow: hidden;
}

.u-btn-darkred.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-darkred.u-btn-hover-v1-2::after, .u-btn-darkred.u-btn-hover-v1-2:hover::after {
  background-color: #b81131;
}

.u-btn-darkred.u-btn-hover-v1-2:hover {
  background-color: #a10f2b;
  overflow: hidden;
}

.u-btn-darkred.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-darkred.u-btn-hover-v1-3::after, .u-btn-darkred.u-btn-hover-v1-3:hover::after {
  background-color: #b81131;
}

.u-btn-darkred.u-btn-hover-v1-3:hover {
  background-color: #a10f2b;
  overflow: hidden;
}

.u-btn-darkred.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-darkred.u-btn-hover-v1-4::after, .u-btn-darkred.u-btn-hover-v1-4:hover::after {
  background-color: #b81131;
}

.u-btn-darkred.u-btn-hover-v1-4:hover {
  background-color: #a10f2b;
  overflow: hidden;
}

.u-btn-darkred.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-darkred.u-btn-hover-v2-1::after, .u-btn-darkred.u-btn-hover-v2-1:hover::after {
  background-color: #b81131;
}

.u-btn-darkred.u-btn-hover-v2-1:hover {
  background-color: #a10f2b;
  overflow: hidden;
}

.u-btn-darkred.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-darkred.u-btn-hover-v2-2::after, .u-btn-darkred.u-btn-hover-v2-2:hover::after {
  background-color: #b81131;
}

.u-btn-darkred.u-btn-hover-v2-2:hover {
  background-color: #a10f2b;
  overflow: hidden;
}

/* Button Blue */
.u-btn-blue {
  color: #fff;
  background-color: #3398dc;
}

.u-btn-blue:hover, .u-btn-blue.active {
  border-color: #5faee3;
  background-color: #5faee3;
}

.u-btn-blue:hover, .u-btn-blue:focus, .u-btn-blue.active {
  color: #fff;
}

.u-btn-blue.g-btn-hover-reset:hover, .u-btn-blue.g-btn-hover-reset.active {
  background-color: #3398dc;
  border-color: #3398dc;
}

.u-btn-blue.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-blue.u-btn-hover-v1-1::after, .u-btn-blue.u-btn-hover-v1-1:hover::after {
  background-color: #49a3e0;
}

.u-btn-blue.u-btn-hover-v1-1:hover {
  background-color: #3398dc;
  overflow: hidden;
}

.u-btn-blue.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-blue.u-btn-hover-v1-2::after, .u-btn-blue.u-btn-hover-v1-2:hover::after {
  background-color: #49a3e0;
}

.u-btn-blue.u-btn-hover-v1-2:hover {
  background-color: #3398dc;
  overflow: hidden;
}

.u-btn-blue.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-blue.u-btn-hover-v1-3::after, .u-btn-blue.u-btn-hover-v1-3:hover::after {
  background-color: #49a3e0;
}

.u-btn-blue.u-btn-hover-v1-3:hover {
  background-color: #3398dc;
  overflow: hidden;
}

.u-btn-blue.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-blue.u-btn-hover-v1-4::after, .u-btn-blue.u-btn-hover-v1-4:hover::after {
  background-color: #49a3e0;
}

.u-btn-blue.u-btn-hover-v1-4:hover {
  background-color: #3398dc;
  overflow: hidden;
}

.u-btn-blue.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-blue.u-btn-hover-v2-1::after, .u-btn-blue.u-btn-hover-v2-1:hover::after {
  background-color: #49a3e0;
}

.u-btn-blue.u-btn-hover-v2-1:hover {
  background-color: #3398dc;
  overflow: hidden;
}

.u-btn-blue.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-blue.u-btn-hover-v2-2::after, .u-btn-blue.u-btn-hover-v2-2:hover::after {
  background-color: #49a3e0;
}

.u-btn-blue.u-btn-hover-v2-2:hover {
  background-color: #3398dc;
  overflow: hidden;
}

/* Button Indigo */
.u-btn-indigo {
  color: #fff;
  background-color: #4263a3;
}

.u-btn-indigo:hover, .u-btn-indigo.active {
  border-color: #5b7cbd;
  background-color: #5b7cbd;
}

.u-btn-indigo:hover, .u-btn-indigo:focus, .u-btn-indigo.active {
  color: #fff;
}

.u-btn-indigo.g-btn-hover-reset:hover, .u-btn-indigo.g-btn-hover-reset.active {
  background-color: #4263a3;
  border-color: #4263a3;
}

.u-btn-indigo.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-indigo.u-btn-hover-v1-1::after, .u-btn-indigo.u-btn-hover-v1-1:hover::after {
  background-color: #496eb5;
}

.u-btn-indigo.u-btn-hover-v1-1:hover {
  background-color: #4263a3;
  overflow: hidden;
}

.u-btn-indigo.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-indigo.u-btn-hover-v1-2::after, .u-btn-indigo.u-btn-hover-v1-2:hover::after {
  background-color: #496eb5;
}

.u-btn-indigo.u-btn-hover-v1-2:hover {
  background-color: #4263a3;
  overflow: hidden;
}

.u-btn-indigo.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-indigo.u-btn-hover-v1-3::after, .u-btn-indigo.u-btn-hover-v1-3:hover::after {
  background-color: #496eb5;
}

.u-btn-indigo.u-btn-hover-v1-3:hover {
  background-color: #4263a3;
  overflow: hidden;
}

.u-btn-indigo.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-indigo.u-btn-hover-v1-4::after, .u-btn-indigo.u-btn-hover-v1-4:hover::after {
  background-color: #496eb5;
}

.u-btn-indigo.u-btn-hover-v1-4:hover {
  background-color: #4263a3;
  overflow: hidden;
}

.u-btn-indigo.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-indigo.u-btn-hover-v2-1::after, .u-btn-indigo.u-btn-hover-v2-1:hover::after {
  background-color: #496eb5;
}

.u-btn-indigo.u-btn-hover-v2-1:hover {
  background-color: #4263a3;
  overflow: hidden;
}

.u-btn-indigo.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-indigo.u-btn-hover-v2-2::after, .u-btn-indigo.u-btn-hover-v2-2:hover::after {
  background-color: #496eb5;
}

.u-btn-indigo.u-btn-hover-v2-2:hover {
  background-color: #4263a3;
  overflow: hidden;
}

/* Button Purple */
.u-btn-purple {
  color: #fff;
  background-color: #9a69cb;
}

.u-btn-purple:hover, .u-btn-purple.active {
  border-color: #b48fd8;
  background-color: #b48fd8;
}

.u-btn-purple:hover, .u-btn-purple:focus, .u-btn-purple.active {
  color: #fff;
}

.u-btn-purple.g-btn-hover-reset:hover, .u-btn-purple.g-btn-hover-reset.active {
  background-color: #9a69cb;
  border-color: #9a69cb;
}

.u-btn-purple.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-purple.u-btn-hover-v1-1::after, .u-btn-purple.u-btn-hover-v1-1:hover::after {
  background-color: #a77cd2;
}

.u-btn-purple.u-btn-hover-v1-1:hover {
  background-color: #9a69cb;
  overflow: hidden;
}

.u-btn-purple.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-purple.u-btn-hover-v1-2::after, .u-btn-purple.u-btn-hover-v1-2:hover::after {
  background-color: #a77cd2;
}

.u-btn-purple.u-btn-hover-v1-2:hover {
  background-color: #9a69cb;
  overflow: hidden;
}

.u-btn-purple.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-purple.u-btn-hover-v1-3::after, .u-btn-purple.u-btn-hover-v1-3:hover::after {
  background-color: #a77cd2;
}

.u-btn-purple.u-btn-hover-v1-3:hover {
  background-color: #9a69cb;
  overflow: hidden;
}

.u-btn-purple.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-purple.u-btn-hover-v1-4::after, .u-btn-purple.u-btn-hover-v1-4:hover::after {
  background-color: #a77cd2;
}

.u-btn-purple.u-btn-hover-v1-4:hover {
  background-color: #9a69cb;
  overflow: hidden;
}

.u-btn-purple.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-purple.u-btn-hover-v2-1::after, .u-btn-purple.u-btn-hover-v2-1:hover::after {
  background-color: #a77cd2;
}

.u-btn-purple.u-btn-hover-v2-1:hover {
  background-color: #9a69cb;
  overflow: hidden;
}

.u-btn-purple.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-purple.u-btn-hover-v2-2::after, .u-btn-purple.u-btn-hover-v2-2:hover::after {
  background-color: #a77cd2;
}

.u-btn-purple.u-btn-hover-v2-2:hover {
  background-color: #9a69cb;
  overflow: hidden;
}

/* Button Dark Purple */
.u-btn-darkpurple {
  color: #fff;
  background-color: #6639b6;
}

.u-btn-darkpurple:hover, .u-btn-darkpurple.active {
  border-color: #8157cb;
  background-color: #8157cb;
}

.u-btn-darkpurple:hover, .u-btn-darkpurple:focus, .u-btn-darkpurple.active {
  color: #fff;
}

.u-btn-darkpurple.g-btn-hover-reset:hover, .u-btn-darkpurple.g-btn-hover-reset.active {
  background-color: #6639b6;
  border-color: #6639b6;
}

.u-btn-darkpurple.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-darkpurple.u-btn-hover-v1-1::after, .u-btn-darkpurple.u-btn-hover-v1-1:hover::after {
  background-color: #7244c4;
}

.u-btn-darkpurple.u-btn-hover-v1-1:hover {
  background-color: #6639b6;
  overflow: hidden;
}

.u-btn-darkpurple.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-darkpurple.u-btn-hover-v1-2::after, .u-btn-darkpurple.u-btn-hover-v1-2:hover::after {
  background-color: #7244c4;
}

.u-btn-darkpurple.u-btn-hover-v1-2:hover {
  background-color: #6639b6;
  overflow: hidden;
}

.u-btn-darkpurple.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-darkpurple.u-btn-hover-v1-3::after, .u-btn-darkpurple.u-btn-hover-v1-3:hover::after {
  background-color: #7244c4;
}

.u-btn-darkpurple.u-btn-hover-v1-3:hover {
  background-color: #6639b6;
  overflow: hidden;
}

.u-btn-darkpurple.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-darkpurple.u-btn-hover-v1-4::after, .u-btn-darkpurple.u-btn-hover-v1-4:hover::after {
  background-color: #7244c4;
}

.u-btn-darkpurple.u-btn-hover-v1-4:hover {
  background-color: #6639b6;
  overflow: hidden;
}

.u-btn-darkpurple.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-darkpurple.u-btn-hover-v2-1::after, .u-btn-darkpurple.u-btn-hover-v2-1:hover::after {
  background-color: #7244c4;
}

.u-btn-darkpurple.u-btn-hover-v2-1:hover {
  background-color: #6639b6;
  overflow: hidden;
}

.u-btn-darkpurple.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-darkpurple.u-btn-hover-v2-2::after, .u-btn-darkpurple.u-btn-hover-v2-2:hover::after {
  background-color: #7244c4;
}

.u-btn-darkpurple.u-btn-hover-v2-2:hover {
  background-color: #6639b6;
  overflow: hidden;
}

/* Button Pink */
.u-btn-pink {
  color: #fff;
  background-color: #e81c62;
}

.u-btn-pink:hover, .u-btn-pink.active {
  border-color: #ed4a82;
  background-color: #ed4a82;
}

.u-btn-pink:hover, .u-btn-pink:focus, .u-btn-pink.active {
  color: #fff;
}

.u-btn-pink.g-btn-hover-reset:hover, .u-btn-pink.g-btn-hover-reset.active {
  background-color: #e81c62;
  border-color: #e81c62;
}

.u-btn-pink.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-pink.u-btn-hover-v1-1::after, .u-btn-pink.u-btn-hover-v1-1:hover::after {
  background-color: #ea3372;
}

.u-btn-pink.u-btn-hover-v1-1:hover {
  background-color: #e81c62;
  overflow: hidden;
}

.u-btn-pink.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-pink.u-btn-hover-v1-2::after, .u-btn-pink.u-btn-hover-v1-2:hover::after {
  background-color: #ea3372;
}

.u-btn-pink.u-btn-hover-v1-2:hover {
  background-color: #e81c62;
  overflow: hidden;
}

.u-btn-pink.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-pink.u-btn-hover-v1-3::after, .u-btn-pink.u-btn-hover-v1-3:hover::after {
  background-color: #ea3372;
}

.u-btn-pink.u-btn-hover-v1-3:hover {
  background-color: #e81c62;
  overflow: hidden;
}

.u-btn-pink.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-pink.u-btn-hover-v1-4::after, .u-btn-pink.u-btn-hover-v1-4:hover::after {
  background-color: #ea3372;
}

.u-btn-pink.u-btn-hover-v1-4:hover {
  background-color: #e81c62;
  overflow: hidden;
}

.u-btn-pink.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-pink.u-btn-hover-v2-1::after, .u-btn-pink.u-btn-hover-v2-1:hover::after {
  background-color: #ea3372;
}

.u-btn-pink.u-btn-hover-v2-1:hover {
  background-color: #e81c62;
  overflow: hidden;
}

.u-btn-pink.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-pink.u-btn-hover-v2-2::after, .u-btn-pink.u-btn-hover-v2-2:hover::after {
  background-color: #ea3372;
}

.u-btn-pink.u-btn-hover-v2-2:hover {
  background-color: #e81c62;
  overflow: hidden;
}

/* Button Orange */
.u-btn-orange {
  color: #fff;
  background-color: #e57d20;
}

.u-btn-orange:hover, .u-btn-orange.active {
  border-color: #ea984e;
  background-color: #ea984e;
}

.u-btn-orange:hover, .u-btn-orange:focus, .u-btn-orange.active {
  color: #fff;
}

.u-btn-orange.g-btn-hover-reset:hover, .u-btn-orange.g-btn-hover-reset.active {
  background-color: #e57d20;
  border-color: #e57d20;
}

.u-btn-orange.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-orange.u-btn-hover-v1-1::after, .u-btn-orange.u-btn-hover-v1-1:hover::after {
  background-color: #e88a37;
}

.u-btn-orange.u-btn-hover-v1-1:hover {
  background-color: #e57d20;
  overflow: hidden;
}

.u-btn-orange.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-orange.u-btn-hover-v1-2::after, .u-btn-orange.u-btn-hover-v1-2:hover::after {
  background-color: #e88a37;
}

.u-btn-orange.u-btn-hover-v1-2:hover {
  background-color: #e57d20;
  overflow: hidden;
}

.u-btn-orange.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-orange.u-btn-hover-v1-3::after, .u-btn-orange.u-btn-hover-v1-3:hover::after {
  background-color: #e88a37;
}

.u-btn-orange.u-btn-hover-v1-3:hover {
  background-color: #e57d20;
  overflow: hidden;
}

.u-btn-orange.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-orange.u-btn-hover-v1-4::after, .u-btn-orange.u-btn-hover-v1-4:hover::after {
  background-color: #e88a37;
}

.u-btn-orange.u-btn-hover-v1-4:hover {
  background-color: #e57d20;
  overflow: hidden;
}

.u-btn-orange.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-orange.u-btn-hover-v2-1::after, .u-btn-orange.u-btn-hover-v2-1:hover::after {
  background-color: #e88a37;
}

.u-btn-orange.u-btn-hover-v2-1:hover {
  background-color: #e57d20;
  overflow: hidden;
}

.u-btn-orange.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-orange.u-btn-hover-v2-2::after, .u-btn-orange.u-btn-hover-v2-2:hover::after {
  background-color: #e88a37;
}

.u-btn-orange.u-btn-hover-v2-2:hover {
  background-color: #e57d20;
  overflow: hidden;
}

/* Button Deep Orange */
.u-btn-deeporange {
  color: #fff;
  background-color: #fe541e;
}

.u-btn-deeporange:hover, .u-btn-deeporange.active {
  border-color: #fe7b51;
  background-color: #fe7b51;
}

.u-btn-deeporange:hover, .u-btn-deeporange:focus, .u-btn-deeporange.active {
  color: #fff;
}

.u-btn-deeporange.g-btn-hover-reset:hover, .u-btn-deeporange.g-btn-hover-reset.active {
  background-color: #fe541e;
  border-color: #fe541e;
}

.u-btn-deeporange.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-deeporange.u-btn-hover-v1-1::after, .u-btn-deeporange.u-btn-hover-v1-1:hover::after {
  background-color: #fe6737;
}

.u-btn-deeporange.u-btn-hover-v1-1:hover {
  background-color: #fe541e;
  overflow: hidden;
}

.u-btn-deeporange.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-deeporange.u-btn-hover-v1-2::after, .u-btn-deeporange.u-btn-hover-v1-2:hover::after {
  background-color: #fe6737;
}

.u-btn-deeporange.u-btn-hover-v1-2:hover {
  background-color: #fe541e;
  overflow: hidden;
}

.u-btn-deeporange.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-deeporange.u-btn-hover-v1-3::after, .u-btn-deeporange.u-btn-hover-v1-3:hover::after {
  background-color: #fe6737;
}

.u-btn-deeporange.u-btn-hover-v1-3:hover {
  background-color: #fe541e;
  overflow: hidden;
}

.u-btn-deeporange.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-deeporange.u-btn-hover-v1-4::after, .u-btn-deeporange.u-btn-hover-v1-4:hover::after {
  background-color: #fe6737;
}

.u-btn-deeporange.u-btn-hover-v1-4:hover {
  background-color: #fe541e;
  overflow: hidden;
}

.u-btn-deeporange.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-deeporange.u-btn-hover-v2-1::after, .u-btn-deeporange.u-btn-hover-v2-1:hover::after {
  background-color: #fe6737;
}

.u-btn-deeporange.u-btn-hover-v2-1:hover {
  background-color: #fe541e;
  overflow: hidden;
}

.u-btn-deeporange.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-deeporange.u-btn-hover-v2-2::after, .u-btn-deeporange.u-btn-hover-v2-2:hover::after {
  background-color: #fe6737;
}

.u-btn-deeporange.u-btn-hover-v2-2:hover {
  background-color: #fe541e;
  overflow: hidden;
}

/* Button Yellow */
.u-btn-yellow {
  color: #fff;
  background-color: #ebc71d;
}

.u-btn-yellow:hover, .u-btn-yellow.active {
  border-color: #efd34c;
  background-color: #efd34c;
}

.u-btn-yellow:hover, .u-btn-yellow:focus, .u-btn-yellow.active {
  color: #fff;
}

.u-btn-yellow.g-btn-hover-reset:hover, .u-btn-yellow.g-btn-hover-reset.active {
  background-color: #ebc71d;
  border-color: #ebc71d;
}

.u-btn-yellow.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-yellow.u-btn-hover-v1-1::after, .u-btn-yellow.u-btn-hover-v1-1:hover::after {
  background-color: #edcd34;
}

.u-btn-yellow.u-btn-hover-v1-1:hover {
  background-color: #ebc71d;
  overflow: hidden;
}

.u-btn-yellow.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-yellow.u-btn-hover-v1-2::after, .u-btn-yellow.u-btn-hover-v1-2:hover::after {
  background-color: #edcd34;
}

.u-btn-yellow.u-btn-hover-v1-2:hover {
  background-color: #ebc71d;
  overflow: hidden;
}

.u-btn-yellow.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-yellow.u-btn-hover-v1-3::after, .u-btn-yellow.u-btn-hover-v1-3:hover::after {
  background-color: #edcd34;
}

.u-btn-yellow.u-btn-hover-v1-3:hover {
  background-color: #ebc71d;
  overflow: hidden;
}

.u-btn-yellow.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-yellow.u-btn-hover-v1-4::after, .u-btn-yellow.u-btn-hover-v1-4:hover::after {
  background-color: #edcd34;
}

.u-btn-yellow.u-btn-hover-v1-4:hover {
  background-color: #ebc71d;
  overflow: hidden;
}

.u-btn-yellow.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-yellow.u-btn-hover-v2-1::after, .u-btn-yellow.u-btn-hover-v2-1:hover::after {
  background-color: #edcd34;
}

.u-btn-yellow.u-btn-hover-v2-1:hover {
  background-color: #ebc71d;
  overflow: hidden;
}

.u-btn-yellow.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-yellow.u-btn-hover-v2-2::after, .u-btn-yellow.u-btn-hover-v2-2:hover::after {
  background-color: #edcd34;
}

.u-btn-yellow.u-btn-hover-v2-2:hover {
  background-color: #ebc71d;
  overflow: hidden;
}

/* Button Aqua */
.u-btn-aqua {
  color: #fff;
  background-color: #29d6e6;
}

.u-btn-aqua:hover, .u-btn-aqua.active {
  border-color: #57dfeb;
  background-color: #57dfeb;
}

.u-btn-aqua:hover, .u-btn-aqua:focus, .u-btn-aqua.active {
  color: #fff;
}

.u-btn-aqua.g-btn-hover-reset:hover, .u-btn-aqua.g-btn-hover-reset.active {
  background-color: #29d6e6;
  border-color: #29d6e6;
}

.u-btn-aqua.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-aqua.u-btn-hover-v1-1::after, .u-btn-aqua.u-btn-hover-v1-1:hover::after {
  background-color: #40dae9;
}

.u-btn-aqua.u-btn-hover-v1-1:hover {
  background-color: #29d6e6;
  overflow: hidden;
}

.u-btn-aqua.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-aqua.u-btn-hover-v1-2::after, .u-btn-aqua.u-btn-hover-v1-2:hover::after {
  background-color: #40dae9;
}

.u-btn-aqua.u-btn-hover-v1-2:hover {
  background-color: #29d6e6;
  overflow: hidden;
}

.u-btn-aqua.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-aqua.u-btn-hover-v1-3::after, .u-btn-aqua.u-btn-hover-v1-3:hover::after {
  background-color: #40dae9;
}

.u-btn-aqua.u-btn-hover-v1-3:hover {
  background-color: #29d6e6;
  overflow: hidden;
}

.u-btn-aqua.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-aqua.u-btn-hover-v1-4::after, .u-btn-aqua.u-btn-hover-v1-4:hover::after {
  background-color: #40dae9;
}

.u-btn-aqua.u-btn-hover-v1-4:hover {
  background-color: #29d6e6;
  overflow: hidden;
}

.u-btn-aqua.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-aqua.u-btn-hover-v2-1::after, .u-btn-aqua.u-btn-hover-v2-1:hover::after {
  background-color: #40dae9;
}

.u-btn-aqua.u-btn-hover-v2-1:hover {
  background-color: #29d6e6;
  overflow: hidden;
}

.u-btn-aqua.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-aqua.u-btn-hover-v2-2::after, .u-btn-aqua.u-btn-hover-v2-2:hover::after {
  background-color: #40dae9;
}

.u-btn-aqua.u-btn-hover-v2-2:hover {
  background-color: #29d6e6;
  overflow: hidden;
}

/* Button Cyan */
.u-btn-cyan {
  color: #fff;
  background-color: #00bed6;
}

.u-btn-cyan:hover, .u-btn-cyan.active {
  border-color: #0ae4ff;
  background-color: #0ae4ff;
}

.u-btn-cyan:hover, .u-btn-cyan:focus, .u-btn-cyan.active {
  color: #fff;
}

.u-btn-cyan.g-btn-hover-reset:hover, .u-btn-cyan.g-btn-hover-reset.active {
  background-color: #00bed6;
  border-color: #00bed6;
}

.u-btn-cyan.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-cyan.u-btn-hover-v1-1::after, .u-btn-cyan.u-btn-hover-v1-1:hover::after {
  background-color: #00d5f0;
}

.u-btn-cyan.u-btn-hover-v1-1:hover {
  background-color: #00bed6;
  overflow: hidden;
}

.u-btn-cyan.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-cyan.u-btn-hover-v1-2::after, .u-btn-cyan.u-btn-hover-v1-2:hover::after {
  background-color: #00d5f0;
}

.u-btn-cyan.u-btn-hover-v1-2:hover {
  background-color: #00bed6;
  overflow: hidden;
}

.u-btn-cyan.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-cyan.u-btn-hover-v1-3::after, .u-btn-cyan.u-btn-hover-v1-3:hover::after {
  background-color: #00d5f0;
}

.u-btn-cyan.u-btn-hover-v1-3:hover {
  background-color: #00bed6;
  overflow: hidden;
}

.u-btn-cyan.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-cyan.u-btn-hover-v1-4::after, .u-btn-cyan.u-btn-hover-v1-4:hover::after {
  background-color: #00d5f0;
}

.u-btn-cyan.u-btn-hover-v1-4:hover {
  background-color: #00bed6;
  overflow: hidden;
}

.u-btn-cyan.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-cyan.u-btn-hover-v2-1::after, .u-btn-cyan.u-btn-hover-v2-1:hover::after {
  background-color: #00d5f0;
}

.u-btn-cyan.u-btn-hover-v2-1:hover {
  background-color: #00bed6;
  overflow: hidden;
}

.u-btn-cyan.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-cyan.u-btn-hover-v2-2::after, .u-btn-cyan.u-btn-hover-v2-2:hover::after {
  background-color: #00d5f0;
}

.u-btn-cyan.u-btn-hover-v2-2:hover {
  background-color: #00bed6;
  overflow: hidden;
}

/* Button Teal */
.u-btn-teal {
  color: #fff;
  background-color: #18ba9b;
}

.u-btn-teal:hover, .u-btn-teal.active {
  border-color: #22e3be;
  background-color: #22e3be;
}

.u-btn-teal:hover, .u-btn-teal:focus, .u-btn-teal.active {
  color: #fff;
}

.u-btn-teal.g-btn-hover-reset:hover, .u-btn-teal.g-btn-hover-reset.active {
  background-color: #18ba9b;
  border-color: #18ba9b;
}

.u-btn-teal.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-teal.u-btn-hover-v1-1::after, .u-btn-teal.u-btn-hover-v1-1:hover::after {
  background-color: #1bd1ae;
}

.u-btn-teal.u-btn-hover-v1-1:hover {
  background-color: #18ba9b;
  overflow: hidden;
}

.u-btn-teal.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-teal.u-btn-hover-v1-2::after, .u-btn-teal.u-btn-hover-v1-2:hover::after {
  background-color: #1bd1ae;
}

.u-btn-teal.u-btn-hover-v1-2:hover {
  background-color: #18ba9b;
  overflow: hidden;
}

.u-btn-teal.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-teal.u-btn-hover-v1-3::after, .u-btn-teal.u-btn-hover-v1-3:hover::after {
  background-color: #1bd1ae;
}

.u-btn-teal.u-btn-hover-v1-3:hover {
  background-color: #18ba9b;
  overflow: hidden;
}

.u-btn-teal.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-teal.u-btn-hover-v1-4::after, .u-btn-teal.u-btn-hover-v1-4:hover::after {
  background-color: #1bd1ae;
}

.u-btn-teal.u-btn-hover-v1-4:hover {
  background-color: #18ba9b;
  overflow: hidden;
}

.u-btn-teal.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-teal.u-btn-hover-v2-1::after, .u-btn-teal.u-btn-hover-v2-1:hover::after {
  background-color: #1bd1ae;
}

.u-btn-teal.u-btn-hover-v2-1:hover {
  background-color: #18ba9b;
  overflow: hidden;
}

.u-btn-teal.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-teal.u-btn-hover-v2-2::after, .u-btn-teal.u-btn-hover-v2-2:hover::after {
  background-color: #1bd1ae;
}

.u-btn-teal.u-btn-hover-v2-2:hover {
  background-color: #18ba9b;
  overflow: hidden;
}

/* Button Brown */
.u-btn-brown {
  color: #fff;
  background-color: #9c8061;
}

.u-btn-brown:hover, .u-btn-brown.active {
  border-color: #b09980;
  background-color: #b09980;
}

.u-btn-brown:hover, .u-btn-brown:focus, .u-btn-brown.active {
  color: #fff;
}

.u-btn-brown.g-btn-hover-reset:hover, .u-btn-brown.g-btn-hover-reset.active {
  background-color: #9c8061;
  border-color: #9c8061;
}

.u-btn-brown.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-brown.u-btn-hover-v1-1::after, .u-btn-brown.u-btn-hover-v1-1:hover::after {
  background-color: #a68d70;
}

.u-btn-brown.u-btn-hover-v1-1:hover {
  background-color: #9c8061;
  overflow: hidden;
}

.u-btn-brown.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-brown.u-btn-hover-v1-2::after, .u-btn-brown.u-btn-hover-v1-2:hover::after {
  background-color: #a68d70;
}

.u-btn-brown.u-btn-hover-v1-2:hover {
  background-color: #9c8061;
  overflow: hidden;
}

.u-btn-brown.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-brown.u-btn-hover-v1-3::after, .u-btn-brown.u-btn-hover-v1-3:hover::after {
  background-color: #a68d70;
}

.u-btn-brown.u-btn-hover-v1-3:hover {
  background-color: #9c8061;
  overflow: hidden;
}

.u-btn-brown.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-brown.u-btn-hover-v1-4::after, .u-btn-brown.u-btn-hover-v1-4:hover::after {
  background-color: #a68d70;
}

.u-btn-brown.u-btn-hover-v1-4:hover {
  background-color: #9c8061;
  overflow: hidden;
}

.u-btn-brown.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-brown.u-btn-hover-v2-1::after, .u-btn-brown.u-btn-hover-v2-1:hover::after {
  background-color: #a68d70;
}

.u-btn-brown.u-btn-hover-v2-1:hover {
  background-color: #9c8061;
  overflow: hidden;
}

.u-btn-brown.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-brown.u-btn-hover-v2-2::after, .u-btn-brown.u-btn-hover-v2-2:hover::after {
  background-color: #a68d70;
}

.u-btn-brown.u-btn-hover-v2-2:hover {
  background-color: #9c8061;
  overflow: hidden;
}

/* Button Bluegrey */
.u-btn-bluegray {
  color: #fff;
  background-color: #585f69;
}

.u-btn-bluegray:hover, .u-btn-bluegray.active {
  border-color: #6f7885;
  background-color: #6f7885;
}

.u-btn-bluegray:hover, .u-btn-bluegray:focus, .u-btn-bluegray.active {
  color: #fff;
}

.u-btn-bluegray.g-btn-hover-reset:hover, .u-btn-bluegray.g-btn-hover-reset.active {
  background-color: #585f69;
  border-color: #585f69;
}

.u-btn-bluegray.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-bluegray.u-btn-hover-v1-1::after, .u-btn-bluegray.u-btn-hover-v1-1:hover::after {
  background-color: #646c77;
}

.u-btn-bluegray.u-btn-hover-v1-1:hover {
  background-color: #585f69;
  overflow: hidden;
}

.u-btn-bluegray.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-bluegray.u-btn-hover-v1-2::after, .u-btn-bluegray.u-btn-hover-v1-2:hover::after {
  background-color: #646c77;
}

.u-btn-bluegray.u-btn-hover-v1-2:hover {
  background-color: #585f69;
  overflow: hidden;
}

.u-btn-bluegray.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-bluegray.u-btn-hover-v1-3::after, .u-btn-bluegray.u-btn-hover-v1-3:hover::after {
  background-color: #646c77;
}

.u-btn-bluegray.u-btn-hover-v1-3:hover {
  background-color: #585f69;
  overflow: hidden;
}

.u-btn-bluegray.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-bluegray.u-btn-hover-v1-4::after, .u-btn-bluegray.u-btn-hover-v1-4:hover::after {
  background-color: #646c77;
}

.u-btn-bluegray.u-btn-hover-v1-4:hover {
  background-color: #585f69;
  overflow: hidden;
}

.u-btn-bluegray.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-bluegray.u-btn-hover-v2-1::after, .u-btn-bluegray.u-btn-hover-v2-1:hover::after {
  background-color: #646c77;
}

.u-btn-bluegray.u-btn-hover-v2-1:hover {
  background-color: #585f69;
  overflow: hidden;
}

.u-btn-bluegray.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-bluegray.u-btn-hover-v2-2::after, .u-btn-bluegray.u-btn-hover-v2-2:hover::after {
  background-color: #646c77;
}

.u-btn-bluegray.u-btn-hover-v2-2:hover {
  background-color: #585f69;
  overflow: hidden;
}

/* Button Facebook */
.u-btn-facebook {
  color: #fff;
  background-color: #3b5998;
}

.u-btn-facebook:hover, .u-btn-facebook.active {
  border-color: #4c70ba;
  background-color: #4c70ba;
}

.u-btn-facebook:hover, .u-btn-facebook:focus, .u-btn-facebook.active {
  color: #fff;
}

.u-btn-facebook.g-btn-hover-reset:hover, .u-btn-facebook.g-btn-hover-reset.active {
  background-color: #3b5998;
  border-color: #3b5998;
}

/* Button Twitter */
.u-btn-twitter {
  color: #fff;
  background-color: #00acee;
}

.u-btn-twitter:hover, .u-btn-twitter.active {
  border-color: #22c2ff;
  background-color: #22c2ff;
}

.u-btn-twitter:hover, .u-btn-twitter:focus, .u-btn-twitter.active {
  color: #fff;
}

.u-btn-twitter.g-btn-hover-reset:hover, .u-btn-twitter.g-btn-hover-reset.active {
  background-color: #00acee;
  border-color: #00acee;
}

/* Button Instagram */
.u-btn-instagram {
  color: #fff;
  background-color: #3f729b;
}

.u-btn-instagram:hover, .u-btn-instagram.active {
  border-color: #548cb9;
  background-color: #548cb9;
}

.u-btn-instagram:hover, .u-btn-instagram:focus, .u-btn-instagram.active {
  color: #fff;
}

.u-btn-instagram.g-btn-hover-reset:hover, .u-btn-instagram.g-btn-hover-reset.active {
  background-color: #3f729b;
  border-color: #3f729b;
}

/* Button VK */
.u-btn-vk {
  color: #fff;
  background-color: #2b587a;
}

.u-btn-vk:hover, .u-btn-vk.active {
  border-color: #3873a0;
  background-color: #3873a0;
}

.u-btn-vk:hover, .u-btn-vk:focus, .u-btn-vk.active {
  color: #fff;
}

.u-btn-vk.g-btn-hover-reset:hover, .u-btn-vk.g-btn-hover-reset.active {
  background-color: #2b587a;
  border-color: #2b587a;
}

/* Button Google Plus */
.u-btn-google-plus {
  color: #fff;
  background-color: #dd4b39;
}

.u-btn-google-plus:hover, .u-btn-google-plus.active {
  border-color: #e47365;
  background-color: #e47365;
}

.u-btn-google-plus:hover, .u-btn-google-plus:focus, .u-btn-google-plus.active {
  color: #fff;
}

.u-btn-google-plus.g-btn-hover-reset:hover, .u-btn-google-plus.g-btn-hover-reset.active {
  background-color: #dd4b39;
  border-color: #dd4b39;
}

/* Outline Button Primary */
.u-btn-outline-primary {
  color: #226f1c;
  border-color: #226f1c;
  background-color: transparent;
}

.u-btn-outline-primary:focus, .u-btn-outline-primary.active {
  color: #fff;
  background-color: #226f1c;
}

.u-btn-outline-primary:hover {
  color: #fff;
  background-color: #226f1c;
}

.u-btn-outline-primary.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-outline-primary.u-btn-hover-v1-1::after, .u-btn-outline-primary.u-btn-hover-v1-1:hover::after {
  background-color: #288321;
}

.u-btn-outline-primary.u-btn-hover-v1-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-primary.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-outline-primary.u-btn-hover-v1-2::after, .u-btn-outline-primary.u-btn-hover-v1-2:hover::after {
  background-color: #288321;
}

.u-btn-outline-primary.u-btn-hover-v1-2:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-primary.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-outline-primary.u-btn-hover-v1-3::after, .u-btn-outline-primary.u-btn-hover-v1-3:hover::after {
  background-color: #288321;
}

.u-btn-outline-primary.u-btn-hover-v1-3:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-primary.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-outline-primary.u-btn-hover-v1-4::after, .u-btn-outline-primary.u-btn-hover-v1-4:hover::after {
  background-color: #288321;
}

.u-btn-outline-primary.u-btn-hover-v1-4:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-primary.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-outline-primary.u-btn-hover-v2-1::after, .u-btn-outline-primary.u-btn-hover-v2-1:hover::after {
  background-color: #288321;
}

.u-btn-outline-primary.u-btn-hover-v2-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-primary.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-outline-primary.u-btn-hover-v2-2::after, .u-btn-outline-primary.u-btn-hover-v2-2:hover::after {
  background-color: #288321;
}

.u-btn-outline-primary.u-btn-hover-v2-2:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-primary.u-btn-hover-v3-1 {
  overflow: hidden;
}

.u-btn-outline-primary.u-btn-hover-v3-1::after, .u-btn-outline-primary.u-btn-hover-v3-1:hover::after {
  background-color: #288321;
}

.u-btn-outline-primary.u-btn-hover-v3-1:hover {
  overflow: hidden;
  background-color: transparent;
}

/* Outline Button White */
.u-btn-outline-white {
  color: #fff;
  border-color: #fff;
  background-color: transparent;
}

.u-btn-outline-white:focus, .u-btn-outline-white.active {
  color: #555;
  background-color: #fff;
}

.u-btn-outline-white:hover {
  color: #555;
  background-color: #fff;
}

.u-btn-outline-white.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-outline-white.u-btn-hover-v1-1::after, .u-btn-outline-white.u-btn-hover-v1-1:hover::after {
  background-color: white;
}

.u-btn-outline-white.u-btn-hover-v1-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-white.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-outline-white.u-btn-hover-v1-2::after, .u-btn-outline-white.u-btn-hover-v1-2:hover::after {
  background-color: white;
}

.u-btn-outline-white.u-btn-hover-v1-2:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-white.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-outline-white.u-btn-hover-v1-3::after, .u-btn-outline-white.u-btn-hover-v1-3:hover::after {
  background-color: white;
}

.u-btn-outline-white.u-btn-hover-v1-3:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-white.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-outline-white.u-btn-hover-v1-4::after, .u-btn-outline-white.u-btn-hover-v1-4:hover::after {
  background-color: white;
}

.u-btn-outline-white.u-btn-hover-v1-4:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-white.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-outline-white.u-btn-hover-v2-1::after, .u-btn-outline-white.u-btn-hover-v2-1:hover::after {
  background-color: white;
}

.u-btn-outline-white.u-btn-hover-v2-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-white.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-outline-white.u-btn-hover-v2-2::after, .u-btn-outline-white.u-btn-hover-v2-2:hover::after {
  background-color: white;
}

.u-btn-outline-white.u-btn-hover-v2-2:hover {
  overflow: hidden;
  background-color: transparent;
}

/* Outline Button Black */
.u-btn-outline-black {
  color: #000;
  border-color: #000;
  background-color: transparent;
}

.u-btn-outline-black:focus, .u-btn-outline-black.active {
  color: #fff;
  background-color: #000;
}

.u-btn-outline-black:hover {
  color: #fff;
  background-color: #000;
}

.u-btn-outline-black.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-outline-black.u-btn-hover-v1-1::after, .u-btn-outline-black.u-btn-hover-v1-1:hover::after {
  background-color: #0d0d0d;
}

.u-btn-outline-black.u-btn-hover-v1-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-black.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-outline-black.u-btn-hover-v1-2::after, .u-btn-outline-black.u-btn-hover-v1-2:hover::after {
  background-color: #0d0d0d;
}

.u-btn-outline-black.u-btn-hover-v1-2:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-black.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-outline-black.u-btn-hover-v1-3::after, .u-btn-outline-black.u-btn-hover-v1-3:hover::after {
  background-color: #0d0d0d;
}

.u-btn-outline-black.u-btn-hover-v1-3:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-black.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-outline-black.u-btn-hover-v1-4::after, .u-btn-outline-black.u-btn-hover-v1-4:hover::after {
  background-color: #0d0d0d;
}

.u-btn-outline-black.u-btn-hover-v1-4:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-black.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-outline-black.u-btn-hover-v2-1::after, .u-btn-outline-black.u-btn-hover-v2-1:hover::after {
  background-color: #0d0d0d;
}

.u-btn-outline-black.u-btn-hover-v2-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-black.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-outline-black.u-btn-hover-v2-2::after, .u-btn-outline-black.u-btn-hover-v2-2:hover::after {
  background-color: #0d0d0d;
}

.u-btn-outline-black.u-btn-hover-v2-2:hover {
  overflow: hidden;
  background-color: transparent;
}

/* Outline Button Dark Gray */
.u-btn-outline-darkgray {
  color: #333;
  border-color: #333;
  background-color: transparent;
}

.u-btn-outline-darkgray:focus, .u-btn-outline-darkgray.active {
  color: #fff;
  background-color: #333;
}

.u-btn-outline-darkgray:hover {
  color: #fff;
  background-color: #333;
}

.u-btn-outline-darkgray.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-outline-darkgray.u-btn-hover-v1-1::after, .u-btn-outline-darkgray.u-btn-hover-v1-1:hover::after {
  background-color: #404040;
}

.u-btn-outline-darkgray.u-btn-hover-v1-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-darkgray.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-outline-darkgray.u-btn-hover-v1-2::after, .u-btn-outline-darkgray.u-btn-hover-v1-2:hover::after {
  background-color: #404040;
}

.u-btn-outline-darkgray.u-btn-hover-v1-2:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-darkgray.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-outline-darkgray.u-btn-hover-v1-3::after, .u-btn-outline-darkgray.u-btn-hover-v1-3:hover::after {
  background-color: #404040;
}

.u-btn-outline-darkgray.u-btn-hover-v1-3:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-darkgray.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-outline-darkgray.u-btn-hover-v1-4::after, .u-btn-outline-darkgray.u-btn-hover-v1-4:hover::after {
  background-color: #404040;
}

.u-btn-outline-darkgray.u-btn-hover-v1-4:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-darkgray.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-outline-darkgray.u-btn-hover-v2-1::after, .u-btn-outline-darkgray.u-btn-hover-v2-1:hover::after {
  background-color: #404040;
}

.u-btn-outline-darkgray.u-btn-hover-v2-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-darkgray.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-outline-darkgray.u-btn-hover-v2-2::after, .u-btn-outline-darkgray.u-btn-hover-v2-2:hover::after {
  background-color: #404040;
}

.u-btn-outline-darkgray.u-btn-hover-v2-2:hover {
  overflow: hidden;
  background-color: transparent;
}

/* Outline Button Light Gray */
.u-btn-outline-lightgray {
  color: #999;
  border-color: #eee;
  background-color: transparent;
}

.u-btn-outline-lightgray:focus, .u-btn-outline-lightgray.active {
  color: #999;
  background-color: #eee;
}

.u-btn-outline-lightgray:hover {
  color: #999;
  background-color: #eee;
}

.u-btn-outline-lightgray.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-outline-lightgray.u-btn-hover-v1-1::after, .u-btn-outline-lightgray.u-btn-hover-v1-1:hover::after {
  background-color: #fbfbfb;
}

.u-btn-outline-lightgray.u-btn-hover-v1-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-lightgray.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-outline-lightgray.u-btn-hover-v1-2::after, .u-btn-outline-lightgray.u-btn-hover-v1-2:hover::after {
  background-color: #fbfbfb;
}

.u-btn-outline-lightgray.u-btn-hover-v1-2:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-lightgray.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-outline-lightgray.u-btn-hover-v1-3::after, .u-btn-outline-lightgray.u-btn-hover-v1-3:hover::after {
  background-color: #fbfbfb;
}

.u-btn-outline-lightgray.u-btn-hover-v1-3:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-lightgray.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-outline-lightgray.u-btn-hover-v1-4::after, .u-btn-outline-lightgray.u-btn-hover-v1-4:hover::after {
  background-color: #fbfbfb;
}

.u-btn-outline-lightgray.u-btn-hover-v1-4:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-lightgray.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-outline-lightgray.u-btn-hover-v2-1::after, .u-btn-outline-lightgray.u-btn-hover-v2-1:hover::after {
  background-color: #fbfbfb;
}

.u-btn-outline-lightgray.u-btn-hover-v2-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-lightgray.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-outline-lightgray.u-btn-hover-v2-2::after, .u-btn-outline-lightgray.u-btn-hover-v2-2:hover::after {
  background-color: #fbfbfb;
}

.u-btn-outline-lightgray.u-btn-hover-v2-2:hover {
  overflow: hidden;
  background-color: transparent;
}

/* Button Outline Red */
.u-btn-outline-red {
  color: #f00;
  border-color: #f00;
  background-color: transparent;
}

.u-btn-outline-red:focus, .u-btn-outline-red.active {
  color: #fff;
  background-color: #f00;
}

.u-btn-outline-red:hover {
  color: #fff;
  background-color: #f00;
}

.u-btn-outline-red.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-outline-red.u-btn-hover-v1-1::after, .u-btn-outline-red.u-btn-hover-v1-1:hover::after {
  background-color: #ff1a1a;
}

.u-btn-outline-red.u-btn-hover-v1-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-red.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-outline-red.u-btn-hover-v1-2::after, .u-btn-outline-red.u-btn-hover-v1-2:hover::after {
  background-color: #ff1a1a;
}

.u-btn-outline-red.u-btn-hover-v1-2:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-red.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-outline-red.u-btn-hover-v1-3::after, .u-btn-outline-red.u-btn-hover-v1-3:hover::after {
  background-color: #ff1a1a;
}

.u-btn-outline-red.u-btn-hover-v1-3:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-red.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-outline-red.u-btn-hover-v1-4::after, .u-btn-outline-red.u-btn-hover-v1-4:hover::after {
  background-color: #ff1a1a;
}

.u-btn-outline-red.u-btn-hover-v1-4:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-red.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-outline-red.u-btn-hover-v2-1::after, .u-btn-outline-red.u-btn-hover-v2-1:hover::after {
  background-color: #ff1a1a;
}

.u-btn-outline-red.u-btn-hover-v2-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-red.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-outline-red.u-btn-hover-v2-2::after, .u-btn-outline-red.u-btn-hover-v2-2:hover::after {
  background-color: #ff1a1a;
}

.u-btn-outline-red.u-btn-hover-v2-2:hover {
  overflow: hidden;
  background-color: transparent;
}

/* Button Outline Red Tomato */
.u-btn-outline-lightred {
  color: #e64b3b;
  border-color: #e64b3b;
  background-color: transparent;
}

.u-btn-outline-lightred:focus, .u-btn-outline-lightred.active {
  color: #fff;
  background-color: #e64b3b;
}

.u-btn-outline-lightred:hover {
  color: #fff;
  background-color: #e64b3b;
}

.u-btn-outline-lightred.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-outline-lightred.u-btn-hover-v1-1::after, .u-btn-outline-lightred.u-btn-hover-v1-1:hover::after {
  background-color: #e96052;
}

.u-btn-outline-lightred.u-btn-hover-v1-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-lightred.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-outline-lightred.u-btn-hover-v1-2::after, .u-btn-outline-lightred.u-btn-hover-v1-2:hover::after {
  background-color: #e96052;
}

.u-btn-outline-lightred.u-btn-hover-v1-2:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-lightred.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-outline-lightred.u-btn-hover-v1-3::after, .u-btn-outline-lightred.u-btn-hover-v1-3:hover::after {
  background-color: #e96052;
}

.u-btn-outline-lightred.u-btn-hover-v1-3:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-lightred.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-outline-lightred.u-btn-hover-v1-4::after, .u-btn-outline-lightred.u-btn-hover-v1-4:hover::after {
  background-color: #e96052;
}

.u-btn-outline-lightred.u-btn-hover-v1-4:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-lightred.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-outline-lightred.u-btn-hover-v2-1::after, .u-btn-outline-lightred.u-btn-hover-v2-1:hover::after {
  background-color: #e96052;
}

.u-btn-outline-lightred.u-btn-hover-v2-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-lightred.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-outline-lightred.u-btn-hover-v2-2::after, .u-btn-outline-lightred.u-btn-hover-v2-2:hover::after {
  background-color: #e96052;
}

.u-btn-outline-lightred.u-btn-hover-v2-2:hover {
  overflow: hidden;
  background-color: transparent;
}

/* Outline Button Dark Red */
.u-btn-outline-darkred {
  color: #a10f2b;
  border-color: #a10f2b;
  background-color: transparent;
}

.u-btn-outline-darkred:focus, .u-btn-outline-darkred.active {
  color: #fff;
  background-color: #a10f2b;
}

.u-btn-outline-darkred:hover {
  color: #fff;
  background-color: #a10f2b;
}

.u-btn-outline-darkred.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-outline-darkred.u-btn-hover-v1-1::after, .u-btn-outline-darkred.u-btn-hover-v1-1:hover::after {
  background-color: #b81131;
}

.u-btn-outline-darkred.u-btn-hover-v1-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-darkred.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-outline-darkred.u-btn-hover-v1-2::after, .u-btn-outline-darkred.u-btn-hover-v1-2:hover::after {
  background-color: #b81131;
}

.u-btn-outline-darkred.u-btn-hover-v1-2:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-darkred.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-outline-darkred.u-btn-hover-v1-3::after, .u-btn-outline-darkred.u-btn-hover-v1-3:hover::after {
  background-color: #b81131;
}

.u-btn-outline-darkred.u-btn-hover-v1-3:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-darkred.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-outline-darkred.u-btn-hover-v1-4::after, .u-btn-outline-darkred.u-btn-hover-v1-4:hover::after {
  background-color: #b81131;
}

.u-btn-outline-darkred.u-btn-hover-v1-4:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-darkred.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-outline-darkred.u-btn-hover-v2-1::after, .u-btn-outline-darkred.u-btn-hover-v2-1:hover::after {
  background-color: #b81131;
}

.u-btn-outline-darkred.u-btn-hover-v2-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-darkred.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-outline-darkred.u-btn-hover-v2-2::after, .u-btn-outline-darkred.u-btn-hover-v2-2:hover::after {
  background-color: #b81131;
}

.u-btn-outline-darkred.u-btn-hover-v2-2:hover {
  overflow: hidden;
  background-color: transparent;
}

/* Outline Button Blue */
.u-btn-outline-blue {
  color: #3398dc;
  border-color: #3398dc;
  background-color: transparent;
}

.u-btn-outline-blue:focus, .u-btn-outline-blue.active {
  color: #fff;
  background-color: #3398dc;
}

.u-btn-outline-blue:hover {
  color: #fff;
  background-color: #3398dc;
}

.u-btn-outline-blue.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-outline-blue.u-btn-hover-v1-1::after, .u-btn-outline-blue.u-btn-hover-v1-1:hover::after {
  background-color: #49a3e0;
}

.u-btn-outline-blue.u-btn-hover-v1-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-blue.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-outline-blue.u-btn-hover-v1-2::after, .u-btn-outline-blue.u-btn-hover-v1-2:hover::after {
  background-color: #49a3e0;
}

.u-btn-outline-blue.u-btn-hover-v1-2:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-blue.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-outline-blue.u-btn-hover-v1-3::after, .u-btn-outline-blue.u-btn-hover-v1-3:hover::after {
  background-color: #49a3e0;
}

.u-btn-outline-blue.u-btn-hover-v1-3:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-blue.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-outline-blue.u-btn-hover-v1-4::after, .u-btn-outline-blue.u-btn-hover-v1-4:hover::after {
  background-color: #49a3e0;
}

.u-btn-outline-blue.u-btn-hover-v1-4:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-blue.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-outline-blue.u-btn-hover-v2-1::after, .u-btn-outline-blue.u-btn-hover-v2-1:hover::after {
  background-color: #49a3e0;
}

.u-btn-outline-blue.u-btn-hover-v2-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-blue.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-outline-blue.u-btn-hover-v2-2::after, .u-btn-outline-blue.u-btn-hover-v2-2:hover::after {
  background-color: #49a3e0;
}

.u-btn-outline-blue.u-btn-hover-v2-2:hover {
  overflow: hidden;
  background-color: transparent;
}

/* Outline Button Indigo */
.u-btn-outline-indigo {
  color: #4263a3;
  border-color: #4263a3;
  background-color: transparent;
}

.u-btn-outline-indigo:focus, .u-btn-outline-indigo.active {
  color: #fff;
  background-color: #4263a3;
}

.u-btn-outline-indigo:hover {
  color: #fff;
  background-color: #4263a3;
}

.u-btn-outline-indigo.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-outline-indigo.u-btn-hover-v1-1::after, .u-btn-outline-indigo.u-btn-hover-v1-1:hover::after {
  background-color: #496eb5;
}

.u-btn-outline-indigo.u-btn-hover-v1-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-indigo.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-outline-indigo.u-btn-hover-v1-2::after, .u-btn-outline-indigo.u-btn-hover-v1-2:hover::after {
  background-color: #496eb5;
}

.u-btn-outline-indigo.u-btn-hover-v1-2:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-indigo.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-outline-indigo.u-btn-hover-v1-3::after, .u-btn-outline-indigo.u-btn-hover-v1-3:hover::after {
  background-color: #496eb5;
}

.u-btn-outline-indigo.u-btn-hover-v1-3:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-indigo.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-outline-indigo.u-btn-hover-v1-4::after, .u-btn-outline-indigo.u-btn-hover-v1-4:hover::after {
  background-color: #496eb5;
}

.u-btn-outline-indigo.u-btn-hover-v1-4:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-indigo.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-outline-indigo.u-btn-hover-v2-1::after, .u-btn-outline-indigo.u-btn-hover-v2-1:hover::after {
  background-color: #496eb5;
}

.u-btn-outline-indigo.u-btn-hover-v2-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-indigo.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-outline-indigo.u-btn-hover-v2-2::after, .u-btn-outline-indigo.u-btn-hover-v2-2:hover::after {
  background-color: #496eb5;
}

.u-btn-outline-indigo.u-btn-hover-v2-2:hover {
  overflow: hidden;
  background-color: transparent;
}

/* Outline Button Purple */
.u-btn-outline-purple {
  color: #9a69cb;
  border-color: #9a69cb;
  background-color: transparent;
}

.u-btn-outline-purple:focus, .u-btn-outline-purple.active {
  color: #fff;
  background-color: #9a69cb;
}

.u-btn-outline-purple:hover {
  color: #fff;
  background-color: #9a69cb;
}

.u-btn-outline-purple.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-outline-purple.u-btn-hover-v1-1::after, .u-btn-outline-purple.u-btn-hover-v1-1:hover::after {
  background-color: #a77cd2;
}

.u-btn-outline-purple.u-btn-hover-v1-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-purple.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-outline-purple.u-btn-hover-v1-2::after, .u-btn-outline-purple.u-btn-hover-v1-2:hover::after {
  background-color: #a77cd2;
}

.u-btn-outline-purple.u-btn-hover-v1-2:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-purple.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-outline-purple.u-btn-hover-v1-3::after, .u-btn-outline-purple.u-btn-hover-v1-3:hover::after {
  background-color: #a77cd2;
}

.u-btn-outline-purple.u-btn-hover-v1-3:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-purple.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-outline-purple.u-btn-hover-v1-4::after, .u-btn-outline-purple.u-btn-hover-v1-4:hover::after {
  background-color: #a77cd2;
}

.u-btn-outline-purple.u-btn-hover-v1-4:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-purple.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-outline-purple.u-btn-hover-v2-1::after, .u-btn-outline-purple.u-btn-hover-v2-1:hover::after {
  background-color: #a77cd2;
}

.u-btn-outline-purple.u-btn-hover-v2-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-purple.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-outline-purple.u-btn-hover-v2-2::after, .u-btn-outline-purple.u-btn-hover-v2-2:hover::after {
  background-color: #a77cd2;
}

.u-btn-outline-purple.u-btn-hover-v2-2:hover {
  overflow: hidden;
  background-color: transparent;
}

/* Outline Button Dark Purple */
.u-btn-outline-darkpurple {
  color: #6639b6;
  border-color: #6639b6;
  background-color: transparent;
}

.u-btn-outline-darkpurple:focus, .u-btn-outline-darkpurple.active {
  color: #fff;
  background-color: #6639b6;
}

.u-btn-outline-darkpurple:hover {
  color: #fff;
  background-color: #6639b6;
}

.u-btn-outline-darkpurple.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-outline-darkpurple.u-btn-hover-v1-1::after, .u-btn-outline-darkpurple.u-btn-hover-v1-1:hover::after {
  background-color: #7244c4;
}

.u-btn-outline-darkpurple.u-btn-hover-v1-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-darkpurple.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-outline-darkpurple.u-btn-hover-v1-2::after, .u-btn-outline-darkpurple.u-btn-hover-v1-2:hover::after {
  background-color: #7244c4;
}

.u-btn-outline-darkpurple.u-btn-hover-v1-2:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-darkpurple.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-outline-darkpurple.u-btn-hover-v1-3::after, .u-btn-outline-darkpurple.u-btn-hover-v1-3:hover::after {
  background-color: #7244c4;
}

.u-btn-outline-darkpurple.u-btn-hover-v1-3:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-darkpurple.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-outline-darkpurple.u-btn-hover-v1-4::after, .u-btn-outline-darkpurple.u-btn-hover-v1-4:hover::after {
  background-color: #7244c4;
}

.u-btn-outline-darkpurple.u-btn-hover-v1-4:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-darkpurple.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-outline-darkpurple.u-btn-hover-v2-1::after, .u-btn-outline-darkpurple.u-btn-hover-v2-1:hover::after {
  background-color: #7244c4;
}

.u-btn-outline-darkpurple.u-btn-hover-v2-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-darkpurple.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-outline-darkpurple.u-btn-hover-v2-2::after, .u-btn-outline-darkpurple.u-btn-hover-v2-2:hover::after {
  background-color: #7244c4;
}

.u-btn-outline-darkpurple.u-btn-hover-v2-2:hover {
  overflow: hidden;
  background-color: transparent;
}

/* Outline Button Pink */
.u-btn-outline-pink {
  color: #e81c62;
  border-color: #e81c62;
  background-color: transparent;
}

.u-btn-outline-pink:focus, .u-btn-outline-pink.active {
  color: #fff;
  background-color: #e81c62;
}

.u-btn-outline-pink:hover {
  color: #fff;
  background-color: #e81c62;
}

.u-btn-outline-pink.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-outline-pink.u-btn-hover-v1-1::after, .u-btn-outline-pink.u-btn-hover-v1-1:hover::after {
  background-color: #ea3372;
}

.u-btn-outline-pink.u-btn-hover-v1-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-pink.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-outline-pink.u-btn-hover-v1-2::after, .u-btn-outline-pink.u-btn-hover-v1-2:hover::after {
  background-color: #ea3372;
}

.u-btn-outline-pink.u-btn-hover-v1-2:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-pink.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-outline-pink.u-btn-hover-v1-3::after, .u-btn-outline-pink.u-btn-hover-v1-3:hover::after {
  background-color: #ea3372;
}

.u-btn-outline-pink.u-btn-hover-v1-3:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-pink.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-outline-pink.u-btn-hover-v1-4::after, .u-btn-outline-pink.u-btn-hover-v1-4:hover::after {
  background-color: #ea3372;
}

.u-btn-outline-pink.u-btn-hover-v1-4:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-pink.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-outline-pink.u-btn-hover-v2-1::after, .u-btn-outline-pink.u-btn-hover-v2-1:hover::after {
  background-color: #ea3372;
}

.u-btn-outline-pink.u-btn-hover-v2-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-pink.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-outline-pink.u-btn-hover-v2-2::after, .u-btn-outline-pink.u-btn-hover-v2-2:hover::after {
  background-color: #ea3372;
}

.u-btn-outline-pink.u-btn-hover-v2-2:hover {
  overflow: hidden;
  background-color: transparent;
}

/* Outline Button Orange */
.u-btn-outline-orange {
  color: #e57d20;
  border-color: #e57d20;
  background-color: transparent;
}

.u-btn-outline-orange:focus, .u-btn-outline-orange.active {
  color: #fff;
  background-color: #e57d20;
}

.u-btn-outline-orange:hover {
  color: #fff;
  background-color: #e57d20;
}

.u-btn-outline-orange.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-outline-orange.u-btn-hover-v1-1::after, .u-btn-outline-orange.u-btn-hover-v1-1:hover::after {
  background-color: #e88a37;
}

.u-btn-outline-orange.u-btn-hover-v1-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-orange.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-outline-orange.u-btn-hover-v1-2::after, .u-btn-outline-orange.u-btn-hover-v1-2:hover::after {
  background-color: #e88a37;
}

.u-btn-outline-orange.u-btn-hover-v1-2:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-orange.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-outline-orange.u-btn-hover-v1-3::after, .u-btn-outline-orange.u-btn-hover-v1-3:hover::after {
  background-color: #e88a37;
}

.u-btn-outline-orange.u-btn-hover-v1-3:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-orange.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-outline-orange.u-btn-hover-v1-4::after, .u-btn-outline-orange.u-btn-hover-v1-4:hover::after {
  background-color: #e88a37;
}

.u-btn-outline-orange.u-btn-hover-v1-4:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-orange.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-outline-orange.u-btn-hover-v2-1::after, .u-btn-outline-orange.u-btn-hover-v2-1:hover::after {
  background-color: #e88a37;
}

.u-btn-outline-orange.u-btn-hover-v2-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-orange.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-outline-orange.u-btn-hover-v2-2::after, .u-btn-outline-orange.u-btn-hover-v2-2:hover::after {
  background-color: #e88a37;
}

.u-btn-outline-orange.u-btn-hover-v2-2:hover {
  overflow: hidden;
  background-color: transparent;
}

/* Outline Button Deep Orange */
.u-btn-outline-deeporange {
  color: #fe541e;
  border-color: #fe541e;
  background-color: transparent;
}

.u-btn-outline-deeporange:focus, .u-btn-outline-deeporange.active {
  color: #fff;
  background-color: #fe541e;
}

.u-btn-outline-deeporange:hover {
  color: #fff;
  background-color: #fe541e;
}

.u-btn-outline-deeporange.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-outline-deeporange.u-btn-hover-v1-1::after, .u-btn-outline-deeporange.u-btn-hover-v1-1:hover::after {
  background-color: #fe6737;
}

.u-btn-outline-deeporange.u-btn-hover-v1-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-deeporange.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-outline-deeporange.u-btn-hover-v1-2::after, .u-btn-outline-deeporange.u-btn-hover-v1-2:hover::after {
  background-color: #fe6737;
}

.u-btn-outline-deeporange.u-btn-hover-v1-2:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-deeporange.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-outline-deeporange.u-btn-hover-v1-3::after, .u-btn-outline-deeporange.u-btn-hover-v1-3:hover::after {
  background-color: #fe6737;
}

.u-btn-outline-deeporange.u-btn-hover-v1-3:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-deeporange.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-outline-deeporange.u-btn-hover-v1-4::after, .u-btn-outline-deeporange.u-btn-hover-v1-4:hover::after {
  background-color: #fe6737;
}

.u-btn-outline-deeporange.u-btn-hover-v1-4:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-deeporange.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-outline-deeporange.u-btn-hover-v2-1::after, .u-btn-outline-deeporange.u-btn-hover-v2-1:hover::after {
  background-color: #fe6737;
}

.u-btn-outline-deeporange.u-btn-hover-v2-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-deeporange.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-outline-deeporange.u-btn-hover-v2-2::after, .u-btn-outline-deeporange.u-btn-hover-v2-2:hover::after {
  background-color: #fe6737;
}

.u-btn-outline-deeporange.u-btn-hover-v2-2:hover {
  overflow: hidden;
  background-color: transparent;
}

/* Outline Button Yellow */
.u-btn-outline-yellow {
  color: #555;
  border-color: #ebc71d;
  background-color: transparent;
}

.u-btn-outline-yellow:focus, .u-btn-outline-yellow.active {
  color: #555;
  background-color: #ebc71d;
}

.u-btn-outline-yellow:hover {
  color: #555;
  background-color: #ebc71d;
}

.u-btn-outline-yellow.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-outline-yellow.u-btn-hover-v1-1::after, .u-btn-outline-yellow.u-btn-hover-v1-1:hover::after {
  background-color: #edcd34;
}

.u-btn-outline-yellow.u-btn-hover-v1-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-yellow.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-outline-yellow.u-btn-hover-v1-2::after, .u-btn-outline-yellow.u-btn-hover-v1-2:hover::after {
  background-color: #edcd34;
}

.u-btn-outline-yellow.u-btn-hover-v1-2:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-yellow.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-outline-yellow.u-btn-hover-v1-3::after, .u-btn-outline-yellow.u-btn-hover-v1-3:hover::after {
  background-color: #edcd34;
}

.u-btn-outline-yellow.u-btn-hover-v1-3:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-yellow.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-outline-yellow.u-btn-hover-v1-4::after, .u-btn-outline-yellow.u-btn-hover-v1-4:hover::after {
  background-color: #edcd34;
}

.u-btn-outline-yellow.u-btn-hover-v1-4:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-yellow.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-outline-yellow.u-btn-hover-v2-1::after, .u-btn-outline-yellow.u-btn-hover-v2-1:hover::after {
  background-color: #edcd34;
}

.u-btn-outline-yellow.u-btn-hover-v2-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-yellow.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-outline-yellow.u-btn-hover-v2-2::after, .u-btn-outline-yellow.u-btn-hover-v2-2:hover::after {
  background-color: #edcd34;
}

.u-btn-outline-yellow.u-btn-hover-v2-2:hover {
  overflow: hidden;
  background-color: transparent;
}

/* Outline Button Aqua */
.u-btn-outline-aqua {
  color: #29d6e6;
  border-color: #29d6e6;
  background-color: transparent;
}

.u-btn-outline-aqua:focus, .u-btn-outline-aqua.active {
  color: #fff;
  background-color: #29d6e6;
}

.u-btn-outline-aqua:hover {
  color: #fff;
  background-color: #29d6e6;
}

.u-btn-outline-aqua.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-outline-aqua.u-btn-hover-v1-1::after, .u-btn-outline-aqua.u-btn-hover-v1-1:hover::after {
  background-color: #40dae9;
}

.u-btn-outline-aqua.u-btn-hover-v1-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-aqua.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-outline-aqua.u-btn-hover-v1-2::after, .u-btn-outline-aqua.u-btn-hover-v1-2:hover::after {
  background-color: #40dae9;
}

.u-btn-outline-aqua.u-btn-hover-v1-2:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-aqua.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-outline-aqua.u-btn-hover-v1-3::after, .u-btn-outline-aqua.u-btn-hover-v1-3:hover::after {
  background-color: #40dae9;
}

.u-btn-outline-aqua.u-btn-hover-v1-3:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-aqua.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-outline-aqua.u-btn-hover-v1-4::after, .u-btn-outline-aqua.u-btn-hover-v1-4:hover::after {
  background-color: #40dae9;
}

.u-btn-outline-aqua.u-btn-hover-v1-4:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-aqua.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-outline-aqua.u-btn-hover-v2-1::after, .u-btn-outline-aqua.u-btn-hover-v2-1:hover::after {
  background-color: #40dae9;
}

.u-btn-outline-aqua.u-btn-hover-v2-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-aqua.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-outline-aqua.u-btn-hover-v2-2::after, .u-btn-outline-aqua.u-btn-hover-v2-2:hover::after {
  background-color: #40dae9;
}

.u-btn-outline-aqua.u-btn-hover-v2-2:hover {
  overflow: hidden;
  background-color: transparent;
}

/* Outline Button Cyan */
.u-btn-outline-cyan {
  color: #00bed6;
  border-color: #00bed6;
  background-color: transparent;
}

.u-btn-outline-cyan:focus, .u-btn-outline-cyan.active {
  color: #fff;
  background-color: #00bed6;
}

.u-btn-outline-cyan:hover {
  color: #fff;
  background-color: #00bed6;
}

.u-btn-outline-cyan.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-outline-cyan.u-btn-hover-v1-1::after, .u-btn-outline-cyan.u-btn-hover-v1-1:hover::after {
  background-color: #00d5f0;
}

.u-btn-outline-cyan.u-btn-hover-v1-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-cyan.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-outline-cyan.u-btn-hover-v1-2::after, .u-btn-outline-cyan.u-btn-hover-v1-2:hover::after {
  background-color: #00d5f0;
}

.u-btn-outline-cyan.u-btn-hover-v1-2:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-cyan.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-outline-cyan.u-btn-hover-v1-3::after, .u-btn-outline-cyan.u-btn-hover-v1-3:hover::after {
  background-color: #00d5f0;
}

.u-btn-outline-cyan.u-btn-hover-v1-3:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-cyan.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-outline-cyan.u-btn-hover-v1-4::after, .u-btn-outline-cyan.u-btn-hover-v1-4:hover::after {
  background-color: #00d5f0;
}

.u-btn-outline-cyan.u-btn-hover-v1-4:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-cyan.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-outline-cyan.u-btn-hover-v2-1::after, .u-btn-outline-cyan.u-btn-hover-v2-1:hover::after {
  background-color: #00d5f0;
}

.u-btn-outline-cyan.u-btn-hover-v2-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-cyan.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-outline-cyan.u-btn-hover-v2-2::after, .u-btn-outline-cyan.u-btn-hover-v2-2:hover::after {
  background-color: #00d5f0;
}

.u-btn-outline-cyan.u-btn-hover-v2-2:hover {
  overflow: hidden;
  background-color: transparent;
}

/* Outline Button Teal */
.u-btn-outline-teal {
  color: #18ba9b;
  border-color: #18ba9b;
  background-color: transparent;
}

.u-btn-outline-teal:focus, .u-btn-outline-teal.active {
  color: #fff;
  background-color: #18ba9b;
}

.u-btn-outline-teal:hover {
  color: #fff;
  background-color: #18ba9b;
}

.u-btn-outline-teal.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-outline-teal.u-btn-hover-v1-1::after, .u-btn-outline-teal.u-btn-hover-v1-1:hover::after {
  background-color: #1bd1ae;
}

.u-btn-outline-teal.u-btn-hover-v1-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-teal.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-outline-teal.u-btn-hover-v1-2::after, .u-btn-outline-teal.u-btn-hover-v1-2:hover::after {
  background-color: #1bd1ae;
}

.u-btn-outline-teal.u-btn-hover-v1-2:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-teal.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-outline-teal.u-btn-hover-v1-3::after, .u-btn-outline-teal.u-btn-hover-v1-3:hover::after {
  background-color: #1bd1ae;
}

.u-btn-outline-teal.u-btn-hover-v1-3:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-teal.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-outline-teal.u-btn-hover-v1-4::after, .u-btn-outline-teal.u-btn-hover-v1-4:hover::after {
  background-color: #1bd1ae;
}

.u-btn-outline-teal.u-btn-hover-v1-4:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-teal.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-outline-teal.u-btn-hover-v2-1::after, .u-btn-outline-teal.u-btn-hover-v2-1:hover::after {
  background-color: #1bd1ae;
}

.u-btn-outline-teal.u-btn-hover-v2-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-teal.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-outline-teal.u-btn-hover-v2-2::after, .u-btn-outline-teal.u-btn-hover-v2-2:hover::after {
  background-color: #1bd1ae;
}

.u-btn-outline-teal.u-btn-hover-v2-2:hover {
  overflow: hidden;
  background-color: transparent;
}

/* Outline Button Brown */
.u-btn-outline-brown {
  color: #9c8061;
  border-color: #9c8061;
  background-color: transparent;
}

.u-btn-outline-brown:focus, .u-btn-outline-brown.active {
  color: #fff;
  background-color: #9c8061;
}

.u-btn-outline-brown:hover {
  color: #fff;
  background-color: #9c8061;
}

.u-btn-outline-brown.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-outline-brown.u-btn-hover-v1-1::after, .u-btn-outline-brown.u-btn-hover-v1-1:hover::after {
  background-color: #a68d70;
}

.u-btn-outline-brown.u-btn-hover-v1-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-brown.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-outline-brown.u-btn-hover-v1-2::after, .u-btn-outline-brown.u-btn-hover-v1-2:hover::after {
  background-color: #a68d70;
}

.u-btn-outline-brown.u-btn-hover-v1-2:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-brown.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-outline-brown.u-btn-hover-v1-3::after, .u-btn-outline-brown.u-btn-hover-v1-3:hover::after {
  background-color: #a68d70;
}

.u-btn-outline-brown.u-btn-hover-v1-3:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-brown.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-outline-brown.u-btn-hover-v1-4::after, .u-btn-outline-brown.u-btn-hover-v1-4:hover::after {
  background-color: #a68d70;
}

.u-btn-outline-brown.u-btn-hover-v1-4:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-brown.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-outline-brown.u-btn-hover-v2-1::after, .u-btn-outline-brown.u-btn-hover-v2-1:hover::after {
  background-color: #a68d70;
}

.u-btn-outline-brown.u-btn-hover-v2-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-brown.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-outline-brown.u-btn-hover-v2-2::after, .u-btn-outline-brown.u-btn-hover-v2-2:hover::after {
  background-color: #a68d70;
}

.u-btn-outline-brown.u-btn-hover-v2-2:hover {
  overflow: hidden;
  background-color: transparent;
}

/* Outline Button Bluegrey */
.u-btn-outline-bluegray {
  color: #585f69;
  border-color: #585f69;
  background-color: transparent;
}

.u-btn-outline-bluegray:focus, .u-btn-outline-bluegray.active {
  color: #fff;
  background-color: #585f69;
}

.u-btn-outline-bluegray:hover {
  color: #fff;
  background-color: #585f69;
}

.u-btn-outline-bluegray.u-btn-hover-v1-1 {
  overflow: hidden;
}

.u-btn-outline-bluegray.u-btn-hover-v1-1::after, .u-btn-outline-bluegray.u-btn-hover-v1-1:hover::after {
  background-color: #646c77;
}

.u-btn-outline-bluegray.u-btn-hover-v1-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-bluegray.u-btn-hover-v1-2 {
  overflow: hidden;
}

.u-btn-outline-bluegray.u-btn-hover-v1-2::after, .u-btn-outline-bluegray.u-btn-hover-v1-2:hover::after {
  background-color: #646c77;
}

.u-btn-outline-bluegray.u-btn-hover-v1-2:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-bluegray.u-btn-hover-v1-3 {
  overflow: hidden;
}

.u-btn-outline-bluegray.u-btn-hover-v1-3::after, .u-btn-outline-bluegray.u-btn-hover-v1-3:hover::after {
  background-color: #646c77;
}

.u-btn-outline-bluegray.u-btn-hover-v1-3:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-bluegray.u-btn-hover-v1-4 {
  overflow: hidden;
}

.u-btn-outline-bluegray.u-btn-hover-v1-4::after, .u-btn-outline-bluegray.u-btn-hover-v1-4:hover::after {
  background-color: #646c77;
}

.u-btn-outline-bluegray.u-btn-hover-v1-4:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-bluegray.u-btn-hover-v2-1 {
  overflow: hidden;
}

.u-btn-outline-bluegray.u-btn-hover-v2-1::after, .u-btn-outline-bluegray.u-btn-hover-v2-1:hover::after {
  background-color: #646c77;
}

.u-btn-outline-bluegray.u-btn-hover-v2-1:hover {
  overflow: hidden;
  background-color: transparent;
}

.u-btn-outline-bluegray.u-btn-hover-v2-2 {
  overflow: hidden;
}

.u-btn-outline-bluegray.u-btn-hover-v2-2::after, .u-btn-outline-bluegray.u-btn-hover-v2-2:hover::after {
  background-color: #646c77;
}

.u-btn-outline-bluegray.u-btn-hover-v2-2:hover {
  overflow: hidden;
  background-color: transparent;
}

/* Button Facebook */
.u-btn-outline-facebook {
  color: #3b5998;
  border-color: #3b5998;
  background-color: transparent;
}

.u-btn-outline-facebook:focus, .u-btn-outline-facebook.active {
  color: #fff;
  background-color: #3b5998;
}

.u-btn-outline-facebook:hover {
  color: #fff;
  background-color: #3b5998;
}

/* Button Twitter */
.u-btn-outline-twitter {
  color: #00acee;
  border-color: #00acee;
  background-color: transparent;
}

.u-btn-outline-twitter:focus, .u-btn-outline-twitter.active {
  color: #fff;
  background-color: #00acee;
}

.u-btn-outline-twitter:hover {
  color: #fff;
  background-color: #00acee;
}

/* Button Dribbble */
.u-btn-outline-dribbble {
  color: #ea4c89;
  border-color: #ea4c89;
  background-color: transparent;
}

.u-btn-outline-dribbble:focus, .u-btn-outline-dribbble.active {
  color: #fff;
  background-color: #ea4c89;
}

.u-btn-outline-dribbble:hover {
  color: #fff;
  background-color: #ea4c89;
}

/* Gradient Style v1 */
.u-btn-gradient-v1 {
  color: #fff;
  background: linear-gradient(to right, #4776E6 0%, #8E54E9 100%) !important;
  -o-border-image: linear-gradient(to right, #4776E6 0%, #8E54E9 100%);
     border-image: linear-gradient(to right, #4776E6 0%, #8E54E9 100%);
  border-image-slice: 1;
}

.u-btn-gradient-v1:hover, .u-btn-gradient-v1:focus, .u-btn-gradient-v1.active {
  opacity: .9;
  color: #fff;
}

/* Gradient Style v2 */
.u-btn-gradient-v2 {
  color: #fff;
  background: linear-gradient(to right, #E040FB 0%, #00BCD4 100%) !important;
  -o-border-image: linear-gradient(to right, #E040FB 0%, #00BCD4 100%);
     border-image: linear-gradient(to right, #E040FB 0%, #00BCD4 100%);
  border-image-slice: 1;
}

.u-btn-gradient-v2:hover, .u-btn-gradient-v2:focus, .u-btn-gradient-v2.active {
  opacity: .9;
  color: #fff;
}

/* Gradient Style v1 */
.u-btn-outline-gradient-v1 {
  color: #555;
  background: transparent;
  -o-border-image: linear-gradient(to right, #4776E6 0%, #8E54E9 100%);
     border-image: linear-gradient(to right, #4776E6 0%, #8E54E9 100%);
  border-image-slice: 1;
}

.u-btn-outline-gradient-v1:hover, .u-btn-outline-gradient-v1:focus, .u-btn-outline-gradient-v1.active {
  background: linear-gradient(to right, #4776E6 0%, #8E54E9 100%);
  color: #fff;
}

/* Gradient Style v2 */
.u-btn-outline-gradient-v2 {
  color: #555;
  background: transparent;
  -o-border-image: linear-gradient(to right, #E040FB 0%, #00BCD4 100%);
     border-image: linear-gradient(to right, #E040FB 0%, #00BCD4 100%);
  border-image-slice: 1;
}

.u-btn-outline-gradient-v2:hover, .u-btn-outline-gradient-v2:focus, .u-btn-outline-gradient-v2.active {
  background: linear-gradient(to right, #E040FB 0%, #00BCD4 100%);
  color: #fff;
}

/* Material Styles
------------------------------------*/
.u-btn-raised,
.u-btn-raised:active:focus,
.u-btn-raised:focus {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

/*------------------------------------
  Material: Floating
------------------------------------*/
.u-btn-floating {
  width: 2.85714rem;
  height: 2.85714rem;
  padding: 0;
  border-radius: 50%;
  vertical-align: middle;
  overflow: hidden;
  line-height: 2.85714rem;
}

.u-btn-floating i {
  font-size: 1.6rem;
  line-height: 2.71429rem;
}

.u-btn-floating.u-halfway-fab {
  position: absolute;
  right: 1.71429rem;
  bottom: -1.42857rem;
}

.u-btn-floating.u-halfway-fab--left {
  right: auto;
  left: 1.71429rem;
}

.u-btn-floating--large {
  width: 4rem;
  height: 4rem;
  line-height: 4rem;
}

.u-btn-floating--large i {
  font-size: 1.6rem;
  line-height: 3.85714rem;
}

.u-btn-floating--large.u-halfway-fab {
  bottom: -2rem;
}

button.u-btn-floating {
  border: none;
}

/*------------------------------------
  Material: Flat
------------------------------------*/
.u-btn-flat {
  color: #555;
  background-color: transparent;
}

.u-btn-flat:hover {
  background-color: rgba(221, 221, 221, 0.4);
}

.u-btn-flat:hover, .u-btn-flat:focus {
  color: #555;
}

/*------------------------------------
  Box-shadows Styles
------------------------------------*/
.u-shadow-none {
  box-shadow: none !important;
}

.u-shadow-none--focus:focus {
  box-shadow: none !important;
}

.g-parent:hover .u-shadow-none--parent-hover {
  box-shadow: none !important;
}

/*------------------------------------
  Box-shadows-v1
------------------------------------*/
.u-shadow-v1-1 {
  box-shadow: 0 0 5px #999;
}

.u-shadow-v1-2 {
  box-shadow: 0 0 10px #999;
}

.u-shadow-v1-2--hover:hover {
  box-shadow: 0 0 10px #999;
}

.g-parent:hover .u-shadow-v1-2--hover-parent {
  box-shadow: 0 0 10px #999;
}

.u-shadow-v1-3 {
  box-shadow: 0 0 5px #ddd;
}

.u-shadow-v1-4 {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.u-shadow-v1-5 {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.u-shadow-v1-5--hover:hover {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

*:hover > .u-shadow-v1-5--hover-parent {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.u-shadow-v1-6 {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.u-shadow-v1-6--hover:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

*:hover > .u-shadow-v1-6--hover-parent {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.u-shadow-v1-7 {
  box-shadow: 0 0 8px #eee;
}

.u-shadow-v1-7--hover:hover {
  box-shadow: 0 0 8px #eee;
}

*:hover > .u-shadow-v1-7--hover-parent {
  box-shadow: 0 0 8px #eee;
}

/*------------------------------------
  Box-shadows-v2
------------------------------------*/
.u-shadow-v2 {
  box-shadow: 0 10px 6px -6px rgba(0, 0, 0, 0.2);
}

/*------------------------------------
  Box-shadows-v23
------------------------------------*/
.u-shadow-v23 {
  box-shadow: 0 15px 20px 0 rgba(0, 0, 0, 0.2);
}

/*------------------------------------
  Box-shadows-v27
------------------------------------*/
.u-shadow-v27 {
  box-shadow: 0 0 3px #b5b5b5;
}

/*------------------------------------
  Form Elements
------------------------------------*/
.u-form-control {
  padding: .8rem 1rem .6rem;
}

.u-form-control:-ms-input-placeholder {
  color: inherit;
  opacity: .3;
}

.u-form-control::placeholder {
  color: inherit;
  opacity: .3;
}

.u-form-control::-moz-placeholder {
  color: inherit;
  opacity: .3;
}

.u-form-control::-webkit-input-placeholder {
  color: inherit;
  opacity: .3;
}

.u-form-control-sm {
  padding: .4rem .5rem .1rem;
  font-size: .875rem;
}

.u-form-control-lg {
  padding: .75rem 1.5rem .55rem;
  font-size: 1.25rem;
}

.u-form-control-shadow--focus:focus {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.u-textarea-expandable {
  max-height: 42px;
  transition-property: max-height;
  transition-duration: .2s;
  transition-timing-function: ease-in;
}

.u-textarea-expandable:focus {
  max-height: 90px;
}

.u-input-group-addon {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center;
  border: 1px solid;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
}

.form-control + .u-input-group-addon:not(:first-child) {
  border-left: 0;
}

/*------------------------------------
  States
------------------------------------*/
/*------------------------------------
  Success states
------------------------------------*/
/*------------------------------------
  Success state v1-1
------------------------------------*/
.u-has-success-v1-1 .form-control,
.u-has-success-v1-1 [class*="input-group-"],
.u-has-success-v1-1 [class*="u-select"] {
  background-color: #f0fff0;
  border-color: #226f1c;
}

.u-has-success-v1-1 .form-control[readonly],
.u-has-success-v1-1 [class*="input-group-"][readonly],
.u-has-success-v1-1 [class*="u-select"][readonly] {
  background-color: #f0fff0;
}

.u-has-success-v1-1 .form-control-feedback {
  color: #226f1c;
}

.u-has-success-v1-1 [class*="u-check-icon"] {
  color: #f0fff0;
  background-color: #f0fff0;
}

.u-has-success-v1-1 [class*="u-check-icon"] i {
  color: rgba(34, 111, 28, 0.2);
}

.u-has-success-v1-1 [class*="input-group-"],
.u-has-success-v1-1 [class*="input-group-"]:last-child,
.u-has-success-v1-1 .chosen-single div b {
  color: #226f1c;
}

/*------------------------------------
  Success state v1-2
------------------------------------*/
.u-has-success-v1-2 {
  position: relative;
}

.u-has-success-v1-2 .form-control,
.u-has-success-v1-2 [class*="input-group-"],
.u-has-success-v1-2 [class*="u-select"] {
  background-color: #f0fff0;
  border-color: #226f1c;
}

.u-has-success-v1-2 .form-control[readonly],
.u-has-success-v1-2 [class*="input-group-"][readonly],
.u-has-success-v1-2 [class*="u-select"][readonly] {
  background-color: #f0fff0;
}

.u-has-success-v1-2 .form-control-feedback {
  color: #226f1c;
}

.u-has-success-v1-2 [class*="u-check-icon"] {
  color: #f0fff0;
  background-color: #f0fff0;
  border-radius: 50%;
}

.u-has-success-v1-2 [class*="u-check-icon"] i {
  color: rgba(34, 111, 28, 0.2);
}

.u-has-success-v1-2 [class*="input-group-"],
.u-has-success-v1-2 [class*="input-group-"]:last-child,
.u-has-success-v1-2 .chosen-single div b {
  color: #226f1c;
}

.u-has-success-v1-2 [class*="input-group-"],
.u-has-success-v1-2 [class*="input-group-"]:last-child {
  color: #fff;
  background-color: #226f1c;
}

.u-has-success-v1-2 .form-control-feedback::before {
  content: "";
  position: absolute;
  bottom: -3px;
  right: 18px;
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 3px 3px 0 3px;
  border-color: #226f1c transparent transparent transparent;
}

/*------------------------------------
  Error states
------------------------------------*/
/*------------------------------------
  Error state v1
------------------------------------*/
.u-has-error-v1 .form-control,
.u-has-error-v1 [class*="input-group-"],
.u-has-error-v1 [class*="u-select"] {
  background-color: #fff0f0;
}

.u-has-error-v1 .form-control[readonly],
.u-has-error-v1 [class*="input-group-"][readonly],
.u-has-error-v1 [class*="u-select"][readonly] {
  background-color: #fff0f0;
}

.u-has-error-v1 .form-control-feedback {
  color: #f00;
}

.u-has-error-v1 [class*="u-check-icon"] {
  background-color: #fff0f0;
}

/*------------------------------------
  Error state v1-2
------------------------------------*/
.u-has-error-v1-2 .form-control,
.u-has-error-v1-2 [class*="input-group-"],
.u-has-error-v1-2 [class*="u-select"] {
  background-color: #fff0f0;
  border-color: #f00;
}

.u-has-error-v1-2 .form-control[readonly],
.u-has-error-v1-2 [class*="input-group-"][readonly],
.u-has-error-v1-2 [class*="u-select"][readonly] {
  background-color: #fff0f0;
}

.u-has-error-v1-2 .form-control-feedback {
  color: #f00;
}

.u-has-error-v1-2 [class*="u-check-icon"] {
  background-color: #fff0f0;
}

.u-has-error-v1-2 [class*="input-group-"]:last-child,
.u-has-error-v1-2 .chosen-single div b {
  color: #f00;
}

.u-has-error-v1-2 .error {
  display: block;
}

/*------------------------------------
  Error state v1-3
------------------------------------*/
.u-has-error-v1-3 {
  position: relative;
}

.u-has-error-v1-3 .form-control,
.u-has-error-v1-3 [class*="input-group-"],
.u-has-error-v1-3 [class*="u-select"] {
  background-color: #fff0f0;
  border-color: #f00;
}

.u-has-error-v1-3 .form-control[readonly],
.u-has-error-v1-3 [class*="input-group-"][readonly],
.u-has-error-v1-3 [class*="u-select"][readonly] {
  background-color: #fff0f0;
}

.u-has-error-v1-3 .form-control-feedback {
  color: #f00;
}

.u-has-error-v1-3 .form-control-feedback::before {
  content: "";
  position: absolute;
  bottom: -3px;
  right: 18px;
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 3px 3px 0 3px;
  border-color: #f00 transparent transparent transparent;
}

.u-has-error-v1-3 [class*="u-check-icon"] {
  background-color: #fff0f0;
}

.u-has-error-v1-3 [class*="input-group-"]:last-child,
.u-has-error-v1-3 .chosen-single div b {
  color: #f00;
}

.u-has-error-v1-3 .error {
  display: block;
}

.u-has-error-v1-3 [class*="input-group-"],
.u-has-error-v1-3 [class*="input-group-"]:last-child {
  color: #fff;
  background-color: #f00;
}

/*------------------------------------
  Error state v2
------------------------------------*/
.u-has-error-v2:after {
  content: '\f00d';
  position: absolute;
  top: 50%;
  right: 15px;
  font-family: 'FontAwesome', sans-serif;
  color: #f00;
  -ms-transform: translateY(-50%);
      transform: translateY(-50%);
}

.u-has-error-v2 input ,
.u-has-error-v2 textarea {
  border-color: #f00;
}

/*------------------------------------
  Disabled states
------------------------------------*/
/*------------------------------------
  Disabled state v1
------------------------------------*/
.u-has-disabled-v1 label,
.u-has-disabled-v1 .form-control,
.u-has-disabled-v1 [class*="input-group-"],
.u-has-disabled-v1 [class*="u-select"] {
  background-color: #fff;
  opacity: .5;
}

.u-has-disabled-v1 label:disabled,
.u-has-disabled-v1 .form-control:disabled,
.u-has-disabled-v1 [class*="input-group-"]:disabled,
.u-has-disabled-v1 [class*="u-select"]:disabled {
  background-color: #fff;
  opacity: .5;
}

/*------------------------------------
  Checked state colors
------------------------------------*/
/* White Colors */
input[type="checkbox"]:checked + .g-color-white--checked,
input[type="radio"]:checked + .g-color-white--checked,
input[type="checkbox"]:checked + * .g-color-white--checked,
input[type="radio"]:checked + * .g-color-white--checked {
  color: #fff !important;
}

.g-color-white--checked.g-checked,
.g-checked .g-color-white--checked {
  color: #fff !important;
}

/*------------------------------------
  Focus state color
------------------------------------*/
/* Primary Colors */
.g-color-primary:focus {
  color: #226f1c;
}

/* Black Colors */
.g-color-black--focus:focus {
  color: #000;
}

/* White Colors */
.g-color-white--focus:focus {
  color: #fff !important;
}

/* Gray Colors */
.g-color-gray-light-v4:focus {
  color: #eee !important;
}

/*------------------------------------
  Checked state background
------------------------------------*/
/* Primary Colors */
input[type="checkbox"]:checked + .g-bg-primary--checked,
input[type="radio"]:checked + .g-bg-primary--checked,
input[type="checkbox"]:checked + * .g-bg-primary--checked,
input[type="radio"]:checked + * .g-bg-primary--checked {
  background-color: #226f1c !important;
}

.g-bg-primary--checked.g-checked,
.g-checked .g-bg-primary--checked {
  background-color: #226f1c !important;
}

/* Black Colors */
input[type="checkbox"]:checked + .g-bg-black--checked,
input[type="radio"]:checked + .g-bg-black--checked,
input[type="checkbox"]:checked + * .g-bg-black--checked,
input[type="radio"]:checked + * .g-bg-black--checked {
  background-color: #000 !important;
}

.g-bg-black--checked.g-checked,
.g-checked .g-bg-black--checked {
  background-color: #000 !important;
}

/* White Colors */
input[type="checkbox"]:checked + .g-bg-white--checked,
input[type="radio"]:checked + .g-bg-white--checked,
input[type="checkbox"]:checked + * .g-bg-white--checked,
input[type="radio"]:checked + * .g-bg-white--checked {
  background-color: #fff !important;
}

.g-bg-white--checked.g-checked,
.g-checked .g-bg-white--checked {
  background-color: #fff !important;
}

/* Gray Colors */
input[type="checkbox"]:checked + .g-bg-dark-dark-v1--checked,
input[type="radio"]:checked + .g-bg-dark-dark-v1--checked,
input[type="checkbox"]:checked + * .g-bg-dark-dark-v1--checked,
input[type="radio"]:checked + * .g-bg-dark-dark-v1--checked {
  background-color: #111 !important;
}

.g-bg-dark-dark-v1--checked.g-checked,
.g-checked .g-bg-dark-dark-v1--checked {
  background-color: #111 !important;
}

input[type="checkbox"]:checked + .g-bg-dark-dark-v2--checked,
input[type="radio"]:checked + .g-bg-dark-dark-v2--checked,
input[type="checkbox"]:checked + * .g-bg-dark-dark-v2--checked,
input[type="radio"]:checked + * .g-bg-dark-dark-v2--checked {
  background-color: #333 !important;
}

.g-bg-dark-dark-v2--checked.g-checked,
.g-checked .g-bg-dark-dark-v2--checked {
  background-color: #333 !important;
}

input[type="checkbox"]:checked + .g-bg-dark-dark-v3--checked,
input[type="radio"]:checked + .g-bg-dark-dark-v3--checked,
input[type="checkbox"]:checked + * .g-bg-dark-dark-v3--checked,
input[type="radio"]:checked + * .g-bg-dark-dark-v3--checked {
  background-color: #555 !important;
}

.g-bg-dark-dark-v3--checked.g-checked,
.g-checked .g-bg-dark-dark-v3--checked {
  background-color: #555 !important;
}

input[type="checkbox"]:checked + .g-bg-dark-dark-v4--checked,
input[type="radio"]:checked + .g-bg-dark-dark-v4--checked,
input[type="checkbox"]:checked + * .g-bg-dark-dark-v4--checked,
input[type="radio"]:checked + * .g-bg-dark-dark-v4--checked {
  background-color: #777 !important;
}

.g-bg-dark-dark-v4--checked.g-checked,
.g-checked .g-bg-dark-dark-v4--checked {
  background-color: #777 !important;
}

input[type="checkbox"]:checked + .g-bg-dark-dark-v5--checked,
input[type="radio"]:checked + .g-bg-dark-dark-v5--checked,
input[type="checkbox"]:checked + * .g-bg-dark-dark-v5--checked,
input[type="radio"]:checked + * .g-bg-dark-dark-v5--checked {
  background-color: #999 !important;
}

.g-bg-dark-dark-v5--checked.g-checked,
.g-checked .g-bg-dark-dark-v5--checked {
  background-color: #999 !important;
}

input[type="checkbox"]:checked + .g-bg-dark-light-v1--checked,
input[type="radio"]:checked + .g-bg-dark-light-v1--checked,
input[type="checkbox"]:checked + * .g-bg-dark-light-v1--checked,
input[type="radio"]:checked + * .g-bg-dark-light-v1--checked {
  background-color: #bbb !important;
}

.g-bg-dark-light-v1--checked.g-checked,
.g-checked .g-bg-dark-light-v1--checked {
  background-color: #bbb !important;
}

input[type="checkbox"]:checked + .g-bg-dark-light-v2--checked,
input[type="radio"]:checked + .g-bg-dark-light-v2--checked,
input[type="checkbox"]:checked + * .g-bg-dark-light-v2--checked,
input[type="radio"]:checked + * .g-bg-dark-light-v2--checked {
  background-color: #ccc !important;
}

.g-bg-dark-light-v2--checked.g-checked,
.g-checked .g-bg-dark-light-v2--checked {
  background-color: #ccc !important;
}

input[type="checkbox"]:checked + .g-bg-dark-light-v3--checked,
input[type="radio"]:checked + .g-bg-dark-light-v3--checked,
input[type="checkbox"]:checked + * .g-bg-dark-light-v3--checked,
input[type="radio"]:checked + * .g-bg-dark-light-v3--checked {
  background-color: #ddd !important;
}

.g-bg-dark-light-v3--checked.g-checked,
.g-checked .g-bg-dark-light-v3--checked {
  background-color: #ddd !important;
}

input[type="checkbox"]:checked + .g-bg-dark-light-v4--checked,
input[type="radio"]:checked + .g-bg-dark-light-v4--checked,
input[type="checkbox"]:checked + * .g-bg-dark-light-v4--checked,
input[type="radio"]:checked + * .g-bg-dark-light-v4--checked {
  background-color: #eee !important;
}

.g-bg-dark-light-v4--checked.g-checked,
.g-checked .g-bg-dark-light-v4--checked {
  background-color: #eee !important;
}

input[type="checkbox"]:checked + .g-bg-dark-light-v5--checked,
input[type="radio"]:checked + .g-bg-dark-light-v5--checked,
input[type="checkbox"]:checked + * .g-bg-dark-light-v5--checked,
input[type="radio"]:checked + * .g-bg-dark-light-v5--checked {
  background-color: #f7f7f7 !important;
}

.g-bg-dark-light-v5--checked.g-checked,
.g-checked .g-bg-dark-light-v5--checked {
  background-color: #f7f7f7 !important;
}

/* Transparent */
input[type="checkbox"]:checked + .g-bg-transparent--checked,
input[type="radio"]:checked + .g-bg-transparent--checked,
input[type="checkbox"]:checked + * .g-bg-transparent--checked,
input[type="radio"]:checked + * .g-bg-transparent--checked {
  background-color: transparent !important;
}

.g-bg-transparent--checked.g-checked,
.g-checked .g-bg-transparent--checked {
  background-color: transparent !important;
}

/* Color Red */
input[type="checkbox"]:checked + .g-bg-red--checked,
input[type="radio"]:checked + .g-bg-red--checked,
input[type="checkbox"]:checked + * .g-bg-red--checked,
input[type="radio"]:checked + * .g-bg-red--checked {
  background-color: #f00 !important;
}

.g-bg-red--checked.g-checked,
.g-checked .g-bg-red--checked {
  background-color: #f00 !important;
}

/*------------------------------------
  Checked state Colors
------------------------------------*/
/* Primary Colors */
input[type="checkbox"]:checked + .g-color-primary--checked,
input[type="radio"]:checked + .g-color-primary--checked,
input[type="checkbox"]:checked + * .g-color-primary--checked,
input[type="radio"]:checked + * .g-color-primary--checked {
  color: #226f1c !important;
}

.g-color-primary--checked.g-checked,
.g-checked .g-color-primary--checked {
  color: #226f1c !important;
}

/*------------------------------------
  Focus state background
------------------------------------*/
/* Black Colors */
.g-bg-black--focus:focus {
  background-color: #000 !important;
}

.g-bg-black-opacity-0_2--focus:focus {
  background-color: rgba(0, 0, 0, 0.2) !important;
}

.g-bg-black-opacity-0_4--focus:focus {
  background-color: rgba(0, 0, 0, 0.4) !important;
}

/* Gray Colors */
.g-bg-gray-dark-v1--focus:focus {
  background-color: #111 !important;
}

.g-bg-gray-dark-v2--focus:focus {
  background-color: #333 !important;
}

.g-bg-gray-dark-v3--focus:focus {
  background-color: #555 !important;
}

.g-bg-gray-light-v4--focus:focus {
  background-color: #eee !important;
}

.g-bg-gray-light-v5--focus:focus {
  background-color: #f7f7f7 !important;
}

/* Transparent */
.g-bg-transparent--focus:focus {
  background-color: transparent;
}

/*------------------------------------
  Checked state border
------------------------------------*/
/* Primary Colors */
input[type="checkbox"]:checked + .g-brd-primary--checked,
input[type="radio"]:checked + .g-brd-primary--checked,
input[type="checkbox"]:checked + * .g-brd-primary--checked,
input[type="radio"]:checked + * .g-brd-primary--checked {
  border-color: #226f1c !important;
}

.g-brd-primary--checked.g-checked,
.g-checked .g-brd-primary--checked {
  border-color: #226f1c !important;
}

/* Black Colors */
input[type="checkbox"]:checked + .g-brd-black--checked,
input[type="radio"]:checked + .g-brd-black--checked,
input[type="checkbox"]:checked + * .g-brd-black--checked,
input[type="radio"]:checked + * .g-brd-black--checked {
  border-color: #000 !important;
}

.g-brd-black--checked.g-checked,
.g-checked .g-brd-black--checked {
  border-color: #000 !important;
}

/* White */
input[type="checkbox"]:checked + .g-brd-white--checked,
input[type="radio"]:checked + .g-brd-white--checked,
input[type="checkbox"]:checked + * .g-brd-white--checked,
input[type="radio"]:checked + * .g-brd-white--checked {
  border-color: #fff !important;
}

.g-brd-white--checked.g-checked,
.g-checked .g-brd-white--checked {
  border-color: #fff !important;
}

/* Gray Colors */
input[type="checkbox"]:checked + .g-brd-gray-dark-v1--checked,
input[type="radio"]:checked + .g-brd-gray-dark-v1--checked,
input[type="checkbox"]:checked + * .g-brd-gray-dark-v1--checked,
input[type="radio"]:checked + * .g-brd-gray-dark-v1--checked {
  border-color: #111 !important;
}

.g-brd-gray-dark-v1--checked.g-checked,
.g-checked .g-brd-gray-dark-v1--checked {
  border-color: #111 !important;
}

input[type="checkbox"]:checked + .g-brd-gray-dark-v2--checked,
input[type="radio"]:checked + .g-brd-gray-dark-v2--checked,
input[type="checkbox"]:checked + * .g-brd-gray-dark-v2--checked,
input[type="radio"]:checked + * .g-brd-gray-dark-v2--checked {
  border-color: #333 !important;
}

.g-brd-gray-dark-v2--checked.g-checked,
.g-checked .g-brd-gray-dark-v2--checked {
  border-color: #333 !important;
}

input[type="checkbox"]:checked + .g-brd-gray-dark-v3--checked,
input[type="radio"]:checked + .g-brd-gray-dark-v3--checked,
input[type="checkbox"]:checked + * .g-brd-gray-dark-v3--checked,
input[type="radio"]:checked + * .g-brd-gray-dark-v3--checked {
  border-color: #555 !important;
}

.g-brd-gray-dark-v3--checked.g-checked,
.g-checked .g-brd-gray-dark-v3--checked {
  border-color: #555 !important;
}

input[type="checkbox"]:checked + .g-brd-gray-dark-v4--checked,
input[type="radio"]:checked + .g-brd-gray-dark-v4--checked,
input[type="checkbox"]:checked + * .g-brd-gray-dark-v4--checked,
input[type="radio"]:checked + * .g-brd-gray-dark-v4--checked {
  border-color: #777 !important;
}

.g-brd-gray-dark-v4--checked.g-checked,
.g-checked .g-brd-gray-dark-v4--checked {
  border-color: #777 !important;
}

input[type="checkbox"]:checked + .g-brd-gray-dark-v5--checked,
input[type="radio"]:checked + .g-brd-gray-dark-v5--checked,
input[type="checkbox"]:checked + * .g-brd-gray-dark-v5--checked,
input[type="radio"]:checked + * .g-brd-gray-dark-v5--checked {
  border-color: #999 !important;
}

.g-brd-gray-dark-v5--checked.g-checked,
.g-checked .g-brd-gray-dark-v5--checked {
  border-color: #999 !important;
}

input[type="checkbox"]:checked + .g-brd-gray-light-v1--checked,
input[type="radio"]:checked + .g-brd-gray-light-v1--checked,
input[type="checkbox"]:checked + * .g-brd-gray-light-v1--checked,
input[type="radio"]:checked + * .g-brd-gray-light-v1--checked {
  border-color: #bbb !important;
}

.g-brd-gray-light-v1--checked.g-checked,
.g-checked .g-brd-gray-light-v1--checked {
  border-color: #bbb !important;
}

input[type="checkbox"]:checked + .g-brd-gray-light-v2--checked,
input[type="radio"]:checked + .g-brd-gray-light-v2--checked,
input[type="checkbox"]:checked + * .g-brd-gray-light-v2--checked,
input[type="radio"]:checked + * .g-brd-gray-light-v2--checked {
  border-color: #ccc !important;
}

.g-brd-gray-light-v2--checked.g-checked,
.g-checked .g-brd-gray-light-v2--checked {
  border-color: #ccc !important;
}

input[type="checkbox"]:checked + .g-brd-gray-light-v3--checked,
input[type="radio"]:checked + .g-brd-gray-light-v3--checked,
input[type="checkbox"]:checked + * .g-brd-gray-light-v3--checked,
input[type="radio"]:checked + * .g-brd-gray-light-v3--checked {
  border-color: #ddd !important;
}

.g-brd-gray-light-v3--checked.g-checked,
.g-checked .g-brd-gray-light-v3--checked {
  border-color: #ddd !important;
}

input[type="checkbox"]:checked + .g-brd-gray-light-v4--checked,
input[type="radio"]:checked + .g-brd-gray-light-v4--checked,
input[type="checkbox"]:checked + * .g-brd-gray-light-v4--checked,
input[type="radio"]:checked + * .g-brd-gray-light-v4--checked {
  border-color: #eee !important;
}

.g-brd-gray-light-v4--checked.g-checked,
.g-checked .g-brd-gray-light-v4--checked {
  border-color: #eee !important;
}

input[type="checkbox"]:checked + .g-brd-gray-light-v5--checked,
input[type="radio"]:checked + .g-brd-gray-light-v5--checked,
input[type="checkbox"]:checked + * .g-brd-gray-light-v5--checked,
input[type="radio"]:checked + * .g-brd-gray-light-v5--checked {
  border-color: #f7f7f7 !important;
}

.g-brd-gray-light-v5--checked.g-checked,
.g-checked .g-brd-gray-light-v5--checked {
  border-color: #f7f7f7 !important;
}

/* Transparent */
input[type="checkbox"]:checked + .g-brd-transparent--checked,
input[type="radio"]:checked + .g-brd-transparent--checked,
input[type="checkbox"]:checked + * .g-brd-transparent--checked,
input[type="radio"]:checked + * .g-brd-transparent--checked {
  border-color: transparent !important;
}

.g-brd-transparent--checked.g-checked,
.g-checked .g-brd-transparent--checked {
  border-color: transparent !important;
}

/* Color Red */
input[type="checkbox"]:checked + .g-brd-red--checked,
input[type="radio"]:checked + .g-brd-red--checked,
input[type="checkbox"]:checked + * .g-brd-red--checked,
input[type="radio"]:checked + * .g-brd-red--checked {
  border-color: #f00 !important;
}

.g-brd-red--checked.g-checked,
.g-checked .g-brd-red--checked {
  border-color: #f00 !important;
}

/*------------------------------------
  Focus state border
------------------------------------*/
/* Primary Colors */
.g-brd-primary--focus:focus,
.g-brd-primary--focus.g-state-focus *,
.g-brd-primary--focus.g-state-focus *:focus {
  border-color: #226f1c !important;
}

/* Black Colors */
.g-brd-black--focus:focus,
.g-brd-black--focus.g-state-focus *,
.g-brd-black--focus.g-state-focus *:focus {
  border-color: #000 !important;
}

/* White */
.g-brd-white--focus:focus,
.g-brd-white--focus.g-state-focus *,
.g-brd-white--focus.g-state-focus *:focus {
  border-color: #fff !important;
}

/* Gray Colors */
.g-brd-gray-dark-v1--focus:focus,
.g-brd-gray-dark-v1--focus.g-state-focus *,
.g-brd-gray-dark-v1--focus.g-state-focus *:focus {
  border-color: #111 !important;
}

.g-brd-gray-dark-v2--focus:focus,
.g-brd-gray-dark-v2--focus.g-state-focus *,
.g-brd-gray-dark-v2--focus.g-state-focus *:focus {
  border-color: #333 !important;
}

.g-brd-gray-dark-v3--focus:focus,
.g-brd-gray-dark-v3--focus.g-state-focus *,
.g-brd-gray-dark-v3--focus.g-state-focus *:focus {
  border-color: #555 !important;
}

.g-brd-gray-dark-v4--focus:focus,
.g-brd-gray-dark-v4--focus.g-state-focus *,
.g-brd-gray-dark-v4--focus.g-state-focus *:focus {
  border-color: #777 !important;
}

.g-brd-gray-dark-v5--focus:focus,
.g-brd-gray-dark-v5--focus.g-state-focus *,
.g-brd-gray-dark-v5--focus.g-state-focus *:focus {
  border-color: #999 !important;
}

.g-brd-gray-dark-light-v1--focus:focus,
.g-brd-gray-dark-light-v1--focus.g-state-focus *,
.g-brd-gray-dark-light-v1--focus.g-state-focus *:focus {
  border-color: #bbb !important;
}

.g-brd-gray-dark-light-v2--focus:focus,
.g-brd-gray-dark-light-v2--focus.g-state-focus *,
.g-brd-gray-dark-light-v2--focus.g-state-focus *:focus {
  border-color: #ccc !important;
}

.g-brd-gray-dark-light-v3--focus:focus,
.g-brd-gray-dark-light-v3--focus.g-state-focus *,
.g-brd-gray-dark-light-v3--focus.g-state-focus *:focus {
  border-color: #ddd !important;
}

.g-brd-gray-dark-light-v4--focus:focus,
.g-brd-gray-dark-light-v4--focus.g-state-focus *,
.g-brd-gray-dark-light-v4--focus.g-state-focus *:focus {
  border-color: #eee !important;
}

.g-brd-gray-dark-light-v5--focus:focus,
.g-brd-gray-dark-light-v5--focus.g-state-focus *,
.g-brd-gray-dark-light-v5--focus.g-state-focus *:focus {
  border-color: #f7f7f7 !important;
}

/* Transparent */
.g-brd-transparent--focus:focus,
.g-brd-transparent--focus.g-state-focus *,
.g-brd-transparent--focus.g-state-focus *:focus {
  border-color: transparent !important;
}

/* Color Red */
.g-brd-red--focus:focus,
.g-brd-red--focus.g-state-focus *,
.g-brd-red--focus.g-state-focus *:focus {
  border-color: #f00 !important;
}

/*------------------------------------
  Checked state box shadow
------------------------------------*/
input[type="checkbox"]:checked + .u-shadow-v1-5--checked,
input[type="radio"]:checked + .u-shadow-v1-5--checked,
input[type="checkbox"]:checked + * .u-shadow-v1-5--checked,
input[type="radio"]:checked + * .u-shadow-v1-5--checked {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.u-shadow-v1-5--checked.g-checked,
.g-checked .u-shadow-v1-5--checked {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

input[type="checkbox"]:checked + .u-shadow-v1-v6--checked,
input[type="radio"]:checked + .u-shadow-v1-v6--checked,
input[type="checkbox"]:checked + * .u-shadow-v1-v6--checked,
input[type="radio"]:checked + * .u-shadow-v1-v6--checked {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.u-shadow-v1-v6--checked.g-checked,
.g-checked .u-shadow-v1-v6--checked {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

/*------------------------------------
  Checked state opacity
------------------------------------*/
input[type="checkbox"]:checked + .g-opacity-1--checked,
input[type="radio"]:checked + .g-opacity-1--checked,
input[type="checkbox"]:checked + * .g-opacity-1--checked,
input[type="radio"]:checked + * .g-opacity-1--checked {
  opacity: 1;
}

.g-opacity-1--checked.g-checked,
.g-checked .g-opacity-1--checked {
  opacity: 1;
}

/*------------------------------------
  Checked state overlay
------------------------------------*/
input[type="checkbox"]:checked + .g-overlay-black-0_5--checked::after,
input[type="radio"]:checked + .g-overlay-black-0_5--checked::after,
input[type="checkbox"]:checked + * > .g-overlay-black-0_5--checked::after,
input[type="radio"]:checked + * > .g-overlay-black-0_5--checked::after {
  background-color: rgba(0, 0, 0, 0.5);
}

input[type="checkbox"]:checked + .g-overlay-black-0_7--checked::after,
input[type="radio"]:checked + .g-overlay-black-0_7--checked::after,
input[type="checkbox"]:checked + * > .g-overlay-black-0_7--checked::after,
input[type="radio"]:checked + * > .g-overlay-black-0_7--checked::after {
  background-color: rgba(0, 0, 0, 0.7);
}

input[type="checkbox"]:checked + .g-overlay-black-gradient-v1--checked::after,
input[type="radio"]:checked + .g-overlay-black-gradient-v1--checked::after,
input[type="checkbox"]:checked + * > .g-overlay-black-gradient-v1--checked::after,
input[type="radio"]:checked + * > .g-overlay-black-gradient-v1--checked::after {
  background-image: linear-gradient(to bottom, transparent 40%, #000 100%);
}

input[type="checkbox"]:checked + .g-overlay-primary-0_5--checked::after,
input[type="radio"]:checked + .g-overlay-primary-0_5--checked::after,
input[type="checkbox"]:checked + * .g-overlay-primary-0_5--checked::after,
input[type="radio"]:checked + * .g-overlay-primary-0_5--checked::after {
  background-color: rgba(34, 111, 28, 0.5);
}

.g-overlay-primary-0_5--checked.g-checked::after,
.g-checked .g-overlay-primary-0_5--checked::after {
  background-color: rgba(34, 111, 28, 0.5);
}

input[type="checkbox"]:checked + .g-overlay-primary-0_9--checked::after,
input[type="radio"]:checked + .g-overlay-primary-0_9--checked::after,
input[type="checkbox"]:checked + * .g-overlay-primary-0_9--checked::after,
input[type="radio"]:checked + * .g-overlay-primary-0_9--checked::after {
  background-color: rgba(34, 111, 28, 0.9);
}

.g-overlay-primary-0_9--checked.g-checked::after,
.g-checked .g-overlay-primary-0_9--checked::after {
  background-color: rgba(34, 111, 28, 0.9);
}

/*------------------------------------
  Fields
------------------------------------*/
[data-capitalize] {
  text-transform: uppercase;
}

label.error {
  color: #d9534f;
  margin-top: .25rem;
  margin-bottom: 0;
}

input .error,
textarea.error,
select.error {
  border-color: #d9534f !important;
}

.js-autocomplete--ajax {
  position: relative;
  z-index: 10;
}

/*------------------------------------
  Fields Group v1
------------------------------------*/
.u-input-group-v1 {
  position: relative;
}

.u-input-group-v1 input ,
.u-input-group-v1 textarea,
.u-input-group-v1 [class*="u-select"] {
  line-height: 1.75rem;
  padding: 40px 15px 10px;
}

.u-input-group-v1 input :focus + label, .u-input-group-v1 input [value] + label, .u-input-group-v1 input [placeholder] + label, .u-input-group-v1 input .g-state-not-empty + label,
.u-input-group-v1 textarea:focus + label,
.u-input-group-v1 textarea[value] + label,
.u-input-group-v1 textarea[placeholder] + label,
.u-input-group-v1 textarea.g-state-not-empty + label,
.u-input-group-v1 [class*="u-select"]:focus + label,
.u-input-group-v1 [class*="u-select"][value] + label,
.u-input-group-v1 [class*="u-select"][placeholder] + label,
.u-input-group-v1 [class*="u-select"].g-state-not-empty + label {
  top: 20px;
  font-size: 90%;
}

.u-input-group-v1 label {
  position: absolute;
  top: 50%;
  left: 15px;
  transition-property: top, font-size;
  transition-duration: .2s;
  transition-timing-function: ease;
  -ms-transform: translateY(-50%);
      transform: translateY(-50%);
  margin-bottom: 0;
}

/*------------------------------------
  Fields Group v2
------------------------------------*/
.u-input-group-v2 {
  position: relative;
}

.u-input-group-v2 input ,
.u-input-group-v2 textarea,
.u-input-group-v2 [class*="u-select"] {
  line-height: 1.75rem;
  padding: 10px 15px;
}

.u-input-group-v2 input :focus + label, .u-input-group-v2 input [value] + label, .u-input-group-v2 input [placeholder] + label, .u-input-group-v2 input .g-state-not-empty + label,
.u-input-group-v2 textarea:focus + label,
.u-input-group-v2 textarea[value] + label,
.u-input-group-v2 textarea[placeholder] + label,
.u-input-group-v2 textarea.g-state-not-empty + label,
.u-input-group-v2 [class*="u-select"]:focus + label,
.u-input-group-v2 [class*="u-select"][value] + label,
.u-input-group-v2 [class*="u-select"][placeholder] + label,
.u-input-group-v2 [class*="u-select"].g-state-not-empty + label {
  top: 0;
  font-size: 90%;
}

.u-input-group-v2 label {
  position: absolute;
  top: 50%;
  left: 10px;
  background-color: #fff;
  padding: 0 5px;
  margin-bottom: 0;
  transition-property: top, font-size;
  transition-duration: .2s;
  transition-timing-function: ease;
  -ms-transform: translateY(-50%);
      transform: translateY(-50%);
}

.u-input-group-v2 textarea + label {
  top: 20px;
}

/*------------------------------------
  Fields Group v3
------------------------------------*/
.u-input-group-v3 input ,
.u-input-group-v3 textarea,
.u-input-group-v3 [class*="u-select"] {
  margin-top: -5px;
  border-color: rgba(204, 204, 204, 0.5);
  border-style: solid;
  border-width: 0 0 1px;
  transition: all .3s ease 0s;
}

.u-input-group-v3 input :focus,
.u-input-group-v3 textarea:focus,
.u-input-group-v3 [class*="u-select"]:focus {
  border-color: rgba(34, 111, 28, 0.5);
  box-shadow: 0 1px 0 0 #226f1c;
}

.u-input-group-v3 label {
  margin-bottom: 0;
}

/*------------------------------------
  Fields Group v4
------------------------------------*/
.u-input-group-v4 {
  position: relative;
}

.u-input-group-v4 input ,
.u-input-group-v4 textarea,
.u-input-group-v4 [class*="u-select"] {
  background-color: transparent;
  border-color: rgba(204, 204, 204, 0.5);
  border-style: solid;
  border-width: 0 0 1px;
}

.u-input-group-v4 input :focus, .u-input-group-v4 input [value], .u-input-group-v4 input [placeholder], .u-input-group-v4 input .g-state-not-empty,
.u-input-group-v4 textarea:focus,
.u-input-group-v4 textarea[value],
.u-input-group-v4 textarea[placeholder],
.u-input-group-v4 textarea.g-state-not-empty,
.u-input-group-v4 [class*="u-select"]:focus,
.u-input-group-v4 [class*="u-select"][value],
.u-input-group-v4 [class*="u-select"][placeholder],
.u-input-group-v4 [class*="u-select"].g-state-not-empty {
  background-color: transparent;
  border-color: rgba(34, 111, 28, 0.5);
  box-shadow: 0 1px 0 0 #226f1c;
}

.u-input-group-v4 input :focus + label, .u-input-group-v4 input [value] + label, .u-input-group-v4 input [placeholder] + label, .u-input-group-v4 input .g-state-not-empty + label,
.u-input-group-v4 textarea:focus + label,
.u-input-group-v4 textarea[value] + label,
.u-input-group-v4 textarea[placeholder] + label,
.u-input-group-v4 textarea.g-state-not-empty + label,
.u-input-group-v4 [class*="u-select"]:focus + label,
.u-input-group-v4 [class*="u-select"][value] + label,
.u-input-group-v4 [class*="u-select"][placeholder] + label,
.u-input-group-v4 [class*="u-select"].g-state-not-empty + label {
  top: 0;
  font-size: 90%;
}

.u-input-group-v4 label {
  position: absolute;
  top: 50%;
  left: 0;
  margin-bottom: 0;
  background-color: transparent;
  color: #999;
  transition-property: top, font-size;
  transition-duration: .2s;
  transition-timing-function: ease;
  -ms-transform: translateY(-50%);
      transform: translateY(-50%);
}

.u-input-group-v4 textarea + label {
  top: 20px;
}

/*------------------------------------
  Checkboxes
------------------------------------*/
.u-check {
  position: relative;
  cursor: pointer;
}

.u-check-icon-font {
  display: inline-block;
  font-size: 0;
  padding-left: 1px;
  padding-right: 1px;
}

.u-check-icon-font i {
  font-size: 22px;
  color: #ccc;
}

.u-check-icon-font i::before {
  content: attr(data-uncheck-icon);
}

.u-check-icon-checkbox, .u-check-icon-radio {
  display: inline-block;
}

.u-check input[type="checkbox"]:checked + .u-check-icon-font i,
.u-check input[type="checkbox"]:checked + * .u-check-icon-font i,
.u-check input[type="radio"]:checked + .u-check-icon-font i,
.u-check input[type="radio"]:checked + * .u-check-icon-font i {
  color: #226f1c;
}

.u-check input[type="checkbox"]:checked + .u-check-icon-font i::before,
.u-check input[type="checkbox"]:checked + * .u-check-icon-font i::before,
.u-check input[type="radio"]:checked + .u-check-icon-font i::before,
.u-check input[type="radio"]:checked + * .u-check-icon-font i::before {
  content: attr(data-check-icon);
}

.g-hide-check {
  display: block !important;
}

.g-checked > * .g-hide-check,
input[type="checkbox"]:checked + .g-hide-check,
input[type="radio"]:checked + .g-hide-check,
input[type="checkbox"]:checked + * .g-hide-check,
input[type="radio"]:checked + * .g-hide-check {
  display: none !important;
}

.g-show-check {
  display: none !important;
}

.g-checked > * .g-show-check,
input[type="checkbox"]:checked + .g-show-check,
input[type="radio"]:checked + .g-show-check,
input[type="checkbox"]:checked + * .g-show-check,
input[type="radio"]:checked + * .g-show-check {
  display: block !important;
}

/*------------------------------------
  Checkboxes v1
------------------------------------*/
.u-check-icon-checkbox-v1, .u-check-icon-radio-v1 {
  background-color: #fff;
  border: solid 1px #ccc;
}

.u-check-icon-checkbox-v1 {
  border-radius: 1px;
}

.u-check-icon-radio-v1 {
  border-radius: 50%;
}

.u-check input[type="checkbox"]:checked + .u-check-icon-checkbox-v1,
.u-check input[type="checkbox"]:checked + * .u-check-icon-checkbox-v1,
.u-check input[type="radio"]:checked + .u-check-icon-checkbox-v1,
.u-check input[type="radio"]:checked + * .u-check-icon-checkbox-v1 {
  color: #fff;
  background-color: #226f1c;
  border-color: #226f1c;
}

.u-check input[type="checkbox"]:checked + .u-check-icon-checkbox-v1::before,
.u-check input[type="checkbox"]:checked + * .u-check-icon-checkbox-v1::before,
.u-check input[type="radio"]:checked + .u-check-icon-checkbox-v1::before,
.u-check input[type="radio"]:checked + * .u-check-icon-checkbox-v1::before {
  content: attr(data-check-icon);
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 12px;
  -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
}

.u-check input[type="checkbox"]:checked + .u-check-icon-radio-v1,
.u-check input[type="checkbox"]:checked + * .u-check-icon-radio-v1,
.u-check input[type="radio"]:checked + .u-check-icon-radio-v1,
.u-check input[type="radio"]:checked + * .u-check-icon-radio-v1 {
  border-width: 4px;
  border-color: #226f1c;
}

/*------------------------------------
  Checkboxes v2
------------------------------------*/
.u-check-icon-checkbox-v2, .u-check-icon-radio-v2 {
  width: 20px;
  height: 20px;
  border: solid 1px #226f1c;
}

.u-check-icon-checkbox-v2 {
  border-radius: 1px;
}

.u-check-icon-radio-v2 {
  border-radius: 50%;
}

/*------------------------------------
  Checkboxes v3
------------------------------------*/
.u-check-icon-checkbox-v3, .u-check-icon-radio-v3 {
  width: 40px;
  height: 40px;
  border: solid 1px #226f1c;
  border-radius: 50%;
}

/*------------------------------------
  Checkboxes v4
------------------------------------*/
.u-check-icon-checkbox-v4, .u-check-icon-radio-v4 {
  width: 18px;
  height: 18px;
  font-size: 12px;
  border: solid 1px #ccc;
}

.u-check-icon-checkbox-v4 i::before, .u-check-icon-radio-v4 i::before {
  content: attr(data-uncheck-icon);
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
}

.u-check-icon-radio-v4 {
  border-radius: 50%;
}

.u-check-icon-radio-v4 i {
  border-radius: 50%;
}

.u-check input[type="checkbox"]:checked + .u-check-icon-checkbox-v4,
.u-check input[type="checkbox"]:checked + .u-check-icon-radio-v4,
.u-check input[type="checkbox"]:checked + * .u-check-icon-checkbox-v4,
.u-check input[type="checkbox"]:checked + * .u-check-icon-radio-v4,
.u-check input[type="radio"]:checked + .u-check-icon-checkbox-v4,
.u-check input[type="radio"]:checked + .u-check-icon-radio-v4,
.u-check input[type="radio"]:checked + * .u-check-icon-checkbox-v4,
.u-check input[type="radio"]:checked + * .u-check-icon-radio-v4 {
  color: #226f1c;
  border-color: #226f1c;
}

.u-check input[type="checkbox"]:checked + .u-check-icon-checkbox-v4 i::before,
.u-check input[type="checkbox"]:checked + .u-check-icon-radio-v4 i::before,
.u-check input[type="checkbox"]:checked + * .u-check-icon-checkbox-v4 i::before,
.u-check input[type="checkbox"]:checked + * .u-check-icon-radio-v4 i::before,
.u-check input[type="radio"]:checked + .u-check-icon-checkbox-v4 i::before,
.u-check input[type="radio"]:checked + .u-check-icon-radio-v4 i::before,
.u-check input[type="radio"]:checked + * .u-check-icon-checkbox-v4 i::before,
.u-check input[type="radio"]:checked + * .u-check-icon-radio-v4 i::before {
  content: attr(data-check-icon);
}

/*------------------------------------
  Checkboxes v5
------------------------------------*/
.u-check-icon-checkbox-v5, .u-check-icon-radio-v5 {
  width: 18px;
  height: 18px;
}

.u-check-icon-checkbox-v5 i, .u-check-icon-radio-v5 i {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 100%;
  height: 100%;
  background-color: #fff;
  border: solid 1px #ccc;
  -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
}

.u-check-icon-checkbox-v5 {
  border-radius: 1px;
}

.u-check-icon-radio-v5 {
  border-radius: 50%;
}

.u-check-icon-radio-v5 i {
  border-radius: 50%;
}

.u-check input[type="checkbox"]:checked + .u-check-icon-checkbox-v5 i,
.u-check input[type="checkbox"]:checked + .u-check-icon-radio-v5 i,
.u-check input[type="checkbox"]:checked + * .u-check-icon-checkbox-v5 i,
.u-check input[type="checkbox"]:checked + * .u-check-icon-radio-v5 i,
.u-check input[type="radio"]:checked + .u-check-icon-checkbox-v5 i,
.u-check input[type="radio"]:checked + .u-check-icon-radio-v5 i,
.u-check input[type="radio"]:checked + * .u-check-icon-checkbox-v5 i,
.u-check input[type="radio"]:checked + * .u-check-icon-radio-v5 i {
  border-width: 4px;
  border-color: #226f1c;
}

/*------------------------------------
  Checkboxes v6
------------------------------------*/
.u-check-icon-checkbox-v6, .u-check-icon-radio-v6 {
  width: 18px;
  height: 18px;
  font-size: 12px;
}

.u-check-icon-checkbox-v6 i, .u-check-icon-radio-v6 i {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 100%;
  height: 100%;
  border: solid 1px #ccc;
  -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
}

.u-check-icon-checkbox-v6 i::before, .u-check-icon-radio-v6 i::before {
  content: attr(data-uncheck-icon);
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
}

.u-check-icon-radio-v6 {
  border-radius: 50%;
}

.u-check-icon-radio-v6 i {
  border-radius: 50%;
}

.u-check input[type="checkbox"]:checked + .u-check-icon-checkbox-v6 i,
.u-check input[type="checkbox"]:checked + .u-check-icon-radio-v6 i,
.u-check input[type="checkbox"]:checked + * .u-check-icon-checkbox-v6 i,
.u-check input[type="checkbox"]:checked + * .u-check-icon-radio-v6 i,
.u-check input[type="radio"]:checked + .u-check-icon-checkbox-v6 i,
.u-check input[type="radio"]:checked + .u-check-icon-radio-v6 i,
.u-check input[type="radio"]:checked + * .u-check-icon-checkbox-v6 i,
.u-check input[type="radio"]:checked + * .u-check-icon-radio-v6 i {
  color: #fff;
  background-color: #226f1c;
  border-color: #226f1c;
}

.u-check input[type="checkbox"]:checked + .u-check-icon-checkbox-v6 i::before,
.u-check input[type="checkbox"]:checked + .u-check-icon-radio-v6 i::before,
.u-check input[type="checkbox"]:checked + * .u-check-icon-checkbox-v6 i::before,
.u-check input[type="checkbox"]:checked + * .u-check-icon-radio-v6 i::before,
.u-check input[type="radio"]:checked + .u-check-icon-checkbox-v6 i::before,
.u-check input[type="radio"]:checked + .u-check-icon-radio-v6 i::before,
.u-check input[type="radio"]:checked + * .u-check-icon-checkbox-v6 i::before,
.u-check input[type="radio"]:checked + * .u-check-icon-radio-v6 i::before {
  content: attr(data-check-icon);
}

/*------------------------------------
  Checkboxes v7
------------------------------------*/
.u-check-icon-checkbox-v7, .u-check-icon-radio-v7 {
  cursor: pointer;
  display: block;
  width: 43px;
  height: 22px;
  font-style: normal;
  font-weight: 700;
  font-size: 9px;
  color: #ddd;
  border: solid 1px #ddd;
  border-radius: 12px;
}

.u-check-icon-checkbox-v7 i::before, .u-check-icon-checkbox-v7 i::after, .u-check-icon-radio-v7 i::before, .u-check-icon-radio-v7 i::after {
  content: "";
  display: block;
  position: absolute;
}

.u-check-icon-checkbox-v7 i::before, .u-check-icon-radio-v7 i::before {
  content: attr(data-uncheck-icon);
  top: 0;
  left: 0;
  width: 100%;
  height: 22px;
  line-height: 18px;
  text-transform: uppercase;
  text-align: right;
  padding: 2px 7px;
}

.u-check-icon-checkbox-v7 i::after, .u-check-icon-radio-v7 i::after {
  top: 50%;
  left: 4px;
  width: 16px;
  height: 16px;
  background-color: #ddd;
  border-radius: 50%;
  -ms-transform: translateY(-50%);
      transform: translateY(-50%);
  transition-property: left;
  transition-duration: .1s;
  transition-timing-function: ease-in;
}

.u-check input[type="checkbox"]:checked + .u-check-icon-checkbox-v7,
.u-check input[type="checkbox"]:checked + .u-check-icon-radio-v7,
.u-check input[type="checkbox"]:checked + * .u-check-icon-checkbox-v7,
.u-check input[type="checkbox"]:checked + * .u-check-icon-radio-v7,
.u-check input[type="radio"]:checked + .u-check-icon-checkbox-v7,
.u-check input[type="radio"]:checked + .u-check-icon-radio-v7,
.u-check input[type="radio"]:checked + * .u-check-icon-checkbox-v7,
.u-check input[type="radio"]:checked + * .u-check-icon-radio-v7 {
  color: #fff;
  background-color: #226f1c;
  border-color: #226f1c;
}

.u-check input[type="checkbox"]:checked + .u-check-icon-checkbox-v7 i:before,
.u-check input[type="checkbox"]:checked + .u-check-icon-radio-v7 i:before,
.u-check input[type="checkbox"]:checked + * .u-check-icon-checkbox-v7 i:before,
.u-check input[type="checkbox"]:checked + * .u-check-icon-radio-v7 i:before,
.u-check input[type="radio"]:checked + .u-check-icon-checkbox-v7 i:before,
.u-check input[type="radio"]:checked + .u-check-icon-radio-v7 i:before,
.u-check input[type="radio"]:checked + * .u-check-icon-checkbox-v7 i:before,
.u-check input[type="radio"]:checked + * .u-check-icon-radio-v7 i:before {
  content: attr(data-check-icon);
  text-align: left;
}

.u-check input[type="checkbox"]:checked + .u-check-icon-checkbox-v7 i::after,
.u-check input[type="checkbox"]:checked + .u-check-icon-radio-v7 i::after,
.u-check input[type="checkbox"]:checked + * .u-check-icon-checkbox-v7 i::after,
.u-check input[type="checkbox"]:checked + * .u-check-icon-radio-v7 i::after,
.u-check input[type="radio"]:checked + .u-check-icon-checkbox-v7 i::after,
.u-check input[type="radio"]:checked + .u-check-icon-radio-v7 i::after,
.u-check input[type="radio"]:checked + * .u-check-icon-checkbox-v7 i::after,
.u-check input[type="radio"]:checked + * .u-check-icon-radio-v7 i::after {
  left: calc(100% - 19px);
  background-color: #fff;
}

/*------------------------------------
  Checkboxes v7
------------------------------------*/
.u-check-icon-checkbox-v8, .u-check-icon-radio-v8 {
  cursor: pointer;
  display: block;
  width: 43px;
  height: 22px;
  font-style: normal;
  font-weight: 700;
  font-size: 9px;
  color: #ddd;
  border: solid 1px #ddd;
  border-radius: 12px;
}

.u-check-icon-checkbox-v8 i::before, .u-check-icon-checkbox-v8 i::after, .u-check-icon-radio-v8 i::before, .u-check-icon-radio-v8 i::after {
  content: "";
  display: block;
  position: absolute;
}

.u-check-icon-checkbox-v8 i::before, .u-check-icon-radio-v8 i::before {
  content: attr(data-uncheck-icon);
  top: 0;
  left: 0;
  width: 100%;
  height: 22px;
  line-height: 18px;
  text-transform: uppercase;
  text-align: right;
  padding: 2px 7px;
}

.u-check-icon-checkbox-v8 i::after, .u-check-icon-radio-v8 i::after {
  top: 50%;
  left: 4px;
  width: 16px;
  height: 16px;
  background-color: #ddd;
  border-radius: 50%;
  -ms-transform: translateY(-50%);
      transform: translateY(-50%);
  transition-property: left;
  transition-duration: .1s;
  transition-timing-function: ease-in;
}

.u-check input[type="checkbox"]:checked + .u-check-icon-checkbox-v8,
.u-check input[type="checkbox"]:checked + .u-check-icon-radio-v8,
.u-check input[type="checkbox"]:checked + * .u-check-icon-checkbox-v8,
.u-check input[type="checkbox"]:checked + * .u-check-icon-radio-v8,
.u-check input[type="radio"]:checked + .u-check-icon-checkbox-v8,
.u-check input[type="radio"]:checked + .u-check-icon-radio-v8,
.u-check input[type="radio"]:checked + * .u-check-icon-checkbox-v8,
.u-check input[type="radio"]:checked + * .u-check-icon-radio-v8 {
  color: #226f1c;
  border-color: #226f1c;
}

.u-check input[type="checkbox"]:checked + .u-check-icon-checkbox-v8 i:before,
.u-check input[type="checkbox"]:checked + .u-check-icon-radio-v8 i:before,
.u-check input[type="checkbox"]:checked + * .u-check-icon-checkbox-v8 i:before,
.u-check input[type="checkbox"]:checked + * .u-check-icon-radio-v8 i:before,
.u-check input[type="radio"]:checked + .u-check-icon-checkbox-v8 i:before,
.u-check input[type="radio"]:checked + .u-check-icon-radio-v8 i:before,
.u-check input[type="radio"]:checked + * .u-check-icon-checkbox-v8 i:before,
.u-check input[type="radio"]:checked + * .u-check-icon-radio-v8 i:before {
  content: attr(data-check-icon);
  text-align: left;
}

.u-check input[type="checkbox"]:checked + .u-check-icon-checkbox-v8 i::after,
.u-check input[type="checkbox"]:checked + .u-check-icon-radio-v8 i::after,
.u-check input[type="checkbox"]:checked + * .u-check-icon-checkbox-v8 i::after,
.u-check input[type="checkbox"]:checked + * .u-check-icon-radio-v8 i::after,
.u-check input[type="radio"]:checked + .u-check-icon-checkbox-v8 i::after,
.u-check input[type="radio"]:checked + .u-check-icon-radio-v8 i::after,
.u-check input[type="radio"]:checked + * .u-check-icon-checkbox-v8 i::after,
.u-check input[type="radio"]:checked + * .u-check-icon-radio-v8 i::after {
  left: calc(100% - 19px);
  background-color: #226f1c;
}

/* P */
[class*="u-checkbox-v1"] {
  display: none;
}

[class*="u-checkbox-v1"] + label {
  cursor: pointer;
}

.u-checkbox-v1--checked-color-primary:checked + label {
  color: #226f1c !important;
}

.u-checkbox-v1--checked-brd-primary:checked + label {
  border-color: #226f1c !important;
}

/*------------------------------------
  File Attachments
------------------------------------*/
/*------------------------------------
  File Attachments v1
------------------------------------*/
.u-file-attach-v1 {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.u-file-attach-v1 input[type="file"] {
  position: absolute;
  top: -25%;
  left: -25%;
  z-index: 10;
  width: 150%;
  height: 150%;
  opacity: 0;
  cursor: pointer;
}

.u-file-attach-v1 input[readonly] {
  background-color: transparent;
}

/*------------------------------------
  File Attachments v2
------------------------------------*/
.u-file-attach-v2 {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.u-file-attach-v2 input[type="file"] {
  position: absolute;
  top: -25%;
  left: -25%;
  z-index: 10;
  width: 150%;
  height: 150%;
  opacity: 0;
  cursor: pointer;
}

/*------------------------------------
  File Attachments v2
------------------------------------*/
.u-file-attach-v3 {
  cursor: pointer;
  position: relative;
  text-align: center;
  background-color: #f7f7f7;
  overflow: hidden;
  border: 1px dashed #ccc;
  padding: 60px;
  transition-property: background-color;
  transition-duration: .2s;
  transition-timing-function: ease-in;
}

.u-file-attach-v3 input[type="file"] {
  position: absolute;
  top: -25%;
  left: -25%;
  z-index: 10;
  width: 150%;
  height: 150%;
  opacity: 0;
  cursor: pointer;
}

.u-file-attach-v3:hover {
  background-color: #eee;
}

/*------------------------------------
  Selects
------------------------------------*/
.input-group select {
  -webkit-appearance: none;
}

/*------------------------------------
  Selects v1
------------------------------------*/
.u-select-v1 {
  position: relative;
  max-width: 100%;
  font-size: 1rem;
  color: rgba(153, 153, 153, 0.3);
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  border-color: #ccc;
  padding: .4rem 1rem;
}

.u-select-v1 .chosen-single {
  position: static;
  height: auto;
  color: inherit;
  background-image: none;
  background-color: transparent;
  border: none;
  border-radius: 0;
  box-shadow: none;
  padding: 0;
  line-height: inherit;
}

.u-select-v1 .chosen-single span {
  margin-right: 0;
}

.u-select-v1 .chosen-single span img {
  position: relative;
  top: -2px;
  margin-right: 4px;
}

.u-select-v1 .chosen-single div {
  width: 40px;
}

.u-select-v1 .chosen-single div b {
  background: none !important;
}

.u-select-v1 .chosen-single div b i {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
}

.u-select-v1 .chosen-single div b i:first-child {
  display: inline-block;
}

.u-select-v1 .chosen-single div b i:last-child {
  display: none;
}

.u-select-v1 .chosen-single:focus {
  outline: 0 none;
}

.u-select-v1 .chosen-drop {
  width: calc(100% + 2px);
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  border-color: inherit;
  border-radius: 0;
  border-top-width: 1px;
  margin-left: -1px;
}

.u-select-v1 .chosen-results {
  padding: 0;
  margin: 0;
}

.u-select-v1 .chosen-results > li {
  position: relative;
  font-size: inherit;
  color: #999;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #ccc;
  padding: 12px 16px;
}

.u-select-v1 .chosen-results > li img {
  position: relative;
  top: -2px;
  margin-right: 4px;
}

.u-select-v1 .chosen-results > li div {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 100%;
}

.u-select-v1 .chosen-results > li div b {
  display: block;
  width: 100%;
  height: 100%;
}

.u-select-v1 .chosen-results > li div b i {
  position: absolute;
  top: 50%;
  left: 50%;
  display: none;
  -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
}

.u-select-v1 .chosen-results > li:last-child {
  border-bottom-width: 0;
}

.u-select-v1 .chosen-results > li.highlighted {
  color: #999;
  background-image: none;
  background-color: rgba(34, 111, 28, 0.2);
  border-bottom-color: rgba(34, 111, 28, 0.2);
}

.u-select-v1 .chosen-results > li.highlighted.g-color-white--active {
  color: #fff !important;
}

.u-select-v1 .chosen-results > li.highlighted.g-bg-primary--active {
  background-color: #226f1c !important;
}

.u-select-v1 .chosen-results > li.result-selected {
  color: #999;
  background-color: rgba(34, 111, 28, 0.2);
  border-bottom-color: rgba(34, 111, 28, 0.2);
}

.u-select-v1 .chosen-results > li.result-selected div b i {
  display: inline-block;
}

.u-select-v1.chosen-container-active .chosen-single {
  background-image: none;
  box-shadow: none;
}

.u-select-v1.chosen-with-drop .chosen-single {
  border: none;
  border-radius: 0;
}

.u-select-v1.chosen-with-drop .chosen-single div b i:first-child {
  display: none;
}

.u-select-v1.chosen-with-drop .chosen-single div b i:last-child {
  display: inline-block;
}

.u-select-v1.chosen-container-multi:not(.u-select-multiple-custom) .chosen-choices {
  background-image: none;
  border: none;
  box-shadow: none;
  padding: 0;
}

.u-select-v1.chosen-container-multi:not(.u-select-multiple-custom) .chosen-choices .search-choice {
  font-size: 12px;
  color: #999;
  background-image: none;
  background-color: #fff;
  border-color: #ccc;
  border-radius: 0;
  padding: 5px 20px 5px 5px;
}

.u-select-v1.chosen-container-multi:not(.u-select-multiple-custom) .chosen-choices .search-choice-close {
  top: 50%;
  -ms-transform: translateY(-50%);
      transform: translateY(-50%);
}

.u-select-v1.chosen-container-multi:not(.u-select-multiple-custom) .chosen-choices .search-field {
  height: 22px;
}

.u-select-v1.chosen-container-multi:not(.u-select-multiple-custom) .chosen-choices .search-field input[type="text"] {
  height: 22px;
  margin: 0;
}

.u-select-v1.u-select-multiple-custom .chosen-choices {
  display: none;
}

.u-select-v1.u-select-multiple-custom .chosen-drop {
  width: 100%;
  position: static;
  top: auto;
  left: auto;
  z-index: 3;
  border: none;
  box-shadow: none;
  margin-left: 0;
}

.u-select-v1.u-dropdown-sm {
  padding: .1rem .5rem;
  font-size: .875rem;
}

.u-select-v1.u-dropdown-sm .chosen-results > li {
  padding: 6px 8px;
}

.u-select-v1.u-dropdown-lg {
  padding: .55rem 1.5rem;
  font-size: 1.25rem;
}

.u-select-v1.u-dropdown-lg .chosen-results > li {
  padding: 14px 18px;
}

/*------------------------------------
  Selects v2
------------------------------------*/
.u-select-v2 {
  position: relative;
  max-width: 100%;
  font-size: 1rem;
  color: rgba(153, 153, 153, 0.3);
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  border-color: #ccc;
  padding: .4rem 1rem;
}

.u-select-v2 .chosen-single {
  position: static;
  height: auto;
  color: inherit;
  background-image: none;
  background-color: transparent;
  border: none;
  border-radius: 0;
  box-shadow: none;
  padding: 0;
  line-height: inherit;
}

.u-select-v2 .chosen-single span {
  margin-right: 0;
}

.u-select-v2 .chosen-single span img {
  position: relative;
  top: -2px;
  margin-right: 4px;
}

.u-select-v2 .chosen-single div {
  width: 50px;
}

.u-select-v2 .chosen-single div b {
  background: none !important;
}

.u-select-v2 .chosen-single div b i {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
}

.u-select-v2 .chosen-single div b i:first-child {
  display: inline-block;
}

.u-select-v2 .chosen-single div b i:last-child {
  display: none;
}

.u-select-v2 .chosen-single:focus {
  outline: 0 none;
}

.u-select-v2 .chosen-drop,
.u-select-v2 .chosen-results {
  border-radius: 3px;
}

.u-select-v2 .chosen-drop {
  width: calc(100% + 2px);
  box-sizing: border-box;
  border: none;
  box-shadow: 0 6px 15px -6px rgba(0, 0, 0, 0.1);
  margin-left: -1px;
  margin-top: 7px;
}

.u-select-v2 .chosen-results {
  padding: 0;
  margin: 0;
}

.u-select-v2 .chosen-results > li {
  position: relative;
  font-size: inherit;
  color: #999;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #ccc;
  padding: 12px 16px;
}

.u-select-v2 .chosen-results > li img {
  position: relative;
  top: -2px;
  margin-right: 4px;
}

.u-select-v2 .chosen-results > li div {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 100%;
}

.u-select-v2 .chosen-results > li div b {
  display: block;
  width: 100%;
  height: 100%;
}

.u-select-v2 .chosen-results > li div b i {
  position: absolute;
  top: 50%;
  left: 50%;
  display: none;
  -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
}

.u-select-v2 .chosen-results > li:last-child {
  border-bottom-width: 0;
}

.u-select-v2 .chosen-results > li.highlighted {
  color: #999;
  background-image: none;
  background-color: rgba(34, 111, 28, 0.2);
  border-bottom-color: rgba(34, 111, 28, 0.2);
}

.u-select-v2 .chosen-results > li.highlighted.g-color-white--active {
  color: #fff !important;
}

.u-select-v2 .chosen-results > li.highlighted.g-bg-primary--active {
  background-color: #226f1c !important;
}

.u-select-v2 .chosen-results > li.result-selected {
  color: #999;
  background-color: rgba(34, 111, 28, 0.2);
  border-bottom-color: rgba(34, 111, 28, 0.2);
}

.u-select-v2 .chosen-results > li.result-selected div b i {
  display: inline-block;
}

.u-select-v2.chosen-container-active .chosen-single {
  background-image: none;
  box-shadow: none;
}

.u-select-v2.chosen-with-drop .chosen-single {
  border: none;
  border-radius: 0;
}

.u-select-v2.chosen-with-drop .chosen-single div b i:first-child {
  display: none;
}

.u-select-v2.chosen-with-drop .chosen-single div b i:last-child {
  display: inline-block;
}

.u-select-v2.chosen-container-multi:not(.u-select-multiple-custom) .chosen-choices {
  background-image: none;
  border: none;
  box-shadow: none;
  padding: 0;
}

.u-select-v2.chosen-container-multi:not(.u-select-multiple-custom) .chosen-choices .search-choice {
  font-size: 12px;
  color: #999;
  background-image: none;
  background-color: #fff;
  border-color: #ccc;
  border-radius: 0;
  padding: 5px 20px 5px 5px;
}

.u-select-v2.chosen-container-multi:not(.u-select-multiple-custom) .chosen-choices .search-choice-close {
  top: 50%;
  -ms-transform: translateY(-50%);
      transform: translateY(-50%);
}

.u-select-v2.chosen-container-multi:not(.u-select-multiple-custom) .chosen-choices .search-field {
  height: 22px;
}

.u-select-v2.chosen-container-multi:not(.u-select-multiple-custom) .chosen-choices .search-field input[type="text"] {
  height: 22px;
  margin: 0;
}

.u-select-v2.u-select-multiple-custom .chosen-choices {
  display: none;
}

.u-select-v2.u-select-multiple-custom .chosen-drop {
  width: 100%;
  position: static;
  top: auto;
  left: auto;
  z-index: 3;
  border: none;
  box-shadow: none;
  margin-left: 0;
}

.u-select-v2.u-dropdown-sm {
  padding: .1rem .5rem;
  font-size: .875rem;
}

.u-select-v2.u-dropdown-sm .chosen-results > li {
  padding: 6px 8px;
}

.u-select-v2.u-dropdown-lg {
  padding: .55rem 1.5rem;
  font-size: 1.25rem;
}

.u-select-v2.u-dropdown-lg .chosen-results > li {
  padding: 14px 18px;
}

/*------------------------------------
  Sliders
------------------------------------*/
/*------------------------------------
  Sliders v1
------------------------------------*/
.u-slider-v1.ui-slider, .u-slider-v1-2.ui-slider, .u-slider-v1-3.ui-slider {
  position: relative;
  background: #eee;
  border: none;
  border-radius: 0;
  margin-top: 12px;
  margin-left: 6px;
  margin-right: 6px;
}

.u-slider-v1.ui-slider .ui-slider-range, .u-slider-v1-2.ui-slider .ui-slider-range, .u-slider-v1-3.ui-slider .ui-slider-range {
  height: 100%;
}

.u-slider-v1.ui-slider .ui-slider-handle, .u-slider-v1-2.ui-slider .ui-slider-handle, .u-slider-v1-3.ui-slider .ui-slider-handle {
  position: absolute;
  top: 50%;
  border-style: solid;
  outline: none;
  background: #fff;
  border-radius: 0;
  cursor: pointer;
  transition-property: border-color;
  transition-duration: .3s;
  transition-timing-function: ease;
}

.u-slider-v1.ui-slider.ui-slider-content, .u-slider-v1-2.ui-slider.ui-slider-content, .u-slider-v1-3.ui-slider.ui-slider-content {
  border-color: #eee;
}

.u-slider-v1.ui-slider {
  height: 2px;
}

.u-slider-v1.ui-slider .ui-slider-range {
  background: #226f1c;
}

.u-slider-v1.ui-slider .ui-slider-handle {
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-width: 2px;
  border-color: #226f1c;
}

.u-slider-v1-2.ui-slider, .u-slider-v1-3.ui-slider {
  height: 4px;
}

.u-slider-v1-2.ui-slider .ui-slider-range, .u-slider-v1-3.ui-slider .ui-slider-range {
  background: #ddd;
}

.u-slider-v1-2.ui-slider .ui-slider-handle, .u-slider-v1-3.ui-slider .ui-slider-handle {
  width: 15px;
  height: 15px;
  margin-top: -8px;
  margin-left: -8px;
  border-width: 2px;
  border-color: #226f1c;
}

.u-slider-v1-3.ui-slider .ui-slider-handle {
  border-radius: 50%;
}

/*------------------------------------
  Sliders v2
------------------------------------*/
.u-slider-v2.ui-slider, .u-slider-v2-2.ui-slider, .u-slider-v2-3.ui-slider {
  position: relative;
  background: rgba(34, 111, 28, 0.5);
  border: none;
  border-radius: 0;
  margin-top: 12px;
  margin-left: 6px;
  margin-right: 6px;
}

.u-slider-v2.ui-slider .ui-slider-range, .u-slider-v2-2.ui-slider .ui-slider-range, .u-slider-v2-3.ui-slider .ui-slider-range {
  height: 100%;
}

.u-slider-v2.ui-slider .ui-slider-handle, .u-slider-v2-2.ui-slider .ui-slider-handle, .u-slider-v2-3.ui-slider .ui-slider-handle {
  position: absolute;
  top: 50%;
  border-style: solid;
  outline: none;
  background: #226f1c;
  border-radius: 0;
  cursor: pointer;
  transition-property: border-color, transform;
  transition-duration: .3s;
  transition-timing-function: ease;
}

.u-slider-v2.ui-slider .ui-slider-handle.ui-state-active, .u-slider-v2-2.ui-slider .ui-slider-handle.ui-state-active, .u-slider-v2-3.ui-slider .ui-slider-handle.ui-state-active {
  -ms-transform: scale(1.5);
      transform: scale(1.5);
}

.u-slider-v2.ui-slider {
  height: 2px;
}

.u-slider-v2.ui-slider .ui-slider-range {
  background: #226f1c;
}

.u-slider-v2.ui-slider .ui-slider-handle {
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-width: 2px;
  border-color: #226f1c;
}

.u-slider-v2-2.ui-slider, .u-slider-v2-3.ui-slider {
  height: 4px;
}

.u-slider-v2-2.ui-slider .ui-slider-range, .u-slider-v2-3.ui-slider .ui-slider-range {
  background: #226f1c;
}

.u-slider-v2-2.ui-slider .ui-slider-handle, .u-slider-v2-3.ui-slider .ui-slider-handle {
  width: 15px;
  height: 15px;
  margin-top: -8px;
  margin-left: -8px;
  border-width: 2px;
  border-color: #226f1c;
}

.u-slider-v2-3.ui-slider .ui-slider-handle {
  border-radius: 50%;
}

/*------------------------------------
  Datepickers
------------------------------------*/
.u-has-dropdowns-in-popup .ui-autocomplete,
.u-has-dropdowns-in-popup .ui-datepicker {
  z-index: 100000 !important;
}

/*------------------------------------
  Datepickers v1
------------------------------------*/
.u-datepicker-v1 {
  border-width: 1px;
  border-style: solid;
  border-radius: 0;
  z-index: 2 !important;
}

.u-datepicker-v1.ui-datepicker-inline {
  width: 100%;
}

.u-datepicker-v1.ui-datepicker {
  width: initial;
  padding: initial;
}

.u-datepicker-v1 .ui-datepicker {
  width: 100%;
  padding: 0;
  border: none;
}

.u-datepicker-v1 .ui-datepicker-header {
  background-color: transparent;
  border: none;
  border-radius: 0;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #ccc;
  padding: 0;
}

.u-datepicker-v1 .ui-datepicker-title {
  height: 45px;
  line-height: 45px;
  font-size: 16px;
  font-weight: 400;
  color: #999;
  margin-left: 45px;
  margin-right: 45px;
}

.u-datepicker-v1 .ui-datepicker-prev, .u-datepicker-v1 .ui-datepicker-next {
  width: 45px;
  height: 45px;
  line-height: 45px;
  top: 0;
  text-align: center;
  border-radius: 0;
  cursor: pointer;
}

.u-datepicker-v1 .ui-datepicker-prev > span, .u-datepicker-v1 .ui-datepicker-next > span {
  position: static;
  top: 0;
  left: 0;
  display: inline-block;
  width: auto;
  height: auto;
  font-size: 18px;
  color: #999;
  background-image: none;
  text-indent: 0;
  margin-top: 0;
  margin-left: 0;
}

.u-datepicker-v1 .ui-datepicker-prev-hover, .u-datepicker-v1 .ui-datepicker-next-hover {
  top: 0;
  background-color: transparent;
}

.u-datepicker-v1 .ui-datepicker-prev.ui-state-hover, .u-datepicker-v1 .ui-datepicker-next.ui-state-hover {
  border: none;
}

.u-datepicker-v1 .ui-datepicker-prev {
  left: 0;
  border-right-width: 1px;
  border-right-style: solid;
  border-right-color: #ccc;
}

.u-datepicker-v1 .ui-datepicker-prev-hover {
  left: 0;
}

.u-datepicker-v1 .ui-datepicker-prev.ui-state-hover {
  border-right-width: 1px;
  border-right-style: solid;
  border-right-color: #ccc;
}

.u-datepicker-v1 .ui-datepicker-next {
  right: 0;
  border-left-width: 1px;
  border-left-style: solid;
  border-left-color: #ccc;
}

.u-datepicker-v1 .ui-datepicker-next-hover {
  right: 0;
}

.u-datepicker-v1 .ui-datepicker-next.ui-state-hover {
  border-left-width: 1px;
  border-left-style: solid;
  border-left-color: #ccc;
}

.u-datepicker-v1 .ui-datepicker-calendar {
  margin-bottom: 0;
}

.u-datepicker-v1 .ui-datepicker-calendar th,
.u-datepicker-v1 .ui-datepicker-calendar td {
  text-align: center;
  padding: 3px;
}

.u-datepicker-v1 .ui-datepicker-calendar th span,
.u-datepicker-v1 .ui-datepicker-calendar th a,
.u-datepicker-v1 .ui-datepicker-calendar td span,
.u-datepicker-v1 .ui-datepicker-calendar td a {
  display: inline-block;
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  color: #555;
  background-color: transparent;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  padding: 0;
}

.u-datepicker-v1 .ui-datepicker-calendar th a,
.u-datepicker-v1 .ui-datepicker-calendar td a {
  border-radius: 50%;
  transition: all .2s;
}

.u-datepicker-v1 .ui-datepicker-calendar th a:hover, .u-datepicker-v1 .ui-datepicker-calendar th a.ui-state-active,
.u-datepicker-v1 .ui-datepicker-calendar td a:hover,
.u-datepicker-v1 .ui-datepicker-calendar td a.ui-state-active {
  color: #fff;
  background-color: #226f1c;
  border-color: #226f1c;
  transition: all .2s;
}

.u-datepicker-v1 .ui-datepicker-calendar th span,
.u-datepicker-v1 .ui-datepicker-calendar th a {
  font-size: 12px;
  font-weight: 400;
  color: #bbb;
  text-transform: uppercase;
}

.u-datepicker-v1 .ui-datepicker-calendar td span,
.u-datepicker-v1 .ui-datepicker-calendar td a {
  font-size: 14px;
}

@media (min-width: 768px) {
  .u-datepicker-v1 .ui-datepicker-title {
    height: 50px;
    line-height: 50px;
    margin-left: 50px;
    margin-right: 50px;
  }
  .u-datepicker-v1 .ui-datepicker-prev, .u-datepicker-v1 .ui-datepicker-next {
    width: 50px;
    height: 50px;
    line-height: 50px;
  }
  .u-datepicker-v1 .ui-datepicker-calendar {
    border-collapse: separate;
    border-spacing: 4px;
  }
  .u-datepicker-v1 .ui-datepicker-calendar th,
  .u-datepicker-v1 .ui-datepicker-calendar td {
    padding: 5px;
  }
  .u-datepicker-v1 .ui-datepicker-calendar th span,
  .u-datepicker-v1 .ui-datepicker-calendar th a,
  .u-datepicker-v1 .ui-datepicker-calendar td span,
  .u-datepicker-v1 .ui-datepicker-calendar td a {
    font-size: 14px;
  }
}

/*------------------------------------
  Quantity
------------------------------------*/
.u-quantity-v1 input {
  background-color: #fff !important;
}

.js-plus,
.js-minus {
  cursor: pointer;
}

/*
@import "base/carousel-indicators/carousel-indicators";
@import "base/carousel-indicators/carousel-indicators-v1";
@import "base/carousel-indicators/carousel-indicators-v2";
@import "base/carousel-indicators/carousel-indicators-v3";
@import "base/carousel-indicators/carousel-indicators-v4";
@import "base/carousel-indicators/carousel-indicators-v5";
@import "base/carousel-indicators/carousel-indicators-v6";
@import "base/carousel-indicators/carousel-indicators-v7";
@import "base/carousel-indicators/carousel-indicators-v8";
@import "base/carousel-indicators/carousel-indicators-v9";
@import "base/carousel-indicators/carousel-indicators-v10";
@import "base/carousel-indicators/carousel-indicators-v11";
@import "base/carousel-indicators/carousel-indicators-v12";
@import "base/carousel-indicators/carousel-indicators-v13";
@import "base/carousel-indicators/carousel-indicators-v14";
@import "base/carousel-indicators/carousel-indicators-v15";
@import "base/carousel-indicators/carousel-indicators-v16";
@import "base/carousel-indicators/carousel-indicators-v17";
@import "base/carousel-indicators/carousel-indicators-v18";
@import "base/carousel-indicators/carousel-indicators-v19";
@import "base/carousel-indicators/carousel-indicators-v20";
@import "base/carousel-indicators/carousel-indicators-v21";
@import "base/carousel-indicators/carousel-indicators-v22";
@import "base/carousel-indicators/carousel-indicators-v23";
@import "base/carousel-indicators/carousel-indicators-v24";
@import "base/carousel-indicators/carousel-indicators-v25";
@import "base/carousel-indicators/carousel-indicators-v26";
@import "base/carousel-indicators/carousel-indicators-v27";
@import "base/carousel-indicators/carousel-indicators-v28";
@import "base/carousel-indicators/carousel-indicators-v29";
@import "base/carousel-indicators/carousel-indicators-v30";
@import "base/carousel-indicators/carousel-indicators-v31";
@import "base/carousel-indicators/carousel-indicators-v32";
@import "base/carousel-indicators/carousel-indicators-v33";
@import "base/carousel-indicators/carousel-indicators-v34";
@import "base/carousel-indicators/carousel-indicators-v35";
*/
/*
@import "base/breadcrumbs/breadcrumbs-v1";
@import "base/breadcrumbs/breadcrumbs-v2";
*/
/*
@import "base/dot-line/dot-line-v1";
@import "base/dot-line/dot-line-v2";
*/
/*------------------------------------
  Heading-v2
------------------------------------*/
.u-heading-v2-1--bottom::after, .u-heading-v2-1--top::before, .u-heading-v2-2--bottom::after, .u-heading-v2-2--top::before, .u-heading-v2-3--bottom::after, .u-heading-v2-3--top::before, .u-heading-v2-4--bottom::after, .u-heading-v2-4--top::before, .u-heading-v2-5--bottom::after, .u-heading-v2-5--top::before, .u-heading-v2-6--bottom::after, .u-heading-v2-6--top::before, .u-heading-v2-7--bottom::after, .u-heading-v2-7--top::before {
  content: "";
  display: inline-block;
  border-top-style: solid;
  border-color: inherit;
}

/*------------------------------------
  Heading-v2-1
------------------------------------*/
.u-heading-v2-1--bottom::after, .u-heading-v2-1--top::before {
  width: 3.14286rem;
  border-top-width: 2px;
}

.u-heading-v2-1--bottom::after {
  margin-top: 1.78571rem;
}

.u-heading-v2-1--top::before {
  margin-bottom: 1.78571rem;
}

/*------------------------------------
  Heading-v2-2
------------------------------------*/
.u-heading-v2-2--bottom::after, .u-heading-v2-2--top::before {
  width: 2.71429rem;
  border-top-width: 5px;
}

.u-heading-v2-2--bottom::after {
  margin-top: 1.78571rem;
}

.u-heading-v2-2--top::before {
  margin-bottom: 1.78571rem;
}

/*------------------------------------
  Heading-v2-3
------------------------------------*/
.u-heading-v2-3--bottom::after, .u-heading-v2-3--top::before {
  width: 5rem;
  border-top-width: 1px;
}

.u-heading-v2-3--bottom::after {
  margin-top: 1.07143rem;
}

.u-heading-v2-3--top::before {
  margin-bottom: 1.07143rem;
}

/*------------------------------------
  Heading-v2-4
------------------------------------*/
.u-heading-v2-4--bottom::after, .u-heading-v2-4--top::before {
  width: 5rem;
  border-top-width: 10px;
}

.u-heading-v2-4--bottom::after {
  margin-top: 2.14286rem;
}

.u-heading-v2-4--top::before {
  margin-bottom: 2.14286rem;
}

/*------------------------------------
  Heading-v2-5
------------------------------------*/
.u-heading-v2-5--bottom::after, .u-heading-v2-5--top::before {
  width: 5rem;
  border-top-width: 2px;
}

.u-heading-v2-5--bottom::after {
  margin-top: 1.42857rem;
}

.u-heading-v2-5--top::before {
  margin-bottom: 1.42857rem;
}

/*------------------------------------
  Heading-v2-6
------------------------------------*/
.u-heading-v2-6--bottom::after, .u-heading-v2-6--top::before {
  width: 2.14286rem;
  border-top-width: 1px;
}

.u-heading-v2-6--bottom::after {
  margin-top: 0.71429rem;
}

.u-heading-v2-6--top::before {
  margin-bottom: 0.71429rem;
}

/*------------------------------------
  Heading-v2-7
------------------------------------*/
.u-heading-v2-7--bottom::after, .u-heading-v2-7--top::before {
  width: 12rem;
  border-top-width: 10px;
}

.u-heading-v2-7--bottom::after {
  margin-top: 2.85714rem;
}

.u-heading-v2-7--top::before {
  margin-bottom: 2.85714rem;
}

/*------------------------------------
  Heading-v8
------------------------------------*/
.u-heading-v8-1 .u-heading-v8__title strong, .u-heading-v8-2 .u-heading-v8__title strong {
  display: inline-block;
  font-weight: inherit;
}

/*------------------------------------
  Heading-v8-1
------------------------------------*/
.u-heading-v8-1 .u-heading-v8__title strong {
  padding: 0.07143rem 0.5rem;
}

/*------------------------------------
  Heading-v8-2
------------------------------------*/
.u-heading-v8-2 .u-heading-v8__title strong {
  padding: 0.71429rem 1.07143rem;
}

/*------------------------------------
  Background Colors
------------------------------------*/
.u-label {
  display: inline-block;
  padding: .35rem .58rem;
  font-size: .9rem;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  color: #fff;
  /* Label Size
  ------------------------------------*/
  /* Label Styles
  ------------------------------------*/
  /* Label Num
  ------------------------------------*/
}

.u-label:empty {
  display: none;
}

.btn .u-label {
  position: relative;
  top: -1px;
}

.u-label-default {
  background-color: #777;
}

.u-label-default[href]:hover, .u-label-default[href]:focus {
  background-color: #555;
}

.u-label-primary {
  background-color: #5cb85c;
}

.u-label-primary[href]:hover, .u-label-primary[href]:focus {
  background-color: #55b555;
}

.u-label-success {
  background-color: #5cb85c;
}

.u-label-success[href]:hover, .u-label-success[href]:focus {
  background-color: #55b555;
}

.u-label-info {
  background-color: #5bc0de;
}

.u-label-info[href]:hover, .u-label-info[href]:focus {
  background-color: #53bddc;
}

.u-label-warning {
  background-color: #f0ad4e;
}

.u-label-warning[href]:hover, .u-label-warning[href]:focus {
  background-color: #efa945;
}

.u-label-danger {
  background-color: #d9534f;
}

.u-label-danger[href]:hover, .u-label-danger[href]:focus {
  background-color: #d74b47;
}

.u-label.g-rounded-10 {
  padding: .35rem .7rem;
}

.u-label--sm {
  font-size: .8rem;
}

.u-label--lg {
  font-size: 1.1rem;
}

.u-label.u-label-with-icon {
  padding: .5rem .85rem;
}

.u-label.u-label-with-icon i {
  margin-right: .5rem;
}

.u-label-num {
  min-width: 2rem;
  height: 2rem;
  padding: 0 .35rem;
  line-height: 2rem;
}

.u-label-num.u-label--sm {
  min-width: 1.6rem;
  height: 1.6rem;
  line-height: 1.6rem;
}

.u-label-num.u-label--lg {
  min-width: 2.2rem;
  height: 2.2rem;
  line-height: 2.2rem;
}

/*------------------------------------
  Link Styles
------------------------------------*/
.u-link-v1 {
  border-bottom: solid 1px;
}

.u-link-v1:hover, .u-link-v1:focus {
  border-bottom: none;
  text-decoration: none;
}

.u-link-v2 {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  text-indent: -100%;
  white-space: nowrap;
  overflow: hidden;
  z-index: 2;
}

.u-link-v3 {
  text-decoration: underline;
}

.u-link-v3:hover {
  text-decoration: none;
}

.u-link-v4 {
  transition: all .3s;
}

.u-link-v4:hover {
  opacity: .8;
}

.u-link-v5 {
  text-decoration: none;
  transition: all .2s;
}

.u-link-v5:hover, .u-link-v5:focus {
  text-decoration: none;
}

.u-link-v6 {
  display: block;
  overflow: hidden;
}

.u-link-v6-arrow {
  margin-left: -50px;
  visibility: hidden;
  opacity: 0;
  transition: all .4s;
}

.u-link .u-block-hover:hover-v6-arrow, .u-link-v6:hover-v6-arrow {
  margin-left: 5px;
  visibility: visible;
  opacity: 1;
}

.u-link-v7 {
  margin-right: 30px;
  transition: all .4s;
}

.u-link-v7-arrow {
  margin-left: -50px;
  visibility: hidden;
  opacity: 0;
  transition: all .4s;
}

.u-link .u-block-hover:hover, .u-link-v7:hover {
  margin-right: 0;
}

.u-link .u-block-hover:hover-v7-arrow, .u-link-v7:hover-v7-arrow {
  margin-left: 10px;
  visibility: visible;
  opacity: 1;
}

/*------------------------------------
  List Styles
------------------------------------*/
.u-list-inline {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.img-bordered {
  border: solid 6px #777;
}

.g-order-1 {
  -ms-flex-order: 1;
      order: 1;
}

.g-order-2 {
  -ms-flex-order: 2;
      order: 2;
}

@media (min-width: 576px) {
  .g-order-1--sm {
    -ms-flex-order: 1;
        order: 1;
  }
  .g-order-2--sm {
    -ms-flex-order: 2;
        order: 2;
  }
}

@media (min-width: 768px) {
  .g-order-1--md {
    -ms-flex-order: 1;
        order: 1;
  }
  .g-order-2--md {
    -ms-flex-order: 2;
        order: 2;
  }
}

@media (min-width: 992px) {
  .g-order-1--lg {
    -ms-flex-order: 1;
        order: 1;
  }
  .g-order-2--lg {
    -ms-flex-order: 2;
        order: 2;
  }
}

/*------------------------------------
  Paginations
------------------------------------*/
/* Pagination v1 */
.u-pagination-v1__item {
  display: inline-block;
  text-align: center;
  text-decoration: none;
  border: solid 1px transparent;
  transition: all .3s ease;
}

.u-pagination-v1__item--active, .u-pagination-v1__item:hover, .u-pagination-v1__item:focus {
  text-decoration: none;
  cursor: pointer;
}

.u-pagination-v1__item-info {
  display: inline-block;
  text-align: center;
  text-decoration: none;
}

.u-pagination-v1__item--disabled {
  opacity: .5;
  pointer-events: none;
}

/* Pagination Style v1 */
.u-pagination-v1-1 {
  color: #999;
  border-color: #999;
}

.u-pagination-v1-1--active, .u-pagination-v1-1:hover, .u-pagination-v1-1:focus {
  background-color: #226f1c;
  color: #fff;
  border-color: #226f1c;
}

/* Pagination Style v2 */
.u-pagination-v1-2 {
  color: #555;
  border-color: #555;
}

.u-pagination-v1-2:hover, .u-pagination-v1-2:focus {
  color: #226f1c;
  border-color: #226f1c;
}

.u-pagination-v1-2--active, .u-pagination-v1-2--nav {
  background-color: #226f1c;
  color: #fff;
  border-color: #226f1c;
}

.u-pagination-v1-2--active:hover, .u-pagination-v1-2--active:focus, .u-pagination-v1-2--nav:hover, .u-pagination-v1-2--nav:focus {
  color: #fff;
}

.u-pagination-v1-2--nav:hover {
  background-color: rgba(34, 111, 28, 0.8);
}

/* Pagination Style v3 */
.u-pagination-v1-3 {
  color: #333;
  border-color: #333;
}

.u-pagination-v1-3--active, .u-pagination-v1-3:hover, .u-pagination-v1-3:focus {
  background-color: #333;
  color: #fff;
  border-color: #333;
}

/* Pagination Style v4 */
.u-pagination-v1-4 {
  color: #333;
  border-color: transparent;
}

.u-pagination-v1-4:hover, .u-pagination-v1-4:focus {
  color: #226f1c;
  border-color: #226f1c;
}

.u-pagination-v1-4--active {
  color: #fff;
  background-color: #226f1c;
  border-color: #226f1c;
}

.u-pagination-v1-4--active:hover, .u-pagination-v1-4--active:focus {
  color: #fff;
}

/* Pagination Style v5 */
.u-pagination-v1-5 {
  color: #999;
  border-color: #ccc;
}

.u-pagination-v1-5--active, .u-pagination-v1-5:hover, .u-pagination-v1-5:focus {
  background-color: #226f1c;
  color: #fff;
  border-color: #226f1c;
}

/*------------------------------------
  Ribbons
------------------------------------*/
.u-ribbon-v1, .u-ribbon-v2 {
  position: absolute;
  font-size: .9rem;
}

.u-ribbon-center {
  left: 50%;
  -ms-transform: translateX(-50%);
      transform: translateX(-50%);
}

.u-ribbon--sm {
  font-size: .7rem;
}

.u-ribbon--lg {
  font-size: 1.1rem;
}

/* Ribbon Style Type 1
------------------------------------*/
.u-ribbon-v1 {
  display: inline-block;
  padding: .35rem .7rem;
  /* Ribbon Type 1 With icon */
}

.u-ribbon-v1.u-ribbon-with-icon {
  padding: .5rem .85rem;
}

.u-ribbon-v1 .u-ribbon-icon--left {
  margin-right: .5rem;
}

.u-ribbon-v1 .u-ribbon-icon--right {
  margin-left: .5rem;
}

/* Ribbon Style Type 2
------------------------------------*/
.u-ribbon-v2 {
  display: inline-block;
  padding: 1rem 1.1rem;
}

.u-ribbon-v2.u-ribbon--sm {
  padding: 1rem 1.2rem;
}

.u-ribbon-v2.u-ribbon--lg {
  padding: 1.2rem 1.1rem;
}

/* Bookmarked Ribbon
------------------------------------*/
.u-ribbon-bookmark::after {
  content: "";
  position: absolute;
  bottom: -1.1rem;
  left: 0;
  width: 100%;
  height: 0;
  border-style: solid;
  border-left-width: 1.5rem;
  border-right-width: 1.5rem;
  border-bottom: 1rem solid transparent !important;
}

.u-ribbon--lg.u-ribbon-bookmark::after {
  border-right-width: 1.4rem;
}

/* Ribbon Colors
------------------------------------*/
.u-ribbon-bookmark.g-bg-primary::after {
  border-color: #226f1c;
}

.u-ribbon-bookmark.g-bg-black::after {
  border-color: #000;
}

.u-ribbon-bookmark.g-bg-white::after {
  border-color: #fff;
}

.u-ribbon-bookmark.g-bg-light-opacity::after {
  border-color: rgba(255, 255, 255, 0.7);
}

.u-ribbon-bookmark.g-bg-dark-opacity::after {
  border-color: rgba(30, 30, 30, 0.7);
}

.u-ribbon-bookmark.g-color-gray-light-v3::after {
  border-color: #ddd;
}

.u-ribbon-bookmark.g-color-gray-light-v4::after {
  border-color: #eee;
}

.u-ribbon-bookmark.g-color-gray-dark-v5::after {
  border-color: #999;
}

.u-ribbon-bookmark.g-bg-green::after {
  border-color: #72c02c;
}

.u-ribbon-bookmark.g-bg-blue::after {
  border-color: #3398dc;
}

.u-ribbon-bookmark.g-bg-lightblue::after {
  border-color: #edf2f8;
}

.u-ribbon-bookmark.g-bg-lightblue-v1::after {
  border-color: #d6e2ee;
}

.u-ribbon-bookmark.g-bg-darkblue::after {
  border-color: #009;
}

.u-ribbon-bookmark.g-bg-indigo::after {
  border-color: #4263a3;
}

.u-ribbon-bookmark.g-bg-red::after {
  border-color: #f00;
}

.u-ribbon-bookmark.g-bg-lightred::after {
  border-color: #e64b3b;
}

.u-ribbon-bookmark.g-bg-darkred::after {
  border-color: #a10f2b;
}

.u-ribbon-bookmark.g-bg-purple::after {
  border-color: #9a69cb;
}

.u-ribbon-bookmark.g-bg-darkpurple::after {
  border-color: #6639b6;
}

.u-ribbon-bookmark.g-bg-pink::after {
  border-color: #e81c62;
}

.u-ribbon-bookmark.g-bg-orange::after {
  border-color: #a10f2b;
}

.u-ribbon-bookmark.g-bg-deeporange::after {
  border-color: #fe541e;
}

.u-ribbon-bookmark.g-bg-yellow::after {
  border-color: #a10f2b;
}

.u-ribbon-bookmark.g-bg-aqua::after {
  border-color: #29d6e6;
}

.u-ribbon-bookmark.g-bg-cyan::after {
  border-color: #00bed6;
}

.u-ribbon-bookmark.g-bg-teal::after {
  border-color: #18ba9b;
}

.u-ribbon-bookmark.g-bg-brown::after {
  border-color: #a10f2b;
}

.u-ribbon-bookmark.g-bg-bluegray::after {
  border-color: #585f69;
}

/* Clipped-v1 Ribbon
------------------------------------*/
.u-ribbon-clip-v1::before,
.u-ribbon-clip-v2::before {
  content: "";
  position: absolute;
  bottom: -0.71429rem;
  border-style: solid;
}

.u-ribbon--left.u-ribbon-clip-v1::before,
.u-ribbon--left.u-ribbon-clip-v2::before {
  left: 0;
  border-width: 0 0.71429rem 0.71429rem 0;
  border-right-color: #999;
}

.u-ribbon--right.u-ribbon-clip-v1::before,
.u-ribbon--right.u-ribbon-clip-v2::before {
  right: 0;
  border-width: 0 0 0.71429rem 0.71429rem;
  border-left-color: #999;
}

/* Clipped-v2 Ribbon
------------------------------------*/
.u-ribbon-clip-v2 {
  padding: .35rem 1.35rem;
  transform: skewX(-10deg) translateZ(1px);
}

.u-ribbon-clip-v2.u-ribbon-with-icon {
  padding: .5rem 1.35rem;
}

.u-ribbon-clip-v2__inner {
  display: inline-block;
  transform: skewX(10deg) translateZ(1px);
}

.u-ribbon-clip-v2::before {
  -ms-transform: skewX(10deg);
      transform: skewX(10deg);
}

.u-ribbon--left.u-ribbon-clip-v2::before {
  left: 0.07143rem;
}

.u-ribbon--right.u-ribbon-clip-v2::before {
  right: -0.07143rem;
}

/* Clipped-v3 Ribbon
------------------------------------*/
.u-ribbon-clip-v3 {
  width: calc(100% + 20px);
  text-align: center;
}

.u-ribbon-clip-v3::before,
.u-ribbon-clip-v3::after {
  content: "";
  position: absolute;
}

.u-ribbon-clip-v3::before {
  left: 0;
  bottom: -0.71429rem;
  width: 0;
  height: 0;
  border-top: 10px solid #999;
  border-left: 10px solid transparent;
}

.u-ribbon-clip-v3::after {
  right: 0;
  bottom: -0.71429rem;
  width: 0;
  height: 0;
  border-top: 10px solid #999;
  border-right: 10px solid transparent;
}

/* Clipped-v4 Ribbon
------------------------------------*/
.u-ribbon-clip-v4 {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.u-ribbon-clip-v4::before,
.u-ribbon-clip-v4::after {
  content: "";
  position: absolute;
}

.u-ribbon-clip-v4::before {
  top: 0;
  width: 2.85714rem;
  height: 0.42857rem;
  background: #999;
  border-radius: 0.57143rem 0.57143rem 0 0;
}

.u-ribbon-clip-v4.u-ribbon--left::before {
  left: 7.14286rem;
}

.u-ribbon-clip-v4.u-ribbon--right::before {
  right: 7.14286rem;
}

.u-ribbon-clip-v4::after {
  top: 7.14286rem;
  width: 0.42857rem;
  height: 2.85714rem;
  background: #999;
}

.u-ribbon-clip-v4.u-ribbon--left::after {
  left: 0;
  border-radius: 0.57143rem 0 0 0.57143rem;
}

.u-ribbon-clip-v4.u-ribbon--right::after {
  right: 0;
  border-radius: 0 0.57143rem 0.57143rem 0;
}

.u-ribbon-clip-v4__inner {
  position: absolute;
  top: 2.14286rem;
  width: 14.28571rem;
  height: 2.85714rem;
  line-height: 2.85714rem;
  overflow: hidden;
  -ms-transform: rotate(45deg);
      transform: rotate(45deg);
  text-align: center;
  z-index: 2;
}

.u-ribbon--left .u-ribbon-clip-v4__inner {
  left: -3.57143rem;
  -ms-transform: rotate(-45deg);
      transform: rotate(-45deg);
}

.u-ribbon--right .u-ribbon-clip-v4__inner {
  right: -3.57143rem;
  -ms-transform: rotate(45deg);
      transform: rotate(45deg);
}

.u-ribbon-clip-v4__inner--bordered {
  border: 1px dashed rgba(255, 255, 255, 0.7);
}

.u-ribbon-clip-v4__inner.g-bg-primary {
  box-shadow: 0 0 0 3px #226f1c, 0 21px 5px -18px rgba(0, 0, 0, 0.6);
}

.u-ribbon-clip-v4__inner.g-bg-black {
  box-shadow: 0 0 0 3px #000, 0 21px 5px -18px rgba(0, 0, 0, 0.6);
}

.u-ribbon-clip-v4__inner.g-bg-white {
  box-shadow: 0 0 0 3px #fff, 0 21px 5px -18px rgba(0, 0, 0, 0.6);
}

.u-ribbon-clip-v4__inner.g-bg-light-opacity {
  box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.7), 0 21px 5px -18px rgba(0, 0, 0, 0.6);
}

.u-ribbon-clip-v4__inner.g-bg-dark-opacity {
  box-shadow: 0 0 0 3px rgba(30, 30, 30, 0.7), 0 21px 5px -18px rgba(0, 0, 0, 0.6);
}

.u-ribbon-clip-v4__inner.g-color-gray-light-v3 {
  box-shadow: 0 0 0 3px #ddd, 0 21px 5px -18px rgba(0, 0, 0, 0.6);
}

.u-ribbon-clip-v4__inner.g-color-gray-light-v4 {
  box-shadow: 0 0 0 3px #eee, 0 21px 5px -18px rgba(0, 0, 0, 0.6);
}

.u-ribbon-clip-v4__inner.g-color-gray-dark-v5 {
  box-shadow: 0 0 0 3px #999, 0 21px 5px -18px rgba(0, 0, 0, 0.6);
}

.u-ribbon-clip-v4__inner.g-bg-green {
  box-shadow: 0 0 0 3px #72c02c, 0 21px 5px -18px rgba(0, 0, 0, 0.6);
}

.u-ribbon-clip-v4__inner.g-bg-blue {
  box-shadow: 0 0 0 3px #3398dc, 0 21px 5px -18px rgba(0, 0, 0, 0.6);
}

.u-ribbon-clip-v4__inner.g-bg-lightblue {
  box-shadow: 0 0 0 3px #edf2f8, 0 21px 5px -18px rgba(0, 0, 0, 0.6);
}

.u-ribbon-clip-v4__inner.g-bg-lightblue-v1 {
  box-shadow: 0 0 0 3px #d6e2ee, 0 21px 5px -18px rgba(0, 0, 0, 0.6);
}

.u-ribbon-clip-v4__inner.g-bg-darkblue {
  box-shadow: 0 0 0 3px #009, 0 21px 5px -18px rgba(0, 0, 0, 0.6);
}

.u-ribbon-clip-v4__inner.g-bg-indigo {
  box-shadow: 0 0 0 3px #4263a3, 0 21px 5px -18px rgba(0, 0, 0, 0.6);
}

.u-ribbon-clip-v4__inner.g-bg-red {
  box-shadow: 0 0 0 3px #f00, 0 21px 5px -18px rgba(0, 0, 0, 0.6);
}

.u-ribbon-clip-v4__inner.g-bg-lightred {
  box-shadow: 0 0 0 3px #e64b3b, 0 21px 5px -18px rgba(0, 0, 0, 0.6);
}

.u-ribbon-clip-v4__inner.g-bg-darkred {
  box-shadow: 0 0 0 3px #a10f2b, 0 21px 5px -18px rgba(0, 0, 0, 0.6);
}

.u-ribbon-clip-v4__inner.g-bg-purple {
  box-shadow: 0 0 0 3px #9a69cb, 0 21px 5px -18px rgba(0, 0, 0, 0.6);
}

.u-ribbon-clip-v4__inner.g-bg-darkpurple {
  box-shadow: 0 0 0 3px #6639b6, 0 21px 5px -18px rgba(0, 0, 0, 0.6);
}

.u-ribbon-clip-v4__inner.g-bg-pink {
  box-shadow: 0 0 0 3px #e81c62, 0 21px 5px -18px rgba(0, 0, 0, 0.6);
}

.u-ribbon-clip-v4__inner.g-bg-orange {
  box-shadow: 0 0 0 3px #a10f2b, 0 21px 5px -18px rgba(0, 0, 0, 0.6);
}

.u-ribbon-clip-v4__inner.g-bg-deeporange {
  box-shadow: 0 0 0 3px #fe541e, 0 21px 5px -18px rgba(0, 0, 0, 0.6);
}

.u-ribbon-clip-v4__inner.g-bg-yellow {
  box-shadow: 0 0 0 3px #a10f2b, 0 21px 5px -18px rgba(0, 0, 0, 0.6);
}

.u-ribbon-clip-v4__inner.g-bg-aqua {
  box-shadow: 0 0 0 3px #29d6e6, 0 21px 5px -18px rgba(0, 0, 0, 0.6);
}

.u-ribbon-clip-v4__inner.g-bg-cyan {
  box-shadow: 0 0 0 3px #00bed6, 0 21px 5px -18px rgba(0, 0, 0, 0.6);
}

.u-ribbon-clip-v4__inner.g-bg-teal {
  box-shadow: 0 0 0 3px #18ba9b, 0 21px 5px -18px rgba(0, 0, 0, 0.6);
}

.u-ribbon-clip-v4__inner.g-bg-brown {
  box-shadow: 0 0 0 3px #a10f2b, 0 21px 5px -18px rgba(0, 0, 0, 0.6);
}

.u-ribbon-clip-v4__inner.g-bg-bluegray {
  box-shadow: 0 0 0 3px #585f69, 0 21px 5px -18px rgba(0, 0, 0, 0.6);
}

/*------------------------------------
  Animation on the scroll
------------------------------------*/
[data-animation]:not(.u-in-viewport) {
  visibility: hidden;
}

[data-animation].js-carousel {
  visibility: visible;
}

.u-in-viewport {
  visibility: visible;
}

/*------------------------------------
  Stickers
------------------------------------*/
.u-sticker {
  position: absolute;
}

/* Sticker Position
------------------------------------*/
.u-sticker-center {
  left: 50%;
  -ms-transform: translateX(-50%);
      transform: translateX(-50%);
}

/*------------------------------------
  Sticky blocks
------------------------------------*/
[class*="sticky-block"] {
  max-width: 100%;
}

/*------------------------------------
  Tables
------------------------------------*/
[class*="text"][class*="center"] td,
[class*="text"][class*="center"] th {
  text-align: center;
}

.u-table--v1 td,
.u-table--v1 th {
  padding: 8px;
}

.u-table--v1 thead th {
  border-bottom-width: 1px;
}

.u-table--v2 td,
.u-table--v2 th {
  padding: 15px;
}

.u-table--v2 thead th {
  background-color: inherit;
  border-bottom-width: 1px;
}

.g-col-border-top-0 td,
.g-col-border-top-0 th {
  border-top-width: 0;
}

.g-col-border-side-0 td,
.g-col-border-side-0 th {
  border-left-width: 0;
  border-right-width: 0;
}

/*------------------------------------
  Tabs
------------------------------------*/
/* Colors
------------------------------------*/
.g-color-primary--active.active {
  color: #226f1c;
}

.g-color-black--active.active {
  color: #000;
}

.g-color-gray-dark-v2--active.active {
  color: #333;
}

.g-color-white--active.active {
  color: #fff;
}

/* Style for Icons
------------------------------------*/
.u-tab-line-icon-pro {
  position: relative;
  top: 2px;
}

/* HZ
------------------------------------*/
.nav-item > a,
.nav-item > .nav-link,
[class*="u-tab-link"]:not([class*="-icon"]) {
  transition-property: color, background-color, border-color;
  transition-duration: .2s;
  transition-timing-function: ease-in;
}

[role="tablist"]:not([data-tabs-mobile-type="slide-up-down"]):not(
[data-tabs-mobile-type="accordion"]):not(
[data-scroll]) {
  display: block;
}

[role="tablist"]  .nav-item {
  display: inline-block;
}

[role="tablist"]  .js-tabs-mobile {
  position: relative;
  display: none;
}

[role="tablist"]  .js-tabs-mobile-control {
  position: relative;
  display: block;
}

[role="tablist"]  .js-tabs-mobile-control::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  display: block;
  width: 0;
  height: 0;
  border-width: 5px 3.5px 0 3.5px;
  border-style: solid;
  border-color: #777 transparent transparent transparent;
  margin-top: -1px;
}

[role="tablist"]  .js-tabs-mobile .nav-inner {
  position: absolute;
  top: calc(100% + 1px);
  right: 0;
  display: none;
  background-color: #fff;
  border: 1px solid;
  padding-left: 0;
}

[role="tablist"]  .js-tabs-mobile .nav-inner .nav-item {
  display: block;
  white-space: nowrap;
}

[data-scroll]:not([data-tabs-mobile-type="slide-up-down"]):not(
[data-tabs-mobile-type="accordion"]) {
  width: 100%;
  white-space: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
}

[data-scroll] ::-webkit-scrollbar {
  display: none;
}

@-moz-document url-prefix() {
  [data-scroll]:not([data-tabs-mobile-type="slide-up-down"]):not(
  [data-tabs-mobile-type="accordion"]) {
    padding-bottom: 15px;
  }
}

@media (min-width: 768px) {
  [data-scroll] {
    width: 100%;
    white-space: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
  }
  [data-scroll]::-webkit-scrollbar {
    display: none;
  }
  @-moz-document url-prefix() {
    [data-scroll] {
      padding-bottom: 15px;
    }
  }
}

/*------------------------------------
  Tabs v1
------------------------------------*/
/* Tabs v1
------------------------------------*/
@media (min-width: 768px) {
  [class*="u-nav-v1"].u-nav-rounded-3 .nav-link {
    border-radius: 3px;
  }
  [class*="u-nav-v1"].u-nav-rounded-5 .nav-link {
    border-radius: 5px;
  }
  [class*="u-nav-v1"].u-nav-rounded-7 .nav-link {
    border-radius: 7px;
  }
  [class*="u-nav-v1"].u-nav-rounded-10 .nav-link {
    border-radius: 10px;
  }
  .u-nav-v1-1 .nav-link.active {
    background-color: #eee;
  }
  .u-nav-v1-1.u-nav-primary .nav-link.active {
    color: #fff;
    background-color: #226f1c;
  }
  .u-nav-v1-1.u-nav-dark .nav-link.active {
    color: #fff;
    background-color: #333;
  }
  .u-nav-v1-1.u-nav-light .nav-link {
    color: #fff;
  }
  .u-nav-v1-1.u-nav-light .nav-link.active {
    color: #333;
    background-color: #fff;
  }
  .u-nav-v1-2 .nav-link {
    border: solid 1px transparent;
  }
  .u-nav-v1-2 .nav-link.active {
    border-color: #eee;
  }
  .u-nav-v1-2.u-nav-primary .nav-link.active {
    border-color: #226f1c;
  }
  .u-nav-v1-2.u-nav-dark .nav-link.active {
    border-color: #333;
  }
  .u-nav-v1-2.u-nav-light .nav-link {
    color: #fff;
  }
  .u-nav-v1-2.u-nav-light .nav-link.active {
    border-color: #fff;
  }
}

/*------------------------------------
  Tabs v2
------------------------------------*/
@media (min-width: 768px) {
  [class*="u-nav-v2"] .nav-link {
    border-style: solid;
    border-color: #eee;
    border-width: 1px;
    margin-left: -1px;
  }
  [class*="u-nav-v2"] .nav-item:first-child .nav-link {
    margin-left: 0;
  }
  [class*="u-nav-v2"].flex-column .nav-link {
    margin: -1px 0 0;
  }
  [class*="u-nav-v2"].flex-column .nav-item:first-child .nav-link {
    margin-top: 0;
  }
  [class*="u-nav-v2"].u-nav-primary .nav-link {
    border-color: #226f1c;
  }
  [class*="u-nav-v2"].u-nav-primary .nav-link.active,
  [class*="u-nav-v2"].u-nav-primary .cbp-filter-item-active .nav-link {
    border-color: #226f1c !important;
  }
  [class*="u-nav-v2"].u-nav-dark .nav-link {
    border-color: #333;
  }
  [class*="u-nav-v2"].u-nav-dark .nav-link.active,
  [class*="u-nav-v2"].u-nav-dark .cbp-filter-item-active .nav-link {
    border-color: #333 !important;
  }
  [class*="u-nav-v2"].u-nav-light .nav-link {
    color: #fff;
    border-color: #fff;
  }
  [class*="u-nav-v2"].u-nav-light .nav-link.active,
  [class*="u-nav-v2"].u-nav-light .cbp-filter-item-active .nav-link {
    border-color: #fff !important;
  }
  [class*="u-nav-v2"].u-nav-rounded-3 .nav-item:first-child .nav-link {
    border-radius: 3px 0 0 3px;
  }
  [class*="u-nav-v2"].u-nav-rounded-3 .nav-item:last-child .nav-link {
    border-radius: 0 3px 3px 0;
  }
  [class*="u-nav-v2"].u-nav-rounded-3.flex-column .nav-item:first-child .nav-link {
    border-radius: 3px 3px 0 0;
  }
  [class*="u-nav-v2"].u-nav-rounded-3.flex-column .nav-item:last-child .nav-link {
    border-radius: 0 0 3px 3px;
  }
  [class*="u-nav-v2"].u-nav-rounded-5 .nav-item:first-child .nav-link {
    border-radius: 5px 0 0 5px;
  }
  [class*="u-nav-v2"].u-nav-rounded-5 .nav-item:last-child .nav-link {
    border-radius: 0 5px 5px 0;
  }
  [class*="u-nav-v2"].u-nav-rounded-5.flex-column .nav-item:first-child .nav-link {
    border-radius: 5px 5px 0 0;
  }
  [class*="u-nav-v2"].u-nav-rounded-5.flex-column .nav-item:last-child .nav-link {
    border-radius: 0 0 5px 5px;
  }
  [class*="u-nav-v2"].u-nav-rounded-7 .nav-item:first-child .nav-link {
    border-radius: 7px 0 0 7px;
  }
  [class*="u-nav-v2"].u-nav-rounded-7 .nav-item:last-child .nav-link {
    border-radius: 0 7px 7px 0;
  }
  [class*="u-nav-v2"].u-nav-rounded-7.flex-column .nav-item:first-child .nav-link {
    border-radius: 7px 7px 0 0;
  }
  [class*="u-nav-v2"].u-nav-rounded-7.flex-column .nav-item:last-child .nav-link {
    border-radius: 0 0 7px 7px;
  }
  [class*="u-nav-v2"].u-nav-rounded-10 .nav-item:first-child .nav-link {
    border-radius: 10px 0 0 10px;
  }
  [class*="u-nav-v2"].u-nav-rounded-10 .nav-item:last-child .nav-link {
    border-radius: 0 10px 10px 0;
  }
  [class*="u-nav-v2"].u-nav-rounded-10.flex-column .nav-item:first-child .nav-link {
    border-radius: 10px 10px 0 0;
  }
  [class*="u-nav-v2"].u-nav-rounded-10.flex-column .nav-item:last-child .nav-link {
    border-radius: 0 0 10px 10px;
  }
  .u-nav-v2-1 .nav-link.active,
  .u-nav-v2-1 .cbp-filter-item-active .nav-link {
    background-color: #eee;
  }
  .u-nav-v2-1.u-nav-primary .nav-link.active,
  .u-nav-v2-1.u-nav-primary .cbp-filter-item-active .nav-link {
    color: #fff;
    background-color: #226f1c;
  }
  .u-nav-v2-1.u-nav-dark .nav-link.active,
  .u-nav-v2-1.u-nav-dark .nav-link.cbp-filter-item-active {
    color: #fff;
    background-color: #333;
  }
  .u-nav-v2-1.u-nav-light .nav-link.active,
  .u-nav-v2-1.u-nav-light .nav-link.cbp-filter-item-active {
    color: #333;
    background-color: #fff;
  }
  .u-nav-v2-2 .nav-link.active,
  .u-nav-v2-2 .cbp-filter-item-active .nav-link {
    color: #226f1c;
  }
}

/*------------------------------------
  Tabs v3
------------------------------------*/
@media (min-width: 768px) {
  [class*="u-nav-v3"] .nav-link {
    border-style: solid;
    border-width: 1px 0;
    border-color: #eee;
  }
  [class*="u-nav-v3"].flex-column .nav-link {
    margin: -1px 0 0;
  }
  [class*="u-nav-v3"].flex-column .nav-item:first-child .nav-link {
    margin-top: 0;
  }
  [class*="u-nav-v3"] .nav-link.active {
    color: #226f1c;
  }
  [class*="u-nav-v3"].u-nav-light .nav-link {
    color: #fff;
    border-color: #fff;
  }
  [class*="u-nav-v3"].u-nav-light .nav-link.active {
    color: #226f1c;
    border-color: #fff !important;
  }
  .u-nav-v3-2 .nav-link {
    z-index: 1;
  }
  .u-nav-v3-2 .nav-link.active {
    border-color: #226f1c;
    z-index: 2;
    position: relative;
  }
}

/*------------------------------------
  Tabs v4
------------------------------------*/
@media (min-width: 768px) {
  .u-nav-v4-1 {
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: #eee;
  }
  .u-nav-v4-1 .nav-item {
    margin-bottom: -1px;
  }
  .u-nav-v4-1 .nav-link {
    border-style: solid;
    border-width: 2px 1px 1px;
    border-color: transparent;
  }
  .u-nav-v4-1 .nav-link.active {
    border-color: #ccc #eee #fff;
  }
  .u-nav-v4-1.u-nav-light .nav-link {
    color: #fff;
  }
  .u-nav-v4-1.u-nav-light .nav-link.active {
    color: #fff;
    border-color: #ccc #eee transparent;
    background-color: #333;
  }
  .u-nav-v4-1.u-nav-light.flex-column .nav-link.active {
    border-color: #ccc transparent #ccc #eee;
    border-width: 1px 0 1px 1px;
    background-color: #333;
  }
  .u-nav-v4-1.u-nav-primary .nav-link.active {
    border-color: #226f1c #eee #fff;
  }
  .u-nav-v4-1.u-nav-dark .nav-link.active {
    border-color: #333 #eee #fff;
  }
}

@media (min-width: 768px) and (min-width: 768px) {
  .u-nav-v4-1-column {
    display: -ms-flexbox;
    display: flex;
  }
  .u-nav-v4-1-column .u-nav-v4-1 {
    border-bottom: none;
  }
  .u-nav-v4-1-column .u-nav-v4-1 .nav-item {
    margin: 0 -1px 0 0;
    z-index: 2;
  }
  .u-nav-v4-1-column .u-nav-v4-1 .nav-link {
    border-style: solid;
    border-width: 1px 1px 1px 2px;
    border-color: transparent;
  }
  .u-nav-v4-1-column .u-nav-v4-1 .nav-link.active {
    border-color: #eee #fff #eee #ccc;
  }
  .u-nav-v4-1-column .u-nav-v4-1.u-nav-primary .nav-link.active {
    border-color: #eee #fff #eee #226f1c;
  }
  .u-nav-v4-1-column .u-nav-v4-1.u-nav-dark .nav-link.active {
    border-color: #eee #fff #eee #333;
  }
  .u-nav-v4-1-column .nav {
    width: 30%;
  }
  .u-nav-v4-1-column .tab-content {
    width: 70%;
  }
}

/*------------------------------------
  Tabs v5
------------------------------------*/
@media (min-width: 768px) {
  [class*="u-nav-v5"] .nav-item {
    margin-bottom: -1px;
  }
  [class*="u-nav-v5"] .nav-link {
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: transparent;
  }
  [class*="u-nav-v5"] .nav-link.active {
    border-color: #ccc;
  }
  [class*="u-nav-v5"].u-nav-primary .nav-link.active {
    border-color: #226f1c;
    color: #226f1c;
  }
  [class*="u-nav-v5"].u-nav-dark .nav-link.active {
    border-color: #333;
    color: #333;
  }
  [class*="u-nav-v5"].u-nav-light .nav-link {
    color: #fff;
  }
  [class*="u-nav-v5"].u-nav-light .nav-link.active {
    color: #fff;
    border-color: #fff;
  }
  .u-nav-v5-2 .nav-link {
    border-bottom-width: 2px;
  }
  .u-nav-v5-3 .nav-link {
    border-bottom-width: 5px;
  }
}

/*------------------------------------
  Tabs v6
------------------------------------*/
@media (min-width: 768px) {
  [class*="u-nav-v6"] .nav-link {
    position: relative;
    border-bottom-style: solid;
    border-bottom-width: 2px;
    border-bottom-color: transparent;
    transition: none;
  }
  [class*="u-nav-v6"] .nav-link:after, [class*="u-nav-v6"] .nav-link:before {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  [class*="u-nav-v6"] .nav-link:after {
    border-top-color: #fff;
    border-width: 4px;
    margin-left: -4px;
  }
  [class*="u-nav-v6"] .nav-link:before {
    border-width: 6px;
    margin-left: -6px;
  }
  [class*="u-nav-v6"] .nav-link.active {
    border-color: #226f1c;
  }
  [class*="u-nav-v6"] .nav-link.active::after, [class*="u-nav-v6"] .nav-link.active::before {
    opacity: 1;
  }
  [class*="u-nav-v6"] .nav-link.active::before {
    border-top-color: #226f1c;
  }
  [class*="u-nav-v6"].u-nav-light .nav-link {
    color: #fff;
  }
  [class*="u-nav-v6"].u-nav-light .nav-link::after {
    border-top-color: #333;
  }
  [class*="u-nav-v6"].u-nav-light .nav-link.active {
    color: #fff;
    border-color: #fff;
  }
  [class*="u-nav-v6"].u-nav-light .nav-link.active:before {
    border-top-color: #fff;
  }
  [class*="u-nav-v6"].u-nav-light .nav-link.active::after {
    border-top-color: #333;
  }
  [class*="u-nav-v6"].flex-column .nav-link {
    border-bottom: none;
    border-right-style: solid;
    border-right-width: 2px;
    border-right-color: transparent;
  }
  [class*="u-nav-v6"].flex-column .nav-link:after, [class*="u-nav-v6"].flex-column .nav-link:before {
    top: 50%;
    left: auto;
  }
  [class*="u-nav-v6"].flex-column .nav-link:after {
    border-top-color: transparent;
    border-left-color: #fff;
    border-width: 4px;
    margin-left: 0;
    margin-top: -5px;
    right: -8px;
  }
  [class*="u-nav-v6"].flex-column .nav-link:before {
    border-top-color: transparent;
    border-left-color: #fff;
    border-width: 6px;
    margin-left: 0;
    margin-top: -7px;
    right: -13px;
  }
  [class*="u-nav-v6"].flex-column .nav-link.active {
    border-color: #226f1c;
  }
  [class*="u-nav-v6"].flex-column .nav-link.active::before {
    border-left-color: #226f1c;
  }
}

/*------------------------------------
  Tabs v7
------------------------------------*/
@media (min-width: 768px) {
  [class*="u-nav-v7"] .nav-item {
    position: relative;
  }
  [class*="u-nav-v7"] .nav-item::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    display: block;
    width: 0;
    height: 35%;
    border-right-style: solid;
    border-right-width: 1px;
    border-right-color: #ccc;
    -ms-transform: translateY(-50%);
        transform: translateY(-50%);
  }
  [class*="u-nav-v7"] .nav-item:last-child::after {
    border-right-color: transparent;
  }
  [class*="u-nav-v7"] .nav-link.active {
    color: #226f1c;
  }
  [class*="u-nav-v7"].u-nav-dark .nav-link.active {
    color: #333;
  }
  [class*="u-nav-v7"].u-nav-light .nav-link {
    color: #fff;
  }
  [class*="u-nav-v7"].u-nav-light .nav-link.active {
    color: #fff;
    opacity: .5;
  }
  [class*="u-nav-v7"].flex-column .nav-item::after {
    top: 100%;
    left: 0;
    right: auto;
    width: 35%;
    height: 0;
    border-right: none;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: #ccc;
    -ms-transform: translateY(0);
        transform: translateY(0);
  }
  [class*="u-nav-v7"].flex-column .nav-item:last-child::after {
    border-bottom-color: transparent;
  }
  [class*="u-nav-v7"].flex-column .nav-link {
    padding-left: 0;
    padding-right: 0;
  }
  [class*="u-nav-v7"].flex-column.text-center .nav-item::after {
    left: 50%;
    -ms-transform: translateX(-50%);
        transform: translateX(-50%);
  }
}

/*------------------------------------
  Tabs v8
------------------------------------*/
[class*="u-nav-v8"] .nav-link {
  position: relative;
  min-height: 100%;
  padding: 10px 20px;
  background-color: #333;
  color: #fff;
  transition: none;
}

[class*="u-nav-v8"] .nav-link::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: 100%;
  background-image: linear-gradient(to left, #333 0%, rgba(17, 17, 17, 0.2) 100%);
  background-repeat: repeat-y;
  z-index: 1;
}

[class*="u-nav-v8"] .nav-link.active {
  background-color: #226f1c;
}

[class*="u-nav-v8"] .nav-link.active::after {
  display: none;
}

[class*="u-nav-v8"] .nav-link.active .u-nav-v8__icon {
  background-color: #226f1c;
}

[class*="u-nav-v8"] .nav-link.active .u-nav-v8__description {
  color: rgba(255, 255, 255, 0.7);
}

[class*="u-nav-v8"].u-nav-light .nav-link {
  color: #333;
}

[class*="u-nav-v8"].u-nav-light .nav-link.active {
  color: #999;
  background-color: #fff;
}

[class*="u-nav-v8"].u-nav-light .nav-link.active .u-nav-v8__icon {
  color: #fff;
  background-color: #226f1c;
}

[class*="u-nav-v8"].u-nav-light .nav-link.active .u-nav-v8__title, [class*="u-nav-v8"].u-nav-light .nav-link.active .u-nav-v8__description {
  color: #999;
}

[class*="u-nav-v8"].u-nav-light .u-nav-v8__icon {
  background-color: #fff;
  color: #fff;
}

[class*="u-nav-v8"].u-nav-light .u-nav-v8__title, [class*="u-nav-v8"].u-nav-light .u-nav-v8__description {
  color: #fff;
}

.u-nav-v8__icon, .u-nav-v8__title, .u-nav-v8__description {
  position: relative;
  z-index: 3;
}

.u-nav-v8__icon {
  display: none;
  background-color: #333;
  color: #fff;
  -ms-transform: translateY(-51%);
      transform: translateY(-51%);
  transition: none;
}

.u-nav-v8__title {
  display: block;
}

.u-nav-v8__description {
  color: inherit;
  font-style: normal;
}

@media (min-width: 768px) {
  [class*="u-nav-v8"] .nav-link {
    padding: 0 20px 25px;
  }
  [class*="u-nav-v8"].u-nav-light .nav-link {
    background-color: #fff;
  }
  [class*="u-nav-v8"].u-nav-light .nav-link::before {
    background-image: linear-gradient(to bottom left, transparent 49.6%, #fff 50%), linear-gradient(to top left, transparent 49.6%, #fff 50%);
  }
  [class*="u-nav-v8"].u-nav-light .nav-link::after {
    background-image: linear-gradient(to left, #fff 0%, rgba(204, 204, 204, 0.2) 100%);
  }
  [class*="u-nav-v8"].u-nav-light .nav-link.active {
    color: #fff;
    background-color: #226f1c;
  }
  [class*="u-nav-v8"].u-nav-light .nav-link.active::before {
    background-image: linear-gradient(to bottom left, transparent 49.6%, #226f1c 50%), linear-gradient(to top left, transparent 49.6%, #226f1c 50%);
  }
  [class*="u-nav-v8"].u-nav-light .nav-link.active .u-nav-v8__title {
    color: #fff;
  }
  [class*="u-nav-v8"].u-nav-light .nav-link.active .u-nav-v8__description {
    color: rgba(255, 255, 255, 0.7);
  }
  [class*="u-nav-v8"].u-nav-light .u-nav-v8__icon, [class*="u-nav-v8"].u-nav-light .u-nav-v8__title, [class*="u-nav-v8"].u-nav-light .u-nav-v8__description {
    color: #999;
  }
  [class*="u-nav-v8"].justify-content-end .nav-item:first-child .nav-link::before, [class*="u-nav-v8"].text-right .nav-item:first-child .nav-link::before {
    display: none;
  }
  [class*="u-nav-v8"].justify-content-end .nav-item:last-child .nav-link, [class*="u-nav-v8"].text-right .nav-item:last-child .nav-link {
    padding: 0 20px 25px;
  }
  [class*="u-nav-v8"].justify-content-end .nav-item:last-child .nav-link::before, [class*="u-nav-v8"].text-right .nav-item:last-child .nav-link::before {
    display: block;
  }
  [class*="u-nav-v8"].justify-content-end .nav-link::before, [class*="u-nav-v8"].text-right .nav-link::before {
    left: -26px;
    right: auto;
    background-position: top right, bottom left;
    background-image: linear-gradient(to bottom right, transparent 49.6%, #333 50%), linear-gradient(to top right, transparent 49.6%, #333 50%);
  }
  [class*="u-nav-v8"].justify-content-end .nav-link::after, [class*="u-nav-v8"].text-right .nav-link::after {
    left: auto;
    right: 0;
    background-image: linear-gradient(to right, #333 0%, rgba(17, 17, 17, 0.2) 100%);
  }
  [class*="u-nav-v8"].justify-content-end .nav-link.active::before, [class*="u-nav-v8"].text-right .nav-link.active::before {
    background-image: linear-gradient(to bottom right, transparent 49.6%, #226f1c 50%), linear-gradient(to top right, transparent 49.6%, #226f1c 50%);
  }
  [class*="u-nav-v8"].flex-column .nav-item {
    margin-bottom: 1px;
  }
  [class*="u-nav-v8"].flex-column .nav-item:first-child .nav-link {
    padding: 20px 20px 20px 50px;
  }
  [class*="u-nav-v8"].flex-column .nav-item:last-child .nav-link::before {
    display: block;
  }
  [class*="u-nav-v8"].flex-column .nav-link {
    padding: 20px 20px 20px 50px;
  }
  [class*="u-nav-v8"].flex-column .nav-link::before {
    opacity: 0;
  }
  [class*="u-nav-v8"].flex-column .nav-link.active::before {
    opacity: 1;
  }
  [class*="u-nav-v8"].flex-column .u-nav-v8__icon {
    position: absolute;
    top: 50%;
    left: 0;
    -ms-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);
  }
  .u-nav-v8__icon {
    display: inline-block;
  }
  .u-nav-v8__description {
    color: rgba(255, 255, 255, 0.5);
  }
  .u-nav-v8-2 .nav-item:first-child .nav-link {
    padding: 0 20px 25px;
  }
  .u-nav-v8-2 .nav-item:last-child .nav-link::before {
    display: none;
  }
  .u-nav-v8-2 .nav-link {
    padding: 0 20px 25px 50px;
  }
  .u-nav-v8-2 .nav-link::before {
    content: "";
    position: absolute;
    top: 0;
    right: -26px;
    display: block;
    width: 26px;
    height: 100%;
    background-repeat: no-repeat;
    background-size: 100% 50.8%;
    background-position: top right, bottom left;
    background-image: linear-gradient(to bottom left, transparent 49.6%, #333 50%), linear-gradient(to top left, transparent 49.6%, #333 50%);
    z-index: 2;
  }
  .u-nav-v8-2 .nav-link.active::before {
    background-image: linear-gradient(to bottom left, transparent 49.6%, #226f1c 50%), linear-gradient(to top left, transparent 49.6%, #226f1c 50%);
  }
  .u-nav-v8-2.justify-content-end .nav-item:first-child .nav-link, .u-nav-v8-2.text-right .nav-item:first-child .nav-link {
    padding: 0 50px 25px 20px;
  }
  .u-nav-v8-2.justify-content-end .nav-link, .u-nav-v8-2.text-right .nav-link {
    padding: 0 50px 25px 20px;
  }
}

@media (max-width: 768px - 1) {
  [data-tabs-mobile-type="slide-up-down"],
  [data-tabs-mobile-type="accordion"] {
    display: none;
  }
  [data-tabs-mobile-type="accordion"] {
    display: none;
  }
  [role="tablist"]:not([data-tabs-mobile-type="slide-up-down"]):not(
  [data-tabs-mobile-type="accordion"]) {
    border-color: #226f1c;
  }
  [role="tablist"]  .nav-item > a,
  [role="tablist"]  .nav-item > .nav-link,
  [role="tablist"]  [class*="u-tab-link"]:not([class*="-icon"]) {
    white-space: nowrap;
    padding: 5px 10px;
  }
  [role="tablist"]  .nav-item > a,
  [role="tablist"]  .nav-item > .nav-link,
  [role="tablist"]  [class*="u-tab-link"] {
    display: block;
  }
  [data-tabs-mobile-type="slide-up-down"] {
    width: 100%;
    padding-top: 10px;
    border-bottom: none !important;
  }
  [data-tabs-mobile-type="slide-up-down"] .nav-item {
    margin: 0;
  }
  [data-tabs-mobile-type="slide-up-down"] .nav-link {
    text-align: center;
    border-style: solid !important;
    border-color: #eee !important;
    border-width: 1px !important;
    border-radius: 0 !important;
    margin: -1px 0 0;
  }
  [data-tabs-mobile-type="slide-up-down"] .nav-item:first-child .nav-link {
    margin-top: 0;
  }
  [data-tabs-mobile-type="slide-up-down"].u-nav-primary .nav-link, [data-tabs-mobile-type="slide-up-down"].nav-pills .nav-link {
    border-color: #226f1c !important;
  }
  [data-tabs-mobile-type="slide-up-down"].u-nav-primary .nav-link.active,
  [data-tabs-mobile-type="slide-up-down"].u-nav-primary .nav-item.show .nav-link, [data-tabs-mobile-type="slide-up-down"].nav-pills .nav-link.active,
  [data-tabs-mobile-type="slide-up-down"].nav-pills .nav-item.show .nav-link {
    border-color: #226f1c !important;
  }
  [data-tabs-mobile-type="slide-up-down"].u-nav-dark .nav-link {
    border-color: #333 !important;
  }
  [data-tabs-mobile-type="slide-up-down"].u-nav-dark .nav-link.active,
  [data-tabs-mobile-type="slide-up-down"].u-nav-dark .nav-item.show .nav-link {
    border-color: #333 !important;
  }
  [data-tabs-mobile-type="slide-up-down"].u-nav-light .nav-item.show .nav-link {
    border-color: #fff;
  }
  [data-tabs-mobile-type="slide-up-down"].u-nav-light .nav-link {
    color: #fff;
    border-color: #fff;
  }
  [data-tabs-mobile-type="slide-up-down"].u-nav-light .nav-link.active {
    color: #999;
    background-color: #fff;
    border-color: #fff;
  }
  [data-tabs-mobile-type="slide-up-down"].u-nav-rounded-3 .nav-item:first-child .nav-link,
  [data-tabs-mobile-type="slide-up-down"].nav-tabs .nav-item:first-child .nav-link,
  [data-tabs-mobile-type="slide-up-down"].nav-pills .nav-item:first-child .nav-link {
    border-radius: 3px 3px 0 0 !important;
  }
  [data-tabs-mobile-type="slide-up-down"].u-nav-rounded-3 .nav-item:last-child .nav-link,
  [data-tabs-mobile-type="slide-up-down"].nav-tabs .nav-item:last-child .nav-link,
  [data-tabs-mobile-type="slide-up-down"].nav-pills .nav-item:last-child .nav-link {
    border-radius: 0 0 3px 3px !important;
  }
  [data-tabs-mobile-type="slide-up-down"].u-nav-rounded-5 .nav-item:first-child .nav-link {
    border-radius: 5px 5px 0 0 !important;
  }
  [data-tabs-mobile-type="slide-up-down"].u-nav-rounded-5 .nav-item:last-child .nav-link {
    border-radius: 0 0 5px 5px !important;
  }
  [data-tabs-mobile-type="slide-up-down"].u-nav-rounded-7 .nav-item:first-child .nav-link {
    border-radius: 7px 7px 0 0 !important;
  }
  [data-tabs-mobile-type="slide-up-down"].u-nav-rounded-7 .nav-item:last-child .nav-link {
    border-radius: 0 0 7px 7px !important;
  }
  [data-tabs-mobile-type="slide-up-down"].u-nav-rounded-10 .nav-item:first-child .nav-link {
    border-radius: 10px 10px 0 0 !important;
  }
  [data-tabs-mobile-type="slide-up-down"].u-nav-rounded-10 .nav-item:last-child .nav-link {
    border-radius: 0 0 10px 10px !important;
  }
  .tab-content {
    -ms-flex-order: 2;
        order: 2;
  }
}

/*------------------------------------
  Tags
------------------------------------*/
/* Pagination v1 */
.u-tags-v1 {
  display: inline-block;
  text-decoration: none;
  transition: all .3s ease;
}

.u-tags-v1:hover, .u-tags-v1:focus {
  text-decoration: none;
  cursor: pointer;
}

.u-tags-v1:focus {
  color: inherit;
}

/*------------------------------------
  Text Animation Slideshow
------------------------------------*/
.u-text-slideshow {
  position: relative;
  overflow: visible !important;
  vertical-align: inherit;
}

.u-text-slideshow__slide {
  position: relative;
  z-index: 1;
  display: inline-block;
  opacity: 0;
  transition: .3s ease;
}

.u-text-slideshow__slide:not(:first-child) {
  position: absolute;
  top: 0;
  left: 0;
}

.u-text-slideshow__slide--current {
  z-index: 2;
  opacity: 1;
}

.u-text-slideshow__slide-target {
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
}

/*------------------------------------
  Text-shadow
------------------------------------*/
.u-txt-shadow-v1 {
  text-shadow: 1px 1px rgba(67, 70, 72, 0.05), 2px 2px rgba(147, 150, 152, 0.07), 3px 3px rgba(178, 182, 183, 0.086), 4px 4px rgba(195, 199, 200, 0.106), 5px 5px rgba(206, 210, 211, 0.125), 6px 6px rgba(213, 217, 218, 0.145), 7px 7px rgba(218, 222, 223, 0.165), 8px 8px rgba(222, 226, 227, 0.184), 9px 9px rgba(226, 230, 231, 0.204), 10px 10px rgba(228, 232, 233, 0.22), 11px 11px rgba(230, 234, 235, 0.24), 12px 12px rgba(232, 236, 237, 0.26), 13px 13px rgba(233, 237, 238, 0.28), 14px 14px rgba(235, 239, 240, 0.298), 15px 15px rgba(236, 240, 241, 0.318), 16px 16px rgba(237, 241, 242, 0.333), 17px 17px rgba(238, 242, 243, 0.353), 18px 18px rgba(238, 242, 243, 0.373), 19px 19px rgba(239, 243, 244, 0.392), 20px 20px rgba(240, 244, 245, 0.41), 21px 21px rgba(240, 244, 245, 0.43), 22px 22px rgba(241, 245, 246, 0.447), 23px 23px rgba(241, 245, 246, 0.467), 24px 24px rgba(242, 246, 247, 0.486), 25px 25px rgba(242, 246, 247, 0.506), 26px 26px rgba(242, 246, 247, 0.525), 27px 27px rgba(243, 247, 248, 0.545), 28px 28px rgba(243, 247, 248, 0.565), 29px 29px rgba(243, 247, 248, 0.58), 30px 30px rgba(244, 248, 249, 0.6), 31px 31px rgba(244, 248, 249, 0.62), 32px 32px rgba(244, 248, 249, 0.64), 33px 33px rgba(244, 248, 249, 0.66), 34px 34px rgba(245, 249, 250, 0.68), 35px 35px rgba(245, 249, 250, 0.694), 36px 36px rgba(245, 249, 250, 0.714), 37px 37px rgba(245, 249, 250, 0.733), 38px 38px rgba(245, 249, 250, 0.753), 39px 39px rgba(246, 250, 251, 0.773), 40px 40px rgba(246, 250, 251, 0.792), 41px 41px rgba(246, 250, 251, 0.81), 42px 42px rgba(246, 250, 251, 0.827), 43px 43px rgba(246, 250, 251, 0.847), 44px 44px rgba(246, 250, 251, 0.867), 45px 45px rgba(246, 250, 251, 0.886), 46px 46px rgba(246, 250, 251, 0.906), 47px 47px rgba(247, 251, 252, 0.925), 48px 48px rgba(247, 251, 252, 0.94), 49px 49px rgba(247, 251, 252, 0.96), 50px 50px rgba(247, 251, 252, 0.98);
}

.u-txt-shadow-v2 {
  text-shadow: 0 6px 55px #999;
}

/*------------------------------------
  Marker-bg
------------------------------------*/
.u-marker-bg-primary, .u-marker-bg-green, .u-marker-bg-black, .u-marker-bg-white, .u-marker-bg-red, .u-marker-bg-yellow {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 0 50%;
  background-size: 100% 70%;
  white-space: nowrap;
}

.u-marker-bg-primary {
  background-image: url(../img/bg/marker/marker-bg-primary.png);
}

.u-marker-bg-green {
  background-image: url(../img/bg/marker/marker-bg-green.png);
}

.u-marker-bg-black {
  background-image: url(../img/bg/marker/marker-bg-black.png);
}

.u-marker-bg-white {
  background-image: url(../img/bg/marker/marker-bg-white.png);
}

.u-marker-bg-red {
  background-image: url(../img/bg/marker/marker-bg-red.png);
}

.u-marker-bg-yellow {
  background-image: url(../img/bg/marker/marker-bg-yellow.png);
}

/*------------------------------------
  Tooltips
------------------------------------*/
.u-tooltip--v1 {
  color: #fff;
  background-color: #000;
  padding: 2px 8px 3px;
}

.u-tooltip--v1:after {
  content: "";
  position: absolute;
  display: block;
}

.u-tooltip--v1.tooltip-top-left, .u-tooltip--v1.tooltip-top-right {
  bottom: 100%;
  margin-bottom: 5px;
}

.u-tooltip--v1.tooltip-top-left:after, .u-tooltip--v1.tooltip-top-right:after {
  top: 100%;
  border-top: 4px solid rgba(0, 0, 0, 0.9);
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.u-tooltip--v1.tooltip-bottom-left, .u-tooltip--v1.tooltip-bottom-right {
  top: 100%;
  margin-top: 5px;
}

.u-tooltip--v1.tooltip-bottom-left:after, .u-tooltip--v1.tooltip-bottom-right:after {
  bottom: 100%;
  border-bottom: 4px solid rgba(0, 0, 0, 0.9);
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.u-tooltip--v1.tooltip-top-left, .u-tooltip--v1.tooltip-bottom-left {
  left: 0;
}

.u-tooltip--v1.tooltip-top-left:after, .u-tooltip--v1.tooltip-bottom-left:after {
  left: 16px;
}

.u-tooltip--v1.tooltip-top-right, .u-tooltip--v1.tooltip-bottom-right {
  right: 0;
}

.u-tooltip--v1.tooltip-top-right:after, .u-tooltip--v1.tooltip-bottom-right:after {
  right: 16px;
}

.u-tooltip--v1.tooltip-left, .u-tooltip--v1.tooltip-right {
  top: 50%;
  -ms-transform: translateY(-50%);
      transform: translateY(-50%);
}

.u-tooltip--v1.tooltip-left:after, .u-tooltip--v1.tooltip-right:after {
  top: 50%;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  -ms-transform: translateY(-50%);
      transform: translateY(-50%);
}

.u-tooltip--v1.tooltip-left {
  right: 100%;
  margin-right: 5px;
}

.u-tooltip--v1.tooltip-left:after {
  right: -4px;
  border-left: 4px solid rgba(0, 0, 0, 0.9);
}

.u-tooltip--v1.tooltip-right {
  left: 100%;
  margin-left: 5px;
}

.u-tooltip--v1.tooltip-right:after {
  left: -4px;
  border-right: 4px solid rgba(0, 0, 0, 0.9);
}

input:focus + .u-tooltip--v1,
textarea:focus + .u-tooltip--v1 {
  opacity: 1;
}

/*------------------------------------
  Typography
------------------------------------*/
.popovers--no-title .popover-title {
  display: none;
}

.g-nowrap {
  white-space: nowrap;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

video {
  max-width: 100%;
}

.u-video-v1 {
  margin-bottom: 15px;
}

.u-video-v1-info {
  display: none;
  padding-left: 0;
}

.u-video-v1-info__item {
  display: inline-block;
  list-style: none;
}

.u-audio-v1 {
  margin-bottom: 15px;
}

.u-audio-v1-info {
  display: none;
  padding-left: 0;
}

.u-audio-v1-info__item {
  display: inline-block;
  list-style: none;
}

.u-audio-v2 .plyr__controls {
  background-color: transparent;
  border: none;
}

.u-audio-v2 .plyr__controls button {
  color: #fff;
}

/*------------------------------------
  Navigation
------------------------------------*/
/* Base Abstractions */
.navbar .u-main-nav-v1 .nav-link, .navbar .u-main-nav-v2 .nav-link, .navbar .u-main-nav-v3 .nav-link, .navbar .u-main-nav-v4 .nav-link, .navbar .u-main-nav-v5 .nav-link, .navbar .u-main-nav-v6 .nav-link, .navbar .u-main-nav-v7 .nav-link, .navbar .u-main-nav-v8 .nav-link, .navbar .u-main-nav-v9 .nav-link {
  display: block;
}

.navbar .u-main-nav-v1 .nav-item.hs-has-sub-menu > a, .navbar .u-main-nav-v1 .nav-item.hs-has-mega-menu > a, .navbar .u-main-nav-v1 .nav-item.dropdown > a, .navbar .u-main-nav-v2 .nav-item.hs-has-sub-menu > a, .navbar .u-main-nav-v2 .nav-item.hs-has-mega-menu > a, .navbar .u-main-nav-v2 .nav-item.dropdown > a, .navbar .u-main-nav-v3 .nav-item.hs-has-sub-menu > a, .navbar .u-main-nav-v3 .nav-item.hs-has-mega-menu > a, .navbar .u-main-nav-v3 .nav-item.dropdown > a, .navbar .u-main-nav-v4 .nav-item.hs-has-sub-menu > a, .navbar .u-main-nav-v4 .nav-item.hs-has-mega-menu > a, .navbar .u-main-nav-v4 .nav-item.dropdown > a, .navbar .u-main-nav-v5 .nav-item.hs-has-sub-menu > a, .navbar .u-main-nav-v5 .nav-item.hs-has-mega-menu > a, .navbar .u-main-nav-v5 .nav-item.dropdown > a, .navbar .u-main-nav-v6 .nav-item.hs-has-sub-menu > a, .navbar .u-main-nav-v6 .nav-item.hs-has-mega-menu > a, .navbar .u-main-nav-v6 .nav-item.dropdown > a, .navbar .u-main-nav-v7 .nav-item.hs-has-sub-menu > a, .navbar .u-main-nav-v7 .nav-item.hs-has-mega-menu > a, .navbar .u-main-nav-v7 .nav-item.dropdown > a, .navbar .u-main-nav-v8 .nav-item.hs-has-sub-menu > a, .navbar .u-main-nav-v8 .nav-item.hs-has-mega-menu > a, .navbar .u-main-nav-v8 .nav-item.dropdown > a, .navbar .u-main-nav-v9 .nav-item.hs-has-sub-menu > a, .navbar .u-main-nav-v9 .nav-item.hs-has-mega-menu > a {
  position: relative;
  z-index: 1;
  padding-right: 1.42857rem;
}

.navbar .u-main-nav-v1 .nav-item.hs-has-sub-menu > a::after, .navbar .u-main-nav-v1 .nav-item.hs-has-mega-menu > a::after, .navbar .u-main-nav-v1 .nav-item.dropdown > a::after, .navbar .u-main-nav-v2 .nav-item.hs-has-sub-menu > a::after, .navbar .u-main-nav-v2 .nav-item.hs-has-mega-menu > a::after, .navbar .u-main-nav-v2 .nav-item.dropdown > a::after, .navbar .u-main-nav-v3 .nav-item.hs-has-sub-menu > a::after, .navbar .u-main-nav-v3 .nav-item.hs-has-mega-menu > a::after, .navbar .u-main-nav-v3 .nav-item.dropdown > a::after, .navbar .u-main-nav-v4 .nav-item.hs-has-sub-menu > a::after, .navbar .u-main-nav-v4 .nav-item.hs-has-mega-menu > a::after, .navbar .u-main-nav-v4 .nav-item.dropdown > a::after, .navbar .u-main-nav-v5 .nav-item.hs-has-sub-menu > a::after, .navbar .u-main-nav-v5 .nav-item.hs-has-mega-menu > a::after, .navbar .u-main-nav-v5 .nav-item.dropdown > a::after, .navbar .u-main-nav-v6 .nav-item.hs-has-sub-menu > a::after, .navbar .u-main-nav-v6 .nav-item.hs-has-mega-menu > a::after, .navbar .u-main-nav-v6 .nav-item.dropdown > a::after, .navbar .u-main-nav-v7 .nav-item.hs-has-sub-menu > a::after, .navbar .u-main-nav-v7 .nav-item.hs-has-mega-menu > a::after, .navbar .u-main-nav-v7 .nav-item.dropdown > a::after, .navbar .u-main-nav-v8 .nav-item.hs-has-sub-menu > a::after, .navbar .u-main-nav-v8 .nav-item.hs-has-mega-menu > a::after, .navbar .u-main-nav-v8 .nav-item.dropdown > a::after, .navbar .u-main-nav-v9 .nav-item.hs-has-sub-menu > a::after, .navbar .u-main-nav-v9 .nav-item.hs-has-mega-menu > a::after {
  content: "\e900";
  font-family: "hs-icons";
  font-weight: inherit;
  line-height: 1;
  position: absolute;
  top: 50%;
  right: 1.07143rem;
  transform: translate3d(0, -50%, 0);
}

/* Base stylesheets */
.u-header__section--dark .navbar [class*="u-main-nav-v"] .nav-link {
  color: #fff;
}

.u-header__section--dark .navbar [class*="u-main-nav-v"] .nav-item.active > .nav-link,
.u-header__section--dark .navbar [class*="u-main-nav-v"] .nav-item:hover > .nav-link,
.u-header__section--dark .navbar [class*="u-main-nav-v"] .nav-item:focus > .nav-link,
.u-header__section--dark .navbar [class*="u-main-nav-v"] .nav-item.show > .nav-link,
.u-header__section--dark .navbar [class*="u-main-nav-v"] .nav-item.hs-sub-menu-opened > .nav-link,
.u-header__section--dark .navbar [class*="u-main-nav-v"] .nav-item .nav-link:focus {
  color: #fff;
}

/* Import */
/* Main navigation styles */
/*------------------------------------
  Navigation Style v1
------------------------------------*/
.navbar .u-main-nav-v1 .nav-link {
  color: #333;
  padding: 0.78571rem 2.14286rem;
  border-radius: 1.57143rem;
}

.navbar .u-main-nav-v1 .nav-item.hs-has-sub-menu > a, .navbar .u-main-nav-v1 .nav-item.hs-has-mega-menu > a, .navbar .u-main-nav-v1 .nav-item.dropdown > a {
  padding-right: 3.21429rem;
}

.navbar .u-main-nav-v1 .nav-item.hs-has-sub-menu > a::after, .navbar .u-main-nav-v1 .nav-item.hs-has-mega-menu > a::after, .navbar .u-main-nav-v1 .nav-item.dropdown > a::after {
  right: 1.78571rem;
}

.navbar .u-main-nav-v1 .nav-item.active > .nav-link,
.navbar .u-main-nav-v1 .nav-item:hover > .nav-link,
.navbar .u-main-nav-v1 .nav-item:focus > .nav-link,
.navbar .u-main-nav-v1 .nav-item.show > .nav-link,
.navbar .u-main-nav-v1 .nav-item.hs-sub-menu-opened > .nav-link,
.navbar .u-main-nav-v1 .nav-item .nav-link:focus {
  color: #fff;
  background-color: #226f1c;
}

@media all and (max-width: 1200px - 1) {
  .navbar .u-main-nav-v1 .nav-link {
    padding: 0.64286rem 1.42857rem;
  }
  .navbar .u-main-nav-v1 .nav-item.hs-has-sub-menu > a, .navbar .u-main-nav-v1 .nav-item.hs-has-mega-menu > a, .navbar .u-main-nav-v1 .nav-item.dropdown > a {
    padding-right: 2.5rem;
  }
  .navbar .u-main-nav-v1 .nav-item.hs-has-sub-menu > a::after, .navbar .u-main-nav-v1 .nav-item.hs-has-mega-menu > a::after, .navbar .u-main-nav-v1 .nav-item.dropdown > a::after {
    right: 1.07143rem;
  }
}

/*------------------------------------
  Navigation Style v2
------------------------------------*/
.navbar .u-main-nav-v2 .nav-link {
  color: #333;
  padding: 0.64286rem 2.14286rem;
  border-radius: 1.57143rem;
  border: 2px solid transparent;
}

.navbar .u-main-nav-v2 .nav-item.hs-has-sub-menu > a, .navbar .u-main-nav-v2 .nav-item.hs-has-mega-menu > a, .navbar .u-main-nav-v2 .nav-item.dropdown > a {
  padding-right: 3.21429rem;
}

.navbar .u-main-nav-v2 .nav-item.hs-has-sub-menu > a::after, .navbar .u-main-nav-v2 .nav-item.hs-has-mega-menu > a::after, .navbar .u-main-nav-v2 .nav-item.dropdown > a::after {
  right: 1.78571rem;
}

.navbar .u-main-nav-v2 .nav-item.active > .nav-link,
.navbar .u-main-nav-v2 .nav-item:hover > .nav-link,
.navbar .u-main-nav-v2 .nav-item:focus > .nav-link,
.navbar .u-main-nav-v2 .nav-item.show > .nav-link,
.navbar .u-main-nav-v2 .nav-item.hs-sub-menu-opened > .nav-link,
.navbar .u-main-nav-v2 .nav-item .nav-link:focus {
  color: #333;
  border-color: #226f1c;
  background-color: transparent;
}

@media all and (max-width: 1200px - 1) {
  .navbar .u-main-nav-v2 .nav-link {
    padding: 0.5rem 1.42857rem;
  }
  .navbar .u-main-nav-v2 .nav-item.hs-has-sub-menu > a, .navbar .u-main-nav-v2 .nav-item.hs-has-mega-menu > a, .navbar .u-main-nav-v2 .nav-item.dropdown > a {
    padding-right: 2.5rem;
  }
  .navbar .u-main-nav-v2 .nav-item.hs-has-sub-menu > a::after, .navbar .u-main-nav-v2 .nav-item.hs-has-mega-menu > a::after, .navbar .u-main-nav-v2 .nav-item.dropdown > a::after {
    right: 1.07143rem;
  }
}

/*------------------------------------
  Navigation Style v3
------------------------------------*/
.navbar .u-main-nav-v3 .nav-link {
  color: #333;
  padding: 2.85714rem 2.57143rem 2.57143rem;
  border-bottom: 4px solid transparent;
}

.navbar .u-main-nav-v3 .nav-item.hs-has-sub-menu > a::after, .navbar .u-main-nav-v3 .nav-item.hs-has-mega-menu > a::after, .navbar .u-main-nav-v3 .nav-item.dropdown > a::after {
  position: static;
  display: inline-block;
  vertical-align: middle;
  margin-left: 0.71429rem;
  -ms-transform: none;
      transform: none;
}

.navbar .u-main-nav-v3 .nav-item.active > .nav-link,
.navbar .u-main-nav-v3 .nav-item:hover > .nav-link,
.navbar .u-main-nav-v3 .nav-item:focus > .nav-link,
.navbar .u-main-nav-v3 .nav-item.show > .nav-link,
.navbar .u-main-nav-v3 .nav-item.hs-sub-menu-opened > .nav-link,
.navbar .u-main-nav-v3 .nav-item .nav-link:focus {
  border-color: #226f1c;
}

@media all and (max-width: 1200px - 1) {
  .navbar .u-main-nav-v3 .nav-link {
    padding: 2.14286rem 1.78571rem;
  }
}

@media all and (max-width: 992px - 1) {
  .navbar .u-main-nav-v3 .nav-link {
    padding: 0.71429rem 1.42857rem;
  }
}

/*------------------------------------
  Navigation Style v4
------------------------------------*/
.navbar .u-main-nav-v4 .nav-link {
  color: #333;
  padding: 2.57143rem 2.57143rem 2.85714rem;
  border-top: 4px solid transparent;
}

.navbar .u-main-nav-v4 .nav-item.hs-has-sub-menu > a::after, .navbar .u-main-nav-v4 .nav-item.hs-has-mega-menu > a::after, .navbar .u-main-nav-v4 .nav-item.dropdown > a::after {
  position: static;
  display: inline-block;
  vertical-align: middle;
  margin-left: 0.71429rem;
  -ms-transform: none;
      transform: none;
}

.navbar .u-main-nav-v4 .nav-item.active > .nav-link,
.navbar .u-main-nav-v4 .nav-item:hover > .nav-link,
.navbar .u-main-nav-v4 .nav-item:focus > .nav-link,
.navbar .u-main-nav-v4 .nav-item.show > .nav-link,
.navbar .u-main-nav-v4 .nav-item.hs-sub-menu-opened > .nav-link,
.navbar .u-main-nav-v4 .nav-item .nav-link:focus {
  border-color: #226f1c;
}

@media all and (max-width: 1200px - 1) {
  .navbar .u-main-nav-v4 .nav-link {
    padding: 2.14286rem 1.78571rem;
  }
}

@media all and (max-width: 992px - 1) {
  .navbar .u-main-nav-v4 .nav-link {
    padding: 0.71429rem 1.42857rem;
  }
}

/*------------------------------------
  Navigation Style v5
------------------------------------*/
.navbar .u-main-nav-v5 .nav-link {
  color: #333;
  padding: 0.28571rem 0;
  border-bottom: 2px solid transparent;
}

.navbar .u-main-nav-v5 .nav-item.hs-has-sub-menu > a, .navbar .u-main-nav-v5 .nav-item.hs-has-mega-menu > a, .navbar .u-main-nav-v5 .nav-item.dropdown > a {
  padding-right: 1.42857rem;
}

.navbar .u-main-nav-v5 .nav-item.hs-has-sub-menu > a::after, .navbar .u-main-nav-v5 .nav-item.hs-has-mega-menu > a::after, .navbar .u-main-nav-v5 .nav-item.dropdown > a::after {
  right: 0;
}

.navbar .u-main-nav-v5 .nav-item.active > .nav-link,
.navbar .u-main-nav-v5 .nav-item:hover > .nav-link,
.navbar .u-main-nav-v5 .nav-item:focus > .nav-link,
.navbar .u-main-nav-v5 .nav-item.show > .nav-link,
.navbar .u-main-nav-v5 .nav-item.hs-sub-menu-opened > .nav-link,
.navbar .u-main-nav-v5 .nav-item .nav-link:focus {
  border-color: #226f1c;
}

/*------------------------------------
  Navigation Style v6
------------------------------------*/
.navbar .u-main-nav-v6 .nav-link {
  color: #333;
  height: 100%;
  padding: 2.85714rem 2.71429rem;
}

.navbar .u-main-nav-v6 .nav-item.hs-has-sub-menu > a, .navbar .u-main-nav-v6 .nav-item.hs-has-mega-menu > a, .navbar .u-main-nav-v6 .nav-item.dropdown > a {
  padding-right: 2.71429rem;
}

.navbar .u-main-nav-v6 .nav-item.hs-has-sub-menu > a::after, .navbar .u-main-nav-v6 .nav-item.hs-has-mega-menu > a::after, .navbar .u-main-nav-v6 .nav-item.dropdown > a::after {
  position: static;
  display: inline-block;
  vertical-align: middle;
  margin-left: 0.71429rem;
  -ms-transform: none;
      transform: none;
}

.navbar .u-main-nav-v6 .nav-item.active > .nav-link,
.navbar .u-main-nav-v6 .nav-item:hover > .nav-link,
.navbar .u-main-nav-v6 .nav-item:focus > .nav-link,
.navbar .u-main-nav-v6 .nav-item.show > .nav-link,
.navbar .u-main-nav-v6 .nav-item.hs-sub-menu-opened > .nav-link,
.navbar .u-main-nav-v6 .nav-item .nav-link:focus {
  color: #fff;
  background-color: #226f1c;
}

@media all and (max-width: 1200px - 1) {
  .navbar .u-main-nav-v6 .nav-link {
    padding: 2.14286rem 1.42857rem;
  }
  .navbar .u-main-nav-v6 .nav-item.hs-has-sub-menu > a, .navbar .u-main-nav-v6 .nav-item.hs-has-mega-menu > a, .navbar .u-main-nav-v6 .nav-item.dropdown > a {
    padding-right: 1.42857rem;
  }
}

/*------------------------------------
  Navigation Style v7
------------------------------------*/
.navbar .u-main-nav-v7 .nav-link {
  color: #333;
  padding: 0.71429rem 2.14286rem;
  border: 2px solid transparent;
}

.navbar .u-main-nav-v7 .nav-item.hs-has-sub-menu > a, .navbar .u-main-nav-v7 .nav-item.hs-has-mega-menu > a, .navbar .u-main-nav-v7 .nav-item.dropdown > a {
  padding-right: 3.21429rem;
}

.navbar .u-main-nav-v7 .nav-item.hs-has-sub-menu > a::after, .navbar .u-main-nav-v7 .nav-item.hs-has-mega-menu > a::after, .navbar .u-main-nav-v7 .nav-item.dropdown > a::after {
  right: 1.78571rem;
}

.navbar .u-main-nav-v7 .nav-item.active > .nav-link,
.navbar .u-main-nav-v7 .nav-item:hover > .nav-link,
.navbar .u-main-nav-v7 .nav-item:focus > .nav-link,
.navbar .u-main-nav-v7 .nav-item.show > .nav-link,
.navbar .u-main-nav-v7 .nav-item.hs-sub-menu-opened > .nav-link,
.navbar .u-main-nav-v7 .nav-item .nav-link:focus {
  border-color: #226f1c;
}

@media all and (max-width: 1200px - 1) {
  .navbar .u-main-nav-v7 .nav-link {
    padding: 0.64286rem 1.42857rem;
  }
  .navbar .u-main-nav-v7 .nav-item.hs-has-sub-menu > a, .navbar .u-main-nav-v7 .nav-item.hs-has-mega-menu > a, .navbar .u-main-nav-v7 .nav-item.dropdown > a {
    padding-right: 2.14286rem;
  }
  .navbar .u-main-nav-v7 .nav-item.hs-has-sub-menu > a::after, .navbar .u-main-nav-v7 .nav-item.hs-has-mega-menu > a::after, .navbar .u-main-nav-v7 .nav-item.dropdown > a::after {
    right: 1.07143rem;
  }
}

/*------------------------------------
  Navigation Style v8
------------------------------------*/
.navbar .u-main-nav-v8 .nav-link {
  color: #333;
  padding: 0.78571rem 2.14286rem;
}

.navbar .u-main-nav-v8 .nav-item.hs-has-sub-menu > a, .navbar .u-main-nav-v8 .nav-item.hs-has-mega-menu > a, .navbar .u-main-nav-v8 .nav-item.dropdown > a {
  padding-right: 3.21429rem;
}

.navbar .u-main-nav-v8 .nav-item.hs-has-sub-menu > a::after, .navbar .u-main-nav-v8 .nav-item.hs-has-mega-menu > a::after, .navbar .u-main-nav-v8 .nav-item.dropdown > a::after {
  right: 1.78571rem;
}

.navbar .u-main-nav-v8 .nav-item.active > .nav-link,
.navbar .u-main-nav-v8 .nav-item:hover > .nav-link,
.navbar .u-main-nav-v8 .nav-item:focus > .nav-link,
.navbar .u-main-nav-v8 .nav-item.show > .nav-link,
.navbar .u-main-nav-v8 .nav-item.hs-sub-menu-opened > .nav-link,
.navbar .u-main-nav-v8 .nav-item .nav-link:focus {
  color: #fff;
  background-color: #226f1c;
}

@media all and (max-width: 1200px - 1) {
  .navbar .u-main-nav-v8 .nav-link {
    padding: 0.64286rem 1.42857rem;
  }
  .navbar .u-main-nav-v8 .nav-item.hs-has-sub-menu > a, .navbar .u-main-nav-v8 .nav-item.hs-has-mega-menu > a, .navbar .u-main-nav-v8 .nav-item.dropdown > a {
    padding-right: 2.14286rem;
  }
  .navbar .u-main-nav-v8 .nav-item.hs-has-sub-menu > a::after, .navbar .u-main-nav-v8 .nav-item.hs-has-mega-menu > a::after, .navbar .u-main-nav-v8 .nav-item.dropdown > a::after {
    right: 1.07143rem;
  }
}

/*------------------------------------
  Navigation Style v9
------------------------------------*/
.navbar .u-main-nav-v9 .nav-link {
  color: #777;
  padding: 0 1.42857rem 0 0;
}

.navbar .u-main-nav-v9 .nav-item > a {
  padding: 1.42857rem 0;
}

.navbar .u-main-nav-v9 .nav-item.hs-has-sub-menu > a::after, .navbar .u-main-nav-v9 .nav-item.hs-has-mega-menu > a::after {
  font-size: 1.28571rem;
  right: 0;
}

.navbar .u-main-nav-v9 .nav-item.active > .nav-link,
.navbar .u-main-nav-v9 .nav-item:hover > .nav-link,
.navbar .u-main-nav-v9 .nav-item:focus > .nav-link,
.navbar .u-main-nav-v9 .nav-item .nav-link:focus {
  color: #000;
}

.navbar .u-main-nav-v9 .nav-item.active > .nav-link::after,
.navbar .u-main-nav-v9 .nav-item:hover > .nav-link::after,
.navbar .u-main-nav-v9 .nav-item:focus > .nav-link::after,
.navbar .u-main-nav-v9 .nav-item .nav-link:focus::after {
  color: #226f1c;
}

/* Dropdown navigation styles */
/*------------------------------------
  Navigation Dropdown Style v1
------------------------------------*/
.navbar .u-sub-menu-v1 .hs-has-sub-menu .hs-has-sub-menu.active > a,
.navbar .u-sub-menu-v1 .hs-has-sub-menu .hs-has-sub-menu:active > a,
.navbar .u-sub-menu-v1 .hs-has-sub-menu .hs-has-sub-menu:focus > a,
.navbar .u-sub-menu-v1 .hs-has-sub-menu .hs-has-sub-menu:hover > a, .navbar .u-sub-menu-v1 .hs-sub-menu .dropdown-item.active > a, .navbar .u-sub-menu-v1 .hs-sub-menu .dropdown-item:active > a, .navbar .u-sub-menu-v1 .hs-sub-menu .dropdown-item:focus > a, .navbar .u-sub-menu-v1 .hs-sub-menu .dropdown-item:hover > a,
.navbar .u-sub-menu-v1 .dropdown-menu .dropdown-item.active > a,
.navbar .u-sub-menu-v1 .dropdown-menu .dropdown-item:active > a,
.navbar .u-sub-menu-v1 .dropdown-menu .dropdown-item:focus > a,
.navbar .u-sub-menu-v1 .dropdown-menu .dropdown-item:hover > a, .navbar .u-sub-menu-v1 .hs-sub-menu a:active, .navbar .u-sub-menu-v1 .hs-sub-menu a:focus, .navbar .u-sub-menu-v1 .hs-sub-menu a:hover,
.navbar .u-sub-menu-v1 .dropdown-menu a:active,
.navbar .u-sub-menu-v1 .dropdown-menu a:focus,
.navbar .u-sub-menu-v1 .dropdown-menu a:hover {
  color: #226f1c !important;
  background-color: transparent;
  border: none;
}

.navbar .u-sub-menu-v1 .hs-has-sub-menu .hs-has-sub-menu > a::after {
  content: "\e902";
  font-family: "hs-icons";
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translate3d(0, -50%, 0);
}

.navbar .u-sub-menu-v1 .hs-has-mega-menu .hs-has-sub-menu > a::after {
  content: "\e902";
}

.navbar .u-sub-menu-v1 .hs-sub-menu,
.navbar .u-sub-menu-v1 .dropdown-menu {
  font-size: 0.92857rem;
  min-width: 16.42857rem;
  padding: 0.71429rem 0;
}

.navbar .u-sub-menu-v1 .hs-sub-menu .dropdown-item,
.navbar .u-sub-menu-v1 .dropdown-menu .dropdown-item {
  font-weight: inherit;
  color: inherit;
  padding: 0;
}

.navbar .u-sub-menu-v1 .hs-sub-menu .dropdown-item.active, .navbar .u-sub-menu-v1 .hs-sub-menu .dropdown-item:active, .navbar .u-sub-menu-v1 .hs-sub-menu .dropdown-item:focus, .navbar .u-sub-menu-v1 .hs-sub-menu .dropdown-item:hover,
.navbar .u-sub-menu-v1 .dropdown-menu .dropdown-item.active,
.navbar .u-sub-menu-v1 .dropdown-menu .dropdown-item:active,
.navbar .u-sub-menu-v1 .dropdown-menu .dropdown-item:focus,
.navbar .u-sub-menu-v1 .dropdown-menu .dropdown-item:hover {
  border: none;
  background-color: transparent;
}

.navbar .u-sub-menu-v1 .hs-sub-menu a,
.navbar .u-sub-menu-v1 .dropdown-menu a {
  color: #777;
  text-decoration: none !important;
  display: block;
  padding: 0.42857rem 1.78571rem;
}

.navbar .u-sub-menu-v1 .hs-sub-menu,
.navbar .u-sub-menu-v1 .hs-mega-menu,
.navbar .u-sub-menu-v1 .dropdown-menu {
  font-weight: normal;
  text-transform: none;
  margin-top: 0;
  color: inherit;
  background-color: #fff;
  background-clip: border-box;
  border-width: 3px 0 0 0;
  border-style: solid;
  border-color: #226f1c;
  border-radius: 0;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}

.navbar .u-sub-menu-v1 .hs-sub-menu .hs-sub-menu,
.navbar .u-sub-menu-v1 .hs-mega-menu .hs-sub-menu,
.navbar .u-sub-menu-v1 .dropdown-menu .hs-sub-menu {
  margin-top: -1.07143rem;
}

.navbar .u-sub-menu-v1.hs-mobile-state .hs-sub-menu {
  width: auto;
}

.navbar .u-sub-menu-v1.hs-mobile-state .hs-sub-menu .hs-sub-menu,
.navbar .u-sub-menu-v1.hs-mobile-state .hs-sub-menu .hs-mega-menu,
.navbar .u-sub-menu-v1.hs-mobile-state .hs-sub-menu .hs-sub-menu.hs-reversed,
.navbar .u-sub-menu-v1.hs-mobile-state .hs-sub-menu .hs-mega-menu.hs-reversed,
.navbar .u-sub-menu-v1.hs-mobile-state .hs-mega-menu .hs-sub-menu,
.navbar .u-sub-menu-v1.hs-mobile-state .hs-mega-menu .hs-mega-menu,
.navbar .u-sub-menu-v1.hs-mobile-state .hs-mega-menu .hs-sub-menu.hs-reversed,
.navbar .u-sub-menu-v1.hs-mobile-state .hs-mega-menu .hs-mega-menu.hs-reversed {
  margin: 0.21429rem;
}

.navbar .hs-mobile-state .u-sub-menu-v1 .hs-sub-menu {
  width: auto;
}

.navbar .hs-mobile-state .u-sub-menu-v1 .hs-sub-menu .hs-sub-menu,
.navbar .hs-mobile-state .u-sub-menu-v1 .hs-sub-menu .hs-mega-menu,
.navbar .hs-mobile-state .u-sub-menu-v1 .hs-sub-menu .hs-sub-menu.hs-reversed,
.navbar .hs-mobile-state .u-sub-menu-v1 .hs-sub-menu .hs-mega-menu.hs-reversed,
.navbar .hs-mobile-state .u-sub-menu-v1 .hs-mega-menu .hs-sub-menu,
.navbar .hs-mobile-state .u-sub-menu-v1 .hs-mega-menu .hs-mega-menu,
.navbar .hs-mobile-state .u-sub-menu-v1 .hs-mega-menu .hs-sub-menu.hs-reversed,
.navbar .hs-mobile-state .u-sub-menu-v1 .hs-mega-menu .hs-mega-menu.hs-reversed {
  margin: 0.21429rem;
}

/*------------------------------------
  Navigation Dropdown Style v2
------------------------------------*/
.navbar .u-sub-menu-v2 .hs-has-sub-menu .hs-has-sub-menu.active > a,
.navbar .u-sub-menu-v2 .hs-has-sub-menu .hs-has-sub-menu:active > a,
.navbar .u-sub-menu-v2 .hs-has-sub-menu .hs-has-sub-menu:focus > a,
.navbar .u-sub-menu-v2 .hs-has-sub-menu .hs-has-sub-menu:hover > a, .navbar .u-sub-menu-v2 .hs-sub-menu .dropdown-item.active > a, .navbar .u-sub-menu-v2 .hs-sub-menu .dropdown-item:active > a, .navbar .u-sub-menu-v2 .hs-sub-menu .dropdown-item:focus > a, .navbar .u-sub-menu-v2 .hs-sub-menu .dropdown-item:hover > a,
.navbar .u-sub-menu-v2 .dropdown-menu .dropdown-item.active > a,
.navbar .u-sub-menu-v2 .dropdown-menu .dropdown-item:active > a,
.navbar .u-sub-menu-v2 .dropdown-menu .dropdown-item:focus > a,
.navbar .u-sub-menu-v2 .dropdown-menu .dropdown-item:hover > a, .navbar .u-sub-menu-v2 .hs-sub-menu a:active, .navbar .u-sub-menu-v2 .hs-sub-menu a:focus, .navbar .u-sub-menu-v2 .hs-sub-menu a:hover,
.navbar .u-sub-menu-v2 .dropdown-menu a:active,
.navbar .u-sub-menu-v2 .dropdown-menu a:focus,
.navbar .u-sub-menu-v2 .dropdown-menu a:hover {
  color: #777 !important;
  background-color: #eee;
  border: none;
}

.navbar .u-sub-menu-v2 .hs-has-sub-menu .hs-has-sub-menu > a::after {
  content: "\e902";
  font-family: "hs-icons";
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translate3d(0, -50%, 0);
}

.navbar .u-sub-menu-v2 .hs-has-mega-menu .hs-has-sub-menu > a::after {
  content: "\e902";
}

.navbar .u-sub-menu-v2 .hs-sub-menu,
.navbar .u-sub-menu-v2 .dropdown-menu {
  font-size: 0.92857rem;
  min-width: 16.42857rem;
  padding: 0.71429rem 0;
}

.navbar .u-sub-menu-v2 .hs-sub-menu .dropdown-item,
.navbar .u-sub-menu-v2 .dropdown-menu .dropdown-item {
  font-weight: inherit;
  color: inherit;
  padding: 0;
}

.navbar .u-sub-menu-v2 .hs-sub-menu .dropdown-item.active, .navbar .u-sub-menu-v2 .hs-sub-menu .dropdown-item:active, .navbar .u-sub-menu-v2 .hs-sub-menu .dropdown-item:focus, .navbar .u-sub-menu-v2 .hs-sub-menu .dropdown-item:hover,
.navbar .u-sub-menu-v2 .dropdown-menu .dropdown-item.active,
.navbar .u-sub-menu-v2 .dropdown-menu .dropdown-item:active,
.navbar .u-sub-menu-v2 .dropdown-menu .dropdown-item:focus,
.navbar .u-sub-menu-v2 .dropdown-menu .dropdown-item:hover {
  border-color: #eee;
  background-color: transparent;
}

.navbar .u-sub-menu-v2 .hs-sub-menu a,
.navbar .u-sub-menu-v2 .dropdown-menu a {
  color: #777;
  text-decoration: none !important;
  display: block;
  padding: 0.42857rem 1.78571rem;
}

.navbar .u-sub-menu-v2 .hs-sub-menu > li:not(:last-child),
.navbar .u-sub-menu-v2 .dropdown-menu > li:not(:last-child) {
  border-bottom: 1px solid #eee;
}

.navbar .u-sub-menu-v2 .hs-sub-menu,
.navbar .u-sub-menu-v2 .hs-mega-menu,
.navbar .u-sub-menu-v2 .dropdown-menu {
  font-weight: normal;
  text-transform: none;
  min-width: 14.28571rem;
  margin-top: 0;
  padding: 0;
  color: inherit;
  background-color: #fff;
  background-clip: border-box;
  border-width: 2px 0 2px 0;
  border-style: solid;
  border-top-color: #226f1c;
  border-bottom-color: #777;
  border-radius: 0;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}

.navbar .u-sub-menu-v2 .hs-sub-menu .hs-sub-menu,
.navbar .u-sub-menu-v2 .hs-mega-menu .hs-sub-menu,
.navbar .u-sub-menu-v2 .dropdown-menu .hs-sub-menu {
  margin-top: -1.07143rem;
}

.navbar .u-sub-menu-v2.hs-mobile-state .hs-sub-menu {
  width: auto;
}

.navbar .u-sub-menu-v2.hs-mobile-state .hs-sub-menu .hs-sub-menu,
.navbar .u-sub-menu-v2.hs-mobile-state .hs-sub-menu .hs-mega-menu,
.navbar .u-sub-menu-v2.hs-mobile-state .hs-sub-menu .hs-sub-menu.hs-reversed,
.navbar .u-sub-menu-v2.hs-mobile-state .hs-sub-menu .hs-mega-menu.hs-reversed,
.navbar .u-sub-menu-v2.hs-mobile-state .hs-mega-menu .hs-sub-menu,
.navbar .u-sub-menu-v2.hs-mobile-state .hs-mega-menu .hs-mega-menu,
.navbar .u-sub-menu-v2.hs-mobile-state .hs-mega-menu .hs-sub-menu.hs-reversed,
.navbar .u-sub-menu-v2.hs-mobile-state .hs-mega-menu .hs-mega-menu.hs-reversed {
  margin: 0.21429rem;
}

.navbar .hs-mobile-state .u-sub-menu-v2 .hs-sub-menu {
  width: auto;
}

.navbar .hs-mobile-state .u-sub-menu-v2 .hs-sub-menu .hs-sub-menu,
.navbar .hs-mobile-state .u-sub-menu-v2 .hs-sub-menu .hs-mega-menu,
.navbar .hs-mobile-state .u-sub-menu-v2 .hs-sub-menu .hs-sub-menu.hs-reversed,
.navbar .hs-mobile-state .u-sub-menu-v2 .hs-sub-menu .hs-mega-menu.hs-reversed,
.navbar .hs-mobile-state .u-sub-menu-v2 .hs-mega-menu .hs-sub-menu,
.navbar .hs-mobile-state .u-sub-menu-v2 .hs-mega-menu .hs-mega-menu,
.navbar .hs-mobile-state .u-sub-menu-v2 .hs-mega-menu .hs-sub-menu.hs-reversed,
.navbar .hs-mobile-state .u-sub-menu-v2 .hs-mega-menu .hs-mega-menu.hs-reversed {
  margin: 0.21429rem;
}

/*------------------------------------
  Navigation Dropdown Style v3
------------------------------------*/
.navbar .u-sub-menu-v3 .hs-has-sub-menu .hs-has-sub-menu.active > a,
.navbar .u-sub-menu-v3 .hs-has-sub-menu .hs-has-sub-menu:active > a,
.navbar .u-sub-menu-v3 .hs-has-sub-menu .hs-has-sub-menu:focus > a,
.navbar .u-sub-menu-v3 .hs-has-sub-menu .hs-has-sub-menu:hover > a, .navbar .u-sub-menu-v3 .hs-sub-menu .dropdown-item.active > a, .navbar .u-sub-menu-v3 .hs-sub-menu .dropdown-item:active > a, .navbar .u-sub-menu-v3 .hs-sub-menu .dropdown-item:focus > a, .navbar .u-sub-menu-v3 .hs-sub-menu .dropdown-item:hover > a,
.navbar .u-sub-menu-v3 .dropdown-menu .dropdown-item.active > a,
.navbar .u-sub-menu-v3 .dropdown-menu .dropdown-item:active > a,
.navbar .u-sub-menu-v3 .dropdown-menu .dropdown-item:focus > a,
.navbar .u-sub-menu-v3 .dropdown-menu .dropdown-item:hover > a, .navbar .u-sub-menu-v3 .hs-sub-menu a:active, .navbar .u-sub-menu-v3 .hs-sub-menu a:focus, .navbar .u-sub-menu-v3 .hs-sub-menu a:hover,
.navbar .u-sub-menu-v3 .dropdown-menu a:active,
.navbar .u-sub-menu-v3 .dropdown-menu a:focus,
.navbar .u-sub-menu-v3 .dropdown-menu a:hover {
  color: #fff !important;
  background-color: #226f1c;
  border: none;
}

.navbar .u-sub-menu-v3 .hs-has-sub-menu .hs-has-sub-menu > a::after {
  content: "\e902";
  font-family: "hs-icons";
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translate3d(0, -50%, 0);
}

.navbar .u-sub-menu-v3 .hs-has-mega-menu .hs-has-sub-menu > a::after {
  content: "\e902";
}

.navbar .u-sub-menu-v3 .hs-sub-menu,
.navbar .u-sub-menu-v3 .dropdown-menu {
  font-size: 0.92857rem;
  min-width: 16.42857rem;
  padding: 0.71429rem 0;
}

.navbar .u-sub-menu-v3 .hs-sub-menu .dropdown-item,
.navbar .u-sub-menu-v3 .dropdown-menu .dropdown-item {
  font-weight: inherit;
  color: inherit;
  padding: 0;
}

.navbar .u-sub-menu-v3 .hs-sub-menu .dropdown-item.active, .navbar .u-sub-menu-v3 .hs-sub-menu .dropdown-item:active, .navbar .u-sub-menu-v3 .hs-sub-menu .dropdown-item:focus, .navbar .u-sub-menu-v3 .hs-sub-menu .dropdown-item:hover,
.navbar .u-sub-menu-v3 .dropdown-menu .dropdown-item.active,
.navbar .u-sub-menu-v3 .dropdown-menu .dropdown-item:active,
.navbar .u-sub-menu-v3 .dropdown-menu .dropdown-item:focus,
.navbar .u-sub-menu-v3 .dropdown-menu .dropdown-item:hover {
  border-color: #eee;
  background-color: transparent;
}

.navbar .u-sub-menu-v3 .hs-sub-menu a,
.navbar .u-sub-menu-v3 .dropdown-menu a {
  color: #777;
  text-decoration: none !important;
  display: block;
  padding: 0.42857rem 1.78571rem;
}

.navbar .u-sub-menu-v3 .hs-sub-menu > li:not(:last-child),
.navbar .u-sub-menu-v3 .dropdown-menu > li:not(:last-child) {
  border-bottom: 1px solid #eee;
}

.navbar .u-sub-menu-v3 .hs-sub-menu,
.navbar .u-sub-menu-v3 .hs-mega-menu,
.navbar .u-sub-menu-v3 .dropdown-menu {
  font-weight: normal;
  text-transform: none;
  min-width: 14.28571rem;
  margin-top: 0;
  padding: 0;
  color: inherit;
  background-color: #fff;
  background-clip: border-box;
  border-width: 2px 0 2px 0;
  border-style: solid;
  border-top-color: #226f1c;
  border-bottom-color: #777;
  border-radius: 0;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}

.navbar .u-sub-menu-v3 .hs-sub-menu .hs-sub-menu,
.navbar .u-sub-menu-v3 .hs-mega-menu .hs-sub-menu,
.navbar .u-sub-menu-v3 .dropdown-menu .hs-sub-menu {
  margin-top: -1.07143rem;
}

.navbar .u-sub-menu-v3.hs-mobile-state .hs-sub-menu {
  width: auto;
}

.navbar .u-sub-menu-v3.hs-mobile-state .hs-sub-menu .hs-sub-menu,
.navbar .u-sub-menu-v3.hs-mobile-state .hs-sub-menu .hs-mega-menu,
.navbar .u-sub-menu-v3.hs-mobile-state .hs-sub-menu .hs-sub-menu.hs-reversed,
.navbar .u-sub-menu-v3.hs-mobile-state .hs-sub-menu .hs-mega-menu.hs-reversed,
.navbar .u-sub-menu-v3.hs-mobile-state .hs-mega-menu .hs-sub-menu,
.navbar .u-sub-menu-v3.hs-mobile-state .hs-mega-menu .hs-mega-menu,
.navbar .u-sub-menu-v3.hs-mobile-state .hs-mega-menu .hs-sub-menu.hs-reversed,
.navbar .u-sub-menu-v3.hs-mobile-state .hs-mega-menu .hs-mega-menu.hs-reversed {
  margin: 0.21429rem;
}

.navbar .hs-mobile-state .u-sub-menu-v3 .hs-sub-menu {
  width: auto;
}

.navbar .hs-mobile-state .u-sub-menu-v3 .hs-sub-menu .hs-sub-menu,
.navbar .hs-mobile-state .u-sub-menu-v3 .hs-sub-menu .hs-mega-menu,
.navbar .hs-mobile-state .u-sub-menu-v3 .hs-sub-menu .hs-sub-menu.hs-reversed,
.navbar .hs-mobile-state .u-sub-menu-v3 .hs-sub-menu .hs-mega-menu.hs-reversed,
.navbar .hs-mobile-state .u-sub-menu-v3 .hs-mega-menu .hs-sub-menu,
.navbar .hs-mobile-state .u-sub-menu-v3 .hs-mega-menu .hs-mega-menu,
.navbar .hs-mobile-state .u-sub-menu-v3 .hs-mega-menu .hs-sub-menu.hs-reversed,
.navbar .hs-mobile-state .u-sub-menu-v3 .hs-mega-menu .hs-mega-menu.hs-reversed {
  margin: 0.21429rem;
}

/*------------------------------------
  Navigation Dropdown Style v4
------------------------------------*/
.navbar .u-sub-menu-v4 .hs-has-sub-menu .hs-has-sub-menu.active > a,
.navbar .u-sub-menu-v4 .hs-has-sub-menu .hs-has-sub-menu:active > a,
.navbar .u-sub-menu-v4 .hs-has-sub-menu .hs-has-sub-menu:focus > a,
.navbar .u-sub-menu-v4 .hs-has-sub-menu .hs-has-sub-menu:hover > a, .navbar .u-sub-menu-v4 .hs-sub-menu .dropdown-item.active > a, .navbar .u-sub-menu-v4 .hs-sub-menu .dropdown-item:active > a, .navbar .u-sub-menu-v4 .hs-sub-menu .dropdown-item:focus > a, .navbar .u-sub-menu-v4 .hs-sub-menu .dropdown-item:hover > a,
.navbar .u-sub-menu-v4 .dropdown-menu .dropdown-item.active > a,
.navbar .u-sub-menu-v4 .dropdown-menu .dropdown-item:active > a,
.navbar .u-sub-menu-v4 .dropdown-menu .dropdown-item:focus > a,
.navbar .u-sub-menu-v4 .dropdown-menu .dropdown-item:hover > a, .navbar .u-sub-menu-v4 .hs-sub-menu a:active, .navbar .u-sub-menu-v4 .hs-sub-menu a:focus, .navbar .u-sub-menu-v4 .hs-sub-menu a:hover,
.navbar .u-sub-menu-v4 .dropdown-menu a:active,
.navbar .u-sub-menu-v4 .dropdown-menu a:focus,
.navbar .u-sub-menu-v4 .dropdown-menu a:hover {
  color: #fff !important;
  background-color: #226f1c;
  border: none;
}

.navbar .u-sub-menu-v4 .hs-has-sub-menu .hs-has-sub-menu > a::after {
  content: "\e902";
  font-family: "hs-icons";
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translate3d(0, -50%, 0);
}

.navbar .u-sub-menu-v4 .hs-has-mega-menu .hs-has-sub-menu > a::after {
  content: "\e902";
}

.navbar .u-sub-menu-v4 .hs-sub-menu,
.navbar .u-sub-menu-v4 .dropdown-menu {
  font-size: 0.92857rem;
  min-width: 16.42857rem;
  padding: 0.71429rem 0;
}

.navbar .u-sub-menu-v4 .hs-sub-menu .dropdown-item,
.navbar .u-sub-menu-v4 .dropdown-menu .dropdown-item {
  font-weight: inherit;
  color: inherit;
  padding: 0;
}

.navbar .u-sub-menu-v4 .hs-sub-menu .dropdown-item.active, .navbar .u-sub-menu-v4 .hs-sub-menu .dropdown-item:active, .navbar .u-sub-menu-v4 .hs-sub-menu .dropdown-item:focus, .navbar .u-sub-menu-v4 .hs-sub-menu .dropdown-item:hover,
.navbar .u-sub-menu-v4 .dropdown-menu .dropdown-item.active,
.navbar .u-sub-menu-v4 .dropdown-menu .dropdown-item:active,
.navbar .u-sub-menu-v4 .dropdown-menu .dropdown-item:focus,
.navbar .u-sub-menu-v4 .dropdown-menu .dropdown-item:hover {
  border: none;
  background-color: transparent;
}

.navbar .u-sub-menu-v4 .hs-sub-menu a,
.navbar .u-sub-menu-v4 .dropdown-menu a {
  color: #777;
  text-decoration: none !important;
  display: block;
  padding: 0.42857rem 1.78571rem;
}

.navbar .u-sub-menu-v4 .hs-sub-menu > li:not(:last-child),
.navbar .u-sub-menu-v4 .dropdown-menu > li:not(:last-child) {
  border-bottom: 1px solid #eee;
}

.navbar .u-sub-menu-v4 .hs-sub-menu,
.navbar .u-sub-menu-v4 .hs-mega-menu,
.navbar .u-sub-menu-v4 .dropdown-menu {
  font-weight: normal;
  text-transform: none;
  min-width: 14.28571rem;
  margin-top: 0;
  padding: 0;
  color: inherit;
  background-color: #fff;
  background-clip: border-box;
  border-width: 2px 0 2px 0;
  border-style: solid;
  border-top-color: #226f1c;
  border-bottom-color: #777;
  border-radius: 0;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}

.navbar .u-sub-menu-v4 .hs-sub-menu .hs-sub-menu,
.navbar .u-sub-menu-v4 .hs-mega-menu .hs-sub-menu,
.navbar .u-sub-menu-v4 .dropdown-menu .hs-sub-menu {
  margin-top: -1.07143rem;
}

.navbar .u-sub-menu-v4.hs-mobile-state .hs-sub-menu {
  width: auto;
}

.navbar .u-sub-menu-v4.hs-mobile-state .hs-sub-menu .hs-sub-menu,
.navbar .u-sub-menu-v4.hs-mobile-state .hs-sub-menu .hs-mega-menu,
.navbar .u-sub-menu-v4.hs-mobile-state .hs-sub-menu .hs-sub-menu.hs-reversed,
.navbar .u-sub-menu-v4.hs-mobile-state .hs-sub-menu .hs-mega-menu.hs-reversed,
.navbar .u-sub-menu-v4.hs-mobile-state .hs-mega-menu .hs-sub-menu,
.navbar .u-sub-menu-v4.hs-mobile-state .hs-mega-menu .hs-mega-menu,
.navbar .u-sub-menu-v4.hs-mobile-state .hs-mega-menu .hs-sub-menu.hs-reversed,
.navbar .u-sub-menu-v4.hs-mobile-state .hs-mega-menu .hs-mega-menu.hs-reversed {
  margin: 0.21429rem;
}

.navbar .hs-mobile-state .u-sub-menu-v4 .hs-sub-menu {
  width: auto;
}

.navbar .hs-mobile-state .u-sub-menu-v4 .hs-sub-menu .hs-sub-menu,
.navbar .hs-mobile-state .u-sub-menu-v4 .hs-sub-menu .hs-mega-menu,
.navbar .hs-mobile-state .u-sub-menu-v4 .hs-sub-menu .hs-sub-menu.hs-reversed,
.navbar .hs-mobile-state .u-sub-menu-v4 .hs-sub-menu .hs-mega-menu.hs-reversed,
.navbar .hs-mobile-state .u-sub-menu-v4 .hs-mega-menu .hs-sub-menu,
.navbar .hs-mobile-state .u-sub-menu-v4 .hs-mega-menu .hs-mega-menu,
.navbar .hs-mobile-state .u-sub-menu-v4 .hs-mega-menu .hs-sub-menu.hs-reversed,
.navbar .hs-mobile-state .u-sub-menu-v4 .hs-mega-menu .hs-mega-menu.hs-reversed {
  margin: 0.21429rem;
}

/*------------------------------------
  Navigation Dropdown Style v5
------------------------------------*/
.u-dropdown-v5 {
  display: block;
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: opacity .3s, max-height .3s;
}

.u-dropdown-v5.show {
  max-height: 200px;
  opacity: 1;
}

/*------------------------------------
  Navigation Dropdown Style Inline
------------------------------------*/
.u-navbar--inline-submenu .dropdown-menu {
  white-space: nowrap;
  top: 98%;
  left: 50%;
  border-radius: 0;
  overflow: visible !important;
  border-left: none;
  border-right: none;
  transform: translate3d(-50%, 0, 0);
}

.u-navbar--inline-submenu .dropdown-menu::after, .u-navbar--inline-submenu .dropdown-menu::before {
  content: "";
  position: absolute;
  top: -1px;
  bottom: 0;
  width: 2000%;
  border-top: inherit;
  border-bottom: inherit;
  background-color: inherit;
}

.u-navbar--inline-submenu .dropdown-menu::after {
  left: 100%;
}

.u-navbar--inline-submenu .dropdown-menu::before {
  right: 100%;
}

.u-navbar--inline-submenu .dropdown-menu > li {
  display: inline-block;
}

.u-navbar--inline-submenu.u-navbar--overflow .dropdown,
.u-navbar--inline-submenu.u-navbar--overflow .container {
  position: static;
}

.u-navbar--inline-submenu.u-navbar--overflow .dropdown-menu {
  white-space: normal;
  left: 0;
  transform: translate3d(0, 0, 0);
  margin-top: 0;
}

@media all and (min-width: 1200px) {
  .u-navbar--inline-submenu--xl .dropdown-menu {
    white-space: nowrap;
    top: 98%;
    left: 50%;
    border-radius: 0;
    overflow: visible !important;
    border-left: none;
    border-right: none;
    transform: translate3d(-50%, 0, 0);
  }
  .u-navbar--inline-submenu--xl .dropdown-menu::after, .u-navbar--inline-submenu--xl .dropdown-menu::before {
    content: "";
    position: absolute;
    top: -1px;
    bottom: 0;
    width: 2000%;
    border-top: inherit;
    border-bottom: inherit;
    background-color: inherit;
  }
  .u-navbar--inline-submenu--xl .dropdown-menu::after {
    left: 100%;
  }
  .u-navbar--inline-submenu--xl .dropdown-menu::before {
    right: 100%;
  }
  .u-navbar--inline-submenu--xl .dropdown-menu > li {
    display: inline-block;
  }
  .u-navbar--inline-submenu--xl.u-navbar--overflow .dropdown,
  .u-navbar--inline-submenu--xl.u-navbar--overflow .container {
    position: static;
  }
  .u-navbar--inline-submenu--xl.u-navbar--overflow .dropdown-menu {
    white-space: normal;
    left: 0;
    transform: translate3d(0, 0, 0);
    margin-top: 0;
  }
}

@media all and (min-width: 992px) {
  .u-navbar--inline-submenu--lg .dropdown-menu {
    white-space: nowrap;
    top: 98%;
    left: 50%;
    border-radius: 0;
    overflow: visible !important;
    border-left: none;
    border-right: none;
    transform: translate3d(-50%, 0, 0);
  }
  .u-navbar--inline-submenu--lg .dropdown-menu::after, .u-navbar--inline-submenu--lg .dropdown-menu::before {
    content: "";
    position: absolute;
    top: -1px;
    bottom: 0;
    width: 2000%;
    border-top: inherit;
    border-bottom: inherit;
    background-color: inherit;
  }
  .u-navbar--inline-submenu--lg .dropdown-menu::after {
    left: 100%;
  }
  .u-navbar--inline-submenu--lg .dropdown-menu::before {
    right: 100%;
  }
  .u-navbar--inline-submenu--lg .dropdown-menu > li {
    display: inline-block;
  }
  .u-navbar--inline-submenu--lg.u-navbar--overflow .dropdown,
  .u-navbar--inline-submenu--lg.u-navbar--overflow .container {
    position: static;
  }
  .u-navbar--inline-submenu--lg.u-navbar--overflow .dropdown-menu {
    white-space: normal;
    left: 0;
    transform: translate3d(0, 0, 0);
    margin-top: 0;
  }
}

@media all and (min-width: 768px) {
  .u-navbar--inline-submenu--md .dropdown-menu {
    white-space: nowrap;
    top: 98%;
    left: 50%;
    border-radius: 0;
    overflow: visible !important;
    border-left: none;
    border-right: none;
    transform: translate3d(-50%, 0, 0);
  }
  .u-navbar--inline-submenu--md .dropdown-menu::after, .u-navbar--inline-submenu--md .dropdown-menu::before {
    content: "";
    position: absolute;
    top: -1px;
    bottom: 0;
    width: 2000%;
    border-top: inherit;
    border-bottom: inherit;
    background-color: inherit;
  }
  .u-navbar--inline-submenu--md .dropdown-menu::after {
    left: 100%;
  }
  .u-navbar--inline-submenu--md .dropdown-menu::before {
    right: 100%;
  }
  .u-navbar--inline-submenu--md .dropdown-menu > li {
    display: inline-block;
  }
  .u-navbar--inline-submenu--md.u-navbar--overflow .dropdown,
  .u-navbar--inline-submenu--md.u-navbar--overflow .container {
    position: static;
  }
  .u-navbar--inline-submenu--md.u-navbar--overflow .dropdown-menu {
    white-space: normal;
    left: 0;
    transform: translate3d(0, 0, 0);
    margin-top: 0;
  }
}

@media all and (min-width: 576px) {
  .u-navbar--inline-submenu--sm .dropdown-menu {
    white-space: nowrap;
    top: 98%;
    left: 50%;
    border-radius: 0;
    overflow: visible !important;
    border-left: none;
    border-right: none;
    transform: translate3d(-50%, 0, 0);
  }
  .u-navbar--inline-submenu--sm .dropdown-menu::after, .u-navbar--inline-submenu--sm .dropdown-menu::before {
    content: "";
    position: absolute;
    top: -1px;
    bottom: 0;
    width: 2000%;
    border-top: inherit;
    border-bottom: inherit;
    background-color: inherit;
  }
  .u-navbar--inline-submenu--sm .dropdown-menu::after {
    left: 100%;
  }
  .u-navbar--inline-submenu--sm .dropdown-menu::before {
    right: 100%;
  }
  .u-navbar--inline-submenu--sm .dropdown-menu > li {
    display: inline-block;
  }
  .u-navbar--inline-submenu--sm.u-navbar--overflow .dropdown,
  .u-navbar--inline-submenu--sm.u-navbar--overflow .container {
    position: static;
  }
  .u-navbar--inline-submenu--sm.u-navbar--overflow .dropdown-menu {
    white-space: normal;
    left: 0;
    transform: translate3d(0, 0, 0);
    margin-top: 0;
  }
}

/*------------------------------------
  Navigation Dropdown With Columns
------------------------------------*/
@media (min-width: 992px) {
  [class*="u-dropdown-col-"] {
    column-gap: 1px;
    column-rule: 1px solid #f7f7f7;
  }
  [class*="u-dropdown-col-"] > * {
    min-width: 200px;
    column-break-inside: avoid;
  }
  .u-dropdown-col-2 {
    columns: 2;
  }
  .u-dropdown-col-3 {
    columns: 3;
  }
}

/*------------------------------------
  Navigation Dropdown Static
------------------------------------*/
.u-dropdown-static {
  position: static !important;
  display: block;
  max-height: 0;
  margin: 0 1.07143rem;
  padding: 0.35714rem 0;
  transform: translate3d(0, 0, 0) !important;
  border: none;
  background-color: transparent;
  overflow: hidden;
  opacity: 0;
  transition: opacity .3s, max-height .3s;
}

.u-dropdown-static.show {
  max-height: 100%;
  opacity: 1;
}

/* Main navigation behaviors */
/*------------------------------------
  Navigation Behavior (Overlay)
------------------------------------*/
body.u-main-nav--overlay-left .u-main-nav__overlay, body.u-main-nav--overlay-right .u-main-nav__overlay {
  overflow: hidden;
}

body.u-main-nav--overlay-right .u-main-nav--overlay {
  right: -21.42857rem;
}

body.u-main-nav--overlay-right .u-main-nav__overlay {
  right: 100%;
}

body.u-main-nav--overlay-right.u-main-nav--overlay-opened .u-main-nav--overlay {
  right: 0;
}

body.u-main-nav--overlay-right.u-main-nav--overlay-opened .u-main-nav__overlay {
  opacity: 1;
  visibility: visible;
}

body.u-main-nav--overlay-left .u-main-nav--overlay {
  left: -21.42857rem;
}

body.u-main-nav--overlay-left .u-main-nav__overlay {
  left: 100%;
}

body.u-main-nav--overlay-left.u-main-nav--overlay-opened .u-main-nav--overlay {
  left: 0;
}

body.u-main-nav--overlay-left.u-main-nav--overlay-opened .u-main-nav__overlay {
  opacity: 1;
  visibility: visible;
}

.navbar-collapse.u-main-nav--overlay {
  position: fixed;
  top: 0;
  height: 100%;
  z-index: 5;
  margin: 0 !important;
  width: 21.42857rem;
  -ms-flex-align: start !important;
      align-items: flex-start !important;
}

.navbar-collapse.u-main-nav--overlay .navbar-nav {
  width: 100%;
  -ms-flex-direction: column;
      flex-direction: column;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.navbar-collapse.u-main-nav--overlay .dropdown-menu {
  position: static;
  width: 100%;
}

.navbar-collapse.u-main-nav--overlay .u-main-nav__list-wrapper {
  margin-left: 0;
  margin-right: 0;
  padding: 2.14286rem 0.71429rem;
  width: 100%;
  height: 100%;
}

.navbar-collapse.u-main-nav--overlay .u-main-nav__list-wrapper .navbar-nav {
  padding: 0.71429rem;
}

/*------------------------------------
  Navigation Behavior (Push)
------------------------------------*/
body.u-main-nav--push-left, body.u-main-nav--push-right {
  width: 100%;
}

body.u-main-nav--push-left .u-main-nav__push, body.u-main-nav--push-right .u-main-nav__push {
  overflow: hidden;
}

body.u-main-nav--push-right .u-main-nav--push {
  right: -21.42857rem;
}

body.u-main-nav--push-right .u-main-nav__overlay {
  right: 100%;
}

body.u-main-nav--push-right.u-main-nav--overlay-opened {
  margin-right: 21.42857rem;
}

body.u-main-nav--push-right.u-main-nav--overlay-opened .u-main-nav--push {
  right: 0;
}

body.u-main-nav--push-right.u-main-nav--overlay-opened .u-main-nav__overlay {
  opacity: 1;
  visibility: visible;
}

body.u-main-nav--push-left .u-main-nav--push {
  left: -21.42857rem;
}

body.u-main-nav--push-left .u-main-nav__overlay {
  left: 100%;
}

body.u-main-nav--push-left.u-main-nav--overlay-opened {
  margin-left: 21.42857rem;
}

body.u-main-nav--push-left.u-main-nav--overlay-opened .u-main-nav--push {
  left: 0;
}

body.u-main-nav--push-left.u-main-nav--overlay-opened .u-main-nav__overlay {
  opacity: 1;
  visibility: visible;
}

.navbar-collapse.u-main-nav--push {
  position: fixed;
  top: 0;
  height: 100%;
  z-index: 5;
  margin: 0 !important;
  width: 21.42857rem;
  max-width: 85%;
  -ms-flex-align: start !important;
      align-items: flex-start !important;
}

.navbar-collapse.u-main-nav--push .navbar-nav {
  width: 100%;
  -ms-flex-direction: column;
      flex-direction: column;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.navbar-collapse.u-main-nav--push .dropdown-menu {
  position: static;
  width: 100%;
}

.navbar-collapse.u-main-nav--push .u-main-nav__list-wrapper {
  margin-left: 0;
  margin-right: 0;
  padding: 2.14286rem 0.71429rem;
  width: 100%;
  height: 100%;
}

.navbar-collapse.u-main-nav--push .u-main-nav__list-wrapper .navbar-nav {
  padding: 0.71429rem;
}

/*------------------------------------
  Navigation Behavior (Smart)
------------------------------------*/
.u-smart-nav {
  position: fixed;
  z-index: 2;
  opacity: 0;
  visibility: hidden;
  transform: scale3d(0.2, 0.2, 0.2);
}

.u-smart-nav .navbar {
  position: absolute;
  width: 21.42857rem;
  opacity: 0;
  visibility: hidden;
}

.u-smart-nav .navbar .dropdown {
  z-index: 1;
}

.u-smart-nav .navbar .dropdown-menu {
  position: relative;
  width: 100%;
}

.u-smart-nav .navbar li {
  transform: translate3d(30px, 0, 0);
  transition: all .3s ease;
}

.u-smart-nav .navbar li:nth-child(1) {
  transition-delay: 60ms;
}

.u-smart-nav .navbar li:nth-child(2) {
  transition-delay: 90ms;
}

.u-smart-nav .navbar li:nth-child(3) {
  transition-delay: 120ms;
}

.u-smart-nav .navbar li:nth-child(4) {
  transition-delay: 150ms;
}

.u-smart-nav .navbar li:nth-child(5) {
  transition-delay: 180ms;
}

.u-smart-nav .navbar li:nth-child(6) {
  transition-delay: 210ms;
}

.u-smart-nav .navbar li:nth-child(7) {
  transition-delay: 240ms;
}

.u-smart-nav .navbar li:nth-child(8) {
  transition-delay: 270ms;
}

.u-smart-nav .navbar li:nth-child(9) {
  transition-delay: 300ms;
}

.u-smart-nav .navbar li:nth-child(10) {
  transition-delay: 330ms;
}

.u-smart-nav .navbar li:nth-child(11) {
  transition-delay: 360ms;
}

.u-smart-nav .navbar li:nth-child(12) {
  transition-delay: 390ms;
}

.u-smart-nav .navbar li:nth-child(13) {
  transition-delay: 420ms;
}

.u-smart-nav .navbar li:nth-child(14) {
  transition-delay: 450ms;
}

.u-smart-nav .navbar li:nth-child(15) {
  transition-delay: 480ms;
}

.u-smart-nav .navbar li:nth-child(16) {
  transition-delay: 510ms;
}

.u-smart-nav .navbar li:nth-child(17) {
  transition-delay: 540ms;
}

.u-smart-nav .navbar li:nth-child(18) {
  transition-delay: 570ms;
}

.u-smart-nav .navbar li:nth-child(19) {
  transition-delay: 600ms;
}

.u-smart-nav__toggler {
  position: relative;
  z-index: 1;
}

.u-smart-nav--opened .navbar {
  opacity: 1;
  visibility: visible;
  transform: scale3d(1, 1, 1) !important;
}

.u-smart-nav--opened .navbar li {
  transform: translate3d(0, 0, 0);
}

.u-smart-nav--shown {
  opacity: 1;
  visibility: visible;
  transform: scale3d(1, 1, 1);
}

.u-smart-nav--bottom-right {
  right: 0.71429rem;
  bottom: 0.71429rem;
}

.u-smart-nav--bottom-right .navbar {
  bottom: 0;
  right: 0;
  transform: scale3d(0.1, 0.1, 0.1);
  -ms-transform-origin: 100% 100%;
      transform-origin: 100% 100%;
  padding-right: 5.71429rem;
}

.u-smart-nav--bottom-left {
  left: 0.71429rem;
  bottom: 0.71429rem;
}

.u-smart-nav--bottom-left .navbar {
  text-align: right;
  bottom: 0;
  left: 0;
  transform: scale3d(0.1, 0.1, 0.1);
  -ms-transform-origin: 0% 100%;
      transform-origin: 0% 100%;
  padding-left: 5.71429rem;
}

.u-smart-nav--top-left {
  left: 0.71429rem;
  top: 0.71429rem;
}

.u-smart-nav--top-left .navbar {
  text-align: right;
  top: 0;
  left: 0;
  transform: scale3d(0.1, 0.1, 0.1);
  -ms-transform-origin: 0% 0%;
      transform-origin: 0% 0%;
  padding-left: 5.71429rem;
}

.u-smart-nav--top-right {
  right: 0.71429rem;
  top: 0.71429rem;
}

.u-smart-nav--top-right .navbar {
  top: 0;
  right: 0;
  transform: scale3d(0.1, 0.1, 0.1);
  -ms-transform-origin: 100% 0%;
      transform-origin: 100% 0%;
  padding-right: 5.71429rem;
}

/* Secondary navigation styles */
/*------------------------------------
  Secondary Navigation
------------------------------------*/
.u-secondary-navigation {
  z-index: 3;
}

.u-secondary-navigation .active a {
  color: #226f1c;
}

/* Sidebar shortcode navigation styles */
/*------------------------------------
  Sidebar Navigation
------------------------------------*/
@media all and (min-width: 992px) {
  .u-sidebar-navigation {
    position: fixed;
    top: 0;
    left: -21.42857rem;
    width: 300px;
  }
}

.u-sidebar-navigation-inner {
  background-color: #1c2434;
  color: #fff;
  padding: 20px 0;
}

.u-sidebar-navigation__search-input {
  border-radius: 3px;
  border: none;
}

.u-sidebar-navigation .nav-link {
  color: #fff;
  padding: 0.5rem 1.42857rem;
}

.u-sidebar-navigation .nav-link:hover {
  background-color: rgba(255, 255, 255, 0.15);
}

.u-sidebar-navigation .nav-link.active {
  background-color: rgba(255, 255, 255, 0.1);
}

.u-sidebar-navigation__toggler {
  position: fixed;
  top: 120px;
  left: 0;
  width: 53px;
  height: 53px;
  background-color: #1c2434;
  color: #fff;
  border-radius: 0 3px 3px 0;
  font-size: 22px;
  z-index: 2;
}

.u-sidebar-navigation__toggler:hover, .u-sidebar-navigation__toggler:focus {
  color: #fff;
}

.u-sidebar-navigation__closer {
  position: absolute;
  top: 22px;
  right: 15px;
  width: 25px;
  height: 25px;
  padding: 0;
  color: #fff;
  background-color: transparent;
  z-index: 3;
}

.u-sidebar-navigation__closer:hover, .u-sidebar-navigation__closer:focus {
  color: #fff;
}

@media all and (max-width: 992px - 1) {
  .u-sidebar-navigation-list {
    display: block;
    columns: 4;
  }
  .u-sidebar-navigation-list .dropdown-toggle {
    display: none;
  }
  .u-sidebar-navigation-list .dropdown-menu {
    display: block;
    max-height: 100%;
    margin: 0;
    float: none;
    opacity: 1;
  }
}

@media all and (max-width: 768px - 1) {
  .u-sidebar-navigation-list {
    columns: 3;
  }
}

@media all and (max-width: 576px - 1) {
  .u-sidebar-navigation-list {
    columns: 1;
  }
}

.u-has-sidebar-navigation .u-header__overlay {
  display: none;
}

/* Override vendor stylesheets */
.hamburger-inner::before,
.hamburger-inner::after {
  background-color: inherit;
}

[class*="u-main-nav-"] .dropdown-toggle::after {
  width: auto;
  height: auto;
  vertical-align: inherit;
  border: none;
}

/* Overlay */
.u-main-nav__overlay {
  position: absolute;
  top: 0;
  height: 100%;
  width: 2000%;
  opacity: 0;
  visibility: hidden;
}

/* Override necessary navigation styles in side-header pages */
body.u-body--header-side-static-left .navbar [class*="u-main-nav-"] .dropdown-toggle::after,
body.u-body--header-side-static-left .navbar [class*="u-main-nav-"] > .hs-has-sub-menu > a::after,
body.u-body--header-side-static-left .navbar [class*="u-main-nav-"] > .hs-has-mega-menu > a::after {
  content: "\e900";
}

body.u-body--header-side-static-right .navbar [class*="u-main-nav-"] .dropdown-toggle::after,
body.u-body--header-side-static-right .navbar [class*="u-main-nav-"] > .hs-has-sub-menu > a::after,
body.u-body--header-side-static-right .navbar [class*="u-main-nav-"] > .hs-has-mega-menu > a::after {
  content: "\e901";
}

/* Scroll on mobile devices */
@media (max-width: 992px - 1) {
  .u-nav-mobile-scroll {
    overflow-y: auto;
  }
}

/*------------------------------------
  Basket Bar
------------------------------------*/
/* Container */
.u-basket {
  position: relative;
  z-index: 1;
}

/* Bar */
.u-basket__bar {
  position: absolute;
  right: 0;
  top: 100%;
  text-align: left;
  width: 21.42857rem;
  background-color: #fff;
  box-shadow: 0 5px 5px 0px rgba(90, 90, 90, 0.075);
}

/* Product */
.u-basket__product {
  position: relative;
  z-index: 1;
  overflow: hidden;
  padding: 0.71429rem;
  border-bottom: solid 1px #eee;
}

/* Product Image */
.u-basket__product-img {
  width: 7.14286rem;
}

.u-basket__product-img img {
  width: 100%;
}

/* Product Remove Button */
.u-basket__product-remove {
  position: absolute;
  top: 1.42857rem;
  right: 1.42857rem;
  padding: 0;
  margin: 0;
  font-size: 1.5rem;
  font-family: inherit;
  font-weight: 700;
  color: #000;
  border: none;
  background-color: transparent;
  cursor: pointer;
  opacity: .2;
}

.u-basket__product-remove:hover, .u-basket__product-remove:focus {
  opacity: 1;
}

/*------------------------------------
	Dropdowns
------------------------------------*/
[class*="u-dropdown--"] [class*="u-dropdown--"] {
  left: 100%;
  top: 0;
}

[class*="u-dropdown--"] [class*="u-dropdown--"].u-dropdown--reverse-x {
  left: auto;
  right: 100%;
}

.u-dropdown--simple.u-dropdown--reverse-y,
.u-dropdown--jquery-slide.u-dropdown--reverse-y,
.u-dropdown--css-animation.u-dropdown--reverse-y {
  top: auto;
  bottom: 100%;
  margin-top: 0 !important;
  margin-bottom: 1.42857rem;
}

/* Simple Dropdown */
.u-dropdown--simple.u-dropdown--hidden {
  display: none;
}

/* CSS Animation Dropdown */
.u-dropdown--css-animation {
  animation-duration: 1s;
}

.u-dropdown--css-animation.u-dropdown--hidden {
  opacity: 0;
  visibility: hidden;
}

/* jQuery Slide */
.u-dropdown--jquery-slide.u-dropdown--hidden {
  opacity: 0;
  visibility: hidden;
}

.u-dropdown--hidden:not(.hs-sub-menu-opened) * {
  transition: none !important;
}

.u-dropdown--hidden:not(.hs-sub-menu-opened) *::before, .u-dropdown--hidden:not(.hs-sub-menu-opened) *::after {
  transition: none !important;
}

/*------------------------------------
  Compressed Form
------------------------------------*/
.u-compressed-form {
  display: inline-block;
  overflow: hidden;
  width: 20rem;
  transition: all .35s ease;
}

.u-compressed-form--hidden {
  width: 5.71429rem;
}

.u-compressed-form .input-group {
  display: block;
  float: right;
}

/*------------------------------------
  Dropcaps
------------------------------------*/
.u-dropcap, .u-dropcap-underline, .u-dropcap-bg, .u-dropcap-bordered {
  float: left;
  font-size: 3.57rem;
  line-height: 1;
}

.u-dropcap-underline {
  padding-bottom: 0.35714rem;
  border-bottom: 2px solid;
}

.u-dropcap-bg {
  width: 5rem;
  height: 5rem;
  margin-top: 0.35714rem;
  text-align: center;
  line-height: 4.78rem;
}

.u-dropcap-bordered {
  width: 5rem;
  height: 5rem;
  margin-top: 0.35714rem;
  text-align: center;
  line-height: 4.35rem;
  border: 3px solid;
}

/*------------------------------------
  BG Angles
------------------------------------*/
.u-angle-v1--top-left--bg-light,
.u-angle-v1--top-right--bg-light,
.u-angle-v1--bottom-left--bg-light,
.u-angle-v1--bottom-right--bg-light,
.u-angle-v1--top-left--bg-dark,
.u-angle-v1--top-right--bg-dark,
.u-angle-v1--bottom-left--bg-dark,
.u-angle-v1--bottom-right--bg-dark,
.u-angle-v2--top-left--bg-light,
.u-angle-v2--top-right--bg-light,
.u-angle-v2--bottom-left--bg-light,
.u-angle-v2--bottom-right--bg-light,
.u-angle-v2--top-left--bg-dark,
.u-angle-v2--top-right--bg-dark,
.u-angle-v2--bottom-left--bg-dark,
.u-angle-v2--bottom-right--bg-dark {
  position: relative;
  overflow: hidden;
}

/* V-1
------------------------------------*/
/* Light */
.u-angle-v1--top-left--bg-light::after {
  content: "";
  position: absolute;
  top: -10px;
  left: -100px;
  -ms-transform: rotate(-55deg);
      transform: rotate(-55deg);
  width: 250px;
  height: 120px;
  background-color: rgba(255, 255, 255, 0.1);
}

.u-angle-v1--top-right--bg-light::after {
  content: "";
  position: absolute;
  top: -10px;
  right: -100px;
  -ms-transform: rotate(55deg);
      transform: rotate(55deg);
  width: 250px;
  height: 120px;
  background-color: rgba(255, 255, 255, 0.1);
}

.u-angle-v1--bottom-left--bg-light::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: -100px;
  -ms-transform: rotate(55deg);
      transform: rotate(55deg);
  width: 250px;
  height: 120px;
  background-color: rgba(255, 255, 255, 0.1);
}

.u-angle-v1--bottom-right--bg-light::after {
  content: "";
  position: absolute;
  bottom: -10px;
  right: -100px;
  -ms-transform: rotate(-55deg);
      transform: rotate(-55deg);
  width: 250px;
  height: 120px;
  background-color: rgba(255, 255, 255, 0.1);
}

/* Dark */
.u-angle-v1--top-left--bg-dark::after {
  content: "";
  position: absolute;
  top: -10px;
  left: -100px;
  -ms-transform: rotate(-55deg);
      transform: rotate(-55deg);
  width: 250px;
  height: 120px;
  background-color: rgba(0, 0, 0, 0.1);
}

.u-angle-v1--top-right--bg-dark::after {
  content: "";
  position: absolute;
  top: -10px;
  right: -100px;
  -ms-transform: rotate(55deg);
      transform: rotate(55deg);
  width: 250px;
  height: 120px;
  background-color: rgba(0, 0, 0, 0.1);
}

.u-angle-v1--bottom-left--bg-dark::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: -100px;
  -ms-transform: rotate(55deg);
      transform: rotate(55deg);
  width: 250px;
  height: 120px;
  background-color: rgba(0, 0, 0, 0.1);
}

.u-angle-v1--bottom-right--bg-dark::after {
  content: "";
  position: absolute;
  bottom: -10px;
  right: -100px;
  -ms-transform: rotate(-55deg);
      transform: rotate(-55deg);
  width: 250px;
  height: 120px;
  background-color: rgba(0, 0, 0, 0.1);
}

/* V-2
------------------------------------*/
/* Light */
.u-angle-v2--top-left--bg-light::after {
  content: "";
  position: absolute;
  top: -10px;
  left: -100px;
  -ms-transform: rotate(-55deg);
      transform: rotate(-55deg);
  width: 250px;
  height: 120px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
}

.u-angle-v2--top-right--bg-light::after {
  content: "";
  position: absolute;
  top: -10px;
  right: -100px;
  -ms-transform: rotate(55deg);
      transform: rotate(55deg);
  width: 250px;
  height: 120px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
}

.u-angle-v2--bottom-left--bg-light::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: -100px;
  -ms-transform: rotate(55deg);
      transform: rotate(55deg);
  width: 250px;
  height: 120px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
}

.u-angle-v2--bottom-right--bg-light::after {
  content: "";
  position: absolute;
  bottom: -10px;
  right: -100px;
  -ms-transform: rotate(-55deg);
      transform: rotate(-55deg);
  width: 250px;
  height: 120px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
}

/* Dark */
.u-angle-v2--top-left--bg-dark::after {
  content: "";
  position: absolute;
  top: -10px;
  left: -100px;
  -ms-transform: rotate(-55deg);
      transform: rotate(-55deg);
  width: 250px;
  height: 120px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 50%;
}

.u-angle-v2--top-right--bg-dark::after {
  content: "";
  position: absolute;
  top: -10px;
  right: -100px;
  -ms-transform: rotate(55deg);
      transform: rotate(55deg);
  width: 250px;
  height: 120px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 50%;
}

.u-angle-v2--bottom-left--bg-dark::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: -100px;
  -ms-transform: rotate(55deg);
      transform: rotate(55deg);
  width: 250px;
  height: 120px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 50%;
}

.u-angle-v2--bottom-right--bg-dark::after {
  content: "";
  position: absolute;
  bottom: -10px;
  right: -100px;
  -ms-transform: rotate(-55deg);
      transform: rotate(-55deg);
  width: 250px;
  height: 120px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 50%;
}

/*------------------------------------
  Block Hovers
------------------------------------*/
[class*="u-block-hover"] {
  position: relative;
  max-width: 100%;
  margin: 0;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform: translateZ(0);
}

[class*="u-block-hover"], [class*="u-block-hover"]::before, [class*="u-block-hover"]::after {
  transition: all .3s ease;
}

.u-block-hover--uncroped {
  overflow: visible;
}

.u-block-hover--uncroped:hover {
  z-index: 2;
}

.u-block-hover__img {
  max-width: 100%;
  vertical-align: top;
}

.u-block-hover__block {
  min-height: 100%;
}

[class*="u-block-hover__additional"] {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

[class*="u-block-hover__additional--v1"] {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 15px;
  opacity: 0;
}

.u-block-hover:hover .u-block-hover__additional--v1 {
  opacity: 1;
}

.u-block-hover--shadow:hover,
.u-block-hover--shadow:hover .u-block-hover__additional-shadow {
  box-shadow: 0 0 10px #777;
}

.u-block-hover--shadow-v2:hover,
.u-block-hover--shadow-v2:hover .u-block-hover__additional-shadow {
  box-shadow: 0 0 15px #ddd;
}

.u-block-hover--shadow-v3:hover,
.u-block-hover--shadow-v3:hover .u-block-hover__additional-shadow {
  box-shadow: 0 0 3px #ddd;
}

.u-block-hover--scale:hover,
.u-block-hover--scale-img:hover .u-block-hover__img {
  transform: scale3d(1.1, 1.1, 1.1);
}

.u-block-hover__additional-scale {
  z-index: -1;
  max-width: initial;
}

.u-block-hover:hover .u-block-hover__additional-scale {
  top: -20px;
  right: -20px;
  bottom: -20px;
  left: -20px;
}

.u-block-hover--scale-down:hover,
.u-block-hover--scale-down-img:hover .u-block-hover__img {
  transform: scale3d(0.9, 0.9, 0.9);
}

.u-block-hover__additional-scale-down {
  z-index: -1;
  max-width: initial;
}

.u-block-hover:hover .u-block-hover__additional-scale-down {
  top: 20px;
  right: 20px;
  bottom: 20px;
  left: 20px;
}

.u-block-hover__main--zoom-v1,
.u-block-hover__main--zoom-v2 {
  -webkit-transform: scale(1.01);
}

.u-block-hover:hover .u-block-hover__main--zoom-v1 {
  -ms-transform: scale(1.1);
      transform: scale(1.1);
}

.u-block-hover:hover .u-block-hover__main--zoom-v2 {
  -ms-transform: scale(1.04);
      transform: scale(1.04);
}

.u-block-hover [class*="icon-"] {
  padding: 1px;
}

.u-block-hover__main--grayscale {
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale");
  /* Firefox 10+, Firefox on Android */
  filter: gray;
  -webkit-filter: grayscale(100%);
}

.u-block-hover:hover .u-block-hover__main--grayscale {
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0'/></filter></svg>#grayscale");
  -webkit-filter: grayscale(0%);
}

.u-block-hover__main--white {
  filter: brightness(0) invert(1);
}

.u-block-hover__additional--blur {
  opacity: 0;
}

.u-block-hover:hover .u-block-hover__additional--blur {
  opacity: 1;
  transition-delay: .11s;
}

.u-block-hover:hover .u-block-hover__main--blur {
  filter: blur(30px);
  -ms-transform: scale(1.2);
      transform: scale(1.2);
  opacity: 0;
}

[class*="u-block-hover-image-overlay"]::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  transition: all .35s ease;
}

.u-block-hover:hover .u-block-hover-image-overlay::after {
  opacity: 1;
}

.u-block-hover__additional--fade {
  opacity: 0;
}

.u-block-hover:hover .u-block-hover__additional--fade {
  opacity: 1;
  -ms-transform: translate(0, 0);
      transform: translate(0, 0);
}

.u-block-hover__additional--fade-up {
  -ms-transform: translate(0, 15%);
      transform: translate(0, 15%);
}

.u-block-hover__additional--fade-down {
  -ms-transform: translate(0, -15%);
      transform: translate(0, -15%);
}

.u-block-hover__additional--fade-left {
  -ms-transform: translate(-15%, 0);
      transform: translate(-15%, 0);
}

.u-block-hover__additional--fade-right {
  -ms-transform: translate(15%, 0);
      transform: translate(15%, 0);
}

.u-block-hover:hover [class*="u-block-hover__additional--push"] {
  -ms-transform: translate(0, 0);
      transform: translate(0, 0);
}

.u-block-hover:hover .u-block-hover__main--push-up {
  -ms-transform: translateY(-100%);
      transform: translateY(-100%);
}

.u-block-hover__additional--push-up {
  -ms-transform: translateY(100%);
      transform: translateY(100%);
}

.u-block-hover:hover .u-block-hover__main--push-down {
  -ms-transform: translateY(100%);
      transform: translateY(100%);
}

.u-block-hover__additional--push-down {
  -ms-transform: translateY(-100%);
      transform: translateY(-100%);
}

.u-block-hover:hover .u-block-hover__main--push-left {
  -ms-transform: translateX(-100%);
      transform: translateX(-100%);
}

.u-block-hover__additional--push-left {
  -ms-transform: translateX(100%);
      transform: translateX(100%);
}

.u-block-hover:hover .u-block-hover__main--push-right {
  -ms-transform: translateX(100%);
      transform: translateX(100%);
}

.u-block-hover__additional--push-right {
  -ms-transform: translateX(-100%);
      transform: translateX(-100%);
}

.u-block-hover:hover [class*="u-block-hover__additional--slide"],
.u-block-hover:hover [class*="u-block-hover__additional--partially-slide"] {
  -ms-transform: translate(0, 0);
      transform: translate(0, 0);
}

.u-block-hover__additional--slide-up {
  -ms-transform: translateY(100%);
      transform: translateY(100%);
}

.u-block-hover__additional--slide-down {
  -ms-transform: translateY(-100%);
      transform: translateY(-100%);
}

.u-block-hover__additional--slide-left {
  -ms-transform: translateX(100%);
      transform: translateX(100%);
}

.u-block-hover__additional--slide-right {
  -ms-transform: translateX(-100%);
      transform: translateX(-100%);
}

.u-block-hover__additional--slide-bottom-right {
  -ms-transform: translate(-100%, -100%);
      transform: translate(-100%, -100%);
}

.u-block-hover__additional--slide-bottom-left {
  -ms-transform: translate(100%, -100%);
      transform: translate(100%, -100%);
}

.u-block-hover__additional--slide-top-right {
  -ms-transform: translate(-100%, 100%);
      transform: translate(-100%, 100%);
}

.u-block-hover__additional--slide-top-left {
  -ms-transform: translate(100%, 100%);
      transform: translate(100%, 100%);
}

.u-block-hover__additional--partially-slide-up {
  top: auto;
  transform: translate3d(0, 100%, 0);
  overflow: visible;
}

.u-block-hover__additional--partially-slide-up .u-block-hover__visible {
  position: absolute;
  bottom: 100%;
  margin-bottom: -1px;
  left: 0;
  right: 0;
  padding-left: inherit;
  padding-right: inherit;
  background: inherit;
}

.u-block-hover__additional--partially-slide-down {
  bottom: auto;
  transform: translate3d(0, -100%, 0);
  overflow: visible;
}

.u-block-hover__additional--partially-slide-down .u-block-hover__visible {
  position: absolute;
  top: 100%;
  margin-top: -1px;
  left: 0;
  right: 0;
  padding-left: inherit;
  padding-right: inherit;
  background: inherit;
}

.u-block-hover--cot {
  perspective: 50em;
}

[class*="u-block-hover__additional--cot"] {
  opacity: 0;
  z-index: 1;
}

.u-block-hover--cot:hover [class*="u-block-hover__main--cot"] {
  opacity: 0;
}

.u-block-hover--cot:hover [class*="u-block-hover__additional--cot"] {
  opacity: 1;
  transition-delay: 0.21s;
}

.u-block-hover__main--cot-up {
  -ms-transform-origin: 50% 0;
      transform-origin: 50% 0;
}

.u-block-hover__additional--cot-up {
  transform: rotateX(90deg);
  -ms-transform-origin: 50% 100%;
      transform-origin: 50% 100%;
}

.u-block-hover--cot:hover .u-block-hover__main--cot-up {
  transform: rotateX(-90deg);
}

.u-block-hover--cot:hover .u-block-hover__additional--cot-up {
  transform: rotateX(0deg);
}

.u-block-hover__main--cot-down {
  -ms-transform-origin: 50% 100%;
      transform-origin: 50% 100%;
}

.u-block-hover__additional--cot-down {
  transform: rotateX(-90deg);
  -ms-transform-origin: 50% -50%;
      transform-origin: 50% -50%;
}

.u-block-hover--cot:hover .u-block-hover__main--cot-down {
  transform: rotateX(90deg);
  opacity: 0;
}

.u-block-hover--cot:hover .u-block-hover__additional--cot-down {
  transform: rotateX(0deg);
}

.u-block-hover__main--cot-left {
  -ms-transform-origin: 0% 50%;
      transform-origin: 0% 50%;
}

.u-block-hover__additional--cot-left {
  transform: rotateY(-90deg);
  -ms-transform-origin: 100% 50%;
      transform-origin: 100% 50%;
}

.u-block-hover--cot:hover .u-block-hover__main--cot-left {
  transform: rotateY(90deg);
}

.u-block-hover--cot:hover .u-block-hover__additional--cot-left {
  transform: rotateY(0deg);
}

.u-block-hover__main--cot-right {
  -ms-transform-origin: 100% 50%;
      transform-origin: 100% 50%;
}

.u-block-hover__additional--cot-right {
  transform: rotateY(90deg);
  -ms-transform-origin: 0 50%;
      transform-origin: 0 50%;
}

.u-block-hover--cot:hover .u-block-hover__main--cot-right {
  transform: rotateY(-90deg);
}

.u-block-hover--cot:hover .u-block-hover__additional--cot-right {
  transform: rotateY(0deg);
}

.u-block-hover--flip {
  perspective: 50em;
}

[class*="u-block-hover__main--flip"] {
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

[class*="u-block-hover__additional--flip"] {
  opacity: 0;
}

.u-block-hover--flip:hover [class*="u-block-hover__main--flip"] {
  opacity: 0;
}

.u-block-hover--flip:hover [class*="u-block-hover__additional--flip"] {
  opacity: 1;
  transition-delay: 0.13999999999999999s;
}

.u-block-hover__additional--flip-horiz {
  transform: rotateX(90deg);
  -ms-transform-origin: 0 50%;
      transform-origin: 0 50%;
}

.u-block-hover--flip:hover .u-block-hover__main--flip-horiz {
  transform: rotateX(-180deg);
}

.u-block-hover--flip:hover .u-block-hover__additional--flip-horiz {
  transform: rotateX(0deg);
}

.u-block-hover__additional--flip-vert {
  transform: rotateY(90deg);
  -ms-transform-origin: 50% 0%;
      transform-origin: 50% 0%;
}

.u-block-hover--flip:hover .u-block-hover__main--flip-vert {
  transform: rotateY(-180deg);
}

.u-block-hover--flip:hover .u-block-hover__additional--flip-vert {
  transform: rotateY(0deg);
}

.u-block-hover__additional--flip-diag-1 {
  transform: rotate3d(1, 1, 0, 100deg);
}

.u-block-hover--flip:hover .u-block-hover__main--flip-diag-1 {
  transform: rotate3d(-1, -1, 0, 100deg);
}

.u-block-hover--flip:hover .u-block-hover__additional--flip-diag-1 {
  transform: rotate3d(0, 0, 0, 0deg);
}

.u-block-hover__additional--flip-diag-2 {
  transform: rotate3d(1, -1, 0, 100deg);
}

.u-block-hover--flip:hover .u-block-hover__main--flip-diag-2 {
  transform: rotate3d(-1, 1, 0, 100deg);
}

.u-block-hover--flip:hover .u-block-hover__additional--flip-diag-2 {
  transform: rotate3d(0, 0, 0, 0deg);
}

.u-block-hover--fold {
  perspective: 50em;
}

[class*="u-block-hover__main--fold"] {
  -ms-transform-origin: 50% 0;
      transform-origin: 50% 0;
}

[class*="u-block-hover__additional--fold"] {
  z-index: 1;
  opacity: 0;
}

.u-block-hover--fold:hover [class*="u-block-hover__main--fold"] {
  opacity: 0;
  transition-delay: 0;
}

.u-block-hover--fold:hover [class*="u-block-hover__additional--fold"] {
  transform: rotateX(0deg) translate3d(0, 0, 0) scale(1);
  opacity: 1;
  transition-delay: .21s;
}

.u-block-hover__main--fold-up {
  -ms-transform-origin: 50% 0;
      transform-origin: 50% 0;
}

.u-block-hover__additional--fold-up {
  transform: rotateX(-90deg) translate3d(0, -50%, 0) scale(0.6);
  -ms-transform-origin: 50% 100%;
      transform-origin: 50% 100%;
}

.u-block-hover--fold:hover .u-block-hover__main--fold-up {
  transform: rotateX(90deg) scale(0.6) translateY(50%);
}

.u-block-hover__main--fold-down {
  -ms-transform-origin: 50% 100%;
      transform-origin: 50% 100%;
}

.u-block-hover__additional--fold-down {
  transform: rotateX(90deg) translate3d(0, 50%, 0) scale(0.6);
  -ms-transform-origin: 50% 0;
      transform-origin: 50% 0;
}

.u-block-hover--fold:hover .u-block-hover__main--fold-down {
  transform: rotateX(-90deg) scale(0.6) translateY(-50%);
}

.u-block-hover__main--fold-left {
  -ms-transform-origin: 0 50%;
      transform-origin: 0 50%;
}

.u-block-hover__additional--fold-left {
  transform: rotateY(90deg) translate3d(-50%, 0, 0) scale(0.6);
  -ms-transform-origin: 100% 50%;
      transform-origin: 100% 50%;
}

.u-block-hover--fold:hover .u-block-hover__main--fold-left {
  transform: rotateY(-90deg) scale(0.6) translateX(50%);
}

.u-block-hover__main--fold-right {
  -ms-transform-origin: 100% 50%;
      transform-origin: 100% 50%;
}

.u-block-hover__additional--fold-right {
  transform: rotateY(-90deg) translate3d(50%, 0, 0) scale(0.6);
  -ms-transform-origin: 0 50%;
      transform-origin: 0 50%;
}

.u-block-hover--fold:hover .u-block-hover__main--fold-right {
  transform: rotateY(90deg) scale(0.6) translateX(-50%);
}

.u-block-hover__additional--zoom-in {
  opacity: 0;
  -ms-transform: scale(0.5);
      transform: scale(0.5);
}

.u-block-hover:hover .u-block-hover__additional--zoom-in {
  -ms-transform: scale(1);
      transform: scale(1);
  opacity: 1;
}

.u-block-hover__additional--zoom-out {
  -ms-transform: scale(0.5);
      transform: scale(0.5);
  -ms-transform-origin: 50% 50%;
      transform-origin: 50% 50%;
  opacity: 0;
}

.u-block-hover:hover .u-block-hover__main--zoom-out {
  -ms-transform: scale(0.5);
      transform: scale(0.5);
  opacity: 0;
}

.u-block-hover:hover .u-block-hover__additional--zoom-out {
  -ms-transform: scale(1);
      transform: scale(1);
  opacity: 1;
  transition-delay: .35s;
}

[class*="u-block-hover--shutter-out"]::after {
  content: "";
  position: absolute;
  transition-delay: .105s;
  z-index: 1;
}

.u-block-hover__additional--shutter-out {
  opacity: 0;
  transition-delay: 0s;
  z-index: 2;
}

[class*="u-block-hover--shutter-out"]:hover::after {
  transition-delay: 0s;
}

[class*="u-block-hover--shutter-out"]:hover .u-block-hover__additional--shutter-out {
  opacity: 1;
  transition-delay: .105s;
}

.u-block-hover--shutter-out-horiz::after {
  left: 50%;
  right: 50%;
  top: 0;
  bottom: 0;
}

.u-block-hover--shutter-out-horiz:hover::after {
  left: 0;
  right: 0;
}

.u-block-hover--shutter-out-vert::after {
  top: 50%;
  bottom: 50%;
  left: 0;
  right: 0;
}

.u-block-hover--shutter-out-vert:hover::after {
  top: 0;
  bottom: 0;
}

.u-block-hover--shutter-out-diag-1::after {
  top: 50%;
  bottom: 50%;
  left: -35%;
  right: -35%;
  -ms-transform: rotate(45deg);
      transform: rotate(45deg);
}

.u-block-hover--shutter-out-diag-1:hover::after {
  top: -35%;
  bottom: -35%;
}

.u-block-hover--shutter-out-diag-2::after {
  top: 50%;
  bottom: 50%;
  left: -35%;
  right: -35%;
  -ms-transform: rotate(-45deg);
      transform: rotate(-45deg);
}

.u-block-hover--shutter-out-diag-2:hover::after {
  top: -35%;
  bottom: -35%;
}

[class*="u-block-hover--shutter-in"]::after,
[class*="u-block-hover--shutter-in"]::before {
  content: "";
  position: absolute;
  z-index: 1;
}

[class*="u-block-hover--shutter-in"]::after {
  top: 0;
  left: 0;
}

[class*="u-block-hover--shutter-in"]::before {
  right: 0;
  bottom: 0;
}

.u-block-hover__additional--shutter-in {
  opacity: 0;
  z-index: 2;
}

[class*="u-block-hover--shutter-in"]:hover .u-block-hover__additional--shutter-in {
  opacity: 1;
  transition-delay: .21s;
}

.u-block-hover--shutter-in-horiz::after,
.u-block-hover--shutter-in-horiz::before {
  width: 0;
  height: 100%;
}

.u-block-hover--shutter-in-horiz:hover::after,
.u-block-hover--shutter-in-horiz:hover::before {
  width: 100%;
}

.u-block-hover--shutter-in-vert::after,
.u-block-hover--shutter-in-vert::before {
  height: 0;
  width: 100%;
}

.u-block-hover--shutter-in-vert:hover::after,
.u-block-hover--shutter-in-vert:hover::before {
  height: 100%;
}

.u-block-hover--shutter-in-diag-1::after,
.u-block-hover--shutter-in-diag-1::before {
  width: 200%;
  height: 200%;
  transition: all .6s ease;
}

.u-block-hover--shutter-in-diag-1::after {
  -ms-transform: skew(-45deg) translateX(-150%);
      transform: skew(-45deg) translateX(-150%);
}

.u-block-hover--shutter-in-diag-1::before {
  -ms-transform: skew(-45deg) translateX(150%);
      transform: skew(-45deg) translateX(150%);
}

.u-block-hover--shutter-in-diag-1:hover::after {
  -ms-transform: skew(-45deg) translateX(-50%);
      transform: skew(-45deg) translateX(-50%);
}

.u-block-hover--shutter-in-diag-1:hover::before {
  -ms-transform: skew(-45deg) translateX(50%);
      transform: skew(-45deg) translateX(50%);
}

.u-block-hover--shutter-in-diag-2::after,
.u-block-hover--shutter-in-diag-2::before {
  width: 200%;
  height: 200%;
  transition: all .6s ease;
}

.u-block-hover--shutter-in-diag-2::after {
  -ms-transform: skew(45deg) translateX(-100%);
      transform: skew(45deg) translateX(-100%);
}

.u-block-hover--shutter-in-diag-2::before {
  -ms-transform: skew(45deg) translateX(100%);
      transform: skew(45deg) translateX(100%);
}

.u-block-hover--shutter-in-diag-2:hover::after {
  -ms-transform: skew(45deg) translateX(0);
      transform: skew(45deg) translateX(0);
}

.u-block-hover--shutter-in-diag-2:hover::before {
  -ms-transform: skew(45deg) translateX(0);
      transform: skew(45deg) translateX(0);
}

[class*="u-block-hover--shutter-in-out"]::after,
[class*="u-block-hover--shutter-in-out"]::before {
  content: "";
  position: absolute;
  z-index: 1;
}

[class*="u-block-hover--shutter-in-out"]::after {
  top: 0;
  left: 0;
}

[class*="u-block-hover--shutter-in-out"]::before {
  right: 0;
  bottom: 0;
}

.u-block-hover__additional--shutter-in-out {
  opacity: 0;
  z-index: 2;
}

[class*="u-block-hover--shutter-in-out"]:hover .u-block-hover__additional--shutter-in-out {
  opacity: 1;
  transition-delay: .21s;
}

.u-block-hover--shutter-in-out-horiz::after,
.u-block-hover--shutter-in-out-horiz::before {
  width: 0;
  height: 100%;
}

.u-block-hover--shutter-in-out-horiz:hover::after,
.u-block-hover--shutter-in-out-horiz:hover::before {
  width: 100%;
  opacity: .75;
}

.u-block-hover--shutter-in-out-vert::after,
.u-block-hover--shutter-in-out-vert::before {
  height: 0;
  width: 100%;
}

.u-block-hover--shutter-in-out-vert:hover::after,
.u-block-hover--shutter-in-out-vert:hover::before {
  height: 100%;
  opacity: .75;
}

.u-block-hover--shutter-in-out-diag-1::after,
.u-block-hover--shutter-in-out-diag-1::before {
  width: 200%;
  height: 200%;
  transition: all .6s ease;
  opacity: .75;
}

.u-block-hover--shutter-in-out-diag-1::after {
  -ms-transform: skew(-45deg) translateX(-150%);
      transform: skew(-45deg) translateX(-150%);
}

.u-block-hover--shutter-in-out-diag-1::before {
  -ms-transform: skew(-45deg) translateX(150%);
      transform: skew(-45deg) translateX(150%);
}

.u-block-hover--shutter-in-out-diag-1:hover::after {
  -ms-transform: skew(-45deg) translateX(-50%);
      transform: skew(-45deg) translateX(-50%);
}

.u-block-hover--shutter-in-out-diag-1:hover::before {
  -ms-transform: skew(-45deg) translateX(50%);
      transform: skew(-45deg) translateX(50%);
}

.u-block-hover--shutter-in-out-diag-2::after,
.u-block-hover--shutter-in-out-diag-2::before {
  width: 200%;
  height: 200%;
  transition: all .6s ease;
  opacity: .75;
}

.u-block-hover--shutter-in-out-diag-2::after {
  -ms-transform: skew(45deg) translateX(-100%);
      transform: skew(45deg) translateX(-100%);
}

.u-block-hover--shutter-in-out-diag-2::before {
  -ms-transform: skew(45deg) translateX(100%);
      transform: skew(45deg) translateX(100%);
}

.u-block-hover--shutter-in-out-diag-2:hover::after {
  -ms-transform: skew(45deg) translateX(0);
      transform: skew(45deg) translateX(0);
}

.u-block-hover--shutter-in-out-diag-2:hover::before {
  -ms-transform: skew(45deg) translateX(0);
      transform: skew(45deg) translateX(0);
}

.u-block-hover--strip-shutter::before,
.u-block-hover--strip-shutter::after,
.u-block-hover__additional--strip-shutter::before,
.u-block-hover__additional--strip-shutter::after {
  content: "";
  position: absolute;
  top: 0;
  width: 25%;
  height: 100%;
  -ms-transform: scaleY(0);
      transform: scaleY(0);
  opacity: 0;
}

.u-block-hover--strip-shutter::before,
.u-block-hover--strip-shutter::after {
  z-index: 1;
}

.u-block-hover--strip-shutter::before {
  left: 0;
  transition-delay: 0s;
}

.u-block-hover--strip-shutter::after {
  left: 25%;
  transition-delay: .105s;
}

.u-block-hover__additional--strip-shutter {
  z-index: 3;
}

.u-block-hover__additional--strip-shutter::before,
.u-block-hover__additional--strip-shutter::after {
  z-index: -1;
}

.u-block-hover__additional--strip-shutter::before {
  left: 50%;
  transition-delay: .21s;
}

.u-block-hover__additional--strip-shutter::after {
  left: 75%;
  transition-delay: .35s;
}

.u-block-hover--strip-shutter:hover::before,
.u-block-hover--strip-shutter:hover::after,
.u-block-hover--strip-shutter:hover .u-block-hover__additional--strip-shutter::before,
.u-block-hover--strip-shutter:hover .u-block-hover__additional--strip-shutter::after {
  -ms-transform: scale(1);
      transform: scale(1);
  opacity: 1;
}

.u-block-hover__additional--strip-shutter__inner {
  opacity: 0;
}

.u-block-hover--strip-shutter:hover .u-block-hover__additional--strip-shutter__inner {
  opacity: 1;
  transition-delay: .35s;
}

.u-block-hover--tile::before,
.u-block-hover--tile::after,
.u-block-hover__additional--tile::before,
.u-block-hover__additional--tile::after {
  content: "";
  position: absolute;
  width: 50%;
  height: 50%;
  -ms-transform: scale(0);
      transform: scale(0);
  opacity: 0;
}

.u-block-hover--tile::before,
.u-block-hover--tile::after {
  z-index: 1;
}

.u-block-hover--tile::before {
  top: 0;
  left: 0;
  transition-delay: 0s;
}

.u-block-hover--tile::after {
  top: 0;
  left: 50%;
  transition-delay: .105s;
}

.u-block-hover__additional--tile {
  z-index: 3;
}

.u-block-hover__additional--tile::before,
.u-block-hover__additional--tile::after {
  z-index: -1;
}

.u-block-hover__additional--tile::before {
  top: 50%;
  left: 0;
  transition-delay: .21s;
}

.u-block-hover__additional--tile::after {
  top: 50%;
  left: 50%;
  transition-delay: .35s;
}

.u-block-hover--tile:hover::before,
.u-block-hover--tile:hover::after,
.u-block-hover--tile:hover .u-block-hover__additional--tile::before,
.u-block-hover--tile:hover .u-block-hover__additional--tile::after {
  -ms-transform: scale(1);
      transform: scale(1);
  opacity: 1;
}

.u-block-hover__additional--tile__inner {
  opacity: 0;
}

.u-block-hover--tile:hover .u-block-hover__additional--tile__inner {
  opacity: 1;
  transition-delay: .35s;
}

.u-block-hover--cube {
  overflow: visible;
  background-color: transparent;
  perspective: 50em;
  transform-style: preserve-3d;
}

[class*="u-block-hover__main--cube"] {
  transition-delay: .05s;
}

[class*="u-block-hover__additional--cube"] {
  opacity: 0;
  transition-delay: 0s;
}

.u-block-hover--cube:hover [class*="u-block-hover__main--cube"] {
  opacity: 0;
  transition-delay: 0s;
}

.u-block-hover--cube:hover [class*="u-block-hover__additional--cube"] {
  opacity: 1;
  transform: translateY(0%) rotateX(0deg);
  transition-delay: .05s;
}

.u-block-hover__additional--cube-up {
  transform: translateY(50%) rotateX(-90deg);
}

.u-block-hover--cube:hover .u-block-hover__main--cube-up {
  transform: translateY(-50%) rotateX(90deg);
}

.u-block-hover__additional--cube-down {
  transform: translateY(-50%) rotateX(90deg);
}

.u-block-hover--cube:hover .u-block-hover__main--cube-down {
  transform: translateY(50%) rotateX(-90deg);
}

.u-block-hover__additional--cube-left {
  transform: translateX(-50%) rotateY(-90deg);
}

.u-block-hover--cube:hover .u-block-hover__main--cube-left {
  transform: translateX(50%) rotateY(90deg);
}

.u-block-hover__additional--cube-right {
  transform: translateX(50%) rotateY(90deg);
}

.u-block-hover--cube:hover .u-block-hover__main--cube-right {
  transform: translateX(-50%) rotateY(-90deg);
}

.u-block-hover--border-reveal::before,
.u-block-hover--border-reveal::after,
.u-block-hover__additional--border-reveal::before,
.u-block-hover__additional--border-reveal::after {
  content: "";
  position: absolute;
  background-color: #fff;
  -ms-transform-origin: 0 0;
      transform-origin: 0 0;
}

.u-block-hover--border-reveal::before,
.u-block-hover--border-reveal::after {
  left: 5px;
  right: 5px;
  height: 4px;
  z-index: 1;
  -ms-transform: scaleX(0);
      transform: scaleX(0);
}

.u-block-hover--border-reveal::before {
  top: 5px;
  transition-delay: .28s;
}

.u-block-hover--border-reveal:hover::before {
  transition-delay: 0s;
}

.u-block-hover--border-reveal::after {
  bottom: 5px;
  transition-delay: 0s;
}

.u-block-hover--border-reveal:hover::after {
  transition-delay: .28s;
}

.u-block-hover__additional--border-reveal {
  background-color: transparent;
  z-index: 3;
}

.u-block-hover__additional--border-reveal__inner {
  opacity: 0;
}

.u-block-hover__additional--border-reveal::before,
.u-block-hover__additional--border-reveal::after {
  top: 5px;
  bottom: 5px;
  width: 4px;
  z-index: -1;
  -ms-transform: scaleY(0);
      transform: scaleY(0);
}

.u-block-hover__additional--border-reveal::before {
  left: 5px;
  transition-delay: .28s;
}

.u-block-hover--border-reveal:hover .u-block-hover__additional--border-reveal::before {
  transition-delay: 0s;
}

.u-block-hover__additional--border-reveal::after {
  right: 5px;
  transition-delay: 0s;
}

.u-block-hover--border-reveal:hover .u-block-hover__additional--border-reveal::after {
  transition-delay: .28s;
}

.u-block-hover--border-reveal:hover .u-block-hover__main--border-reveal {
  opacity: 0;
}

.u-block-hover--border-reveal:hover::before,
.u-block-hover--border-reveal:hover::after,
.u-block-hover--border-reveal:hover .u-block-hover__additional--border-reveal::before,
.u-block-hover--border-reveal:hover .u-block-hover__additional--border-reveal::after {
  -ms-transform: scale(1);
      transform: scale(1);
}

.u-block-hover--border-reveal:hover .u-block-hover__additional--border-reveal__inner {
  opacity: 1;
  transition-delay: .35s;
}

.u-block-hover:hover img[class*="u-block-hover__main--mover-"],
.u-block-hover:hover [class*="u-block-hover__additional--mover-"] {
  opacity: 1;
  transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
}

img[class*="u-block-hover__main--mover-"] {
  max-width: initial;
  width: calc(100% + 60px);
  transition-duration: 0.5s;
  -ms-transform-origin: 50% 50%;
      transform-origin: 50% 50%;
}

[class*="u-block-hover__additional--mover-"] {
  opacity: 0;
  transition-duration: 0.5s;
}

img.u-block-hover__main--mover-left {
  transform: translate3d(-60px, 0, 0);
}

.u-block-hover__additional--mover-left {
  transform: translate3d(10px, 0, 0);
}

img.u-block-hover__main--mover-right {
  transform: translate3d(-60px, 0, 0);
}

.u-block-hover__additional--mover-right {
  transform: translate3d(-10px, 0, 0);
}

img.u-block-hover__main--mover-up {
  transform: translate3d(0, 20px, 0) scale3d(1.1, 1.1, 1.1);
}

.u-block-hover__additional--mover-up {
  transform: translate3d(0, 10px, 0);
}

img.u-block-hover__main--mover-down {
  transform: translate3d(0, -20px, 0) scale3d(1.1, 1.1, 1.1);
}

.u-block-hover__additional--mover-down {
  transform: translate3d(0, -10px, 0);
}

.u-block-hover__additional--focuser-element {
  top: 30px;
  right: 30px;
  bottom: 30px;
  left: 30px;
  opacity: .3;
  box-shadow: 0 0 0 31px rgba(0, 0, 0, 0.5);
  transform: scale3d(1.4, 1.4, 1.4);
  transition-duration: .5s;
}

.u-block-hover__additional--focuser-target {
  position: static;
  opacity: 0;
  transform: scale3d(1.4, 1.4, 1.4);
  transition-duration: .5s;
}

.u-block-hover:hover .u-block-hover__additional--focuser-target,
.u-block-hover:hover .u-block-hover__additional--focuser-element {
  opacity: 1;
  transform: scale3d(1, 1, 1);
  transition-duration: .3s;
}

img[class*="u-block-hover__main--magnifier"] {
  max-width: initial;
  width: calc(100% + 10px);
  margin: -10px 0;
}

[class*="u-block-hover__additional--magnifier-element"] {
  width: 400px;
  height: 400px;
  border-radius: 50%;
  box-shadow: 0 0 0 4000px rgba(255, 255, 255, 0.3);
  z-index: 2;
  opacity: 0;
  transform: scale3d(0.7, 0.7, 0.7);
  -ms-transform-origin: 50% 50%;
      transform-origin: 50% 50%;
}

.u-block-hover:hover [class*="u-block-hover__additional--magnifier-element"] {
  opacity: 1;
  transform: scale3d(1, 1, 1);
}

[class*="u-block-hover__additional--magnifier-description"] {
  max-width: 115px;
  z-index: 3;
  opacity: 0;
}

.u-block-hover:hover [class*="u-block-hover__additional--magnifier-description"] {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.u-block-hover__additional--magnifier-element-bottom-right {
  top: auto;
  left: auto;
  bottom: -180px;
  right: -180px;
}

.u-block-hover__additional--magnifier-description-bottom-right {
  top: auto;
  right: 30px;
  bottom: 30px;
  left: auto;
  transform: translate3d(20px, 20px, 0);
}

.u-block-hover:hover img.u-block-hover__main--magnifier-bottom-right {
  transform: translate3d(-10px, -10px, 0);
}

.u-block-hover__additional--magnifier-element-top-right {
  top: -180px;
  right: -180px;
  bottom: auto;
  left: auto;
}

.u-block-hover__additional--magnifier-description-top-right {
  top: 30px;
  right: 30px;
  bottom: auto;
  left: auto;
  transform: translate3d(20px, -20px, 0);
}

.u-block-hover:hover img.u-block-hover__main--magnifier-top-right {
  transform: translate3d(-10px, 10px, 0);
}

img.u-block-hover__main--magnifier-bottom-left {
  transform: translate3d(-10px, 0, 0);
}

.u-block-hover__additional--magnifier-element-bottom-left {
  top: auto;
  left: -180px;
  bottom: -180px;
  right: auto;
}

.u-block-hover__additional--magnifier-description-bottom-left {
  top: auto;
  right: auto;
  bottom: 30px;
  left: 30px;
  transform: translate3d(-20px, 20px, 0);
}

.u-block-hover:hover img.u-block-hover__main--magnifier-bottom-left {
  transform: translate3d(0, -10px, 0);
}

img.u-block-hover__main--magnifier-top-left {
  transform: translate3d(-10px, 0, 0);
}

.u-block-hover__additional--magnifier-element-top-left {
  top: -180px;
  left: -180px;
  bottom: auto;
  right: auto;
}

.u-block-hover__additional--magnifier-description-top-left {
  top: 30px;
  right: auto;
  bottom: auto;
  left: 30px;
  transform: translate3d(-20px, -20px, 0);
}

.u-block-hover:hover img.u-block-hover__main--magnifier-top-left {
  transform: translate3d(0, 10px, 0);
}

[class*="u-block-hover__additional--pappercuter"] {
  -ms-transform-origin: 50% 50%;
      transform-origin: 50% 50%;
}

.u-block-hover__additional--pappercuter-inner {
  width: 100%;
  transform: rotate3d(0, 0, 1, 5deg);
}

.u-block-hover__additional--pappercuter-front,
.u-block-hover__additional--pappercuter-back {
  max-width: initial;
  width: 120%;
  top: -60px;
  bottom: -60px;
  left: -10%;
  z-index: 2;
  background-position: center;
  background-size: cover;
  transform: rotate3d(0, 0, 1, -5deg);
}

.u-block-hover__additional--pappercuter-front {
  clip: rect(0px, auto, 246px, 0px);
}

.u-block-hover__additional--pappercuter-back {
  top: -61px;
  clip: rect(246px, auto, auto, 0px);
}

.u-block-hover:hover .u-block-hover__additional--pappercuter-front {
  transform: scale3d(1.3, 1.3, 1.3) rotate3d(0, 0, 1, -10deg) translate3d(0, -45%, 0);
}

.u-block-hover:hover .u-block-hover__additional--pappercuter-back {
  transform: scale3d(1.3, 1.3, 1.3) rotate3d(0, 0, 1, -10deg) translate3d(0, 45%, 0);
}

[class*="u-block-hover__additional--outside"] {
  opacity: 0;
  visibility: hidden;
  transition-duration: .5s;
  transition-timing-function: cubic-bezier(0.7, -1.2, 0.8, 1.2);
}

.u-block-hover:hover [class*="u-block-hover__additional--outside"] {
  opacity: 1;
  visibility: visible;
  transform: translate3d(0, 0, 0);
  transition-timing-function: cubic-bezier(0.25, 1.8, 0.8, 1);
}

.u-block-hover__additional--outside-down {
  transform: translate3d(0, -100%, 0);
}

.u-block-hover__additional--outside-up {
  transform: translate3d(0, 100%, 0);
}

.u-block-hover__additional--outside-left {
  transform: translate3d(100%, 0, 0);
}

.u-block-hover__additional--outside-right {
  transform: translate3d(-100%, 0, 0);
}

[class*="u-block-hover__additional--lightspeed"] {
  visibility: hidden;
  opacity: 0;
  transition-duration: .7s;
  transition-timing-function: cubic-bezier(0.75, -1.2, 0.8, 2);
}

.u-block-hover:hover [class*="u-block-hover__additional--lightspeed"] {
  opacity: 1;
  visibility: visible;
  transform: translate3d(0, 0, 0) skew(0deg, 0deg);
  transition-timing-function: cubic-bezier(0.25, 2, 0.75, 1);
}

.u-block-hover__additional--lightspeed-left {
  -ms-transform-origin: 50% 0%;
      transform-origin: 50% 0%;
  transform: translate3d(150%, 0, 0) skew(-35deg, 0deg);
}

.u-block-hover__additional--lightspeed-right {
  -ms-transform-origin: 50% 100%;
      transform-origin: 50% 100%;
  transform: translate3d(-150%, 0, 0) skew(35deg, 0deg);
}

.u-block-hover__additional--lightspeed-down {
  -ms-transform-origin: 50% 50%;
      transform-origin: 50% 50%;
  transform: translate3d(0, -150%, 0) skew(0deg, -35deg);
}

.u-block-hover__additional--lightspeed-up {
  -ms-transform-origin: 100% 50%;
      transform-origin: 100% 50%;
  transform: translate3d(0, 150%, 0) skew(0deg, -35deg);
}

[class*="u-block-hover__additional--rotate"] {
  opacity: 0;
  visibility: hidden;
}

.u-block-hover:hover [class*="u-block-hover__additional--rotate"] {
  opacity: 1;
  visibility: visible;
  transform: rotate3d(0, 0, 0, 0deg) scale3d(1, 1, 1);
}

.u-block-hover__additional--rotate-in {
  opacity: 1;
  transition-property: transform, opacity, visibility;
  transition-duration: .4s;
  transform: rotate3d(0, 0, 1, 720deg) scale3d(0, 0, 0);
}

.u-block-hover__additional--rotate-down-left {
  -ms-transform-origin: 0 100%;
      transform-origin: 0 100%;
  transform: rotate3d(0, 0, 1, -45deg);
}

.u-block-hover__additional--rotate-down-right {
  -ms-transform-origin: 100% 100%;
      transform-origin: 100% 100%;
  transform: rotate3d(0, 0, 1, 45deg);
}

.u-block-hover__additional--rotate-up-left {
  -ms-transform-origin: 0 100%;
      transform-origin: 0 100%;
  transform: rotate3d(0, 0, 1, 45deg);
}

.u-block-hover__additional--rotate-up-right {
  -ms-transform-origin: 100% 100%;
      transform-origin: 100% 100%;
  transform: rotate3d(0, 0, 1, -45deg);
}

.u-block-hover__additional--jump {
  position: static;
}

.u-block-hover:hover .u-block-hover__additional--jump,
.u-block-hover.u-block-hover__additional--jump:hover {
  transform: translate3d(0, -10px, 0);
}

/*------------------------------------
  Dedicated Properties
------------------------------------*/
.u-block-hover:hover, .u-block-hover:focus {
  /* Opacity */
  /* Colors */
  /* Background-colors */
}

.u-block-hover:hover .u-block-hover__prop-opacity-1, .u-block-hover:focus .u-block-hover__prop-opacity-1 {
  opacity: 1;
}

.u-block-hover:hover .u-block-hover__prop-color-white, .u-block-hover:focus .u-block-hover__prop-color-white {
  color: #fff;
}

.u-block-hover:hover .u-block-hover__prop-bg-primary, .u-block-hover:focus .u-block-hover__prop-bg-primary {
  background-color: #226f1c;
}

.u-block-hover:hover .u-block-hover__prop-bg-primary-opacity-0_9, .u-block-hover:focus .u-block-hover__prop-bg-primary-opacity-0_9 {
  background-color: rgba(34, 111, 28, 0.9);
}

/*------------------------------------
  Blockquotes
------------------------------------*/
.u-blockquote-v1 {
  position: relative;
  background-color: #fff;
  box-shadow: 5px 6px 9px -6px rgba(0, 0, 0, 0.15);
}

.u-blockquote-v1::before {
  content: "\201C";
  position: absolute;
  width: 60px;
  color: #226f1c;
  font-size: 60px;
  margin: -25px 0 0 -40px;
}

.u-blockquote-v1::after {
  content: "";
  position: absolute;
  bottom: -30px;
  left: 80px;
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 30px 30px 0 0;
  border-color: #fff transparent transparent transparent;
  filter: drop-shadow(2px 2px 1px rgba(0, 0, 0, 0.1));
}

.u-blockquote-v2::before, .u-blockquote-v2::after {
  position: relative;
  top: 5px;
  font-size: 22px;
  line-height: 10px;
}

.u-blockquote-v2::before {
  content: "\“";
  padding-right: 5px;
}

.u-blockquote-v2::after {
  content: "\”";
  padding-left: 5px;
}

.u-blockquote-v3 {
  position: relative;
}

.u-blockquote-v3::before {
  content: "\201C";
  position: absolute;
  font-size: 60px;
  color: #226f1c;
  margin: -30px 0 0 -40px;
}

.u-blockquote-v4::before, .u-blockquote-v4::after {
  content: "\201C";
  position: absolute;
  font-size: 50px;
  margin-top: -12px;
}

.u-blockquote-v4::before {
  margin-left: -30px;
}

.u-blockquote-v4::after {
  margin-left: 13px;
}

.u-blockquote-v5::before {
  content: "\201C";
  position: absolute;
  width: 60px;
  color: rgba(255, 255, 255, 0.2);
  font-size: 70px;
  margin: -25px 0 0 -40px;
}

.u-blockquote-v6::before, .u-blockquote-v6::after {
  content: "\0022";
  font-family: inherit;
  color: inherit;
}

.u-blockquote-v7 {
  position: relative;
  padding-top: 55px;
}

.u-blockquote-v7::before {
  content: "\f10d";
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 35px;
  height: 35px;
  font-size: 12px;
  font-family: "FontAwesome";
  color: #fff;
  line-height: 35px;
  background: #226f1c;
  border-radius: 50%;
  margin-left: -18px;
}

.u-blockquote-v8 {
  position: relative;
  background-color: #fff;
  box-shadow: 5px 6px 9px -6px rgba(153, 153, 153, 0.08);
}

.u-blockquote-v8::after {
  content: "";
  position: absolute;
  left: 50px;
  bottom: -15px;
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 15px 15px 0 0;
  border-color: #fff transparent transparent transparent;
  filter: drop-shadow(2px 2px 1px rgba(0, 0, 0, 0.05));
}

/*------------------------------------
  Accordions
------------------------------------*/
.u-accordion__header {
  padding: 0.71429rem 1.07143rem;
}

.u-accordion__body {
  padding: 1.07143rem;
}

.u-accordion__control-icon i:nth-child(1) {
  display: none;
}

.collapsed .u-accordion__control-icon i:nth-child(1) {
  display: inline;
}

.u-accordion__control-icon i:nth-child(2) {
  display: inline;
}

.collapsed .u-accordion__control-icon i:nth-child(2) {
  display: none;
}

[aria-expanded="false"] .u-accordion__control-icon i:nth-child(1) {
  display: inline;
}

[aria-expanded="false"] .u-accordion__control-icon i:nth-child(2) {
  display: none;
}

[aria-expanded="true"] .u-accordion__control-icon i:nth-child(1) {
  display: none;
}

[aria-expanded="true"] .u-accordion__control-icon i:nth-child(2) {
  display: inline;
}

.u-accordion [class*="et-icon-"], .u-accordion-line-icon-pro {
  position: relative;
  top: 3px;
}

.u-accordion-color-primary .u-accordion__header [aria-expanded="true"] {
  color: #226f1c !important;
}

.u-accordion-color-white .u-accordion__header [aria-expanded="true"] {
  color: #fff !important;
}

.u-accordion-bg-primary .u-accordion__header [aria-expanded="true"] {
  background-color: #226f1c !important;
  border-color: #226f1c !important;
}

.u-accordion-bg-white .u-accordion__header [aria-expanded="true"] {
  background-color: #fff !important;
  border-color: #fff !important;
}

.u-accordion-brd-primary .u-accordion__header [aria-expanded="true"] {
  border-color: #226f1c !important;
}

.u-accordion-brd-white .u-accordion__header [aria-expanded="true"] {
  border-color: #fff !important;
}

/*------------------------------------
  Carousels
------------------------------------*/
.u-carousel-v1 .js-prev,
.u-carousel-v1 .js-next {
  opacity: 0;
  transition-property: opacity;
  transition-duration: .2s;
  transition-timing-function: ease-in;
}

.u-carousel-v1:hover .js-prev,
.u-carousel-v1:hover .js-next {
  opacity: 1;
}

.u-carousel-v2 .slick-slide {
  padding-top: 4px;
  padding-bottom: 4px;
  opacity: .5;
  -ms-transform: scale(1);
      transform: scale(1);
  transition-property: all;
  transition-duration: .4s;
  transition-timing-function: ease-in;
}

.u-carousel-v2 .slick-center {
  padding-top: 0;
  padding-bottom: 0;
  opacity: 1;
  -ms-transform: scale(1.1);
      transform: scale(1.1);
}

.u-carousel-v3 .slick-slide {
  opacity: .5;
  transition-property: opacity;
  transition-duration: .4s;
  transition-timing-function: ease-in;
}

.u-carousel-v3 .slick-center {
  opacity: 1;
}

.u-carousel-v4 .js-pagination {
  opacity: 0;
  transition-property: opacity;
  transition-duration: .2s;
  transition-timing-function: ease-in;
}

.u-carousel-v4:hover .js-pagination {
  opacity: 1;
}

.u-carousel-v5 .slick-list {
  height: 100%;
}

.u-carousel-v5 .slick-track {
  display: -ms-flexbox;
  display: flex;
  height: 100%;
}

.u-carousel-v5 .slick-track .slick-slide {
  display: -ms-flexbox;
  display: flex;
  height: auto;
}

.u-carousel-v6 .slick-list.draggable {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.u-carousel-v11 .slick-slide {
  opacity: .5;
}

.u-carousel-v11 .slick-slide.slick-current {
  opacity: 1;
}

/*------------------------------------
  Slick Carousel v12
------------------------------------*/
.u-carousel-v12 .slick-current .g-opacity-1--active {
  opacity: 1 !important;
}

.u-carousel-v12 .slick-current .u-block-hover__main--grayscale {
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0'/></filter></svg>#grayscale");
  -webkit-filter: grayscale(0%);
}

.owl-carousel .owl-wrapper,
.owl-carousel .owl-item,
.swiper-wrapper,
.swiper-slide {
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
}

.single-slide {
  opacity: 0 !important;
}

.single-slide.set-position {
  opacity: 1 !important;
}

.js-origin {
  perspective: 1200px;
  perspective-origin: 50% 50%;
}

/* backSlide */
.js-back-slide-out {
  animation: backSlideOut 1s both ease;
}

.js-back-slide-in {
  animation: backSlideIn 1s both ease;
}

.swiper-carousel {
  overflow: hidden;
}

@keyframes backSlideOut {
  25% {
    opacity: .5;
    transform: translateZ(-400px);
  }
  75% {
    opacity: .5;
    transform: translateZ(-400px) translateX(-200%);
  }
  100% {
    opacity: .5;
    transform: translateZ(-400px) translateX(-200%);
  }
}

@keyframes backSlideIn {
  0%, 25% {
    opacity: .5;
    transform: translateZ(-400px) translateX(200%);
  }
  75% {
    opacity: .5;
    transform: translateZ(-400px);
  }
  100% {
    opacity: 1;
    transform: translateZ(0) translateX(0);
  }
}

/*--------------------------------------------------
  Chart Pie v1
----------------------------------------------------*/
.u-chart-pie-v1 {
  position: relative;
  line-height: 1em;
}

.u-chart-pie-v1::after {
  content: "";
  display: block;
  position: absolute;
  top: 9px;
  right: 10px;
  bottom: 11px;
  left: 10px;
  border-radius: 50%;
  border-width: 1px;
  border-style: solid;
  border-color: inherit;
}

/*------------------------------------
  Charts
------------------------------------*/
/* Sparkline chart's tooltip */
.jqstooltip {
  width: auto !important;
  height: auto !important;
}

/*--------------------------------------------------
  Counter v1
----------------------------------------------------*/
.u-counter-v3 {
  position: relative;
}

.u-counter-v3::after {
  content: "";
  position: absolute;
  left: -9px;
  right: -9px;
  bottom: -9px;
  top: -9px;
  display: block;
  border-radius: inherit;
  border-width: 1px;
  border-style: solid;
  border-color: inherit;
}

/*--------------------------------------------------
  Counter v6
----------------------------------------------------*/
.u-counter-v6 {
  position: relative;
  width: 120px;
  height: 120px;
}

.u-counter-v6::after {
  content: "";
  position: absolute;
  top: -11px;
  right: -11px;
  bottom: -11px;
  left: -11px;
  display: block;
  border-radius: inherit;
  border-width: 2px;
  border-style: inherit;
  border-color: inherit;
  clip: rect(auto, 71px, 142px, auto);
}

/*------------------------------------
  Credit cards
------------------------------------*/
.u-card--v1 {
  position: relative;
}

.u-card--v1-front {
  position: relative;
  margin-bottom: 220px;
  z-index: 2;
}

.u-card--v1-back {
  position: absolute;
  bottom: -70%;
  right: 0;
  z-index: 1;
  width: 100%;
  height: 80%;
  padding-left: 0;
}

@media (min-width: 768px) {
  .u-card--v1-front {
    margin-bottom: 30px;
  }
  .u-card--v1-back {
    bottom: auto;
    top: 30px;
    right: -30%;
    height: 100%;
    padding-left: 70%;
  }
}

/*------------------------------------
  Dropdowns v1
------------------------------------*/
.u-dropdown-v1 {
  position: relative;
  line-height: normal;
  font-size: 16px;
  color: #777;
  background-color: #fff;
  border-width: 1px;
  border-style: solid;
  border-color: #ccc;
  padding: 11px 40px 11px 14px;
  transition-property: background-color, border-color, box-shadow;
  transition-duration: .2s;
  transition-timing-function: ease;
}

.u-dropdown-v1::after {
  display: none;
}

.u-dropdown-v1-menu {
  padding: 0;
  border-color: #ccc;
  border-radius: 3px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
}

.u-dropdown-v1-menu__item {
  line-height: normal;
  font-size: 16px;
  color: #777;
  padding: 9px 20px;
}

.u-dropdown-v1-menu__item:hover {
  color: #777;
}

.u-dropdown-v1__icon {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 40px;
  height: 100%;
}

.u-dropdown-v1__icon-open, .u-dropdown-v1__icon-close {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
}

.u-dropdown-v1__icon-open {
  opacity: 1;
}

.u-dropdown-v1__icon-close {
  opacity: 0;
}

.u-dropdown-v1[aria-expanded="true"] {
  background-color: rgba(204, 204, 204, 0.1);
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
}

.u-dropdown-v1[aria-expanded="true"] [class*="__icon-open"] {
  opacity: 0;
}

.u-dropdown-v1[aria-expanded="true"] [class*="__icon-close"] {
  opacity: 1;
}

.u-dropdown-v1:focus {
  outline: 0 none;
}

/*------------------------------------
  Covers
------------------------------------*/
.u-bg-overlay {
  position: relative;
}

.u-bg-overlay::before, .u-bg-overlay::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.u-bg-overlay--v1::after {
  position: absolute;
  top: 0.35714rem;
  right: 0.35714rem;
  bottom: 0.35714rem;
  left: 0.35714rem;
}

.u-bg-overlay__inner {
  z-index: 3;
  position: relative;
}

.u-bg-overlay--reverse::before {
  z-index: 2;
}

.u-bg-overlay--reverse::after {
  z-index: 1;
}

.u-outer-spaces-helper {
  position: fixed;
  bottom: 0;
  left: 0;
  display: none;
  width: 100%;
  overflow: hidden;
}

.u-outer-spaces-helper::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100vh;
  background-color: #f7f7f7;
  background-position: center;
  background-repeat: repeat;
}

.g-dark-theme .u-outer-spaces-helper::after {
  background-color: #000;
}

[class*="g-bgi-v"] .u-outer-spaces-helper::after {
  background-color: transparent;
}

.u-outer-space-v1 .u-outer-spaces-helper,
.u-outer-space-v2 .u-outer-spaces-helper {
  display: block;
}

.u-outer-space-v1 .u-outer-spaces-helper {
  height: 20px;
}

.u-outer-space-v2 .u-outer-spaces-helper {
  height: 40px;
}

/*------------------------------------
  Info Block v1-1
------------------------------------*/
.u-info-v1-1 {
  overflow: hidden;
  position: relative;
}

.u-info-v1-1::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 40px;
}

/*--------------------------------------------------
  Info Block v1-2
----------------------------------------------------*/
.u-info-v1-2__item {
  position: relative;
  z-index: 1;
  padding-bottom: 0.78571rem;
}

.u-info-v1-2__item::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  display: block;
  width: 40px;
  height: 1px;
  background: #bbb;
  transition: all 0.4s ease-in-out;
}

.u-info-v1-2:hover .u-info-v1-2__item::after {
  width: 100%;
  background: #226f1c;
}

/*------------------------------------
  Info Block v1-3
------------------------------------*/
.u-info-v1-3__item {
  position: relative;
  z-index: 1;
  padding-bottom: 0.92857rem;
}

.u-info-v1-3__item::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 2.14286rem;
  height: 1px;
  margin-left: -1.07143rem;
  background: #777;
}

/*------------------------------------
  Info Block v1-4
------------------------------------*/
.u-info-v1-4__item-hidden {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 61px;
  overflow: hidden;
  transform: translate3d(0, 61px, 0);
}

.u-info-v1-4:hover .u-info-v1-4__item-hidden {
  transform: translate3d(0, 0, 0);
}

.u-info-v1-4:hover .u-info-v1-4__item-regular {
  transform: translate3d(0, -61px, 0);
}

/*------------------------------------
  Info Block v1-5
------------------------------------*/
.u-info-v1-5__item {
  position: relative;
  padding-bottom: 19px;
}

.u-info-v1-5__item::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 1px;
  display: block;
  width: 28px;
  border-bottom: 1px solid #226f1c;
}

/*------------------------------------
  Info Block v2-1
------------------------------------*/
.u-info-v2-1__item {
  height: 65px;
  transition: .3s ease-out;
}

.u-info-v2-1__item:hover {
  height: 80px;
  margin-top: -15px;
}

/*------------------------------------
  Info Block v2-2
------------------------------------*/
.u-info-v2-2__item {
  position: relative;
  border-left: solid 1px;
  border-right: solid 1px;
  border-bottom: solid 1px;
}

.u-info-v2-2__item::after, .u-info-v2-2__item::before {
  content: "";
  position: absolute;
  top: 0;
  width: 30%;
  border-top: solid 1px;
  z-index: 1;
}

.u-info-v2-2__item::before {
  left: 0;
}

.u-info-v2-2__item::after {
  right: 0;
}

.u-info-v2-2__item.g-brd-white-dark-v3 {
  border-color: rgba(255, 255, 255, 0.3);
}

.u-info-v2-2__item.g-brd-white-dark-v3::before, .u-info-v2-2__item.g-brd-white-dark-v3::after {
  border-color: rgba(255, 255, 255, 0.3);
}

.u-info-v2-2__item.g-brd-black-dark-v3 {
  border-color: rgba(0, 0, 0, 0.3);
}

.u-info-v2-2__item.g-brd-black-dark-v3::before, .u-info-v2-2__item.g-brd-black-dark-v3::after {
  border-color: rgba(0, 0, 0, 0.3);
}

/*------------------------------------
  Info Block v2-3
------------------------------------*/
.u-info-v2-3:hover .u-info-v2-3__title {
  color: #000;
}

/*------------------------------------
  Info Block v3-1
------------------------------------*/
/* Title */
.info-v3-1__title {
  position: relative;
  display: inline-block;
  margin-left: 4.28571rem;
  margin-right: 4.28571rem;
}

.info-v3-1__title::before, .info-v3-1__title::after {
  content: "";
  position: absolute;
  top: 50%;
  display: block;
  width: 3.57143rem;
  margin-top: -1px;
  height: 2px;
  background-color: #fff;
}

.info-v3-1__title::before {
  right: 100%;
  margin-right: 10px;
}

.info-v3-1__title::after {
  left: 100%;
  margin-left: 10px;
}

/*------------------------------------
  Info Block v3-2
------------------------------------*/
.info-v3-2 {
  position: relative;
  background-position: center;
}

.info-v3-2::before {
  content: "";
  position: absolute;
  top: 1.42857rem;
  right: 1.42857rem;
  bottom: 1.42857rem;
  left: 1.42857rem;
  display: block;
  border: 1px solid rgba(255, 255, 255, 0.2);
  z-index: 1;
}

.info-v3-2:hover {
  background-position: center 0;
}

/*------------------------------------
  Info Block v3-3
------------------------------------*/
.info-v3-3 {
  overflow: hidden;
}

.info-v3-3:hover .info-v3-3__title {
  color: #fff;
}

.info-v3-3:hover .info-v3-3__img,
.info-v3-3:hover .info-v3-3__description {
  transform: translate3d(0, 0, 0);
}

.info-v3-3:hover .info-v3-3__content {
  display: block;
}

.info-v3-3:hover .info-v3-3__category {
  display: none;
}

.info-v3-3:hover::after {
  opacity: 1;
}

.info-v3-3::after {
  opacity: 0;
  transition: opacity .5s;
}

/* Image */
.info-v3-3__img {
  transform: translate3d(70%, 0, 0);
  transition: transform .5s;
}

/* Content */
.info-v3-3__content {
  display: none;
}

/* Description */
.info-v3-3__description {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translate3d(-20%, 0, 0);
  transition: transform .5s;
  z-index: 1;
}

/*------------------------------------
  Info Block v3-4
------------------------------------*/
.info-v3-4 {
  position: relative;
}

.info-v3-4::before {
  content: "";
  position: absolute;
  top: 0.71429rem;
  right: 0.71429rem;
  bottom: 0.71429rem;
  left: 0.71429rem;
  display: block;
  border: 1px solid rgba(255, 255, 255, 0.4);
  z-index: 1;
}

/*------------------------------------
  Info Block v5-1
------------------------------------*/
/* Title */
.info-v5-1__title {
  position: relative;
  display: inline-block;
}

.info-v5-1__title::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 100%;
  display: block;
  width: 3000%;
  margin-left: 15px;
  border-bottom: 1px solid #eee;
}

/*------------------------------------
  Info Block v5-2
------------------------------------*/
/* Product Image */
.info-v5-2__image {
  width: calc(100% + 1px);
}

/*------------------------------------
  Info Block v5-3
------------------------------------*/
.info-v5-3__info-price {
  opacity: 0;
}

.info-v5-3__info-title {
  position: absolute;
  left: 0;
  bottom: 100%;
  width: 100%;
  margin-bottom: 4.64286rem;
  transform: translate3d(0, 70px, 0);
}

.info-v5-3:hover .info-v5-3__info {
  color: rgba(255, 255, 255, 0.8);
  background-color: rgba(34, 111, 28, 0.9);
}

.info-v5-3:hover .info-v5-3__info-price {
  opacity: 1;
}

.info-v5-3:hover .info-v5-3__info-list {
  color: #fff;
}

.info-v5-3:hover .info-v5-3__info-list .fa {
  color: #fff;
}

.info-v5-3:hover .info-v5-3__info-title {
  transform: translate3d(0, 0, 0);
  margin-bottom: 3.21429rem;
}

/*------------------------------------
  Info Block v5-4
------------------------------------*/
/* Actions */
.info-v5-4__action .fa {
  opacity: 0.75;
}

.info-v5-4__action:hover .fa {
  opacity: 1;
}

/*------------------------------------
  Info Block v5-5
------------------------------------*/
.info-v5-5__header::after,
.info-v5-5__content::after {
  transition: inherit;
}

.info-v5-5__content::after {
  opacity: 0;
}

.info-v5-5:hover .info-v5-5__header::after,
.info-v5-5:hover .info-v5-5__content::after {
  opacity: .8;
}

/*------------------------------------
  Info Block v5-6
------------------------------------*/
.info-v5-6:hover .info-v5-6__rating {
  color: #333;
}

.info-v5-6:hover .info-v5-6__price {
  background-color: #333;
}

.info-v5-6 .g-color-gray-light-v5--hover .g-rating,
.info-v5-6 .g-bg-white--hover,
.info-v5-6 .g-color-primary--hover {
  transition-property: all;
  transition-timing-function: ease;
  transition-delay: 0s;
  transition-duration: .3s;
}

.info-v5-6:hover .g-color-gray-light-v5--hover .g-rating {
  color: #f7f7f7;
}

.info-v5-6:hover .g-bg-white--hover {
  background: #fff;
}

.info-v5-6:hover .g-color-primary--hover {
  color: #226f1c;
}

/*--------------------------------------------------
  Info Block v6-1
----------------------------------------------------*/
.u-info-v6-1__item {
  position: relative;
  z-index: 1;
  padding-bottom: 0.78571rem;
}

.u-info-v6-1__item::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  display: block;
  width: 40px;
  height: 1px;
  background-image: linear-gradient(to right, #226f1c 0%, transparent 100%);
  background-repeat: repeat-x;
  transition: all 0.4s ease-in-out;
}

.u-info-v6-1:hover .u-info-v6-1__item::after {
  width: 100%;
  background-image: linear-gradient(to right, #226f1c 0%, transparent 100%);
  background-repeat: repeat-x;
}

/*--------------------------------------------------
  Info Block v7-1
----------------------------------------------------*/
.u-info-v7-1__item {
  border: 2px solid transparent;
  transition: all .2s ease-in-out;
}

.u-info-v7-1__item-child-v1 {
  border: 5px solid transparent;
  transition: all .2s ease-in-out;
}

.u-info-v7-1__item-child-v2 {
  transition: all .2s ease-in-out;
}

.u-info-v7-1:hover {
  border-color: #eee;
}

.u-info-v7-1:hover .u-info-v7-1__item {
  border-color: #ddd;
}

.u-info-v7-1:hover .u-info-v7-1__item-child-v1 {
  border-color: #ddd;
}

.u-info-v7-1:hover .u-info-v7-1__item-child-v2 {
  color: #fff;
  background: #226f1c;
}

/*--------------------------------------------------
  Info Block v8-1
----------------------------------------------------*/
.u-hs-filter {
  padding: 20px;
  margin-bottom: 0;
}

.u-hs-filter a {
  padding: 0.5rem 1.07143rem;
  color: rgba(255, 255, 255, 0.8);
  border: solid 1px rgba(255, 255, 255, 0.2);
  text-transform: uppercase;
  border-radius: 3px;
  transition: all .3s;
}

.u-hs-filter a:hover, .u-hs-filter a:focus {
  text-decoration: none;
}

.u-hs-filter a:hover {
  color: #585f69;
  background-color: white;
}

.u-hs-filter .active a {
  color: #585f69;
  background-color: white;
}

/*--------------------------------------------------
  Info Block v9-1
----------------------------------------------------*/
@media (min-width: 768px) {
  .u-info-v9-1 {
    position: relative;
  }
  .u-info-v9-1::before {
    position: absolute;
    top: 111px;
    left: 17%;
    width: 66%;
    border-top: 1px dotted #ddd;
    content: " ";
  }
}

/*--------------------------------------------------
  Info Block v10-1
----------------------------------------------------*/
.u-info-v10-1 {
  position: relative;
  display: inline-block;
}

.u-info-v10-1::before, .u-info-v10-1::after {
  display: block;
  position: absolute;
  top: 50%;
  width: 1000px;
  height: 1px;
  background: #f7f7f7;
  content: " ";
  margin-top: 0.5px;
}

.u-info-v10-1::before {
  right: 100%;
  margin-right: 25px;
}

.u-info-v10-1::after {
  left: 100%;
  margin-left: 25px;
}

/*------------------------------------
  Info v11-1
------------------------------------*/
.u-info-v11-1-img {
  border: 0 solid #eee;
  -ms-transform: scale(1);
      transform: scale(1);
  transition: all .3s ease;
}

.u-info-v11-1:hover .u-info-v11-1-img {
  border-width: 5px;
  -ms-transform: scale(0.95);
      transform: scale(0.95);
}

/*------------------------------------
  Custom Bootstrap
------------------------------------*/
.container-semiboxed {
  box-sizing: border-box;
  max-width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 576px) {
  .container-semiboxed {
    width: 540px;
  }
}

@media (min-width: 768px) {
  .container-semiboxed {
    width: 720px;
  }
}

@media (min-width: 992px) {
  .container-semiboxed {
    width: 960px;
  }
}

@media (min-width: 1200px) {
  .container-semiboxed {
    width: 1140px;
  }
}

@media (min-width: 1400px) {
  .container-semiboxed {
    width: 1340px;
  }
}

.text-muted {
  color: #999 !important;
}

.list-group-item {
  border-color: #eee;
}

.list-group-item-action:hover {
  background-color: #f7f7f7;
}

.list-group-item.active {
  background-color: #226f1c;
  border-color: #226f1c;
}

.list-group-border-0 .list-group-item:first-child,
.list-group-border-0 .list-group-item:last-child {
  border-radius: 0;
}

/* Paginations */
.page-link {
  color: #226f1c;
}

.page-link:focus, .page-link:hover {
  color: #226f1c;
}

.page-item.active .page-link,
.page-item.active .page-link:focus,
.page-item.active .page-link:hover {
  border-color: #226f1c;
  background-color: #226f1c;
}

.nav-pills .nav-item.show .nav-link,
.nav-pills .nav-link.active {
  background-color: #226f1c;
}

.nav-tabs {
  border-bottom: 1px solid #eee;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #999;
  border-color: #eee #eee #fff;
}

.progress {
  font-size: 1rem;
  line-height: inherit;
  height: inherit;
  background-color: #eee;
}

.progress-bar {
  height: auto;
  min-height: 12px;
  background-color: #226f1c;
}

.btn-primary {
  background-color: #226f1c;
  border-color: #226f1c;
}

.btn-primary:hover {
  color: #fff;
  background-color: #20671a;
  border-color: #20671a;
}

.btn-primary:focus, .btn-primary.focus {
  box-shadow: 0 0 0 2px rgba(34, 111, 28, 0.5);
}

.btn-primary.disabled, .btn-primary:disabled {
  background-color: #226f1c;
  border-color: #226f1c;
}

.btn-primary:active, .btn-primary.active,
.show > .btn-primary.dropdown-toggle {
  background-color: #20671a;
  border-color: #20671a;
}

.btn-outline-primary {
  color: #226f1c;
  border-color: #226f1c;
}

.btn-outline-primary:hover {
  background-color: #20671a;
  border-color: #20671a;
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 2px rgba(34, 111, 28, 0.5);
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #226f1c;
}

.btn-outline-primary:active, .btn-outline-primary.active,
.show > .btn-outline-primary.dropdown-toggle {
  background-color: #20671a;
  border-color: #20671a;
}

.card,
.card-header {
  border-color: #eee;
}

.card-header {
  padding: 0.71429rem 1.07143rem;
}

.card-block {
  padding: 1.07143rem;
}

.form-control {
  border-color: #ccc;
  height: auto;
}

.form-control-md {
  padding: .8rem 1rem .6rem;
}

.input-group-lg > .form-control, .input-group-lg > .input-group-prepend > .input-group-text, .input-group-lg > .input-group-append > .input-group-text, .input-group-lg > .input-group-prepend > .btn, .input-group-lg > .input-group-append > .btn {
  height: calc(3.75rem + 2px);
}

.form-control,
.form-control:focus {
  color: #999;
}

.form-control:focus,
.custom-select:focus {
  border-color: #226f1c;
  box-shadow: none;
}

.form-control:-ms-input-placeholder {
  color: inherit;
  opacity: .5;
}

.form-control::placeholder {
  color: inherit;
  opacity: .5;
}

.has-success .col-form-label,
.has-success .custom-control,
.has-success .form-check-label,
.has-success .form-control-feedback,
.has-success .form-control-label {
  color: #5cb85c;
}

.has-success .form-control {
  border-color: #5cb85c;
}

.has-warning .col-form-label,
.has-warning .custom-control,
.has-warning .form-check-label,
.has-warning .form-control-feedback,
.has-warning .form-control-label {
  color: #f0ad4e;
}

.has-warning .form-control {
  border-color: #f0ad4e;
}

.has-danger .col-form-label,
.has-danger .custom-control,
.has-danger .form-check-label,
.has-danger .form-control-feedback,
.has-danger .form-control-label {
  color: #d9534f;
}

.has-danger .form-control {
  border-color: #d9534f;
}

[class*="input-group-"] {
  min-width: 38px;
  background-color: transparent;
  border-color: #ccc;
  transition: border-color .15s ease-in-out 0s;
}

[class*="input-group-"] > * {
  white-space: normal;
}

[class*="input-group-"] i {
  margin: 0 auto;
}

.input-group .form-control:active,
.input-group .form-control:focus,
.input-group .form-control:hover {
  z-index: auto;
}

.btn-group {
  display: block;
}

.input-group-addon + .input-group-addon {
  border-left: solid 1px #ccc;
}

.table-striped tbody tr:nth-of-type(2n+1) {
  background-color: #f7f7f7;
}

.table-striped tbody td {
  border-top: none;
}

@media (min-width: 768px) {
  .btn-group {
    display: -ms-inline-flexbox;
    display: inline-flex;
  }
  .justified-content {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
  .justified-content > * {
    display: table-cell;
    width: 1%;
    float: none;
  }
}

.dropdown-menu {
  min-width: 15rem;
  padding: 0;
}

.dropdown-toggle::before {
  display: none;
}

.dropdown-toggle::after {
  content: "\e900";
  position: relative;
  top: 0.21429rem;
  font-family: "hs-icons" !important;
  font-size: 10px;
  display: inline;
  border: none;
  margin-left: 0.5rem;
}

.dropdown-item {
  padding: 0.21429rem 1.07143rem;
}

.dropdown-item:focus, .dropdown-item:hover {
  background: rgba(0, 0, 0, 0.03);
}

.dropdown-item.active, .dropdown-item:active {
  background: rgba(0, 0, 0, 0.05);
  color: inherit !important;
}

.dropdown-item.active > a, .dropdown-item:active > a {
  color: inherit !important;
}

.dropdown-item.active > a::before, .dropdown-item.active > a::after, .dropdown-item:active > a::before, .dropdown-item:active > a::after {
  background-color: #fff;
}

.u-header__section--dark .dropdown-item.active > a, .u-header__section--dark .dropdown-item:active > a {
  color: #fff !important;
}

/*--------------------------------------------------
  Fancybox
----------------------------------------------------*/
/* Blur bg container */
/*--------------------------------------------------
     Fancybox
     ----------------------------------------------------*/
.u-fancybox-theme .fancybox-content {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.u-fancybox-theme .fancybox-slide--iframe .fancybox-content {
  position: static;
}

.u-fancybox-theme.fancybox-show-thumbs .fancybox-content {
  right: 220px;
}

.u-fancybox-theme .fancybox-button,
.u-fancybox-theme .fancybox-arrow {
  font-size: 1.42857rem;
  line-height: 2.92857rem;
  width: 3.14286rem;
  height: 3.14286rem;
  text-align: center;
  background-color: transparent;
  border-width: 0.07143rem;
  border-style: solid;
  border-color: #fff;
  color: #fff;
  outline: none;
  border-radius: 50%;
  opacity: .8;
  transition: opacity .3s ease;
}

.u-fancybox-theme .fancybox-button::before,
.u-fancybox-theme .fancybox-arrow::before {
  font-family: "hs-icons";
  position: static;
  opacity: 1;
  background-color: transparent;
  border: none;
  box-shadow: none;
  -ms-transform: none;
  transform: none;
  width: auto;
  height: auto;
}

.u-fancybox-theme .fancybox-button::after,
.u-fancybox-theme .fancybox-arrow::after {
  display: none;
}

.u-fancybox-theme .fancybox-button:hover, .u-fancybox-theme .fancybox-button:focus,
.u-fancybox-theme .fancybox-arrow:hover,
.u-fancybox-theme .fancybox-arrow:focus {
  opacity: 1;
}

.u-fancybox-theme .fancybox-button {
  display: inline-block;
}

.u-fancybox-theme .fancybox-button--close::before {
  content: "\e904";
}

.u-fancybox-theme .fancybox-button--play::before {
  content: "\e90c";
}

.u-fancybox-theme .fancybox-button--fullscreen::before {
  content: "\e909";
}

.u-fancybox-theme .fancybox-button--thumbs::before {
  content: "\e906";
}

.u-fancybox-theme .fancybox-arrow--left, .u-fancybox-theme .fancybox-arrow--right {
  margin-top: -1.57143rem;
  position: absolute;
  top: 50%;
  z-index: 100000;
  display: none;
}

.u-fancybox-theme.fancybox-show-nav .fancybox-arrow--left, .u-fancybox-theme.fancybox-show-nav .fancybox-arrow--right {
  display: block;
}

.u-fancybox-theme .fancybox-arrow--left::before, .u-fancybox-theme .fancybox-arrow--right::before {
  content: "\e902";
}

.u-fancybox-theme .fancybox-arrow--left {
  left: 1.42857rem;
}

.u-fancybox-theme .fancybox-arrow--right {
  right: 1.42857rem;
}

.u-fancybox-theme .fancybox-toolbar {
  top: 1.42857rem;
  right: 1.42857rem;
  margin-left: -0.35714rem;
  margin-right: -0.35714rem;
}

.u-fancybox-theme .fancybox-toolbar .fancybox-button {
  margin-left: 0.35714rem;
  margin-right: 0.35714rem;
}

.u-fancybox-theme .fancybox-thumbs {
  background-color: #000;
}

.u-fancybox-theme .fancybox-thumbs > ul > li {
  border-color: #000;
}

.u-fancybox-theme .fancybox-thumbs > ul > li::before {
  border-color: #72c02c;
}

.fancybox-controls--canzoomIn .fancybox-placeholder,
.fancybox-controls--canzoomOut .fancybox-placeholder {
  cursor: inherit;
}

.fancybox-slide.has-animation {
  display: block;
}

.fancybox-is-sliding .fancybox-slide.has-animation,
.fancybox-slide--current.has-animation,
.fancybox-slide--next.has-animation,
.fancybox-slide--previous.has-animation {
  display: none;
}

.fancybox-is-sliding .fancybox-slide.has-animation.animated,
.fancybox-slide--current.has-animation.animated,
.fancybox-slide--next.has-animation.animated,
.fancybox-slide--previous.has-animation.animated {
  display: block;
}

/*--------------------------------------------------
  Hamburgers
----------------------------------------------------*/
.hamburger {
  padding: 10px;
}

.hamburger-box {
  width: 37px;
  height: 25px;
}

.hamburger-inner {
  margin-top: 1px;
}

.hamburger-inner, .hamburger-inner::after, .hamburger-inner::before {
  width: 100%;
  height: 1px;
  border-radius: 0;
}

.u-header__section--light .hamburger-inner,
.u-header__section--light .hamburger-inner::after,
.u-header__section--light .hamburger-inner::before {
  background: #999;
}

.u-header__section--dark .hamburger-inner,
.u-header__section--dark .hamburger-inner::after,
.u-header__section--dark .hamburger-inner::before {
  background: #fff;
}

/*------------------------------------
  jQuery UI
------------------------------------*/
.ui-autocomplete {
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 1px;
  z-index: 991;
}

.ui-autocomplete .left {
  float: left;
}

.ui-autocomplete .right {
  float: right;
}

.ui-autocomplete .ui-menu-item {
  padding: 0;
}

.ui-autocomplete .ui-menu-item-wrapper {
  display: block;
  color: inherit;
  background-color: transparent;
  border-color: transparent;
  padding: 7px 15px;
}

.ui-autocomplete .ui-menu-item-wrapper.ui-state-active, .ui-autocomplete .ui-menu-item-wrapper:hover {
  color: #fff;
  background-color: #226f1c;
  text-decoration: none;
}

.ui-autocomplete .ui-menu-item:hover {
  color: #fff;
  background-color: #226f1c;
}

.ui-autocomplete .ui-menu-item:hover .ui-menu-item-wrapper {
  background-color: transparent;
  border-color: transparent;
}

.ui-autocomplete-category {
  padding: 5px 15px;
  margin: 0;
  font-weight: bold;
}

.slick-transform-off .slick-track {
  -ms-transform: none !important;
      transform: none !important;
}

.slick-slide:focus {
  outline: 0 none;
}

[data-lazy] {
  opacity: 0;
  transition-property: opacity;
  transition-duration: .4s;
  transition-timing-function: ease-in;
}

.js-carousel {
  background: url(../../assets/img/preloaders/preloader-black.svg) no-repeat 50% 50%;
}

.js-carousel .js-next,
.js-carousel .js-prev {
  opacity: 0;
}

.js-carousel.slick-initialized {
  background: none;
}

.js-carousel.slick-initialized .js-slide,
.js-carousel.slick-initialized .js-thumb {
  visibility: visible;
  height: auto;
  overflow: visible;
}

.js-carousel.slick-initialized .js-next,
.js-carousel.slick-initialized .js-prev {
  opacity: 1;
}

.js-slide, .js-thumb {
  height: 0;
  visibility: hidden;
  overflow: hidden;
}

.js-slide:first-child, .js-thumb:first-child {
  height: auto;
}

.slick-vertical .slick-slide {
  border: none;
}

/*------------------------------------
  Border Colors
------------------------------------*/
/* Primary Colors */
.slick-active .g-brd-primary--before--active::before {
  border-color: #226f1c !important;
}

.slick-active .g-brd-primary--active {
  border-color: #226f1c !important;
}

.js-pagination .g-brd-primary--before--hover:hover::before {
  border-color: #226f1c !important;
}

/*------------------------------------
  Border Width
------------------------------------*/
.slick-active .g-brd-3--before--active::before {
  border-width: 3px !important;
}

/*------------------------------------
  Box shadows
------------------------------------*/
.g-parent.slick-current .u-shadow-v24--active {
  box-shadow: 0 15px 20px 0 rgba(0, 0, 0, 0.06);
}

/*------------------------------------
  Dot line v1
------------------------------------*/
.slick-active .u-dot-line-v1__inner::before, .slick-active
.u-dot-line-v1-2__inner::before {
  width: 100%;
  height: 100%;
}

/*------------------------------------
  Dot line v2
------------------------------------*/
.slick-active .u-dot-line-v2__inner::before, .slick-active
.u-dot-line-v2-2__inner::before {
  width: 100%;
  height: 100%;
}

/*------------------------------------
  JS
------------------------------------*/
.js-carousel_single-item {
  max-width: 100%;
  position: relative;
}

.js-next,
.js-prev,
.js-thumb {
  cursor: pointer;
}

.js-next,
.js-prev {
  z-index: 10;
}

.js-thumb img {
  display: inline-block;
}

.js-carousel_single-item-thumbs5__thumbs .js-thumb {
  opacity: .3;
  transition-property: opacity;
  transition-duration: .2s;
  transition-timing-function: ease-in;
}

.js-carousel_single-item-thumbs5__thumbs .slick-center {
  opacity: 1;
}

.js-carousel_single-item-thumbs5--v2__thumbs .js-thumb {
  opacity: .4;
  transition-property: opacity;
  transition-duration: .2s;
  transition-timing-function: ease-in;
}

.js-carousel_single-item-thumbs5--v2__thumbs .slick-center {
  opacity: 1;
}

.js-carousel_single-item-thumbs5--v3__thumbs .js-thumb {
  opacity: .4;
  transition-property: opacity;
  transition-duration: .2s;
  transition-timing-function: ease-in;
}

.js-carousel_single-item-thumbs5--v3__thumbs .slick-center {
  opacity: 1;
}

.u-pagi-control--v2 {
  display: block;
  width: 12px;
  height: 12px;
  margin: 0 3px;
  border-radius: 50%;
  background-color: #ddd;
  opacity: .3;
  cursor: pointer;
}

.slick-active .u-pagi-control-red {
  background-color: #f00;
}

.slick-active .u-pagi-control-grey {
  opacity: 1;
}

.u-carousel--v3 .js-prev,
.u-carousel--v3 .js-next {
  top: 50%;
  opacity: 0;
  transition-property: opacity;
  transition-duration: .2s;
  transition-timing-function: ease-in;
}

.u-carousel--v3 .g-height-40.js-prev, .u-carousel--v3 .g-height-40.js-next {
  margin-top: -20px;
  left: 50%;
}

.u-carousel--v3 .g-height-40.js-prev {
  margin-left: -99px;
}

.u-carousel--v3 .g-height-40.js-next {
  margin-left: 65px;
}

.u-carousel--v3:hover .js-prev,
.u-carousel--v3:hover .js-next {
  opacity: 1;
}

.u-carousel--v4 {
  margin-left: -15px;
  margin-right: -15px;
}

.u-carousel--v4 .js-prev,
.u-carousel--v4 .js-next {
  top: 50%;
  opacity: 0;
  transition-property: opacity;
  transition-duration: .4s;
  transition-timing-function: ease-in;
}

.u-carousel--v4 .g-height-40.js-prev, .u-carousel--v4 .g-height-40.js-next {
  margin-top: -20px;
  left: 50%;
}

.u-carousel--v4 .g-height-40.js-prev {
  margin-left: -160px;
}

.u-carousel--v4 .g-height-40.js-next {
  margin-left: 125px;
}

.u-carousel--v4:hover .js-prev,
.u-carousel--v4:hover .js-next {
  opacity: 1;
}

/*--------------------------------------------------
  Filter v1
----------------------------------------------------*/
.u-filter-v1 > li:not(:last-child)::after {
  content: "|";
  font-size: 0.71429rem;
  margin: 0 7px 0 14px;
  position: relative;
  top: -2px;
  line-height: inherit;
  display: inline-block;
  vertical-align: middle;
  color: #ddd;
}

.u-filter-v1 > li:hover,
.u-filter-v1 [class*="active"] {
  color: #226f1c;
}

/*--------------------------------------------------
  Carousel v10
----------------------------------------------------*/
.u-carousel-v10 .slick-dots {
  position: absolute;
  bottom: 30px;
  left: 0;
  width: auto;
  visibility: hidden;
  margin: 0;
  padding: 0;
}

.u-carousel-v10 .slick-dots li {
  display: block;
  margin-bottom: 5px;
}

.u-carousel-v10 .slick-dots button {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  color: transparent;
  background: #ccc;
  border: none;
  outline: none;
}

.u-carousel-v10 .slick-dots .slick-active button {
  background: #226f1c;
}

.u-carousel-v10-nav {
  margin-top: -135px;
}

.u-carousel-v10-nav .js-slide {
  position: relative;
  cursor: pointer;
  height: 100%;
}

.u-carousel-v10-nav .js-slide:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  transition: opacity 0.3s ease-in-out;
}

.u-carousel-v10-nav .js-slide.slick-current::after {
  opacity: 0;
}

@media only screen and (max-width: 991px) {
  .u-carousel-v10 .slick-dots {
    visibility: visible;
  }
  .u-carousel-v10-nav {
    display: none;
  }
}

/*------------------------------------
  Internet Explorer (IE)
------------------------------------*/
/* Text Gradients */
.IE .g-color-cyan-gradient-opacity-v1,
.IE .g-color-blue-gradient-opacity-v1 {
  background: transparent;
  -webkit-text-fill-color: initial;
}

.IE .g-color-cyan-gradient-opacity-v1 {
  color: #00bed6;
}

.IE .g-color-blue-gradient-opacity-v1 {
  color: #8654da;
}

.IE .form-control:-ms-input-placeholder {
  opacity: 1;
}

/*------------------------------------
  Demo of Icons
------------------------------------*/
.u-icons-demo__item {
  border: solid 1px #eee;
  margin: 0 -1px -1px 0;
  padding: 20px 15px;
}

.u-icons-demo-item__value, .u-icons-demo-item__value:focus {
  width: 100%;
  text-align: center;
  border: none;
  outline: none;
}

/* Add here all your css styles (customizations) */
@media all and (max-width: 575px) {
  .g-bg-white--sm-down {
    background-color: #FFFFFF !important;
  }
}

@media all and (max-width: 1199px) {
  .g-bg-white--xl-down {
    background-color: #FFFFFF !important;
  }
}

/*------------------------------------
  Globals
------------------------------------*/
/*------------------------------------
  Background Attachment
------------------------------------*/
.g-bg-attachment-fixed {
  background-attachment: fixed;
}

/*------------------------------------
  Background Colors
------------------------------------*/
/* Basic Colors
------------------------------------*/
/* Main Colors */
.g-bg-main {
  background-color: #fff !important;
}

/* Main Secondary */
.g-bg-secondary {
  background-color: #fafafa !important;
}

/* Primary Colors */
.g-bg-primary {
  background-color: #226f1c !important;
}

.g-bg-primary--hover:hover {
  background-color: #226f1c !important;
}

.u-block-hover:hover .g-bg-primary--hover {
  background-color: #226f1c;
}

.g-parent:hover .g-bg-primary--parent-hover {
  background-color: #226f1c !important;
}

.g-bg-primary--active.active,
.active .g-bg-primary--active {
  background-color: #226f1c !important;
}

.g-parent.active .g-bg-primary--parent-active {
  background-color: #226f1c !important;
}

.g-bg-primary--before::before, .g-bg-primary--after::after {
  background-color: #226f1c !important;
}

.g-bg-primary-dark-v1 {
  background-color: #20671a !important;
}

.g-bg-primary-dark-v2 {
  background-color: #1c5b17 !important;
}

.g-bg-primary-dark-v3 {
  background-color: #164612 !important;
}

.g-bg-primary-opacity-0_1 {
  background-color: rgba(34, 111, 28, 0.1) !important;
}

.g-bg-primary-opacity-0_2 {
  background-color: rgba(34, 111, 28, 0.2) !important;
}

.g-bg-primary-opacity-0_2--hover:hover {
  background-color: rgba(34, 111, 28, 0.2) !important;
}

.g-bg-primary-opacity-0_2--hover--after:hover::after {
  background-color: rgba(34, 111, 28, 0.2) !important;
}

.active .g-bg-primary-opacity-0_2--active {
  background-color: rgba(34, 111, 28, 0.2) !important;
}

.g-bg-primary-opacity-0_2--before::before, .g-bg-primary-opacity-0_2--after::after {
  background-color: rgba(34, 111, 28, 0.2) !important;
}

.g-bg-primary-opacity-0_3 {
  background-color: rgba(34, 111, 28, 0.3) !important;
}

.g-bg-primary-opacity-0_3--hover:hover {
  background-color: rgba(34, 111, 28, 0.3) !important;
}

.g-bg-primary-opacity-0_3--hover--after:hover::after {
  background-color: rgba(34, 111, 28, 0.3) !important;
}

.active .g-bg-primary-opacity-0_3--active {
  background-color: rgba(34, 111, 28, 0.3) !important;
}

.g-bg-primary-opacity-0_3--before::before, .g-bg-primary-opacity-0_3--after::after {
  background-color: rgba(34, 111, 28, 0.3) !important;
}

.g-bg-primary-opacity-0_4 {
  background-color: rgba(34, 111, 28, 0.4) !important;
}

.g-bg-primary-opacity-0_4--hover:hover {
  background-color: rgba(34, 111, 28, 0.4) !important;
}

.g-bg-primary-opacity-0_4--hover--after:hover::after {
  background-color: rgba(34, 111, 28, 0.4) !important;
}

.g-bg-primary-opacity-0_4--after::after {
  background-color: rgba(34, 111, 28, 0.4) !important;
}

.g-bg-primary-opacity-0_6 {
  background-color: rgba(34, 111, 28, 0.6) !important;
}

.g-bg-primary-opacity-0_6--hover:hover {
  background-color: rgba(34, 111, 28, 0.6) !important;
}

.g-bg-primary-opacity-0_6--hover--after:hover::after {
  background-color: rgba(34, 111, 28, 0.6) !important;
}

.g-bg-primary-opacity-0_6--after::after {
  background-color: rgba(34, 111, 28, 0.6) !important;
}

.g-bg-primary-opacity-0_8 {
  background-color: rgba(34, 111, 28, 0.8) !important;
}

.g-bg-primary-opacity-0_8--hover:hover {
  background-color: rgba(34, 111, 28, 0.8) !important;
}

.g-bg-primary-opacity-0_8--hover--after:hover::after {
  background-color: rgba(34, 111, 28, 0.8) !important;
}

.g-bg-primary-opacity-0_8--before::after, .g-bg-primary-opacity-0_8--after::after {
  background-color: rgba(34, 111, 28, 0.8) !important;
}

.g-bg-primary-opacity-0_8--before--hover:hover::after, .g-bg-primary-opacity-0_8--after--hover:hover::after {
  background-color: rgba(34, 111, 28, 0.8) !important;
}

.g-bg-primary-opacity-0_9 {
  background-color: rgba(34, 111, 28, 0.9) !important;
}

.g-bg-primary-opacity-0_9--hover:hover {
  background-color: rgba(34, 111, 28, 0.9) !important;
}

.g-bg-primary-opacity-0_9--hover--after:hover::after {
  background-color: rgba(34, 111, 28, 0.9) !important;
}

.g-bg-primary-opacity-0_9--before::after, .g-bg-primary-opacity-0_9--after::after {
  background-color: rgba(34, 111, 28, 0.9) !important;
}

.g-bg-primary-opacity-0_9--before--hover:hover::after, .g-bg-primary-opacity-0_9--after--hover:hover::after {
  background-color: rgba(34, 111, 28, 0.9) !important;
}

/* Black Colors */
.g-bg-black {
  background-color: #000 !important;
}

.g-bg-black--hover:hover {
  background-color: #000 !important;
}

.g-bg-black--after::after {
  background-color: #000 !important;
}

.g-bg-black-opacity-0_1 {
  background-color: rgba(0, 0, 0, 0.1) !important;
}

.g-bg-black-opacity-0_1--after::after {
  background-color: rgba(0, 0, 0, 0.1) !important;
}

.g-bg-black-opacity-0_2 {
  background-color: rgba(0, 0, 0, 0.2) !important;
}

.g-bg-black-opacity-0_2--after::after {
  background-color: rgba(0, 0, 0, 0.2) !important;
}

.g-bg-black-opacity-0_3 {
  background-color: rgba(0, 0, 0, 0.3) !important;
}

.g-bg-black-opacity-0_3--hover:hover {
  background-color: rgba(0, 0, 0, 0.3) !important;
}

.g-bg-black-opacity-0_3--after::after, .g-bg-black-opacity-0_3--before::before {
  background-color: rgba(0, 0, 0, 0.3) !important;
}

.g-bg-black-opacity-0_3--hover--after:hover::after {
  background-color: rgba(0, 0, 0, 0.3) !important;
}

.g-bg-black-opacity-0_4 {
  background-color: rgba(0, 0, 0, 0.4) !important;
}

.g-bg-black-opacity-0_4--after::after {
  background-color: rgba(0, 0, 0, 0.4) !important;
}

.g-bg-black-opacity-0_5 {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.g-bg-black-opacity-0_5--hover:hover {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.g-bg-black-opacity-0_5--after::after {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.g-bg-black-opacity-0_6 {
  background-color: rgba(0, 0, 0, 0.6) !important;
}

.g-bg-black-opacity-0_6--hover:hover {
  background-color: rgba(0, 0, 0, 0.7) !important;
}

.g-bg-black-opacity-0_6--after::after {
  background-color: rgba(0, 0, 0, 0.6) !important;
}

.g-bg-black-opacity-0_7 {
  background-color: rgba(0, 0, 0, 0.7) !important;
}

.g-bg-black-opacity-0_7--hover:hover {
  background-color: rgba(0, 0, 0, 0.7) !important;
}

.g-bg-black-opacity-0_7--after::after {
  background-color: rgba(0, 0, 0, 0.7) !important;
}

.g-bg-black-opacity-0_8 {
  background-color: rgba(0, 0, 0, 0.8) !important;
}

.g-bg-black-opacity-0_8--after::after {
  background-color: rgba(0, 0, 0, 0.8) !important;
}

.g-bg-black-opacity-0_8--hover:hover {
  background-color: rgba(0, 0, 0, 0.8) !important;
}

.g-bg-black-opacity-0_9 {
  background-color: rgba(0, 0, 0, 0.9) !important;
}

.g-bg-black-opacity-0_9--after::after {
  background-color: rgba(0, 0, 0, 0.9) !important;
}

.g-bg-black-opacity-0_9--hover:hover {
  background-color: rgba(0, 0, 0, 0.9) !important;
}

.u-block-hover:hover .g-bg-black--hover {
  background-color: #000 !important;
}

/* White Colors */
.g-bg-white {
  background-color: #fff !important;
}

.g-bg-white--before::before, .g-bg-white--after::after {
  background-color: #fff !important;
}

.g-bg-white--hover:hover, .g-bg-white--active.active {
  background-color: #fff !important;
}

.g-parent:hover .g-bg-white--parent-hover {
  background-color: #fff !important;
}

.g-bg-white--disabled:disabled {
  background-color: #fff !important;
}

.g-bg-white--before::before, .g-bg-white--after::after {
  background-color: #fff !important;
}

.g-parent:hover .g-bg-white-opacity-0--after--parent-hover::after {
  background-color: rgba(255, 255, 255, 0) !important;
}

.g-bg-white-opacity-0_05 {
  background-color: rgba(255, 255, 255, 0.05) !important;
}

.g-bg-white-opacity-0_1 {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

.g-bg-white-opacity-0_1--after::after {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

.g-bg-white-opacity-0_2 {
  background-color: rgba(255, 255, 255, 0.2) !important;
}

.g-bg-white-opacity-0_2--hover:hover {
  background-color: rgba(255, 255, 255, 0.2) !important;
}

.g-bg-white-opacity-0_3 {
  background-color: rgba(255, 255, 255, 0.3) !important;
}

.g-bg-white-opacity-0_3--hover:hover {
  background-color: rgba(255, 255, 255, 0.3) !important;
}

.g-bg-white-opacity-0_3--after::after {
  background-color: rgba(255, 255, 255, 0.3) !important;
}

.g-bg-white-opacity-0_4 {
  background-color: rgba(255, 255, 255, 0.4) !important;
}

.g-bg-white-opacity-0_4--hover:hover {
  background-color: rgba(255, 255, 255, 0.4) !important;
}

.g-bg-white-opacity-0_5 {
  background-color: rgba(255, 255, 255, 0.5) !important;
}

.g-bg-white-opacity-0_5--after::after {
  background-color: rgba(255, 255, 255, 0.5) !important;
}

.g-bg-white-opacity-0_7 {
  background-color: rgba(255, 255, 255, 0.7) !important;
}

.g-bg-white-opacity-0_7--hover:hover {
  background-color: rgba(255, 255, 255, 0.7) !important;
}

.g-bg-white-opacity-0_7--after::after {
  background-color: rgba(255, 255, 255, 0.7) !important;
}

.g-bg-white-opacity-0_8 {
  background-color: rgba(255, 255, 255, 0.8) !important;
}

.g-bg-white-opacity-0_8--hover:hover {
  background-color: rgba(255, 255, 255, 0.8) !important;
}

.g-bg-white-opacity-0_8--after::after {
  background-color: rgba(255, 255, 255, 0.8) !important;
}

.g-bg-white-opacity-0_8--hover--after:hover::after {
  background-color: rgba(34, 111, 28, 0.8) !important;
}

.g-bg-white-opacity-0_9 {
  background-color: rgba(255, 255, 255, 0.9) !important;
}

.g-bg-white-opacity-0_9--hover:hover {
  background-color: rgba(255, 255, 255, 0.9) !important;
}

.g-bg-white-opacity-0_9--after::after {
  background-color: rgba(255, 255, 255, 0.9) !important;
}

/* Gray Colors */
.g-bg-gray-dark-v1 {
  background-color: #111 !important;
}

.g-bg-gray-dark-v1--hover:hover {
  background-color: #111 !important;
}

.g-bg-gray-dark-v2 {
  background-color: #333 !important;
}

.g-bg-gray-dark-v2--hover:hover {
  background-color: #333 !important;
}

.g-bg-gray-dark-v3 {
  background-color: #555 !important;
}

.g-bg-gray-dark-v3--hover:hover {
  background-color: #555 !important;
}

.g-bg-gray-dark-v4 {
  background-color: #777 !important;
}

.g-bg-gray-dark-v4--hover:hover {
  background-color: #777 !important;
}

.g-bg-gray-dark-v5 {
  background-color: #999 !important;
}

.g-bg-gray-dark-v5--hover:hover {
  background-color: #999 !important;
}

.g-bg-gray-light-v1 {
  background-color: #bbb !important;
}

.g-bg-gray-light-v1--hover:hover {
  background-color: #bbb !important;
}

.g-bg-gray-light-v2 {
  background-color: #ccc !important;
}

.g-bg-gray-light-v2--hover:hover {
  background-color: #ccc !important;
}

.g-bg-gray-light-v3 {
  background-color: #ddd !important;
}

.g-bg-gray-light-v3--hover:hover {
  background-color: #ddd !important;
}

.g-bg-gray-light-v4 {
  background-color: #eee !important;
}

.g-bg-gray-light-v4--hover:hover {
  background-color: #eee !important;
}

.g-bg-gray-light-v5 {
  background-color: #f7f7f7 !important;
}

.g-bg-gray-light-v5--hover:hover {
  background-color: #f7f7f7 !important;
}

.g-bg-gray-light-v5--active.active,
.active .g-bg-gray-light-v5--active {
  background-color: #f7f7f7 !important;
}

/* Transparent */
.g-bg-transparent {
  background-color: transparent !important;
}

.g-bg-transparent--hover:hover {
  background-color: transparent !important;
}

.g-bg-transparent--hover--after:hover::after {
  background-color: transparent !important;
}

.g-parent:hover .g-bg-transparent--parent-hover {
  background-color: transparent !important;
}

/* Complementary Colors
------------------------------------*/
/* Beige Colors */
.g-bg-beige {
  background-color: #e5e1de !important;
}

/* Color Green */
.g-bg-green {
  background-color: #72c02c !important;
}

.g-bg-green-opacity-0_1 {
  background-color: rgba(114, 192, 44, 0.1) !important;
}

.g-bg-green--hover:hover {
  background-color: #72c02c !important;
}

/* Color Blue */
.g-bg-blue {
  background-color: #3398dc !important;
}

.g-bg-blue-opacity-0_1 {
  background-color: rgba(51, 152, 220, 0.1) !important;
}

.g-bg-blue-opacity-0_7 {
  background-color: rgba(51, 152, 220, 0.7) !important;
}

.g-bg-blue-opacity-0_9 {
  background-color: rgba(51, 152, 220, 0.9) !important;
}

.g-bg-blue--hover:hover {
  background-color: #3398dc !important;
}

/* Color Light Blue */
.g-bg-lightblue {
  background-color: #edf2f8 !important;
}

.g-bg-lightblue-opacity-0_1 {
  background-color: rgba(237, 242, 248, 0.1) !important;
}

.g-bg-lightblue-v1 {
  background-color: #d6e2ee !important;
}

.g-bg-lightblue-v1-opacity-0_1 {
  background-color: rgba(214, 226, 238, 0.1) !important;
}

/* Color Dark Blue */
.g-bg-darkblue {
  background-color: #009 !important;
}

.g-bg-darkblue-opacity-0_1 {
  background-color: rgba(0, 0, 153, 0.1) !important;
}

/* Color Indigo */
.g-bg-indigo {
  background-color: #4263a3 !important;
}

.g-bg-indigo-opacity-0_1 {
  background-color: rgba(66, 99, 163, 0.1) !important;
}

/* Color Red */
.g-bg-red {
  background-color: #f00 !important;
}

.g-bg-red-opacity-0_1 {
  background-color: rgba(255, 0, 0, 0.1) !important;
}

.g-bg-red-opacity-0_2 {
  background-color: rgba(255, 0, 0, 0.2) !important;
}

.g-bg-red-opacity-0_5 {
  background-color: rgba(255, 0, 0, 0.5) !important;
}

.g-bg-red-opacity-0_8 {
  background-color: rgba(255, 0, 0, 0.8) !important;
}

.g-bg-red--hover:hover {
  background-color: #f00 !important;
}

/* Color Light Red */
.g-bg-lightred {
  background-color: #e64b3b !important;
}

.g-bg-lightred-opacity-0_1 {
  background-color: rgba(230, 75, 59, 0.1) !important;
}

.g-bg-lightred--hover:hover {
  background-color: #e64b3b !important;
}

/* Color Dark Red */
.g-bg-darkred {
  background-color: #a10f2b !important;
}

.g-bg-darkred-opacity-0_1 {
  background-color: rgba(161, 15, 43, 0.1) !important;
}

/* Color Purple */
.g-bg-purple {
  background-color: #9a69cb !important;
}

.g-bg-purple-opacity-0_1 {
  background-color: rgba(154, 105, 203, 0.1) !important;
}

.g-bg-purple-opacity-0_7 {
  background-color: rgba(154, 105, 203, 0.7) !important;
}

.g-bg-purple-opacity-0_9 {
  background-color: rgba(154, 105, 203, 0.9) !important;
}

.g-bg-purple-opacity-0_9--after::after {
  background-color: rgba(154, 105, 203, 0.9) !important;
}

.g-bg-purple--hover:hover {
  background-color: #9a69cb !important;
}

/* Color Dark Purple */
.g-bg-darkpurple {
  background-color: #6639b6 !important;
}

.g-bg-darkpurple-opacity-0_1 {
  background-color: rgba(102, 57, 182, 0.1) !important;
}

/* Color Pink */
.g-bg-pink {
  background-color: #e81c62 !important;
}

.g-bg-pink-opacity-0_1 {
  background-color: rgba(232, 28, 98, 0.1) !important;
}

.g-bg-pink-opacity-0_9 {
  background-color: rgba(232, 28, 98, 0.9) !important;
}

.g-bg-pink--hover:hover {
  background-color: #e81c62 !important;
}

.g-bg-pink--before::after, .g-bg-pink--after::after {
  background-color: #e81c62 !important;
}

.g-bg-pink--before--hover:hover::after, .g-bg-pink--after--hover:hover::after {
  background-color: #e81c62 !important;
}

/* Color Orange */
.g-bg-orange {
  background-color: #e57d20 !important;
}

.g-bg-orange-opacity-0_1 {
  background-color: rgba(229, 125, 32, 0.1) !important;
}

.g-bg-orange-opacity-0_2 {
  background-color: rgba(229, 125, 32, 0.2) !important;
}

.g-bg-orange--hover:hover {
  background-color: #e57d20 !important;
}

/* Color Deep Orange */
.g-bg-deeporange {
  background-color: #fe541e !important;
}

.g-bg-deeporange-opacity-0_1 {
  background-color: rgba(254, 84, 30, 0.1) !important;
}

.g-bg-deeporange--hover:hover {
  background-color: #fe541e !important;
}

/* Color Yellow */
.g-bg-yellow {
  background-color: #ebc71d !important;
}

.g-bg-yellow-opacity-0_1 {
  background-color: rgba(235, 199, 29, 0.1) !important;
}

.g-bg-yellow-opacity-0_7 {
  background: rgba(235, 199, 29, 0.7) !important;
}

.g-bg-yellow--hover:hover {
  background-color: #ebc71d !important;
}

/* Color Aqua */
.g-bg-aqua {
  background-color: #29d6e6 !important;
}

.g-bg-aqua-opacity-0_1 {
  background-color: rgba(41, 214, 230, 0.1) !important;
}

.g-bg-aqua-opacity-0_9 {
  background-color: rgba(41, 214, 230, 0.9) !important;
}

.g-bg-aqua--hover:hover {
  background-color: #29d6e6 !important;
}

/* Color Cyan */
.g-bg-cyan {
  background-color: #00bed6 !important;
}

.g-bg-cyan-opacity-0_1 {
  background-color: rgba(0, 190, 214, 0.1) !important;
}

.g-bg-cyan-opacity-0_9 {
  background-color: rgba(0, 190, 214, 0.9) !important;
}

.g-bg-cyan-opacity-0_9--after::after {
  background-color: rgba(0, 190, 214, 0.9) !important;
}

.g-bg-cyan--hover:hover {
  background-color: #00bed6 !important;
}

/* Color Teal */
.g-bg-teal {
  background-color: #18ba9b !important;
}

.g-bg-teal-opacity-0_1 {
  background-color: rgba(24, 186, 155, 0.1) !important;
}

.g-bg-teal-opacity-0_9 {
  background-color: rgba(24, 186, 155, 0.9) !important;
}

.g-bg-teal-opacity-0_9--after::after {
  background-color: rgba(24, 186, 155, 0.9) !important;
}

.g-bg-teal--hover:hover {
  background-color: #18ba9b !important;
}

/* Color Brown */
.g-bg-brown {
  background-color: #9c8061 !important;
}

.g-bg-brown-opacity-0_1 {
  background-color: rgba(156, 128, 97, 0.1) !important;
}

.g-bg-brown--hover:hover {
  background-color: #9c8061 !important;
}

/* Color Blue Gray */
.g-bg-bluegray {
  background-color: #585f69 !important;
}

.g-bg-bluegray-opacity-0_1 {
  background-color: rgba(88, 95, 105, 0.1) !important;
}

.g-bg-bluegray-opacity-0_2--before::before, .g-bg-bluegray-opacity-0_2--after::after {
  background-color: rgba(88, 95, 105, 0.2) !important;
}

.g-bg-bluegray-opacity-0_3--before::before, .g-bg-bluegray-opacity-0_3--after::after {
  background-color: rgba(88, 95, 105, 0.3) !important;
}

.g-bg-bluegray-opacity-0_5, .g-bg-bluegray-opacity-0_5--after::after {
  background-color: rgba(88, 95, 105, 0.5) !important;
}

.g-bg-bluegray-opacity-0_7 {
  background-color: rgba(88, 95, 105, 0.7) !important;
}

.g-bg-bluegray--hover:hover {
  background-color: #585f69 !important;
}

.g-grayscale-100x {
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale");
  /* Firefox 10+, Firefox on Android */
  filter: gray;
  -webkit-filter: grayscale(100%);
}

.g-parent:hover .g-grayscale-0--parent-hover {
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0'/></filter></svg>#grayscale");
  -webkit-filter: grayscale(0%);
}

.g-grayscale-0--hover:hover {
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0'/></filter></svg>#grayscale");
  -webkit-filter: grayscale(0%);
}

/* O */
@media (min-width: 576px) {
  .g-bg-transparent--sm {
    background-color: transparent !important;
  }
}

/*------------------------------------
  Background Colors Extended
------------------------------------*/
.js-header-change-moment .g-bg-primary--scrolling {
  background-color: #226f1c !important;
}

/*------------------------------------
  Images Grid
------------------------------------*/
.g-bg-grid-v1--before::before {
  background-image: url(../img/bg/grid/wave.png);
}

.g-bg-grid-v2--before::after {
  background-image: url(../img/bg/grid/tile-left.png);
}

.g-bg-grid-v3--before::after {
  background-image: url(../img/bg/grid/tile-right.png);
}

.g-bg-grid-v4--before::after {
  background-image: url(../img/bg/grid/diagonal-left.png);
}

.g-bg-grid-v5--before::after {
  background-image: url(../img/bg/grid/diagonal-right.png);
}

.g-bg-grid-v6--before::after {
  background-image: url(../img/bg/grid/zigzag-left.png);
}

.g-bg-grid-v7--before::after {
  background-image: url(../img/bg/grid/zigzag-right.png);
}

/*------------------------------------
  Covers
------------------------------------*/
.g-bg-cover {
  position: relative;
}

.g-bg-cover::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.g-bg-cover-v1::after {
  position: absolute;
  top: 0.35714rem;
  right: 0.35714rem;
  bottom: 0.35714rem;
  left: 0.35714rem;
}

.g-bg-cover__inner {
  z-index: 1;
  position: relative;
}

/*------------------------------------
  Background Image Style
------------------------------------*/
.g-bg-img-hero {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

/*------------------------------------
  Background Patterns
------------------------------------*/
.g-bg-pattern-green {
  background-image: url("../img/bg/pattern--green.png");
}

/*------------------------------------
  Background Position
------------------------------------*/
.g-bg-pos-center {
  background-position: center;
}

.g-bg-pos-left-center {
  background-position: left center;
}

.g-bg-pos-top-left {
  background-position: top left;
}

.g-bg-pos-top-right {
  background-position: top right;
}

.g-bg-pos-top-center {
  background-position: top center;
}

.g-bg-pos-bottom-center {
  background-position: bottom center;
}

/*------------------------------------
  Background Repeat
------------------------------------*/
.g-bg-no-repeat {
  background-repeat: no-repeat;
}

/*------------------------------------
  Background Size
------------------------------------*/
.g-bg-size-cover {
  background-size: cover !important;
}

.g-bg-size-100x {
  background-size: 100% !important;
}

.g-bg-size-100x--hover:hover {
  background-size: 100% !important;
}

.g-parent:hover .g-bg-size-100x--parent-hover {
  background-size: 100% !important;
}

.g-bg-size-120x {
  background-size: 120% !important;
}

.g-bg-size-120x--hover {
  background-size: 120% !important;
}

.g-parent:hover .g-bg-size-120x--parent-hover {
  background-size: 120% !important;
}

.g-bg-repeat {
  background-repeat: repeat !important;
  /* P */
  background-size: auto !important;
}

/*------------------------------------
  Borders Default (solid)
------------------------------------*/
.g-brd-none {
  /* P */
  border: none !important;
}

.g-brd-x {
  border-left: solid 1px transparent !important;
  border-right: solid 1px transparent !important;
}

.g-brd-y {
  border-top: solid 1px transparent !important;
  border-bottom: solid 1px transparent !important;
}

.g-brd-around {
  border: solid 1px transparent !important;
}

.g-brd-top {
  border-top: solid 1px transparent !important;
}

.g-brd-right {
  border-right: solid 1px transparent !important;
}

.g-brd-bottom {
  border-bottom: solid 1px transparent !important;
}

.g-brd-left {
  border-left: solid 1px transparent !important;
}

.g-brd-left-none {
  border-left: none !important;
}

@media (min-width: 576px) {
  .g-brd-around--sm {
    border: solid 1px transparent !important;
  }
  .g-brd-top--sm {
    border-top: solid 1px transparent !important;
  }
  .g-brd-right--sm {
    border-right: solid 1px transparent !important;
  }
  .g-brd-bottom--sm {
    border-bottom: solid 1px transparent !important;
  }
  .g-brd-left--sm {
    border-left: solid 1px transparent !important;
  }
}

@media (min-width: 768px) {
  .g-brd-x--md {
    border-left: solid 1px transparent !important;
    border-right: solid 1px transparent !important;
  }
  .g-brd-around--md {
    border: solid 1px transparent !important;
  }
  .g-brd-top--md {
    border-top: solid 1px transparent !important;
  }
  .g-brd-right--md {
    border-right: solid 1px transparent !important;
  }
  .g-brd-bottom--md {
    border-bottom: solid 1px transparent !important;
  }
  .g-brd-left--md {
    border-left: solid 1px transparent !important;
  }
}

@media (min-width: 992px) {
  /* P */
  .g-brd-around--lg {
    border: solid 1px transparent !important;
  }
  .g-brd-top--lg {
    border-top: solid 1px transparent !important;
  }
  .g-brd-right--lg {
    border-right: solid 1px transparent !important;
  }
  .g-brd-bottom--lg {
    border-bottom: solid 1px transparent !important;
  }
  .g-brd-left--lg {
    border-left: solid 1px transparent !important;
  }
  .g-brd-left-none--lg {
    border-left: none !important;
  }
  .g-brd-x--lg {
    border-left: solid 1px transparent;
    border-right: solid 1px transparent;
  }
  .g-brd-none--lg {
    border: none !important;
  }
}

/*------------------------------------
  Borders None
------------------------------------*/
.g-brd-around-none {
  border-width: 0 !important;
}

.g-brd-y-none {
  border-top: none !important;
  border-bottom: none !important;
}

.g-brd-top-none {
  border-top: none !important;
}

.g-brd-bottom-none {
  border-bottom: none !important;
}

.g-brd-x-none {
  border-left: none !important;
  border-right: none !important;
}

.g-brd-left-none {
  border-left: none !important;
}

.g-brd-right-none {
  border-right: none !important;
}

@media (min-width: 576px) {
  .g-brd-around-none--sm {
    border-width: 0 !important;
  }
  .g-brd-y-none--sm {
    border-top: none !important;
    border-bottom: none !important;
  }
  .g-brd-top-none--sm {
    border-top: none !important;
  }
  .g-brd-bottom-none--sm {
    border-bottom: none !important;
  }
  .g-brd-x-none--sm {
    border-left: none !important;
    border-right: none !important;
  }
  .g-brd-left-none--sm {
    border-left: none !important;
  }
  .g-brd-right-none--sm {
    border-right: none !important;
  }
}

@media (min-width: 768px) {
  .g-brd-around-none--md {
    border-width: 0 !important;
  }
  .g-brd-y-none--md {
    border-top: none !important;
    border-bottom: none !important;
  }
  .g-brd-top-none--md {
    border-top: none !important;
  }
  .g-brd-bottom-none--md {
    border-bottom: none !important;
  }
  .g-brd-x-none--md {
    border-left: none !important;
    border-right: none !important;
  }
  .g-brd-left-none--md {
    border-left: none !important;
  }
  .g-brd-right-none--md {
    border-right: none !important;
  }
}

@media (min-width: 992px) {
  .g-brd-around-none--lg {
    border-width: 0 !important;
  }
  .g-brd-y-none--lg {
    border-top: none !important;
    border-bottom: none !important;
  }
  .g-brd-top-none--lg {
    border-top: none !important;
  }
  .g-brd-bottom-none--lg {
    border-bottom: none !important;
  }
  .g-brd-x-none--lg {
    border-left: none !important;
    border-right: none !important;
  }
  .g-brd-left-none--lg {
    border-left: none !important;
  }
  .g-brd-right-none--lg {
    border-right: none !important;
  }
}

@media (min-width: 1200px) {
  .g-brd-around-none--xl {
    border-width: 0 !important;
  }
  .g-brd-y-none--xl {
    border-top: none !important;
    border-bottom: none !important;
  }
  .g-brd-top-none--xl {
    border-top: none !important;
  }
  .g-brd-bottom-none--xl {
    border-bottom: none !important;
  }
  .g-brd-x-none--xl {
    border-left: none !important;
    border-right: none !important;
  }
  .g-brd-left-none--xl {
    border-left: none !important;
  }
  .g-brd-right-none--xl {
    border-right: none !important;
  }
}

/*------------------------------------
  Border Style
------------------------------------*/
.g-brd-style-solid {
  border-style: solid !important;
}

.g-brd-style-dashed {
  border-style: dashed !important;
}

.g-brd-style-dotted {
  border-style: dotted !important;
}

/*------------------------------------
  Border Radius (Rounded Styles)
------------------------------------*/
/* Rounded Around */
.rounded-0 {
  border-radius: 0 !important;
}

.g-rounded-50x {
  border-radius: 50%;
}

.g-rounded-1 {
  border-radius: 1px !important;
}

.g-rounded-2 {
  border-radius: 2px !important;
}

.g-rounded-3 {
  border-radius: 3px !important;
}

.g-rounded-4 {
  border-radius: 4px !important;
}

.g-rounded-5 {
  border-radius: 5px !important;
}

.g-rounded-6 {
  border-radius: 6px !important;
}

.g-rounded-7 {
  border-radius: 7px !important;
}

.g-rounded-10 {
  border-radius: 10px !important;
}

.g-rounded-15 {
  border-radius: 15px !important;
}

.g-rounded-20 {
  border-radius: 20px !important;
}

.g-rounded-25 {
  border-radius: 25px !important;
}

.g-rounded-30 {
  border-radius: 30px !important;
}

.g-rounded-50 {
  border-radius: 50px !important;
}

.g-rounded-100 {
  border-radius: 100px !important;
}

.g-rounded-100--after::after {
  border-radius: 100px !important;
}

/* Rounded Top */
.g-rounded-top-5 {
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
}

/* Rounded Right */
.g-rounded-right-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.g-rounded-right-3 {
  border-top-right-radius: 3px !important;
  border-bottom-right-radius: 3px !important;
}

.g-rounded-right-4 {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

.g-rounded-right-5 {
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.g-rounded-right-10 {
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.g-rounded-right-20 {
  border-bottom-right-radius: 20px !important;
  border-top-right-radius: 20px !important;
}

.g-rounded-right-25 {
  border-bottom-right-radius: 25px !important;
  border-top-right-radius: 25px !important;
}

.g-rounded-right-30 {
  border-top-right-radius: 30px !important;
  border-bottom-right-radius: 30px !important;
}

.g-rounded-right-50 {
  border-top-right-radius: 50px !important;
  border-bottom-right-radius: 50px !important;
}

/* Rounded Bottom */
.g-rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.g-rounded-bottom-3 {
  border-bottom-left-radius: 3px !important;
  border-bottom-right-radius: 3px !important;
}

.g-rounded-bottom-4 {
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

.g-rounded-bottom-5 {
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

/* Rounded Left */
.g-rounded-left-0 {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.g-rounded-left-3 {
  border-top-left-radius: 3px !important;
  border-bottom-left-radius: 3px !important;
}

.g-rounded-left-4 {
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}

.g-rounded-left-5 {
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}

.g-rounded-left-10 {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}

.g-rounded-left-20 {
  border-bottom-left-radius: 20px !important;
  border-top-left-radius: 20px !important;
}

.g-rounded-left-25 {
  border-bottom-left-radius: 25px !important;
  border-top-left-radius: 25px !important;
}

.g-rounded-left-30 {
  border-top-left-radius: 30px !important;
  border-bottom-left-radius: 30px !important;
}

.g-rounded-left-50 {
  border-top-left-radius: 50px !important;
  border-bottom-left-radius: 50px !important;
}

.g-rounded-circle--before::before {
  border-radius: 50% !important;
}

.g-rounded-circle--after::after {
  border-radius: 50% !important;
}

@media (min-width: 768px) {
  .g-rounded-0--md {
    border-radius: 0 !important;
  }
  .g-rounded-left-0--md {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
  .g-rounded-right-0--md {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}

@media (min-width: 992px) {
  .g-rounded-left-5--lg-up {
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
  }
  .g-rounded-right-5--lg-up {
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
  }
}

/*------------------------------------
  Borders Default (dashed)
------------------------------------*/
.g-brd-around--dashed {
  border: dashed 1px transparent;
}

.g-brd-top--dashed {
  border-top: dashed 1px transparent;
}

.g-brd-bottom--dashed {
  border-bottom: dashed 1px transparent;
}

.g-brd-right--dashed {
  border-right: dashed 1px transparent;
}

.g-brd-left--dashed {
  border-left: dashed 1px transparent;
}

@media (min-width: 576px) {
  .g-brd-around--dashed--sm {
    border: dashed 1px transparent;
  }
  .g-brd-top--dashed--sm {
    border-top: dashed 1px transparent;
  }
  .g-brd-bottom--dashed--sm {
    border-bottom: dashed 1px transparent;
  }
  .g-brd-right--dashed--sm {
    border-right: dashed 1px transparent;
  }
  .g-brd-left--dashed--sm {
    border-left: dashed 1px transparent;
  }
}

@media (min-width: 768px) {
  .g-brd-around--dashed--md {
    border: dashed 1px transparent;
  }
  .g-brd-top--dashed--md {
    border-top: dashed 1px transparent;
  }
  .g-brd-bottom--dashed--md {
    border-bottom: dashed 1px transparent;
  }
  .g-brd-right--dashed--md {
    border-right: dashed 1px transparent;
  }
  .g-brd-left--dashed--md {
    border-left: dashed 1px transparent;
  }
}

/*------------------------------------
  Borders Default (dotted)
------------------------------------*/
.g-brd-around--dotted {
  border: dotted 1px transparent;
}

.g-brd-top--dotted {
  border-top: dotted 1px transparent;
}

.g-brd-bottom--dotted {
  border-bottom: dotted 1px transparent;
}

.g-brd-right--dotted {
  border-right: dotted 1px transparent;
}

.g-brd-left--dotted {
  border-left: dotted 1px transparent;
}

@media (min-width: 576px) {
  .g-brd-around--dotted--sm {
    border: dotted 1px transparent;
  }
  .g-brd-top--dotted--sm {
    border-top: dotted 1px transparent;
  }
  .g-brd-bottom--dotted--sm {
    border-bottom: dotted 1px transparent;
  }
  .g-brd-right--dotted--sm {
    border-right: dotted 1px transparent;
  }
  .g-brd-left--dotted--sm {
    border-left: dotted 1px transparent;
  }
}

@media (min-width: 768px) {
  .g-brd-around--dotted--md {
    border: dotted 1px transparent;
  }
  .g-brd-top--dotted--md {
    border-top: dotted 1px transparent;
  }
  .g-brd-bottom--dotted--md {
    border-bottom: dotted 1px transparent;
  }
  .g-brd-right--dotted--md {
    border-right: dotted 1px transparent;
  }
  .g-brd-left--dotted--md {
    border-left: dotted 1px transparent;
  }
}

/*------------------------------------
  Border Width
------------------------------------*/
.g-brd-0 {
  border-width: 0 !important;
}

.g-brd-1 {
  border-width: 1px !important;
}

.g-brd-2 {
  border-width: 2px !important;
}

.g-brd-3 {
  border-width: 3px !important;
}

.g-brd-3--before::before {
  border-width: 3px !important;
}

.g-brd-3--before--active.active::before {
  border-width: 3px !important;
}

.g-brd-4 {
  border-width: 4px !important;
}

.g-brd-5 {
  border-width: 5px !important;
}

.g-brd-6 {
  border-width: 6px !important;
}

.g-brd-7 {
  border-width: 7px !important;
}

.g-brd-10 {
  border-width: 10px !important;
}

.g-brd-12 {
  border-width: 12px !important;
}

.g-brd-top-0 {
  border-top-width: 0 !important;
}

.g-brd-top-2 {
  border-top-width: 2px !important;
}

.g-brd-top-3 {
  border-top-width: 3px !important;
}

.g-brd-top-5 {
  border-top-width: 5px !important;
}

.g-brd-bottom-0 {
  border-bottom-width: 0 !important;
}

.g-brd-bottom-2 {
  border-bottom-width: 2px !important;
}

.g-brd-bottom-2--hover:hover {
  border-bottom-width: 2px !important;
}

.g-parent:hover .g-brd-bottom-2--parent-hover {
  border-bottom-width: 2px !important;
}

.g-brd-bottom-5 {
  border-bottom-width: 5px !important;
}

.g-brd-left-0 {
  border-left-width: 0 !important;
}

.g-brd-left-1 {
  border-left-width: 1px !important;
}

.g-brd-left-2 {
  border-left-width: 2px !important;
}

.g-brd-left-3 {
  border-left-width: 3px !important;
}

.g-brd-left-4 {
  border-left-width: 4px !important;
}

.g-brd-right-0 {
  border-right-width: 0 !important;
}

.g-brd-right-1 {
  border-right-width: 1px !important;
}

.g-brd-right-2 {
  border-right-width: 2px !important;
}

.g-brd-right-3 {
  border-right-width: 3px !important;
}

.g-brd-right-4 {
  border-right-width: 4px !important;
}

@media (min-width: 768px) {
  .g-brd-0--md {
    border-width: 0 !important;
  }
  .g-brd-1--md {
    border-width: 1px !important;
  }
  .g-brd-2--md {
    border-width: 2px !important;
  }
  .g-brd-3--md {
    border-width: 3px !important;
  }
  .g-brd-4--md {
    border-width: 4px !important;
  }
  .g-brd-5--md {
    border-width: 5px !important;
  }
  .g-brd-6--md {
    border-width: 6px !important;
  }
  .g-brd-10--md {
    border-width: 10px !important;
  }
  .g-brd-top-0--md {
    border-top-width: 0 !important;
  }
  .g-brd-top-2--md {
    border-top-width: 2px !important;
  }
  .g-brd-top-3--md {
    border-top-width: 3px !important;
  }
  .g-brd-top-5--md {
    border-top-width: 5px !important;
  }
  .g-brd-bottom-0--md {
    border-bottom-width: 0 !important;
  }
  .g-brd-bottom-2--md {
    border-bottom-width: 2px !important;
  }
  .g-brd-bottom-5--md {
    border-bottom-width: 5px !important;
  }
  .g-brd-left-0--md {
    border-left-width: 0 !important;
  }
  .g-brd-left-1--md {
    border-left-width: 1px !important;
  }
  .g-brd-left-2--md {
    border-left-width: 2px !important;
  }
  .g-brd-left-3--md {
    border-left-width: 3px !important;
  }
  .g-brd-left-4--md {
    border-left-width: 4px !important;
  }
  .g-brd-right-0--md {
    border-right-width: 0 !important;
  }
  .g-brd-right-1--md {
    border-right-width: 1px !important;
  }
}

@media (min-width: 992px) {
  .g-brd-0--lg {
    border-width: 0 !important;
  }
  .g-brd-1--lg {
    border-width: 1px !important;
  }
  .g-brd-top-0--lg {
    border-top-width: 0 !important;
  }
  .g-brd-top-1--lg {
    border-top-width: 1px !important;
  }
  .g-brd-bottom-0--lg {
    border-bottom-width: 0 !important;
  }
  .g-brd-bottom-1--lg {
    border-bottom-width: 1px !important;
  }
  .g-brd-left-0--lg {
    border-left-width: 0 !important;
  }
  .g-brd-left-1--lg {
    border-left-width: 1px !important;
  }
  .g-brd-right-0--lg {
    border-right-width: 0 !important;
  }
  .g-brd-right-1--lg {
    border-right-width: 1px !important;
  }
}

/*------------------------------------
  Border Colors
------------------------------------*/
/* Basic Colors
------------------------------------*/
/* Primary Colors */
.g-brd-primary {
  border-color: #226f1c !important;
}

.g-brd-primary--before::before {
  border-color: #226f1c !important;
}

.g-brd-primary--before--active.active::before {
  border-color: #226f1c !important;
}

.g-brd-primary--hover:hover, .g-brd-primary--active.active {
  border-color: #226f1c !important;
}

.g-brd-primary--active.active,
.active .g-brd-primary--active {
  border-color: #226f1c !important;
}

*:hover > .g-brd-primary--hover-parent {
  border-color: #226f1c !important;
}

.g-brd-primary-opacity-0_3 {
  border-color: rgba(34, 111, 28, 0.3) !important;
}

.g-brd-primary-opacity-0_3--hover:hover, .g-brd-primary-opacity-0_3--active.active {
  border-color: rgba(34, 111, 28, 0.3) !important;
}

*:hover > .g-brd-primary-opacity-0_3--hover-parent {
  border-color: rgba(34, 111, 28, 0.3) !important;
}

.g-brd-primary-dark-dark-v1 {
  border-color: #20671a !important;
}

.g-brd-primary-dark-dark-v1--hover:hover, .g-brd-primary-dark-dark-v1--active.active {
  border-color: #20671a !important;
}

*:hover > .g-brd-primary-dark-dark-v1--hover-parent {
  border-color: #20671a !important;
}

.g-brd-primary-dark-dark-v2 {
  border-color: #1c5b17 !important;
}

.g-brd-primary-dark-dark-v2--hover:hover, .g-brd-primary-dark-dark-v2--active.active {
  border-color: #1c5b17 !important;
}

*:hover > .g-brd-primary-dark-dark-v2--hover-parent {
  border-color: #1c5b17 !important;
}

.g-brd-primary-dark-dark-v3 {
  border-color: #164612 !important;
}

.g-brd-primary-dark-dark-v3--hover:hover, .g-brd-primary-dark-dark-v3--active.active {
  border-color: #164612 !important;
}

*:hover > .g-brd-primary-dark-dark-v3--hover-parent {
  border-color: #164612 !important;
}

.g-brd-bottom-primary--hover:hover {
  border-bottom-color: #226f1c !important;
}

/* Main Colors */
.g-brd-main {
  border-color: #999 !important;
}

.g-brd-main--hover:hover {
  border-color: #999 !important;
}

/* Black Colors */
.g-brd-black {
  border-color: #000 !important;
}

.g-brd-black--hover:hover, .g-brd-black--active.active {
  border-color: #000 !important;
}

*:hover > .g-brd-black--hover-parent {
  border-color: #000 !important;
}

.g-brd-black-opacity-0_4 {
  border-color: rgba(0, 0, 0, 0.4) !important;
}

/* White Colors */
.g-brd-white {
  border-color: #fff !important;
}

.g-brd-white--hover:hover, .g-brd-white--active.active {
  border-color: #fff !important;
}

*:hover > .g-brd-white--hover-parent {
  border-color: #fff !important;
}

.g-brd-white-opacity-0_1 {
  border-color: rgba(255, 255, 255, 0.1) !important;
}

.g-brd-white-opacity-0_1--hover:hover, .g-brd-white-opacity-0_1--active.active {
  border-color: rgba(255, 255, 255, 0.1) !important;
}

*:hover > .g-brd-white-opacity-0_1--hover-parent {
  border-color: rgba(255, 255, 255, 0.1) !important;
}

.g-brd-white-opacity-0_2 {
  border-color: rgba(255, 255, 255, 0.2) !important;
}

.g-brd-white-opacity-0_2--hover:hover, .g-brd-white-opacity-0_2--active.active {
  border-color: rgba(255, 255, 255, 0.2) !important;
}

*:hover > .g-brd-white-opacity-0_2--hover-parent {
  border-color: rgba(255, 255, 255, 0.2) !important;
}

.g-brd-white-opacity-0_3 {
  border-color: rgba(255, 255, 255, 0.3) !important;
}

.g-brd-white-opacity-0_3--hover:hover, .g-brd-white-opacity-0_3--active.active {
  border-color: rgba(255, 255, 255, 0.3) !important;
}

*:hover > .g-brd-white-opacity-0_3--hover-parent {
  border-color: rgba(255, 255, 255, 0.3) !important;
}

.g-brd-white-opacity-0_4 {
  border-color: rgba(255, 255, 255, 0.4) !important;
}

.g-brd-white-opacity-0_4--hover:hover, .g-brd-white-opacity-0_4--active.active {
  border-color: rgba(255, 255, 255, 0.4) !important;
}

*:hover > .g-brd-white-opacity-0_4--hover-parent {
  border-color: rgba(255, 255, 255, 0.4) !important;
}

.g-brd-white-opacity-0_5 {
  border-color: rgba(255, 255, 255, 0.5) !important;
}

.g-brd-white-opacity-0_5--hover:hover, .g-brd-white-opacity-0_5--active.active {
  border-color: rgba(255, 255, 255, 0.5) !important;
}

*:hover > .g-brd-white-opacity-0_5--hover-parent {
  border-color: rgba(255, 255, 255, 0.5) !important;
}

.g-brd-white-opacity-0_6 {
  border-color: rgba(255, 255, 255, 0.6) !important;
}

.g-brd-white-opacity-0_6--hover:hover, .g-brd-white-opacity-0_6--active.active {
  border-color: rgba(255, 255, 255, 0.6) !important;
}

*:hover > .g-brd-white-opacity-0_6--hover-parent {
  border-color: rgba(255, 255, 255, 0.6) !important;
}

.g-brd-white-opacity-0_8 {
  border-color: rgba(255, 255, 255, 0.8) !important;
}

.g-brd-white-opacity-0_8--hover:hover, .g-brd-white-opacity-0_8--active.active {
  border-color: rgba(255, 255, 255, 0.8) !important;
}

*:hover > .g-brd-white-opacity-0_8--hover-parent {
  border-color: rgba(255, 255, 255, 0.8) !important;
}

/* Gray Colors */
.g-brd-gray-dark-v1 {
  border-color: #111 !important;
}

.g-brd-gray-dark-v1--hover:hover, .g-brd-gray-dark-v1--active.active {
  border-color: #111 !important;
}

*:hover > .g-brd-gray-dark-v1--hover-parent {
  border-color: #111 !important;
}

.g-brd-gray-dark-v2 {
  border-color: #333 !important;
}

.g-brd-gray-dark-v2--hover:hover, .g-brd-gray-dark-v2--active.active {
  border-color: #333 !important;
}

*:hover > .g-brd-gray-dark-v2--hover-parent {
  border-color: #333 !important;
}

.g-brd-gray-dark-v3 {
  border-color: #555 !important;
}

.g-brd-gray-dark-v3--hover:hover, .g-brd-gray-dark-v3--active.active {
  border-color: #555 !important;
}

*:hover > .g-brd-gray-dark-v3--hover-parent {
  border-color: #555 !important;
}

.g-brd-gray-dark-v4 {
  border-color: #777 !important;
}

.g-brd-gray-dark-v4--hover:hover, .g-brd-gray-dark-v4--active.active {
  border-color: #777 !important;
}

*:hover > .g-brd-gray-dark-v4--hover-parent {
  border-color: #777 !important;
}

.g-brd-gray-dark-v5 {
  border-color: #999 !important;
}

.g-brd-gray-dark-v5--hover:hover, .g-brd-gray-dark-v5--active.active {
  border-color: #999 !important;
}

*:hover > .g-brd-gray-dark-v5--hover-parent {
  border-color: #999 !important;
}

.g-brd-gray-light-v1 {
  border-color: #bbb !important;
}

.g-brd-gray-light-v1--hover:hover, .g-brd-gray-light-v1--focus:focus, .g-brd-gray-light-v1--active.active {
  border-color: #bbb !important;
}

*:hover > .g-brd-gray-light-v1--hover-parent {
  border-color: #bbb !important;
}

.g-brd-gray-light-v2 {
  border-color: #ccc !important;
}

.g-brd-gray-light-v2--before::before {
  border-color: #ccc !important;
}

.g-brd-gray-light-v2--after::after {
  border-color: #ccc !important;
}

.g-brd-gray-light-v2--hover:hover, .g-brd-gray-light-v2--active.active {
  border-color: #ccc !important;
}

*:hover > .g-brd-gray-light-v2--hover-parent {
  border-color: #ccc !important;
}

.g-brd-gray-light-v3 {
  border-color: #ddd !important;
}

.g-brd-gray-light-v3--hover:hover, .g-brd-gray-light-v3--active.active, .g-brd-gray-light-v3--focus:focus {
  border-color: #ddd !important;
}

*:hover > .g-brd-gray-light-v3--hover-parent {
  border-color: #ddd !important;
}

.g-brd-gray-light-v4 {
  border-color: #eee !important;
}

.g-brd-gray-light-v4--hover:hover, .g-brd-gray-light-v4--active.active {
  border-color: #eee !important;
}

*:hover > .g-brd-gray-light-v4--hover-parent {
  border-color: #eee !important;
}

.g-brd-gray-light-v5 {
  border-color: #f7f7f7 !important;
}

.g-brd-gray-light-v5--hover:hover, .g-brd-gray-light-v5--active.active {
  border-color: #f7f7f7 !important;
}

*:hover > .g-brd-gray-light-v5--hover-parent {
  border-color: #f7f7f7 !important;
}

/* Transparent */
.g-brd-transparent {
  border-color: transparent !important;
}

.g-brd-transparent--before::before {
  border-color: transparent !important;
}

.g-brd-transparent--after::after {
  border-color: transparent !important;
}

.g-brd-transparent--hover:hover, .g-brd-transparent--active.active {
  border-color: transparent !important;
}

*:hover > .g-brd-transparent--hover-parent {
  border-color: transparent !important;
}

/* Complementary Colors
------------------------------------*/
/* Color Green */
.g-brd-green {
  border-color: #72c02c !important;
}

.g-brd-green--hover:hover, .g-brd-green--active.active {
  border-color: #72c02c !important;
}

*:hover > .g-brd-green--hover-parent {
  border-color: #72c02c !important;
}

/* Color Blue */
.g-brd-blue {
  border-color: #3398dc !important;
}

.g-brd-blue--hover:hover, .g-brd-blue--active.active {
  border-color: #3398dc !important;
}

*:hover > .g-brd-blue--hover-parent {
  border-color: #3398dc !important;
}

.g-brd-blue-opacity-0_1 {
  border-color: rgba(51, 152, 220, 0.1) !important;
}

/* Color Light Blue */
.g-brd-lightblue {
  border-color: #edf2f8 !important;
}

.g-brd-lightblue--hover:hover, .g-brd-lightblue--active.active {
  border-color: #edf2f8 !important;
}

*:hover > .g-brd-lightblue--hover-parent {
  border-color: #edf2f8 !important;
}

.g-brd-lightblue-v1 {
  border-color: #d6e2ee !important;
}

.g-brd-lightblue-v1--hover:hover, .g-brd-lightblue-v1--active.active {
  border-color: #d6e2ee !important;
}

*:hover > .g-brd-lightblue-v1--hover-parent {
  border-color: #d6e2ee !important;
}

/* Color Dark Blue */
.g-brd-darkblue {
  border-color: #009 !important;
}

.g-brd-darkblue--hover:hover, .g-brd-darkblue--active.active {
  border-color: #009 !important;
}

*:hover > .g-brd-darkblue--hover-parent {
  border-color: #009 !important;
}

/* Color Indigo */
.g-brd-indigo {
  border-color: #4263a3 !important;
}

.g-brd-indigo--hover:hover, .g-brd-indigo--active.active {
  border-color: #4263a3 !important;
}

*:hover > .g-brd-indigo--hover-parent {
  border-color: #4263a3 !important;
}

/* Color Red */
.g-brd-red {
  border-color: #f00 !important;
}

.g-brd-red--hover:hover, .g-brd-red--active.active {
  border-color: #f00 !important;
}

*:hover > .g-brd-red--hover-parent {
  border-color: #f00 !important;
}

/* Color Light Red */
.g-brd-lightred {
  border-color: #e64b3b !important;
}

.g-brd-lightred--hover:hover, .g-brd-lightred--active.active {
  border-color: #e64b3b !important;
}

*:hover > .g-brd-lightred--hover-parent {
  border-color: #e64b3b !important;
}

/* Color Dark Red */
.g-brd-darkred {
  border-color: #a10f2b !important;
}

.g-brd-darkred--hover:hover, .g-brd-darkred--active.active {
  border-color: #a10f2b !important;
}

*:hover > .g-brd-darkred--hover-parent {
  border-color: #a10f2b !important;
}

/* Color Purple */
.g-brd-purple {
  border-color: #9a69cb !important;
}

.g-brd-purple--hover:hover, .g-brd-purple--active.active {
  border-color: #9a69cb !important;
}

*:hover > .g-brd-purple--hover-parent {
  border-color: #9a69cb !important;
}

.g-brd-purple-opacity-0_1 {
  border-color: rgba(154, 105, 203, 0.1) !important;
}

/* Color Dark Purple */
.g-brd-darkpurple {
  border-color: #6639b6 !important;
}

.g-brd-darkpurple--hover:hover, .g-brd-darkpurple--active.active {
  border-color: #6639b6 !important;
}

*:hover > .g-brd-darkpurple--hover-parent {
  border-color: #6639b6 !important;
}

/* Color Pink */
.g-brd-pink {
  border-color: #e81c62 !important;
}

.g-brd-pink--hover:hover, .g-brd-pink--active.active {
  border-color: #e81c62 !important;
}

*:hover > .g-brd-pink--hover-parent {
  border-color: #e81c62 !important;
}

/* Color Orange */
.g-brd-orange {
  border-color: #e57d20 !important;
}

.g-brd-orange--hover:hover, .g-brd-orange--active.active {
  border-color: #e57d20 !important;
}

*:hover > .g-brd-orange--hover-parent {
  border-color: #e57d20 !important;
}

/* Color Deep Orange */
.g-brd-deeporange {
  border-color: #fe541e !important;
}

.g-brd-deeporange--hover:hover, .g-brd-deeporange--active.active {
  border-color: #fe541e !important;
}

*:hover > .g-brd-deeporange--hover-parent {
  border-color: #fe541e !important;
}

/* Color Yellow */
.g-brd-yellow {
  border-color: #ebc71d !important;
}

.g-brd-yellow--hover:hover, .g-brd-yellow--active.active {
  border-color: #ebc71d !important;
}

*:hover > .g-brd-yellow--hover-parent {
  border-color: #ebc71d !important;
}

/* Color Aqua */
.g-brd-aqua {
  border-color: #29d6e6 !important;
}

.g-brd-aqua--hover:hover, .g-brd-aqua--active.active {
  border-color: #29d6e6 !important;
}

*:hover > .g-brd-aqua--hover-parent {
  border-color: #29d6e6 !important;
}

/* Color Cyan */
.g-brd-cyan {
  border-color: #00bed6 !important;
}

.g-brd-cyan--hover:hover, .g-brd-cyan--active.active {
  border-color: #00bed6 !important;
}

*:hover > .g-brd-cyan--hover-parent {
  border-color: #00bed6 !important;
}

/* Color Teal */
.g-brd-teal {
  border-color: #18ba9b !important;
}

.g-brd-teal--hover:hover, .g-brd-teal--active.active {
  border-color: #18ba9b !important;
}

*:hover > .g-brd-teal--hover-parent {
  border-color: #18ba9b !important;
}

/* Color Brown */
.g-brd-brown {
  border-color: #9c8061 !important;
}

.g-brd-brown--hover:hover, .g-brd-brown--active.active {
  border-color: #9c8061 !important;
}

*:hover > .g-brd-brown--hover-parent {
  border-color: #9c8061 !important;
}

/* Color Blue Gray */
.g-brd-bluegray {
  border-color: #585f69 !important;
}

.g-brd-bluegray--hover:hover, .g-brd-bluegray--active.active {
  border-color: #585f69 !important;
}

*:hover > .g-brd-bluegray--hover-parent {
  border-color: #585f69 !important;
}

/* Primary Colors */
.g-brd-primary-top {
  border-top-color: #226f1c !important;
}

.g-brd-primary-top--before::before {
  border-top-color: #226f1c !important;
}

.g-brd-primary-bottom {
  border-bottom-color: #226f1c !important;
}

.g-brd-primary-bottom--before:before {
  border-bottom-color: #226f1c !important;
}

.g-brd-primary-left {
  border-left-color: #226f1c !important;
}

.g-brd-primary-left--before:before {
  border-left-color: #226f1c !important;
}

.g-brd-primary-right {
  border-right-color: #226f1c !important;
}

.g-brd-primary-right--before:before {
  border-right-color: #226f1c !important;
}

.g-brd-primary-opacity-0_3-top {
  border-top-color: rgba(34, 111, 28, 0.3) !important;
}

.g-brd-primary-opacity-0_3-bottom {
  border-bottom-color: rgba(34, 111, 28, 0.3) !important;
}

.g-brd-primary-opacity-0_3-left {
  border-left-color: rgba(34, 111, 28, 0.3) !important;
}

.g-brd-primary-opacity-0_3-right {
  border-right-color: rgba(34, 111, 28, 0.3) !important;
}

.g-brd-primary-dark-dark-v1-top {
  border-top-color: #20671a !important;
}

.g-brd-primary-dark-dark-v1-bottom {
  border-bottom-color: #20671a !important;
}

.g-brd-primary-dark-dark-v1-left {
  border-left-color: #20671a !important;
}

.g-brd-primary-dark-dark-v1-right {
  border-right-color: #20671a !important;
}

.g-brd-primary-dark-dark-v2-top {
  border-top-color: #1c5b17 !important;
}

.g-brd-primary-dark-dark-v2-bottom {
  border-bottom-color: #1c5b17 !important;
}

.g-brd-primary-dark-dark-v2-left {
  border-left-color: #1c5b17 !important;
}

.g-brd-primary-dark-dark-v2-right {
  border-right-color: #1c5b17 !important;
}

.g-brd-primary-dark-dark-v3-top {
  border-top-color: #164612 !important;
}

.g-brd-primary-dark-dark-v3-bottom {
  border-bottom-color: #164612 !important;
}

.g-brd-primary-dark-dark-v3-left {
  border-left-color: #164612 !important;
}

.g-brd-primary-dark-dark-v3-right {
  border-right-color: #164612 !important;
}

/* Black Colors */
.g-brd-black-top {
  border-top-color: #000 !important;
}

.g-brd-black-bottom {
  border-bottom-color: #000 !important;
}

.g-brd-black-left {
  border-left-color: #000 !important;
}

.g-brd-black-right {
  border-right-color: #000 !important;
}

/* White */
.g-brd-white-top {
  border-top-color: #fff !important;
}

.g-brd-white-bottom {
  border-bottom-color: #fff !important;
}

.g-brd-white-left {
  border-left-color: #fff !important;
}

.g-brd-white-right {
  border-right-color: #fff !important;
}

.g-brd-white-opacity-0_1-top {
  border-top-color: rgba(255, 255, 255, 0.1) !important;
}

.g-brd-white-opacity-0_1-bottom {
  border-bottom-color: rgba(255, 255, 255, 0.1) !important;
}

.g-brd-white-opacity-0_1-left {
  border-left-color: rgba(255, 255, 255, 0.1) !important;
}

.g-brd-white-opacity-0_1-right {
  border-right-color: rgba(255, 255, 255, 0.1) !important;
}

.g-brd-white-opacity-0_2-top {
  border-top-color: rgba(255, 255, 255, 0.2) !important;
}

.g-brd-white-opacity-0_2-bottom {
  border-bottom-color: rgba(255, 255, 255, 0.2) !important;
}

.g-brd-white-opacity-0_2-left {
  border-left-color: rgba(255, 255, 255, 0.2) !important;
}

.g-brd-white-opacity-0_2-right {
  border-right-color: rgba(255, 255, 255, 0.2) !important;
}

.g-brd-white-opacity-0_3-top {
  border-top-color: rgba(255, 255, 255, 0.3) !important;
}

.g-brd-white-opacity-0_3-bottom {
  border-bottom-color: rgba(255, 255, 255, 0.3) !important;
}

.g-brd-white-opacity-0_3-left {
  border-left-color: rgba(255, 255, 255, 0.3) !important;
}

.g-brd-white-opacity-0_3-right {
  border-right-color: rgba(255, 255, 255, 0.3) !important;
}

.g-brd-white-opacity-0_4-top {
  border-top-color: rgba(255, 255, 255, 0.4) !important;
}

.g-brd-white-opacity-0_4-bottom {
  border-bottom-color: rgba(255, 255, 255, 0.4) !important;
}

.g-brd-white-opacity-0_4-left {
  border-left-color: rgba(255, 255, 255, 0.4) !important;
}

.g-brd-white-opacity-0_4-right {
  border-right-color: rgba(255, 255, 255, 0.4) !important;
}

.g-brd-white-opacity-0_5-top {
  border-top-color: rgba(255, 255, 255, 0.5) !important;
}

.g-brd-white-opacity-0_5-bottom {
  border-bottom-color: rgba(255, 255, 255, 0.5) !important;
}

.g-brd-white-opacity-0_5-left {
  border-left-color: rgba(255, 255, 255, 0.5) !important;
}

.g-brd-white-opacity-0_5-right {
  border-right-color: rgba(255, 255, 255, 0.5) !important;
}

.g-brd-white-opacity-0_6-top {
  border-top-color: rgba(255, 255, 255, 0.6) !important;
}

.g-brd-white-opacity-0_6-bottom {
  border-bottom-color: rgba(255, 255, 255, 0.6) !important;
}

.g-brd-white-opacity-0_6-left {
  border-left-color: rgba(255, 255, 255, 0.6) !important;
}

.g-brd-white-opacity-0_6-right {
  border-right-color: rgba(255, 255, 255, 0.6) !important;
}

/* Gray Colors */
.g-brd-gray-dark-v1-top {
  border-top-color: #111 !important;
}

.g-brd-gray-dark-v1-bottom {
  border-bottom-color: #111 !important;
}

.g-brd-gray-dark-v1-left {
  border-left-color: #111 !important;
}

.g-brd-gray-dark-v1-right {
  border-right-color: #111 !important;
}

.g-brd-gray-dark-v2-top {
  border-top-color: #333 !important;
}

.g-brd-gray-dark-v2-bottom {
  border-bottom-color: #333 !important;
}

.g-brd-gray-dark-v2-left {
  border-left-color: #333 !important;
}

.g-brd-gray-dark-v2-right {
  border-right-color: #333 !important;
}

.g-brd-gray-dark-v3-top {
  border-top-color: #555 !important;
}

.g-brd-gray-dark-v3-bottom {
  border-bottom-color: #555 !important;
}

.g-brd-gray-dark-v3-left {
  border-left-color: #555 !important;
}

.g-brd-gray-dark-v3-right {
  border-right-color: #555 !important;
}

.g-brd-gray-dark-v4-top {
  border-top-color: #777 !important;
}

.g-brd-gray-dark-v4-bottom {
  border-bottom-color: #777 !important;
}

.g-brd-gray-dark-v4-left {
  border-left-color: #777 !important;
}

.g-brd-gray-dark-v4-right {
  border-right-color: #777 !important;
}

.g-brd-gray-dark-v5-top {
  border-top-color: #999 !important;
}

.g-brd-gray-dark-v5-bottom {
  border-bottom-color: #999 !important;
}

.g-brd-gray-dark-v5-left {
  border-left-color: #999 !important;
}

.g-brd-gray-dark-v5-right {
  border-right-color: #999 !important;
}

.g-brd-gray-light-v1-top {
  border-top-color: #bbb !important;
}

.g-brd-gray-light-v1-bottom {
  border-bottom-color: #bbb !important;
}

.g-brd-gray-light-v1-left {
  border-left-color: #bbb !important;
}

.g-brd-gray-light-v1-right {
  border-right-color: #bbb !important;
}

.g-brd-gray-light-v2-top {
  border-top-color: #ccc !important;
}

.g-brd-gray-light-v2-bottom {
  border-bottom-color: #ccc !important;
}

.g-brd-gray-light-v2-left {
  border-left-color: #ccc !important;
}

.g-brd-gray-light-v2-right {
  border-right-color: #ccc !important;
}

.g-brd-gray-light-v3-top {
  border-top-color: #ddd !important;
}

.g-brd-gray-light-v3-bottom {
  border-bottom-color: #ddd !important;
}

.g-brd-gray-light-v3-left {
  border-left-color: #ddd !important;
}

.g-brd-gray-light-v3-right {
  border-right-color: #ddd !important;
}

.g-brd-gray-light-v4-top {
  border-top-color: #eee !important;
}

.g-brd-gray-light-v4-bottom {
  border-bottom-color: #eee !important;
}

.g-brd-gray-light-v4-left {
  border-left-color: #eee !important;
}

.g-brd-gray-light-v4-right {
  border-right-color: #eee !important;
}

.g-brd-gray-light-v5-top {
  border-top-color: #f7f7f7 !important;
}

.g-brd-gray-light-v5-bottom {
  border-bottom-color: #f7f7f7 !important;
}

.g-brd-gray-light-v5-left {
  border-left-color: #f7f7f7 !important;
}

.g-brd-gray-light-v5-right {
  border-right-color: #f7f7f7 !important;
}

/* Transparent */
.g-brd-transparent-top {
  border-top-color: transparent !important;
}

.g-brd-transparent-bottom {
  border-bottom-color: transparent !important;
}

.g-brd-transparent-left {
  border-left-color: transparent !important;
}

.g-brd-transparent-right {
  border-right-color: transparent !important;
}

/* Complementary Colors
------------------------------------*/
/* Color Green */
.g-brd-green-top {
  border-top-color: #72c02c !important;
}

.g-brd-green-bottom {
  border-bottom-color: #72c02c !important;
}

.g-brd-green-left {
  border-left-color: #72c02c !important;
}

.g-brd-green-right {
  border-right-color: #72c02c !important;
}

/* Color Blue */
.g-brd-blue-top {
  border-top-color: #3398dc !important;
}

.g-brd-blue-bottom {
  border-bottom-color: #3398dc !important;
}

.g-brd-blue-left {
  border-left-color: #3398dc !important;
}

.g-brd-blue-right {
  border-right-color: #3398dc !important;
}

/* Color Light Blue */
.g-brd-lightblue-top {
  border-top-color: #edf2f8 !important;
}

.g-brd-lightblue-bottom {
  border-bottom-color: #edf2f8 !important;
}

.g-brd-lightblue-left {
  border-left-color: #edf2f8 !important;
}

.g-brd-lightblue-right {
  border-right-color: #edf2f8 !important;
}

.g-brd-lightblue-v1-top {
  border-top-color: #d6e2ee !important;
}

.g-brd-lightblue-v1-bottom {
  border-bottom-color: #d6e2ee !important;
}

.g-brd-lightblue-v1-left {
  border-left-color: #d6e2ee !important;
}

.g-brd-lightblue-v1-right {
  border-right-color: #d6e2ee !important;
}

/* Color Dark Blue */
.g-brd-darkblue-top {
  border-top-color: #009 !important;
}

.g-brd-darkblue-bottom {
  border-bottom-color: #009 !important;
}

.g-brd-darkblue-left {
  border-left-color: #009 !important;
}

.g-brd-darkblue-right {
  border-right-color: #009 !important;
}

/* Color Indigo */
.g-brd-indigo-top {
  border-top-color: #4263a3 !important;
}

.g-brd-indigo-bottom {
  border-bottom-color: #4263a3 !important;
}

.g-brd-indigo-left {
  border-left-color: #4263a3 !important;
}

.g-brd-indigo-right {
  border-right-color: #4263a3 !important;
}

/* Color Red */
.g-brd-red-top {
  border-top-color: #f00 !important;
}

.g-brd-red-bottom {
  border-bottom-color: #f00 !important;
}

.g-brd-red-left {
  border-left-color: #f00 !important;
}

.g-brd-red-right {
  border-right-color: #f00 !important;
}

/* Color Light Red */
.g-brd-lightred-top {
  border-top-color: #e64b3b !important;
}

.g-brd-lightred-bottom {
  border-bottom-color: #e64b3b !important;
}

.g-brd-lightred-left {
  border-left-color: #e64b3b !important;
}

.g-brd-lightred-right {
  border-right-color: #e64b3b !important;
}

/* Color Dark Red */
.g-brd-darkred-top {
  border-top-color: #a10f2b !important;
}

.g-brd-darkred-bottom {
  border-bottom-color: #a10f2b !important;
}

.g-brd-darkred-left {
  border-left-color: #a10f2b !important;
}

.g-brd-darkred-right {
  border-right-color: #a10f2b !important;
}

/* Color Purple */
.g-brd-purple-top {
  border-top-color: #9a69cb !important;
}

.g-brd-purple-bottom {
  border-bottom-color: #9a69cb !important;
}

.g-brd-purple-left {
  border-left-color: #9a69cb !important;
}

.g-brd-purple-right {
  border-right-color: #9a69cb !important;
}

/* Color Dark Purple */
.g-brd-darkpurple-top {
  border-top-color: #6639b6 !important;
}

.g-brd-darkpurple-bottom {
  border-bottom-color: #6639b6 !important;
}

.g-brd-darkpurple-left {
  border-left-color: #6639b6 !important;
}

.g-brd-darkpurple-right {
  border-right-color: #6639b6 !important;
}

/* Color Pink */
.g-brd-pink-top {
  border-top-color: #e81c62 !important;
}

.g-brd-pink-bottom {
  border-bottom-color: #e81c62 !important;
}

.g-brd-pink-left {
  border-left-color: #e81c62 !important;
}

.g-brd-pink-right {
  border-right-color: #e81c62 !important;
}

/* Color Orange */
.g-brd-orange-top {
  border-top-color: #e57d20 !important;
}

.g-brd-orange-bottom {
  border-bottom-color: #e57d20 !important;
}

.g-brd-orange-left {
  border-left-color: #e57d20 !important;
}

.g-brd-orange-right {
  border-right-color: #e57d20 !important;
}

/* Color Deep Orange */
.g-brd-deeporange-top {
  border-top-color: #fe541e !important;
}

.g-brd-deeporange-bottom {
  border-bottom-color: #fe541e !important;
}

.g-brd-deeporange-left {
  border-left-color: #fe541e !important;
}

.g-brd-deeporange-right {
  border-right-color: #fe541e !important;
}

/* Color Yellow */
.g-brd-yellow-top {
  border-top-color: #ebc71d !important;
}

.g-brd-yellow-bottom {
  border-bottom-color: #ebc71d !important;
}

.g-brd-yellow-left {
  border-left-color: #ebc71d !important;
}

.g-brd-yellow-right {
  border-right-color: #ebc71d !important;
}

/* Color Aqua */
.g-brd-aqua-top {
  border-top-color: #29d6e6 !important;
}

.g-brd-aqua-bottom {
  border-bottom-color: #29d6e6 !important;
}

.g-brd-aqua-left {
  border-left-color: #29d6e6 !important;
}

.g-brd-aqua-right {
  border-right-color: #29d6e6 !important;
}

/* Color Cyan */
.g-brd-cyan-top {
  border-top-color: #00bed6 !important;
}

.g-brd-cyan-bottom {
  border-bottom-color: #00bed6 !important;
}

.g-brd-cyan-left {
  border-left-color: #00bed6 !important;
}

.g-brd-cyan-right {
  border-right-color: #00bed6 !important;
}

/* Color Teal */
.g-brd-teal-top {
  border-top-color: #18ba9b !important;
}

.g-brd-teal-bottom {
  border-bottom-color: #18ba9b !important;
}

.g-brd-teal-left {
  border-left-color: #18ba9b !important;
}

.g-brd-teal-right {
  border-right-color: #18ba9b !important;
}

/* Color Brown */
.g-brd-brown-top {
  border-top-color: #9c8061 !important;
}

.g-brd-brown-bottom {
  border-bottom-color: #9c8061 !important;
}

.g-brd-brown-left {
  border-left-color: #9c8061 !important;
}

.g-brd-brown-right {
  border-right-color: #9c8061 !important;
}

/* Color Blue Gray */
.g-brd-bluegray-top {
  border-top-color: #585f69 !important;
}

.g-brd-bluegray-bottom {
  border-bottom-color: #585f69 !important;
}

.g-brd-bluegray-left {
  border-left-color: #585f69 !important;
}

.g-brd-bluegray-right {
  border-right-color: #585f69 !important;
}

/* O */
.g-brd-primary-top--hover:hover {
  border-top-color: #226f1c !important;
}

.g-brd-primary-top--hover:hover::after {
  border-top-color: #226f1c;
}

.g-brd-primary-top--active.active {
  border-top-color: #226f1c !important;
}

.g-brd-primary-top--active.active::after {
  border-top-color: #226f1c;
}

.g-brd-primary-bottom--hover:hover {
  border-bottom-color: #226f1c !important;
}

.g-brd-primary-bottom--hover:hover::after {
  border-bottom-color: #226f1c;
}

.g-brd-primary-bottom--active.active {
  border-bottom-color: #226f1c !important;
}

.g-brd-primary-bottom--active.active::after {
  border-bottom-color: #226f1c;
}

.g-brd-pinterest {
  border-color: #c8232c !important;
}

.g-brd-pinterest::after {
  border-color: #c8232c !important;
}

.g-brd-pinterest-top--hover:hover {
  border-top-color: #c8232c !important;
}

.g-brd-pinterest-top--hover:hover::after {
  border-top-color: #c8232c;
}

.g-brd-pinterest-top--active.active {
  border-top-color: #c8232c !important;
}

.g-brd-pinterest-top--active.active::after {
  border-top-color: #c8232c;
}

.g-brd-pinterest-bottom--hover:hover {
  border-bottom-color: #c8232c !important;
}

.g-brd-pinterest-bottom--hover:hover::after {
  border-bottom-color: #c8232c;
}

.g-brd-pinterest-bottom--active.active {
  border-bottom-color: #c8232c !important;
}

.g-brd-pinterest-bottom--active.active::after {
  border-bottom-color: #c8232c;
}

/*------------------------------------
  Typography Font Family
------------------------------------*/
.g-font-secondary {
  font-family: "Roboto Slab", Helvetica, Arial, sans-serif;
}

.g-font-code {
  font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

.g-font-niconne {
  font-family: "Niconne", cursive;
}

/*------------------------------------
  Typography Font Size
------------------------------------*/
.g-font-size-default {
  font-size: 1rem !important;
}

.g-font-size-70x {
  font-size: 70% !important;
}

.g-font-size-75x {
  font-size: 75% !important;
}

.g-font-size-80x {
  font-size: 80% !important;
}

.g-font-size-85x {
  font-size: 85% !important;
}

.g-font-size-90x {
  font-size: 90% !important;
}

.g-font-size-95x {
  font-size: 95% !important;
}

.g-font-size-0 {
  font-size: 0;
}

.g-font-size-5 {
  font-size: 0.35714rem !important;
}

.g-font-size-8 {
  font-size: 0.57143rem !important;
}

.g-font-size-9 {
  font-size: 0.64286rem !important;
}

.g-font-size-10 {
  font-size: 0.71429rem !important;
}

.g-font-size-11 {
  font-size: 0.78571rem !important;
}

.g-font-size-12 {
  font-size: 0.85714rem !important;
}

.g-font-size-13 {
  font-size: 0.92857rem !important;
}

.g-font-size-14 {
  font-size: 1rem !important;
}

.g-font-size-15 {
  font-size: 1.07143rem !important;
}

.g-font-size-16 {
  font-size: 1.14286rem !important;
}

.g-font-size-17 {
  font-size: 1.21429rem !important;
}

.g-font-size-18 {
  font-size: 1.28571rem !important;
}

.g-font-size-20 {
  font-size: 1.42857rem !important;
}

.g-font-size-22 {
  font-size: 1.57143rem !important;
}

.g-font-size-23 {
  font-size: 1.64286rem !important;
}

.g-font-size-24 {
  font-size: 1.71429rem !important;
}

.g-font-size-25 {
  font-size: 1.78571rem !important;
}

.g-font-size-26 {
  font-size: 1.85714rem !important;
}

.g-font-size-27 {
  font-size: 1.92857rem !important;
}

.g-font-size-28 {
  font-size: 2rem !important;
}

.g-font-size-30 {
  font-size: 2.14286rem !important;
}

.g-font-size-32 {
  font-size: 2.28571rem !important;
}

.g-font-size-33 {
  font-size: 2.35714rem !important;
}

.g-font-size-35 {
  font-size: 2.5rem !important;
}

.g-font-size-36 {
  font-size: 2.57143rem !important;
}

.g-font-size-38 {
  font-size: 2.71429rem !important;
}

.g-font-size-40 {
  font-size: 2.85714rem !important;
}

.g-font-size-42 {
  font-size: 3rem !important;
}

.g-font-size-45 {
  font-size: 3.21429rem !important;
}

.g-font-size-46 {
  font-size: 3.28571rem !important;
}

.g-font-size-48 {
  font-size: 3.42857rem !important;
}

.g-font-size-50 {
  font-size: 3.57143rem !important;
}

.g-font-size-55 {
  font-size: 3.92857rem !important;
}

.g-font-size-56 {
  font-size: 4rem !important;
}

.g-font-size-60 {
  font-size: 4.28571rem !important;
}

.g-font-size-65 {
  font-size: 4.64286rem !important;
}

.g-font-size-70 {
  line-height: 70px;
  font-size: 5rem !important;
}

.g-font-size-75 {
  font-size: 5.35714rem !important;
}

.g-font-size-76 {
  font-size: 5.42857rem !important;
}

.g-font-size-80 {
  font-size: 5.71429rem !important;
}

.g-font-size-86 {
  font-size: 6.14286rem !important;
}

.g-font-size-90 {
  font-size: 6.42857rem !important;
}

.g-font-size-120 {
  font-size: 8.57143rem !important;
}

.g-font-size-200 {
  line-height: 210px;
  font-size: 14.28571rem !important;
}

.g-font-size-180 {
  font-size: 12.85714rem !important;
}

@media (min-width: 576px) {
  .g-font-size-20--sm {
    font-size: 1.42857rem !important;
  }
  .g-font-size-25--sm {
    font-size: 1.78571rem !important;
  }
  .g-font-size-40--sm {
    font-size: 2.85714rem !important;
  }
  .g-font-size-50--sm {
    font-size: 3.57143rem !important;
  }
  .g-font-size-240--sm {
    font-size: 17.14286rem !important;
  }
}

@media (min-width: 768px) {
  .g-font-size-default--md {
    font-size: 1rem !important;
  }
  .g-font-size-12--md {
    font-size: 0.85714rem !important;
  }
  .g-font-size-16--md {
    font-size: 1.14286rem !important;
  }
  .g-font-size-18--md {
    font-size: 1.28571rem !important;
  }
  .g-font-size-20--md {
    font-size: 1.42857rem !important;
  }
  .g-font-size-24--md {
    font-size: 1.71429rem !important;
  }
  .g-font-size-25--md {
    font-size: 1.78571rem !important;
  }
  .g-font-size-26--md {
    font-size: 1.85714rem !important;
  }
  .g-font-size-27--md {
    font-size: 1.92857rem !important;
  }
  .g-font-size-28--md {
    font-size: 2rem !important;
  }
  .g-font-size-30--md {
    font-size: 2.14286rem !important;
  }
  .g-font-size-32--md {
    font-size: 2.28571rem !important;
  }
  .g-font-size-35--md {
    font-size: 2.5rem !important;
  }
  .g-font-size-36--md {
    font-size: 2.57143rem !important;
  }
  .g-font-size-40--md {
    font-size: 2.85714rem !important;
  }
  .g-font-size-45--md {
    font-size: 3.21429rem !important;
  }
  .g-font-size-46--md {
    font-size: 3.28571rem !important;
  }
  .g-font-size-48--md {
    font-size: 3.42857rem !important;
  }
  .g-font-size-50--md {
    font-size: 3.57143rem !important;
  }
  .g-font-size-55--md {
    font-size: 3.92857rem !important;
  }
  .g-font-size-56--md {
    font-size: 4rem !important;
  }
  .g-font-size-60--md {
    font-size: 4.28571rem !important;
  }
  .g-font-size-65--md {
    font-size: 4.64286rem !important;
  }
  .g-font-size-70--md {
    font-size: 5rem !important;
  }
  .g-font-size-75--md {
    font-size: 5.35714rem !important;
  }
  .g-font-size-76--md {
    font-size: 5.42857rem !important;
  }
  .g-font-size-90--md {
    font-size: 6.42857rem !important;
  }
  .g-font-size-130--md {
    font-size: 9.28571rem !important;
  }
}

@media (min-width: 992px) {
  .g-font-size-default--lg {
    font-size: 1rem !important;
  }
  .g-font-size-16--lg {
    font-size: 1.14286rem !important;
  }
  .g-font-size-18--lg {
    font-size: 1.28571rem !important;
  }
  .g-font-size-26--lg {
    font-size: 1.85714rem !important;
  }
  .g-font-size-32--lg {
    font-size: 2.28571rem !important;
  }
  .g-font-size-35--lg {
    font-size: 2.5rem !important;
  }
  .g-font-size-36--lg {
    font-size: 2.57143rem !important;
  }
  .g-font-size-42--lg {
    font-size: 3rem !important;
  }
  .g-font-size-60--lg {
    font-size: 4.28571rem !important;
  }
  .g-font-size-75--lg {
    font-size: 5.35714rem !important;
  }
  .g-font-size-76--lg {
    font-size: 5.42857rem !important;
  }
  .g-font-size-420--lg {
    font-size: 30rem !important;
  }
}

@media (min-width: 1200px) {
  .g-font-size-17--xl {
    font-size: 1.21429rem !important;
  }
}

@media (max-width: 576px - 1) {
  .g-font-size-25 {
    font-size: 1.78571rem !important;
  }
}

/*------------------------------------
  Typography Font Weight
------------------------------------*/
.g-font-weight-100 {
  font-weight: 100 !important;
}

.g-font-weight-200 {
  font-weight: 200 !important;
}

.g-font-weight-300 {
  font-weight: 300 !important;
}

.g-font-weight-400 {
  font-weight: 400 !important;
}

.g-font-weight-500 {
  font-weight: 500 !important;
}

.g-font-weight-600 {
  font-weight: 600 !important;
}

.g-font-weight-700 {
  font-weight: 700 !important;
}

.g-font-weight-800 {
  font-weight: 800 !important;
}

.g-font-weight-900 {
  font-weight: 900 !important;
}

@media (min-width: 768px) {
  .g-font-weight-100--md {
    font-weight: 100 !important;
  }
  .g-font-weight-200--md {
    font-weight: 200 !important;
  }
  .g-font-weight-300--md {
    font-weight: 300 !important;
  }
  .g-font-weight-400--md {
    font-weight: 400 !important;
  }
  .g-font-weight-500--md {
    font-weight: 500 !important;
  }
  .g-font-weight-600--md {
    font-weight: 600 !important;
  }
  .g-font-weight-700--md {
    font-weight: 700 !important;
  }
  .g-font-weight-800--md {
    font-weight: 800 !important;
  }
  .g-font-weight-900--md {
    font-weight: 900 !important;
  }
}

/*------------------------------------
  Typography Text Transform
------------------------------------*/
.g-text-transform-none {
  text-transform: none !important;
}

/*------------------------------------
  Typography Text Decoration
------------------------------------*/
.g-text-underline {
  text-decoration: underline;
}

.g-text-underline--none--hover:focus, .g-text-underline--none--hover:hover {
  text-decoration: none;
}

.g-text-strike {
  text-decoration: line-through;
}

/*------------------------------------
  Typography Letter Spacing
------------------------------------*/
.g-letter-spacing-minus-2 {
  letter-spacing: -0.14286rem;
}

.g-letter-spacing-0_5 {
  letter-spacing: 0.03571rem;
}

.g-letter-spacing-1_5 {
  letter-spacing: 0.10714rem;
}

.g-letter-spacing-0 {
  letter-spacing: 0px;
}

.g-letter-spacing-1 {
  letter-spacing: 0.07143rem;
}

.g-letter-spacing-2 {
  letter-spacing: 0.14286rem;
}

.g-letter-spacing-3 {
  letter-spacing: 0.21429rem;
}

.g-letter-spacing-4 {
  letter-spacing: 0.28571rem;
}

.g-letter-spacing-5 {
  letter-spacing: 0.35714rem;
}

.g-letter-spacing-6 {
  letter-spacing: 0.42857rem;
}

.g-letter-spacing-7 {
  letter-spacing: 0.5rem;
}

.g-letter-spacing-8 {
  letter-spacing: 0.57143rem;
}

.g-letter-spacing-9 {
  letter-spacing: 0.64286rem;
}

.g-letter-spacing-10 {
  letter-spacing: 0.71429rem;
}

.g-letter-spacing-11 {
  letter-spacing: 0.78571rem;
}

.g-letter-spacing-12 {
  letter-spacing: 0.85714rem;
}

/*------------------------------------
  Typography Line Height
------------------------------------*/
.g-line-height-0 {
  line-height: 0 !important;
}

.g-line-height-0_7 {
  line-height: .7 !important;
}

.g-line-height-0_8 {
  line-height: .8 !important;
}

.g-line-height-0_9 {
  line-height: .9 !important;
}

.g-line-height-1 {
  line-height: 1 !important;
}

.g-line-height-1_1 {
  line-height: 1.1 !important;
}

.g-line-height-1_2 {
  line-height: 1.2 !important;
}

.g-line-height-1_3 {
  line-height: 1.3 !important;
}

.g-line-height-1_4 {
  line-height: 1.4 !important;
}

.g-line-height-1_5 {
  line-height: 1.5 !important;
}

.g-line-height-1_6 {
  line-height: 1.6 !important;
}

.g-line-height-1_8 {
  line-height: 1.8 !important;
}

.g-line-height-2 {
  line-height: 2 !important;
}

/*------------------------------------
  Typography Font Style
------------------------------------*/
.g-font-style-normal {
  font-style: normal;
}

.g-font-style-italic {
  font-style: italic;
}

/*------------------------------------
  List Style Types
------------------------------------*/
.g-list-style-circle {
  list-style-type: circle;
}

.g-list-style-disc {
  list-style-type: disc;
}

.g-list-style-square {
  list-style-type: square;
}

.g-list-style-lower-roman {
  list-style-type: lower-roman;
}

.g-list-style-upper-roman {
  list-style-type: upper-roman;
}

.g-list-style-lower-latin {
  list-style-type: lower-latin;
}

.g-list-style-upper-latin {
  list-style-type: upper-latin;
}

/*------------------------------------
  Text Types
------------------------------------*/
.g-text-break-word {
  word-wrap: break-word;
}

/*------------------------------------
  Quotes
------------------------------------*/
/* Quote v1 */
.u-quote-v1::before {
  content: "\“";
  font-size: 36px;
  line-height: 0.75em;
  text-align: center;
  font-weight: 600;
  display: block;
}

.u-quote-v1::after {
  display: none;
}

/*------------------------------------
  Positions
------------------------------------*/
.g-pos-rel {
  position: relative !important;
}

.g-pos-abs {
  position: absolute !important;
}

.g-pos-stc {
  position: static !important;
}

.g-pos-fix {
  position: fixed !important;
}

@media (min-width: 576px) {
  .g-pos-rel--sm {
    position: relative !important;
  }
  .g-pos-fix--sm {
    position: fixed !important;
  }
  .g-pos-abs--sm {
    position: absolute !important;
  }
  .g-pos-stc--sm {
    position: static !important;
  }
}

@media (min-width: 768px) {
  .g-pos-rel--md {
    position: relative !important;
  }
  .g-pos-fix--md {
    position: fixed !important;
  }
  .g-pos-abs--md {
    position: absolute !important;
  }
  .g-pos-stc--sm {
    position: static !important;
  }
}

@media (min-width: 992px) {
  .g-pos-rel--lg {
    position: relative !important;
  }
  .g-pos-fix--lg {
    position: fixed !important;
  }
  .g-pos-abs--lg {
    position: absolute !important;
  }
  .g-pos-stc--lg {
    position: static !important;
  }
}

/*------------------------------------
  Position Spaces
------------------------------------*/
/* Top */
.g-top-auto {
  /* P */
  top: auto;
}

.g-parent:hover .g-top-auto--parent-hover {
  top: auto;
}

.g-top-0 {
  top: 0 !important;
}

.g-top-0--hover:hover {
  top: 0 !important;
}

.g-parent:hover .g-top-0--parent-hover {
  top: 0 !important;
}

.g-top-1 {
  top: 0.07143rem;
}

.g-top-2 {
  top: 0.14286rem;
}

.g-top-3 {
  top: 0.21429rem;
}

.g-top-5 {
  top: 0.35714rem !important;
}

.g-top-7 {
  top: 0.5rem !important;
}

.g-top-10 {
  top: 0.71429rem !important;
}

.g-top-15 {
  top: 1.07143rem;
}

.g-top-20 {
  top: 1.42857rem;
}

.g-top-25 {
  top: 1.78571rem;
}

.g-top-30 {
  top: 2.14286rem;
}

.g-top-35 {
  top: 2.5rem;
}

.g-top-55 {
  top: 3.92857rem;
}

.g-top-65 {
  top: 4.64286rem;
}

.g-top-100 {
  top: 7.14286rem;
}

.g-top-15x {
  top: 15%;
}

.g-top-20x {
  top: 20%;
}

.g-top-25x {
  top: 25%;
}

.g-top-30x {
  top: 30%;
}

.g-top-35x {
  top: 35%;
}

.g-top-50x {
  top: 50%;
}

.g-top-100x {
  top: 100%;
}

/* Top Minis */
.g-top-minus-1 {
  top: -0.07143rem;
}

.g-top-minus-2 {
  top: -0.14286rem;
}

.g-top-minus-3 {
  top: -0.21429rem !important;
}

.g-top-minus-4 {
  top: -0.28571rem;
}

.g-top-minus-5 {
  top: -0.35714rem;
}

.g-top-minus-6 {
  top: -0.42857rem;
}

.g-top-minus-8 {
  top: -0.57143rem;
}

.g-top-minus-10 {
  top: -0.71429rem;
}

.g-top-minus-15 {
  top: -1.07143rem;
}

.g-top-minus-20 {
  top: -1.42857rem;
}

.g-top-minus-30 {
  top: -2.14286rem;
}

.g-top-minus-35 {
  top: -2.5rem;
}

.g-top-minus-40 {
  top: -2.85714rem;
}

.g-top-minus-70 {
  top: -5rem;
}

.g-top-minus-120 {
  top: -8.57143rem;
}

.g-top-minus-25x {
  top: -25%;
}

.g-top-minus-80x {
  top: -80%;
}

/* Left */
.g-left-auto {
  /* P */
  left: auto !important;
}

.g-left-0 {
  left: 0;
}

.g-left-0--hover:hover {
  left: 0;
}

.g-parent:hover .g-left-0--parent-hover {
  left: 0;
}

.g-left-2 {
  left: 0.14286rem;
}

.g-left-5 {
  left: 0.35714rem;
}

.g-left-10 {
  left: 0.71429rem;
}

.g-left-15 {
  left: 1.07143rem !important;
}

.g-left-20 {
  left: 1.42857rem;
}

.g-left-30 {
  left: 2.14286rem;
}

.g-left-40 {
  left: 2.85714rem;
  /* O */
}

.g-left-45 {
  left: 3.21429rem;
  /* P */
}

.g-left-75 {
  left: 5.35714rem;
}

.g-left-110 {
  left: 7.85714rem;
}

.g-left-130 {
  left: 9.28571rem;
}

.g-left-200 {
  left: 14.28571rem;
}

.g-left-15x {
  left: 15%;
}

.g-left-35x {
  left: 35%;
}

.g-left-50x {
  left: 50%;
}

.g-left-100x {
  left: 100%;
}

/* Left Minus */
.g-left-minus-3 {
  left: -0.21429rem;
}

.g-left-minus-6 {
  left: -0.42857rem;
}

.g-left-minus-7 {
  left: -0.5rem;
}

.g-left-minus-10 {
  left: -0.71429rem;
}

.g-left-minus-15 {
  left: -1.07143rem;
}

.g-left-minus-20 {
  left: -1.42857rem;
}

.g-left-minus-25 {
  left: -1.78571rem;
}

.g-left-minus-30 {
  left: -2.14286rem;
}

.g-left-minus-40 {
  left: -2.85714rem;
}

.g-left-minus-50 {
  left: -3.57143rem;
}

.g-parent:hover .g-left-minus-50--parent-hover {
  left: -3.57143rem;
}

/* Right */
.g-right-auto {
  /* P */
  right: auto;
}

.g-right-0 {
  right: 0 !important;
}

.g-right-0--hover:hover {
  right: 0 !important;
}

.g-parent:hover .g-right-0--parent-hover {
  right: 0 !important;
}

.g-right-5 {
  right: 0.35714rem !important;
}

.g-right-7 {
  right: 0.5rem !important;
}

.g-right-10 {
  right: 0.71429rem !important;
}

.g-right-14 {
  right: 1rem !important;
}

.g-right-15 {
  right: 1.07143rem !important;
}

.g-right-20 {
  right: 1.42857rem;
}

.g-right-30 {
  right: 2.14286rem;
}

.g-right-40 {
  right: 2.85714rem;
}

.g-right-45 {
  right: 3.21429rem;
  /* P */
}

.g-right-55 {
  right: 3.92857rem;
  /* O */
}

.g-right-65 {
  right: 4.64286rem;
}

.g-right-100 {
  right: 7.14286rem;
}

.g-right-110 {
  right: 7.85714rem;
}

.g-right-130 {
  right: 9.28571rem;
}

.g-right-15x {
  right: 15%;
}

.g-right-35x {
  right: 35%;
}

.g-right-50x {
  right: 50%;
}

.g-right-100x {
  right: 100%;
}

/* Right Minus */
.g-right-minus-3 {
  right: -0.21429rem !important;
}

.g-right-minus-6 {
  right: -0.42857rem;
}

.g-right-minus-5 {
  right: -0.35714rem;
}

.g-right-minus-10 {
  right: -0.71429rem;
}

.g-right-minus-13 {
  right: -0.92857rem;
}

.g-right-minus-15 {
  right: -1.07143rem;
}

.g-right-minus-20 {
  right: -1.42857rem;
}

.g-right-minus-25 {
  right: -1.78571rem;
}

.g-right-minus-40 {
  right: -2.85714rem;
}

.g-right-minus-50 {
  right: -3.57143rem;
}

/* Bottom */
.g-bottom-auto {
  /* P */
  bottom: auto;
}

.g-bottom-0 {
  bottom: 0;
}

.g-bottom-0--hover:hover {
  bottom: 0;
}

.g-parent:hover .g-bottom-0--parent-hover {
  bottom: 0;
}

.g-bottom-6 {
  bottom: 0.42857rem;
}

.g-bottom-10 {
  bottom: 0.71429rem;
}

.g-bottom-20 {
  bottom: 1.42857rem;
}

.g-bottom-30 {
  bottom: 2.14286rem;
}

.g-bottom-40 {
  bottom: 2.85714rem;
}

.g-bottom-50 {
  bottom: 3.57143rem;
}

.g-parent:hover .g-bottom-50--parent-hover {
  bottom: 3.57143rem;
}

.g-bottom-60 {
  bottom: 4.28571rem;
}

.g-bottom-80 {
  bottom: 5.71429rem;
}

.g-bottom-minus-20 {
  bottom: -1.42857rem;
}

.g-bottom-minus-30 {
  bottom: -2.14286rem;
}

.g-bottom-minus-40 {
  bottom: -2.85714rem;
}

.g-bottom-minus-70 {
  bottom: -5rem;
}

.g-bottom-minus-35x {
  bottom: -35%;
}

.g-bottom-15x {
  bottom: 15%;
}

.g-bottom-minus-25x {
  bottom: -25%;
}

.g-bottom-100x {
  bottom: 100%;
}

/* Z */
@media (min-width: 576px) {
  .g-top-auto--sm {
    top: auto;
  }
  .g-left-auto--sm {
    left: auto;
  }
  .g-left-minus-20--sm {
    left: -20px;
  }
  .g-right-minus-20--sm {
    right: -20px;
  }
}

/* O */
@media (min-width: 768px) {
  .g-right-0--md {
    right: 0 !important;
  }
  .g-left-0--md {
    left: 0 !important;
  }
  .g-right-minus-15--md {
    right: -1.07143rem;
  }
  .g-left-minus-15--md {
    left: -1.07143rem;
  }
  .g-top-minus-20--md {
    top: -1.42857rem;
  }
  .g-right-minus-25--md {
    right: -1.78571rem;
  }
  .g-right-100--md {
    right: 7.14286rem;
  }
  .g-bottom-minus-50--md {
    bottom: -3.57143rem;
  }
  .g-left-auto--md {
    left: auto !important;
  }
  .g-right-auto--md {
    right: auto !important;
  }
  .g-left-minus-25--md {
    left: -1.78571rem;
  }
  .g-left-130--md {
    left: 9.28571rem !important;
  }
  .g-left-35x--md {
    left: 35%;
  }
  .g-left-50x--md {
    left: 50%;
  }
  .g-right-130--md {
    right: 9.28571rem !important;
  }
  .g-right-35x--md {
    right: 35%;
  }
  .g-right-50x--md {
    right: 50%;
  }
}

@media (min-width: 992px) {
  .g-top-0--lg {
    top: 0 !important;
  }
  .g-right-0--lg {
    right: 0;
  }
  .g-left-minus-35--lg {
    left: -2.5rem;
  }
  .g-left-40--lg {
    left: 2.85714rem;
  }
  .g-right-minus-35--lg {
    right: -2.5rem;
  }
  .g-right-40--lg {
    right: 2.85714rem;
  }
}

/*------------------------------------
  Block Alignments
------------------------------------*/
/* Absolute (Position, X, Y) */
.g-absolute-centered {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
  -webkit-backface-visibility: hidden;
}

.g-absolute-centered--x {
  position: absolute !important;
  left: 50% !important;
  -ms-transform: translateX(-50%);
      transform: translateX(-50%);
  -webkit-backface-visibility: hidden;
}

@media (min-width: 576px) {
  .g-absolute-centered--x--sm {
    position: absolute !important;
    left: 50% !important;
    -ms-transform: translateX(-50%);
        transform: translateX(-50%);
    -webkit-backface-visibility: hidden;
  }
}

@media (min-width: 768px) {
  .g-absolute-centered--x--md {
    position: absolute !important;
    left: 50% !important;
    -ms-transform: translateX(-50%);
        transform: translateX(-50%);
    -webkit-backface-visibility: hidden;
  }
}

.g-absolute-centered--y {
  position: absolute !important;
  top: 50% !important;
  -ms-transform: translateY(-50%);
      transform: translateY(-50%);
  -webkit-backface-visibility: hidden;
}

@media (min-width: 768px) {
  .g-absolute-centered--y--md {
    position: absolute !important;
    top: 50% !important;
    -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    -webkit-backface-visibility: hidden;
  }
}

@media (min-width: 992px) {
  .g-absolute-centered--y--lg {
    position: absolute !important;
    top: 50% !important;
    -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    -webkit-backface-visibility: hidden;
  }
}

/* O */
.g-transform-origin--top-left {
  -ms-transform-origin: top left;
      transform-origin: top left;
}

.g-transform-origin--top-right {
  -ms-transform-origin: top right;
      transform-origin: top right;
}

.g-absolute-centered--y--scl-0_6 {
  position: absolute;
  top: 50%;
  -ms-transform: scale(0.6) translateY(-50%);
      transform: scale(0.6) translateY(-50%);
}

/* Relative (Position, X, Y) */
.g-relative-centered {
  position: relative;
  top: 50% !important;
  left: 50% !important;
  -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
  -webkit-backface-visibility: hidden;
}

.g-relative-centered--x {
  position: relative;
  left: 50% !important;
  -ms-transform: translateX(-50%);
      transform: translateX(-50%);
  -webkit-backface-visibility: hidden;
}

.g-relative-centered--y {
  position: relative;
  top: 50% !important;
  -ms-transform: translateY(-50%);
      transform: translateY(-50%);
  -webkit-backface-visibility: hidden;
}

/* Flex centered */
.g-flex-centered {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
}

.g-flex-centered-item {
  -ms-flex: 1;
      flex: 1;
}

.g-flex-centered-item--top {
  -ms-flex-item-align: start;
      align-self: flex-start;
}

.g-flex-centered-item--bottom {
  -ms-flex-item-align: end;
      align-self: flex-end;
}

/* Flex right */
.g-flex-right--xs {
  -ms-flex-pack: end;
      justify-content: flex-end;
}

/* Flex middle */
.g-flex-middle {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: column nowrap;
      flex-flow: column nowrap;
  -ms-flex-pack: justify;
      justify-content: space-between;
}

.g-flex-middle-item {
  margin-top: auto;
  margin-bottom: auto;
}

.g-flex-middle-item--top {
  margin-top: 0;
  margin-bottom: 0;
}

.g-flex-middle-item--bottom {
  margin-top: auto;
  margin-bottom: 0;
}

.g-flex-middle-item--fixed {
  -ms-flex: none;
      flex: none;
  max-width: 50%;
}

@media (min-width: 768px) {
  /* Flex right */
  .g-flex-right--md {
    -ms-flex-pack: end;
        justify-content: flex-end;
  }
  .g-flex-centered--md {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center;
  }
  .g-absolute-centered--y--scl-1--md {
    position: absolute;
    top: 50%;
    -ms-transform: scale(1) translateY(-50%);
        transform: scale(1) translateY(-50%);
  }
}

@media (min-width: 576px) {
  .g-absolute-centered--sm {
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    -webkit-backface-visibility: hidden;
  }
  .g-absolute-centered--x-sm--reset {
    position: static !important;
    left: auto !important;
    -ms-transform: translateX(0) !important;
        transform: translateX(0) !important;
  }
}

@media (min-width: 768px) {
  .g-absolute-centered--md {
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    -webkit-backface-visibility: hidden;
  }
}

@media (min-width: 992px) {
  .g-absolute-centered--x--lg {
    position: absolute !important;
    left: 50% !important;
    -ms-transform: translateX(-50%);
        transform: translateX(-50%);
    -webkit-backface-visibility: hidden;
  }
  .g-flex-centered--lg {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center;
  }
}

/*------------------------------------
  Alignments
------------------------------------*/
.g-valign-super {
  vertical-align: super !important;
}

.g-valign-sub {
  vertical-align: sub !important;
}

.g-valign-top {
  vertical-align: top !important;
}

.g-valign-middle {
  vertical-align: middle !important;
}

.g-valign-bottom {
  vertical-align: bottom !important;
}

/*------------------------------------
  Blur
------------------------------------*/
.g-blur-30 {
  filter: blur(30px);
}

.g-blur-30--hover:hover {
  filter: blur(30px);
}

.g-parent:hover .g-blur-30--parent-hover {
  filter: blur(30px);
}

/*------------------------------------
  Box-shadows
------------------------------------*/
.g-box-shadow-none {
  box-shadow: none !important;
}

/*------------------------------------
  Clear
------------------------------------*/
.g-clear {
  clear: both;
}

.g-clear--left {
  clear: left;
}

.g-clear--right {
  clear: right;
}

/*------------------------------------
  Cursors
------------------------------------*/
.g-cursor-pointer {
  cursor: pointer;
}

/*------------------------------------
  Overflows
------------------------------------*/
.g-overflow-hidden {
  overflow: hidden !important;
}

.g-overflow-visible {
  overflow: visible;
}

.g-overflow-x-hidden {
  overflow-x: hidden;
}

.g-overflow-x-auto {
  overflow-x: auto !important;
}

.g-overflow-x-scroll {
  overflow-x: scroll;
}

.g-overflow-y-auto {
  overflow-y: auto;
}

.g-overflow-y-hidden {
  overflow-y: hidden;
}

@media (min-width: 768px) {
  .g-overflow-x-visible--md {
    overflow-x: visible;
  }
}

@media (min-width: 992px) {
  .g-overflow-x-visible--lg {
    overflow-x: visible;
  }
}

/*------------------------------------
  Transitions
------------------------------------*/
/*.g-transition {
  &-0_2 {
    transition: .2s ease-out;
  }
  &-0_3 {
    transition: .3s ease-out;
  }
  &-0_6 {
    transition: .6s ease-out;
    &-ease {
      transition: .6s ease;
    }
  }
}

[class*="g-color-"][class*="--hover"] {
  transition: color .2s ease-out;
}
[class*="g-bg-"][class*="--hover"] {
  transition: background-color .3s ease-out;
}
[class*="g-color-"][class*="--hover"][class*="g-bg-"][class*="--hover"] {
  transition: .3s ease-out;
}*/
[class*="g-transition"] {
  transition-property: all;
  transition-timing-function: ease;
  transition-delay: 0s;
}

[class*="g-transition"]::before, [class*="g-transition"]::after {
  transition-property: all;
  transition-timing-function: ease;
  transition-delay: 0s;
}

svg[class*="g-transition"] path,
svg[class*="g-transition"] polygon {
  transition-property: all;
  transition-timing-function: ease;
  transition-delay: 0s;
}

.g-transition-0 {
  transition-duration: 0s;
}

.g-transition-0::before, .g-transition-0::after {
  transition-duration: 0s;
}

svg.g-transition-0 path,
svg.g-transition-0 polygon {
  transition-duration: 0s;
}

.g-transition-0_2 {
  transition-duration: .2s;
}

.g-transition-0_2::before, .g-transition-0_2::after {
  transition-duration: .2s;
}

svg.g-transition-0_2 path,
svg.g-transition-0_2 polygon {
  transition-duration: .2s;
}

.g-transition-0_3 {
  transition-duration: .3s;
}

.g-transition-0_3::before, .g-transition-0_3::after {
  transition-duration: .3s;
}

svg.g-transition-0_3 path,
svg.g-transition-0_3 polygon {
  transition-duration: .3s;
}

.g-transition-0_5 {
  transition-duration: .5s;
}

.g-transition-0_5::before, .g-transition-0_5::after {
  transition-duration: .5s;
}

svg.g-transition-0_5 path,
svg.g-transition-0_5 polygon {
  transition-duration: .5s;
}

.g-transition-0_6 {
  transition-duration: .6s;
}

.g-transition-0_6::before, .g-transition-0_6::after {
  transition-duration: .6s;
}

svg.g-transition-0_6 path,
svg.g-transition-0_6 polygon {
  transition-duration: .6s;
}

.g-transition-delay-0_11 {
  transition-delay: .11s;
}

.g-transition-delay-0_11::before, .g-transition-delay-0_11::after {
  transition-delay: .11s;
}

svg.g-transition-0_11 path,
svg.g-transition-0_11 polygon {
  transition-duration: .11s;
}

.g-transition-delay-0_2 {
  transition-delay: .2s;
}

.g-transition-delay-0_2::before, .g-transition-delay-0_2::after {
  transition-delay: .2s;
}

svg.g-transition-delay-0_2 path,
svg.g-transition-delay-0_2 polygon {
  transition-delay: .2s;
}

.g-transition-delay-0_45 {
  transition-delay: .45s;
}

.g-transition-delay-0_45::before, .g-transition-delay-0_45::after {
  transition-delay: .45s;
}

svg.g-transition-delay-0_45 path,
svg.g-transition-delay-0_45 polygon {
  transition-delay: .45s;
}

.g-transition--ease-out {
  transition-timing-function: ease-out;
}

.g-transition--ease-out::before, .g-transition--ease-out::after {
  transition-timing-function: ease-out;
}

svg.g-transition--ease-out path,
svg.g-transition--ease-out polygon {
  transition-timing-function: ease-out;
}

.g-transition--ease-in {
  transition-timing-function: ease-in;
}

.g-transition--ease-in::before, .g-transition--ease-in::after {
  transition-timing-function: ease-in;
}

svg.g-transition--ease-in path,
svg.g-transition--ease-in polygon {
  transition-timing-function: ease-in;
}

.g-transition--ease-in-out {
  transition-timing-function: ease-in-out;
}

.g-transition--ease-in-out::before, .g-transition--ease-in-out::after {
  transition-timing-function: ease-in-out;
}

svg.g-transition--ease-in-out path,
svg.g-transition--ease-in-out polygon {
  transition-timing-function: ease-in-out;
}

.g-transition--linear {
  transition-timing-function: linear;
}

.g-transition--linear::before, .g-transition--linear::after {
  transition-timing-function: linear;
}

svg.g-transition--linear path,
svg.g-transition--linear polygon {
  transition-timing-function: linear;
}

/*------------------------------------
  Transforms
------------------------------------*/
.g-transform-scale-0_5 {
  -ms-transform: scale(0.5);
      transform: scale(0.5);
}

.g-transform-scale-0_5--hover:hover {
  -ms-transform: scale(0.5);
      transform: scale(0.5);
}

.g-parent:hover .g-transform-scale-0_5--parent-hover {
  -ms-transform: scale(0.5);
      transform: scale(0.5);
}

.g-transform-scale-0_8 {
  -ms-transform: scale(0.8);
      transform: scale(0.8);
}

.g-transform-scale-0_8--hover:hover {
  -ms-transform: scale(0.8);
      transform: scale(0.8);
}

.g-parent:hover .g-transform-scale-0_8--parent-hover {
  -ms-transform: scale(0.8);
      transform: scale(0.8);
}

.g-transform-scale-0_85 {
  -ms-transform: scale(0.85);
      transform: scale(0.85);
}

.g-transform-scale-0_85--hover:hover {
  -ms-transform: scale(0.85);
      transform: scale(0.85);
}

.g-parent:hover .g-transform-scale-0_85--parent-hover {
  -ms-transform: scale(0.85);
      transform: scale(0.85);
}

.g-transform-scale-0_9 {
  -ms-transform: scale(0.9);
      transform: scale(0.9);
}

.g-transform-scale-0_9--hover:hover {
  -ms-transform: scale(0.9);
      transform: scale(0.9);
}

.g-parent:hover .g-transform-scale-0_9--parent-hover {
  -ms-transform: scale(0.9);
      transform: scale(0.9);
}

.g-transform-scale-1 {
  -ms-transform: scale(1);
      transform: scale(1);
}

.g-transform-scale-1--hover:hover {
  -ms-transform: scale(1);
      transform: scale(1);
}

.g-parent:hover .g-transform-scale-1--parent-hover {
  -ms-transform: scale(1);
      transform: scale(1);
}

.g-transform-scale-1_05 {
  transform: scale3d(1.05, 1.05, 1.05);
}

.g-transform-scale-1_05--hover:hover {
  transform: scale3d(1.05, 1.05, 1.05);
  z-index: 2;
}

.g-parent:hover .g-transform-scale-1_05--parent-hover {
  transform: scale3d(1.1, 1.1, 1.1);
}

.g-transform-scale-1_1 {
  transform: scale3d(1.1, 1.1, 1.1);
}

.g-transform-scale-1_1--hover:hover {
  transform: scale3d(1.1, 1.1, 1.1);
  z-index: 2;
}

.g-parent:hover .g-transform-scale-1_1--parent-hover {
  transform: scale3d(1.1, 1.1, 1.1);
}

.g-transform-scale-1_2 {
  -ms-transform: scale(1.2);
      transform: scale(1.2);
}

.g-transform-scale-1_2--hover:hover {
  -ms-transform: scale(1.2);
      transform: scale(1.2);
}

.g-parent:hover .g-transform-scale-1_2--parent-hover {
  -ms-transform: scale(1.2);
      transform: scale(1.2);
}

.g-transform-translate-x-100x {
  -ms-transform: translateX(100%);
      transform: translateX(100%);
}

.g-transform-translate-x-100x--hover:hover {
  -ms-transform: translateX(100%);
      transform: translateX(100%);
}

.g-parent:hover .g-transform-translate-x-100x--parent-hover {
  -ms-transform: translateX(100%);
      transform: translateX(100%);
}

.g-transform-translate-x-minus-100x {
  -ms-transform: translateX(-100%);
      transform: translateX(-100%);
}

.g-transform-translate-x-minus-100x--hover:hover {
  -ms-transform: translateX(-100%);
      transform: translateX(-100%);
}

.g-parent:hover .g-transform-translate-x-minus-100x--parent-hover {
  -ms-transform: translateX(-100%);
      transform: translateX(-100%);
}

.g-transform-translate-y-5 {
  -ms-transform: translateY(5px);
      transform: translateY(5px);
}

.g-transform-translate-y-5--hover:hover {
  -ms-transform: translateY(5px);
      transform: translateY(5px);
}

.g-parent:hover .g-transform-translate-y-5--parent-hover {
  -ms-transform: translateY(5px);
      transform: translateY(5px);
}

.g-transform-translate-y-100x {
  -ms-transform: translateY(100%);
      transform: translateY(100%);
}

.g-transform-translate-y-100x--hover:hover {
  -ms-transform: translateY(100%);
      transform: translateY(100%);
}

.g-parent:hover .g-transform-translate-y-100x--parent-hover {
  -ms-transform: translateY(100%);
      transform: translateY(100%);
}

.g-transform-translate-y-minus-5 {
  -ms-transform: translateY(-5px);
      transform: translateY(-5px);
}

.g-transform-translate-y-minus-5--hover:hover {
  -ms-transform: translateY(-5px);
      transform: translateY(-5px);
}

.g-parent:hover .g-transform-translate-y-minus-5--parent-hover {
  -ms-transform: translateY(-5px);
      transform: translateY(-5px);
}

.g-transform-translate-y-minus-70 {
  -ms-transform: translateY(-70px);
      transform: translateY(-70px);
}

.g-transform-translate-y-minus-70--hover:hover {
  -ms-transform: translateY(-70px);
      transform: translateY(-70px);
}

.g-parent:hover .g-transform-translate-y-minus-70--parent-hover {
  -ms-transform: translateY(-70px);
      transform: translateY(-70px);
}

.g-transform-translate-y-minus-100x {
  -ms-transform: translateY(-100%);
      transform: translateY(-100%);
}

.g-transform-translate-y-minus-100x--hover:hover {
  -ms-transform: translateY(-100%);
      transform: translateY(-100%);
}

.g-parent:hover .g-transform-translate-y-minus-100x--parent-hover {
  -ms-transform: translateY(-100%);
      transform: translateY(-100%);
}

/*------------------------------------
  Opacity
------------------------------------*/
.opacity-0 {
  opacity: 0 !important;
}

.g-opacity-0_3 {
  opacity: .3 !important;
}

.g-opacity-0_4 {
  opacity: .4 !important;
}

.g-opacity-0_5 {
  opacity: .5 !important;
}

.g-opacity-0_6 {
  opacity: .6 !important;
}

.g-opacity-0_7 {
  opacity: .7 !important;
}

.g-opacity-0_8 {
  opacity: .8 !important;
}

.g-opacity-1 {
  opacity: 1 !important;
}

.opacity-0--hover:hover {
  opacity: 0 !important;
}

.g-parent:hover .opacity-0--parent-hover {
  opacity: 0 !important;
}

.g-opacity-0_3--hover:hover {
  opacity: .3 !important;
}

.g-parent:hover .g-opacity-0_3--parent-hover {
  opacity: .3 !important;
}

.g-opacity-0_5--hover:hover {
  opacity: .5 !important;
}

.g-parent:hover .g-opacity-0_5--parent-hover {
  opacity: .5 !important;
}

.g-opacity-0_6--hover:hover {
  opacity: .6 !important;
}

.g-parent:hover .g-opacity-0_6--parent-hover {
  opacity: .6 !important;
}

.g-opacity-0_7--hover:hover {
  opacity: .7 !important;
}

.g-parent:hover .g-opacity-0_7--parent-hover {
  opacity: .7 !important;
}

.g-opacity-0_8--hover:hover {
  opacity: .8 !important;
}

.g-parent:hover .g-opacity-0_8--parent-hover {
  opacity: .8 !important;
}

.u-block-hover:hover .g-opacity-1--hover, .g-opacity-1--hover:hover {
  opacity: 1 !important;
}

.g-parent:hover .g-opacity-1--parent-hover {
  opacity: 1 !important;
}

.selected .g-opacity-1--parents-selected {
  opacity: 1 !important;
}

/*------------------------------------
  Z-Index
------------------------------------*/
.g-z-index-auto {
  z-index: auto;
}

.g-z-index-1 {
  z-index: 1;
}

.g-z-index-2 {
  z-index: 2;
}

.g-z-index-3 {
  z-index: 3;
}

.g-z-index-3--hover:hover {
  z-index: 3;
}

.g-z-index-4 {
  z-index: 4;
}

.g-z-index-4--hover:hover {
  z-index: 4;
}

.g-z-index-99 {
  z-index: 99;
}

.g-z-index-9999 {
  z-index: 9999;
}

.g-z-index-minus-1 {
  z-index: -1;
}

/*------------------------------------
  Resize
------------------------------------*/
.g-resize-none {
  resize: none;
}

/*------------------------------------
  Placeholder
------------------------------------*/
.g-placeholder-white:-ms-input-placeholder {
  opacity: 1;
  color: #fff;
}
.g-placeholder-white::placeholder {
  opacity: 1;
  color: #fff;
}

.g-placeholder-primary:-ms-input-placeholder {
  color: #fff;
}

.g-placeholder-primary::placeholder {
  color: #fff;
}

.g-placeholder-inherit:-ms-input-placeholder {
  color: inherit;
  opacity: 1;
}

.g-placeholder-inherit::placeholder {
  color: inherit;
  opacity: 1;
}

.g-placeholder-inherit::-moz-placeholder {
  color: inherit;
  opacity: 1;
}

.g-placeholder-inherit::-webkit-input-placeholder {
  color: inherit;
  opacity: 1;
}

/*------------------------------------
  Offsets
------------------------------------*/
@media (min-width: 768px) {
  .g-offset-md-1 {
    margin-left: 8.333333%;
  }
}

@media (min-width: 992px) {
  .g-offset-lg-1 {
    margin-left: 8.333333%;
  }
  .g-offset-lg-4 {
    margin-left: 33.333333%;
  }
}

/*------------------------------------
  Colors
------------------------------------*/
/* Basic Colors
------------------------------------*/
/* Inherit Colors */
.g-color-inherit {
  color: inherit !important;
}

/* Main Colors */
.g-color-main {
  color: #999 !important;
}

.g-color-main--hover:hover {
  color: #999 !important;
}

/* Primary Colors */
.g-color-primary {
  color: #226f1c !important;
}

.u-block-hover:hover .g-color-primary--hover, .g-color-primary--hover:hover {
  color: #226f1c !important;
}

.g-parent:hover .g-color-primary--parent-hover {
  color: #226f1c !important;
}

.g-color-primary-opacity-0_3 {
  color: rgba(34, 111, 28, 0.3) !important;
}

.g-color-primary-opacity-0_4 {
  color: rgba(34, 111, 28, 0.4) !important;
}

.g-color-primary--active.active {
  color: #226f1c !important;
}

.active .g-color-primary--parent-active {
  color: #226f1c !important;
}

.g-color-primary--before::before, .g-color-primary--after::after {
  color: #226f1c;
}

/* Secondary Colors */
.g-color-secondary {
  color: #e74b3c !important;
}

.u-block-hover:hover .g-color-secondary--hover, .g-color-secondary--hover:hover {
  color: #e74b3c !important;
}

.g-parent:hover .g-color-secondary--parent-hover {
  color: #e74b3c !important;
}

/* Black Colors */
.g-color-black {
  color: #000 !important;
}

.g-color-black--hover:hover {
  color: #000 !important;
}

.g-parent:hover .g-color-black--parent-hover {
  color: #000 !important;
}

.g-color-black--active.active {
  color: #000 !important;
}

.g-color-black-opacity-0_1 {
  color: rgba(0, 0, 0, 0.1) !important;
}

.g-color-black-opacity-0_3 {
  color: rgba(0, 0, 0, 0.3) !important;
}

.g-color-black-opacity-0_5 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.g-color-black-opacity-0_6 {
  color: rgba(0, 0, 0, 0.6) !important;
}

.g-color-black-opacity-0_7 {
  color: rgba(0, 0, 0, 0.7) !important;
}

.g-color-black-opacity-0_8 {
  color: rgba(0, 0, 0, 0.8) !important;
}

.g-color-black-opacity-0_8--child * {
  color: rgba(0, 0, 0, 0.8) !important;
}

.g-color-black-opacity-0_9 {
  color: rgba(0, 0, 0, 0.9) !important;
}

/* White Colors */
.g-color-white {
  color: #fff !important;
}

.g-color-white--opened-menu:not(.collapsed) {
  color: #fff !important;
}

.u-block-hover:hover .g-color-white--hover, .g-color-white--hover:hover {
  color: #fff !important;
}

.g-parent:hover .g-color-white--parent-hover {
  color: #fff !important;
}

.g-color-white--active.active {
  color: #fff !important;
}

.active .g-color-white--parent-active {
  color: #fff !important;
}

.g-color-white--child * {
  color: #fff !important;
}

.g-color-white-opacity-0_1 {
  color: rgba(255, 255, 255, 0.1) !important;
}

.g-color-white-opacity-0_2 {
  color: rgba(255, 255, 255, 0.2) !important;
}

.g-color-white-opacity-0_3 {
  color: rgba(255, 255, 255, 0.3) !important;
}

.g-color-white-opacity-0_5, .g-color-white-opacity-0_5--hover:hover {
  color: rgba(255, 255, 255, 0.5) !important;
}

.g-color-white-opacity-0_6 {
  color: rgba(255, 255, 255, 0.6) !important;
}

.g-parent:hover .g-color-white-opacity-0_6--parent-hover {
  color: rgba(255, 255, 255, 0.6) !important;
}

.g-color-white-opacity-0_7, .g-color-white-opacity-0_7--hover:hover {
  color: rgba(255, 255, 255, 0.7) !important;
}

.g-color-white-opacity-0_75 {
  color: rgba(255, 255, 255, 0.75) !important;
}

.g-color-white-opacity-0_8 {
  color: rgba(255, 255, 255, 0.8) !important;
}

.g-color-white-opacity-0_8--child * {
  color: rgba(255, 255, 255, 0.8) !important;
}

.g-color-white-opacity-0_9, .g-color-white-opacity-0_9--hover:hover {
  color: rgba(255, 255, 255, 0.9) !important;
}

.u-block-hover:hover .g-color-white-opacity-0_7--hover {
  color: rgba(255, 255, 255, 0.7) !important;
}

/* Gray Colors */
.g-color-gray-light-v1 {
  color: #bbb !important;
}

.g-color-gray-light-v1--hover:hover {
  color: #bbb !important;
}

.g-color-gray-light-v2 {
  color: #ccc !important;
}

.g-color-gray-light-v2--hover:hover {
  color: #ccc !important;
}

.g-color-gray-light-v3 {
  color: #ddd !important;
}

.g-color-gray-light-v3--hover:hover {
  color: #ddd !important;
}

.g-color-gray-light-v4 {
  color: #eee !important;
}

.g-color-gray-light-v4--hover:hover {
  color: #eee !important;
}

.g-color-gray-light-v4-opacity-0_6 {
  color: rgba(238, 238, 238, 0.6) !important;
}

.g-color-gray-light-v5 {
  color: #f7f7f7 !important;
}

.g-color-gray-light-v5--hover:hover {
  color: #f7f7f7 !important;
}

.g-color-gray-dark-v1 {
  color: #111 !important;
}

.g-color-gray-dark-v1--hover:hover {
  color: #111 !important;
}

.g-color-gray-dark-v2 {
  color: #333 !important;
}

.g-color-gray-dark-v2--hover:hover {
  color: #333 !important;
}

.g-color-gray-dark-v2-opacity-0_75 {
  color: rgba(51, 51, 51, 0.75) !important;
}

.g-color-gray-dark-v3 {
  color: #555 !important;
}

.g-color-gray-dark-v3--hover:hover {
  color: #555 !important;
}

.g-color-gray-dark-v4 {
  color: #777 !important;
}

.g-color-gray-dark-v4--hover:hover {
  color: #777 !important;
}

.g-color-gray-dark-v5 {
  color: #999 !important;
}

.g-color-gray-dark-v5--hover:hover {
  color: #999 !important;
}

.g-parent:hover .g-color-gray-dark-v5--parent-hover {
  color: #999 !important;
}

/* Complementary Colors
------------------------------------*/
/* Color Green */
.g-color-green {
  color: #72c02c !important;
}

.g-color-green--hover:hover {
  color: #72c02c !important;
}

/* Color Blue */
.g-color-blue {
  color: #3398dc !important;
}

.g-color-blue--hover:hover {
  color: #3398dc !important;
}

.g-color-blue-dark-v1 {
  color: #175a88 !important;
}

/* Color Light Blue */
.g-color-lightblue {
  color: #edf2f8 !important;
}

.g-color-lightblue--hover:hover {
  color: #edf2f8 !important;
}

.g-color-lightblue-v1 {
  color: #d6e2ee !important;
}

.g-color-lightblue-v1--hover:hover {
  color: #d6e2ee !important;
}

/* Color Dark Blue */
.g-color-darkblue {
  color: #009 !important;
}

.g-color-darkblue--hover:hover {
  color: #009 !important;
}

/* Color Indigo */
.g-color-indigo {
  color: #4263a3 !important;
}

.g-color-indigo--hover:hover {
  color: #4263a3 !important;
}

/* Color Red */
.g-color-red {
  color: #f00 !important;
}

.g-color-red--hover:hover {
  color: #f00 !important;
}

*:hover > .g-color-red--parent-hover {
  color: #f00 !important;
}

/* Color Light Red */
.g-color-lightred {
  color: #e64b3b !important;
}

.g-color-lightred--hover:hover {
  color: #e64b3b !important;
}

/* Color Dark Red */
.g-color-darkred {
  color: #a10f2b !important;
}

.g-color-darkred--hover:hover {
  color: #a10f2b !important;
}

/* Color Purple */
.g-color-purple {
  color: #9a69cb;
}

.g-color-purple--hover:hover {
  color: #9a69cb !important;
}

.g-color-purple-dark-v1 {
  color: #552c7e !important;
}

/* Color Dark Purple */
.g-color-darkpurple {
  color: #6639b6 !important;
}

.g-color-darkpurple--hover:hover {
  color: #6639b6 !important;
}

/* Color Pink */
.g-color-pink {
  color: #e81c62;
}

.g-color-pink--hover:hover {
  color: #e81c62 !important;
}

.g-color-pink-dark-v1 {
  color: #6f0b2d !important;
}

/* Color Orange */
.g-color-orange {
  color: #e57d20 !important;
}

.g-color-orange--hover:hover {
  color: #e57d20 !important;
}

/* Color Deep Orange */
.g-color-deeporange {
  color: #fe541e !important;
}

.g-color-deeporange--hover:hover {
  color: #fe541e !important;
}

/* Color Yellow */
.g-color-yellow {
  color: #ebc71d !important;
}

.g-color-yellow--hover:hover {
  color: #ebc71d !important;
}

/* Color Aqua */
.g-color-aqua {
  color: #29d6e6;
}

.g-color-aqua--hover:hover {
  color: #29d6e6 !important;
}

.g-color-aqua-dark-v1 {
  color: #11848e !important;
}

/* Color Cyan */
.g-color-cyan {
  color: #00bed6 !important;
}

.g-color-cyan--hover:hover {
  color: #00bed6 !important;
}

/* Color Teal */
.g-color-teal {
  color: #18ba9b !important;
}

.g-color-teal--hover:hover {
  color: #18ba9b !important;
}

/* Color Brown */
.g-color-brown {
  color: #9c8061 !important;
}

.g-color-brown--hover:hover {
  color: #9c8061 !important;
}

/* Color Blue Gray */
.g-color-bluegray {
  color: #585f69 !important;
}

.g-color-bluegray--hover:hover {
  color: #585f69 !important;
}

/*------------------------------------
  Social Colors
------------------------------------*/
/* Facebook */
.g-color-facebook {
  color: #3b5998;
}

.g-color-facebook:hover {
  color: #344e86;
}

.g-color-facebook--hover:hover {
  color: #3b5998 !important;
}

/* Twitter */
.g-color-twitter {
  color: #00acee;
}

.g-color-twitter:hover {
  color: #009ad5;
}

.g-color-twitter--hover:hover {
  color: #00acee !important;
}

/* Skype */
.g-color-skype {
  color: #00aff0;
}

.g-color-skype:hover {
  color: #009cd7;
}

.g-color-skype--hover:hover {
  color: #00aff0 !important;
}

/* Pinterest */
.g-color-pinterest {
  color: #c8232c;
}

.g-color-pinterest:hover {
  color: #b21f27;
}

.g-color-pinterest--hover:hover {
  color: #c8232c !important;
}

/* Vine */
.g-color-vine {
  color: #00bf8f;
}

.g-color-vine:hover {
  color: #00a67c;
}

.g-color-vine--hover:hover {
  color: #00bf8f !important;
}

/* Youtube */
.g-color-youtube {
  color: #c4302b;
}

.g-color-youtube:hover {
  color: #af2b26;
}

.g-color-youtube--hover:hover {
  color: #c4302b !important;
}

/* Google plus */
.g-color-google-plus {
  color: #dd4b39;
}

.g-color-google-plus:hover {
  color: #d73925;
}

.g-color-google-plus--hover:hover {
  color: #dd4b39 !important;
}

/* Dribbble */
.g-color-dribbble {
  color: #ea4c89;
}

.g-color-dribbble:hover {
  color: #e7357a;
}

.g-color-dribbble--hover:hover {
  color: #ea4c89 !important;
}

/* VK */
.g-color-vk {
  color: #2b587a;
}

.g-color-vk:hover {
  color: #244a67;
}

.g-color-vk--hover:hover {
  color: #2b587a !important;
}

/* Linkedin */
.g-color-linkedin {
  color: #0e76a8;
}

.g-color-linkedin:hover {
  color: #0c6590;
}

.g-color-linkedin--hover:hover {
  color: #0e76a8 !important;
}

/* Instagram */
.g-color-instagram {
  color: #3f729b;
}

.g-color-instagram:hover {
  color: #386589;
}

.g-color-instagram--hover:hover {
  color: #3f729b !important;
}

/*------------------------------------
  Gradient Colors
------------------------------------*/
/* Cyan Gradient */
.g-color-cyan-gradient-opacity-v1 {
  background-repeat: repeat-x;
  background-image: linear-gradient(160deg, rgba(0, 0, 153, 0.55), rgba(0, 190, 214, 0.6));
  background-clip: text;
  -webkit-background-clip: text;
  text-fill-color: transparent;
  -webkit-text-fill-color: transparent;
}

/* Blue Gradient */
.g-color-blue-gradient-opacity-v1 {
  background-repeat: repeat-x;
  background-image: linear-gradient(-260deg, #8654da, rgba(66, 229, 248, 0.8));
  background-clip: text;
  -webkit-background-clip: text;
  text-fill-color: transparent;
  -webkit-text-fill-color: transparent;
}

/*------------------------------------
  Widths
------------------------------------*/
/* Width in Percentage (%) */
.g-width-30x {
  width: 30% !important;
  /* P */
}

.g-width-40x {
  width: 40% !important;
  /* P */
}

.g-width-50x {
  width: 50% !important;
  /* P */
}

.g-width-60x {
  width: 60% !important;
  /* P */
}

.g-width-70x {
  width: 70% !important;
  /* P */
}

.g-width-80x {
  width: 80% !important;
  /* P */
}

.g-width-90x {
  width: 90% !important;
  /* P */
}

@media (max-width: 446px - 1) {
  .w-100--2xs {
    width: 100% !important;
  }
}

@media (min-width: 576px) {
  .g-width-20x--sm {
    width: 20% !important;
  }
  .g-width-25x--sm {
    width: 25% !important;
  }
  .g-width-33_3x--sm {
    width: 33.333333% !important;
  }
  .g-width-40x--sm {
    width: 40% !important;
  }
  .g-width-60x--sm {
    width: 60% !important;
  }
  .g-width-16_6x--sm {
    width: 16.666666% !important;
  }
  .g-width-66_6x--sm {
    width: 66.666666% !important;
  }
}

@media (min-width: 768px) {
  /* P */
  .g-width-20x--md {
    width: 20% !important;
    /* P */
  }
  .g-width-25x--md {
    width: 25% !important;
    /* P */
  }
  .g-width-30x--md {
    width: 30% !important;
    /* P */
  }
  .g-width-35x--md {
    width: 35% !important;
    /* P */
  }
  .g-width-40x--md {
    width: 40% !important;
    /* P */
  }
  .g-width-45x--md {
    width: 45% !important;
    /* P */
  }
  .g-width-50x--md {
    width: 50% !important;
    /* P */
  }
  .g-width-55x--md {
    width: 55% !important;
    /* P */
  }
  .g-width-60x--md {
    width: 60% !important;
    /* P */
  }
  .g-width-65x--md {
    width: 65% !important;
    /* P */
  }
  .g-width-70x--md {
    width: 70% !important;
    /* P */
  }
  .g-width-75x--md {
    width: 75% !important;
    /* P */
  }
  .g-width-80x--md {
    width: 80% !important;
    /* P */
  }
  .g-width-85x--md {
    width: 85% !important;
    /* P */
  }
  .g-width-90x--md {
    width: 90% !important;
    /* P */
  }
  .g-width-33_3x--md {
    /* P */
    width: 33.333333% !important;
  }
  .g-width-16_6x--md {
    /* P */
    width: 16.666666% !important;
  }
  .g-width-66_6x--md {
    /* P */
    width: 66.666666% !important;
  }
}

.g-width-auto {
  width: auto !important;
  /* P */
}

/* Max Width in Percentage (%) */
.g-width-1x {
  max-width: 1%;
}

.g-width-80x {
  max-width: 80%;
}

.g-width-90x {
  max-width: 90%;
}

.g-width-95x {
  width: 95%;
}

.g-max-width-60x {
  max-width: 60%;
}

.g-max-width-100x {
  max-width: 100%;
}

/* Min Width in Percentage (%) */
.g-min-width-100x {
  min-width: 100%;
}

/* Width Viewport Width (vw) */
.g-width-50vw {
  width: 50vw !important;
  /* P */
}

.g-width-100vw {
  width: 100vw !important;
  /* P */
}

/* Width in Pixels (px) */
.g-width-3 {
  width: 3px !important;
  /* O */
}

.g-width-10 {
  width: 10px !important;
  /* O */
}

.g-width-12 {
  width: 12px !important;
  /* Z */
}

.g-width-16 {
  width: 16px !important;
  /* O */
}

.g-width-18 {
  width: 18px !important;
  /* O */
}

.g-width-20 {
  width: 20px !important;
  /* O */
}

.g-width-24 {
  width: 24px !important;
  /* O */
}

.g-width-25 {
  width: 25px !important;
}

.g-width-26 {
  width: 26px !important;
  /* P */
}

.g-width-28 {
  width: 28px !important;
  /* O */
}

.g-width-30 {
  width: 30px !important;
  /* P */
}

.g-width-32 {
  width: 32px !important;
  /* O */
}

.g-width-35 {
  width: 35px !important;
  /* P */
}

.g-width-36 {
  width: 36px !important;
  /* O */
}

.g-width-40 {
  width: 40px !important;
  /* P */
}

.g-width-45 {
  width: 45px !important;
  /* P */
}

.g-width-48 {
  width: 48px !important;
  /* P */
}

.g-width-50 {
  width: 50px !important;
  /* P */
}

.g-width-54 {
  width: 54px !important;
  /* P */
}

.g-width-55 {
  width: 55px !important;
  /* P */
}

.g-width-60 {
  width: 60px !important;
  /* P */
}

.g-width-64 {
  width: 64px !important;
  /* P */
}

.g-width-70 {
  width: 70px !important;
  /* P */
}

.g-width-75 {
  width: 75px !important;
  /* O */
}

.g-width-80 {
  width: 80px !important;
  /* P */
}

.g-width-85 {
  width: 85px !important;
  /* P */
}

.g-width-95 {
  width: 95px !important;
  /* P */
}

.g-width-100 {
  width: 100px !important;
  /* P */
}

.g-width-105 {
  width: 105px;
}

.g-width-110 {
  width: 110px !important;
  /* O */
}

.g-width-115 {
  width: 115px !important;
  /* O */
}

.g-width-120 {
  width: 120px !important;
  /* P */
}

.g-width-125 {
  width: 125px !important;
  /* P */
}

.g-width-130 {
  width: 130px !important;
  /* P */
}

.g-width-135 {
  width: 135px !important;
  /* O */
}

.g-width-140 {
  width: 140px !important;
  /* P */
}

.g-width-150 {
  width: 150px !important;
}

.g-width-160 {
  width: 160px !important;
  /* P */
}

.g-width-170 {
  width: 170px !important;
  /* P */
}

.g-width-180 {
  width: 180px !important;
  /* O */
}

.g-width-200 {
  width: 200px !important;
  /* P */
}

.g-width-220 {
  width: 220px !important;
}

.g-width-215 {
  width: 215px !important;
}

.g-width-235 {
  width: 235px !important;
}

.g-width-250 {
  /* RG-Q */
  width: 250px !important;
  /* P */
  max-width: 100%;
}

.g-width-270 {
  width: 270px !important;
  /* O */
  max-width: 100%;
}

.g-width-300 {
  width: 300px !important;
  /* P */
  max-width: 100%;
}

.g-width-400 {
  width: 400px !important;
  /* O */
  max-width: 100%;
}

.g-width-340 {
  width: 340px !important;
  /* O */
  max-width: 100%;
}

.g-width-360 {
  width: 360px !important;
  /* O */
  max-width: 100%;
}

.g-width-370 {
  width: 370px !important;
  /* P */
  max-width: 100%;
}

.g-width-380 {
  width: 380px !important;
  /* O */
  max-width: 100%;
}

.g-width-410 {
  width: 410px !important;
  /* O */
}

.g-width-460 {
  width: 460px !important;
  /* O */
  max-width: 100%;
}

.g-width-465 {
  width: 465px !important;
  /* O */
  max-width: 100%;
}

.g-width-485 {
  width: 485px !important;
  /* O */
  max-width: 100%;
}

.g-width-560 {
  width: 560px !important;
  /* O */
  max-width: 100%;
}

.g-width-590 {
  width: 590px !important;
  max-width: 100%;
}

.g-width-600 {
  width: 600px !important;
  /* O */
  max-width: 100%;
}

.g-width-630 {
  width: 630px !important;
  /* O */
  max-width: 100%;
}

.g-width-680 {
  width: 680px !important;
  /* O */
  max-width: 100%;
}

.g-width-720 {
  width: 720px !important;
  /* O */
  max-width: 100%;
}

.g-width-760 {
  width: 760px !important;
  max-width: 100%;
}

.g-width-770 {
  width: 770px !important;
  max-width: 100%;
}

.g-width-780 {
  /* RG-Q */
  width: 780px !important;
  /* P */
  max-width: 100%;
}

.g-width-900 {
  width: 900px !important;
  /* P */
  max-width: 100%;
}

.g-width-945 {
  width: 945px !important;
  max-width: 100%;
}

.g-width-1025 {
  width: 1025px !important;
  max-width: 100%;
}

/* Max Width in Pixels (px) */
.g-max-width-100 {
  max-width: 100px;
}

.g-max-width-120 {
  max-width: 120px;
}

.g-max-width-170 {
  max-width: 170px;
  /* O */
}

.g-max-width-190 {
  max-width: 190px;
}

.g-max-width-200 {
  max-width: 200px;
}

.g-max-width-250 {
  max-width: 250px;
}

.g-max-width-300 {
  max-width: 300px;
}

.g-max-width-400 {
  max-width: 400px;
}

.g-max-width-500 {
  max-width: 500px;
}

.g-max-width-540 {
  max-width: 540px;
}

.g-max-width-550 {
  max-width: 550px;
}

.g-max-width-570 {
  max-width: 570px;
}

.g-max-width-600 {
  max-width: 600px;
}

.g-max-width-645 {
  max-width: 645px;
}

.g-max-width-670 {
  max-width: 670px;
}

.g-max-width-700 {
  max-width: 700px;
}

.g-max-width-750 {
  max-width: 750px;
}

.g-max-width-770 {
  max-width: 770px;
}

.g-max-width-780 {
  max-width: 780px;
}

.g-max-width-800 {
  max-width: 800px;
}

.g-max-width-840 {
  max-width: 840px;
}

.g-max-width-870 {
  max-width: 870px;
}

.g-max-width-960 {
  max-width: 960px;
}

/* Min Width in Pixels (px) */
.g-min-width-35 {
  min-width: 35px;
}

.g-min-width-40 {
  min-width: 40px;
}

.g-min-width-65 {
  min-width: 65px;
}

.g-min-width-110 {
  min-width: 110px;
}

.g-min-width-130 {
  min-width: 130px;
}

.g-min-width-150 {
  min-width: 150px;
}

.g-min-width-170 {
  min-width: 170px;
}

.g-min-width-200 {
  min-width: 200px;
}

.g-min-width-220 {
  min-width: 220px;
}

.g-min-width-300 {
  min-width: 300px;
}

.g-min-width-400 {
  min-width: 400px;
}

@media (min-width: 576px) {
  .g-width-50x--sm {
    width: 50% !important;
    /* P */
  }
  .g-width-80x--sm {
    width: 80% !important;
    /* R */
  }
  .g-width-100--sm {
    width: 100px !important;
    /* P */
  }
  .g-width-150--sm {
    width: 150px !important;
    /* O */
  }
  .g-width-170--sm {
    width: 170px !important;
    /* O */
  }
  .g-width-180--sm {
    width: 180px !important;
  }
  .g-min-width-180--sm {
    min-width: 180px;
  }
  .g-width-485--sm {
    width: 485px !important;
    /* O */
    max-width: 100%;
  }
  .g-width-auto--sm {
    width: auto !important;
  }
  .g-max-width-300--sm {
    max-width: 300px;
  }
}

@media (min-width: 768px) {
  .g-width-40--md {
    width: 40px !important;
    /* O */
  }
  .g-width-45--md {
    width: 45px !important;
    /* O */
  }
  .g-width-60--md {
    width: 60px !important;
    /* O */
  }
  .g-width-80--md {
    width: 80px !important;
    /* O */
  }
  .g-width-50x--md {
    width: 50% !important;
    /* P */
  }
  .g-width-60x--md {
    width: 60% !important;
    /* P */
  }
  .g-width-80x--md {
    width: 80% !important;
    /* R */
  }
  .g-width-45--md {
    width: 45px !important;
  }
  .g-width-100--md {
    width: 100px !important;
    /* O */
  }
  .g-width-125--md {
    width: 125px !important;
    /* P */
  }
  .g-width-135--md {
    width: 135px !important;
  }
  .g-width-140--md {
    width: 140px !important;
    /* R */
  }
  .g-width-155--md {
    width: 155px !important;
    /* R */
  }
  .g-width-180--md {
    width: 180px !important;
  }
  .g-width-225--md {
    width: 225px !important;
  }
  .g-width-160--md {
    width: 160px !important;
  }
  .g-width-165--md {
    width: 165px !important;
  }
  .g-width-170--md {
    width: 170px !important;
    /* P */
  }
  .g-width-185--md {
    width: 185px !important;
    /* O */
  }
  .g-width-280--md {
    width: 280px !important;
    /* O */
  }
  .g-width-780--md {
    width: 780px !important;
    /* O */
    max-width: 100%;
  }
  .g-max-width-200--md {
    max-width: 200px;
  }
  .g-max-width-400--md {
    max-width: 400px;
  }
  .g-min-width-110--md {
    min-width: 110px;
  }
  .g-width-auto--md {
    width: auto !important;
    /* P */
  }
}

@media (min-width: 992px) {
  .g-width-60x--lg {
    width: 60% !important;
    /* J */
  }
  .g-width-100x--lg {
    width: 100% !important;
    /* P */
  }
  .g-width-155--lg {
    width: 155px !important;
    /* O */
  }
  .g-width-165--lg {
    width: 165px !important;
  }
  .g-width-200--lg {
    width: 200px !important;
    /* O */
    max-width: 100%;
  }
  .g-width-250--lg {
    /* RG-Q */
    width: 250px !important;
    /* P */
    max-width: 100%;
  }
  .g-width-485--lg {
    width: 485px !important;
    /* O */
    max-width: 100%;
  }
  .g-width-auto--lg {
    width: auto !important;
    /* P */
  }
  .g-max-width-200--lg {
    max-width: 200px;
  }
}

@media (min-width: 1200px) {
  .g-min-width-315--xl {
    min-width: 315px;
  }
}

* > .g-width-70--hover {
  transition-property: width;
  transition-duration: .2s;
  transition-timing-function: ease-in;
}

*:hover > .g-width-70--hover {
  width: 70px;
}

/*------------------------------------
  Heights
------------------------------------*/
/* Height in Percentage (%) */
.g-height-50x {
  height: 50%;
}

.g-height-80x {
  height: 80%;
}

.g-height-90x {
  height: 90%;
}

.g-height-95x {
  height: 95%;
}

.g-height-100x {
  height: 100%;
}

/* Max Height in Percentage (%) */
.g-max-height-100x {
  max-height: 100%;
}

/* Max Height in Viewport Height (vh) */
.g-max-height-70vh {
  max-height: 70vh !important;
}

.g-max-height-90vh {
  height: 90vh !important;
}

/* Min Height in Percentage (%) */
.g-min-height-100x {
  min-height: 100%;
}

/* Height  in Viewport Height (vh) */
.g-height-50vh {
  height: 50vh !important;
}

.g-height-70vh {
  height: 70vh;
}

.g-height-75vh {
  height: 75vh;
}

.g-height-100vh {
  height: 100vh !important;
}

/* Min Height in Viewport Height (vh) */
.g-min-height-50vh {
  min-height: 50vh;
}

.g-min-height-60vh {
  min-height: 60vh;
}

.g-min-height-70vh {
  min-height: 70vh;
}

.g-min-height-80vh {
  min-height: 80vh;
}

.g-min-height-90vh {
  min-height: 90vh;
}

.g-min-height-100vh {
  min-height: 100vh;
}

/* Auto Height */
.g-height-auto {
  height: auto;
}

@media (min-width: 576px) {
  .g-height-100vh--sm {
    height: 100vh !important;
  }
  .g-height-auto--sm {
    height: auto;
  }
  .g-height-100x--sm {
    height: 100%;
  }
}

@media (min-width: 768px) {
  .g-height-auto--md {
    height: auto;
  }
  .g-min-height-100vh--md {
    min-height: 100vh;
  }
}

@media (min-width: 992px) {
  .g-min-height-90vh--lg {
    min-height: 90vh;
  }
  .g-height-100vh--lg {
    height: 100vh !important;
  }
  .g-height-auto--lg {
    height: auto;
  }
}

@media (min-width: 1200px) {
  .g-height-auto--xl {
    height: auto;
  }
}

/* Height in Pixels (px) */
.g-height-1 {
  height: 1px;
}

.g-height-2 {
  height: 2px;
}

.g-height-4 {
  height: 4px;
}

.g-height-5 {
  height: 5px !important;
}

.g-height-6 {
  height: 6px !important;
}

.g-height-10 {
  height: 10px !important;
}

.g-height-12 {
  height: 12px !important;
}

.g-height-16 {
  height: 16px !important;
}

.g-height-18 {
  height: 18px;
}

.g-height-20 {
  height: 20px !important;
}

.g-height-22 {
  height: 22px !important;
}

.g-height-24 {
  height: 24px;
}

.g-height-25 {
  height: 25px;
}

.g-height-26 {
  height: 26px;
}

.g-height-28 {
  height: 28px !important;
  /* O */
}

.g-height-30 {
  height: 30px !important;
}

.g-height-32 {
  height: 32px;
  /* O */
}

.g-height-35 {
  height: 35px !important;
}

.g-height-40 {
  height: 40px;
}

.g-height-45 {
  height: 45px;
}

.g-height-48 {
  height: 48px;
  /* O */
}

.g-height-50 {
  height: 50px;
}

.g-height-55 {
  height: 55px;
}

.g-height-60 {
  height: 60px;
}

.g-height-64 {
  height: 64px;
}

.g-height-70 {
  height: 70px;
}

.g-height-75 {
  height: 75px;
  /* O */
}

.g-height-80 {
  height: 80px !important;
}

.g-height-85 {
  height: 85px;
}

.g-height-88 {
  height: 88px;
}

.g-height-90 {
  height: 90px;
}

.g-height-95 {
  height: 95px !important;
}

.g-height-100 {
  height: 100px !important;
}

.g-height-105 {
  height: 105px;
}

.g-height-115 {
  height: 115px !important;
  /* O */
}

.g-height-120 {
  height: 120px;
}

.g-height-125 {
  height: 125px;
}

.g-height-130 {
  height: 130px;
}

.g-height-140 {
  height: 140px;
}

.g-height-150 {
  height: 150px;
}

.g-height-160 {
  height: 160px;
}

.g-height-170 {
  height: 170px;
  /* O */
}

.g-height-180 {
  height: 180px !important;
  /* O */
}

.g-height-200 {
  height: 200px;
}

.g-height-220 {
  height: 220px;
}

.g-height-250 {
  height: 250px;
}

.g-height-280 {
  height: 280px;
}

.g-height-300 {
  height: 300px;
}

.g-height-350 {
  height: 350px;
}

.g-height-400 {
  height: 400px;
}

.g-height-450 {
  height: 450px;
}

.g-height-460 {
  height: 460px;
  /* O */
  max-height: 100% !important;
}

.g-height-500 {
  height: 500px;
}

.g-height-600 {
  height: 600px;
}

.g-height-680 {
  height: 680px;
}

/* Min Height in Pixels (px) */
.g-min-height-4 {
  min-height: 4px;
}

.g-min-height-35 {
  min-height: 35px;
}

.g-min-height-170 {
  min-height: 170px;
}

.g-min-height-200 {
  min-height: 200px;
}

.g-min-height-250 {
  min-height: 250px;
}

.g-min-height-275 {
  min-height: 275px;
}

.g-min-height-300 {
  min-height: 300px;
}

.g-min-height-312 {
  min-height: 312px;
}

.g-min-height-360 {
  min-height: 360px;
}

.g-min-height-400 {
  min-height: 400px;
}

.g-min-height-450 {
  min-height: 450px;
}

.g-min-height-500 {
  min-height: 500px;
}

.g-min-height-600 {
  min-height: 600px;
}

@media (min-width: 576px) {
  .g-height-350--sm {
    height: 350px;
  }
  .g-height-680--sm {
    height: 680px;
  }
}

@media (min-width: 768px) {
  .g-height-40--md {
    height: 40px !important;
  }
  .g-height-45--md {
    height: 45px !important;
  }
  .g-height-60--md {
    height: 60px !important;
  }
  .g-height-100--md {
    height: 100px !important;
  }
  .g-height-170--md {
    height: 170px !important;
  }
  .g-height-350--md {
    height: 350px;
  }
  .g-height-500--md {
    height: 500px;
  }
  .g-height-680--md {
    height: 680px;
  }
  .g-height-100x--md {
    height: 100%;
  }
  .g-height-100vh--md {
    height: 100vh;
  }
  .g-min-height-230--md {
    min-height: 230px;
  }
  .g-min-height-500--md {
    min-height: 500px;
  }
}

@media (min-width: 992px) {
  .g-height-80--lg {
    height: 80px !important;
  }
  .g-height-200--lg {
    height: 200px !important;
  }
  .g-height-230--lg {
    height: 230px;
  }
  .g-height-350--lg {
    height: 350px;
  }
  .g-height-680--lg {
    height: 680px;
  }
}

@media (min-width: 1200px) {
  .g-height-350--xl {
    height: 350px;
  }
  .g-height-680--xl {
    height: 680px;
  }
}

/*------------------------------------
  Margin Spaces
------------------------------------*/
/* Margin Spaces (xs)
------------------------------------*/
@media (min-width: 0) {
  .g-ma-0 {
    margin: 0 !important;
  }
  .g-mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .g-my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .g-ml-0 {
    margin-left: 0 !important;
  }
  .g-mr-0 {
    margin-right: 0 !important;
  }
  .g-mt-0 {
    margin-top: 0 !important;
  }
  .g-mb-0 {
    margin-bottom: 0 !important;
  }
  .g-mx-1 {
    margin-left: 0.07143rem !important;
    margin-right: 0.07143rem !important;
  }
  .g-mx-2 {
    margin-left: 0.14286rem !important;
    margin-right: 0.14286rem !important;
  }
  .g-mx-3 {
    margin-left: 0.21429rem !important;
    margin-right: 0.21429rem !important;
  }
  .g-mx-4 {
    margin-left: 0.28571rem !important;
    margin-right: 0.28571rem !important;
  }
  .g-mx-5 {
    margin-left: 0.35714rem !important;
    margin-right: 0.35714rem !important;
  }
  .g-mx-6 {
    margin-left: 0.42857rem !important;
    margin-right: 0.42857rem !important;
  }
  .g-mx-7 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
  .g-mx-8 {
    margin-left: 0.57143rem !important;
    margin-right: 0.57143rem !important;
  }
  .g-mx-9 {
    margin-left: 0.64286rem !important;
    margin-right: 0.64286rem !important;
  }
  .g-mx-10 {
    margin-left: 0.71429rem !important;
    margin-right: 0.71429rem !important;
  }
  .g-mx-10 {
    margin-left: 0.71429rem !important;
    margin-right: 0.71429rem !important;
  }
  .g-mx-15 {
    margin-left: 1.07143rem !important;
    margin-right: 1.07143rem !important;
  }
  .g-mx-20 {
    margin-left: 1.42857rem !important;
    margin-right: 1.42857rem !important;
  }
  .g-mx-25 {
    margin-left: 1.78571rem !important;
    margin-right: 1.78571rem !important;
  }
  .g-mx-30 {
    margin-left: 2.14286rem !important;
    margin-right: 2.14286rem !important;
  }
  .g-mx-35 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }
  .g-mx-40 {
    margin-left: 2.85714rem !important;
    margin-right: 2.85714rem !important;
  }
  .g-mx-45 {
    margin-left: 3.21429rem !important;
    margin-right: 3.21429rem !important;
  }
  .g-mx-50 {
    margin-left: 3.57143rem !important;
    margin-right: 3.57143rem !important;
  }
  .g-mx-55 {
    margin-left: 3.92857rem !important;
    margin-right: 3.92857rem !important;
  }
  .g-mx-60 {
    margin-left: 4.28571rem !important;
    margin-right: 4.28571rem !important;
  }
  .g-mx-65 {
    margin-left: 4.64286rem !important;
    margin-right: 4.64286rem !important;
  }
  .g-mx-70 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }
  .g-mx-75 {
    margin-left: 5.35714rem !important;
    margin-right: 5.35714rem !important;
  }
  .g-mx-80 {
    margin-left: 5.71429rem !important;
    margin-right: 5.71429rem !important;
  }
  .g-mx-85 {
    margin-left: 6.07143rem !important;
    margin-right: 6.07143rem !important;
  }
  .g-mx-90 {
    margin-left: 6.42857rem !important;
    margin-right: 6.42857rem !important;
  }
  .g-mx-95 {
    margin-left: 6.78571rem !important;
    margin-right: 6.78571rem !important;
  }
  .g-mx-100 {
    margin-left: 7.14286rem !important;
    margin-right: 7.14286rem !important;
  }
  .g-my-1 {
    margin-top: 0.07143rem !important;
    margin-bottom: 0.07143rem !important;
  }
  .g-my-2 {
    margin-top: 0.14286rem !important;
    margin-bottom: 0.14286rem !important;
  }
  .g-my-3 {
    margin-top: 0.21429rem !important;
    margin-bottom: 0.21429rem !important;
  }
  .g-my-4 {
    margin-top: 0.28571rem !important;
    margin-bottom: 0.28571rem !important;
  }
  .g-my-5 {
    margin-top: 0.35714rem !important;
    margin-bottom: 0.35714rem !important;
  }
  .g-my-6 {
    margin-top: 0.42857rem !important;
    margin-bottom: 0.42857rem !important;
  }
  .g-my-7 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .g-my-8 {
    margin-top: 0.57143rem !important;
    margin-bottom: 0.57143rem !important;
  }
  .g-my-9 {
    margin-top: 0.64286rem !important;
    margin-bottom: 0.64286rem !important;
  }
  .g-my-10 {
    margin-top: 0.71429rem !important;
    margin-bottom: 0.71429rem !important;
  }
  .g-my-10 {
    margin-top: 0.71429rem !important;
    margin-bottom: 0.71429rem !important;
  }
  .g-my-15 {
    margin-top: 1.07143rem !important;
    margin-bottom: 1.07143rem !important;
  }
  .g-my-20 {
    margin-top: 1.42857rem !important;
    margin-bottom: 1.42857rem !important;
  }
  .g-my-25 {
    margin-top: 1.78571rem !important;
    margin-bottom: 1.78571rem !important;
  }
  .g-my-30 {
    margin-top: 2.14286rem !important;
    margin-bottom: 2.14286rem !important;
  }
  .g-my-35 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .g-my-40 {
    margin-top: 2.85714rem !important;
    margin-bottom: 2.85714rem !important;
  }
  .g-my-45 {
    margin-top: 3.21429rem !important;
    margin-bottom: 3.21429rem !important;
  }
  .g-my-50 {
    margin-top: 3.57143rem !important;
    margin-bottom: 3.57143rem !important;
  }
  .g-my-55 {
    margin-top: 3.92857rem !important;
    margin-bottom: 3.92857rem !important;
  }
  .g-my-60 {
    margin-top: 4.28571rem !important;
    margin-bottom: 4.28571rem !important;
  }
  .g-my-65 {
    margin-top: 4.64286rem !important;
    margin-bottom: 4.64286rem !important;
  }
  .g-my-70 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .g-my-75 {
    margin-top: 5.35714rem !important;
    margin-bottom: 5.35714rem !important;
  }
  .g-my-80 {
    margin-top: 5.71429rem !important;
    margin-bottom: 5.71429rem !important;
  }
  .g-my-85 {
    margin-top: 6.07143rem !important;
    margin-bottom: 6.07143rem !important;
  }
  .g-my-90 {
    margin-top: 6.42857rem !important;
    margin-bottom: 6.42857rem !important;
  }
  .g-my-95 {
    margin-top: 6.78571rem !important;
    margin-bottom: 6.78571rem !important;
  }
  .g-my-100 {
    margin-top: 7.14286rem !important;
    margin-bottom: 7.14286rem !important;
  }
  .g-mt-1 {
    margin-top: 0.07143rem !important;
  }
  .g-mt-minus-1 {
    margin-top: -0.07143rem !important;
  }
  .g-mt-2 {
    margin-top: 0.14286rem !important;
  }
  .g-mt-minus-2 {
    margin-top: -0.14286rem !important;
  }
  .g-mt-3 {
    margin-top: 0.21429rem !important;
  }
  .g-mt-minus-3 {
    margin-top: -0.21429rem !important;
  }
  .g-mt-4 {
    margin-top: 0.28571rem !important;
  }
  .g-mt-minus-4 {
    margin-top: -0.28571rem !important;
  }
  .g-mt-5 {
    margin-top: 0.35714rem !important;
  }
  .g-mt-minus-5 {
    margin-top: -0.35714rem !important;
  }
  .g-mt-6 {
    margin-top: 0.42857rem !important;
  }
  .g-mt-minus-6 {
    margin-top: -0.42857rem !important;
  }
  .g-mt-7 {
    margin-top: 0.5rem !important;
  }
  .g-mt-minus-7 {
    margin-top: -0.5rem !important;
  }
  .g-mt-8 {
    margin-top: 0.57143rem !important;
  }
  .g-mt-minus-8 {
    margin-top: -0.57143rem !important;
  }
  .g-mt-9 {
    margin-top: 0.64286rem !important;
  }
  .g-mt-minus-9 {
    margin-top: -0.64286rem !important;
  }
  .g-mt-10 {
    margin-top: 0.71429rem !important;
  }
  .g-mt-minus-10 {
    margin-top: -0.71429rem !important;
  }
  .g-mt-11 {
    margin-top: 0.78571rem !important;
  }
  .g-mt-minus-11 {
    margin-top: -0.78571rem !important;
  }
  .g-mt-12 {
    margin-top: 0.85714rem !important;
  }
  .g-mt-minus-12 {
    margin-top: -0.85714rem !important;
  }
  .g-mt-13 {
    margin-top: 0.92857rem !important;
  }
  .g-mt-minus-13 {
    margin-top: -0.92857rem !important;
  }
  .g-mt-14 {
    margin-top: 1rem !important;
  }
  .g-mt-minus-14 {
    margin-top: -1rem !important;
  }
  .g-mt-15 {
    margin-top: 1.07143rem !important;
  }
  .g-mt-minus-15 {
    margin-top: -1.07143rem !important;
  }
  .g-mt-16 {
    margin-top: 1.14286rem !important;
  }
  .g-mt-minus-16 {
    margin-top: -1.14286rem !important;
  }
  .g-mt-17 {
    margin-top: 1.21429rem !important;
  }
  .g-mt-minus-17 {
    margin-top: -1.21429rem !important;
  }
  .g-mt-18 {
    margin-top: 1.28571rem !important;
  }
  .g-mt-minus-18 {
    margin-top: -1.28571rem !important;
  }
  .g-mt-19 {
    margin-top: 1.35714rem !important;
  }
  .g-mt-minus-19 {
    margin-top: -1.35714rem !important;
  }
  .g-mt-20 {
    margin-top: 1.42857rem !important;
  }
  .g-mt-minus-20 {
    margin-top: -1.42857rem !important;
  }
  .g-mt-10 {
    margin-top: 0.71429rem !important;
  }
  .g-mt-minus-10 {
    margin-top: -0.71429rem !important;
  }
  .g-mt-15 {
    margin-top: 1.07143rem !important;
  }
  .g-mt-minus-15 {
    margin-top: -1.07143rem !important;
  }
  .g-mt-20 {
    margin-top: 1.42857rem !important;
  }
  .g-mt-minus-20 {
    margin-top: -1.42857rem !important;
  }
  .g-mt-25 {
    margin-top: 1.78571rem !important;
  }
  .g-mt-minus-25 {
    margin-top: -1.78571rem !important;
  }
  .g-mt-30 {
    margin-top: 2.14286rem !important;
  }
  .g-mt-minus-30 {
    margin-top: -2.14286rem !important;
  }
  .g-mt-35 {
    margin-top: 2.5rem !important;
  }
  .g-mt-minus-35 {
    margin-top: -2.5rem !important;
  }
  .g-mt-40 {
    margin-top: 2.85714rem !important;
  }
  .g-mt-minus-40 {
    margin-top: -2.85714rem !important;
  }
  .g-mt-45 {
    margin-top: 3.21429rem !important;
  }
  .g-mt-minus-45 {
    margin-top: -3.21429rem !important;
  }
  .g-mt-50 {
    margin-top: 3.57143rem !important;
  }
  .g-mt-minus-50 {
    margin-top: -3.57143rem !important;
  }
  .g-mt-55 {
    margin-top: 3.92857rem !important;
  }
  .g-mt-minus-55 {
    margin-top: -3.92857rem !important;
  }
  .g-mt-60 {
    margin-top: 4.28571rem !important;
  }
  .g-mt-minus-60 {
    margin-top: -4.28571rem !important;
  }
  .g-mt-65 {
    margin-top: 4.64286rem !important;
  }
  .g-mt-minus-65 {
    margin-top: -4.64286rem !important;
  }
  .g-mt-70 {
    margin-top: 5rem !important;
  }
  .g-mt-minus-70 {
    margin-top: -5rem !important;
  }
  .g-mt-75 {
    margin-top: 5.35714rem !important;
  }
  .g-mt-minus-75 {
    margin-top: -5.35714rem !important;
  }
  .g-mt-80 {
    margin-top: 5.71429rem !important;
  }
  .g-mt-minus-80 {
    margin-top: -5.71429rem !important;
  }
  .g-mt-85 {
    margin-top: 6.07143rem !important;
  }
  .g-mt-minus-85 {
    margin-top: -6.07143rem !important;
  }
  .g-mt-90 {
    margin-top: 6.42857rem !important;
  }
  .g-mt-minus-90 {
    margin-top: -6.42857rem !important;
  }
  .g-mt-95 {
    margin-top: 6.78571rem !important;
  }
  .g-mt-minus-95 {
    margin-top: -6.78571rem !important;
  }
  .g-mt-100 {
    margin-top: 7.14286rem !important;
  }
  .g-mt-minus-100 {
    margin-top: -7.14286rem !important;
  }
  .g-mt-105 {
    margin-top: 7.5rem !important;
  }
  .g-mt-minus-105 {
    margin-top: -7.5rem !important;
  }
  .g-mt-110 {
    margin-top: 7.85714rem !important;
  }
  .g-mt-minus-110 {
    margin-top: -7.85714rem !important;
  }
  .g-mt-115 {
    margin-top: 8.21429rem !important;
  }
  .g-mt-minus-115 {
    margin-top: -8.21429rem !important;
  }
  .g-mt-120 {
    margin-top: 8.57143rem !important;
  }
  .g-mt-minus-120 {
    margin-top: -8.57143rem !important;
  }
  .g-mt-125 {
    margin-top: 8.92857rem !important;
  }
  .g-mt-minus-125 {
    margin-top: -8.92857rem !important;
  }
  .g-mt-130 {
    margin-top: 9.28571rem !important;
  }
  .g-mt-minus-130 {
    margin-top: -9.28571rem !important;
  }
  .g-mt-135 {
    margin-top: 9.64286rem !important;
  }
  .g-mt-minus-135 {
    margin-top: -9.64286rem !important;
  }
  .g-mt-140 {
    margin-top: 10rem !important;
  }
  .g-mt-minus-140 {
    margin-top: -10rem !important;
  }
  .g-mt-145 {
    margin-top: 10.35714rem !important;
  }
  .g-mt-minus-145 {
    margin-top: -10.35714rem !important;
  }
  .g-mt-150 {
    margin-top: 10.71429rem !important;
  }
  .g-mt-minus-150 {
    margin-top: -10.71429rem !important;
  }
  .g-mt-155 {
    margin-top: 11.07143rem !important;
  }
  .g-mt-minus-155 {
    margin-top: -11.07143rem !important;
  }
  .g-mt-160 {
    margin-top: 11.42857rem !important;
  }
  .g-mt-minus-160 {
    margin-top: -11.42857rem !important;
  }
  .g-mt-165 {
    margin-top: 11.78571rem !important;
  }
  .g-mt-minus-165 {
    margin-top: -11.78571rem !important;
  }
  .g-mt-170 {
    margin-top: 12.14286rem !important;
  }
  .g-mt-minus-170 {
    margin-top: -12.14286rem !important;
  }
  .g-mb-1 {
    margin-bottom: 0.07143rem !important;
  }
  .g-mb-minus-1 {
    margin-bottom: -0.07143rem !important;
  }
  .g-mb-2 {
    margin-bottom: 0.14286rem !important;
  }
  .g-mb-minus-2 {
    margin-bottom: -0.14286rem !important;
  }
  .g-mb-3 {
    margin-bottom: 0.21429rem !important;
  }
  .g-mb-minus-3 {
    margin-bottom: -0.21429rem !important;
  }
  .g-mb-4 {
    margin-bottom: 0.28571rem !important;
  }
  .g-mb-minus-4 {
    margin-bottom: -0.28571rem !important;
  }
  .g-mb-5 {
    margin-bottom: 0.35714rem !important;
  }
  .g-mb-minus-5 {
    margin-bottom: -0.35714rem !important;
  }
  .g-mb-6 {
    margin-bottom: 0.42857rem !important;
  }
  .g-mb-minus-6 {
    margin-bottom: -0.42857rem !important;
  }
  .g-mb-7 {
    margin-bottom: 0.5rem !important;
  }
  .g-mb-minus-7 {
    margin-bottom: -0.5rem !important;
  }
  .g-mb-8 {
    margin-bottom: 0.57143rem !important;
  }
  .g-mb-minus-8 {
    margin-bottom: -0.57143rem !important;
  }
  .g-mb-9 {
    margin-bottom: 0.64286rem !important;
  }
  .g-mb-minus-9 {
    margin-bottom: -0.64286rem !important;
  }
  .g-mb-10 {
    margin-bottom: 0.71429rem !important;
  }
  .g-mb-minus-10 {
    margin-bottom: -0.71429rem !important;
  }
  .g-mb-11 {
    margin-bottom: 0.78571rem !important;
  }
  .g-mb-minus-11 {
    margin-bottom: -0.78571rem !important;
  }
  .g-mb-12 {
    margin-bottom: 0.85714rem !important;
  }
  .g-mb-minus-12 {
    margin-bottom: -0.85714rem !important;
  }
  .g-mb-13 {
    margin-bottom: 0.92857rem !important;
  }
  .g-mb-minus-13 {
    margin-bottom: -0.92857rem !important;
  }
  .g-mb-14 {
    margin-bottom: 1rem !important;
  }
  .g-mb-minus-14 {
    margin-bottom: -1rem !important;
  }
  .g-mb-15 {
    margin-bottom: 1.07143rem !important;
  }
  .g-mb-minus-15 {
    margin-bottom: -1.07143rem !important;
  }
  .g-mb-16 {
    margin-bottom: 1.14286rem !important;
  }
  .g-mb-minus-16 {
    margin-bottom: -1.14286rem !important;
  }
  .g-mb-17 {
    margin-bottom: 1.21429rem !important;
  }
  .g-mb-minus-17 {
    margin-bottom: -1.21429rem !important;
  }
  .g-mb-18 {
    margin-bottom: 1.28571rem !important;
  }
  .g-mb-minus-18 {
    margin-bottom: -1.28571rem !important;
  }
  .g-mb-19 {
    margin-bottom: 1.35714rem !important;
  }
  .g-mb-minus-19 {
    margin-bottom: -1.35714rem !important;
  }
  .g-mb-20 {
    margin-bottom: 1.42857rem !important;
  }
  .g-mb-minus-20 {
    margin-bottom: -1.42857rem !important;
  }
  .g-mb-10 {
    margin-bottom: 0.71429rem !important;
  }
  .g-mb-15 {
    margin-bottom: 1.07143rem !important;
  }
  .g-mb-20 {
    margin-bottom: 1.42857rem !important;
  }
  .g-mb-25 {
    margin-bottom: 1.78571rem !important;
  }
  .g-mb-30 {
    margin-bottom: 2.14286rem !important;
  }
  .g-mb-35 {
    margin-bottom: 2.5rem !important;
  }
  .g-mb-40 {
    margin-bottom: 2.85714rem !important;
  }
  .g-mb-45 {
    margin-bottom: 3.21429rem !important;
  }
  .g-mb-50 {
    margin-bottom: 3.57143rem !important;
  }
  .g-mb-55 {
    margin-bottom: 3.92857rem !important;
  }
  .g-mb-60 {
    margin-bottom: 4.28571rem !important;
  }
  .g-mb-65 {
    margin-bottom: 4.64286rem !important;
  }
  .g-mb-70 {
    margin-bottom: 5rem !important;
  }
  .g-mb-75 {
    margin-bottom: 5.35714rem !important;
  }
  .g-mb-80 {
    margin-bottom: 5.71429rem !important;
  }
  .g-mb-85 {
    margin-bottom: 6.07143rem !important;
  }
  .g-mb-90 {
    margin-bottom: 6.42857rem !important;
  }
  .g-mb-95 {
    margin-bottom: 6.78571rem !important;
  }
  .g-mb-100 {
    margin-bottom: 7.14286rem !important;
  }
  .g-mb-105 {
    margin-bottom: 7.5rem !important;
  }
  .g-mb-110 {
    margin-bottom: 7.85714rem !important;
  }
  .g-mb-115 {
    margin-bottom: 8.21429rem !important;
  }
  .g-mb-120 {
    margin-bottom: 8.57143rem !important;
  }
  .g-mb-125 {
    margin-bottom: 8.92857rem !important;
  }
  .g-mb-130 {
    margin-bottom: 9.28571rem !important;
  }
  .g-mb-135 {
    margin-bottom: 9.64286rem !important;
  }
  .g-mb-140 {
    margin-bottom: 10rem !important;
  }
  .g-mb-145 {
    margin-bottom: 10.35714rem !important;
  }
  .g-mb-150 {
    margin-bottom: 10.71429rem !important;
  }
  .g-mb-155 {
    margin-bottom: 11.07143rem !important;
  }
  .g-mb-160 {
    margin-bottom: 11.42857rem !important;
  }
  .g-mb-165 {
    margin-bottom: 11.78571rem !important;
  }
  .g-mb-170 {
    margin-bottom: 12.14286rem !important;
  }
  .g-ml-1 {
    margin-left: 0.07143rem !important;
  }
  .g-ml-minus-1 {
    margin-left: -0.07143rem !important;
  }
  .g-ml-2 {
    margin-left: 0.14286rem !important;
  }
  .g-ml-minus-2 {
    margin-left: -0.14286rem !important;
  }
  .g-ml-3 {
    margin-left: 0.21429rem !important;
  }
  .g-ml-minus-3 {
    margin-left: -0.21429rem !important;
  }
  .g-ml-4 {
    margin-left: 0.28571rem !important;
  }
  .g-ml-minus-4 {
    margin-left: -0.28571rem !important;
  }
  .g-ml-5 {
    margin-left: 0.35714rem !important;
  }
  .g-ml-minus-5 {
    margin-left: -0.35714rem !important;
  }
  .g-ml-6 {
    margin-left: 0.42857rem !important;
  }
  .g-ml-minus-6 {
    margin-left: -0.42857rem !important;
  }
  .g-ml-7 {
    margin-left: 0.5rem !important;
  }
  .g-ml-minus-7 {
    margin-left: -0.5rem !important;
  }
  .g-ml-8 {
    margin-left: 0.57143rem !important;
  }
  .g-ml-minus-8 {
    margin-left: -0.57143rem !important;
  }
  .g-ml-9 {
    margin-left: 0.64286rem !important;
  }
  .g-ml-minus-9 {
    margin-left: -0.64286rem !important;
  }
  .g-ml-10 {
    margin-left: 0.71429rem !important;
  }
  .g-ml-minus-10 {
    margin-left: -0.71429rem !important;
  }
  .g-ml-5 {
    margin-left: 0.35714rem !important;
  }
  .g-ml-minus-5 {
    margin-left: -0.35714rem !important;
  }
  .g-ml-10 {
    margin-left: 0.71429rem !important;
  }
  .g-ml-minus-10 {
    margin-left: -0.71429rem !important;
  }
  .g-ml-15 {
    margin-left: 1.07143rem !important;
  }
  .g-ml-minus-15 {
    margin-left: -1.07143rem !important;
  }
  .g-ml-20 {
    margin-left: 1.42857rem !important;
  }
  .g-ml-minus-20 {
    margin-left: -1.42857rem !important;
  }
  .g-ml-25 {
    margin-left: 1.78571rem !important;
  }
  .g-ml-minus-25 {
    margin-left: -1.78571rem !important;
  }
  .g-ml-30 {
    margin-left: 2.14286rem !important;
  }
  .g-ml-minus-30 {
    margin-left: -2.14286rem !important;
  }
  .g-ml-35 {
    margin-left: 2.5rem !important;
  }
  .g-ml-minus-35 {
    margin-left: -2.5rem !important;
  }
  .g-ml-40 {
    margin-left: 2.85714rem !important;
  }
  .g-ml-minus-40 {
    margin-left: -2.85714rem !important;
  }
  .g-ml-45 {
    margin-left: 3.21429rem !important;
  }
  .g-ml-minus-45 {
    margin-left: -3.21429rem !important;
  }
  .g-ml-50 {
    margin-left: 3.57143rem !important;
  }
  .g-ml-minus-50 {
    margin-left: -3.57143rem !important;
  }
  .g-mr-1 {
    margin-right: 0.07143rem !important;
  }
  .g-mr-minus-1 {
    margin-right: -0.07143rem !important;
  }
  .g-mr-2 {
    margin-right: 0.14286rem !important;
  }
  .g-mr-minus-2 {
    margin-right: -0.14286rem !important;
  }
  .g-mr-3 {
    margin-right: 0.21429rem !important;
  }
  .g-mr-minus-3 {
    margin-right: -0.21429rem !important;
  }
  .g-mr-4 {
    margin-right: 0.28571rem !important;
  }
  .g-mr-minus-4 {
    margin-right: -0.28571rem !important;
  }
  .g-mr-5 {
    margin-right: 0.35714rem !important;
  }
  .g-mr-minus-5 {
    margin-right: -0.35714rem !important;
  }
  .g-mr-6 {
    margin-right: 0.42857rem !important;
  }
  .g-mr-minus-6 {
    margin-right: -0.42857rem !important;
  }
  .g-mr-7 {
    margin-right: 0.5rem !important;
  }
  .g-mr-minus-7 {
    margin-right: -0.5rem !important;
  }
  .g-mr-8 {
    margin-right: 0.57143rem !important;
  }
  .g-mr-minus-8 {
    margin-right: -0.57143rem !important;
  }
  .g-mr-9 {
    margin-right: 0.64286rem !important;
  }
  .g-mr-minus-9 {
    margin-right: -0.64286rem !important;
  }
  .g-mr-10 {
    margin-right: 0.71429rem !important;
  }
  .g-mr-minus-10 {
    margin-right: -0.71429rem !important;
  }
  .g-mr-5 {
    margin-right: 0.35714rem !important;
  }
  .g-mr-10 {
    margin-right: 0.71429rem !important;
  }
  .g-mr-15 {
    margin-right: 1.07143rem !important;
  }
  .g-mr-20 {
    margin-right: 1.42857rem !important;
  }
  .g-mr-25 {
    margin-right: 1.78571rem !important;
  }
  .g-mr-30 {
    margin-right: 2.14286rem !important;
  }
  .g-mr-35 {
    margin-right: 2.5rem !important;
  }
  .g-mr-40 {
    margin-right: 2.85714rem !important;
  }
  .g-mr-45 {
    margin-right: 3.21429rem !important;
  }
  .g-mr-50 {
    margin-right: 3.57143rem !important;
  }
}

/* Margin Spaces (sm)
------------------------------------*/
@media (min-width: 576px) {
  .g-ma-0--sm {
    margin: 0 !important;
  }
  .g-mx-0--sm {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .g-my-0--sm {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .g-ml-0--sm {
    margin-left: 0 !important;
  }
  .g-mr-0--sm {
    margin-right: 0 !important;
  }
  .g-mt-0--sm {
    margin-top: 0 !important;
  }
  .g-mb-0--sm {
    margin-bottom: 0 !important;
  }
  .g-mx-1--sm {
    margin-left: 0.07143rem !important;
    margin-right: 0.07143rem !important;
  }
  .g-mx-2--sm {
    margin-left: 0.14286rem !important;
    margin-right: 0.14286rem !important;
  }
  .g-mx-3--sm {
    margin-left: 0.21429rem !important;
    margin-right: 0.21429rem !important;
  }
  .g-mx-4--sm {
    margin-left: 0.28571rem !important;
    margin-right: 0.28571rem !important;
  }
  .g-mx-5--sm {
    margin-left: 0.35714rem !important;
    margin-right: 0.35714rem !important;
  }
  .g-mx-6--sm {
    margin-left: 0.42857rem !important;
    margin-right: 0.42857rem !important;
  }
  .g-mx-7--sm {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
  .g-mx-8--sm {
    margin-left: 0.57143rem !important;
    margin-right: 0.57143rem !important;
  }
  .g-mx-9--sm {
    margin-left: 0.64286rem !important;
    margin-right: 0.64286rem !important;
  }
  .g-mx-10--sm {
    margin-left: 0.71429rem !important;
    margin-right: 0.71429rem !important;
  }
  .g-mx-10--sm {
    margin-left: 0.71429rem !important;
    margin-right: 0.71429rem !important;
  }
  .g-mx-15--sm {
    margin-left: 1.07143rem !important;
    margin-right: 1.07143rem !important;
  }
  .g-mx-20--sm {
    margin-left: 1.42857rem !important;
    margin-right: 1.42857rem !important;
  }
  .g-mx-25--sm {
    margin-left: 1.78571rem !important;
    margin-right: 1.78571rem !important;
  }
  .g-mx-30--sm {
    margin-left: 2.14286rem !important;
    margin-right: 2.14286rem !important;
  }
  .g-mx-35--sm {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }
  .g-mx-40--sm {
    margin-left: 2.85714rem !important;
    margin-right: 2.85714rem !important;
  }
  .g-mx-45--sm {
    margin-left: 3.21429rem !important;
    margin-right: 3.21429rem !important;
  }
  .g-mx-50--sm {
    margin-left: 3.57143rem !important;
    margin-right: 3.57143rem !important;
  }
  .g-mx-55--sm {
    margin-left: 3.92857rem !important;
    margin-right: 3.92857rem !important;
  }
  .g-mx-60--sm {
    margin-left: 4.28571rem !important;
    margin-right: 4.28571rem !important;
  }
  .g-mx-65--sm {
    margin-left: 4.64286rem !important;
    margin-right: 4.64286rem !important;
  }
  .g-mx-70--sm {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }
  .g-mx-75--sm {
    margin-left: 5.35714rem !important;
    margin-right: 5.35714rem !important;
  }
  .g-mx-80--sm {
    margin-left: 5.71429rem !important;
    margin-right: 5.71429rem !important;
  }
  .g-mx-85--sm {
    margin-left: 6.07143rem !important;
    margin-right: 6.07143rem !important;
  }
  .g-mx-90--sm {
    margin-left: 6.42857rem !important;
    margin-right: 6.42857rem !important;
  }
  .g-mx-95--sm {
    margin-left: 6.78571rem !important;
    margin-right: 6.78571rem !important;
  }
  .g-mx-100--sm {
    margin-left: 7.14286rem !important;
    margin-right: 7.14286rem !important;
  }
  .g-my-1--sm {
    margin-top: 0.07143rem !important;
    margin-bottom: 0.07143rem !important;
  }
  .g-my-2--sm {
    margin-top: 0.14286rem !important;
    margin-bottom: 0.14286rem !important;
  }
  .g-my-3--sm {
    margin-top: 0.21429rem !important;
    margin-bottom: 0.21429rem !important;
  }
  .g-my-4--sm {
    margin-top: 0.28571rem !important;
    margin-bottom: 0.28571rem !important;
  }
  .g-my-5--sm {
    margin-top: 0.35714rem !important;
    margin-bottom: 0.35714rem !important;
  }
  .g-my-6--sm {
    margin-top: 0.42857rem !important;
    margin-bottom: 0.42857rem !important;
  }
  .g-my-7--sm {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .g-my-8--sm {
    margin-top: 0.57143rem !important;
    margin-bottom: 0.57143rem !important;
  }
  .g-my-9--sm {
    margin-top: 0.64286rem !important;
    margin-bottom: 0.64286rem !important;
  }
  .g-my-10--sm {
    margin-top: 0.71429rem !important;
    margin-bottom: 0.71429rem !important;
  }
  .g-my-10--sm {
    margin-top: 0.71429rem !important;
    margin-bottom: 0.71429rem !important;
  }
  .g-my-15--sm {
    margin-top: 1.07143rem !important;
    margin-bottom: 1.07143rem !important;
  }
  .g-my-20--sm {
    margin-top: 1.42857rem !important;
    margin-bottom: 1.42857rem !important;
  }
  .g-my-25--sm {
    margin-top: 1.78571rem !important;
    margin-bottom: 1.78571rem !important;
  }
  .g-my-30--sm {
    margin-top: 2.14286rem !important;
    margin-bottom: 2.14286rem !important;
  }
  .g-my-35--sm {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .g-my-40--sm {
    margin-top: 2.85714rem !important;
    margin-bottom: 2.85714rem !important;
  }
  .g-my-45--sm {
    margin-top: 3.21429rem !important;
    margin-bottom: 3.21429rem !important;
  }
  .g-my-50--sm {
    margin-top: 3.57143rem !important;
    margin-bottom: 3.57143rem !important;
  }
  .g-my-55--sm {
    margin-top: 3.92857rem !important;
    margin-bottom: 3.92857rem !important;
  }
  .g-my-60--sm {
    margin-top: 4.28571rem !important;
    margin-bottom: 4.28571rem !important;
  }
  .g-my-65--sm {
    margin-top: 4.64286rem !important;
    margin-bottom: 4.64286rem !important;
  }
  .g-my-70--sm {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .g-my-75--sm {
    margin-top: 5.35714rem !important;
    margin-bottom: 5.35714rem !important;
  }
  .g-my-80--sm {
    margin-top: 5.71429rem !important;
    margin-bottom: 5.71429rem !important;
  }
  .g-my-85--sm {
    margin-top: 6.07143rem !important;
    margin-bottom: 6.07143rem !important;
  }
  .g-my-90--sm {
    margin-top: 6.42857rem !important;
    margin-bottom: 6.42857rem !important;
  }
  .g-my-95--sm {
    margin-top: 6.78571rem !important;
    margin-bottom: 6.78571rem !important;
  }
  .g-my-100--sm {
    margin-top: 7.14286rem !important;
    margin-bottom: 7.14286rem !important;
  }
  .g-mt-1--sm {
    margin-top: 0.07143rem !important;
  }
  .g-mt-minus-1--sm {
    margin-top: -0.07143rem !important;
  }
  .g-mt-2--sm {
    margin-top: 0.14286rem !important;
  }
  .g-mt-minus-2--sm {
    margin-top: -0.14286rem !important;
  }
  .g-mt-3--sm {
    margin-top: 0.21429rem !important;
  }
  .g-mt-minus-3--sm {
    margin-top: -0.21429rem !important;
  }
  .g-mt-4--sm {
    margin-top: 0.28571rem !important;
  }
  .g-mt-minus-4--sm {
    margin-top: -0.28571rem !important;
  }
  .g-mt-5--sm {
    margin-top: 0.35714rem !important;
  }
  .g-mt-minus-5--sm {
    margin-top: -0.35714rem !important;
  }
  .g-mt-6--sm {
    margin-top: 0.42857rem !important;
  }
  .g-mt-minus-6--sm {
    margin-top: -0.42857rem !important;
  }
  .g-mt-7--sm {
    margin-top: 0.5rem !important;
  }
  .g-mt-minus-7--sm {
    margin-top: -0.5rem !important;
  }
  .g-mt-8--sm {
    margin-top: 0.57143rem !important;
  }
  .g-mt-minus-8--sm {
    margin-top: -0.57143rem !important;
  }
  .g-mt-9--sm {
    margin-top: 0.64286rem !important;
  }
  .g-mt-minus-9--sm {
    margin-top: -0.64286rem !important;
  }
  .g-mt-10--sm {
    margin-top: 0.71429rem !important;
  }
  .g-mt-minus-10--sm {
    margin-top: -0.71429rem !important;
  }
  .g-mt-11--sm {
    margin-top: 0.78571rem !important;
  }
  .g-mt-minus-11--sm {
    margin-top: -0.78571rem !important;
  }
  .g-mt-12--sm {
    margin-top: 0.85714rem !important;
  }
  .g-mt-minus-12--sm {
    margin-top: -0.85714rem !important;
  }
  .g-mt-13--sm {
    margin-top: 0.92857rem !important;
  }
  .g-mt-minus-13--sm {
    margin-top: -0.92857rem !important;
  }
  .g-mt-14--sm {
    margin-top: 1rem !important;
  }
  .g-mt-minus-14--sm {
    margin-top: -1rem !important;
  }
  .g-mt-15--sm {
    margin-top: 1.07143rem !important;
  }
  .g-mt-minus-15--sm {
    margin-top: -1.07143rem !important;
  }
  .g-mt-16--sm {
    margin-top: 1.14286rem !important;
  }
  .g-mt-minus-16--sm {
    margin-top: -1.14286rem !important;
  }
  .g-mt-17--sm {
    margin-top: 1.21429rem !important;
  }
  .g-mt-minus-17--sm {
    margin-top: -1.21429rem !important;
  }
  .g-mt-18--sm {
    margin-top: 1.28571rem !important;
  }
  .g-mt-minus-18--sm {
    margin-top: -1.28571rem !important;
  }
  .g-mt-19--sm {
    margin-top: 1.35714rem !important;
  }
  .g-mt-minus-19--sm {
    margin-top: -1.35714rem !important;
  }
  .g-mt-20--sm {
    margin-top: 1.42857rem !important;
  }
  .g-mt-minus-20--sm {
    margin-top: -1.42857rem !important;
  }
  .g-mt-10--sm {
    margin-top: 0.71429rem !important;
  }
  .g-mt-minus-10--sm {
    margin-top: -0.71429rem !important;
  }
  .g-mt-15--sm {
    margin-top: 1.07143rem !important;
  }
  .g-mt-minus-15--sm {
    margin-top: -1.07143rem !important;
  }
  .g-mt-20--sm {
    margin-top: 1.42857rem !important;
  }
  .g-mt-minus-20--sm {
    margin-top: -1.42857rem !important;
  }
  .g-mt-25--sm {
    margin-top: 1.78571rem !important;
  }
  .g-mt-minus-25--sm {
    margin-top: -1.78571rem !important;
  }
  .g-mt-30--sm {
    margin-top: 2.14286rem !important;
  }
  .g-mt-minus-30--sm {
    margin-top: -2.14286rem !important;
  }
  .g-mt-35--sm {
    margin-top: 2.5rem !important;
  }
  .g-mt-minus-35--sm {
    margin-top: -2.5rem !important;
  }
  .g-mt-40--sm {
    margin-top: 2.85714rem !important;
  }
  .g-mt-minus-40--sm {
    margin-top: -2.85714rem !important;
  }
  .g-mt-45--sm {
    margin-top: 3.21429rem !important;
  }
  .g-mt-minus-45--sm {
    margin-top: -3.21429rem !important;
  }
  .g-mt-50--sm {
    margin-top: 3.57143rem !important;
  }
  .g-mt-minus-50--sm {
    margin-top: -3.57143rem !important;
  }
  .g-mt-55--sm {
    margin-top: 3.92857rem !important;
  }
  .g-mt-minus-55--sm {
    margin-top: -3.92857rem !important;
  }
  .g-mt-60--sm {
    margin-top: 4.28571rem !important;
  }
  .g-mt-minus-60--sm {
    margin-top: -4.28571rem !important;
  }
  .g-mt-65--sm {
    margin-top: 4.64286rem !important;
  }
  .g-mt-minus-65--sm {
    margin-top: -4.64286rem !important;
  }
  .g-mt-70--sm {
    margin-top: 5rem !important;
  }
  .g-mt-minus-70--sm {
    margin-top: -5rem !important;
  }
  .g-mt-75--sm {
    margin-top: 5.35714rem !important;
  }
  .g-mt-minus-75--sm {
    margin-top: -5.35714rem !important;
  }
  .g-mt-80--sm {
    margin-top: 5.71429rem !important;
  }
  .g-mt-minus-80--sm {
    margin-top: -5.71429rem !important;
  }
  .g-mt-85--sm {
    margin-top: 6.07143rem !important;
  }
  .g-mt-minus-85--sm {
    margin-top: -6.07143rem !important;
  }
  .g-mt-90--sm {
    margin-top: 6.42857rem !important;
  }
  .g-mt-minus-90--sm {
    margin-top: -6.42857rem !important;
  }
  .g-mt-95--sm {
    margin-top: 6.78571rem !important;
  }
  .g-mt-minus-95--sm {
    margin-top: -6.78571rem !important;
  }
  .g-mt-100--sm {
    margin-top: 7.14286rem !important;
  }
  .g-mt-minus-100--sm {
    margin-top: -7.14286rem !important;
  }
  .g-mt-105--sm {
    margin-top: 7.5rem !important;
  }
  .g-mt-minus-105--sm {
    margin-top: -7.5rem !important;
  }
  .g-mt-110--sm {
    margin-top: 7.85714rem !important;
  }
  .g-mt-minus-110--sm {
    margin-top: -7.85714rem !important;
  }
  .g-mt-115--sm {
    margin-top: 8.21429rem !important;
  }
  .g-mt-minus-115--sm {
    margin-top: -8.21429rem !important;
  }
  .g-mt-120--sm {
    margin-top: 8.57143rem !important;
  }
  .g-mt-minus-120--sm {
    margin-top: -8.57143rem !important;
  }
  .g-mt-125--sm {
    margin-top: 8.92857rem !important;
  }
  .g-mt-minus-125--sm {
    margin-top: -8.92857rem !important;
  }
  .g-mt-130--sm {
    margin-top: 9.28571rem !important;
  }
  .g-mt-minus-130--sm {
    margin-top: -9.28571rem !important;
  }
  .g-mt-135--sm {
    margin-top: 9.64286rem !important;
  }
  .g-mt-minus-135--sm {
    margin-top: -9.64286rem !important;
  }
  .g-mt-140--sm {
    margin-top: 10rem !important;
  }
  .g-mt-minus-140--sm {
    margin-top: -10rem !important;
  }
  .g-mt-145--sm {
    margin-top: 10.35714rem !important;
  }
  .g-mt-minus-145--sm {
    margin-top: -10.35714rem !important;
  }
  .g-mt-150--sm {
    margin-top: 10.71429rem !important;
  }
  .g-mt-minus-150--sm {
    margin-top: -10.71429rem !important;
  }
  .g-mt-155--sm {
    margin-top: 11.07143rem !important;
  }
  .g-mt-minus-155--sm {
    margin-top: -11.07143rem !important;
  }
  .g-mt-160--sm {
    margin-top: 11.42857rem !important;
  }
  .g-mt-minus-160--sm {
    margin-top: -11.42857rem !important;
  }
  .g-mt-165--sm {
    margin-top: 11.78571rem !important;
  }
  .g-mt-minus-165--sm {
    margin-top: -11.78571rem !important;
  }
  .g-mt-170--sm {
    margin-top: 12.14286rem !important;
  }
  .g-mt-minus-170--sm {
    margin-top: -12.14286rem !important;
  }
  .g-mb-1--sm {
    margin-bottom: 0.07143rem !important;
  }
  .g-mb-minus-1--sm {
    margin-bottom: -0.07143rem !important;
  }
  .g-mb-2--sm {
    margin-bottom: 0.14286rem !important;
  }
  .g-mb-minus-2--sm {
    margin-bottom: -0.14286rem !important;
  }
  .g-mb-3--sm {
    margin-bottom: 0.21429rem !important;
  }
  .g-mb-minus-3--sm {
    margin-bottom: -0.21429rem !important;
  }
  .g-mb-4--sm {
    margin-bottom: 0.28571rem !important;
  }
  .g-mb-minus-4--sm {
    margin-bottom: -0.28571rem !important;
  }
  .g-mb-5--sm {
    margin-bottom: 0.35714rem !important;
  }
  .g-mb-minus-5--sm {
    margin-bottom: -0.35714rem !important;
  }
  .g-mb-6--sm {
    margin-bottom: 0.42857rem !important;
  }
  .g-mb-minus-6--sm {
    margin-bottom: -0.42857rem !important;
  }
  .g-mb-7--sm {
    margin-bottom: 0.5rem !important;
  }
  .g-mb-minus-7--sm {
    margin-bottom: -0.5rem !important;
  }
  .g-mb-8--sm {
    margin-bottom: 0.57143rem !important;
  }
  .g-mb-minus-8--sm {
    margin-bottom: -0.57143rem !important;
  }
  .g-mb-9--sm {
    margin-bottom: 0.64286rem !important;
  }
  .g-mb-minus-9--sm {
    margin-bottom: -0.64286rem !important;
  }
  .g-mb-10--sm {
    margin-bottom: 0.71429rem !important;
  }
  .g-mb-minus-10--sm {
    margin-bottom: -0.71429rem !important;
  }
  .g-mb-11--sm {
    margin-bottom: 0.78571rem !important;
  }
  .g-mb-minus-11--sm {
    margin-bottom: -0.78571rem !important;
  }
  .g-mb-12--sm {
    margin-bottom: 0.85714rem !important;
  }
  .g-mb-minus-12--sm {
    margin-bottom: -0.85714rem !important;
  }
  .g-mb-13--sm {
    margin-bottom: 0.92857rem !important;
  }
  .g-mb-minus-13--sm {
    margin-bottom: -0.92857rem !important;
  }
  .g-mb-14--sm {
    margin-bottom: 1rem !important;
  }
  .g-mb-minus-14--sm {
    margin-bottom: -1rem !important;
  }
  .g-mb-15--sm {
    margin-bottom: 1.07143rem !important;
  }
  .g-mb-minus-15--sm {
    margin-bottom: -1.07143rem !important;
  }
  .g-mb-16--sm {
    margin-bottom: 1.14286rem !important;
  }
  .g-mb-minus-16--sm {
    margin-bottom: -1.14286rem !important;
  }
  .g-mb-17--sm {
    margin-bottom: 1.21429rem !important;
  }
  .g-mb-minus-17--sm {
    margin-bottom: -1.21429rem !important;
  }
  .g-mb-18--sm {
    margin-bottom: 1.28571rem !important;
  }
  .g-mb-minus-18--sm {
    margin-bottom: -1.28571rem !important;
  }
  .g-mb-19--sm {
    margin-bottom: 1.35714rem !important;
  }
  .g-mb-minus-19--sm {
    margin-bottom: -1.35714rem !important;
  }
  .g-mb-20--sm {
    margin-bottom: 1.42857rem !important;
  }
  .g-mb-minus-20--sm {
    margin-bottom: -1.42857rem !important;
  }
  .g-mb-10--sm {
    margin-bottom: 0.71429rem !important;
  }
  .g-mb-15--sm {
    margin-bottom: 1.07143rem !important;
  }
  .g-mb-20--sm {
    margin-bottom: 1.42857rem !important;
  }
  .g-mb-25--sm {
    margin-bottom: 1.78571rem !important;
  }
  .g-mb-30--sm {
    margin-bottom: 2.14286rem !important;
  }
  .g-mb-35--sm {
    margin-bottom: 2.5rem !important;
  }
  .g-mb-40--sm {
    margin-bottom: 2.85714rem !important;
  }
  .g-mb-45--sm {
    margin-bottom: 3.21429rem !important;
  }
  .g-mb-50--sm {
    margin-bottom: 3.57143rem !important;
  }
  .g-mb-55--sm {
    margin-bottom: 3.92857rem !important;
  }
  .g-mb-60--sm {
    margin-bottom: 4.28571rem !important;
  }
  .g-mb-65--sm {
    margin-bottom: 4.64286rem !important;
  }
  .g-mb-70--sm {
    margin-bottom: 5rem !important;
  }
  .g-mb-75--sm {
    margin-bottom: 5.35714rem !important;
  }
  .g-mb-80--sm {
    margin-bottom: 5.71429rem !important;
  }
  .g-mb-85--sm {
    margin-bottom: 6.07143rem !important;
  }
  .g-mb-90--sm {
    margin-bottom: 6.42857rem !important;
  }
  .g-mb-95--sm {
    margin-bottom: 6.78571rem !important;
  }
  .g-mb-100--sm {
    margin-bottom: 7.14286rem !important;
  }
  .g-mb-105--sm {
    margin-bottom: 7.5rem !important;
  }
  .g-mb-110--sm {
    margin-bottom: 7.85714rem !important;
  }
  .g-mb-115--sm {
    margin-bottom: 8.21429rem !important;
  }
  .g-mb-120--sm {
    margin-bottom: 8.57143rem !important;
  }
  .g-mb-125--sm {
    margin-bottom: 8.92857rem !important;
  }
  .g-mb-130--sm {
    margin-bottom: 9.28571rem !important;
  }
  .g-mb-135--sm {
    margin-bottom: 9.64286rem !important;
  }
  .g-mb-140--sm {
    margin-bottom: 10rem !important;
  }
  .g-mb-145--sm {
    margin-bottom: 10.35714rem !important;
  }
  .g-mb-150--sm {
    margin-bottom: 10.71429rem !important;
  }
  .g-mb-155--sm {
    margin-bottom: 11.07143rem !important;
  }
  .g-mb-160--sm {
    margin-bottom: 11.42857rem !important;
  }
  .g-mb-165--sm {
    margin-bottom: 11.78571rem !important;
  }
  .g-mb-170--sm {
    margin-bottom: 12.14286rem !important;
  }
  .g-ml-1--sm {
    margin-left: 0.07143rem !important;
  }
  .g-ml-minus-1--sm {
    margin-left: -0.07143rem !important;
  }
  .g-ml-2--sm {
    margin-left: 0.14286rem !important;
  }
  .g-ml-minus-2--sm {
    margin-left: -0.14286rem !important;
  }
  .g-ml-3--sm {
    margin-left: 0.21429rem !important;
  }
  .g-ml-minus-3--sm {
    margin-left: -0.21429rem !important;
  }
  .g-ml-4--sm {
    margin-left: 0.28571rem !important;
  }
  .g-ml-minus-4--sm {
    margin-left: -0.28571rem !important;
  }
  .g-ml-5--sm {
    margin-left: 0.35714rem !important;
  }
  .g-ml-minus-5--sm {
    margin-left: -0.35714rem !important;
  }
  .g-ml-6--sm {
    margin-left: 0.42857rem !important;
  }
  .g-ml-minus-6--sm {
    margin-left: -0.42857rem !important;
  }
  .g-ml-7--sm {
    margin-left: 0.5rem !important;
  }
  .g-ml-minus-7--sm {
    margin-left: -0.5rem !important;
  }
  .g-ml-8--sm {
    margin-left: 0.57143rem !important;
  }
  .g-ml-minus-8--sm {
    margin-left: -0.57143rem !important;
  }
  .g-ml-9--sm {
    margin-left: 0.64286rem !important;
  }
  .g-ml-minus-9--sm {
    margin-left: -0.64286rem !important;
  }
  .g-ml-10--sm {
    margin-left: 0.71429rem !important;
  }
  .g-ml-minus-10--sm {
    margin-left: -0.71429rem !important;
  }
  .g-ml-5--sm {
    margin-left: 0.35714rem !important;
  }
  .g-ml-minus-5--sm {
    margin-left: -0.35714rem !important;
  }
  .g-ml-10--sm {
    margin-left: 0.71429rem !important;
  }
  .g-ml-minus-10--sm {
    margin-left: -0.71429rem !important;
  }
  .g-ml-15--sm {
    margin-left: 1.07143rem !important;
  }
  .g-ml-minus-15--sm {
    margin-left: -1.07143rem !important;
  }
  .g-ml-20--sm {
    margin-left: 1.42857rem !important;
  }
  .g-ml-minus-20--sm {
    margin-left: -1.42857rem !important;
  }
  .g-ml-25--sm {
    margin-left: 1.78571rem !important;
  }
  .g-ml-minus-25--sm {
    margin-left: -1.78571rem !important;
  }
  .g-ml-30--sm {
    margin-left: 2.14286rem !important;
  }
  .g-ml-minus-30--sm {
    margin-left: -2.14286rem !important;
  }
  .g-ml-35--sm {
    margin-left: 2.5rem !important;
  }
  .g-ml-minus-35--sm {
    margin-left: -2.5rem !important;
  }
  .g-ml-40--sm {
    margin-left: 2.85714rem !important;
  }
  .g-ml-minus-40--sm {
    margin-left: -2.85714rem !important;
  }
  .g-ml-45--sm {
    margin-left: 3.21429rem !important;
  }
  .g-ml-minus-45--sm {
    margin-left: -3.21429rem !important;
  }
  .g-ml-50--sm {
    margin-left: 3.57143rem !important;
  }
  .g-ml-minus-50--sm {
    margin-left: -3.57143rem !important;
  }
  .g-mr-1--sm {
    margin-right: 0.07143rem !important;
  }
  .g-mr-minus-1--sm {
    margin-right: -0.07143rem !important;
  }
  .g-mr-2--sm {
    margin-right: 0.14286rem !important;
  }
  .g-mr-minus-2--sm {
    margin-right: -0.14286rem !important;
  }
  .g-mr-3--sm {
    margin-right: 0.21429rem !important;
  }
  .g-mr-minus-3--sm {
    margin-right: -0.21429rem !important;
  }
  .g-mr-4--sm {
    margin-right: 0.28571rem !important;
  }
  .g-mr-minus-4--sm {
    margin-right: -0.28571rem !important;
  }
  .g-mr-5--sm {
    margin-right: 0.35714rem !important;
  }
  .g-mr-minus-5--sm {
    margin-right: -0.35714rem !important;
  }
  .g-mr-6--sm {
    margin-right: 0.42857rem !important;
  }
  .g-mr-minus-6--sm {
    margin-right: -0.42857rem !important;
  }
  .g-mr-7--sm {
    margin-right: 0.5rem !important;
  }
  .g-mr-minus-7--sm {
    margin-right: -0.5rem !important;
  }
  .g-mr-8--sm {
    margin-right: 0.57143rem !important;
  }
  .g-mr-minus-8--sm {
    margin-right: -0.57143rem !important;
  }
  .g-mr-9--sm {
    margin-right: 0.64286rem !important;
  }
  .g-mr-minus-9--sm {
    margin-right: -0.64286rem !important;
  }
  .g-mr-10--sm {
    margin-right: 0.71429rem !important;
  }
  .g-mr-minus-10--sm {
    margin-right: -0.71429rem !important;
  }
  .g-mr-5--sm {
    margin-right: 0.35714rem !important;
  }
  .g-mr-10--sm {
    margin-right: 0.71429rem !important;
  }
  .g-mr-15--sm {
    margin-right: 1.07143rem !important;
  }
  .g-mr-20--sm {
    margin-right: 1.42857rem !important;
  }
  .g-mr-25--sm {
    margin-right: 1.78571rem !important;
  }
  .g-mr-30--sm {
    margin-right: 2.14286rem !important;
  }
  .g-mr-35--sm {
    margin-right: 2.5rem !important;
  }
  .g-mr-40--sm {
    margin-right: 2.85714rem !important;
  }
  .g-mr-45--sm {
    margin-right: 3.21429rem !important;
  }
  .g-mr-50--sm {
    margin-right: 3.57143rem !important;
  }
}

/* Margin Spaces (md)
------------------------------------*/
@media (min-width: 768px) {
  .g-ma-0--md {
    margin: 0 !important;
  }
  .g-mx-0--md {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .g-my-0--md {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .g-ml-0--md {
    margin-left: 0 !important;
  }
  .g-mr-0--md {
    margin-right: 0 !important;
  }
  .g-mt-0--md {
    margin-top: 0 !important;
  }
  .g-mb-0--md {
    margin-bottom: 0 !important;
  }
  .g-mx-1--md {
    margin-left: 0.07143rem !important;
    margin-right: 0.07143rem !important;
  }
  .g-mx-2--md {
    margin-left: 0.14286rem !important;
    margin-right: 0.14286rem !important;
  }
  .g-mx-3--md {
    margin-left: 0.21429rem !important;
    margin-right: 0.21429rem !important;
  }
  .g-mx-4--md {
    margin-left: 0.28571rem !important;
    margin-right: 0.28571rem !important;
  }
  .g-mx-5--md {
    margin-left: 0.35714rem !important;
    margin-right: 0.35714rem !important;
  }
  .g-mx-6--md {
    margin-left: 0.42857rem !important;
    margin-right: 0.42857rem !important;
  }
  .g-mx-7--md {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
  .g-mx-8--md {
    margin-left: 0.57143rem !important;
    margin-right: 0.57143rem !important;
  }
  .g-mx-9--md {
    margin-left: 0.64286rem !important;
    margin-right: 0.64286rem !important;
  }
  .g-mx-10--md {
    margin-left: 0.71429rem !important;
    margin-right: 0.71429rem !important;
  }
  .g-mx-10--md {
    margin-left: 0.71429rem !important;
    margin-right: 0.71429rem !important;
  }
  .g-mx-15--md {
    margin-left: 1.07143rem !important;
    margin-right: 1.07143rem !important;
  }
  .g-mx-20--md {
    margin-left: 1.42857rem !important;
    margin-right: 1.42857rem !important;
  }
  .g-mx-25--md {
    margin-left: 1.78571rem !important;
    margin-right: 1.78571rem !important;
  }
  .g-mx-30--md {
    margin-left: 2.14286rem !important;
    margin-right: 2.14286rem !important;
  }
  .g-mx-35--md {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }
  .g-mx-40--md {
    margin-left: 2.85714rem !important;
    margin-right: 2.85714rem !important;
  }
  .g-mx-45--md {
    margin-left: 3.21429rem !important;
    margin-right: 3.21429rem !important;
  }
  .g-mx-50--md {
    margin-left: 3.57143rem !important;
    margin-right: 3.57143rem !important;
  }
  .g-mx-55--md {
    margin-left: 3.92857rem !important;
    margin-right: 3.92857rem !important;
  }
  .g-mx-60--md {
    margin-left: 4.28571rem !important;
    margin-right: 4.28571rem !important;
  }
  .g-mx-65--md {
    margin-left: 4.64286rem !important;
    margin-right: 4.64286rem !important;
  }
  .g-mx-70--md {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }
  .g-mx-75--md {
    margin-left: 5.35714rem !important;
    margin-right: 5.35714rem !important;
  }
  .g-mx-80--md {
    margin-left: 5.71429rem !important;
    margin-right: 5.71429rem !important;
  }
  .g-mx-85--md {
    margin-left: 6.07143rem !important;
    margin-right: 6.07143rem !important;
  }
  .g-mx-90--md {
    margin-left: 6.42857rem !important;
    margin-right: 6.42857rem !important;
  }
  .g-mx-95--md {
    margin-left: 6.78571rem !important;
    margin-right: 6.78571rem !important;
  }
  .g-mx-100--md {
    margin-left: 7.14286rem !important;
    margin-right: 7.14286rem !important;
  }
  .g-my-1--md {
    margin-top: 0.07143rem !important;
    margin-bottom: 0.07143rem !important;
  }
  .g-my-2--md {
    margin-top: 0.14286rem !important;
    margin-bottom: 0.14286rem !important;
  }
  .g-my-3--md {
    margin-top: 0.21429rem !important;
    margin-bottom: 0.21429rem !important;
  }
  .g-my-4--md {
    margin-top: 0.28571rem !important;
    margin-bottom: 0.28571rem !important;
  }
  .g-my-5--md {
    margin-top: 0.35714rem !important;
    margin-bottom: 0.35714rem !important;
  }
  .g-my-6--md {
    margin-top: 0.42857rem !important;
    margin-bottom: 0.42857rem !important;
  }
  .g-my-7--md {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .g-my-8--md {
    margin-top: 0.57143rem !important;
    margin-bottom: 0.57143rem !important;
  }
  .g-my-9--md {
    margin-top: 0.64286rem !important;
    margin-bottom: 0.64286rem !important;
  }
  .g-my-10--md {
    margin-top: 0.71429rem !important;
    margin-bottom: 0.71429rem !important;
  }
  .g-my-10--md {
    margin-top: 0.71429rem !important;
    margin-bottom: 0.71429rem !important;
  }
  .g-my-15--md {
    margin-top: 1.07143rem !important;
    margin-bottom: 1.07143rem !important;
  }
  .g-my-20--md {
    margin-top: 1.42857rem !important;
    margin-bottom: 1.42857rem !important;
  }
  .g-my-25--md {
    margin-top: 1.78571rem !important;
    margin-bottom: 1.78571rem !important;
  }
  .g-my-30--md {
    margin-top: 2.14286rem !important;
    margin-bottom: 2.14286rem !important;
  }
  .g-my-35--md {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .g-my-40--md {
    margin-top: 2.85714rem !important;
    margin-bottom: 2.85714rem !important;
  }
  .g-my-45--md {
    margin-top: 3.21429rem !important;
    margin-bottom: 3.21429rem !important;
  }
  .g-my-50--md {
    margin-top: 3.57143rem !important;
    margin-bottom: 3.57143rem !important;
  }
  .g-my-55--md {
    margin-top: 3.92857rem !important;
    margin-bottom: 3.92857rem !important;
  }
  .g-my-60--md {
    margin-top: 4.28571rem !important;
    margin-bottom: 4.28571rem !important;
  }
  .g-my-65--md {
    margin-top: 4.64286rem !important;
    margin-bottom: 4.64286rem !important;
  }
  .g-my-70--md {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .g-my-75--md {
    margin-top: 5.35714rem !important;
    margin-bottom: 5.35714rem !important;
  }
  .g-my-80--md {
    margin-top: 5.71429rem !important;
    margin-bottom: 5.71429rem !important;
  }
  .g-my-85--md {
    margin-top: 6.07143rem !important;
    margin-bottom: 6.07143rem !important;
  }
  .g-my-90--md {
    margin-top: 6.42857rem !important;
    margin-bottom: 6.42857rem !important;
  }
  .g-my-95--md {
    margin-top: 6.78571rem !important;
    margin-bottom: 6.78571rem !important;
  }
  .g-my-100--md {
    margin-top: 7.14286rem !important;
    margin-bottom: 7.14286rem !important;
  }
  .g-mt-1--md {
    margin-top: 0.07143rem !important;
  }
  .g-mt-minus-1--md {
    margin-top: -0.07143rem !important;
  }
  .g-mt-2--md {
    margin-top: 0.14286rem !important;
  }
  .g-mt-minus-2--md {
    margin-top: -0.14286rem !important;
  }
  .g-mt-3--md {
    margin-top: 0.21429rem !important;
  }
  .g-mt-minus-3--md {
    margin-top: -0.21429rem !important;
  }
  .g-mt-4--md {
    margin-top: 0.28571rem !important;
  }
  .g-mt-minus-4--md {
    margin-top: -0.28571rem !important;
  }
  .g-mt-5--md {
    margin-top: 0.35714rem !important;
  }
  .g-mt-minus-5--md {
    margin-top: -0.35714rem !important;
  }
  .g-mt-6--md {
    margin-top: 0.42857rem !important;
  }
  .g-mt-minus-6--md {
    margin-top: -0.42857rem !important;
  }
  .g-mt-7--md {
    margin-top: 0.5rem !important;
  }
  .g-mt-minus-7--md {
    margin-top: -0.5rem !important;
  }
  .g-mt-8--md {
    margin-top: 0.57143rem !important;
  }
  .g-mt-minus-8--md {
    margin-top: -0.57143rem !important;
  }
  .g-mt-9--md {
    margin-top: 0.64286rem !important;
  }
  .g-mt-minus-9--md {
    margin-top: -0.64286rem !important;
  }
  .g-mt-10--md {
    margin-top: 0.71429rem !important;
  }
  .g-mt-minus-10--md {
    margin-top: -0.71429rem !important;
  }
  .g-mt-11--md {
    margin-top: 0.78571rem !important;
  }
  .g-mt-minus-11--md {
    margin-top: -0.78571rem !important;
  }
  .g-mt-12--md {
    margin-top: 0.85714rem !important;
  }
  .g-mt-minus-12--md {
    margin-top: -0.85714rem !important;
  }
  .g-mt-13--md {
    margin-top: 0.92857rem !important;
  }
  .g-mt-minus-13--md {
    margin-top: -0.92857rem !important;
  }
  .g-mt-14--md {
    margin-top: 1rem !important;
  }
  .g-mt-minus-14--md {
    margin-top: -1rem !important;
  }
  .g-mt-15--md {
    margin-top: 1.07143rem !important;
  }
  .g-mt-minus-15--md {
    margin-top: -1.07143rem !important;
  }
  .g-mt-16--md {
    margin-top: 1.14286rem !important;
  }
  .g-mt-minus-16--md {
    margin-top: -1.14286rem !important;
  }
  .g-mt-17--md {
    margin-top: 1.21429rem !important;
  }
  .g-mt-minus-17--md {
    margin-top: -1.21429rem !important;
  }
  .g-mt-18--md {
    margin-top: 1.28571rem !important;
  }
  .g-mt-minus-18--md {
    margin-top: -1.28571rem !important;
  }
  .g-mt-19--md {
    margin-top: 1.35714rem !important;
  }
  .g-mt-minus-19--md {
    margin-top: -1.35714rem !important;
  }
  .g-mt-20--md {
    margin-top: 1.42857rem !important;
  }
  .g-mt-minus-20--md {
    margin-top: -1.42857rem !important;
  }
  .g-mt-10--md {
    margin-top: 0.71429rem !important;
  }
  .g-mt-minus-10--md {
    margin-top: -0.71429rem !important;
  }
  .g-mt-15--md {
    margin-top: 1.07143rem !important;
  }
  .g-mt-minus-15--md {
    margin-top: -1.07143rem !important;
  }
  .g-mt-20--md {
    margin-top: 1.42857rem !important;
  }
  .g-mt-minus-20--md {
    margin-top: -1.42857rem !important;
  }
  .g-mt-25--md {
    margin-top: 1.78571rem !important;
  }
  .g-mt-minus-25--md {
    margin-top: -1.78571rem !important;
  }
  .g-mt-30--md {
    margin-top: 2.14286rem !important;
  }
  .g-mt-minus-30--md {
    margin-top: -2.14286rem !important;
  }
  .g-mt-35--md {
    margin-top: 2.5rem !important;
  }
  .g-mt-minus-35--md {
    margin-top: -2.5rem !important;
  }
  .g-mt-40--md {
    margin-top: 2.85714rem !important;
  }
  .g-mt-minus-40--md {
    margin-top: -2.85714rem !important;
  }
  .g-mt-45--md {
    margin-top: 3.21429rem !important;
  }
  .g-mt-minus-45--md {
    margin-top: -3.21429rem !important;
  }
  .g-mt-50--md {
    margin-top: 3.57143rem !important;
  }
  .g-mt-minus-50--md {
    margin-top: -3.57143rem !important;
  }
  .g-mt-55--md {
    margin-top: 3.92857rem !important;
  }
  .g-mt-minus-55--md {
    margin-top: -3.92857rem !important;
  }
  .g-mt-60--md {
    margin-top: 4.28571rem !important;
  }
  .g-mt-minus-60--md {
    margin-top: -4.28571rem !important;
  }
  .g-mt-65--md {
    margin-top: 4.64286rem !important;
  }
  .g-mt-minus-65--md {
    margin-top: -4.64286rem !important;
  }
  .g-mt-70--md {
    margin-top: 5rem !important;
  }
  .g-mt-minus-70--md {
    margin-top: -5rem !important;
  }
  .g-mt-75--md {
    margin-top: 5.35714rem !important;
  }
  .g-mt-minus-75--md {
    margin-top: -5.35714rem !important;
  }
  .g-mt-80--md {
    margin-top: 5.71429rem !important;
  }
  .g-mt-minus-80--md {
    margin-top: -5.71429rem !important;
  }
  .g-mt-85--md {
    margin-top: 6.07143rem !important;
  }
  .g-mt-minus-85--md {
    margin-top: -6.07143rem !important;
  }
  .g-mt-90--md {
    margin-top: 6.42857rem !important;
  }
  .g-mt-minus-90--md {
    margin-top: -6.42857rem !important;
  }
  .g-mt-95--md {
    margin-top: 6.78571rem !important;
  }
  .g-mt-minus-95--md {
    margin-top: -6.78571rem !important;
  }
  .g-mt-100--md {
    margin-top: 7.14286rem !important;
  }
  .g-mt-minus-100--md {
    margin-top: -7.14286rem !important;
  }
  .g-mt-105--md {
    margin-top: 7.5rem !important;
  }
  .g-mt-minus-105--md {
    margin-top: -7.5rem !important;
  }
  .g-mt-110--md {
    margin-top: 7.85714rem !important;
  }
  .g-mt-minus-110--md {
    margin-top: -7.85714rem !important;
  }
  .g-mt-115--md {
    margin-top: 8.21429rem !important;
  }
  .g-mt-minus-115--md {
    margin-top: -8.21429rem !important;
  }
  .g-mt-120--md {
    margin-top: 8.57143rem !important;
  }
  .g-mt-minus-120--md {
    margin-top: -8.57143rem !important;
  }
  .g-mt-125--md {
    margin-top: 8.92857rem !important;
  }
  .g-mt-minus-125--md {
    margin-top: -8.92857rem !important;
  }
  .g-mt-130--md {
    margin-top: 9.28571rem !important;
  }
  .g-mt-minus-130--md {
    margin-top: -9.28571rem !important;
  }
  .g-mt-135--md {
    margin-top: 9.64286rem !important;
  }
  .g-mt-minus-135--md {
    margin-top: -9.64286rem !important;
  }
  .g-mt-140--md {
    margin-top: 10rem !important;
  }
  .g-mt-minus-140--md {
    margin-top: -10rem !important;
  }
  .g-mt-145--md {
    margin-top: 10.35714rem !important;
  }
  .g-mt-minus-145--md {
    margin-top: -10.35714rem !important;
  }
  .g-mt-150--md {
    margin-top: 10.71429rem !important;
  }
  .g-mt-minus-150--md {
    margin-top: -10.71429rem !important;
  }
  .g-mt-155--md {
    margin-top: 11.07143rem !important;
  }
  .g-mt-minus-155--md {
    margin-top: -11.07143rem !important;
  }
  .g-mt-160--md {
    margin-top: 11.42857rem !important;
  }
  .g-mt-minus-160--md {
    margin-top: -11.42857rem !important;
  }
  .g-mt-165--md {
    margin-top: 11.78571rem !important;
  }
  .g-mt-minus-165--md {
    margin-top: -11.78571rem !important;
  }
  .g-mt-170--md {
    margin-top: 12.14286rem !important;
  }
  .g-mt-minus-170--md {
    margin-top: -12.14286rem !important;
  }
  .g-mb-1--md {
    margin-bottom: 0.07143rem !important;
  }
  .g-mb-minus-1--md {
    margin-bottom: -0.07143rem !important;
  }
  .g-mb-2--md {
    margin-bottom: 0.14286rem !important;
  }
  .g-mb-minus-2--md {
    margin-bottom: -0.14286rem !important;
  }
  .g-mb-3--md {
    margin-bottom: 0.21429rem !important;
  }
  .g-mb-minus-3--md {
    margin-bottom: -0.21429rem !important;
  }
  .g-mb-4--md {
    margin-bottom: 0.28571rem !important;
  }
  .g-mb-minus-4--md {
    margin-bottom: -0.28571rem !important;
  }
  .g-mb-5--md {
    margin-bottom: 0.35714rem !important;
  }
  .g-mb-minus-5--md {
    margin-bottom: -0.35714rem !important;
  }
  .g-mb-6--md {
    margin-bottom: 0.42857rem !important;
  }
  .g-mb-minus-6--md {
    margin-bottom: -0.42857rem !important;
  }
  .g-mb-7--md {
    margin-bottom: 0.5rem !important;
  }
  .g-mb-minus-7--md {
    margin-bottom: -0.5rem !important;
  }
  .g-mb-8--md {
    margin-bottom: 0.57143rem !important;
  }
  .g-mb-minus-8--md {
    margin-bottom: -0.57143rem !important;
  }
  .g-mb-9--md {
    margin-bottom: 0.64286rem !important;
  }
  .g-mb-minus-9--md {
    margin-bottom: -0.64286rem !important;
  }
  .g-mb-10--md {
    margin-bottom: 0.71429rem !important;
  }
  .g-mb-minus-10--md {
    margin-bottom: -0.71429rem !important;
  }
  .g-mb-11--md {
    margin-bottom: 0.78571rem !important;
  }
  .g-mb-minus-11--md {
    margin-bottom: -0.78571rem !important;
  }
  .g-mb-12--md {
    margin-bottom: 0.85714rem !important;
  }
  .g-mb-minus-12--md {
    margin-bottom: -0.85714rem !important;
  }
  .g-mb-13--md {
    margin-bottom: 0.92857rem !important;
  }
  .g-mb-minus-13--md {
    margin-bottom: -0.92857rem !important;
  }
  .g-mb-14--md {
    margin-bottom: 1rem !important;
  }
  .g-mb-minus-14--md {
    margin-bottom: -1rem !important;
  }
  .g-mb-15--md {
    margin-bottom: 1.07143rem !important;
  }
  .g-mb-minus-15--md {
    margin-bottom: -1.07143rem !important;
  }
  .g-mb-16--md {
    margin-bottom: 1.14286rem !important;
  }
  .g-mb-minus-16--md {
    margin-bottom: -1.14286rem !important;
  }
  .g-mb-17--md {
    margin-bottom: 1.21429rem !important;
  }
  .g-mb-minus-17--md {
    margin-bottom: -1.21429rem !important;
  }
  .g-mb-18--md {
    margin-bottom: 1.28571rem !important;
  }
  .g-mb-minus-18--md {
    margin-bottom: -1.28571rem !important;
  }
  .g-mb-19--md {
    margin-bottom: 1.35714rem !important;
  }
  .g-mb-minus-19--md {
    margin-bottom: -1.35714rem !important;
  }
  .g-mb-20--md {
    margin-bottom: 1.42857rem !important;
  }
  .g-mb-minus-20--md {
    margin-bottom: -1.42857rem !important;
  }
  .g-mb-10--md {
    margin-bottom: 0.71429rem !important;
  }
  .g-mb-15--md {
    margin-bottom: 1.07143rem !important;
  }
  .g-mb-20--md {
    margin-bottom: 1.42857rem !important;
  }
  .g-mb-25--md {
    margin-bottom: 1.78571rem !important;
  }
  .g-mb-30--md {
    margin-bottom: 2.14286rem !important;
  }
  .g-mb-35--md {
    margin-bottom: 2.5rem !important;
  }
  .g-mb-40--md {
    margin-bottom: 2.85714rem !important;
  }
  .g-mb-45--md {
    margin-bottom: 3.21429rem !important;
  }
  .g-mb-50--md {
    margin-bottom: 3.57143rem !important;
  }
  .g-mb-55--md {
    margin-bottom: 3.92857rem !important;
  }
  .g-mb-60--md {
    margin-bottom: 4.28571rem !important;
  }
  .g-mb-65--md {
    margin-bottom: 4.64286rem !important;
  }
  .g-mb-70--md {
    margin-bottom: 5rem !important;
  }
  .g-mb-75--md {
    margin-bottom: 5.35714rem !important;
  }
  .g-mb-80--md {
    margin-bottom: 5.71429rem !important;
  }
  .g-mb-85--md {
    margin-bottom: 6.07143rem !important;
  }
  .g-mb-90--md {
    margin-bottom: 6.42857rem !important;
  }
  .g-mb-95--md {
    margin-bottom: 6.78571rem !important;
  }
  .g-mb-100--md {
    margin-bottom: 7.14286rem !important;
  }
  .g-mb-105--md {
    margin-bottom: 7.5rem !important;
  }
  .g-mb-110--md {
    margin-bottom: 7.85714rem !important;
  }
  .g-mb-115--md {
    margin-bottom: 8.21429rem !important;
  }
  .g-mb-120--md {
    margin-bottom: 8.57143rem !important;
  }
  .g-mb-125--md {
    margin-bottom: 8.92857rem !important;
  }
  .g-mb-130--md {
    margin-bottom: 9.28571rem !important;
  }
  .g-mb-135--md {
    margin-bottom: 9.64286rem !important;
  }
  .g-mb-140--md {
    margin-bottom: 10rem !important;
  }
  .g-mb-145--md {
    margin-bottom: 10.35714rem !important;
  }
  .g-mb-150--md {
    margin-bottom: 10.71429rem !important;
  }
  .g-mb-155--md {
    margin-bottom: 11.07143rem !important;
  }
  .g-mb-160--md {
    margin-bottom: 11.42857rem !important;
  }
  .g-mb-165--md {
    margin-bottom: 11.78571rem !important;
  }
  .g-mb-170--md {
    margin-bottom: 12.14286rem !important;
  }
  .g-ml-1--md {
    margin-left: 0.07143rem !important;
  }
  .g-ml-minus-1--md {
    margin-left: -0.07143rem !important;
  }
  .g-ml-2--md {
    margin-left: 0.14286rem !important;
  }
  .g-ml-minus-2--md {
    margin-left: -0.14286rem !important;
  }
  .g-ml-3--md {
    margin-left: 0.21429rem !important;
  }
  .g-ml-minus-3--md {
    margin-left: -0.21429rem !important;
  }
  .g-ml-4--md {
    margin-left: 0.28571rem !important;
  }
  .g-ml-minus-4--md {
    margin-left: -0.28571rem !important;
  }
  .g-ml-5--md {
    margin-left: 0.35714rem !important;
  }
  .g-ml-minus-5--md {
    margin-left: -0.35714rem !important;
  }
  .g-ml-6--md {
    margin-left: 0.42857rem !important;
  }
  .g-ml-minus-6--md {
    margin-left: -0.42857rem !important;
  }
  .g-ml-7--md {
    margin-left: 0.5rem !important;
  }
  .g-ml-minus-7--md {
    margin-left: -0.5rem !important;
  }
  .g-ml-8--md {
    margin-left: 0.57143rem !important;
  }
  .g-ml-minus-8--md {
    margin-left: -0.57143rem !important;
  }
  .g-ml-9--md {
    margin-left: 0.64286rem !important;
  }
  .g-ml-minus-9--md {
    margin-left: -0.64286rem !important;
  }
  .g-ml-10--md {
    margin-left: 0.71429rem !important;
  }
  .g-ml-minus-10--md {
    margin-left: -0.71429rem !important;
  }
  .g-ml-5--md {
    margin-left: 0.35714rem !important;
  }
  .g-ml-minus-5--md {
    margin-left: -0.35714rem !important;
  }
  .g-ml-10--md {
    margin-left: 0.71429rem !important;
  }
  .g-ml-minus-10--md {
    margin-left: -0.71429rem !important;
  }
  .g-ml-15--md {
    margin-left: 1.07143rem !important;
  }
  .g-ml-minus-15--md {
    margin-left: -1.07143rem !important;
  }
  .g-ml-20--md {
    margin-left: 1.42857rem !important;
  }
  .g-ml-minus-20--md {
    margin-left: -1.42857rem !important;
  }
  .g-ml-25--md {
    margin-left: 1.78571rem !important;
  }
  .g-ml-minus-25--md {
    margin-left: -1.78571rem !important;
  }
  .g-ml-30--md {
    margin-left: 2.14286rem !important;
  }
  .g-ml-minus-30--md {
    margin-left: -2.14286rem !important;
  }
  .g-ml-35--md {
    margin-left: 2.5rem !important;
  }
  .g-ml-minus-35--md {
    margin-left: -2.5rem !important;
  }
  .g-ml-40--md {
    margin-left: 2.85714rem !important;
  }
  .g-ml-minus-40--md {
    margin-left: -2.85714rem !important;
  }
  .g-ml-45--md {
    margin-left: 3.21429rem !important;
  }
  .g-ml-minus-45--md {
    margin-left: -3.21429rem !important;
  }
  .g-ml-50--md {
    margin-left: 3.57143rem !important;
  }
  .g-ml-minus-50--md {
    margin-left: -3.57143rem !important;
  }
  .g-mr-1--md {
    margin-right: 0.07143rem !important;
  }
  .g-mr-minus-1--md {
    margin-right: -0.07143rem !important;
  }
  .g-mr-2--md {
    margin-right: 0.14286rem !important;
  }
  .g-mr-minus-2--md {
    margin-right: -0.14286rem !important;
  }
  .g-mr-3--md {
    margin-right: 0.21429rem !important;
  }
  .g-mr-minus-3--md {
    margin-right: -0.21429rem !important;
  }
  .g-mr-4--md {
    margin-right: 0.28571rem !important;
  }
  .g-mr-minus-4--md {
    margin-right: -0.28571rem !important;
  }
  .g-mr-5--md {
    margin-right: 0.35714rem !important;
  }
  .g-mr-minus-5--md {
    margin-right: -0.35714rem !important;
  }
  .g-mr-6--md {
    margin-right: 0.42857rem !important;
  }
  .g-mr-minus-6--md {
    margin-right: -0.42857rem !important;
  }
  .g-mr-7--md {
    margin-right: 0.5rem !important;
  }
  .g-mr-minus-7--md {
    margin-right: -0.5rem !important;
  }
  .g-mr-8--md {
    margin-right: 0.57143rem !important;
  }
  .g-mr-minus-8--md {
    margin-right: -0.57143rem !important;
  }
  .g-mr-9--md {
    margin-right: 0.64286rem !important;
  }
  .g-mr-minus-9--md {
    margin-right: -0.64286rem !important;
  }
  .g-mr-10--md {
    margin-right: 0.71429rem !important;
  }
  .g-mr-minus-10--md {
    margin-right: -0.71429rem !important;
  }
  .g-mr-5--md {
    margin-right: 0.35714rem !important;
  }
  .g-mr-10--md {
    margin-right: 0.71429rem !important;
  }
  .g-mr-15--md {
    margin-right: 1.07143rem !important;
  }
  .g-mr-20--md {
    margin-right: 1.42857rem !important;
  }
  .g-mr-25--md {
    margin-right: 1.78571rem !important;
  }
  .g-mr-30--md {
    margin-right: 2.14286rem !important;
  }
  .g-mr-35--md {
    margin-right: 2.5rem !important;
  }
  .g-mr-40--md {
    margin-right: 2.85714rem !important;
  }
  .g-mr-45--md {
    margin-right: 3.21429rem !important;
  }
  .g-mr-50--md {
    margin-right: 3.57143rem !important;
  }
}

/* Margin Spaces (lg)
------------------------------------*/
@media (min-width: 992px) {
  .g-ma-0--lg {
    margin: 0 !important;
  }
  .g-mx-0--lg {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .g-my-0--lg {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .g-ml-0--lg {
    margin-left: 0 !important;
  }
  .g-mr-0--lg {
    margin-right: 0 !important;
  }
  .g-mt-0--lg {
    margin-top: 0 !important;
  }
  .g-mb-0--lg {
    margin-bottom: 0 !important;
  }
  .g-mx-1--lg {
    margin-left: 0.07143rem !important;
    margin-right: 0.07143rem !important;
  }
  .g-mx-2--lg {
    margin-left: 0.14286rem !important;
    margin-right: 0.14286rem !important;
  }
  .g-mx-3--lg {
    margin-left: 0.21429rem !important;
    margin-right: 0.21429rem !important;
  }
  .g-mx-4--lg {
    margin-left: 0.28571rem !important;
    margin-right: 0.28571rem !important;
  }
  .g-mx-5--lg {
    margin-left: 0.35714rem !important;
    margin-right: 0.35714rem !important;
  }
  .g-mx-6--lg {
    margin-left: 0.42857rem !important;
    margin-right: 0.42857rem !important;
  }
  .g-mx-7--lg {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
  .g-mx-8--lg {
    margin-left: 0.57143rem !important;
    margin-right: 0.57143rem !important;
  }
  .g-mx-9--lg {
    margin-left: 0.64286rem !important;
    margin-right: 0.64286rem !important;
  }
  .g-mx-10--lg {
    margin-left: 0.71429rem !important;
    margin-right: 0.71429rem !important;
  }
  .g-mx-10--lg {
    margin-left: 0.71429rem !important;
    margin-right: 0.71429rem !important;
  }
  .g-mx-15--lg {
    margin-left: 1.07143rem !important;
    margin-right: 1.07143rem !important;
  }
  .g-mx-20--lg {
    margin-left: 1.42857rem !important;
    margin-right: 1.42857rem !important;
  }
  .g-mx-25--lg {
    margin-left: 1.78571rem !important;
    margin-right: 1.78571rem !important;
  }
  .g-mx-30--lg {
    margin-left: 2.14286rem !important;
    margin-right: 2.14286rem !important;
  }
  .g-mx-35--lg {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }
  .g-mx-40--lg {
    margin-left: 2.85714rem !important;
    margin-right: 2.85714rem !important;
  }
  .g-mx-45--lg {
    margin-left: 3.21429rem !important;
    margin-right: 3.21429rem !important;
  }
  .g-mx-50--lg {
    margin-left: 3.57143rem !important;
    margin-right: 3.57143rem !important;
  }
  .g-mx-55--lg {
    margin-left: 3.92857rem !important;
    margin-right: 3.92857rem !important;
  }
  .g-mx-60--lg {
    margin-left: 4.28571rem !important;
    margin-right: 4.28571rem !important;
  }
  .g-mx-65--lg {
    margin-left: 4.64286rem !important;
    margin-right: 4.64286rem !important;
  }
  .g-mx-70--lg {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }
  .g-mx-75--lg {
    margin-left: 5.35714rem !important;
    margin-right: 5.35714rem !important;
  }
  .g-mx-80--lg {
    margin-left: 5.71429rem !important;
    margin-right: 5.71429rem !important;
  }
  .g-mx-85--lg {
    margin-left: 6.07143rem !important;
    margin-right: 6.07143rem !important;
  }
  .g-mx-90--lg {
    margin-left: 6.42857rem !important;
    margin-right: 6.42857rem !important;
  }
  .g-mx-95--lg {
    margin-left: 6.78571rem !important;
    margin-right: 6.78571rem !important;
  }
  .g-mx-100--lg {
    margin-left: 7.14286rem !important;
    margin-right: 7.14286rem !important;
  }
  .g-my-1--lg {
    margin-top: 0.07143rem !important;
    margin-bottom: 0.07143rem !important;
  }
  .g-my-2--lg {
    margin-top: 0.14286rem !important;
    margin-bottom: 0.14286rem !important;
  }
  .g-my-3--lg {
    margin-top: 0.21429rem !important;
    margin-bottom: 0.21429rem !important;
  }
  .g-my-4--lg {
    margin-top: 0.28571rem !important;
    margin-bottom: 0.28571rem !important;
  }
  .g-my-5--lg {
    margin-top: 0.35714rem !important;
    margin-bottom: 0.35714rem !important;
  }
  .g-my-6--lg {
    margin-top: 0.42857rem !important;
    margin-bottom: 0.42857rem !important;
  }
  .g-my-7--lg {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .g-my-8--lg {
    margin-top: 0.57143rem !important;
    margin-bottom: 0.57143rem !important;
  }
  .g-my-9--lg {
    margin-top: 0.64286rem !important;
    margin-bottom: 0.64286rem !important;
  }
  .g-my-10--lg {
    margin-top: 0.71429rem !important;
    margin-bottom: 0.71429rem !important;
  }
  .g-my-10--lg {
    margin-top: 0.71429rem !important;
    margin-bottom: 0.71429rem !important;
  }
  .g-my-15--lg {
    margin-top: 1.07143rem !important;
    margin-bottom: 1.07143rem !important;
  }
  .g-my-20--lg {
    margin-top: 1.42857rem !important;
    margin-bottom: 1.42857rem !important;
  }
  .g-my-25--lg {
    margin-top: 1.78571rem !important;
    margin-bottom: 1.78571rem !important;
  }
  .g-my-30--lg {
    margin-top: 2.14286rem !important;
    margin-bottom: 2.14286rem !important;
  }
  .g-my-35--lg {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .g-my-40--lg {
    margin-top: 2.85714rem !important;
    margin-bottom: 2.85714rem !important;
  }
  .g-my-45--lg {
    margin-top: 3.21429rem !important;
    margin-bottom: 3.21429rem !important;
  }
  .g-my-50--lg {
    margin-top: 3.57143rem !important;
    margin-bottom: 3.57143rem !important;
  }
  .g-my-55--lg {
    margin-top: 3.92857rem !important;
    margin-bottom: 3.92857rem !important;
  }
  .g-my-60--lg {
    margin-top: 4.28571rem !important;
    margin-bottom: 4.28571rem !important;
  }
  .g-my-65--lg {
    margin-top: 4.64286rem !important;
    margin-bottom: 4.64286rem !important;
  }
  .g-my-70--lg {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .g-my-75--lg {
    margin-top: 5.35714rem !important;
    margin-bottom: 5.35714rem !important;
  }
  .g-my-80--lg {
    margin-top: 5.71429rem !important;
    margin-bottom: 5.71429rem !important;
  }
  .g-my-85--lg {
    margin-top: 6.07143rem !important;
    margin-bottom: 6.07143rem !important;
  }
  .g-my-90--lg {
    margin-top: 6.42857rem !important;
    margin-bottom: 6.42857rem !important;
  }
  .g-my-95--lg {
    margin-top: 6.78571rem !important;
    margin-bottom: 6.78571rem !important;
  }
  .g-my-100--lg {
    margin-top: 7.14286rem !important;
    margin-bottom: 7.14286rem !important;
  }
  .g-mt-1--lg {
    margin-top: 0.07143rem !important;
  }
  .g-mt-minus-1--lg {
    margin-top: -0.07143rem !important;
  }
  .g-mt-2--lg {
    margin-top: 0.14286rem !important;
  }
  .g-mt-minus-2--lg {
    margin-top: -0.14286rem !important;
  }
  .g-mt-3--lg {
    margin-top: 0.21429rem !important;
  }
  .g-mt-minus-3--lg {
    margin-top: -0.21429rem !important;
  }
  .g-mt-4--lg {
    margin-top: 0.28571rem !important;
  }
  .g-mt-minus-4--lg {
    margin-top: -0.28571rem !important;
  }
  .g-mt-5--lg {
    margin-top: 0.35714rem !important;
  }
  .g-mt-minus-5--lg {
    margin-top: -0.35714rem !important;
  }
  .g-mt-6--lg {
    margin-top: 0.42857rem !important;
  }
  .g-mt-minus-6--lg {
    margin-top: -0.42857rem !important;
  }
  .g-mt-7--lg {
    margin-top: 0.5rem !important;
  }
  .g-mt-minus-7--lg {
    margin-top: -0.5rem !important;
  }
  .g-mt-8--lg {
    margin-top: 0.57143rem !important;
  }
  .g-mt-minus-8--lg {
    margin-top: -0.57143rem !important;
  }
  .g-mt-9--lg {
    margin-top: 0.64286rem !important;
  }
  .g-mt-minus-9--lg {
    margin-top: -0.64286rem !important;
  }
  .g-mt-10--lg {
    margin-top: 0.71429rem !important;
  }
  .g-mt-minus-10--lg {
    margin-top: -0.71429rem !important;
  }
  .g-mt-11--lg {
    margin-top: 0.78571rem !important;
  }
  .g-mt-minus-11--lg {
    margin-top: -0.78571rem !important;
  }
  .g-mt-12--lg {
    margin-top: 0.85714rem !important;
  }
  .g-mt-minus-12--lg {
    margin-top: -0.85714rem !important;
  }
  .g-mt-13--lg {
    margin-top: 0.92857rem !important;
  }
  .g-mt-minus-13--lg {
    margin-top: -0.92857rem !important;
  }
  .g-mt-14--lg {
    margin-top: 1rem !important;
  }
  .g-mt-minus-14--lg {
    margin-top: -1rem !important;
  }
  .g-mt-15--lg {
    margin-top: 1.07143rem !important;
  }
  .g-mt-minus-15--lg {
    margin-top: -1.07143rem !important;
  }
  .g-mt-16--lg {
    margin-top: 1.14286rem !important;
  }
  .g-mt-minus-16--lg {
    margin-top: -1.14286rem !important;
  }
  .g-mt-17--lg {
    margin-top: 1.21429rem !important;
  }
  .g-mt-minus-17--lg {
    margin-top: -1.21429rem !important;
  }
  .g-mt-18--lg {
    margin-top: 1.28571rem !important;
  }
  .g-mt-minus-18--lg {
    margin-top: -1.28571rem !important;
  }
  .g-mt-19--lg {
    margin-top: 1.35714rem !important;
  }
  .g-mt-minus-19--lg {
    margin-top: -1.35714rem !important;
  }
  .g-mt-20--lg {
    margin-top: 1.42857rem !important;
  }
  .g-mt-minus-20--lg {
    margin-top: -1.42857rem !important;
  }
  .g-mt-10--lg {
    margin-top: 0.71429rem !important;
  }
  .g-mt-minus-10--lg {
    margin-top: -0.71429rem !important;
  }
  .g-mt-15--lg {
    margin-top: 1.07143rem !important;
  }
  .g-mt-minus-15--lg {
    margin-top: -1.07143rem !important;
  }
  .g-mt-20--lg {
    margin-top: 1.42857rem !important;
  }
  .g-mt-minus-20--lg {
    margin-top: -1.42857rem !important;
  }
  .g-mt-25--lg {
    margin-top: 1.78571rem !important;
  }
  .g-mt-minus-25--lg {
    margin-top: -1.78571rem !important;
  }
  .g-mt-30--lg {
    margin-top: 2.14286rem !important;
  }
  .g-mt-minus-30--lg {
    margin-top: -2.14286rem !important;
  }
  .g-mt-35--lg {
    margin-top: 2.5rem !important;
  }
  .g-mt-minus-35--lg {
    margin-top: -2.5rem !important;
  }
  .g-mt-40--lg {
    margin-top: 2.85714rem !important;
  }
  .g-mt-minus-40--lg {
    margin-top: -2.85714rem !important;
  }
  .g-mt-45--lg {
    margin-top: 3.21429rem !important;
  }
  .g-mt-minus-45--lg {
    margin-top: -3.21429rem !important;
  }
  .g-mt-50--lg {
    margin-top: 3.57143rem !important;
  }
  .g-mt-minus-50--lg {
    margin-top: -3.57143rem !important;
  }
  .g-mt-55--lg {
    margin-top: 3.92857rem !important;
  }
  .g-mt-minus-55--lg {
    margin-top: -3.92857rem !important;
  }
  .g-mt-60--lg {
    margin-top: 4.28571rem !important;
  }
  .g-mt-minus-60--lg {
    margin-top: -4.28571rem !important;
  }
  .g-mt-65--lg {
    margin-top: 4.64286rem !important;
  }
  .g-mt-minus-65--lg {
    margin-top: -4.64286rem !important;
  }
  .g-mt-70--lg {
    margin-top: 5rem !important;
  }
  .g-mt-minus-70--lg {
    margin-top: -5rem !important;
  }
  .g-mt-75--lg {
    margin-top: 5.35714rem !important;
  }
  .g-mt-minus-75--lg {
    margin-top: -5.35714rem !important;
  }
  .g-mt-80--lg {
    margin-top: 5.71429rem !important;
  }
  .g-mt-minus-80--lg {
    margin-top: -5.71429rem !important;
  }
  .g-mt-85--lg {
    margin-top: 6.07143rem !important;
  }
  .g-mt-minus-85--lg {
    margin-top: -6.07143rem !important;
  }
  .g-mt-90--lg {
    margin-top: 6.42857rem !important;
  }
  .g-mt-minus-90--lg {
    margin-top: -6.42857rem !important;
  }
  .g-mt-95--lg {
    margin-top: 6.78571rem !important;
  }
  .g-mt-minus-95--lg {
    margin-top: -6.78571rem !important;
  }
  .g-mt-100--lg {
    margin-top: 7.14286rem !important;
  }
  .g-mt-minus-100--lg {
    margin-top: -7.14286rem !important;
  }
  .g-mt-105--lg {
    margin-top: 7.5rem !important;
  }
  .g-mt-minus-105--lg {
    margin-top: -7.5rem !important;
  }
  .g-mt-110--lg {
    margin-top: 7.85714rem !important;
  }
  .g-mt-minus-110--lg {
    margin-top: -7.85714rem !important;
  }
  .g-mt-115--lg {
    margin-top: 8.21429rem !important;
  }
  .g-mt-minus-115--lg {
    margin-top: -8.21429rem !important;
  }
  .g-mt-120--lg {
    margin-top: 8.57143rem !important;
  }
  .g-mt-minus-120--lg {
    margin-top: -8.57143rem !important;
  }
  .g-mt-125--lg {
    margin-top: 8.92857rem !important;
  }
  .g-mt-minus-125--lg {
    margin-top: -8.92857rem !important;
  }
  .g-mt-130--lg {
    margin-top: 9.28571rem !important;
  }
  .g-mt-minus-130--lg {
    margin-top: -9.28571rem !important;
  }
  .g-mt-135--lg {
    margin-top: 9.64286rem !important;
  }
  .g-mt-minus-135--lg {
    margin-top: -9.64286rem !important;
  }
  .g-mt-140--lg {
    margin-top: 10rem !important;
  }
  .g-mt-minus-140--lg {
    margin-top: -10rem !important;
  }
  .g-mt-145--lg {
    margin-top: 10.35714rem !important;
  }
  .g-mt-minus-145--lg {
    margin-top: -10.35714rem !important;
  }
  .g-mt-150--lg {
    margin-top: 10.71429rem !important;
  }
  .g-mt-minus-150--lg {
    margin-top: -10.71429rem !important;
  }
  .g-mt-155--lg {
    margin-top: 11.07143rem !important;
  }
  .g-mt-minus-155--lg {
    margin-top: -11.07143rem !important;
  }
  .g-mt-160--lg {
    margin-top: 11.42857rem !important;
  }
  .g-mt-minus-160--lg {
    margin-top: -11.42857rem !important;
  }
  .g-mt-165--lg {
    margin-top: 11.78571rem !important;
  }
  .g-mt-minus-165--lg {
    margin-top: -11.78571rem !important;
  }
  .g-mt-170--lg {
    margin-top: 12.14286rem !important;
  }
  .g-mt-minus-170--lg {
    margin-top: -12.14286rem !important;
  }
  .g-mb-1--lg {
    margin-bottom: 0.07143rem !important;
  }
  .g-mb-minus-1--lg {
    margin-bottom: -0.07143rem !important;
  }
  .g-mb-2--lg {
    margin-bottom: 0.14286rem !important;
  }
  .g-mb-minus-2--lg {
    margin-bottom: -0.14286rem !important;
  }
  .g-mb-3--lg {
    margin-bottom: 0.21429rem !important;
  }
  .g-mb-minus-3--lg {
    margin-bottom: -0.21429rem !important;
  }
  .g-mb-4--lg {
    margin-bottom: 0.28571rem !important;
  }
  .g-mb-minus-4--lg {
    margin-bottom: -0.28571rem !important;
  }
  .g-mb-5--lg {
    margin-bottom: 0.35714rem !important;
  }
  .g-mb-minus-5--lg {
    margin-bottom: -0.35714rem !important;
  }
  .g-mb-6--lg {
    margin-bottom: 0.42857rem !important;
  }
  .g-mb-minus-6--lg {
    margin-bottom: -0.42857rem !important;
  }
  .g-mb-7--lg {
    margin-bottom: 0.5rem !important;
  }
  .g-mb-minus-7--lg {
    margin-bottom: -0.5rem !important;
  }
  .g-mb-8--lg {
    margin-bottom: 0.57143rem !important;
  }
  .g-mb-minus-8--lg {
    margin-bottom: -0.57143rem !important;
  }
  .g-mb-9--lg {
    margin-bottom: 0.64286rem !important;
  }
  .g-mb-minus-9--lg {
    margin-bottom: -0.64286rem !important;
  }
  .g-mb-10--lg {
    margin-bottom: 0.71429rem !important;
  }
  .g-mb-minus-10--lg {
    margin-bottom: -0.71429rem !important;
  }
  .g-mb-11--lg {
    margin-bottom: 0.78571rem !important;
  }
  .g-mb-minus-11--lg {
    margin-bottom: -0.78571rem !important;
  }
  .g-mb-12--lg {
    margin-bottom: 0.85714rem !important;
  }
  .g-mb-minus-12--lg {
    margin-bottom: -0.85714rem !important;
  }
  .g-mb-13--lg {
    margin-bottom: 0.92857rem !important;
  }
  .g-mb-minus-13--lg {
    margin-bottom: -0.92857rem !important;
  }
  .g-mb-14--lg {
    margin-bottom: 1rem !important;
  }
  .g-mb-minus-14--lg {
    margin-bottom: -1rem !important;
  }
  .g-mb-15--lg {
    margin-bottom: 1.07143rem !important;
  }
  .g-mb-minus-15--lg {
    margin-bottom: -1.07143rem !important;
  }
  .g-mb-16--lg {
    margin-bottom: 1.14286rem !important;
  }
  .g-mb-minus-16--lg {
    margin-bottom: -1.14286rem !important;
  }
  .g-mb-17--lg {
    margin-bottom: 1.21429rem !important;
  }
  .g-mb-minus-17--lg {
    margin-bottom: -1.21429rem !important;
  }
  .g-mb-18--lg {
    margin-bottom: 1.28571rem !important;
  }
  .g-mb-minus-18--lg {
    margin-bottom: -1.28571rem !important;
  }
  .g-mb-19--lg {
    margin-bottom: 1.35714rem !important;
  }
  .g-mb-minus-19--lg {
    margin-bottom: -1.35714rem !important;
  }
  .g-mb-20--lg {
    margin-bottom: 1.42857rem !important;
  }
  .g-mb-minus-20--lg {
    margin-bottom: -1.42857rem !important;
  }
  .g-mb-10--lg {
    margin-bottom: 0.71429rem !important;
  }
  .g-mb-15--lg {
    margin-bottom: 1.07143rem !important;
  }
  .g-mb-20--lg {
    margin-bottom: 1.42857rem !important;
  }
  .g-mb-25--lg {
    margin-bottom: 1.78571rem !important;
  }
  .g-mb-30--lg {
    margin-bottom: 2.14286rem !important;
  }
  .g-mb-35--lg {
    margin-bottom: 2.5rem !important;
  }
  .g-mb-40--lg {
    margin-bottom: 2.85714rem !important;
  }
  .g-mb-45--lg {
    margin-bottom: 3.21429rem !important;
  }
  .g-mb-50--lg {
    margin-bottom: 3.57143rem !important;
  }
  .g-mb-55--lg {
    margin-bottom: 3.92857rem !important;
  }
  .g-mb-60--lg {
    margin-bottom: 4.28571rem !important;
  }
  .g-mb-65--lg {
    margin-bottom: 4.64286rem !important;
  }
  .g-mb-70--lg {
    margin-bottom: 5rem !important;
  }
  .g-mb-75--lg {
    margin-bottom: 5.35714rem !important;
  }
  .g-mb-80--lg {
    margin-bottom: 5.71429rem !important;
  }
  .g-mb-85--lg {
    margin-bottom: 6.07143rem !important;
  }
  .g-mb-90--lg {
    margin-bottom: 6.42857rem !important;
  }
  .g-mb-95--lg {
    margin-bottom: 6.78571rem !important;
  }
  .g-mb-100--lg {
    margin-bottom: 7.14286rem !important;
  }
  .g-mb-105--lg {
    margin-bottom: 7.5rem !important;
  }
  .g-mb-110--lg {
    margin-bottom: 7.85714rem !important;
  }
  .g-mb-115--lg {
    margin-bottom: 8.21429rem !important;
  }
  .g-mb-120--lg {
    margin-bottom: 8.57143rem !important;
  }
  .g-mb-125--lg {
    margin-bottom: 8.92857rem !important;
  }
  .g-mb-130--lg {
    margin-bottom: 9.28571rem !important;
  }
  .g-mb-135--lg {
    margin-bottom: 9.64286rem !important;
  }
  .g-mb-140--lg {
    margin-bottom: 10rem !important;
  }
  .g-mb-145--lg {
    margin-bottom: 10.35714rem !important;
  }
  .g-mb-150--lg {
    margin-bottom: 10.71429rem !important;
  }
  .g-mb-155--lg {
    margin-bottom: 11.07143rem !important;
  }
  .g-mb-160--lg {
    margin-bottom: 11.42857rem !important;
  }
  .g-mb-165--lg {
    margin-bottom: 11.78571rem !important;
  }
  .g-mb-170--lg {
    margin-bottom: 12.14286rem !important;
  }
  .g-ml-1--lg {
    margin-left: 0.07143rem !important;
  }
  .g-ml-minus-1--lg {
    margin-left: -0.07143rem !important;
  }
  .g-ml-2--lg {
    margin-left: 0.14286rem !important;
  }
  .g-ml-minus-2--lg {
    margin-left: -0.14286rem !important;
  }
  .g-ml-3--lg {
    margin-left: 0.21429rem !important;
  }
  .g-ml-minus-3--lg {
    margin-left: -0.21429rem !important;
  }
  .g-ml-4--lg {
    margin-left: 0.28571rem !important;
  }
  .g-ml-minus-4--lg {
    margin-left: -0.28571rem !important;
  }
  .g-ml-5--lg {
    margin-left: 0.35714rem !important;
  }
  .g-ml-minus-5--lg {
    margin-left: -0.35714rem !important;
  }
  .g-ml-6--lg {
    margin-left: 0.42857rem !important;
  }
  .g-ml-minus-6--lg {
    margin-left: -0.42857rem !important;
  }
  .g-ml-7--lg {
    margin-left: 0.5rem !important;
  }
  .g-ml-minus-7--lg {
    margin-left: -0.5rem !important;
  }
  .g-ml-8--lg {
    margin-left: 0.57143rem !important;
  }
  .g-ml-minus-8--lg {
    margin-left: -0.57143rem !important;
  }
  .g-ml-9--lg {
    margin-left: 0.64286rem !important;
  }
  .g-ml-minus-9--lg {
    margin-left: -0.64286rem !important;
  }
  .g-ml-10--lg {
    margin-left: 0.71429rem !important;
  }
  .g-ml-minus-10--lg {
    margin-left: -0.71429rem !important;
  }
  .g-ml-5--lg {
    margin-left: 0.35714rem !important;
  }
  .g-ml-minus-5--lg {
    margin-left: -0.35714rem !important;
  }
  .g-ml-10--lg {
    margin-left: 0.71429rem !important;
  }
  .g-ml-minus-10--lg {
    margin-left: -0.71429rem !important;
  }
  .g-ml-15--lg {
    margin-left: 1.07143rem !important;
  }
  .g-ml-minus-15--lg {
    margin-left: -1.07143rem !important;
  }
  .g-ml-20--lg {
    margin-left: 1.42857rem !important;
  }
  .g-ml-minus-20--lg {
    margin-left: -1.42857rem !important;
  }
  .g-ml-25--lg {
    margin-left: 1.78571rem !important;
  }
  .g-ml-minus-25--lg {
    margin-left: -1.78571rem !important;
  }
  .g-ml-30--lg {
    margin-left: 2.14286rem !important;
  }
  .g-ml-minus-30--lg {
    margin-left: -2.14286rem !important;
  }
  .g-ml-35--lg {
    margin-left: 2.5rem !important;
  }
  .g-ml-minus-35--lg {
    margin-left: -2.5rem !important;
  }
  .g-ml-40--lg {
    margin-left: 2.85714rem !important;
  }
  .g-ml-minus-40--lg {
    margin-left: -2.85714rem !important;
  }
  .g-ml-45--lg {
    margin-left: 3.21429rem !important;
  }
  .g-ml-minus-45--lg {
    margin-left: -3.21429rem !important;
  }
  .g-ml-50--lg {
    margin-left: 3.57143rem !important;
  }
  .g-ml-minus-50--lg {
    margin-left: -3.57143rem !important;
  }
  .g-mr-1--lg {
    margin-right: 0.07143rem !important;
  }
  .g-mr-minus-1--lg {
    margin-right: -0.07143rem !important;
  }
  .g-mr-2--lg {
    margin-right: 0.14286rem !important;
  }
  .g-mr-minus-2--lg {
    margin-right: -0.14286rem !important;
  }
  .g-mr-3--lg {
    margin-right: 0.21429rem !important;
  }
  .g-mr-minus-3--lg {
    margin-right: -0.21429rem !important;
  }
  .g-mr-4--lg {
    margin-right: 0.28571rem !important;
  }
  .g-mr-minus-4--lg {
    margin-right: -0.28571rem !important;
  }
  .g-mr-5--lg {
    margin-right: 0.35714rem !important;
  }
  .g-mr-minus-5--lg {
    margin-right: -0.35714rem !important;
  }
  .g-mr-6--lg {
    margin-right: 0.42857rem !important;
  }
  .g-mr-minus-6--lg {
    margin-right: -0.42857rem !important;
  }
  .g-mr-7--lg {
    margin-right: 0.5rem !important;
  }
  .g-mr-minus-7--lg {
    margin-right: -0.5rem !important;
  }
  .g-mr-8--lg {
    margin-right: 0.57143rem !important;
  }
  .g-mr-minus-8--lg {
    margin-right: -0.57143rem !important;
  }
  .g-mr-9--lg {
    margin-right: 0.64286rem !important;
  }
  .g-mr-minus-9--lg {
    margin-right: -0.64286rem !important;
  }
  .g-mr-10--lg {
    margin-right: 0.71429rem !important;
  }
  .g-mr-minus-10--lg {
    margin-right: -0.71429rem !important;
  }
  .g-mr-5--lg {
    margin-right: 0.35714rem !important;
  }
  .g-mr-10--lg {
    margin-right: 0.71429rem !important;
  }
  .g-mr-15--lg {
    margin-right: 1.07143rem !important;
  }
  .g-mr-20--lg {
    margin-right: 1.42857rem !important;
  }
  .g-mr-25--lg {
    margin-right: 1.78571rem !important;
  }
  .g-mr-30--lg {
    margin-right: 2.14286rem !important;
  }
  .g-mr-35--lg {
    margin-right: 2.5rem !important;
  }
  .g-mr-40--lg {
    margin-right: 2.85714rem !important;
  }
  .g-mr-45--lg {
    margin-right: 3.21429rem !important;
  }
  .g-mr-50--lg {
    margin-right: 3.57143rem !important;
  }
}

/* Margin Spaces (xl)
------------------------------------*/
/* P */
@media (min-width: 1200px) {
  .g-ma-0--xl {
    margin: 0 !important;
  }
  .g-mx-0--xl {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .g-my-0--xl {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .g-ml-0--xl {
    margin-left: 0 !important;
  }
  .g-mr-0--xl {
    margin-right: 0 !important;
  }
  .g-mt-0--xl {
    margin-top: 0 !important;
  }
  .g-mb-0--xl {
    margin-bottom: 0 !important;
  }
  .g-mx-1--xl {
    margin-left: 0.07143rem !important;
    margin-right: 0.07143rem !important;
  }
  .g-mx-2--xl {
    margin-left: 0.14286rem !important;
    margin-right: 0.14286rem !important;
  }
  .g-mx-3--xl {
    margin-left: 0.21429rem !important;
    margin-right: 0.21429rem !important;
  }
  .g-mx-4--xl {
    margin-left: 0.28571rem !important;
    margin-right: 0.28571rem !important;
  }
  .g-mx-5--xl {
    margin-left: 0.35714rem !important;
    margin-right: 0.35714rem !important;
  }
  .g-mx-6--xl {
    margin-left: 0.42857rem !important;
    margin-right: 0.42857rem !important;
  }
  .g-mx-7--xl {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
  .g-mx-8--xl {
    margin-left: 0.57143rem !important;
    margin-right: 0.57143rem !important;
  }
  .g-mx-9--xl {
    margin-left: 0.64286rem !important;
    margin-right: 0.64286rem !important;
  }
  .g-mx-10--xl {
    margin-left: 0.71429rem !important;
    margin-right: 0.71429rem !important;
  }
  .g-mx-10--xl {
    margin-left: 0.71429rem !important;
    margin-right: 0.71429rem !important;
  }
  .g-mx-15--xl {
    margin-left: 1.07143rem !important;
    margin-right: 1.07143rem !important;
  }
  .g-mx-20--xl {
    margin-left: 1.42857rem !important;
    margin-right: 1.42857rem !important;
  }
  .g-mx-25--xl {
    margin-left: 1.78571rem !important;
    margin-right: 1.78571rem !important;
  }
  .g-mx-30--xl {
    margin-left: 2.14286rem !important;
    margin-right: 2.14286rem !important;
  }
  .g-mx-35--xl {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }
  .g-mx-40--xl {
    margin-left: 2.85714rem !important;
    margin-right: 2.85714rem !important;
  }
  .g-mx-45--xl {
    margin-left: 3.21429rem !important;
    margin-right: 3.21429rem !important;
  }
  .g-mx-50--xl {
    margin-left: 3.57143rem !important;
    margin-right: 3.57143rem !important;
  }
  .g-mx-55--xl {
    margin-left: 3.92857rem !important;
    margin-right: 3.92857rem !important;
  }
  .g-mx-60--xl {
    margin-left: 4.28571rem !important;
    margin-right: 4.28571rem !important;
  }
  .g-mx-65--xl {
    margin-left: 4.64286rem !important;
    margin-right: 4.64286rem !important;
  }
  .g-mx-70--xl {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }
  .g-mx-75--xl {
    margin-left: 5.35714rem !important;
    margin-right: 5.35714rem !important;
  }
  .g-mx-80--xl {
    margin-left: 5.71429rem !important;
    margin-right: 5.71429rem !important;
  }
  .g-mx-85--xl {
    margin-left: 6.07143rem !important;
    margin-right: 6.07143rem !important;
  }
  .g-mx-90--xl {
    margin-left: 6.42857rem !important;
    margin-right: 6.42857rem !important;
  }
  .g-mx-95--xl {
    margin-left: 6.78571rem !important;
    margin-right: 6.78571rem !important;
  }
  .g-mx-100--xl {
    margin-left: 7.14286rem !important;
    margin-right: 7.14286rem !important;
  }
  .g-my-1--xl {
    margin-top: 0.07143rem !important;
    margin-bottom: 0.07143rem !important;
  }
  .g-my-2--xl {
    margin-top: 0.14286rem !important;
    margin-bottom: 0.14286rem !important;
  }
  .g-my-3--xl {
    margin-top: 0.21429rem !important;
    margin-bottom: 0.21429rem !important;
  }
  .g-my-4--xl {
    margin-top: 0.28571rem !important;
    margin-bottom: 0.28571rem !important;
  }
  .g-my-5--xl {
    margin-top: 0.35714rem !important;
    margin-bottom: 0.35714rem !important;
  }
  .g-my-6--xl {
    margin-top: 0.42857rem !important;
    margin-bottom: 0.42857rem !important;
  }
  .g-my-7--xl {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .g-my-8--xl {
    margin-top: 0.57143rem !important;
    margin-bottom: 0.57143rem !important;
  }
  .g-my-9--xl {
    margin-top: 0.64286rem !important;
    margin-bottom: 0.64286rem !important;
  }
  .g-my-10--xl {
    margin-top: 0.71429rem !important;
    margin-bottom: 0.71429rem !important;
  }
  .g-my-10--xl {
    margin-top: 0.71429rem !important;
    margin-bottom: 0.71429rem !important;
  }
  .g-my-15--xl {
    margin-top: 1.07143rem !important;
    margin-bottom: 1.07143rem !important;
  }
  .g-my-20--xl {
    margin-top: 1.42857rem !important;
    margin-bottom: 1.42857rem !important;
  }
  .g-my-25--xl {
    margin-top: 1.78571rem !important;
    margin-bottom: 1.78571rem !important;
  }
  .g-my-30--xl {
    margin-top: 2.14286rem !important;
    margin-bottom: 2.14286rem !important;
  }
  .g-my-35--xl {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .g-my-40--xl {
    margin-top: 2.85714rem !important;
    margin-bottom: 2.85714rem !important;
  }
  .g-my-45--xl {
    margin-top: 3.21429rem !important;
    margin-bottom: 3.21429rem !important;
  }
  .g-my-50--xl {
    margin-top: 3.57143rem !important;
    margin-bottom: 3.57143rem !important;
  }
  .g-my-55--xl {
    margin-top: 3.92857rem !important;
    margin-bottom: 3.92857rem !important;
  }
  .g-my-60--xl {
    margin-top: 4.28571rem !important;
    margin-bottom: 4.28571rem !important;
  }
  .g-my-65--xl {
    margin-top: 4.64286rem !important;
    margin-bottom: 4.64286rem !important;
  }
  .g-my-70--xl {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .g-my-75--xl {
    margin-top: 5.35714rem !important;
    margin-bottom: 5.35714rem !important;
  }
  .g-my-80--xl {
    margin-top: 5.71429rem !important;
    margin-bottom: 5.71429rem !important;
  }
  .g-my-85--xl {
    margin-top: 6.07143rem !important;
    margin-bottom: 6.07143rem !important;
  }
  .g-my-90--xl {
    margin-top: 6.42857rem !important;
    margin-bottom: 6.42857rem !important;
  }
  .g-my-95--xl {
    margin-top: 6.78571rem !important;
    margin-bottom: 6.78571rem !important;
  }
  .g-my-100--xl {
    margin-top: 7.14286rem !important;
    margin-bottom: 7.14286rem !important;
  }
  .g-mt-1--xl {
    margin-top: 0.07143rem !important;
  }
  .g-mt-minus-1--xl {
    margin-top: -0.07143rem !important;
  }
  .g-mt-2--xl {
    margin-top: 0.14286rem !important;
  }
  .g-mt-minus-2--xl {
    margin-top: -0.14286rem !important;
  }
  .g-mt-3--xl {
    margin-top: 0.21429rem !important;
  }
  .g-mt-minus-3--xl {
    margin-top: -0.21429rem !important;
  }
  .g-mt-4--xl {
    margin-top: 0.28571rem !important;
  }
  .g-mt-minus-4--xl {
    margin-top: -0.28571rem !important;
  }
  .g-mt-5--xl {
    margin-top: 0.35714rem !important;
  }
  .g-mt-minus-5--xl {
    margin-top: -0.35714rem !important;
  }
  .g-mt-6--xl {
    margin-top: 0.42857rem !important;
  }
  .g-mt-minus-6--xl {
    margin-top: -0.42857rem !important;
  }
  .g-mt-7--xl {
    margin-top: 0.5rem !important;
  }
  .g-mt-minus-7--xl {
    margin-top: -0.5rem !important;
  }
  .g-mt-8--xl {
    margin-top: 0.57143rem !important;
  }
  .g-mt-minus-8--xl {
    margin-top: -0.57143rem !important;
  }
  .g-mt-9--xl {
    margin-top: 0.64286rem !important;
  }
  .g-mt-minus-9--xl {
    margin-top: -0.64286rem !important;
  }
  .g-mt-10--xl {
    margin-top: 0.71429rem !important;
  }
  .g-mt-minus-10--xl {
    margin-top: -0.71429rem !important;
  }
  .g-mt-11--xl {
    margin-top: 0.78571rem !important;
  }
  .g-mt-minus-11--xl {
    margin-top: -0.78571rem !important;
  }
  .g-mt-12--xl {
    margin-top: 0.85714rem !important;
  }
  .g-mt-minus-12--xl {
    margin-top: -0.85714rem !important;
  }
  .g-mt-13--xl {
    margin-top: 0.92857rem !important;
  }
  .g-mt-minus-13--xl {
    margin-top: -0.92857rem !important;
  }
  .g-mt-14--xl {
    margin-top: 1rem !important;
  }
  .g-mt-minus-14--xl {
    margin-top: -1rem !important;
  }
  .g-mt-15--xl {
    margin-top: 1.07143rem !important;
  }
  .g-mt-minus-15--xl {
    margin-top: -1.07143rem !important;
  }
  .g-mt-16--xl {
    margin-top: 1.14286rem !important;
  }
  .g-mt-minus-16--xl {
    margin-top: -1.14286rem !important;
  }
  .g-mt-17--xl {
    margin-top: 1.21429rem !important;
  }
  .g-mt-minus-17--xl {
    margin-top: -1.21429rem !important;
  }
  .g-mt-18--xl {
    margin-top: 1.28571rem !important;
  }
  .g-mt-minus-18--xl {
    margin-top: -1.28571rem !important;
  }
  .g-mt-19--xl {
    margin-top: 1.35714rem !important;
  }
  .g-mt-minus-19--xl {
    margin-top: -1.35714rem !important;
  }
  .g-mt-20--xl {
    margin-top: 1.42857rem !important;
  }
  .g-mt-minus-20--xl {
    margin-top: -1.42857rem !important;
  }
  .g-mt-10--xl {
    margin-top: 0.71429rem !important;
  }
  .g-mt-minus-10--xl {
    margin-top: -0.71429rem !important;
  }
  .g-mt-15--xl {
    margin-top: 1.07143rem !important;
  }
  .g-mt-minus-15--xl {
    margin-top: -1.07143rem !important;
  }
  .g-mt-20--xl {
    margin-top: 1.42857rem !important;
  }
  .g-mt-minus-20--xl {
    margin-top: -1.42857rem !important;
  }
  .g-mt-25--xl {
    margin-top: 1.78571rem !important;
  }
  .g-mt-minus-25--xl {
    margin-top: -1.78571rem !important;
  }
  .g-mt-30--xl {
    margin-top: 2.14286rem !important;
  }
  .g-mt-minus-30--xl {
    margin-top: -2.14286rem !important;
  }
  .g-mt-35--xl {
    margin-top: 2.5rem !important;
  }
  .g-mt-minus-35--xl {
    margin-top: -2.5rem !important;
  }
  .g-mt-40--xl {
    margin-top: 2.85714rem !important;
  }
  .g-mt-minus-40--xl {
    margin-top: -2.85714rem !important;
  }
  .g-mt-45--xl {
    margin-top: 3.21429rem !important;
  }
  .g-mt-minus-45--xl {
    margin-top: -3.21429rem !important;
  }
  .g-mt-50--xl {
    margin-top: 3.57143rem !important;
  }
  .g-mt-minus-50--xl {
    margin-top: -3.57143rem !important;
  }
  .g-mt-55--xl {
    margin-top: 3.92857rem !important;
  }
  .g-mt-minus-55--xl {
    margin-top: -3.92857rem !important;
  }
  .g-mt-60--xl {
    margin-top: 4.28571rem !important;
  }
  .g-mt-minus-60--xl {
    margin-top: -4.28571rem !important;
  }
  .g-mt-65--xl {
    margin-top: 4.64286rem !important;
  }
  .g-mt-minus-65--xl {
    margin-top: -4.64286rem !important;
  }
  .g-mt-70--xl {
    margin-top: 5rem !important;
  }
  .g-mt-minus-70--xl {
    margin-top: -5rem !important;
  }
  .g-mt-75--xl {
    margin-top: 5.35714rem !important;
  }
  .g-mt-minus-75--xl {
    margin-top: -5.35714rem !important;
  }
  .g-mt-80--xl {
    margin-top: 5.71429rem !important;
  }
  .g-mt-minus-80--xl {
    margin-top: -5.71429rem !important;
  }
  .g-mt-85--xl {
    margin-top: 6.07143rem !important;
  }
  .g-mt-minus-85--xl {
    margin-top: -6.07143rem !important;
  }
  .g-mt-90--xl {
    margin-top: 6.42857rem !important;
  }
  .g-mt-minus-90--xl {
    margin-top: -6.42857rem !important;
  }
  .g-mt-95--xl {
    margin-top: 6.78571rem !important;
  }
  .g-mt-minus-95--xl {
    margin-top: -6.78571rem !important;
  }
  .g-mt-100--xl {
    margin-top: 7.14286rem !important;
  }
  .g-mt-minus-100--xl {
    margin-top: -7.14286rem !important;
  }
  .g-mt-105--xl {
    margin-top: 7.5rem !important;
  }
  .g-mt-minus-105--xl {
    margin-top: -7.5rem !important;
  }
  .g-mt-110--xl {
    margin-top: 7.85714rem !important;
  }
  .g-mt-minus-110--xl {
    margin-top: -7.85714rem !important;
  }
  .g-mt-115--xl {
    margin-top: 8.21429rem !important;
  }
  .g-mt-minus-115--xl {
    margin-top: -8.21429rem !important;
  }
  .g-mt-120--xl {
    margin-top: 8.57143rem !important;
  }
  .g-mt-minus-120--xl {
    margin-top: -8.57143rem !important;
  }
  .g-mt-125--xl {
    margin-top: 8.92857rem !important;
  }
  .g-mt-minus-125--xl {
    margin-top: -8.92857rem !important;
  }
  .g-mt-130--xl {
    margin-top: 9.28571rem !important;
  }
  .g-mt-minus-130--xl {
    margin-top: -9.28571rem !important;
  }
  .g-mt-135--xl {
    margin-top: 9.64286rem !important;
  }
  .g-mt-minus-135--xl {
    margin-top: -9.64286rem !important;
  }
  .g-mt-140--xl {
    margin-top: 10rem !important;
  }
  .g-mt-minus-140--xl {
    margin-top: -10rem !important;
  }
  .g-mt-145--xl {
    margin-top: 10.35714rem !important;
  }
  .g-mt-minus-145--xl {
    margin-top: -10.35714rem !important;
  }
  .g-mt-150--xl {
    margin-top: 10.71429rem !important;
  }
  .g-mt-minus-150--xl {
    margin-top: -10.71429rem !important;
  }
  .g-mt-155--xl {
    margin-top: 11.07143rem !important;
  }
  .g-mt-minus-155--xl {
    margin-top: -11.07143rem !important;
  }
  .g-mt-160--xl {
    margin-top: 11.42857rem !important;
  }
  .g-mt-minus-160--xl {
    margin-top: -11.42857rem !important;
  }
  .g-mt-165--xl {
    margin-top: 11.78571rem !important;
  }
  .g-mt-minus-165--xl {
    margin-top: -11.78571rem !important;
  }
  .g-mt-170--xl {
    margin-top: 12.14286rem !important;
  }
  .g-mt-minus-170--xl {
    margin-top: -12.14286rem !important;
  }
  .g-mb-1--xl {
    margin-bottom: 0.07143rem !important;
  }
  .g-mb-minus-1--xl {
    margin-bottom: -0.07143rem !important;
  }
  .g-mb-2--xl {
    margin-bottom: 0.14286rem !important;
  }
  .g-mb-minus-2--xl {
    margin-bottom: -0.14286rem !important;
  }
  .g-mb-3--xl {
    margin-bottom: 0.21429rem !important;
  }
  .g-mb-minus-3--xl {
    margin-bottom: -0.21429rem !important;
  }
  .g-mb-4--xl {
    margin-bottom: 0.28571rem !important;
  }
  .g-mb-minus-4--xl {
    margin-bottom: -0.28571rem !important;
  }
  .g-mb-5--xl {
    margin-bottom: 0.35714rem !important;
  }
  .g-mb-minus-5--xl {
    margin-bottom: -0.35714rem !important;
  }
  .g-mb-6--xl {
    margin-bottom: 0.42857rem !important;
  }
  .g-mb-minus-6--xl {
    margin-bottom: -0.42857rem !important;
  }
  .g-mb-7--xl {
    margin-bottom: 0.5rem !important;
  }
  .g-mb-minus-7--xl {
    margin-bottom: -0.5rem !important;
  }
  .g-mb-8--xl {
    margin-bottom: 0.57143rem !important;
  }
  .g-mb-minus-8--xl {
    margin-bottom: -0.57143rem !important;
  }
  .g-mb-9--xl {
    margin-bottom: 0.64286rem !important;
  }
  .g-mb-minus-9--xl {
    margin-bottom: -0.64286rem !important;
  }
  .g-mb-10--xl {
    margin-bottom: 0.71429rem !important;
  }
  .g-mb-minus-10--xl {
    margin-bottom: -0.71429rem !important;
  }
  .g-mb-11--xl {
    margin-bottom: 0.78571rem !important;
  }
  .g-mb-minus-11--xl {
    margin-bottom: -0.78571rem !important;
  }
  .g-mb-12--xl {
    margin-bottom: 0.85714rem !important;
  }
  .g-mb-minus-12--xl {
    margin-bottom: -0.85714rem !important;
  }
  .g-mb-13--xl {
    margin-bottom: 0.92857rem !important;
  }
  .g-mb-minus-13--xl {
    margin-bottom: -0.92857rem !important;
  }
  .g-mb-14--xl {
    margin-bottom: 1rem !important;
  }
  .g-mb-minus-14--xl {
    margin-bottom: -1rem !important;
  }
  .g-mb-15--xl {
    margin-bottom: 1.07143rem !important;
  }
  .g-mb-minus-15--xl {
    margin-bottom: -1.07143rem !important;
  }
  .g-mb-16--xl {
    margin-bottom: 1.14286rem !important;
  }
  .g-mb-minus-16--xl {
    margin-bottom: -1.14286rem !important;
  }
  .g-mb-17--xl {
    margin-bottom: 1.21429rem !important;
  }
  .g-mb-minus-17--xl {
    margin-bottom: -1.21429rem !important;
  }
  .g-mb-18--xl {
    margin-bottom: 1.28571rem !important;
  }
  .g-mb-minus-18--xl {
    margin-bottom: -1.28571rem !important;
  }
  .g-mb-19--xl {
    margin-bottom: 1.35714rem !important;
  }
  .g-mb-minus-19--xl {
    margin-bottom: -1.35714rem !important;
  }
  .g-mb-20--xl {
    margin-bottom: 1.42857rem !important;
  }
  .g-mb-minus-20--xl {
    margin-bottom: -1.42857rem !important;
  }
  .g-mb-10--xl {
    margin-bottom: 0.71429rem !important;
  }
  .g-mb-15--xl {
    margin-bottom: 1.07143rem !important;
  }
  .g-mb-20--xl {
    margin-bottom: 1.42857rem !important;
  }
  .g-mb-25--xl {
    margin-bottom: 1.78571rem !important;
  }
  .g-mb-30--xl {
    margin-bottom: 2.14286rem !important;
  }
  .g-mb-35--xl {
    margin-bottom: 2.5rem !important;
  }
  .g-mb-40--xl {
    margin-bottom: 2.85714rem !important;
  }
  .g-mb-45--xl {
    margin-bottom: 3.21429rem !important;
  }
  .g-mb-50--xl {
    margin-bottom: 3.57143rem !important;
  }
  .g-mb-55--xl {
    margin-bottom: 3.92857rem !important;
  }
  .g-mb-60--xl {
    margin-bottom: 4.28571rem !important;
  }
  .g-mb-65--xl {
    margin-bottom: 4.64286rem !important;
  }
  .g-mb-70--xl {
    margin-bottom: 5rem !important;
  }
  .g-mb-75--xl {
    margin-bottom: 5.35714rem !important;
  }
  .g-mb-80--xl {
    margin-bottom: 5.71429rem !important;
  }
  .g-mb-85--xl {
    margin-bottom: 6.07143rem !important;
  }
  .g-mb-90--xl {
    margin-bottom: 6.42857rem !important;
  }
  .g-mb-95--xl {
    margin-bottom: 6.78571rem !important;
  }
  .g-mb-100--xl {
    margin-bottom: 7.14286rem !important;
  }
  .g-mb-105--xl {
    margin-bottom: 7.5rem !important;
  }
  .g-mb-110--xl {
    margin-bottom: 7.85714rem !important;
  }
  .g-mb-115--xl {
    margin-bottom: 8.21429rem !important;
  }
  .g-mb-120--xl {
    margin-bottom: 8.57143rem !important;
  }
  .g-mb-125--xl {
    margin-bottom: 8.92857rem !important;
  }
  .g-mb-130--xl {
    margin-bottom: 9.28571rem !important;
  }
  .g-mb-135--xl {
    margin-bottom: 9.64286rem !important;
  }
  .g-mb-140--xl {
    margin-bottom: 10rem !important;
  }
  .g-mb-145--xl {
    margin-bottom: 10.35714rem !important;
  }
  .g-mb-150--xl {
    margin-bottom: 10.71429rem !important;
  }
  .g-mb-155--xl {
    margin-bottom: 11.07143rem !important;
  }
  .g-mb-160--xl {
    margin-bottom: 11.42857rem !important;
  }
  .g-mb-165--xl {
    margin-bottom: 11.78571rem !important;
  }
  .g-mb-170--xl {
    margin-bottom: 12.14286rem !important;
  }
  .g-ml-1--xl {
    margin-left: 0.07143rem !important;
  }
  .g-ml-minus-1--xl {
    margin-left: -0.07143rem !important;
  }
  .g-ml-2--xl {
    margin-left: 0.14286rem !important;
  }
  .g-ml-minus-2--xl {
    margin-left: -0.14286rem !important;
  }
  .g-ml-3--xl {
    margin-left: 0.21429rem !important;
  }
  .g-ml-minus-3--xl {
    margin-left: -0.21429rem !important;
  }
  .g-ml-4--xl {
    margin-left: 0.28571rem !important;
  }
  .g-ml-minus-4--xl {
    margin-left: -0.28571rem !important;
  }
  .g-ml-5--xl {
    margin-left: 0.35714rem !important;
  }
  .g-ml-minus-5--xl {
    margin-left: -0.35714rem !important;
  }
  .g-ml-6--xl {
    margin-left: 0.42857rem !important;
  }
  .g-ml-minus-6--xl {
    margin-left: -0.42857rem !important;
  }
  .g-ml-7--xl {
    margin-left: 0.5rem !important;
  }
  .g-ml-minus-7--xl {
    margin-left: -0.5rem !important;
  }
  .g-ml-8--xl {
    margin-left: 0.57143rem !important;
  }
  .g-ml-minus-8--xl {
    margin-left: -0.57143rem !important;
  }
  .g-ml-9--xl {
    margin-left: 0.64286rem !important;
  }
  .g-ml-minus-9--xl {
    margin-left: -0.64286rem !important;
  }
  .g-ml-10--xl {
    margin-left: 0.71429rem !important;
  }
  .g-ml-minus-10--xl {
    margin-left: -0.71429rem !important;
  }
  .g-ml-5--xl {
    margin-left: 0.35714rem !important;
  }
  .g-ml-minus-5--xl {
    margin-left: -0.35714rem !important;
  }
  .g-ml-10--xl {
    margin-left: 0.71429rem !important;
  }
  .g-ml-minus-10--xl {
    margin-left: -0.71429rem !important;
  }
  .g-ml-15--xl {
    margin-left: 1.07143rem !important;
  }
  .g-ml-minus-15--xl {
    margin-left: -1.07143rem !important;
  }
  .g-ml-20--xl {
    margin-left: 1.42857rem !important;
  }
  .g-ml-minus-20--xl {
    margin-left: -1.42857rem !important;
  }
  .g-ml-25--xl {
    margin-left: 1.78571rem !important;
  }
  .g-ml-minus-25--xl {
    margin-left: -1.78571rem !important;
  }
  .g-ml-30--xl {
    margin-left: 2.14286rem !important;
  }
  .g-ml-minus-30--xl {
    margin-left: -2.14286rem !important;
  }
  .g-ml-35--xl {
    margin-left: 2.5rem !important;
  }
  .g-ml-minus-35--xl {
    margin-left: -2.5rem !important;
  }
  .g-ml-40--xl {
    margin-left: 2.85714rem !important;
  }
  .g-ml-minus-40--xl {
    margin-left: -2.85714rem !important;
  }
  .g-ml-45--xl {
    margin-left: 3.21429rem !important;
  }
  .g-ml-minus-45--xl {
    margin-left: -3.21429rem !important;
  }
  .g-ml-50--xl {
    margin-left: 3.57143rem !important;
  }
  .g-ml-minus-50--xl {
    margin-left: -3.57143rem !important;
  }
  .g-mr-1--xl {
    margin-right: 0.07143rem !important;
  }
  .g-mr-minus-1--xl {
    margin-right: -0.07143rem !important;
  }
  .g-mr-2--xl {
    margin-right: 0.14286rem !important;
  }
  .g-mr-minus-2--xl {
    margin-right: -0.14286rem !important;
  }
  .g-mr-3--xl {
    margin-right: 0.21429rem !important;
  }
  .g-mr-minus-3--xl {
    margin-right: -0.21429rem !important;
  }
  .g-mr-4--xl {
    margin-right: 0.28571rem !important;
  }
  .g-mr-minus-4--xl {
    margin-right: -0.28571rem !important;
  }
  .g-mr-5--xl {
    margin-right: 0.35714rem !important;
  }
  .g-mr-minus-5--xl {
    margin-right: -0.35714rem !important;
  }
  .g-mr-6--xl {
    margin-right: 0.42857rem !important;
  }
  .g-mr-minus-6--xl {
    margin-right: -0.42857rem !important;
  }
  .g-mr-7--xl {
    margin-right: 0.5rem !important;
  }
  .g-mr-minus-7--xl {
    margin-right: -0.5rem !important;
  }
  .g-mr-8--xl {
    margin-right: 0.57143rem !important;
  }
  .g-mr-minus-8--xl {
    margin-right: -0.57143rem !important;
  }
  .g-mr-9--xl {
    margin-right: 0.64286rem !important;
  }
  .g-mr-minus-9--xl {
    margin-right: -0.64286rem !important;
  }
  .g-mr-10--xl {
    margin-right: 0.71429rem !important;
  }
  .g-mr-minus-10--xl {
    margin-right: -0.71429rem !important;
  }
  .g-mr-5--xl {
    margin-right: 0.35714rem !important;
  }
  .g-mr-10--xl {
    margin-right: 0.71429rem !important;
  }
  .g-mr-15--xl {
    margin-right: 1.07143rem !important;
  }
  .g-mr-20--xl {
    margin-right: 1.42857rem !important;
  }
  .g-mr-25--xl {
    margin-right: 1.78571rem !important;
  }
  .g-mr-30--xl {
    margin-right: 2.14286rem !important;
  }
  .g-mr-35--xl {
    margin-right: 2.5rem !important;
  }
  .g-mr-40--xl {
    margin-right: 2.85714rem !important;
  }
  .g-mr-45--xl {
    margin-right: 3.21429rem !important;
  }
  .g-mr-50--xl {
    margin-right: 3.57143rem !important;
  }
}

/* Margins Around
------------------------------------*/
.g-ma-1 {
  margin: 0.07143rem !important;
}

.g-ma-3 {
  margin: 0.21429rem !important;
}

.g-ma-5 {
  margin: 0.35714rem !important;
}

.g-ma-10 {
  margin: 0.71429rem !important;
}

.g-ma-20 {
  margin: 1.42857rem !important;
}

.g-ma-minus-1 {
  margin: -0.07143rem !important;
}

/* Minus Margins
------------------------------------*/
/* Minus Margin Top */
.g-mt-minus-1 {
  margin-top: -0.07143rem;
}

.g-mt-minus-20 {
  margin-top: -1.42857rem;
}

.g-mt-minus-25 {
  margin-top: -1.78571rem;
}

.g-mt-minus-30 {
  margin-top: -2.14286rem;
}

.g-mt-minus-40 {
  margin-top: -2.85714rem;
}

.g-mt-minus-70 {
  margin-top: -5rem;
}

.g-mt-minus-73 {
  margin-top: -5.14286rem !important;
}

.g-mt-minus-120 {
  margin-top: -8.57143rem;
}

.g-mt-minus-200 {
  margin-top: -14.28571rem;
}

.g-mt-minus-300 {
  margin-top: -21.42857rem;
}

/* Minus Margin Bottom */
.g-mb-minus-70 {
  margin-bottom: -5rem;
}

/* Minus Margin Left */
.g-ml-minus-20 {
  margin-left: -1.42857rem;
}

.g-ml-minus-23 {
  margin-left: -1.64286rem;
}

.g-ml-minus-35 {
  margin-left: -2.5rem;
}

.g-ml-minus-55 {
  margin-left: -3.92857rem;
}

.g-ml-minus-25 {
  margin-left: -1.78571rem;
}

.g-ml-minus-82 {
  margin-left: -5.85714rem;
}

.g-ml-minus-90 {
  margin-left: -6.42857rem;
}

.g-ml-minus-100 {
  margin-left: -7.14286rem;
}

.g-ml-minus-118 {
  margin-left: -8.42857rem;
}

.g-ml-minus-142 {
  margin-left: -10.14286rem;
}

/* Minus Margin Right */
.g-mr-minus-50 {
  margin-right: -3.57143rem;
}

.g-mr-minus-100 {
  margin-right: -7.14286rem;
}

/* Margin Left and Right */
.g-mx-minus-1 {
  margin-left: -0.07143rem;
  margin-right: -0.07143rem;
}

.g-mx-minus-2 {
  margin-left: -0.14286rem;
  margin-right: -0.14286rem;
}

.g-mx-minus-4 {
  margin-left: -0.28571rem;
  margin-right: -0.28571rem;
}

.g-mx-minus-5 {
  margin-left: -0.35714rem;
  margin-right: -0.35714rem;
}

.g-mx-minus-15 {
  margin-left: -1.07143rem;
  margin-right: -1.07143rem;
}

.g-mx-minus-25 {
  margin-left: -1.78571rem;
  margin-right: -1.78571rem;
}

.g-mx-minus-30 {
  margin-left: -2.14286rem;
  margin-right: -2.14286rem;
}

/* Custon Spaces
------------------------------------*/
/* Margin Top */
.g-mt-10x {
  margin-top: 10%;
  /* O */
}

.g-mt-21 {
  margin-top: 1.5rem;
}

.g-mt-22 {
  margin-top: 1.57143rem;
}

.g-mt-28 {
  margin-top: 2rem !important;
}

.g-mt-32 {
  margin-top: 2.28571rem !important;
}

.g-mt-57 {
  margin-top: 4.07143rem !important;
}

.g-mt-500 {
  margin-top: 35.71429rem;
}

/* Margin Bottom */
.g-mb-23 {
  margin-bottom: 1.64286rem;
}

.g-mb-28 {
  margin-bottom: 2rem;
}

.g-mb-500 {
  margin-bottom: 35.71429rem;
}

/* Margin Left */
.g-ml-10x {
  margin-left: 10%;
  /* O */
}

.g-ml-12 {
  margin-left: 0.85714rem;
}

.g-ml-13 {
  margin-left: 0.92857rem;
}

.g-ml-20 {
  margin-left: 1.42857rem;
}

.g-ml-25 {
  margin-left: 1.78571rem;
}

.g-ml-35 {
  margin-left: 2.5rem;
}

.g-ml-43 {
  margin-left: 3.07143rem;
}

.g-mr-43 {
  margin-right: 3.07143rem;
}

.g-ml-50 {
  margin-left: 3.57143rem;
}

.g-ml-60 {
  margin-left: 4.28571rem;
}

.g-ml-75 {
  margin-left: 5.35714rem;
}

.g-ml-82 {
  margin-left: 5.85714rem;
}

.g-ml-83 {
  margin-left: 5.92857rem;
}

.g-ml-85 {
  margin-left: 6.07143rem;
}

.g-ml-105 {
  margin-left: 7.5rem;
}

.g-ml-118 {
  margin-left: 8.42857rem;
}

.g-ml-142 {
  margin-left: 10.14286rem;
}

/* Margin Right */
.g-mr-12 {
  margin-right: 0.85714rem;
}

.g-mr-60 {
  margin-right: 4.28571rem;
}

.g-mr-63 {
  margin-right: 4.5rem;
}

.g-mr-75 {
  margin-right: 5.35714rem;
}

.g-mr-85 {
  margin-right: 6.07143rem !important;
}

.g-mr-minus-10 {
  margin-right: -0.71429rem;
}

.g-mr-minus-13 {
  margin-right: -0.92857rem;
}

.g-mr-minus-15 {
  margin-right: -1.07143rem;
}

.g-mr-minus-23 {
  margin-right: -1.64286rem;
}

.g-mr-minus-30 {
  margin-right: -2.14286rem;
}

/* Margin Left and Right */
.g-mx-minus-10 {
  margin-left: -0.71429rem;
  margin-right: -0.71429rem;
}

.g-mx-minus-15 {
  margin-left: -1.07143rem;
  margin-right: -1.07143rem;
}

.g-mx-minus-20 {
  margin-left: -1.42857rem;
  margin-right: -1.42857rem;
}

.g-mx-minus-25 {
  margin-left: -1.78571rem;
  margin-right: -1.78571rem;
}

.g-mx-minus-30 {
  margin-left: -2.14286rem;
  margin-right: -2.14286rem;
}

/* Margin Top and Bottom */
.g-my-minus-1 {
  margin-top: -0.07143rem;
  margin-bottom: -0.07143rem;
}

.g-my-minus-2 {
  margin-top: -0.14286rem;
  margin-bottom: -0.14286rem;
}

.g-my-minus-10 {
  margin-top: -0.71429rem;
  margin-bottom: -0.71429rem;
}

.g-m-reset {
  margin: 0 !important;
}

@media (min-width: 576px) {
  .g-mb-0--sm {
    margin-bottom: 0 !important;
  }
  .g-mx-minus-10--sm {
    margin-left: -0.71429rem;
    margin-right: -0.71429rem;
  }
  .g-my-minus-5--sm {
    margin-top: -0.35714rem;
    margin-bottom: -0.35714rem;
  }
  .g-my-minus-10--sm {
    margin-top: -0.71429rem;
    margin-bottom: -0.71429rem;
  }
  .g-mx-minus-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .g-my-minus-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .g-mr-12--sm {
    margin-right: 0.85714rem;
  }
}

@media (min-width: 768px) {
  .g-ml-0--md {
    margin-left: 0 !important;
  }
  .g-mr-0--md {
    margin-right: 0 !important;
  }
  .g-mx-12--md {
    margin-left: 0.85714rem !important;
    margin-right: 0.85714rem !important;
  }
  .g-ml-12--md {
    margin-left: 0.85714rem !important;
  }
  .g-mr-12--md {
    margin-right: 0.85714rem !important;
  }
  .g-mb-0--md {
    margin-bottom: 0 !important;
  }
  .g-ml-minus-1--md {
    margin-left: -0.07143rem;
  }
  .g-ml-minus-9--md {
    margin-left: -0.64286rem !important;
  }
  .g-ml-minus-15--md {
    margin-left: -1.07143rem !important;
  }
  .g-ml-minus-23--md {
    margin-left: -1.64286rem !important;
  }
  .g-ml-minus-25--md {
    margin-left: -1.78571rem !important;
  }
  .g-mr-minus-1--md {
    margin-right: -0.07143rem;
  }
  .g-mr-minus-9--md {
    margin-right: -0.64286rem !important;
  }
  .g-mr-minus-13--md {
    margin-right: -0.92857rem;
  }
  .g-mr-minus-23--md {
    margin-right: -1.64286rem;
  }
  .g-ml-minus-82--md {
    margin-left: -5.85714rem !important;
  }
  .g-mr-minus-82--md {
    margin-right: -5.85714rem !important;
  }
  .g-mr-60--md {
    margin-right: 4.28571rem !important;
  }
  .g-mr-63--md {
    margin-right: 4.5rem !important;
  }
  .g-ml-63--md {
    margin-left: 4.5rem !important;
  }
  .g-ml-85--md {
    margin-left: 6.07143rem !important;
  }
  .g-ml-250--md {
    margin-left: 17.85714rem !important;
  }
  .g-ml-minus-90--md {
    margin-left: -6.42857rem !important;
  }
  .g-m-reset--md {
    margin: 0 !important;
  }
  .g-mt-130--md {
    margin-top: 9.28571rem !important;
  }
  .g-mt-minus-76--md {
    margin-top: -5.42857rem !important;
  }
  .g-my-30--md {
    margin-top: 2.14286rem !important;
    margin-bpttpm: 2.14286rem !important;
  }
}

@media (min-width: 992px) {
  .g-mx-12--lg {
    margin-left: 0.85714rem !important;
    margin-right: 0.85714rem !important;
  }
  .g-mb-60--lg {
    margin-bottom: 4.28571rem !important;
  }
  .g-ml-12--lg {
    margin-left: 0.85714rem !important;
  }
  .g-mr-12--lg {
    margin-right: 0.85714rem !important;
  }
  .g-mr-minus-1--lg {
    margin-right: -0.07143rem;
  }
  .g-mr-minus-50--lg {
    margin-right: -3.57143rem;
  }
  .g-mr-minus-100--lg {
    margin-right: -7.14286rem;
  }
  .g-ml-minus-100--lg {
    margin-left: -7.14286rem;
  }
  .g-ml-minus-100 {
    margin-left: -7.14286rem;
  }
  .g-mx-minus-5--lg {
    margin-left: -0.35714rem;
    margin-right: -0.35714rem;
  }
  .g-mx-minus-10--lg {
    margin-left: -0.71429rem;
    margin-right: -0.71429rem;
  }
  .g-mx-minus-15--lg {
    margin-left: -1.07143rem;
    margin-right: -1.07143rem;
  }
  .g-ml-minus-50--lg {
    margin-left: -3.57143rem;
  }
  .g-m-reset--lg {
    margin: 0 !important;
  }
  .g-ml-100--lg {
    margin-left: 7.14286rem;
  }
}

@media (min-width: 1200px) {
  .g-mx-minus-10--xl {
    margin-left: -0.71429rem;
    margin-right: -0.71429rem;
  }
  .g-mx-minus-15--xl {
    margin-left: -1.07143rem;
    margin-right: -1.07143rem;
  }
  .g-m-reset--xl {
    margin: 0 !important;
  }
  .g-mr-0--xl {
    margin-right: 0px !important;
  }
}

/*------------------------------------
  Margins Extended
------------------------------------*/
@media (min-width: 992px) {
  /* Margin Top */
  .js-header-change-moment .g-mt-1--lg--scrolling {
    margin-top: 0.07143rem !important;
  }
  .js-header-change-moment .g-mt-2--lg--scrolling {
    margin-top: 0.14286rem !important;
  }
  .js-header-change-moment .g-mt-3--lg--scrolling {
    margin-top: 0.21429rem !important;
  }
  .js-header-change-moment .g-mt-4--lg--scrolling {
    margin-top: 0.28571rem !important;
  }
  .js-header-change-moment .g-mt-5--lg--scrolling {
    margin-top: 0.35714rem !important;
  }
  .js-header-change-moment .g-mt-6--lg--scrolling {
    margin-top: 0.42857rem !important;
  }
  .js-header-change-moment .g-mt-7--lg--scrolling {
    margin-top: 0.5rem !important;
  }
  .js-header-change-moment .g-mt-8--lg--scrolling {
    margin-top: 0.57143rem !important;
  }
  .js-header-change-moment .g-mt-9--lg--scrolling {
    margin-top: 0.64286rem !important;
  }
  .js-header-change-moment .g-mt-10--lg--scrolling {
    margin-top: 0.71429rem !important;
  }
  .js-header-change-moment .g-mt-11--lg--scrolling {
    margin-top: 0.78571rem !important;
  }
  .js-header-change-moment .g-mt-12--lg--scrolling {
    margin-top: 0.85714rem !important;
  }
  .js-header-change-moment .g-mt-13--lg--scrolling {
    margin-top: 0.92857rem !important;
  }
  .js-header-change-moment .g-mt-14--lg--scrolling {
    margin-top: 1rem !important;
  }
  .js-header-change-moment .g-mt-15--lg--scrolling {
    margin-top: 1.07143rem !important;
  }
  .js-header-change-moment .g-mt-16--lg--scrolling {
    margin-top: 1.14286rem !important;
  }
  .js-header-change-moment .g-mt-17--lg--scrolling {
    margin-top: 1.21429rem !important;
  }
  .js-header-change-moment .g-mt-18--lg--scrolling {
    margin-top: 1.28571rem !important;
  }
  .js-header-change-moment .g-mt-19--lg--scrolling {
    margin-top: 1.35714rem !important;
  }
  .js-header-change-moment .g-mt-20--lg--scrolling {
    margin-top: 1.42857rem !important;
  }
  .js-header-change-moment .g-mt-21--lg--scrolling {
    margin-top: 1.5rem !important;
  }
  .js-header-change-moment .g-mt-22--lg--scrolling {
    margin-top: 1.57143rem !important;
  }
  .js-header-change-moment .g-mt-23--lg--scrolling {
    margin-top: 1.64286rem !important;
  }
  .js-header-change-moment .g-mt-24--lg--scrolling {
    margin-top: 1.71429rem !important;
  }
  .js-header-change-moment .g-mt-25--lg--scrolling {
    margin-top: 1.78571rem !important;
  }
}

/*------------------------------------
  Padding Spaces
------------------------------------*/
/* Padding Spaces (xs)
------------------------------------*/
@media (min-width: 0) {
  .g-pa-0 {
    padding: 0 !important;
  }
  .g-px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .g-py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .g-pt-0 {
    padding-top: 0 !important;
  }
  .g-pr-0 {
    padding-right: 0 !important;
  }
  .g-pb-0 {
    padding-bottom: 0 !important;
  }
  .g-pl-0 {
    padding-left: 0 !important;
  }
  /* Padding Around */
  .g-pa-2 {
    padding: 0.14286rem !important;
  }
  .g-pa-3 {
    padding: 0.21429rem !important;
  }
  .g-pa-5 {
    padding: 0.35714rem !important;
  }
  .g-pa-7 {
    padding: 0.5rem !important;
  }
  .g-pa-10 {
    padding: 0.71429rem !important;
  }
  .g-pa-15 {
    padding: 1.07143rem !important;
  }
  .g-pa-20 {
    padding: 1.42857rem !important;
  }
  .g-pa-25 {
    padding: 1.78571rem !important;
  }
  .g-pa-30 {
    padding: 2.14286rem !important;
  }
  .g-pa-35 {
    padding: 2.5rem !important;
  }
  .g-pa-40 {
    padding: 2.85714rem !important;
  }
  .g-pa-45 {
    padding: 3.21429rem !important;
  }
  .g-pa-50 {
    padding: 3.57143rem !important;
  }
  .g-pa-55 {
    padding: 3.92857rem !important;
  }
  .g-pa-60 {
    padding: 4.28571rem !important;
  }
  .g-pa-65 {
    padding: 4.64286rem !important;
  }
  .g-pa-70 {
    padding: 5rem !important;
  }
  .g-pa-75 {
    padding: 5.35714rem !important;
  }
  .g-pa-80 {
    padding: 5.71429rem !important;
  }
  .g-pa-85 {
    padding: 6.07143rem !important;
  }
  .g-pa-90 {
    padding: 6.42857rem !important;
  }
  .g-pa-95 {
    padding: 6.78571rem !important;
  }
  .g-pa-100 {
    padding: 7.14286rem !important;
  }
  .g-pa-105 {
    padding: 7.5rem !important;
  }
  .g-pa-110 {
    padding: 7.85714rem !important;
  }
  .g-pa-115 {
    padding: 8.21429rem !important;
  }
  .g-pa-120 {
    padding: 8.57143rem !important;
  }
  .g-pa-125 {
    padding: 8.92857rem !important;
  }
  .g-pa-130 {
    padding: 9.28571rem !important;
  }
  .g-pa-135 {
    padding: 9.64286rem !important;
  }
  .g-pa-140 {
    padding: 10rem !important;
  }
  .g-pa-145 {
    padding: 10.35714rem !important;
  }
  .g-pa-150 {
    padding: 10.71429rem !important;
  }
  /* Padding X */
  .g-px-1 {
    padding-left: 0.07143rem !important;
    padding-right: 0.07143rem !important;
  }
  .g-px-2 {
    padding-left: 0.14286rem !important;
    padding-right: 0.14286rem !important;
  }
  .g-px-3 {
    padding-left: 0.21429rem !important;
    padding-right: 0.21429rem !important;
  }
  .g-px-4 {
    padding-left: 0.28571rem !important;
    padding-right: 0.28571rem !important;
  }
  .g-px-5 {
    padding-left: 0.35714rem !important;
    padding-right: 0.35714rem !important;
  }
  .g-px-6 {
    padding-left: 0.42857rem !important;
    padding-right: 0.42857rem !important;
  }
  .g-px-7 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
  .g-px-8 {
    padding-left: 0.57143rem !important;
    padding-right: 0.57143rem !important;
  }
  .g-px-9 {
    padding-left: 0.64286rem !important;
    padding-right: 0.64286rem !important;
  }
  .g-px-10 {
    padding-left: 0.71429rem !important;
    padding-right: 0.71429rem !important;
  }
  .g-px-11 {
    padding-left: 0.78571rem !important;
    padding-right: 0.78571rem !important;
  }
  .g-px-12 {
    padding-left: 0.85714rem !important;
    padding-right: 0.85714rem !important;
  }
  .g-px-13 {
    padding-left: 0.92857rem !important;
    padding-right: 0.92857rem !important;
  }
  .g-px-14 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .g-px-15 {
    padding-left: 1.07143rem !important;
    padding-right: 1.07143rem !important;
  }
  .g-px-16 {
    padding-left: 1.14286rem !important;
    padding-right: 1.14286rem !important;
  }
  .g-px-17 {
    padding-left: 1.21429rem !important;
    padding-right: 1.21429rem !important;
  }
  .g-px-18 {
    padding-left: 1.28571rem !important;
    padding-right: 1.28571rem !important;
  }
  .g-px-19 {
    padding-left: 1.35714rem !important;
    padding-right: 1.35714rem !important;
  }
  .g-px-10 {
    padding-left: 0.71429rem !important;
    padding-right: 0.71429rem !important;
  }
  .g-px-15 {
    padding-left: 1.07143rem !important;
    padding-right: 1.07143rem !important;
  }
  .g-px-20 {
    padding-left: 1.42857rem !important;
    padding-right: 1.42857rem !important;
  }
  .g-px-25 {
    padding-left: 1.78571rem !important;
    padding-right: 1.78571rem !important;
  }
  .g-px-30 {
    padding-left: 2.14286rem !important;
    padding-right: 2.14286rem !important;
  }
  .g-px-35 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }
  .g-px-40 {
    padding-left: 2.85714rem !important;
    padding-right: 2.85714rem !important;
  }
  .g-px-45 {
    padding-left: 3.21429rem !important;
    padding-right: 3.21429rem !important;
  }
  .g-px-50 {
    padding-left: 3.57143rem !important;
    padding-right: 3.57143rem !important;
  }
  .g-px-55 {
    padding-left: 3.92857rem !important;
    padding-right: 3.92857rem !important;
  }
  .g-px-60 {
    padding-left: 4.28571rem !important;
    padding-right: 4.28571rem !important;
  }
  .g-px-65 {
    padding-left: 4.64286rem !important;
    padding-right: 4.64286rem !important;
  }
  .g-px-70 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }
  .g-px-75 {
    padding-left: 5.35714rem !important;
    padding-right: 5.35714rem !important;
  }
  .g-px-80 {
    padding-left: 5.71429rem !important;
    padding-right: 5.71429rem !important;
  }
  .g-px-85 {
    padding-left: 6.07143rem !important;
    padding-right: 6.07143rem !important;
  }
  .g-px-90 {
    padding-left: 6.42857rem !important;
    padding-right: 6.42857rem !important;
  }
  .g-px-95 {
    padding-left: 6.78571rem !important;
    padding-right: 6.78571rem !important;
  }
  .g-px-100 {
    padding-left: 7.14286rem !important;
    padding-right: 7.14286rem !important;
  }
  .g-px-105 {
    padding-left: 7.5rem !important;
    padding-right: 7.5rem !important;
  }
  .g-px-110 {
    padding-left: 7.85714rem !important;
    padding-right: 7.85714rem !important;
  }
  .g-px-115 {
    padding-left: 8.21429rem !important;
    padding-right: 8.21429rem !important;
  }
  .g-px-120 {
    padding-left: 8.57143rem !important;
    padding-right: 8.57143rem !important;
  }
  .g-px-125 {
    padding-left: 8.92857rem !important;
    padding-right: 8.92857rem !important;
  }
  .g-px-130 {
    padding-left: 9.28571rem !important;
    padding-right: 9.28571rem !important;
  }
  .g-px-135 {
    padding-left: 9.64286rem !important;
    padding-right: 9.64286rem !important;
  }
  .g-px-140 {
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }
  .g-px-145 {
    padding-left: 10.35714rem !important;
    padding-right: 10.35714rem !important;
  }
  .g-px-150 {
    padding-left: 10.71429rem !important;
    padding-right: 10.71429rem !important;
  }
  /* Padding Y */
  .g-py-1 {
    padding-top: 0.07143rem !important;
    padding-bottom: 0.07143rem !important;
  }
  .g-py-2 {
    padding-top: 0.14286rem !important;
    padding-bottom: 0.14286rem !important;
  }
  .g-py-3 {
    padding-top: 0.21429rem !important;
    padding-bottom: 0.21429rem !important;
  }
  .g-py-4 {
    padding-top: 0.28571rem !important;
    padding-bottom: 0.28571rem !important;
  }
  .g-py-5 {
    padding-top: 0.35714rem !important;
    padding-bottom: 0.35714rem !important;
  }
  .g-py-6 {
    padding-top: 0.42857rem !important;
    padding-bottom: 0.42857rem !important;
  }
  .g-py-7 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .g-py-8 {
    padding-top: 0.57143rem !important;
    padding-bottom: 0.57143rem !important;
  }
  .g-py-9 {
    padding-top: 0.64286rem !important;
    padding-bottom: 0.64286rem !important;
  }
  .g-py-10 {
    padding-top: 0.71429rem !important;
    padding-bottom: 0.71429rem !important;
  }
  .g-py-11 {
    padding-top: 0.78571rem !important;
    padding-bottom: 0.78571rem !important;
  }
  .g-py-12 {
    padding-top: 0.85714rem !important;
    padding-bottom: 0.85714rem !important;
  }
  .g-py-13 {
    padding-top: 0.92857rem !important;
    padding-bottom: 0.92857rem !important;
  }
  .g-py-14 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .g-py-15 {
    padding-top: 1.07143rem !important;
    padding-bottom: 1.07143rem !important;
  }
  .g-py-16 {
    padding-top: 1.14286rem !important;
    padding-bottom: 1.14286rem !important;
  }
  .g-py-17 {
    padding-top: 1.21429rem !important;
    padding-bottom: 1.21429rem !important;
  }
  .g-py-18 {
    padding-top: 1.28571rem !important;
    padding-bottom: 1.28571rem !important;
  }
  .g-py-19 {
    padding-top: 1.35714rem !important;
    padding-bottom: 1.35714rem !important;
  }
  /* P */
  .g-py-10 {
    padding-top: 0.71429rem !important;
    padding-bottom: 0.71429rem !important;
  }
  /* P */
  .g-py-15 {
    padding-top: 1.07143rem !important;
    padding-bottom: 1.07143rem !important;
  }
  /* P */
  .g-py-20 {
    padding-top: 1.42857rem !important;
    padding-bottom: 1.42857rem !important;
  }
  /* P */
  .g-py-25 {
    padding-top: 1.78571rem !important;
    padding-bottom: 1.78571rem !important;
  }
  /* P */
  .g-py-30 {
    padding-top: 2.14286rem !important;
    padding-bottom: 2.14286rem !important;
  }
  /* P */
  .g-py-35 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  /* P */
  .g-py-40 {
    padding-top: 2.85714rem !important;
    padding-bottom: 2.85714rem !important;
  }
  /* P */
  .g-py-45 {
    padding-top: 3.21429rem !important;
    padding-bottom: 3.21429rem !important;
  }
  /* P */
  .g-py-50 {
    padding-top: 3.57143rem !important;
    padding-bottom: 3.57143rem !important;
  }
  /* P */
  .g-py-55 {
    padding-top: 3.92857rem !important;
    padding-bottom: 3.92857rem !important;
  }
  /* P */
  .g-py-60 {
    padding-top: 4.28571rem !important;
    padding-bottom: 4.28571rem !important;
  }
  /* P */
  .g-py-65 {
    padding-top: 4.64286rem !important;
    padding-bottom: 4.64286rem !important;
  }
  /* P */
  .g-py-70 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  /* P */
  .g-py-75 {
    padding-top: 5.35714rem !important;
    padding-bottom: 5.35714rem !important;
  }
  /* P */
  .g-py-80 {
    padding-top: 5.71429rem !important;
    padding-bottom: 5.71429rem !important;
  }
  /* P */
  .g-py-85 {
    padding-top: 6.07143rem !important;
    padding-bottom: 6.07143rem !important;
  }
  /* P */
  .g-py-90 {
    padding-top: 6.42857rem !important;
    padding-bottom: 6.42857rem !important;
  }
  /* P */
  .g-py-95 {
    padding-top: 6.78571rem !important;
    padding-bottom: 6.78571rem !important;
  }
  /* P */
  .g-py-100 {
    padding-top: 7.14286rem !important;
    padding-bottom: 7.14286rem !important;
  }
  /* P */
  .g-py-105 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }
  /* P */
  .g-py-110 {
    padding-top: 7.85714rem !important;
    padding-bottom: 7.85714rem !important;
  }
  /* P */
  .g-py-115 {
    padding-top: 8.21429rem !important;
    padding-bottom: 8.21429rem !important;
  }
  /* P */
  .g-py-120 {
    padding-top: 8.57143rem !important;
    padding-bottom: 8.57143rem !important;
  }
  /* P */
  .g-py-125 {
    padding-top: 8.92857rem !important;
    padding-bottom: 8.92857rem !important;
  }
  /* P */
  .g-py-130 {
    padding-top: 9.28571rem !important;
    padding-bottom: 9.28571rem !important;
  }
  /* P */
  .g-py-135 {
    padding-top: 9.64286rem !important;
    padding-bottom: 9.64286rem !important;
  }
  /* P */
  .g-py-140 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  /* P */
  .g-py-145 {
    padding-top: 10.35714rem !important;
    padding-bottom: 10.35714rem !important;
  }
  /* P */
  .g-py-150 {
    padding-top: 10.71429rem !important;
    padding-bottom: 10.71429rem !important;
  }
  /* P */
  .g-py-155 {
    padding-top: 11.07143rem !important;
    padding-bottom: 11.07143rem !important;
  }
  /* P */
  .g-py-160 {
    padding-top: 11.42857rem !important;
    padding-bottom: 11.42857rem !important;
  }
  /* P */
  .g-py-165 {
    padding-top: 11.78571rem !important;
    padding-bottom: 11.78571rem !important;
  }
  /* P */
  .g-py-170 {
    padding-top: 12.14286rem !important;
    padding-bottom: 12.14286rem !important;
  }
  /* P */
  .g-py-175 {
    padding-top: 12.5rem !important;
    padding-bottom: 12.5rem !important;
  }
  /* P */
  .g-py-180 {
    padding-top: 12.85714rem !important;
    padding-bottom: 12.85714rem !important;
  }
  /* P */
  .g-py-185 {
    padding-top: 13.21429rem !important;
    padding-bottom: 13.21429rem !important;
  }
  /* P */
  .g-py-190 {
    padding-top: 13.57143rem !important;
    padding-bottom: 13.57143rem !important;
  }
  /* P */
  .g-py-195 {
    padding-top: 13.92857rem !important;
    padding-bottom: 13.92857rem !important;
  }
  /* P */
  .g-py-200 {
    padding-top: 14.28571rem !important;
    padding-bottom: 14.28571rem !important;
  }
  /* P */
  .g-py-205 {
    padding-top: 14.64286rem !important;
    padding-bottom: 14.64286rem !important;
  }
  /* P */
  .g-py-210 {
    padding-top: 15rem !important;
    padding-bottom: 15rem !important;
  }
  /* Padding Top */
  .g-pt-0 {
    padding-top: 0px !important;
  }
  .g-pt-1 {
    padding-top: 0.07143rem !important;
  }
  .g-pt-2 {
    padding-top: 0.14286rem !important;
  }
  .g-pt-3 {
    padding-top: 0.21429rem !important;
  }
  .g-pt-4 {
    padding-top: 0.28571rem !important;
  }
  .g-pt-5 {
    padding-top: 0.35714rem !important;
  }
  .g-pt-6 {
    padding-top: 0.42857rem !important;
  }
  .g-pt-7 {
    padding-top: 0.5rem !important;
  }
  .g-pt-8 {
    padding-top: 0.57143rem !important;
  }
  .g-pt-9 {
    padding-top: 0.64286rem !important;
  }
  .g-pt-10 {
    padding-top: 0.71429rem !important;
  }
  .g-pt-11 {
    padding-top: 0.78571rem !important;
  }
  .g-pt-12 {
    padding-top: 0.85714rem !important;
  }
  .g-pt-13 {
    padding-top: 0.92857rem !important;
  }
  .g-pt-14 {
    padding-top: 1rem !important;
  }
  .g-pt-15 {
    padding-top: 1.07143rem !important;
  }
  .g-pt-16 {
    padding-top: 1.14286rem !important;
  }
  .g-pt-17 {
    padding-top: 1.21429rem !important;
  }
  .g-pt-18 {
    padding-top: 1.28571rem !important;
  }
  .g-pt-19 {
    padding-top: 1.35714rem !important;
  }
  .g-pt-10 {
    padding-top: 0.71429rem !important;
  }
  .g-pt-15 {
    padding-top: 1.07143rem !important;
  }
  .g-pt-20 {
    padding-top: 1.42857rem !important;
  }
  .g-pt-25 {
    padding-top: 1.78571rem !important;
  }
  .g-pt-30 {
    padding-top: 2.14286rem !important;
  }
  .g-pt-35 {
    padding-top: 2.5rem !important;
  }
  .g-pt-40 {
    padding-top: 2.85714rem !important;
  }
  .g-pt-45 {
    padding-top: 3.21429rem !important;
  }
  .g-pt-50 {
    padding-top: 3.57143rem !important;
  }
  .g-pt-55 {
    padding-top: 3.92857rem !important;
  }
  .g-pt-60 {
    padding-top: 4.28571rem !important;
  }
  .g-pt-65 {
    padding-top: 4.64286rem !important;
  }
  .g-pt-70 {
    padding-top: 5rem !important;
  }
  .g-pt-75 {
    padding-top: 5.35714rem !important;
  }
  .g-pt-80 {
    padding-top: 5.71429rem !important;
  }
  .g-pt-85 {
    padding-top: 6.07143rem !important;
  }
  .g-pt-90 {
    padding-top: 6.42857rem !important;
  }
  .g-pt-95 {
    padding-top: 6.78571rem !important;
  }
  .g-pt-100 {
    padding-top: 7.14286rem !important;
  }
  .g-pt-105 {
    padding-top: 7.5rem !important;
  }
  .g-pt-110 {
    padding-top: 7.85714rem !important;
  }
  .g-pt-115 {
    padding-top: 8.21429rem !important;
  }
  .g-pt-120 {
    padding-top: 8.57143rem !important;
  }
  .g-pt-125 {
    padding-top: 8.92857rem !important;
  }
  .g-pt-130 {
    padding-top: 9.28571rem !important;
  }
  .g-pt-135 {
    padding-top: 9.64286rem !important;
  }
  .g-pt-140 {
    padding-top: 10rem !important;
  }
  .g-pt-145 {
    padding-top: 10.35714rem !important;
  }
  .g-pt-150 {
    padding-top: 10.71429rem !important;
  }
  /* Padding Right */
  .g-pr-0 {
    padding-right: 0px !important;
  }
  .g-pr-1 {
    padding-right: 0.07143rem !important;
  }
  .g-pr-2 {
    padding-right: 0.14286rem !important;
  }
  .g-pr-3 {
    padding-right: 0.21429rem !important;
  }
  .g-pr-4 {
    padding-right: 0.28571rem !important;
  }
  .g-pr-5 {
    padding-right: 0.35714rem !important;
  }
  .g-pr-6 {
    padding-right: 0.42857rem !important;
  }
  .g-pr-7 {
    padding-right: 0.5rem !important;
  }
  .g-pr-8 {
    padding-right: 0.57143rem !important;
  }
  .g-pr-9 {
    padding-right: 0.64286rem !important;
  }
  /* P */
  .g-pr-10 {
    padding-right: 0.71429rem !important;
  }
  /* P */
  .g-pr-15 {
    padding-right: 1.07143rem !important;
  }
  /* P */
  .g-pr-20 {
    padding-right: 1.42857rem !important;
  }
  /* P */
  .g-pr-25 {
    padding-right: 1.78571rem !important;
  }
  /* P */
  .g-pr-30 {
    padding-right: 2.14286rem !important;
  }
  /* P */
  .g-pr-35 {
    padding-right: 2.5rem !important;
  }
  /* P */
  .g-pr-40 {
    padding-right: 2.85714rem !important;
  }
  /* P */
  .g-pr-45 {
    padding-right: 3.21429rem !important;
  }
  /* P */
  .g-pr-50 {
    padding-right: 3.57143rem !important;
  }
  /* P */
  .g-pr-55 {
    padding-right: 3.92857rem !important;
  }
  /* P */
  .g-pr-60 {
    padding-right: 4.28571rem !important;
  }
  /* P */
  .g-pr-65 {
    padding-right: 4.64286rem !important;
  }
  /* P */
  .g-pr-70 {
    padding-right: 5rem !important;
  }
  /* P */
  .g-pr-75 {
    padding-right: 5.35714rem !important;
  }
  /* P */
  .g-pr-80 {
    padding-right: 5.71429rem !important;
  }
  /* P */
  .g-pr-85 {
    padding-right: 6.07143rem !important;
  }
  /* P */
  .g-pr-90 {
    padding-right: 6.42857rem !important;
  }
  /* P */
  .g-pr-95 {
    padding-right: 6.78571rem !important;
  }
  /* P */
  .g-pr-100 {
    padding-right: 7.14286rem !important;
  }
  /* P */
  .g-pr-105 {
    padding-right: 7.5rem !important;
  }
  /* P */
  .g-pr-110 {
    padding-right: 7.85714rem !important;
  }
  /* P */
  .g-pr-115 {
    padding-right: 8.21429rem !important;
  }
  /* P */
  .g-pr-120 {
    padding-right: 8.57143rem !important;
  }
  /* P */
  .g-pr-125 {
    padding-right: 8.92857rem !important;
  }
  /* P */
  .g-pr-130 {
    padding-right: 9.28571rem !important;
  }
  /* P */
  .g-pr-135 {
    padding-right: 9.64286rem !important;
  }
  /* P */
  .g-pr-140 {
    padding-right: 10rem !important;
  }
  /* P */
  .g-pr-145 {
    padding-right: 10.35714rem !important;
  }
  /* P */
  .g-pr-150 {
    padding-right: 10.71429rem !important;
  }
  /* P */
  .g-pr-155 {
    padding-right: 11.07143rem !important;
  }
  /* P */
  .g-pr-160 {
    padding-right: 11.42857rem !important;
  }
  /* P */
  .g-pr-165 {
    padding-right: 11.78571rem !important;
  }
  /* P */
  .g-pr-170 {
    padding-right: 12.14286rem !important;
  }
  /* Padding Bottom */
  .g-pb-0 {
    padding-bottom: 0px !important;
  }
  .g-pb-1 {
    padding-bottom: 0.07143rem !important;
  }
  .g-pb-2 {
    padding-bottom: 0.14286rem !important;
  }
  .g-pb-3 {
    padding-bottom: 0.21429rem !important;
  }
  .g-pb-4 {
    padding-bottom: 0.28571rem !important;
  }
  .g-pb-5 {
    padding-bottom: 0.35714rem !important;
  }
  .g-pb-6 {
    padding-bottom: 0.42857rem !important;
  }
  .g-pb-7 {
    padding-bottom: 0.5rem !important;
  }
  .g-pb-8 {
    padding-bottom: 0.57143rem !important;
  }
  .g-pb-9 {
    padding-bottom: 0.64286rem !important;
  }
  .g-pb-10 {
    padding-bottom: 0.71429rem !important;
  }
  .g-pb-15 {
    padding-bottom: 1.07143rem !important;
  }
  .g-pb-20 {
    padding-bottom: 1.42857rem !important;
  }
  .g-pb-25 {
    padding-bottom: 1.78571rem !important;
  }
  .g-pb-30 {
    padding-bottom: 2.14286rem !important;
  }
  .g-pb-35 {
    padding-bottom: 2.5rem !important;
  }
  .g-pb-40 {
    padding-bottom: 2.85714rem !important;
  }
  .g-pb-45 {
    padding-bottom: 3.21429rem !important;
  }
  .g-pb-50 {
    padding-bottom: 3.57143rem !important;
  }
  .g-pb-55 {
    padding-bottom: 3.92857rem !important;
  }
  .g-pb-60 {
    padding-bottom: 4.28571rem !important;
  }
  .g-pb-65 {
    padding-bottom: 4.64286rem !important;
  }
  .g-pb-70 {
    padding-bottom: 5rem !important;
  }
  .g-pb-75 {
    padding-bottom: 5.35714rem !important;
  }
  .g-pb-80 {
    padding-bottom: 5.71429rem !important;
  }
  .g-pb-85 {
    padding-bottom: 6.07143rem !important;
  }
  .g-pb-90 {
    padding-bottom: 6.42857rem !important;
  }
  .g-pb-95 {
    padding-bottom: 6.78571rem !important;
  }
  .g-pb-100 {
    padding-bottom: 7.14286rem !important;
  }
  .g-pb-105 {
    padding-bottom: 7.5rem !important;
  }
  .g-pb-110 {
    padding-bottom: 7.85714rem !important;
  }
  .g-pb-115 {
    padding-bottom: 8.21429rem !important;
  }
  .g-pb-120 {
    padding-bottom: 8.57143rem !important;
  }
  .g-pb-125 {
    padding-bottom: 8.92857rem !important;
  }
  .g-pb-130 {
    padding-bottom: 9.28571rem !important;
  }
  .g-pb-135 {
    padding-bottom: 9.64286rem !important;
  }
  .g-pb-140 {
    padding-bottom: 10rem !important;
  }
  .g-pb-145 {
    padding-bottom: 10.35714rem !important;
  }
  .g-pb-150 {
    padding-bottom: 10.71429rem !important;
  }
  /* Padding Left */
  .g-pl-0 {
    padding-left: 0px !important;
  }
  .g-pl-1 {
    padding-left: 0.07143rem !important;
  }
  .g-pl-2 {
    padding-left: 0.14286rem !important;
  }
  .g-pl-3 {
    padding-left: 0.21429rem !important;
  }
  .g-pl-4 {
    padding-left: 0.28571rem !important;
  }
  .g-pl-5 {
    padding-left: 0.35714rem !important;
  }
  .g-pl-6 {
    padding-left: 0.42857rem !important;
  }
  .g-pl-7 {
    padding-left: 0.5rem !important;
  }
  .g-pl-8 {
    padding-left: 0.57143rem !important;
  }
  .g-pl-9 {
    padding-left: 0.64286rem !important;
  }
  /* P */
  .g-pl-10 {
    padding-left: 0.71429rem !important;
  }
  /* P */
  .g-pl-15 {
    padding-left: 1.07143rem !important;
  }
  /* P */
  .g-pl-20 {
    padding-left: 1.42857rem !important;
  }
  /* P */
  .g-pl-25 {
    padding-left: 1.78571rem !important;
  }
  /* P */
  .g-pl-30 {
    padding-left: 2.14286rem !important;
  }
  /* P */
  .g-pl-35 {
    padding-left: 2.5rem !important;
  }
  /* P */
  .g-pl-40 {
    padding-left: 2.85714rem !important;
  }
  /* P */
  .g-pl-45 {
    padding-left: 3.21429rem !important;
  }
  /* P */
  .g-pl-50 {
    padding-left: 3.57143rem !important;
  }
  /* P */
  .g-pl-55 {
    padding-left: 3.92857rem !important;
  }
  /* P */
  .g-pl-60 {
    padding-left: 4.28571rem !important;
  }
  /* P */
  .g-pl-65 {
    padding-left: 4.64286rem !important;
  }
  /* P */
  .g-pl-70 {
    padding-left: 5rem !important;
  }
  /* P */
  .g-pl-75 {
    padding-left: 5.35714rem !important;
  }
  /* P */
  .g-pl-80 {
    padding-left: 5.71429rem !important;
  }
  /* P */
  .g-pl-85 {
    padding-left: 6.07143rem !important;
  }
  /* P */
  .g-pl-90 {
    padding-left: 6.42857rem !important;
  }
  /* P */
  .g-pl-95 {
    padding-left: 6.78571rem !important;
  }
  /* P */
  .g-pl-100 {
    padding-left: 7.14286rem !important;
  }
  /* P */
  .g-pl-105 {
    padding-left: 7.5rem !important;
  }
  /* P */
  .g-pl-110 {
    padding-left: 7.85714rem !important;
  }
  /* P */
  .g-pl-115 {
    padding-left: 8.21429rem !important;
  }
  /* P */
  .g-pl-120 {
    padding-left: 8.57143rem !important;
  }
  /* P */
  .g-pl-125 {
    padding-left: 8.92857rem !important;
  }
  /* P */
  .g-pl-130 {
    padding-left: 9.28571rem !important;
  }
  /* P */
  .g-pl-135 {
    padding-left: 9.64286rem !important;
  }
  /* P */
  .g-pl-140 {
    padding-left: 10rem !important;
  }
  /* P */
  .g-pl-145 {
    padding-left: 10.35714rem !important;
  }
  /* P */
  .g-pl-150 {
    padding-left: 10.71429rem !important;
  }
  /* P */
  .g-pl-155 {
    padding-left: 11.07143rem !important;
  }
  /* P */
  .g-pl-160 {
    padding-left: 11.42857rem !important;
  }
  /* P */
  .g-pl-165 {
    padding-left: 11.78571rem !important;
  }
  /* P */
  .g-pl-170 {
    padding-left: 12.14286rem !important;
  }
}

/* Padding Spaces (sm)
------------------------------------*/
@media (min-width: 576px) {
  .g-pa-0--sm {
    padding: 0 !important;
  }
  .g-px-0--sm {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .g-py-0--sm {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .g-pt-0--sm {
    padding-top: 0 !important;
  }
  .g-pr-0--sm {
    padding-right: 0 !important;
  }
  .g-pb-0--sm {
    padding-bottom: 0 !important;
  }
  .g-pl-0--sm {
    padding-left: 0 !important;
  }
  /* Padding Around */
  .g-pa-2--sm {
    padding: 0.14286rem !important;
  }
  .g-pa-3--sm {
    padding: 0.21429rem !important;
  }
  .g-pa-5--sm {
    padding: 0.35714rem !important;
  }
  .g-pa-7--sm {
    padding: 0.5rem !important;
  }
  .g-pa-10--sm {
    padding: 0.71429rem !important;
  }
  .g-pa-15--sm {
    padding: 1.07143rem !important;
  }
  .g-pa-20--sm {
    padding: 1.42857rem !important;
  }
  .g-pa-25--sm {
    padding: 1.78571rem !important;
  }
  .g-pa-30--sm {
    padding: 2.14286rem !important;
  }
  .g-pa-35--sm {
    padding: 2.5rem !important;
  }
  .g-pa-40--sm {
    padding: 2.85714rem !important;
  }
  .g-pa-45--sm {
    padding: 3.21429rem !important;
  }
  .g-pa-50--sm {
    padding: 3.57143rem !important;
  }
  .g-pa-55--sm {
    padding: 3.92857rem !important;
  }
  .g-pa-60--sm {
    padding: 4.28571rem !important;
  }
  .g-pa-65--sm {
    padding: 4.64286rem !important;
  }
  .g-pa-70--sm {
    padding: 5rem !important;
  }
  .g-pa-75--sm {
    padding: 5.35714rem !important;
  }
  .g-pa-80--sm {
    padding: 5.71429rem !important;
  }
  .g-pa-85--sm {
    padding: 6.07143rem !important;
  }
  .g-pa-90--sm {
    padding: 6.42857rem !important;
  }
  .g-pa-95--sm {
    padding: 6.78571rem !important;
  }
  .g-pa-100--sm {
    padding: 7.14286rem !important;
  }
  .g-pa-105--sm {
    padding: 7.5rem !important;
  }
  .g-pa-110--sm {
    padding: 7.85714rem !important;
  }
  .g-pa-115--sm {
    padding: 8.21429rem !important;
  }
  .g-pa-120--sm {
    padding: 8.57143rem !important;
  }
  .g-pa-125--sm {
    padding: 8.92857rem !important;
  }
  .g-pa-130--sm {
    padding: 9.28571rem !important;
  }
  .g-pa-135--sm {
    padding: 9.64286rem !important;
  }
  .g-pa-140--sm {
    padding: 10rem !important;
  }
  .g-pa-145--sm {
    padding: 10.35714rem !important;
  }
  .g-pa-150--sm {
    padding: 10.71429rem !important;
  }
  /* Padding X */
  .g-px-1--sm {
    padding-left: 0.07143rem !important;
    padding-right: 0.07143rem !important;
  }
  .g-px-2--sm {
    padding-left: 0.14286rem !important;
    padding-right: 0.14286rem !important;
  }
  .g-px-3--sm {
    padding-left: 0.21429rem !important;
    padding-right: 0.21429rem !important;
  }
  .g-px-4--sm {
    padding-left: 0.28571rem !important;
    padding-right: 0.28571rem !important;
  }
  .g-px-5--sm {
    padding-left: 0.35714rem !important;
    padding-right: 0.35714rem !important;
  }
  .g-px-6--sm {
    padding-left: 0.42857rem !important;
    padding-right: 0.42857rem !important;
  }
  .g-px-7--sm {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
  .g-px-8--sm {
    padding-left: 0.57143rem !important;
    padding-right: 0.57143rem !important;
  }
  .g-px-9--sm {
    padding-left: 0.64286rem !important;
    padding-right: 0.64286rem !important;
  }
  .g-px-10--sm {
    padding-left: 0.71429rem !important;
    padding-right: 0.71429rem !important;
  }
  .g-px-11--sm {
    padding-left: 0.78571rem !important;
    padding-right: 0.78571rem !important;
  }
  .g-px-12--sm {
    padding-left: 0.85714rem !important;
    padding-right: 0.85714rem !important;
  }
  .g-px-13--sm {
    padding-left: 0.92857rem !important;
    padding-right: 0.92857rem !important;
  }
  .g-px-14--sm {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .g-px-15--sm {
    padding-left: 1.07143rem !important;
    padding-right: 1.07143rem !important;
  }
  .g-px-16--sm {
    padding-left: 1.14286rem !important;
    padding-right: 1.14286rem !important;
  }
  .g-px-17--sm {
    padding-left: 1.21429rem !important;
    padding-right: 1.21429rem !important;
  }
  .g-px-18--sm {
    padding-left: 1.28571rem !important;
    padding-right: 1.28571rem !important;
  }
  .g-px-19--sm {
    padding-left: 1.35714rem !important;
    padding-right: 1.35714rem !important;
  }
  .g-px-10--sm {
    padding-left: 0.71429rem !important;
    padding-right: 0.71429rem !important;
  }
  .g-px-15--sm {
    padding-left: 1.07143rem !important;
    padding-right: 1.07143rem !important;
  }
  .g-px-20--sm {
    padding-left: 1.42857rem !important;
    padding-right: 1.42857rem !important;
  }
  .g-px-25--sm {
    padding-left: 1.78571rem !important;
    padding-right: 1.78571rem !important;
  }
  .g-px-30--sm {
    padding-left: 2.14286rem !important;
    padding-right: 2.14286rem !important;
  }
  .g-px-35--sm {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }
  .g-px-40--sm {
    padding-left: 2.85714rem !important;
    padding-right: 2.85714rem !important;
  }
  .g-px-45--sm {
    padding-left: 3.21429rem !important;
    padding-right: 3.21429rem !important;
  }
  .g-px-50--sm {
    padding-left: 3.57143rem !important;
    padding-right: 3.57143rem !important;
  }
  .g-px-55--sm {
    padding-left: 3.92857rem !important;
    padding-right: 3.92857rem !important;
  }
  .g-px-60--sm {
    padding-left: 4.28571rem !important;
    padding-right: 4.28571rem !important;
  }
  .g-px-65--sm {
    padding-left: 4.64286rem !important;
    padding-right: 4.64286rem !important;
  }
  .g-px-70--sm {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }
  .g-px-75--sm {
    padding-left: 5.35714rem !important;
    padding-right: 5.35714rem !important;
  }
  .g-px-80--sm {
    padding-left: 5.71429rem !important;
    padding-right: 5.71429rem !important;
  }
  .g-px-85--sm {
    padding-left: 6.07143rem !important;
    padding-right: 6.07143rem !important;
  }
  .g-px-90--sm {
    padding-left: 6.42857rem !important;
    padding-right: 6.42857rem !important;
  }
  .g-px-95--sm {
    padding-left: 6.78571rem !important;
    padding-right: 6.78571rem !important;
  }
  .g-px-100--sm {
    padding-left: 7.14286rem !important;
    padding-right: 7.14286rem !important;
  }
  .g-px-105--sm {
    padding-left: 7.5rem !important;
    padding-right: 7.5rem !important;
  }
  .g-px-110--sm {
    padding-left: 7.85714rem !important;
    padding-right: 7.85714rem !important;
  }
  .g-px-115--sm {
    padding-left: 8.21429rem !important;
    padding-right: 8.21429rem !important;
  }
  .g-px-120--sm {
    padding-left: 8.57143rem !important;
    padding-right: 8.57143rem !important;
  }
  .g-px-125--sm {
    padding-left: 8.92857rem !important;
    padding-right: 8.92857rem !important;
  }
  .g-px-130--sm {
    padding-left: 9.28571rem !important;
    padding-right: 9.28571rem !important;
  }
  .g-px-135--sm {
    padding-left: 9.64286rem !important;
    padding-right: 9.64286rem !important;
  }
  .g-px-140--sm {
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }
  .g-px-145--sm {
    padding-left: 10.35714rem !important;
    padding-right: 10.35714rem !important;
  }
  .g-px-150--sm {
    padding-left: 10.71429rem !important;
    padding-right: 10.71429rem !important;
  }
  /* Padding Y */
  .g-py-1--sm {
    padding-top: 0.07143rem !important;
    padding-bottom: 0.07143rem !important;
  }
  .g-py-2--sm {
    padding-top: 0.14286rem !important;
    padding-bottom: 0.14286rem !important;
  }
  .g-py-3--sm {
    padding-top: 0.21429rem !important;
    padding-bottom: 0.21429rem !important;
  }
  .g-py-4--sm {
    padding-top: 0.28571rem !important;
    padding-bottom: 0.28571rem !important;
  }
  .g-py-5--sm {
    padding-top: 0.35714rem !important;
    padding-bottom: 0.35714rem !important;
  }
  .g-py-6--sm {
    padding-top: 0.42857rem !important;
    padding-bottom: 0.42857rem !important;
  }
  .g-py-7--sm {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .g-py-8--sm {
    padding-top: 0.57143rem !important;
    padding-bottom: 0.57143rem !important;
  }
  .g-py-9--sm {
    padding-top: 0.64286rem !important;
    padding-bottom: 0.64286rem !important;
  }
  .g-py-10--sm {
    padding-top: 0.71429rem !important;
    padding-bottom: 0.71429rem !important;
  }
  .g-py-11--sm {
    padding-top: 0.78571rem !important;
    padding-bottom: 0.78571rem !important;
  }
  .g-py-12--sm {
    padding-top: 0.85714rem !important;
    padding-bottom: 0.85714rem !important;
  }
  .g-py-13--sm {
    padding-top: 0.92857rem !important;
    padding-bottom: 0.92857rem !important;
  }
  .g-py-14--sm {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .g-py-15--sm {
    padding-top: 1.07143rem !important;
    padding-bottom: 1.07143rem !important;
  }
  .g-py-16--sm {
    padding-top: 1.14286rem !important;
    padding-bottom: 1.14286rem !important;
  }
  .g-py-17--sm {
    padding-top: 1.21429rem !important;
    padding-bottom: 1.21429rem !important;
  }
  .g-py-18--sm {
    padding-top: 1.28571rem !important;
    padding-bottom: 1.28571rem !important;
  }
  .g-py-19--sm {
    padding-top: 1.35714rem !important;
    padding-bottom: 1.35714rem !important;
  }
  /* P */
  .g-py-10--sm {
    padding-top: 0.71429rem !important;
    padding-bottom: 0.71429rem !important;
  }
  /* P */
  .g-py-15--sm {
    padding-top: 1.07143rem !important;
    padding-bottom: 1.07143rem !important;
  }
  /* P */
  .g-py-20--sm {
    padding-top: 1.42857rem !important;
    padding-bottom: 1.42857rem !important;
  }
  /* P */
  .g-py-25--sm {
    padding-top: 1.78571rem !important;
    padding-bottom: 1.78571rem !important;
  }
  /* P */
  .g-py-30--sm {
    padding-top: 2.14286rem !important;
    padding-bottom: 2.14286rem !important;
  }
  /* P */
  .g-py-35--sm {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  /* P */
  .g-py-40--sm {
    padding-top: 2.85714rem !important;
    padding-bottom: 2.85714rem !important;
  }
  /* P */
  .g-py-45--sm {
    padding-top: 3.21429rem !important;
    padding-bottom: 3.21429rem !important;
  }
  /* P */
  .g-py-50--sm {
    padding-top: 3.57143rem !important;
    padding-bottom: 3.57143rem !important;
  }
  /* P */
  .g-py-55--sm {
    padding-top: 3.92857rem !important;
    padding-bottom: 3.92857rem !important;
  }
  /* P */
  .g-py-60--sm {
    padding-top: 4.28571rem !important;
    padding-bottom: 4.28571rem !important;
  }
  /* P */
  .g-py-65--sm {
    padding-top: 4.64286rem !important;
    padding-bottom: 4.64286rem !important;
  }
  /* P */
  .g-py-70--sm {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  /* P */
  .g-py-75--sm {
    padding-top: 5.35714rem !important;
    padding-bottom: 5.35714rem !important;
  }
  /* P */
  .g-py-80--sm {
    padding-top: 5.71429rem !important;
    padding-bottom: 5.71429rem !important;
  }
  /* P */
  .g-py-85--sm {
    padding-top: 6.07143rem !important;
    padding-bottom: 6.07143rem !important;
  }
  /* P */
  .g-py-90--sm {
    padding-top: 6.42857rem !important;
    padding-bottom: 6.42857rem !important;
  }
  /* P */
  .g-py-95--sm {
    padding-top: 6.78571rem !important;
    padding-bottom: 6.78571rem !important;
  }
  /* P */
  .g-py-100--sm {
    padding-top: 7.14286rem !important;
    padding-bottom: 7.14286rem !important;
  }
  /* P */
  .g-py-105--sm {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }
  /* P */
  .g-py-110--sm {
    padding-top: 7.85714rem !important;
    padding-bottom: 7.85714rem !important;
  }
  /* P */
  .g-py-115--sm {
    padding-top: 8.21429rem !important;
    padding-bottom: 8.21429rem !important;
  }
  /* P */
  .g-py-120--sm {
    padding-top: 8.57143rem !important;
    padding-bottom: 8.57143rem !important;
  }
  /* P */
  .g-py-125--sm {
    padding-top: 8.92857rem !important;
    padding-bottom: 8.92857rem !important;
  }
  /* P */
  .g-py-130--sm {
    padding-top: 9.28571rem !important;
    padding-bottom: 9.28571rem !important;
  }
  /* P */
  .g-py-135--sm {
    padding-top: 9.64286rem !important;
    padding-bottom: 9.64286rem !important;
  }
  /* P */
  .g-py-140--sm {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  /* P */
  .g-py-145--sm {
    padding-top: 10.35714rem !important;
    padding-bottom: 10.35714rem !important;
  }
  /* P */
  .g-py-150--sm {
    padding-top: 10.71429rem !important;
    padding-bottom: 10.71429rem !important;
  }
  /* P */
  .g-py-155--sm {
    padding-top: 11.07143rem !important;
    padding-bottom: 11.07143rem !important;
  }
  /* P */
  .g-py-160--sm {
    padding-top: 11.42857rem !important;
    padding-bottom: 11.42857rem !important;
  }
  /* P */
  .g-py-165--sm {
    padding-top: 11.78571rem !important;
    padding-bottom: 11.78571rem !important;
  }
  /* P */
  .g-py-170--sm {
    padding-top: 12.14286rem !important;
    padding-bottom: 12.14286rem !important;
  }
  /* P */
  .g-py-175--sm {
    padding-top: 12.5rem !important;
    padding-bottom: 12.5rem !important;
  }
  /* P */
  .g-py-180--sm {
    padding-top: 12.85714rem !important;
    padding-bottom: 12.85714rem !important;
  }
  /* P */
  .g-py-185--sm {
    padding-top: 13.21429rem !important;
    padding-bottom: 13.21429rem !important;
  }
  /* P */
  .g-py-190--sm {
    padding-top: 13.57143rem !important;
    padding-bottom: 13.57143rem !important;
  }
  /* P */
  .g-py-195--sm {
    padding-top: 13.92857rem !important;
    padding-bottom: 13.92857rem !important;
  }
  /* P */
  .g-py-200--sm {
    padding-top: 14.28571rem !important;
    padding-bottom: 14.28571rem !important;
  }
  /* P */
  .g-py-205--sm {
    padding-top: 14.64286rem !important;
    padding-bottom: 14.64286rem !important;
  }
  /* P */
  .g-py-210--sm {
    padding-top: 15rem !important;
    padding-bottom: 15rem !important;
  }
  /* Padding Top */
  .g-pt-0--sm {
    padding-top: 0px !important;
  }
  .g-pt-1--sm {
    padding-top: 0.07143rem !important;
  }
  .g-pt-2--sm {
    padding-top: 0.14286rem !important;
  }
  .g-pt-3--sm {
    padding-top: 0.21429rem !important;
  }
  .g-pt-4--sm {
    padding-top: 0.28571rem !important;
  }
  .g-pt-5--sm {
    padding-top: 0.35714rem !important;
  }
  .g-pt-6--sm {
    padding-top: 0.42857rem !important;
  }
  .g-pt-7--sm {
    padding-top: 0.5rem !important;
  }
  .g-pt-8--sm {
    padding-top: 0.57143rem !important;
  }
  .g-pt-9--sm {
    padding-top: 0.64286rem !important;
  }
  .g-pt-10--sm {
    padding-top: 0.71429rem !important;
  }
  .g-pt-11--sm {
    padding-top: 0.78571rem !important;
  }
  .g-pt-12--sm {
    padding-top: 0.85714rem !important;
  }
  .g-pt-13--sm {
    padding-top: 0.92857rem !important;
  }
  .g-pt-14--sm {
    padding-top: 1rem !important;
  }
  .g-pt-15--sm {
    padding-top: 1.07143rem !important;
  }
  .g-pt-16--sm {
    padding-top: 1.14286rem !important;
  }
  .g-pt-17--sm {
    padding-top: 1.21429rem !important;
  }
  .g-pt-18--sm {
    padding-top: 1.28571rem !important;
  }
  .g-pt-19--sm {
    padding-top: 1.35714rem !important;
  }
  .g-pt-10--sm {
    padding-top: 0.71429rem !important;
  }
  .g-pt-15--sm {
    padding-top: 1.07143rem !important;
  }
  .g-pt-20--sm {
    padding-top: 1.42857rem !important;
  }
  .g-pt-25--sm {
    padding-top: 1.78571rem !important;
  }
  .g-pt-30--sm {
    padding-top: 2.14286rem !important;
  }
  .g-pt-35--sm {
    padding-top: 2.5rem !important;
  }
  .g-pt-40--sm {
    padding-top: 2.85714rem !important;
  }
  .g-pt-45--sm {
    padding-top: 3.21429rem !important;
  }
  .g-pt-50--sm {
    padding-top: 3.57143rem !important;
  }
  .g-pt-55--sm {
    padding-top: 3.92857rem !important;
  }
  .g-pt-60--sm {
    padding-top: 4.28571rem !important;
  }
  .g-pt-65--sm {
    padding-top: 4.64286rem !important;
  }
  .g-pt-70--sm {
    padding-top: 5rem !important;
  }
  .g-pt-75--sm {
    padding-top: 5.35714rem !important;
  }
  .g-pt-80--sm {
    padding-top: 5.71429rem !important;
  }
  .g-pt-85--sm {
    padding-top: 6.07143rem !important;
  }
  .g-pt-90--sm {
    padding-top: 6.42857rem !important;
  }
  .g-pt-95--sm {
    padding-top: 6.78571rem !important;
  }
  .g-pt-100--sm {
    padding-top: 7.14286rem !important;
  }
  .g-pt-105--sm {
    padding-top: 7.5rem !important;
  }
  .g-pt-110--sm {
    padding-top: 7.85714rem !important;
  }
  .g-pt-115--sm {
    padding-top: 8.21429rem !important;
  }
  .g-pt-120--sm {
    padding-top: 8.57143rem !important;
  }
  .g-pt-125--sm {
    padding-top: 8.92857rem !important;
  }
  .g-pt-130--sm {
    padding-top: 9.28571rem !important;
  }
  .g-pt-135--sm {
    padding-top: 9.64286rem !important;
  }
  .g-pt-140--sm {
    padding-top: 10rem !important;
  }
  .g-pt-145--sm {
    padding-top: 10.35714rem !important;
  }
  .g-pt-150--sm {
    padding-top: 10.71429rem !important;
  }
  /* Padding Right */
  .g-pr-0--sm {
    padding-right: 0px !important;
  }
  .g-pr-1--sm {
    padding-right: 0.07143rem !important;
  }
  .g-pr-2--sm {
    padding-right: 0.14286rem !important;
  }
  .g-pr-3--sm {
    padding-right: 0.21429rem !important;
  }
  .g-pr-4--sm {
    padding-right: 0.28571rem !important;
  }
  .g-pr-5--sm {
    padding-right: 0.35714rem !important;
  }
  .g-pr-6--sm {
    padding-right: 0.42857rem !important;
  }
  .g-pr-7--sm {
    padding-right: 0.5rem !important;
  }
  .g-pr-8--sm {
    padding-right: 0.57143rem !important;
  }
  .g-pr-9--sm {
    padding-right: 0.64286rem !important;
  }
  /* P */
  .g-pr-10--sm {
    padding-right: 0.71429rem !important;
  }
  /* P */
  .g-pr-15--sm {
    padding-right: 1.07143rem !important;
  }
  /* P */
  .g-pr-20--sm {
    padding-right: 1.42857rem !important;
  }
  /* P */
  .g-pr-25--sm {
    padding-right: 1.78571rem !important;
  }
  /* P */
  .g-pr-30--sm {
    padding-right: 2.14286rem !important;
  }
  /* P */
  .g-pr-35--sm {
    padding-right: 2.5rem !important;
  }
  /* P */
  .g-pr-40--sm {
    padding-right: 2.85714rem !important;
  }
  /* P */
  .g-pr-45--sm {
    padding-right: 3.21429rem !important;
  }
  /* P */
  .g-pr-50--sm {
    padding-right: 3.57143rem !important;
  }
  /* P */
  .g-pr-55--sm {
    padding-right: 3.92857rem !important;
  }
  /* P */
  .g-pr-60--sm {
    padding-right: 4.28571rem !important;
  }
  /* P */
  .g-pr-65--sm {
    padding-right: 4.64286rem !important;
  }
  /* P */
  .g-pr-70--sm {
    padding-right: 5rem !important;
  }
  /* P */
  .g-pr-75--sm {
    padding-right: 5.35714rem !important;
  }
  /* P */
  .g-pr-80--sm {
    padding-right: 5.71429rem !important;
  }
  /* P */
  .g-pr-85--sm {
    padding-right: 6.07143rem !important;
  }
  /* P */
  .g-pr-90--sm {
    padding-right: 6.42857rem !important;
  }
  /* P */
  .g-pr-95--sm {
    padding-right: 6.78571rem !important;
  }
  /* P */
  .g-pr-100--sm {
    padding-right: 7.14286rem !important;
  }
  /* P */
  .g-pr-105--sm {
    padding-right: 7.5rem !important;
  }
  /* P */
  .g-pr-110--sm {
    padding-right: 7.85714rem !important;
  }
  /* P */
  .g-pr-115--sm {
    padding-right: 8.21429rem !important;
  }
  /* P */
  .g-pr-120--sm {
    padding-right: 8.57143rem !important;
  }
  /* P */
  .g-pr-125--sm {
    padding-right: 8.92857rem !important;
  }
  /* P */
  .g-pr-130--sm {
    padding-right: 9.28571rem !important;
  }
  /* P */
  .g-pr-135--sm {
    padding-right: 9.64286rem !important;
  }
  /* P */
  .g-pr-140--sm {
    padding-right: 10rem !important;
  }
  /* P */
  .g-pr-145--sm {
    padding-right: 10.35714rem !important;
  }
  /* P */
  .g-pr-150--sm {
    padding-right: 10.71429rem !important;
  }
  /* P */
  .g-pr-155--sm {
    padding-right: 11.07143rem !important;
  }
  /* P */
  .g-pr-160--sm {
    padding-right: 11.42857rem !important;
  }
  /* P */
  .g-pr-165--sm {
    padding-right: 11.78571rem !important;
  }
  /* P */
  .g-pr-170--sm {
    padding-right: 12.14286rem !important;
  }
  /* Padding Bottom */
  .g-pb-0--sm {
    padding-bottom: 0px !important;
  }
  .g-pb-1--sm {
    padding-bottom: 0.07143rem !important;
  }
  .g-pb-2--sm {
    padding-bottom: 0.14286rem !important;
  }
  .g-pb-3--sm {
    padding-bottom: 0.21429rem !important;
  }
  .g-pb-4--sm {
    padding-bottom: 0.28571rem !important;
  }
  .g-pb-5--sm {
    padding-bottom: 0.35714rem !important;
  }
  .g-pb-6--sm {
    padding-bottom: 0.42857rem !important;
  }
  .g-pb-7--sm {
    padding-bottom: 0.5rem !important;
  }
  .g-pb-8--sm {
    padding-bottom: 0.57143rem !important;
  }
  .g-pb-9--sm {
    padding-bottom: 0.64286rem !important;
  }
  .g-pb-10--sm {
    padding-bottom: 0.71429rem !important;
  }
  .g-pb-15--sm {
    padding-bottom: 1.07143rem !important;
  }
  .g-pb-20--sm {
    padding-bottom: 1.42857rem !important;
  }
  .g-pb-25--sm {
    padding-bottom: 1.78571rem !important;
  }
  .g-pb-30--sm {
    padding-bottom: 2.14286rem !important;
  }
  .g-pb-35--sm {
    padding-bottom: 2.5rem !important;
  }
  .g-pb-40--sm {
    padding-bottom: 2.85714rem !important;
  }
  .g-pb-45--sm {
    padding-bottom: 3.21429rem !important;
  }
  .g-pb-50--sm {
    padding-bottom: 3.57143rem !important;
  }
  .g-pb-55--sm {
    padding-bottom: 3.92857rem !important;
  }
  .g-pb-60--sm {
    padding-bottom: 4.28571rem !important;
  }
  .g-pb-65--sm {
    padding-bottom: 4.64286rem !important;
  }
  .g-pb-70--sm {
    padding-bottom: 5rem !important;
  }
  .g-pb-75--sm {
    padding-bottom: 5.35714rem !important;
  }
  .g-pb-80--sm {
    padding-bottom: 5.71429rem !important;
  }
  .g-pb-85--sm {
    padding-bottom: 6.07143rem !important;
  }
  .g-pb-90--sm {
    padding-bottom: 6.42857rem !important;
  }
  .g-pb-95--sm {
    padding-bottom: 6.78571rem !important;
  }
  .g-pb-100--sm {
    padding-bottom: 7.14286rem !important;
  }
  .g-pb-105--sm {
    padding-bottom: 7.5rem !important;
  }
  .g-pb-110--sm {
    padding-bottom: 7.85714rem !important;
  }
  .g-pb-115--sm {
    padding-bottom: 8.21429rem !important;
  }
  .g-pb-120--sm {
    padding-bottom: 8.57143rem !important;
  }
  .g-pb-125--sm {
    padding-bottom: 8.92857rem !important;
  }
  .g-pb-130--sm {
    padding-bottom: 9.28571rem !important;
  }
  .g-pb-135--sm {
    padding-bottom: 9.64286rem !important;
  }
  .g-pb-140--sm {
    padding-bottom: 10rem !important;
  }
  .g-pb-145--sm {
    padding-bottom: 10.35714rem !important;
  }
  .g-pb-150--sm {
    padding-bottom: 10.71429rem !important;
  }
  /* Padding Left */
  .g-pl-0--sm {
    padding-left: 0px !important;
  }
  .g-pl-1--sm {
    padding-left: 0.07143rem !important;
  }
  .g-pl-2--sm {
    padding-left: 0.14286rem !important;
  }
  .g-pl-3--sm {
    padding-left: 0.21429rem !important;
  }
  .g-pl-4--sm {
    padding-left: 0.28571rem !important;
  }
  .g-pl-5--sm {
    padding-left: 0.35714rem !important;
  }
  .g-pl-6--sm {
    padding-left: 0.42857rem !important;
  }
  .g-pl-7--sm {
    padding-left: 0.5rem !important;
  }
  .g-pl-8--sm {
    padding-left: 0.57143rem !important;
  }
  .g-pl-9--sm {
    padding-left: 0.64286rem !important;
  }
  /* P */
  .g-pl-10--sm {
    padding-left: 0.71429rem !important;
  }
  /* P */
  .g-pl-15--sm {
    padding-left: 1.07143rem !important;
  }
  /* P */
  .g-pl-20--sm {
    padding-left: 1.42857rem !important;
  }
  /* P */
  .g-pl-25--sm {
    padding-left: 1.78571rem !important;
  }
  /* P */
  .g-pl-30--sm {
    padding-left: 2.14286rem !important;
  }
  /* P */
  .g-pl-35--sm {
    padding-left: 2.5rem !important;
  }
  /* P */
  .g-pl-40--sm {
    padding-left: 2.85714rem !important;
  }
  /* P */
  .g-pl-45--sm {
    padding-left: 3.21429rem !important;
  }
  /* P */
  .g-pl-50--sm {
    padding-left: 3.57143rem !important;
  }
  /* P */
  .g-pl-55--sm {
    padding-left: 3.92857rem !important;
  }
  /* P */
  .g-pl-60--sm {
    padding-left: 4.28571rem !important;
  }
  /* P */
  .g-pl-65--sm {
    padding-left: 4.64286rem !important;
  }
  /* P */
  .g-pl-70--sm {
    padding-left: 5rem !important;
  }
  /* P */
  .g-pl-75--sm {
    padding-left: 5.35714rem !important;
  }
  /* P */
  .g-pl-80--sm {
    padding-left: 5.71429rem !important;
  }
  /* P */
  .g-pl-85--sm {
    padding-left: 6.07143rem !important;
  }
  /* P */
  .g-pl-90--sm {
    padding-left: 6.42857rem !important;
  }
  /* P */
  .g-pl-95--sm {
    padding-left: 6.78571rem !important;
  }
  /* P */
  .g-pl-100--sm {
    padding-left: 7.14286rem !important;
  }
  /* P */
  .g-pl-105--sm {
    padding-left: 7.5rem !important;
  }
  /* P */
  .g-pl-110--sm {
    padding-left: 7.85714rem !important;
  }
  /* P */
  .g-pl-115--sm {
    padding-left: 8.21429rem !important;
  }
  /* P */
  .g-pl-120--sm {
    padding-left: 8.57143rem !important;
  }
  /* P */
  .g-pl-125--sm {
    padding-left: 8.92857rem !important;
  }
  /* P */
  .g-pl-130--sm {
    padding-left: 9.28571rem !important;
  }
  /* P */
  .g-pl-135--sm {
    padding-left: 9.64286rem !important;
  }
  /* P */
  .g-pl-140--sm {
    padding-left: 10rem !important;
  }
  /* P */
  .g-pl-145--sm {
    padding-left: 10.35714rem !important;
  }
  /* P */
  .g-pl-150--sm {
    padding-left: 10.71429rem !important;
  }
  /* P */
  .g-pl-155--sm {
    padding-left: 11.07143rem !important;
  }
  /* P */
  .g-pl-160--sm {
    padding-left: 11.42857rem !important;
  }
  /* P */
  .g-pl-165--sm {
    padding-left: 11.78571rem !important;
  }
  /* P */
  .g-pl-170--sm {
    padding-left: 12.14286rem !important;
  }
}

/* Padding Spaces (md)
------------------------------------*/
/* P */
@media (min-width: 768px) {
  .g-pa-0--md {
    padding: 0 !important;
  }
  .g-px-0--md {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .g-py-0--md {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .g-pt-0--md {
    padding-top: 0 !important;
  }
  .g-pr-0--md {
    padding-right: 0 !important;
  }
  .g-pb-0--md {
    padding-bottom: 0 !important;
  }
  .g-pl-0--md {
    padding-left: 0 !important;
  }
  /* Padding Around */
  .g-pa-2--md {
    padding: 0.14286rem !important;
  }
  .g-pa-3--md {
    padding: 0.21429rem !important;
  }
  .g-pa-5--md {
    padding: 0.35714rem !important;
  }
  .g-pa-7--md {
    padding: 0.5rem !important;
  }
  .g-pa-10--md {
    padding: 0.71429rem !important;
  }
  .g-pa-15--md {
    padding: 1.07143rem !important;
  }
  .g-pa-20--md {
    padding: 1.42857rem !important;
  }
  .g-pa-25--md {
    padding: 1.78571rem !important;
  }
  .g-pa-30--md {
    padding: 2.14286rem !important;
  }
  .g-pa-35--md {
    padding: 2.5rem !important;
  }
  .g-pa-40--md {
    padding: 2.85714rem !important;
  }
  .g-pa-45--md {
    padding: 3.21429rem !important;
  }
  .g-pa-50--md {
    padding: 3.57143rem !important;
  }
  .g-pa-55--md {
    padding: 3.92857rem !important;
  }
  .g-pa-60--md {
    padding: 4.28571rem !important;
  }
  .g-pa-65--md {
    padding: 4.64286rem !important;
  }
  .g-pa-70--md {
    padding: 5rem !important;
  }
  .g-pa-75--md {
    padding: 5.35714rem !important;
  }
  .g-pa-80--md {
    padding: 5.71429rem !important;
  }
  .g-pa-85--md {
    padding: 6.07143rem !important;
  }
  .g-pa-90--md {
    padding: 6.42857rem !important;
  }
  .g-pa-95--md {
    padding: 6.78571rem !important;
  }
  .g-pa-100--md {
    padding: 7.14286rem !important;
  }
  .g-pa-105--md {
    padding: 7.5rem !important;
  }
  .g-pa-110--md {
    padding: 7.85714rem !important;
  }
  .g-pa-115--md {
    padding: 8.21429rem !important;
  }
  .g-pa-120--md {
    padding: 8.57143rem !important;
  }
  .g-pa-125--md {
    padding: 8.92857rem !important;
  }
  .g-pa-130--md {
    padding: 9.28571rem !important;
  }
  .g-pa-135--md {
    padding: 9.64286rem !important;
  }
  .g-pa-140--md {
    padding: 10rem !important;
  }
  .g-pa-145--md {
    padding: 10.35714rem !important;
  }
  .g-pa-150--md {
    padding: 10.71429rem !important;
  }
  /* Padding X */
  .g-px-1--md {
    padding-left: 0.07143rem !important;
    padding-right: 0.07143rem !important;
  }
  .g-px-2--md {
    padding-left: 0.14286rem !important;
    padding-right: 0.14286rem !important;
  }
  .g-px-3--md {
    padding-left: 0.21429rem !important;
    padding-right: 0.21429rem !important;
  }
  .g-px-4--md {
    padding-left: 0.28571rem !important;
    padding-right: 0.28571rem !important;
  }
  .g-px-5--md {
    padding-left: 0.35714rem !important;
    padding-right: 0.35714rem !important;
  }
  .g-px-6--md {
    padding-left: 0.42857rem !important;
    padding-right: 0.42857rem !important;
  }
  .g-px-7--md {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
  .g-px-8--md {
    padding-left: 0.57143rem !important;
    padding-right: 0.57143rem !important;
  }
  .g-px-9--md {
    padding-left: 0.64286rem !important;
    padding-right: 0.64286rem !important;
  }
  .g-px-10--md {
    padding-left: 0.71429rem !important;
    padding-right: 0.71429rem !important;
  }
  .g-px-11--md {
    padding-left: 0.78571rem !important;
    padding-right: 0.78571rem !important;
  }
  .g-px-12--md {
    padding-left: 0.85714rem !important;
    padding-right: 0.85714rem !important;
  }
  .g-px-13--md {
    padding-left: 0.92857rem !important;
    padding-right: 0.92857rem !important;
  }
  .g-px-14--md {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .g-px-15--md {
    padding-left: 1.07143rem !important;
    padding-right: 1.07143rem !important;
  }
  .g-px-16--md {
    padding-left: 1.14286rem !important;
    padding-right: 1.14286rem !important;
  }
  .g-px-17--md {
    padding-left: 1.21429rem !important;
    padding-right: 1.21429rem !important;
  }
  .g-px-18--md {
    padding-left: 1.28571rem !important;
    padding-right: 1.28571rem !important;
  }
  .g-px-19--md {
    padding-left: 1.35714rem !important;
    padding-right: 1.35714rem !important;
  }
  .g-px-10--md {
    padding-left: 0.71429rem !important;
    padding-right: 0.71429rem !important;
  }
  .g-px-15--md {
    padding-left: 1.07143rem !important;
    padding-right: 1.07143rem !important;
  }
  .g-px-20--md {
    padding-left: 1.42857rem !important;
    padding-right: 1.42857rem !important;
  }
  .g-px-25--md {
    padding-left: 1.78571rem !important;
    padding-right: 1.78571rem !important;
  }
  .g-px-30--md {
    padding-left: 2.14286rem !important;
    padding-right: 2.14286rem !important;
  }
  .g-px-35--md {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }
  .g-px-40--md {
    padding-left: 2.85714rem !important;
    padding-right: 2.85714rem !important;
  }
  .g-px-45--md {
    padding-left: 3.21429rem !important;
    padding-right: 3.21429rem !important;
  }
  .g-px-50--md {
    padding-left: 3.57143rem !important;
    padding-right: 3.57143rem !important;
  }
  .g-px-55--md {
    padding-left: 3.92857rem !important;
    padding-right: 3.92857rem !important;
  }
  .g-px-60--md {
    padding-left: 4.28571rem !important;
    padding-right: 4.28571rem !important;
  }
  .g-px-65--md {
    padding-left: 4.64286rem !important;
    padding-right: 4.64286rem !important;
  }
  .g-px-70--md {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }
  .g-px-75--md {
    padding-left: 5.35714rem !important;
    padding-right: 5.35714rem !important;
  }
  .g-px-80--md {
    padding-left: 5.71429rem !important;
    padding-right: 5.71429rem !important;
  }
  .g-px-85--md {
    padding-left: 6.07143rem !important;
    padding-right: 6.07143rem !important;
  }
  .g-px-90--md {
    padding-left: 6.42857rem !important;
    padding-right: 6.42857rem !important;
  }
  .g-px-95--md {
    padding-left: 6.78571rem !important;
    padding-right: 6.78571rem !important;
  }
  .g-px-100--md {
    padding-left: 7.14286rem !important;
    padding-right: 7.14286rem !important;
  }
  .g-px-105--md {
    padding-left: 7.5rem !important;
    padding-right: 7.5rem !important;
  }
  .g-px-110--md {
    padding-left: 7.85714rem !important;
    padding-right: 7.85714rem !important;
  }
  .g-px-115--md {
    padding-left: 8.21429rem !important;
    padding-right: 8.21429rem !important;
  }
  .g-px-120--md {
    padding-left: 8.57143rem !important;
    padding-right: 8.57143rem !important;
  }
  .g-px-125--md {
    padding-left: 8.92857rem !important;
    padding-right: 8.92857rem !important;
  }
  .g-px-130--md {
    padding-left: 9.28571rem !important;
    padding-right: 9.28571rem !important;
  }
  .g-px-135--md {
    padding-left: 9.64286rem !important;
    padding-right: 9.64286rem !important;
  }
  .g-px-140--md {
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }
  .g-px-145--md {
    padding-left: 10.35714rem !important;
    padding-right: 10.35714rem !important;
  }
  .g-px-150--md {
    padding-left: 10.71429rem !important;
    padding-right: 10.71429rem !important;
  }
  /* Padding Y */
  .g-py-1--md {
    padding-top: 0.07143rem !important;
    padding-bottom: 0.07143rem !important;
  }
  .g-py-2--md {
    padding-top: 0.14286rem !important;
    padding-bottom: 0.14286rem !important;
  }
  .g-py-3--md {
    padding-top: 0.21429rem !important;
    padding-bottom: 0.21429rem !important;
  }
  .g-py-4--md {
    padding-top: 0.28571rem !important;
    padding-bottom: 0.28571rem !important;
  }
  .g-py-5--md {
    padding-top: 0.35714rem !important;
    padding-bottom: 0.35714rem !important;
  }
  .g-py-6--md {
    padding-top: 0.42857rem !important;
    padding-bottom: 0.42857rem !important;
  }
  .g-py-7--md {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .g-py-8--md {
    padding-top: 0.57143rem !important;
    padding-bottom: 0.57143rem !important;
  }
  .g-py-9--md {
    padding-top: 0.64286rem !important;
    padding-bottom: 0.64286rem !important;
  }
  .g-py-10--md {
    padding-top: 0.71429rem !important;
    padding-bottom: 0.71429rem !important;
  }
  .g-py-11--md {
    padding-top: 0.78571rem !important;
    padding-bottom: 0.78571rem !important;
  }
  .g-py-12--md {
    padding-top: 0.85714rem !important;
    padding-bottom: 0.85714rem !important;
  }
  .g-py-13--md {
    padding-top: 0.92857rem !important;
    padding-bottom: 0.92857rem !important;
  }
  .g-py-14--md {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .g-py-15--md {
    padding-top: 1.07143rem !important;
    padding-bottom: 1.07143rem !important;
  }
  .g-py-16--md {
    padding-top: 1.14286rem !important;
    padding-bottom: 1.14286rem !important;
  }
  .g-py-17--md {
    padding-top: 1.21429rem !important;
    padding-bottom: 1.21429rem !important;
  }
  .g-py-18--md {
    padding-top: 1.28571rem !important;
    padding-bottom: 1.28571rem !important;
  }
  .g-py-19--md {
    padding-top: 1.35714rem !important;
    padding-bottom: 1.35714rem !important;
  }
  /* P */
  .g-py-10--md {
    padding-top: 0.71429rem !important;
    padding-bottom: 0.71429rem !important;
  }
  /* P */
  .g-py-15--md {
    padding-top: 1.07143rem !important;
    padding-bottom: 1.07143rem !important;
  }
  /* P */
  .g-py-20--md {
    padding-top: 1.42857rem !important;
    padding-bottom: 1.42857rem !important;
  }
  /* P */
  .g-py-25--md {
    padding-top: 1.78571rem !important;
    padding-bottom: 1.78571rem !important;
  }
  /* P */
  .g-py-30--md {
    padding-top: 2.14286rem !important;
    padding-bottom: 2.14286rem !important;
  }
  /* P */
  .g-py-35--md {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  /* P */
  .g-py-40--md {
    padding-top: 2.85714rem !important;
    padding-bottom: 2.85714rem !important;
  }
  /* P */
  .g-py-45--md {
    padding-top: 3.21429rem !important;
    padding-bottom: 3.21429rem !important;
  }
  /* P */
  .g-py-50--md {
    padding-top: 3.57143rem !important;
    padding-bottom: 3.57143rem !important;
  }
  /* P */
  .g-py-55--md {
    padding-top: 3.92857rem !important;
    padding-bottom: 3.92857rem !important;
  }
  /* P */
  .g-py-60--md {
    padding-top: 4.28571rem !important;
    padding-bottom: 4.28571rem !important;
  }
  /* P */
  .g-py-65--md {
    padding-top: 4.64286rem !important;
    padding-bottom: 4.64286rem !important;
  }
  /* P */
  .g-py-70--md {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  /* P */
  .g-py-75--md {
    padding-top: 5.35714rem !important;
    padding-bottom: 5.35714rem !important;
  }
  /* P */
  .g-py-80--md {
    padding-top: 5.71429rem !important;
    padding-bottom: 5.71429rem !important;
  }
  /* P */
  .g-py-85--md {
    padding-top: 6.07143rem !important;
    padding-bottom: 6.07143rem !important;
  }
  /* P */
  .g-py-90--md {
    padding-top: 6.42857rem !important;
    padding-bottom: 6.42857rem !important;
  }
  /* P */
  .g-py-95--md {
    padding-top: 6.78571rem !important;
    padding-bottom: 6.78571rem !important;
  }
  /* P */
  .g-py-100--md {
    padding-top: 7.14286rem !important;
    padding-bottom: 7.14286rem !important;
  }
  /* P */
  .g-py-105--md {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }
  /* P */
  .g-py-110--md {
    padding-top: 7.85714rem !important;
    padding-bottom: 7.85714rem !important;
  }
  /* P */
  .g-py-115--md {
    padding-top: 8.21429rem !important;
    padding-bottom: 8.21429rem !important;
  }
  /* P */
  .g-py-120--md {
    padding-top: 8.57143rem !important;
    padding-bottom: 8.57143rem !important;
  }
  /* P */
  .g-py-125--md {
    padding-top: 8.92857rem !important;
    padding-bottom: 8.92857rem !important;
  }
  /* P */
  .g-py-130--md {
    padding-top: 9.28571rem !important;
    padding-bottom: 9.28571rem !important;
  }
  /* P */
  .g-py-135--md {
    padding-top: 9.64286rem !important;
    padding-bottom: 9.64286rem !important;
  }
  /* P */
  .g-py-140--md {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  /* P */
  .g-py-145--md {
    padding-top: 10.35714rem !important;
    padding-bottom: 10.35714rem !important;
  }
  /* P */
  .g-py-150--md {
    padding-top: 10.71429rem !important;
    padding-bottom: 10.71429rem !important;
  }
  /* P */
  .g-py-155--md {
    padding-top: 11.07143rem !important;
    padding-bottom: 11.07143rem !important;
  }
  /* P */
  .g-py-160--md {
    padding-top: 11.42857rem !important;
    padding-bottom: 11.42857rem !important;
  }
  /* P */
  .g-py-165--md {
    padding-top: 11.78571rem !important;
    padding-bottom: 11.78571rem !important;
  }
  /* P */
  .g-py-170--md {
    padding-top: 12.14286rem !important;
    padding-bottom: 12.14286rem !important;
  }
  /* P */
  .g-py-175--md {
    padding-top: 12.5rem !important;
    padding-bottom: 12.5rem !important;
  }
  /* P */
  .g-py-180--md {
    padding-top: 12.85714rem !important;
    padding-bottom: 12.85714rem !important;
  }
  /* P */
  .g-py-185--md {
    padding-top: 13.21429rem !important;
    padding-bottom: 13.21429rem !important;
  }
  /* P */
  .g-py-190--md {
    padding-top: 13.57143rem !important;
    padding-bottom: 13.57143rem !important;
  }
  /* P */
  .g-py-195--md {
    padding-top: 13.92857rem !important;
    padding-bottom: 13.92857rem !important;
  }
  /* P */
  .g-py-200--md {
    padding-top: 14.28571rem !important;
    padding-bottom: 14.28571rem !important;
  }
  /* P */
  .g-py-205--md {
    padding-top: 14.64286rem !important;
    padding-bottom: 14.64286rem !important;
  }
  /* P */
  .g-py-210--md {
    padding-top: 15rem !important;
    padding-bottom: 15rem !important;
  }
  /* Padding Top */
  .g-pt-0--md {
    padding-top: 0px !important;
  }
  .g-pt-1--md {
    padding-top: 0.07143rem !important;
  }
  .g-pt-2--md {
    padding-top: 0.14286rem !important;
  }
  .g-pt-3--md {
    padding-top: 0.21429rem !important;
  }
  .g-pt-4--md {
    padding-top: 0.28571rem !important;
  }
  .g-pt-5--md {
    padding-top: 0.35714rem !important;
  }
  .g-pt-6--md {
    padding-top: 0.42857rem !important;
  }
  .g-pt-7--md {
    padding-top: 0.5rem !important;
  }
  .g-pt-8--md {
    padding-top: 0.57143rem !important;
  }
  .g-pt-9--md {
    padding-top: 0.64286rem !important;
  }
  .g-pt-10--md {
    padding-top: 0.71429rem !important;
  }
  .g-pt-11--md {
    padding-top: 0.78571rem !important;
  }
  .g-pt-12--md {
    padding-top: 0.85714rem !important;
  }
  .g-pt-13--md {
    padding-top: 0.92857rem !important;
  }
  .g-pt-14--md {
    padding-top: 1rem !important;
  }
  .g-pt-15--md {
    padding-top: 1.07143rem !important;
  }
  .g-pt-16--md {
    padding-top: 1.14286rem !important;
  }
  .g-pt-17--md {
    padding-top: 1.21429rem !important;
  }
  .g-pt-18--md {
    padding-top: 1.28571rem !important;
  }
  .g-pt-19--md {
    padding-top: 1.35714rem !important;
  }
  .g-pt-10--md {
    padding-top: 0.71429rem !important;
  }
  .g-pt-15--md {
    padding-top: 1.07143rem !important;
  }
  .g-pt-20--md {
    padding-top: 1.42857rem !important;
  }
  .g-pt-25--md {
    padding-top: 1.78571rem !important;
  }
  .g-pt-30--md {
    padding-top: 2.14286rem !important;
  }
  .g-pt-35--md {
    padding-top: 2.5rem !important;
  }
  .g-pt-40--md {
    padding-top: 2.85714rem !important;
  }
  .g-pt-45--md {
    padding-top: 3.21429rem !important;
  }
  .g-pt-50--md {
    padding-top: 3.57143rem !important;
  }
  .g-pt-55--md {
    padding-top: 3.92857rem !important;
  }
  .g-pt-60--md {
    padding-top: 4.28571rem !important;
  }
  .g-pt-65--md {
    padding-top: 4.64286rem !important;
  }
  .g-pt-70--md {
    padding-top: 5rem !important;
  }
  .g-pt-75--md {
    padding-top: 5.35714rem !important;
  }
  .g-pt-80--md {
    padding-top: 5.71429rem !important;
  }
  .g-pt-85--md {
    padding-top: 6.07143rem !important;
  }
  .g-pt-90--md {
    padding-top: 6.42857rem !important;
  }
  .g-pt-95--md {
    padding-top: 6.78571rem !important;
  }
  .g-pt-100--md {
    padding-top: 7.14286rem !important;
  }
  .g-pt-105--md {
    padding-top: 7.5rem !important;
  }
  .g-pt-110--md {
    padding-top: 7.85714rem !important;
  }
  .g-pt-115--md {
    padding-top: 8.21429rem !important;
  }
  .g-pt-120--md {
    padding-top: 8.57143rem !important;
  }
  .g-pt-125--md {
    padding-top: 8.92857rem !important;
  }
  .g-pt-130--md {
    padding-top: 9.28571rem !important;
  }
  .g-pt-135--md {
    padding-top: 9.64286rem !important;
  }
  .g-pt-140--md {
    padding-top: 10rem !important;
  }
  .g-pt-145--md {
    padding-top: 10.35714rem !important;
  }
  .g-pt-150--md {
    padding-top: 10.71429rem !important;
  }
  /* Padding Right */
  .g-pr-0--md {
    padding-right: 0px !important;
  }
  .g-pr-1--md {
    padding-right: 0.07143rem !important;
  }
  .g-pr-2--md {
    padding-right: 0.14286rem !important;
  }
  .g-pr-3--md {
    padding-right: 0.21429rem !important;
  }
  .g-pr-4--md {
    padding-right: 0.28571rem !important;
  }
  .g-pr-5--md {
    padding-right: 0.35714rem !important;
  }
  .g-pr-6--md {
    padding-right: 0.42857rem !important;
  }
  .g-pr-7--md {
    padding-right: 0.5rem !important;
  }
  .g-pr-8--md {
    padding-right: 0.57143rem !important;
  }
  .g-pr-9--md {
    padding-right: 0.64286rem !important;
  }
  /* P */
  .g-pr-10--md {
    padding-right: 0.71429rem !important;
  }
  /* P */
  .g-pr-15--md {
    padding-right: 1.07143rem !important;
  }
  /* P */
  .g-pr-20--md {
    padding-right: 1.42857rem !important;
  }
  /* P */
  .g-pr-25--md {
    padding-right: 1.78571rem !important;
  }
  /* P */
  .g-pr-30--md {
    padding-right: 2.14286rem !important;
  }
  /* P */
  .g-pr-35--md {
    padding-right: 2.5rem !important;
  }
  /* P */
  .g-pr-40--md {
    padding-right: 2.85714rem !important;
  }
  /* P */
  .g-pr-45--md {
    padding-right: 3.21429rem !important;
  }
  /* P */
  .g-pr-50--md {
    padding-right: 3.57143rem !important;
  }
  /* P */
  .g-pr-55--md {
    padding-right: 3.92857rem !important;
  }
  /* P */
  .g-pr-60--md {
    padding-right: 4.28571rem !important;
  }
  /* P */
  .g-pr-65--md {
    padding-right: 4.64286rem !important;
  }
  /* P */
  .g-pr-70--md {
    padding-right: 5rem !important;
  }
  /* P */
  .g-pr-75--md {
    padding-right: 5.35714rem !important;
  }
  /* P */
  .g-pr-80--md {
    padding-right: 5.71429rem !important;
  }
  /* P */
  .g-pr-85--md {
    padding-right: 6.07143rem !important;
  }
  /* P */
  .g-pr-90--md {
    padding-right: 6.42857rem !important;
  }
  /* P */
  .g-pr-95--md {
    padding-right: 6.78571rem !important;
  }
  /* P */
  .g-pr-100--md {
    padding-right: 7.14286rem !important;
  }
  /* P */
  .g-pr-105--md {
    padding-right: 7.5rem !important;
  }
  /* P */
  .g-pr-110--md {
    padding-right: 7.85714rem !important;
  }
  /* P */
  .g-pr-115--md {
    padding-right: 8.21429rem !important;
  }
  /* P */
  .g-pr-120--md {
    padding-right: 8.57143rem !important;
  }
  /* P */
  .g-pr-125--md {
    padding-right: 8.92857rem !important;
  }
  /* P */
  .g-pr-130--md {
    padding-right: 9.28571rem !important;
  }
  /* P */
  .g-pr-135--md {
    padding-right: 9.64286rem !important;
  }
  /* P */
  .g-pr-140--md {
    padding-right: 10rem !important;
  }
  /* P */
  .g-pr-145--md {
    padding-right: 10.35714rem !important;
  }
  /* P */
  .g-pr-150--md {
    padding-right: 10.71429rem !important;
  }
  /* P */
  .g-pr-155--md {
    padding-right: 11.07143rem !important;
  }
  /* P */
  .g-pr-160--md {
    padding-right: 11.42857rem !important;
  }
  /* P */
  .g-pr-165--md {
    padding-right: 11.78571rem !important;
  }
  /* P */
  .g-pr-170--md {
    padding-right: 12.14286rem !important;
  }
  /* Padding Bottom */
  .g-pb-0--md {
    padding-bottom: 0px !important;
  }
  .g-pb-1--md {
    padding-bottom: 0.07143rem !important;
  }
  .g-pb-2--md {
    padding-bottom: 0.14286rem !important;
  }
  .g-pb-3--md {
    padding-bottom: 0.21429rem !important;
  }
  .g-pb-4--md {
    padding-bottom: 0.28571rem !important;
  }
  .g-pb-5--md {
    padding-bottom: 0.35714rem !important;
  }
  .g-pb-6--md {
    padding-bottom: 0.42857rem !important;
  }
  .g-pb-7--md {
    padding-bottom: 0.5rem !important;
  }
  .g-pb-8--md {
    padding-bottom: 0.57143rem !important;
  }
  .g-pb-9--md {
    padding-bottom: 0.64286rem !important;
  }
  .g-pb-10--md {
    padding-bottom: 0.71429rem !important;
  }
  .g-pb-15--md {
    padding-bottom: 1.07143rem !important;
  }
  .g-pb-20--md {
    padding-bottom: 1.42857rem !important;
  }
  .g-pb-25--md {
    padding-bottom: 1.78571rem !important;
  }
  .g-pb-30--md {
    padding-bottom: 2.14286rem !important;
  }
  .g-pb-35--md {
    padding-bottom: 2.5rem !important;
  }
  .g-pb-40--md {
    padding-bottom: 2.85714rem !important;
  }
  .g-pb-45--md {
    padding-bottom: 3.21429rem !important;
  }
  .g-pb-50--md {
    padding-bottom: 3.57143rem !important;
  }
  .g-pb-55--md {
    padding-bottom: 3.92857rem !important;
  }
  .g-pb-60--md {
    padding-bottom: 4.28571rem !important;
  }
  .g-pb-65--md {
    padding-bottom: 4.64286rem !important;
  }
  .g-pb-70--md {
    padding-bottom: 5rem !important;
  }
  .g-pb-75--md {
    padding-bottom: 5.35714rem !important;
  }
  .g-pb-80--md {
    padding-bottom: 5.71429rem !important;
  }
  .g-pb-85--md {
    padding-bottom: 6.07143rem !important;
  }
  .g-pb-90--md {
    padding-bottom: 6.42857rem !important;
  }
  .g-pb-95--md {
    padding-bottom: 6.78571rem !important;
  }
  .g-pb-100--md {
    padding-bottom: 7.14286rem !important;
  }
  .g-pb-105--md {
    padding-bottom: 7.5rem !important;
  }
  .g-pb-110--md {
    padding-bottom: 7.85714rem !important;
  }
  .g-pb-115--md {
    padding-bottom: 8.21429rem !important;
  }
  .g-pb-120--md {
    padding-bottom: 8.57143rem !important;
  }
  .g-pb-125--md {
    padding-bottom: 8.92857rem !important;
  }
  .g-pb-130--md {
    padding-bottom: 9.28571rem !important;
  }
  .g-pb-135--md {
    padding-bottom: 9.64286rem !important;
  }
  .g-pb-140--md {
    padding-bottom: 10rem !important;
  }
  .g-pb-145--md {
    padding-bottom: 10.35714rem !important;
  }
  .g-pb-150--md {
    padding-bottom: 10.71429rem !important;
  }
  /* Padding Left */
  .g-pl-0--md {
    padding-left: 0px !important;
  }
  .g-pl-1--md {
    padding-left: 0.07143rem !important;
  }
  .g-pl-2--md {
    padding-left: 0.14286rem !important;
  }
  .g-pl-3--md {
    padding-left: 0.21429rem !important;
  }
  .g-pl-4--md {
    padding-left: 0.28571rem !important;
  }
  .g-pl-5--md {
    padding-left: 0.35714rem !important;
  }
  .g-pl-6--md {
    padding-left: 0.42857rem !important;
  }
  .g-pl-7--md {
    padding-left: 0.5rem !important;
  }
  .g-pl-8--md {
    padding-left: 0.57143rem !important;
  }
  .g-pl-9--md {
    padding-left: 0.64286rem !important;
  }
  /* P */
  .g-pl-10--md {
    padding-left: 0.71429rem !important;
  }
  /* P */
  .g-pl-15--md {
    padding-left: 1.07143rem !important;
  }
  /* P */
  .g-pl-20--md {
    padding-left: 1.42857rem !important;
  }
  /* P */
  .g-pl-25--md {
    padding-left: 1.78571rem !important;
  }
  /* P */
  .g-pl-30--md {
    padding-left: 2.14286rem !important;
  }
  /* P */
  .g-pl-35--md {
    padding-left: 2.5rem !important;
  }
  /* P */
  .g-pl-40--md {
    padding-left: 2.85714rem !important;
  }
  /* P */
  .g-pl-45--md {
    padding-left: 3.21429rem !important;
  }
  /* P */
  .g-pl-50--md {
    padding-left: 3.57143rem !important;
  }
  /* P */
  .g-pl-55--md {
    padding-left: 3.92857rem !important;
  }
  /* P */
  .g-pl-60--md {
    padding-left: 4.28571rem !important;
  }
  /* P */
  .g-pl-65--md {
    padding-left: 4.64286rem !important;
  }
  /* P */
  .g-pl-70--md {
    padding-left: 5rem !important;
  }
  /* P */
  .g-pl-75--md {
    padding-left: 5.35714rem !important;
  }
  /* P */
  .g-pl-80--md {
    padding-left: 5.71429rem !important;
  }
  /* P */
  .g-pl-85--md {
    padding-left: 6.07143rem !important;
  }
  /* P */
  .g-pl-90--md {
    padding-left: 6.42857rem !important;
  }
  /* P */
  .g-pl-95--md {
    padding-left: 6.78571rem !important;
  }
  /* P */
  .g-pl-100--md {
    padding-left: 7.14286rem !important;
  }
  /* P */
  .g-pl-105--md {
    padding-left: 7.5rem !important;
  }
  /* P */
  .g-pl-110--md {
    padding-left: 7.85714rem !important;
  }
  /* P */
  .g-pl-115--md {
    padding-left: 8.21429rem !important;
  }
  /* P */
  .g-pl-120--md {
    padding-left: 8.57143rem !important;
  }
  /* P */
  .g-pl-125--md {
    padding-left: 8.92857rem !important;
  }
  /* P */
  .g-pl-130--md {
    padding-left: 9.28571rem !important;
  }
  /* P */
  .g-pl-135--md {
    padding-left: 9.64286rem !important;
  }
  /* P */
  .g-pl-140--md {
    padding-left: 10rem !important;
  }
  /* P */
  .g-pl-145--md {
    padding-left: 10.35714rem !important;
  }
  /* P */
  .g-pl-150--md {
    padding-left: 10.71429rem !important;
  }
  /* P */
  .g-pl-155--md {
    padding-left: 11.07143rem !important;
  }
  /* P */
  .g-pl-160--md {
    padding-left: 11.42857rem !important;
  }
  /* P */
  .g-pl-165--md {
    padding-left: 11.78571rem !important;
  }
  /* P */
  .g-pl-170--md {
    padding-left: 12.14286rem !important;
  }
}

/* Padding Spaces (lg) P
------------------------------------*/
/* P */
@media (min-width: 992px) {
  .g-pa-0--lg {
    padding: 0 !important;
  }
  .g-px-0--lg {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .g-py-0--lg {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .g-pt-0--lg {
    padding-top: 0 !important;
  }
  .g-pr-0--lg {
    padding-right: 0 !important;
  }
  .g-pb-0--lg {
    padding-bottom: 0 !important;
  }
  .g-pl-0--lg {
    padding-left: 0 !important;
  }
  /* Padding Around */
  .g-pa-2--lg {
    padding: 0.14286rem !important;
  }
  .g-pa-3--lg {
    padding: 0.21429rem !important;
  }
  .g-pa-5--lg {
    padding: 0.35714rem !important;
  }
  .g-pa-7--lg {
    padding: 0.5rem !important;
  }
  .g-pa-10--lg {
    padding: 0.71429rem !important;
  }
  .g-pa-15--lg {
    padding: 1.07143rem !important;
  }
  .g-pa-20--lg {
    padding: 1.42857rem !important;
  }
  .g-pa-25--lg {
    padding: 1.78571rem !important;
  }
  .g-pa-30--lg {
    padding: 2.14286rem !important;
  }
  .g-pa-35--lg {
    padding: 2.5rem !important;
  }
  .g-pa-40--lg {
    padding: 2.85714rem !important;
  }
  .g-pa-45--lg {
    padding: 3.21429rem !important;
  }
  .g-pa-50--lg {
    padding: 3.57143rem !important;
  }
  .g-pa-55--lg {
    padding: 3.92857rem !important;
  }
  .g-pa-60--lg {
    padding: 4.28571rem !important;
  }
  .g-pa-65--lg {
    padding: 4.64286rem !important;
  }
  .g-pa-70--lg {
    padding: 5rem !important;
  }
  .g-pa-75--lg {
    padding: 5.35714rem !important;
  }
  .g-pa-80--lg {
    padding: 5.71429rem !important;
  }
  .g-pa-85--lg {
    padding: 6.07143rem !important;
  }
  .g-pa-90--lg {
    padding: 6.42857rem !important;
  }
  .g-pa-95--lg {
    padding: 6.78571rem !important;
  }
  .g-pa-100--lg {
    padding: 7.14286rem !important;
  }
  .g-pa-105--lg {
    padding: 7.5rem !important;
  }
  .g-pa-110--lg {
    padding: 7.85714rem !important;
  }
  .g-pa-115--lg {
    padding: 8.21429rem !important;
  }
  .g-pa-120--lg {
    padding: 8.57143rem !important;
  }
  .g-pa-125--lg {
    padding: 8.92857rem !important;
  }
  .g-pa-130--lg {
    padding: 9.28571rem !important;
  }
  .g-pa-135--lg {
    padding: 9.64286rem !important;
  }
  .g-pa-140--lg {
    padding: 10rem !important;
  }
  .g-pa-145--lg {
    padding: 10.35714rem !important;
  }
  .g-pa-150--lg {
    padding: 10.71429rem !important;
  }
  /* Padding X */
  .g-px-1--lg {
    padding-left: 0.07143rem !important;
    padding-right: 0.07143rem !important;
  }
  .g-px-2--lg {
    padding-left: 0.14286rem !important;
    padding-right: 0.14286rem !important;
  }
  .g-px-3--lg {
    padding-left: 0.21429rem !important;
    padding-right: 0.21429rem !important;
  }
  .g-px-4--lg {
    padding-left: 0.28571rem !important;
    padding-right: 0.28571rem !important;
  }
  .g-px-5--lg {
    padding-left: 0.35714rem !important;
    padding-right: 0.35714rem !important;
  }
  .g-px-6--lg {
    padding-left: 0.42857rem !important;
    padding-right: 0.42857rem !important;
  }
  .g-px-7--lg {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
  .g-px-8--lg {
    padding-left: 0.57143rem !important;
    padding-right: 0.57143rem !important;
  }
  .g-px-9--lg {
    padding-left: 0.64286rem !important;
    padding-right: 0.64286rem !important;
  }
  .g-px-10--lg {
    padding-left: 0.71429rem !important;
    padding-right: 0.71429rem !important;
  }
  .g-px-11--lg {
    padding-left: 0.78571rem !important;
    padding-right: 0.78571rem !important;
  }
  .g-px-12--lg {
    padding-left: 0.85714rem !important;
    padding-right: 0.85714rem !important;
  }
  .g-px-13--lg {
    padding-left: 0.92857rem !important;
    padding-right: 0.92857rem !important;
  }
  .g-px-14--lg {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .g-px-15--lg {
    padding-left: 1.07143rem !important;
    padding-right: 1.07143rem !important;
  }
  .g-px-16--lg {
    padding-left: 1.14286rem !important;
    padding-right: 1.14286rem !important;
  }
  .g-px-17--lg {
    padding-left: 1.21429rem !important;
    padding-right: 1.21429rem !important;
  }
  .g-px-18--lg {
    padding-left: 1.28571rem !important;
    padding-right: 1.28571rem !important;
  }
  .g-px-19--lg {
    padding-left: 1.35714rem !important;
    padding-right: 1.35714rem !important;
  }
  .g-px-10--lg {
    padding-left: 0.71429rem !important;
    padding-right: 0.71429rem !important;
  }
  .g-px-15--lg {
    padding-left: 1.07143rem !important;
    padding-right: 1.07143rem !important;
  }
  .g-px-20--lg {
    padding-left: 1.42857rem !important;
    padding-right: 1.42857rem !important;
  }
  .g-px-25--lg {
    padding-left: 1.78571rem !important;
    padding-right: 1.78571rem !important;
  }
  .g-px-30--lg {
    padding-left: 2.14286rem !important;
    padding-right: 2.14286rem !important;
  }
  .g-px-35--lg {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }
  .g-px-40--lg {
    padding-left: 2.85714rem !important;
    padding-right: 2.85714rem !important;
  }
  .g-px-45--lg {
    padding-left: 3.21429rem !important;
    padding-right: 3.21429rem !important;
  }
  .g-px-50--lg {
    padding-left: 3.57143rem !important;
    padding-right: 3.57143rem !important;
  }
  .g-px-55--lg {
    padding-left: 3.92857rem !important;
    padding-right: 3.92857rem !important;
  }
  .g-px-60--lg {
    padding-left: 4.28571rem !important;
    padding-right: 4.28571rem !important;
  }
  .g-px-65--lg {
    padding-left: 4.64286rem !important;
    padding-right: 4.64286rem !important;
  }
  .g-px-70--lg {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }
  .g-px-75--lg {
    padding-left: 5.35714rem !important;
    padding-right: 5.35714rem !important;
  }
  .g-px-80--lg {
    padding-left: 5.71429rem !important;
    padding-right: 5.71429rem !important;
  }
  .g-px-85--lg {
    padding-left: 6.07143rem !important;
    padding-right: 6.07143rem !important;
  }
  .g-px-90--lg {
    padding-left: 6.42857rem !important;
    padding-right: 6.42857rem !important;
  }
  .g-px-95--lg {
    padding-left: 6.78571rem !important;
    padding-right: 6.78571rem !important;
  }
  .g-px-100--lg {
    padding-left: 7.14286rem !important;
    padding-right: 7.14286rem !important;
  }
  .g-px-105--lg {
    padding-left: 7.5rem !important;
    padding-right: 7.5rem !important;
  }
  .g-px-110--lg {
    padding-left: 7.85714rem !important;
    padding-right: 7.85714rem !important;
  }
  .g-px-115--lg {
    padding-left: 8.21429rem !important;
    padding-right: 8.21429rem !important;
  }
  .g-px-120--lg {
    padding-left: 8.57143rem !important;
    padding-right: 8.57143rem !important;
  }
  .g-px-125--lg {
    padding-left: 8.92857rem !important;
    padding-right: 8.92857rem !important;
  }
  .g-px-130--lg {
    padding-left: 9.28571rem !important;
    padding-right: 9.28571rem !important;
  }
  .g-px-135--lg {
    padding-left: 9.64286rem !important;
    padding-right: 9.64286rem !important;
  }
  .g-px-140--lg {
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }
  .g-px-145--lg {
    padding-left: 10.35714rem !important;
    padding-right: 10.35714rem !important;
  }
  .g-px-150--lg {
    padding-left: 10.71429rem !important;
    padding-right: 10.71429rem !important;
  }
  /* Padding Y */
  .g-py-1--lg {
    padding-top: 0.07143rem !important;
    padding-bottom: 0.07143rem !important;
  }
  .g-py-2--lg {
    padding-top: 0.14286rem !important;
    padding-bottom: 0.14286rem !important;
  }
  .g-py-3--lg {
    padding-top: 0.21429rem !important;
    padding-bottom: 0.21429rem !important;
  }
  .g-py-4--lg {
    padding-top: 0.28571rem !important;
    padding-bottom: 0.28571rem !important;
  }
  .g-py-5--lg {
    padding-top: 0.35714rem !important;
    padding-bottom: 0.35714rem !important;
  }
  .g-py-6--lg {
    padding-top: 0.42857rem !important;
    padding-bottom: 0.42857rem !important;
  }
  .g-py-7--lg {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .g-py-8--lg {
    padding-top: 0.57143rem !important;
    padding-bottom: 0.57143rem !important;
  }
  .g-py-9--lg {
    padding-top: 0.64286rem !important;
    padding-bottom: 0.64286rem !important;
  }
  .g-py-10--lg {
    padding-top: 0.71429rem !important;
    padding-bottom: 0.71429rem !important;
  }
  .g-py-11--lg {
    padding-top: 0.78571rem !important;
    padding-bottom: 0.78571rem !important;
  }
  .g-py-12--lg {
    padding-top: 0.85714rem !important;
    padding-bottom: 0.85714rem !important;
  }
  .g-py-13--lg {
    padding-top: 0.92857rem !important;
    padding-bottom: 0.92857rem !important;
  }
  .g-py-14--lg {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .g-py-15--lg {
    padding-top: 1.07143rem !important;
    padding-bottom: 1.07143rem !important;
  }
  .g-py-16--lg {
    padding-top: 1.14286rem !important;
    padding-bottom: 1.14286rem !important;
  }
  .g-py-17--lg {
    padding-top: 1.21429rem !important;
    padding-bottom: 1.21429rem !important;
  }
  .g-py-18--lg {
    padding-top: 1.28571rem !important;
    padding-bottom: 1.28571rem !important;
  }
  .g-py-19--lg {
    padding-top: 1.35714rem !important;
    padding-bottom: 1.35714rem !important;
  }
  /* P */
  .g-py-10--lg {
    padding-top: 0.71429rem !important;
    padding-bottom: 0.71429rem !important;
  }
  /* P */
  .g-py-15--lg {
    padding-top: 1.07143rem !important;
    padding-bottom: 1.07143rem !important;
  }
  /* P */
  .g-py-20--lg {
    padding-top: 1.42857rem !important;
    padding-bottom: 1.42857rem !important;
  }
  /* P */
  .g-py-25--lg {
    padding-top: 1.78571rem !important;
    padding-bottom: 1.78571rem !important;
  }
  /* P */
  .g-py-30--lg {
    padding-top: 2.14286rem !important;
    padding-bottom: 2.14286rem !important;
  }
  /* P */
  .g-py-35--lg {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  /* P */
  .g-py-40--lg {
    padding-top: 2.85714rem !important;
    padding-bottom: 2.85714rem !important;
  }
  /* P */
  .g-py-45--lg {
    padding-top: 3.21429rem !important;
    padding-bottom: 3.21429rem !important;
  }
  /* P */
  .g-py-50--lg {
    padding-top: 3.57143rem !important;
    padding-bottom: 3.57143rem !important;
  }
  /* P */
  .g-py-55--lg {
    padding-top: 3.92857rem !important;
    padding-bottom: 3.92857rem !important;
  }
  /* P */
  .g-py-60--lg {
    padding-top: 4.28571rem !important;
    padding-bottom: 4.28571rem !important;
  }
  /* P */
  .g-py-65--lg {
    padding-top: 4.64286rem !important;
    padding-bottom: 4.64286rem !important;
  }
  /* P */
  .g-py-70--lg {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  /* P */
  .g-py-75--lg {
    padding-top: 5.35714rem !important;
    padding-bottom: 5.35714rem !important;
  }
  /* P */
  .g-py-80--lg {
    padding-top: 5.71429rem !important;
    padding-bottom: 5.71429rem !important;
  }
  /* P */
  .g-py-85--lg {
    padding-top: 6.07143rem !important;
    padding-bottom: 6.07143rem !important;
  }
  /* P */
  .g-py-90--lg {
    padding-top: 6.42857rem !important;
    padding-bottom: 6.42857rem !important;
  }
  /* P */
  .g-py-95--lg {
    padding-top: 6.78571rem !important;
    padding-bottom: 6.78571rem !important;
  }
  /* P */
  .g-py-100--lg {
    padding-top: 7.14286rem !important;
    padding-bottom: 7.14286rem !important;
  }
  /* P */
  .g-py-105--lg {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }
  /* P */
  .g-py-110--lg {
    padding-top: 7.85714rem !important;
    padding-bottom: 7.85714rem !important;
  }
  /* P */
  .g-py-115--lg {
    padding-top: 8.21429rem !important;
    padding-bottom: 8.21429rem !important;
  }
  /* P */
  .g-py-120--lg {
    padding-top: 8.57143rem !important;
    padding-bottom: 8.57143rem !important;
  }
  /* P */
  .g-py-125--lg {
    padding-top: 8.92857rem !important;
    padding-bottom: 8.92857rem !important;
  }
  /* P */
  .g-py-130--lg {
    padding-top: 9.28571rem !important;
    padding-bottom: 9.28571rem !important;
  }
  /* P */
  .g-py-135--lg {
    padding-top: 9.64286rem !important;
    padding-bottom: 9.64286rem !important;
  }
  /* P */
  .g-py-140--lg {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  /* P */
  .g-py-145--lg {
    padding-top: 10.35714rem !important;
    padding-bottom: 10.35714rem !important;
  }
  /* P */
  .g-py-150--lg {
    padding-top: 10.71429rem !important;
    padding-bottom: 10.71429rem !important;
  }
  /* P */
  .g-py-155--lg {
    padding-top: 11.07143rem !important;
    padding-bottom: 11.07143rem !important;
  }
  /* P */
  .g-py-160--lg {
    padding-top: 11.42857rem !important;
    padding-bottom: 11.42857rem !important;
  }
  /* P */
  .g-py-165--lg {
    padding-top: 11.78571rem !important;
    padding-bottom: 11.78571rem !important;
  }
  /* P */
  .g-py-170--lg {
    padding-top: 12.14286rem !important;
    padding-bottom: 12.14286rem !important;
  }
  /* P */
  .g-py-175--lg {
    padding-top: 12.5rem !important;
    padding-bottom: 12.5rem !important;
  }
  /* P */
  .g-py-180--lg {
    padding-top: 12.85714rem !important;
    padding-bottom: 12.85714rem !important;
  }
  /* P */
  .g-py-185--lg {
    padding-top: 13.21429rem !important;
    padding-bottom: 13.21429rem !important;
  }
  /* P */
  .g-py-190--lg {
    padding-top: 13.57143rem !important;
    padding-bottom: 13.57143rem !important;
  }
  /* P */
  .g-py-195--lg {
    padding-top: 13.92857rem !important;
    padding-bottom: 13.92857rem !important;
  }
  /* P */
  .g-py-200--lg {
    padding-top: 14.28571rem !important;
    padding-bottom: 14.28571rem !important;
  }
  /* P */
  .g-py-205--lg {
    padding-top: 14.64286rem !important;
    padding-bottom: 14.64286rem !important;
  }
  /* P */
  .g-py-210--lg {
    padding-top: 15rem !important;
    padding-bottom: 15rem !important;
  }
  /* Padding Top */
  .g-pt-0--lg {
    padding-top: 0px !important;
  }
  .g-pt-1--lg {
    padding-top: 0.07143rem !important;
  }
  .g-pt-2--lg {
    padding-top: 0.14286rem !important;
  }
  .g-pt-3--lg {
    padding-top: 0.21429rem !important;
  }
  .g-pt-4--lg {
    padding-top: 0.28571rem !important;
  }
  .g-pt-5--lg {
    padding-top: 0.35714rem !important;
  }
  .g-pt-6--lg {
    padding-top: 0.42857rem !important;
  }
  .g-pt-7--lg {
    padding-top: 0.5rem !important;
  }
  .g-pt-8--lg {
    padding-top: 0.57143rem !important;
  }
  .g-pt-9--lg {
    padding-top: 0.64286rem !important;
  }
  .g-pt-10--lg {
    padding-top: 0.71429rem !important;
  }
  .g-pt-11--lg {
    padding-top: 0.78571rem !important;
  }
  .g-pt-12--lg {
    padding-top: 0.85714rem !important;
  }
  .g-pt-13--lg {
    padding-top: 0.92857rem !important;
  }
  .g-pt-14--lg {
    padding-top: 1rem !important;
  }
  .g-pt-15--lg {
    padding-top: 1.07143rem !important;
  }
  .g-pt-16--lg {
    padding-top: 1.14286rem !important;
  }
  .g-pt-17--lg {
    padding-top: 1.21429rem !important;
  }
  .g-pt-18--lg {
    padding-top: 1.28571rem !important;
  }
  .g-pt-19--lg {
    padding-top: 1.35714rem !important;
  }
  .g-pt-10--lg {
    padding-top: 0.71429rem !important;
  }
  .g-pt-15--lg {
    padding-top: 1.07143rem !important;
  }
  .g-pt-20--lg {
    padding-top: 1.42857rem !important;
  }
  .g-pt-25--lg {
    padding-top: 1.78571rem !important;
  }
  .g-pt-30--lg {
    padding-top: 2.14286rem !important;
  }
  .g-pt-35--lg {
    padding-top: 2.5rem !important;
  }
  .g-pt-40--lg {
    padding-top: 2.85714rem !important;
  }
  .g-pt-45--lg {
    padding-top: 3.21429rem !important;
  }
  .g-pt-50--lg {
    padding-top: 3.57143rem !important;
  }
  .g-pt-55--lg {
    padding-top: 3.92857rem !important;
  }
  .g-pt-60--lg {
    padding-top: 4.28571rem !important;
  }
  .g-pt-65--lg {
    padding-top: 4.64286rem !important;
  }
  .g-pt-70--lg {
    padding-top: 5rem !important;
  }
  .g-pt-75--lg {
    padding-top: 5.35714rem !important;
  }
  .g-pt-80--lg {
    padding-top: 5.71429rem !important;
  }
  .g-pt-85--lg {
    padding-top: 6.07143rem !important;
  }
  .g-pt-90--lg {
    padding-top: 6.42857rem !important;
  }
  .g-pt-95--lg {
    padding-top: 6.78571rem !important;
  }
  .g-pt-100--lg {
    padding-top: 7.14286rem !important;
  }
  .g-pt-105--lg {
    padding-top: 7.5rem !important;
  }
  .g-pt-110--lg {
    padding-top: 7.85714rem !important;
  }
  .g-pt-115--lg {
    padding-top: 8.21429rem !important;
  }
  .g-pt-120--lg {
    padding-top: 8.57143rem !important;
  }
  .g-pt-125--lg {
    padding-top: 8.92857rem !important;
  }
  .g-pt-130--lg {
    padding-top: 9.28571rem !important;
  }
  .g-pt-135--lg {
    padding-top: 9.64286rem !important;
  }
  .g-pt-140--lg {
    padding-top: 10rem !important;
  }
  .g-pt-145--lg {
    padding-top: 10.35714rem !important;
  }
  .g-pt-150--lg {
    padding-top: 10.71429rem !important;
  }
  /* Padding Right */
  .g-pr-0--lg {
    padding-right: 0px !important;
  }
  .g-pr-1--lg {
    padding-right: 0.07143rem !important;
  }
  .g-pr-2--lg {
    padding-right: 0.14286rem !important;
  }
  .g-pr-3--lg {
    padding-right: 0.21429rem !important;
  }
  .g-pr-4--lg {
    padding-right: 0.28571rem !important;
  }
  .g-pr-5--lg {
    padding-right: 0.35714rem !important;
  }
  .g-pr-6--lg {
    padding-right: 0.42857rem !important;
  }
  .g-pr-7--lg {
    padding-right: 0.5rem !important;
  }
  .g-pr-8--lg {
    padding-right: 0.57143rem !important;
  }
  .g-pr-9--lg {
    padding-right: 0.64286rem !important;
  }
  /* P */
  .g-pr-10--lg {
    padding-right: 0.71429rem !important;
  }
  /* P */
  .g-pr-15--lg {
    padding-right: 1.07143rem !important;
  }
  /* P */
  .g-pr-20--lg {
    padding-right: 1.42857rem !important;
  }
  /* P */
  .g-pr-25--lg {
    padding-right: 1.78571rem !important;
  }
  /* P */
  .g-pr-30--lg {
    padding-right: 2.14286rem !important;
  }
  /* P */
  .g-pr-35--lg {
    padding-right: 2.5rem !important;
  }
  /* P */
  .g-pr-40--lg {
    padding-right: 2.85714rem !important;
  }
  /* P */
  .g-pr-45--lg {
    padding-right: 3.21429rem !important;
  }
  /* P */
  .g-pr-50--lg {
    padding-right: 3.57143rem !important;
  }
  /* P */
  .g-pr-55--lg {
    padding-right: 3.92857rem !important;
  }
  /* P */
  .g-pr-60--lg {
    padding-right: 4.28571rem !important;
  }
  /* P */
  .g-pr-65--lg {
    padding-right: 4.64286rem !important;
  }
  /* P */
  .g-pr-70--lg {
    padding-right: 5rem !important;
  }
  /* P */
  .g-pr-75--lg {
    padding-right: 5.35714rem !important;
  }
  /* P */
  .g-pr-80--lg {
    padding-right: 5.71429rem !important;
  }
  /* P */
  .g-pr-85--lg {
    padding-right: 6.07143rem !important;
  }
  /* P */
  .g-pr-90--lg {
    padding-right: 6.42857rem !important;
  }
  /* P */
  .g-pr-95--lg {
    padding-right: 6.78571rem !important;
  }
  /* P */
  .g-pr-100--lg {
    padding-right: 7.14286rem !important;
  }
  /* P */
  .g-pr-105--lg {
    padding-right: 7.5rem !important;
  }
  /* P */
  .g-pr-110--lg {
    padding-right: 7.85714rem !important;
  }
  /* P */
  .g-pr-115--lg {
    padding-right: 8.21429rem !important;
  }
  /* P */
  .g-pr-120--lg {
    padding-right: 8.57143rem !important;
  }
  /* P */
  .g-pr-125--lg {
    padding-right: 8.92857rem !important;
  }
  /* P */
  .g-pr-130--lg {
    padding-right: 9.28571rem !important;
  }
  /* P */
  .g-pr-135--lg {
    padding-right: 9.64286rem !important;
  }
  /* P */
  .g-pr-140--lg {
    padding-right: 10rem !important;
  }
  /* P */
  .g-pr-145--lg {
    padding-right: 10.35714rem !important;
  }
  /* P */
  .g-pr-150--lg {
    padding-right: 10.71429rem !important;
  }
  /* P */
  .g-pr-155--lg {
    padding-right: 11.07143rem !important;
  }
  /* P */
  .g-pr-160--lg {
    padding-right: 11.42857rem !important;
  }
  /* P */
  .g-pr-165--lg {
    padding-right: 11.78571rem !important;
  }
  /* P */
  .g-pr-170--lg {
    padding-right: 12.14286rem !important;
  }
  /* Padding Bottom */
  .g-pb-0--lg {
    padding-bottom: 0px !important;
  }
  .g-pb-1--lg {
    padding-bottom: 0.07143rem !important;
  }
  .g-pb-2--lg {
    padding-bottom: 0.14286rem !important;
  }
  .g-pb-3--lg {
    padding-bottom: 0.21429rem !important;
  }
  .g-pb-4--lg {
    padding-bottom: 0.28571rem !important;
  }
  .g-pb-5--lg {
    padding-bottom: 0.35714rem !important;
  }
  .g-pb-6--lg {
    padding-bottom: 0.42857rem !important;
  }
  .g-pb-7--lg {
    padding-bottom: 0.5rem !important;
  }
  .g-pb-8--lg {
    padding-bottom: 0.57143rem !important;
  }
  .g-pb-9--lg {
    padding-bottom: 0.64286rem !important;
  }
  .g-pb-10--lg {
    padding-bottom: 0.71429rem !important;
  }
  .g-pb-15--lg {
    padding-bottom: 1.07143rem !important;
  }
  .g-pb-20--lg {
    padding-bottom: 1.42857rem !important;
  }
  .g-pb-25--lg {
    padding-bottom: 1.78571rem !important;
  }
  .g-pb-30--lg {
    padding-bottom: 2.14286rem !important;
  }
  .g-pb-35--lg {
    padding-bottom: 2.5rem !important;
  }
  .g-pb-40--lg {
    padding-bottom: 2.85714rem !important;
  }
  .g-pb-45--lg {
    padding-bottom: 3.21429rem !important;
  }
  .g-pb-50--lg {
    padding-bottom: 3.57143rem !important;
  }
  .g-pb-55--lg {
    padding-bottom: 3.92857rem !important;
  }
  .g-pb-60--lg {
    padding-bottom: 4.28571rem !important;
  }
  .g-pb-65--lg {
    padding-bottom: 4.64286rem !important;
  }
  .g-pb-70--lg {
    padding-bottom: 5rem !important;
  }
  .g-pb-75--lg {
    padding-bottom: 5.35714rem !important;
  }
  .g-pb-80--lg {
    padding-bottom: 5.71429rem !important;
  }
  .g-pb-85--lg {
    padding-bottom: 6.07143rem !important;
  }
  .g-pb-90--lg {
    padding-bottom: 6.42857rem !important;
  }
  .g-pb-95--lg {
    padding-bottom: 6.78571rem !important;
  }
  .g-pb-100--lg {
    padding-bottom: 7.14286rem !important;
  }
  .g-pb-105--lg {
    padding-bottom: 7.5rem !important;
  }
  .g-pb-110--lg {
    padding-bottom: 7.85714rem !important;
  }
  .g-pb-115--lg {
    padding-bottom: 8.21429rem !important;
  }
  .g-pb-120--lg {
    padding-bottom: 8.57143rem !important;
  }
  .g-pb-125--lg {
    padding-bottom: 8.92857rem !important;
  }
  .g-pb-130--lg {
    padding-bottom: 9.28571rem !important;
  }
  .g-pb-135--lg {
    padding-bottom: 9.64286rem !important;
  }
  .g-pb-140--lg {
    padding-bottom: 10rem !important;
  }
  .g-pb-145--lg {
    padding-bottom: 10.35714rem !important;
  }
  .g-pb-150--lg {
    padding-bottom: 10.71429rem !important;
  }
  /* Padding Left */
  .g-pl-0--lg {
    padding-left: 0px !important;
  }
  .g-pl-1--lg {
    padding-left: 0.07143rem !important;
  }
  .g-pl-2--lg {
    padding-left: 0.14286rem !important;
  }
  .g-pl-3--lg {
    padding-left: 0.21429rem !important;
  }
  .g-pl-4--lg {
    padding-left: 0.28571rem !important;
  }
  .g-pl-5--lg {
    padding-left: 0.35714rem !important;
  }
  .g-pl-6--lg {
    padding-left: 0.42857rem !important;
  }
  .g-pl-7--lg {
    padding-left: 0.5rem !important;
  }
  .g-pl-8--lg {
    padding-left: 0.57143rem !important;
  }
  .g-pl-9--lg {
    padding-left: 0.64286rem !important;
  }
  /* P */
  .g-pl-10--lg {
    padding-left: 0.71429rem !important;
  }
  /* P */
  .g-pl-15--lg {
    padding-left: 1.07143rem !important;
  }
  /* P */
  .g-pl-20--lg {
    padding-left: 1.42857rem !important;
  }
  /* P */
  .g-pl-25--lg {
    padding-left: 1.78571rem !important;
  }
  /* P */
  .g-pl-30--lg {
    padding-left: 2.14286rem !important;
  }
  /* P */
  .g-pl-35--lg {
    padding-left: 2.5rem !important;
  }
  /* P */
  .g-pl-40--lg {
    padding-left: 2.85714rem !important;
  }
  /* P */
  .g-pl-45--lg {
    padding-left: 3.21429rem !important;
  }
  /* P */
  .g-pl-50--lg {
    padding-left: 3.57143rem !important;
  }
  /* P */
  .g-pl-55--lg {
    padding-left: 3.92857rem !important;
  }
  /* P */
  .g-pl-60--lg {
    padding-left: 4.28571rem !important;
  }
  /* P */
  .g-pl-65--lg {
    padding-left: 4.64286rem !important;
  }
  /* P */
  .g-pl-70--lg {
    padding-left: 5rem !important;
  }
  /* P */
  .g-pl-75--lg {
    padding-left: 5.35714rem !important;
  }
  /* P */
  .g-pl-80--lg {
    padding-left: 5.71429rem !important;
  }
  /* P */
  .g-pl-85--lg {
    padding-left: 6.07143rem !important;
  }
  /* P */
  .g-pl-90--lg {
    padding-left: 6.42857rem !important;
  }
  /* P */
  .g-pl-95--lg {
    padding-left: 6.78571rem !important;
  }
  /* P */
  .g-pl-100--lg {
    padding-left: 7.14286rem !important;
  }
  /* P */
  .g-pl-105--lg {
    padding-left: 7.5rem !important;
  }
  /* P */
  .g-pl-110--lg {
    padding-left: 7.85714rem !important;
  }
  /* P */
  .g-pl-115--lg {
    padding-left: 8.21429rem !important;
  }
  /* P */
  .g-pl-120--lg {
    padding-left: 8.57143rem !important;
  }
  /* P */
  .g-pl-125--lg {
    padding-left: 8.92857rem !important;
  }
  /* P */
  .g-pl-130--lg {
    padding-left: 9.28571rem !important;
  }
  /* P */
  .g-pl-135--lg {
    padding-left: 9.64286rem !important;
  }
  /* P */
  .g-pl-140--lg {
    padding-left: 10rem !important;
  }
  /* P */
  .g-pl-145--lg {
    padding-left: 10.35714rem !important;
  }
  /* P */
  .g-pl-150--lg {
    padding-left: 10.71429rem !important;
  }
  /* P */
  .g-pl-155--lg {
    padding-left: 11.07143rem !important;
  }
  /* P */
  .g-pl-160--lg {
    padding-left: 11.42857rem !important;
  }
  /* P */
  .g-pl-165--lg {
    padding-left: 11.78571rem !important;
  }
  /* P */
  .g-pl-170--lg {
    padding-left: 12.14286rem !important;
  }
}

/* Padding Spaces (xl) P
------------------------------------*/
@media (min-width: 1200px) {
  .g-pa-0--xl {
    padding: 0 !important;
  }
  .g-px-0--xl {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .g-py-0--xl {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .g-pt-0--xl {
    padding-top: 0 !important;
  }
  .g-pr-0--xl {
    padding-right: 0 !important;
  }
  .g-pb-0--xl {
    padding-bottom: 0 !important;
  }
  .g-pl-0--xl {
    padding-left: 0 !important;
  }
  /* Padding Around */
  .g-pa-2--xl {
    padding: 0.14286rem !important;
  }
  .g-pa-3--xl {
    padding: 0.21429rem !important;
  }
  .g-pa-5--xl {
    padding: 0.35714rem !important;
  }
  .g-pa-7--xl {
    padding: 0.5rem !important;
  }
  .g-pa-10--xl {
    padding: 0.71429rem !important;
  }
  .g-pa-15--xl {
    padding: 1.07143rem !important;
  }
  .g-pa-20--xl {
    padding: 1.42857rem !important;
  }
  .g-pa-25--xl {
    padding: 1.78571rem !important;
  }
  .g-pa-30--xl {
    padding: 2.14286rem !important;
  }
  .g-pa-35--xl {
    padding: 2.5rem !important;
  }
  .g-pa-40--xl {
    padding: 2.85714rem !important;
  }
  .g-pa-45--xl {
    padding: 3.21429rem !important;
  }
  .g-pa-50--xl {
    padding: 3.57143rem !important;
  }
  .g-pa-55--xl {
    padding: 3.92857rem !important;
  }
  .g-pa-60--xl {
    padding: 4.28571rem !important;
  }
  .g-pa-65--xl {
    padding: 4.64286rem !important;
  }
  .g-pa-70--xl {
    padding: 5rem !important;
  }
  .g-pa-75--xl {
    padding: 5.35714rem !important;
  }
  .g-pa-80--xl {
    padding: 5.71429rem !important;
  }
  .g-pa-85--xl {
    padding: 6.07143rem !important;
  }
  .g-pa-90--xl {
    padding: 6.42857rem !important;
  }
  .g-pa-95--xl {
    padding: 6.78571rem !important;
  }
  .g-pa-100--xl {
    padding: 7.14286rem !important;
  }
  .g-pa-105--xl {
    padding: 7.5rem !important;
  }
  .g-pa-110--xl {
    padding: 7.85714rem !important;
  }
  .g-pa-115--xl {
    padding: 8.21429rem !important;
  }
  .g-pa-120--xl {
    padding: 8.57143rem !important;
  }
  .g-pa-125--xl {
    padding: 8.92857rem !important;
  }
  .g-pa-130--xl {
    padding: 9.28571rem !important;
  }
  .g-pa-135--xl {
    padding: 9.64286rem !important;
  }
  .g-pa-140--xl {
    padding: 10rem !important;
  }
  .g-pa-145--xl {
    padding: 10.35714rem !important;
  }
  .g-pa-150--xl {
    padding: 10.71429rem !important;
  }
  /* Padding X */
  .g-px-1--xl {
    padding-left: 0.07143rem !important;
    padding-right: 0.07143rem !important;
  }
  .g-px-2--xl {
    padding-left: 0.14286rem !important;
    padding-right: 0.14286rem !important;
  }
  .g-px-3--xl {
    padding-left: 0.21429rem !important;
    padding-right: 0.21429rem !important;
  }
  .g-px-4--xl {
    padding-left: 0.28571rem !important;
    padding-right: 0.28571rem !important;
  }
  .g-px-5--xl {
    padding-left: 0.35714rem !important;
    padding-right: 0.35714rem !important;
  }
  .g-px-6--xl {
    padding-left: 0.42857rem !important;
    padding-right: 0.42857rem !important;
  }
  .g-px-7--xl {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
  .g-px-8--xl {
    padding-left: 0.57143rem !important;
    padding-right: 0.57143rem !important;
  }
  .g-px-9--xl {
    padding-left: 0.64286rem !important;
    padding-right: 0.64286rem !important;
  }
  .g-px-10--xl {
    padding-left: 0.71429rem !important;
    padding-right: 0.71429rem !important;
  }
  .g-px-11--xl {
    padding-left: 0.78571rem !important;
    padding-right: 0.78571rem !important;
  }
  .g-px-12--xl {
    padding-left: 0.85714rem !important;
    padding-right: 0.85714rem !important;
  }
  .g-px-13--xl {
    padding-left: 0.92857rem !important;
    padding-right: 0.92857rem !important;
  }
  .g-px-14--xl {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .g-px-15--xl {
    padding-left: 1.07143rem !important;
    padding-right: 1.07143rem !important;
  }
  .g-px-16--xl {
    padding-left: 1.14286rem !important;
    padding-right: 1.14286rem !important;
  }
  .g-px-17--xl {
    padding-left: 1.21429rem !important;
    padding-right: 1.21429rem !important;
  }
  .g-px-18--xl {
    padding-left: 1.28571rem !important;
    padding-right: 1.28571rem !important;
  }
  .g-px-19--xl {
    padding-left: 1.35714rem !important;
    padding-right: 1.35714rem !important;
  }
  .g-px-10--xl {
    padding-left: 0.71429rem !important;
    padding-right: 0.71429rem !important;
  }
  .g-px-15--xl {
    padding-left: 1.07143rem !important;
    padding-right: 1.07143rem !important;
  }
  .g-px-20--xl {
    padding-left: 1.42857rem !important;
    padding-right: 1.42857rem !important;
  }
  .g-px-25--xl {
    padding-left: 1.78571rem !important;
    padding-right: 1.78571rem !important;
  }
  .g-px-30--xl {
    padding-left: 2.14286rem !important;
    padding-right: 2.14286rem !important;
  }
  .g-px-35--xl {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }
  .g-px-40--xl {
    padding-left: 2.85714rem !important;
    padding-right: 2.85714rem !important;
  }
  .g-px-45--xl {
    padding-left: 3.21429rem !important;
    padding-right: 3.21429rem !important;
  }
  .g-px-50--xl {
    padding-left: 3.57143rem !important;
    padding-right: 3.57143rem !important;
  }
  .g-px-55--xl {
    padding-left: 3.92857rem !important;
    padding-right: 3.92857rem !important;
  }
  .g-px-60--xl {
    padding-left: 4.28571rem !important;
    padding-right: 4.28571rem !important;
  }
  .g-px-65--xl {
    padding-left: 4.64286rem !important;
    padding-right: 4.64286rem !important;
  }
  .g-px-70--xl {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }
  .g-px-75--xl {
    padding-left: 5.35714rem !important;
    padding-right: 5.35714rem !important;
  }
  .g-px-80--xl {
    padding-left: 5.71429rem !important;
    padding-right: 5.71429rem !important;
  }
  .g-px-85--xl {
    padding-left: 6.07143rem !important;
    padding-right: 6.07143rem !important;
  }
  .g-px-90--xl {
    padding-left: 6.42857rem !important;
    padding-right: 6.42857rem !important;
  }
  .g-px-95--xl {
    padding-left: 6.78571rem !important;
    padding-right: 6.78571rem !important;
  }
  .g-px-100--xl {
    padding-left: 7.14286rem !important;
    padding-right: 7.14286rem !important;
  }
  .g-px-105--xl {
    padding-left: 7.5rem !important;
    padding-right: 7.5rem !important;
  }
  .g-px-110--xl {
    padding-left: 7.85714rem !important;
    padding-right: 7.85714rem !important;
  }
  .g-px-115--xl {
    padding-left: 8.21429rem !important;
    padding-right: 8.21429rem !important;
  }
  .g-px-120--xl {
    padding-left: 8.57143rem !important;
    padding-right: 8.57143rem !important;
  }
  .g-px-125--xl {
    padding-left: 8.92857rem !important;
    padding-right: 8.92857rem !important;
  }
  .g-px-130--xl {
    padding-left: 9.28571rem !important;
    padding-right: 9.28571rem !important;
  }
  .g-px-135--xl {
    padding-left: 9.64286rem !important;
    padding-right: 9.64286rem !important;
  }
  .g-px-140--xl {
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }
  .g-px-145--xl {
    padding-left: 10.35714rem !important;
    padding-right: 10.35714rem !important;
  }
  .g-px-150--xl {
    padding-left: 10.71429rem !important;
    padding-right: 10.71429rem !important;
  }
  /* Padding Y */
  .g-py-1--xl {
    padding-top: 0.07143rem !important;
    padding-bottom: 0.07143rem !important;
  }
  .g-py-2--xl {
    padding-top: 0.14286rem !important;
    padding-bottom: 0.14286rem !important;
  }
  .g-py-3--xl {
    padding-top: 0.21429rem !important;
    padding-bottom: 0.21429rem !important;
  }
  .g-py-4--xl {
    padding-top: 0.28571rem !important;
    padding-bottom: 0.28571rem !important;
  }
  .g-py-5--xl {
    padding-top: 0.35714rem !important;
    padding-bottom: 0.35714rem !important;
  }
  .g-py-6--xl {
    padding-top: 0.42857rem !important;
    padding-bottom: 0.42857rem !important;
  }
  .g-py-7--xl {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .g-py-8--xl {
    padding-top: 0.57143rem !important;
    padding-bottom: 0.57143rem !important;
  }
  .g-py-9--xl {
    padding-top: 0.64286rem !important;
    padding-bottom: 0.64286rem !important;
  }
  .g-py-10--xl {
    padding-top: 0.71429rem !important;
    padding-bottom: 0.71429rem !important;
  }
  .g-py-11--xl {
    padding-top: 0.78571rem !important;
    padding-bottom: 0.78571rem !important;
  }
  .g-py-12--xl {
    padding-top: 0.85714rem !important;
    padding-bottom: 0.85714rem !important;
  }
  .g-py-13--xl {
    padding-top: 0.92857rem !important;
    padding-bottom: 0.92857rem !important;
  }
  .g-py-14--xl {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .g-py-15--xl {
    padding-top: 1.07143rem !important;
    padding-bottom: 1.07143rem !important;
  }
  .g-py-16--xl {
    padding-top: 1.14286rem !important;
    padding-bottom: 1.14286rem !important;
  }
  .g-py-17--xl {
    padding-top: 1.21429rem !important;
    padding-bottom: 1.21429rem !important;
  }
  .g-py-18--xl {
    padding-top: 1.28571rem !important;
    padding-bottom: 1.28571rem !important;
  }
  .g-py-19--xl {
    padding-top: 1.35714rem !important;
    padding-bottom: 1.35714rem !important;
  }
  /* P */
  .g-py-10--xl {
    padding-top: 0.71429rem !important;
    padding-bottom: 0.71429rem !important;
  }
  /* P */
  .g-py-15--xl {
    padding-top: 1.07143rem !important;
    padding-bottom: 1.07143rem !important;
  }
  /* P */
  .g-py-20--xl {
    padding-top: 1.42857rem !important;
    padding-bottom: 1.42857rem !important;
  }
  /* P */
  .g-py-25--xl {
    padding-top: 1.78571rem !important;
    padding-bottom: 1.78571rem !important;
  }
  /* P */
  .g-py-30--xl {
    padding-top: 2.14286rem !important;
    padding-bottom: 2.14286rem !important;
  }
  /* P */
  .g-py-35--xl {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  /* P */
  .g-py-40--xl {
    padding-top: 2.85714rem !important;
    padding-bottom: 2.85714rem !important;
  }
  /* P */
  .g-py-45--xl {
    padding-top: 3.21429rem !important;
    padding-bottom: 3.21429rem !important;
  }
  /* P */
  .g-py-50--xl {
    padding-top: 3.57143rem !important;
    padding-bottom: 3.57143rem !important;
  }
  /* P */
  .g-py-55--xl {
    padding-top: 3.92857rem !important;
    padding-bottom: 3.92857rem !important;
  }
  /* P */
  .g-py-60--xl {
    padding-top: 4.28571rem !important;
    padding-bottom: 4.28571rem !important;
  }
  /* P */
  .g-py-65--xl {
    padding-top: 4.64286rem !important;
    padding-bottom: 4.64286rem !important;
  }
  /* P */
  .g-py-70--xl {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  /* P */
  .g-py-75--xl {
    padding-top: 5.35714rem !important;
    padding-bottom: 5.35714rem !important;
  }
  /* P */
  .g-py-80--xl {
    padding-top: 5.71429rem !important;
    padding-bottom: 5.71429rem !important;
  }
  /* P */
  .g-py-85--xl {
    padding-top: 6.07143rem !important;
    padding-bottom: 6.07143rem !important;
  }
  /* P */
  .g-py-90--xl {
    padding-top: 6.42857rem !important;
    padding-bottom: 6.42857rem !important;
  }
  /* P */
  .g-py-95--xl {
    padding-top: 6.78571rem !important;
    padding-bottom: 6.78571rem !important;
  }
  /* P */
  .g-py-100--xl {
    padding-top: 7.14286rem !important;
    padding-bottom: 7.14286rem !important;
  }
  /* P */
  .g-py-105--xl {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }
  /* P */
  .g-py-110--xl {
    padding-top: 7.85714rem !important;
    padding-bottom: 7.85714rem !important;
  }
  /* P */
  .g-py-115--xl {
    padding-top: 8.21429rem !important;
    padding-bottom: 8.21429rem !important;
  }
  /* P */
  .g-py-120--xl {
    padding-top: 8.57143rem !important;
    padding-bottom: 8.57143rem !important;
  }
  /* P */
  .g-py-125--xl {
    padding-top: 8.92857rem !important;
    padding-bottom: 8.92857rem !important;
  }
  /* P */
  .g-py-130--xl {
    padding-top: 9.28571rem !important;
    padding-bottom: 9.28571rem !important;
  }
  /* P */
  .g-py-135--xl {
    padding-top: 9.64286rem !important;
    padding-bottom: 9.64286rem !important;
  }
  /* P */
  .g-py-140--xl {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  /* P */
  .g-py-145--xl {
    padding-top: 10.35714rem !important;
    padding-bottom: 10.35714rem !important;
  }
  /* P */
  .g-py-150--xl {
    padding-top: 10.71429rem !important;
    padding-bottom: 10.71429rem !important;
  }
  /* P */
  .g-py-155--xl {
    padding-top: 11.07143rem !important;
    padding-bottom: 11.07143rem !important;
  }
  /* P */
  .g-py-160--xl {
    padding-top: 11.42857rem !important;
    padding-bottom: 11.42857rem !important;
  }
  /* P */
  .g-py-165--xl {
    padding-top: 11.78571rem !important;
    padding-bottom: 11.78571rem !important;
  }
  /* P */
  .g-py-170--xl {
    padding-top: 12.14286rem !important;
    padding-bottom: 12.14286rem !important;
  }
  /* P */
  .g-py-175--xl {
    padding-top: 12.5rem !important;
    padding-bottom: 12.5rem !important;
  }
  /* P */
  .g-py-180--xl {
    padding-top: 12.85714rem !important;
    padding-bottom: 12.85714rem !important;
  }
  /* P */
  .g-py-185--xl {
    padding-top: 13.21429rem !important;
    padding-bottom: 13.21429rem !important;
  }
  /* P */
  .g-py-190--xl {
    padding-top: 13.57143rem !important;
    padding-bottom: 13.57143rem !important;
  }
  /* P */
  .g-py-195--xl {
    padding-top: 13.92857rem !important;
    padding-bottom: 13.92857rem !important;
  }
  /* P */
  .g-py-200--xl {
    padding-top: 14.28571rem !important;
    padding-bottom: 14.28571rem !important;
  }
  /* P */
  .g-py-205--xl {
    padding-top: 14.64286rem !important;
    padding-bottom: 14.64286rem !important;
  }
  /* P */
  .g-py-210--xl {
    padding-top: 15rem !important;
    padding-bottom: 15rem !important;
  }
  /* Padding Top */
  .g-pt-0--xl {
    padding-top: 0px !important;
  }
  .g-pt-1--xl {
    padding-top: 0.07143rem !important;
  }
  .g-pt-2--xl {
    padding-top: 0.14286rem !important;
  }
  .g-pt-3--xl {
    padding-top: 0.21429rem !important;
  }
  .g-pt-4--xl {
    padding-top: 0.28571rem !important;
  }
  .g-pt-5--xl {
    padding-top: 0.35714rem !important;
  }
  .g-pt-6--xl {
    padding-top: 0.42857rem !important;
  }
  .g-pt-7--xl {
    padding-top: 0.5rem !important;
  }
  .g-pt-8--xl {
    padding-top: 0.57143rem !important;
  }
  .g-pt-9--xl {
    padding-top: 0.64286rem !important;
  }
  .g-pt-10--xl {
    padding-top: 0.71429rem !important;
  }
  .g-pt-11--xl {
    padding-top: 0.78571rem !important;
  }
  .g-pt-12--xl {
    padding-top: 0.85714rem !important;
  }
  .g-pt-13--xl {
    padding-top: 0.92857rem !important;
  }
  .g-pt-14--xl {
    padding-top: 1rem !important;
  }
  .g-pt-15--xl {
    padding-top: 1.07143rem !important;
  }
  .g-pt-16--xl {
    padding-top: 1.14286rem !important;
  }
  .g-pt-17--xl {
    padding-top: 1.21429rem !important;
  }
  .g-pt-18--xl {
    padding-top: 1.28571rem !important;
  }
  .g-pt-19--xl {
    padding-top: 1.35714rem !important;
  }
  .g-pt-10--xl {
    padding-top: 0.71429rem !important;
  }
  .g-pt-15--xl {
    padding-top: 1.07143rem !important;
  }
  .g-pt-20--xl {
    padding-top: 1.42857rem !important;
  }
  .g-pt-25--xl {
    padding-top: 1.78571rem !important;
  }
  .g-pt-30--xl {
    padding-top: 2.14286rem !important;
  }
  .g-pt-35--xl {
    padding-top: 2.5rem !important;
  }
  .g-pt-40--xl {
    padding-top: 2.85714rem !important;
  }
  .g-pt-45--xl {
    padding-top: 3.21429rem !important;
  }
  .g-pt-50--xl {
    padding-top: 3.57143rem !important;
  }
  .g-pt-55--xl {
    padding-top: 3.92857rem !important;
  }
  .g-pt-60--xl {
    padding-top: 4.28571rem !important;
  }
  .g-pt-65--xl {
    padding-top: 4.64286rem !important;
  }
  .g-pt-70--xl {
    padding-top: 5rem !important;
  }
  .g-pt-75--xl {
    padding-top: 5.35714rem !important;
  }
  .g-pt-80--xl {
    padding-top: 5.71429rem !important;
  }
  .g-pt-85--xl {
    padding-top: 6.07143rem !important;
  }
  .g-pt-90--xl {
    padding-top: 6.42857rem !important;
  }
  .g-pt-95--xl {
    padding-top: 6.78571rem !important;
  }
  .g-pt-100--xl {
    padding-top: 7.14286rem !important;
  }
  .g-pt-105--xl {
    padding-top: 7.5rem !important;
  }
  .g-pt-110--xl {
    padding-top: 7.85714rem !important;
  }
  .g-pt-115--xl {
    padding-top: 8.21429rem !important;
  }
  .g-pt-120--xl {
    padding-top: 8.57143rem !important;
  }
  .g-pt-125--xl {
    padding-top: 8.92857rem !important;
  }
  .g-pt-130--xl {
    padding-top: 9.28571rem !important;
  }
  .g-pt-135--xl {
    padding-top: 9.64286rem !important;
  }
  .g-pt-140--xl {
    padding-top: 10rem !important;
  }
  .g-pt-145--xl {
    padding-top: 10.35714rem !important;
  }
  .g-pt-150--xl {
    padding-top: 10.71429rem !important;
  }
  /* Padding Right */
  .g-pr-0--xl {
    padding-right: 0px !important;
  }
  .g-pr-1--xl {
    padding-right: 0.07143rem !important;
  }
  .g-pr-2--xl {
    padding-right: 0.14286rem !important;
  }
  .g-pr-3--xl {
    padding-right: 0.21429rem !important;
  }
  .g-pr-4--xl {
    padding-right: 0.28571rem !important;
  }
  .g-pr-5--xl {
    padding-right: 0.35714rem !important;
  }
  .g-pr-6--xl {
    padding-right: 0.42857rem !important;
  }
  .g-pr-7--xl {
    padding-right: 0.5rem !important;
  }
  .g-pr-8--xl {
    padding-right: 0.57143rem !important;
  }
  .g-pr-9--xl {
    padding-right: 0.64286rem !important;
  }
  /* P */
  .g-pr-10--xl {
    padding-right: 0.71429rem !important;
  }
  /* P */
  .g-pr-15--xl {
    padding-right: 1.07143rem !important;
  }
  /* P */
  .g-pr-20--xl {
    padding-right: 1.42857rem !important;
  }
  /* P */
  .g-pr-25--xl {
    padding-right: 1.78571rem !important;
  }
  /* P */
  .g-pr-30--xl {
    padding-right: 2.14286rem !important;
  }
  /* P */
  .g-pr-35--xl {
    padding-right: 2.5rem !important;
  }
  /* P */
  .g-pr-40--xl {
    padding-right: 2.85714rem !important;
  }
  /* P */
  .g-pr-45--xl {
    padding-right: 3.21429rem !important;
  }
  /* P */
  .g-pr-50--xl {
    padding-right: 3.57143rem !important;
  }
  /* P */
  .g-pr-55--xl {
    padding-right: 3.92857rem !important;
  }
  /* P */
  .g-pr-60--xl {
    padding-right: 4.28571rem !important;
  }
  /* P */
  .g-pr-65--xl {
    padding-right: 4.64286rem !important;
  }
  /* P */
  .g-pr-70--xl {
    padding-right: 5rem !important;
  }
  /* P */
  .g-pr-75--xl {
    padding-right: 5.35714rem !important;
  }
  /* P */
  .g-pr-80--xl {
    padding-right: 5.71429rem !important;
  }
  /* P */
  .g-pr-85--xl {
    padding-right: 6.07143rem !important;
  }
  /* P */
  .g-pr-90--xl {
    padding-right: 6.42857rem !important;
  }
  /* P */
  .g-pr-95--xl {
    padding-right: 6.78571rem !important;
  }
  /* P */
  .g-pr-100--xl {
    padding-right: 7.14286rem !important;
  }
  /* P */
  .g-pr-105--xl {
    padding-right: 7.5rem !important;
  }
  /* P */
  .g-pr-110--xl {
    padding-right: 7.85714rem !important;
  }
  /* P */
  .g-pr-115--xl {
    padding-right: 8.21429rem !important;
  }
  /* P */
  .g-pr-120--xl {
    padding-right: 8.57143rem !important;
  }
  /* P */
  .g-pr-125--xl {
    padding-right: 8.92857rem !important;
  }
  /* P */
  .g-pr-130--xl {
    padding-right: 9.28571rem !important;
  }
  /* P */
  .g-pr-135--xl {
    padding-right: 9.64286rem !important;
  }
  /* P */
  .g-pr-140--xl {
    padding-right: 10rem !important;
  }
  /* P */
  .g-pr-145--xl {
    padding-right: 10.35714rem !important;
  }
  /* P */
  .g-pr-150--xl {
    padding-right: 10.71429rem !important;
  }
  /* P */
  .g-pr-155--xl {
    padding-right: 11.07143rem !important;
  }
  /* P */
  .g-pr-160--xl {
    padding-right: 11.42857rem !important;
  }
  /* P */
  .g-pr-165--xl {
    padding-right: 11.78571rem !important;
  }
  /* P */
  .g-pr-170--xl {
    padding-right: 12.14286rem !important;
  }
  /* Padding Bottom */
  .g-pb-0--xl {
    padding-bottom: 0px !important;
  }
  .g-pb-1--xl {
    padding-bottom: 0.07143rem !important;
  }
  .g-pb-2--xl {
    padding-bottom: 0.14286rem !important;
  }
  .g-pb-3--xl {
    padding-bottom: 0.21429rem !important;
  }
  .g-pb-4--xl {
    padding-bottom: 0.28571rem !important;
  }
  .g-pb-5--xl {
    padding-bottom: 0.35714rem !important;
  }
  .g-pb-6--xl {
    padding-bottom: 0.42857rem !important;
  }
  .g-pb-7--xl {
    padding-bottom: 0.5rem !important;
  }
  .g-pb-8--xl {
    padding-bottom: 0.57143rem !important;
  }
  .g-pb-9--xl {
    padding-bottom: 0.64286rem !important;
  }
  .g-pb-10--xl {
    padding-bottom: 0.71429rem !important;
  }
  .g-pb-15--xl {
    padding-bottom: 1.07143rem !important;
  }
  .g-pb-20--xl {
    padding-bottom: 1.42857rem !important;
  }
  .g-pb-25--xl {
    padding-bottom: 1.78571rem !important;
  }
  .g-pb-30--xl {
    padding-bottom: 2.14286rem !important;
  }
  .g-pb-35--xl {
    padding-bottom: 2.5rem !important;
  }
  .g-pb-40--xl {
    padding-bottom: 2.85714rem !important;
  }
  .g-pb-45--xl {
    padding-bottom: 3.21429rem !important;
  }
  .g-pb-50--xl {
    padding-bottom: 3.57143rem !important;
  }
  .g-pb-55--xl {
    padding-bottom: 3.92857rem !important;
  }
  .g-pb-60--xl {
    padding-bottom: 4.28571rem !important;
  }
  .g-pb-65--xl {
    padding-bottom: 4.64286rem !important;
  }
  .g-pb-70--xl {
    padding-bottom: 5rem !important;
  }
  .g-pb-75--xl {
    padding-bottom: 5.35714rem !important;
  }
  .g-pb-80--xl {
    padding-bottom: 5.71429rem !important;
  }
  .g-pb-85--xl {
    padding-bottom: 6.07143rem !important;
  }
  .g-pb-90--xl {
    padding-bottom: 6.42857rem !important;
  }
  .g-pb-95--xl {
    padding-bottom: 6.78571rem !important;
  }
  .g-pb-100--xl {
    padding-bottom: 7.14286rem !important;
  }
  .g-pb-105--xl {
    padding-bottom: 7.5rem !important;
  }
  .g-pb-110--xl {
    padding-bottom: 7.85714rem !important;
  }
  .g-pb-115--xl {
    padding-bottom: 8.21429rem !important;
  }
  .g-pb-120--xl {
    padding-bottom: 8.57143rem !important;
  }
  .g-pb-125--xl {
    padding-bottom: 8.92857rem !important;
  }
  .g-pb-130--xl {
    padding-bottom: 9.28571rem !important;
  }
  .g-pb-135--xl {
    padding-bottom: 9.64286rem !important;
  }
  .g-pb-140--xl {
    padding-bottom: 10rem !important;
  }
  .g-pb-145--xl {
    padding-bottom: 10.35714rem !important;
  }
  .g-pb-150--xl {
    padding-bottom: 10.71429rem !important;
  }
  /* Padding Left */
  .g-pl-0--xl {
    padding-left: 0px !important;
  }
  .g-pl-1--xl {
    padding-left: 0.07143rem !important;
  }
  .g-pl-2--xl {
    padding-left: 0.14286rem !important;
  }
  .g-pl-3--xl {
    padding-left: 0.21429rem !important;
  }
  .g-pl-4--xl {
    padding-left: 0.28571rem !important;
  }
  .g-pl-5--xl {
    padding-left: 0.35714rem !important;
  }
  .g-pl-6--xl {
    padding-left: 0.42857rem !important;
  }
  .g-pl-7--xl {
    padding-left: 0.5rem !important;
  }
  .g-pl-8--xl {
    padding-left: 0.57143rem !important;
  }
  .g-pl-9--xl {
    padding-left: 0.64286rem !important;
  }
  /* P */
  .g-pl-10--xl {
    padding-left: 0.71429rem !important;
  }
  /* P */
  .g-pl-15--xl {
    padding-left: 1.07143rem !important;
  }
  /* P */
  .g-pl-20--xl {
    padding-left: 1.42857rem !important;
  }
  /* P */
  .g-pl-25--xl {
    padding-left: 1.78571rem !important;
  }
  /* P */
  .g-pl-30--xl {
    padding-left: 2.14286rem !important;
  }
  /* P */
  .g-pl-35--xl {
    padding-left: 2.5rem !important;
  }
  /* P */
  .g-pl-40--xl {
    padding-left: 2.85714rem !important;
  }
  /* P */
  .g-pl-45--xl {
    padding-left: 3.21429rem !important;
  }
  /* P */
  .g-pl-50--xl {
    padding-left: 3.57143rem !important;
  }
  /* P */
  .g-pl-55--xl {
    padding-left: 3.92857rem !important;
  }
  /* P */
  .g-pl-60--xl {
    padding-left: 4.28571rem !important;
  }
  /* P */
  .g-pl-65--xl {
    padding-left: 4.64286rem !important;
  }
  /* P */
  .g-pl-70--xl {
    padding-left: 5rem !important;
  }
  /* P */
  .g-pl-75--xl {
    padding-left: 5.35714rem !important;
  }
  /* P */
  .g-pl-80--xl {
    padding-left: 5.71429rem !important;
  }
  /* P */
  .g-pl-85--xl {
    padding-left: 6.07143rem !important;
  }
  /* P */
  .g-pl-90--xl {
    padding-left: 6.42857rem !important;
  }
  /* P */
  .g-pl-95--xl {
    padding-left: 6.78571rem !important;
  }
  /* P */
  .g-pl-100--xl {
    padding-left: 7.14286rem !important;
  }
  /* P */
  .g-pl-105--xl {
    padding-left: 7.5rem !important;
  }
  /* P */
  .g-pl-110--xl {
    padding-left: 7.85714rem !important;
  }
  /* P */
  .g-pl-115--xl {
    padding-left: 8.21429rem !important;
  }
  /* P */
  .g-pl-120--xl {
    padding-left: 8.57143rem !important;
  }
  /* P */
  .g-pl-125--xl {
    padding-left: 8.92857rem !important;
  }
  /* P */
  .g-pl-130--xl {
    padding-left: 9.28571rem !important;
  }
  /* P */
  .g-pl-135--xl {
    padding-left: 9.64286rem !important;
  }
  /* P */
  .g-pl-140--xl {
    padding-left: 10rem !important;
  }
  /* P */
  .g-pl-145--xl {
    padding-left: 10.35714rem !important;
  }
  /* P */
  .g-pl-150--xl {
    padding-left: 10.71429rem !important;
  }
  /* P */
  .g-pl-155--xl {
    padding-left: 11.07143rem !important;
  }
  /* P */
  .g-pl-160--xl {
    padding-left: 11.42857rem !important;
  }
  /* P */
  .g-pl-165--xl {
    padding-left: 11.78571rem !important;
  }
  /* P */
  .g-pl-170--xl {
    padding-left: 12.14286rem !important;
  }
}

/* Custom Paddings (xs)
------------------------------------*/
.g-pa-0-20-20 {
  padding: 0 1.42857rem 1.42857rem !important;
}

.g-pa-4-11 {
  padding: 0.28571rem 0.78571rem !important;
}

.g-pa-4-13 {
  padding: 0.28571rem 0.92857rem !important;
}

.g-pa-5-10 {
  padding: 0.35714rem 0.71429rem !important;
}

.g-pa-5-15 {
  padding: 0.35714rem 1.07143rem !important;
}

.g-pa-6-8 {
  padding: 0.42857rem 0.57143rem !important;
}

.g-pa-7-14 {
  padding: 0.5rem 1rem !important;
}

.g-pa-7-16 {
  padding: 0.5rem 1.14286rem !important;
}

.g-pa-10-5-5 {
  padding: 0.71429rem 0.35714rem 0.35714rem !important;
}

.g-pa-10-15 {
  padding: 0.71429rem 1.07143rem !important;
}

.g-pa-10-16 {
  padding: 0.71429rem 1.14286rem !important;
}

.g-pa-10-20 {
  padding: 0.71429rem 1.42857rem !important;
}

.g-pa-10-30 {
  padding: 0.71429rem 2.14286rem !important;
}

.g-pa-12 {
  padding: 0.85714rem !important;
}

.g-pa-12-19 {
  padding: 0.85714rem 1.35714rem !important;
}

.g-pa-12-21 {
  padding: 0.85714rem 1.5rem !important;
}

.g-pa-12-5-7 {
  padding: 0.85714rem 0.35714rem 0.5rem !important;
}

.g-pa-14-18 {
  padding: 1rem 1.28571rem !important;
}

.g-pa-15-0 {
  padding: 1.07143rem 0 !important;
}

.g-pa-15-5 {
  padding: 1.07143rem 0.35714rem !important;
}

.g-pa-15-20-10 {
  padding: 1.07143rem 1.42857rem 0.71429rem !important;
}

.g-pa-15-20 {
  padding: 1.07143rem 1.42857rem !important;
}

.g-pa-15-30 {
  padding: 1.07143rem 2.14286rem !important;
}

.g-pa-17-23 {
  padding: 1.21429rem 1.64286rem !important;
}

.g-pa-20-10 {
  padding: 1.42857rem 0.71429rem !important;
}

.g-pa-20-20-15 {
  padding: 1.42857rem 1.42857rem 1.07143rem !important;
}

.g-pa-20-20-20-55 {
  padding: 1.42857rem 1.42857rem 1.42857rem 3.92857rem !important;
}

.g-pa-20-30-0 {
  padding: 1.42857rem 2.14286rem 0 !important;
}

.g-pa-20-40-25 {
  padding: 1.42857rem 2.85714rem 1.78571rem !important;
}

.g-pa-24 {
  padding: 1.71429rem !important;
}

.g-pa-25-20-30 {
  padding: 1.78571rem 1.42857rem 2.14286rem !important;
}

.g-pa-25-30-20 {
  padding: 1.78571rem 2.14286rem 1.42857rem !important;
}

.g-pa-25-30 {
  padding: 1.78571rem 2.14286rem !important;
}

.g-pa-30-10 {
  padding: 2.14286rem 0.71429rem !important;
}

.g-pa-30-15 {
  padding: 2.14286rem 1.07143rem !important;
}

.g-pa-30-20 {
  padding: 2.14286rem 1.42857rem !important;
}

.g-pa-30-30-20 {
  padding: 2.14286rem 2.14286rem 1.42857rem !important;
}

.g-pa-40 {
  padding: 2.85714rem !important;
}

.g-pa-40-20 {
  padding: 2.85714rem 1.42857rem !important;
}

.g-pa-40-30-30 {
  padding: 2.85714rem 2.14286rem 2.14286rem !important;
}

.g-pa-50-15-0 {
  padding: 3.57143rem 1.07143rem 0 !important;
}

.g-pa-15x {
  padding: 15% !important;
}

.g-px-17 {
  padding-left: 1.21429rem !important;
  padding-right: 1.21429rem !important;
}

.g-px-18 {
  padding-left: 1.28571rem !important;
  /* O */
  padding-right: 1.28571rem !important;
}

.g-py-23 {
  padding-top: 1.64286rem !important;
  /* O */
  padding-bottom: 1.64286rem !important;
}

.g-py-235 {
  padding-top: 16.78571rem !important;
  /* O */
  padding-bottom: 16.78571rem !important;
}

.g-pl-12 {
  padding-left: 0.85714rem !important;
}

.g-pl-17 {
  padding-left: 1.21429rem !important;
}

.g-pl-7--hover:hover {
  padding-left: 0.5rem !important;
}

.g-pl-25--hover:hover {
  padding-left: 1.78571rem !important;
}

.active.g-pl-25--active {
  padding-left: 1.78571rem !important;
}

.g-pl-60--xs {
  padding-left: 4.28571rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.g-pt-11 {
  padding-top: 0.78571rem !important;
}

.g-pt-12 {
  padding-top: 0.85714rem !important;
}

.g-parent:hover .g-pt-25--parent-hover {
  padding-top: 1.78571rem !important;
}

.g-pt-130 {
  padding-top: 9.28571rem !important;
}

.g-pt-150 {
  padding-top: 10.71429rem !important;
}

.g-pt-170 {
  padding-top: 12.14286rem !important;
}

.g-pt-195 {
  padding-top: 13.92857rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.g-pb-170 {
  padding-bottom: 12.14286rem !important;
}

.g-pb-180 {
  padding-bottom: 12.85714rem !important;
}

.g-pb-200 {
  padding-bottom: 14.28571rem !important;
}

.g-pb-250 {
  padding-bottom: 17.85714rem !important;
}

.g-pb-13 {
  padding-bottom: 0.92857rem !important;
}

.g-pb-16 {
  padding-bottom: 1.14286rem !important;
}

.g-pa-9 {
  padding: 0.64286rem !important;
}

.g-pa-11 {
  padding: 0.78571rem !important;
}

.g-pa-12 {
  padding: 0.85714rem !important;
}

.g-pl-30--hover:hover {
  padding-left: 2.14286rem !important;
}

/* Custom Paddings (sm)
------------------------------------*/
@media (min-width: 576px) {
  .g-px-10x--sm {
    padding-left: 10% !important;
    padding-right: 10% !important;
  }
  .g-px-0--sm {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .g-py-0--sm {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .g-py-5--sm {
    padding-top: 0.35714rem !important;
    padding-bottom: 0.35714rem !important;
  }
  .g-pa-0-10x--sm {
    padding-left: 10% !important;
    padding-right: 10% !important;
  }
  .g-pa-0-35-35--sm {
    padding: 0 2.5rem 2.5rem !important;
  }
  .g-pa-5-10--sm {
    padding: 0.35714rem 0.71429rem !important;
  }
  .g-pa-8-16--sm {
    padding: 0.57143rem 1.14286rem !important;
  }
  .g-pa-10-20--sm {
    padding: 0.71429rem 1.42857rem !important;
  }
  .g-pa-15-20-10--sm {
    padding: 1.07143rem 1.42857rem 0.71429rem !important;
  }
  .g-pa-20-20-0--sm {
    padding: 1.42857rem 1.42857rem 0 !important;
  }
  .g-pa-20-20-10--sm {
    padding: 1.42857rem 1.42857rem 0.71429rem !important;
  }
  .g-pa-20-30-0--sm {
    padding: 1.42857rem 2.14286rem 0 !important;
  }
  .g-pa-20-30--sm {
    padding: 1.42857rem 2.14286rem !important;
  }
  .g-pa-30-20--sm {
    padding: 2.14286rem 1.42857rem !important;
  }
  .g-pa-30-25-20--sm {
    padding: 2.14286rem 1.78571rem 1.42857rem !important;
  }
  .g-pa-30-30-20--sm {
    padding: 2.14286rem 2.14286rem 1.42857rem !important;
  }
  .g-pa-30-50-40--sm {
    padding: 2.14286rem 3.57143rem 2.85714rem !important;
  }
  .g-pa-30-80--sm {
    padding: 2.14286rem 5.71429rem !important;
  }
  .g-pa-40-30-30--sm {
    padding: 2.85714rem 2.14286rem 2.14286rem !important;
  }
  .g-pa-60-30--sm {
    padding: 4.28571rem 2.14286rem !important;
  }
  .g-pa-70-50-50-30--sm {
    padding: 5rem 3.57143rem 3.57143rem 2.14286rem !important;
  }
  .g-pa-80-40--sm {
    padding: 5.71429rem 2.85714rem !important;
  }
  .g-pa-85-0-40--sm {
    padding: 6.07143rem 0 2.85714rem !important;
  }
  .g-pa-100-30--sm {
    padding: 7.14286rem 2.14286rem !important;
  }
  .g-pa-170-0-150--sm {
    padding: 12.14286rem 0 10.71429rem !important;
  }
  .g-pl-300--sm {
    padding-left: 21.42857rem !important;
  }
}

/* Custom Paddings (md)
------------------------------------*/
@media (min-width: 768px) {
  .g-pa-25-30--md {
    padding: 1.78571rem 2.14286rem !important;
  }
  .g-pa-30-10--md {
    padding: 2.14286rem 0.71429rem !important;
  }
  .g-pa-30-35--md {
    padding: 2.14286rem 2.5rem !important;
  }
  .g-py-0--md {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .g-py-23--md {
    padding-top: 1.64286rem !important;
    padding-bottom: 1.64286rem !important;
  }
  .g-px-18--md {
    padding-left: 1.28571rem !important;
    padding-right: 1.28571rem !important;
  }
  .g-pt-135--md {
    padding-top: 9.64286rem !important;
  }
  .g-pt-145--md {
    padding-top: 10.35714rem !important;
  }
  .g-pt-150--md {
    padding-top: 10.71429rem !important;
  }
  .g-pt-170--md {
    padding-top: 12.14286rem !important;
  }
  .g-pt-175--md {
    padding-top: 12.5rem !important;
  }
  .g-pb-70--md {
    padding-bottom: 5rem !important;
  }
  .g-pb-170--md {
    padding-bottom: 12.14286rem !important;
  }
  .g-pb-250--md {
    padding-bottom: 17.85714rem !important;
  }
  .g-pl-300--md {
    padding-left: 21.42857rem !important;
  }
}

/* Custom Paddings (lg)
------------------------------------*/
@media (min-width: 992px) {
  .g-py-0--lg {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .g-py-100--lg {
    padding-top: 7.14286rem !important;
    padding-bottom: 7.14286rem !important;
  }
  .g-px-200--lg {
    padding-left: 14.28571rem !important;
    padding-right: 14.28571rem !important;
  }
  .g-pt-170--lg {
    padding-top: 12.14286rem !important;
  }
  .g-pb-200--lg {
    padding-bottom: 14.28571rem !important;
  }
}

/* Custom Paddings (xl)
------------------------------------*/
@media (min-width: 1200px) {
  .g-py-0--xl {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .g-px-23--xl {
    padding-left: 1.64286rem !important;
    padding-right: 1.64286rem !important;
  }
}

/*------------------------------------
  Block Hidden
------------------------------------*/
.g-hidden-xs-up {
  display: none !important;
}

@media (max-width: 575px) {
  .g-hidden-xs-down {
    display: none !important;
  }
}

@media (min-width: 576px) {
  .g-hidden-sm-up {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .g-hidden-sm-down {
    display: none !important;
  }
}

@media (min-width: 768px) {
  .g-hidden-md-up {
    display: none !important;
  }
}

@media (max-width: 991px) {
  .g-hidden-md-down {
    display: none !important;
  }
}

@media (min-width: 992px) {
  .g-hidden-lg-up {
    display: none !important;
  }
}

@media (max-width: 1199px) {
  .g-hidden-lg-down {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .g-hidden-xl-up {
    display: none !important;
  }
}

.g-hidden-xl-down {
  display: none !important;
}

/* Typography */
h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  color: #226f1c;
}

.g-theme-h-v1 {
  font-family: 'Source Sans Pro', sans-serif;
}

a:hover, a:active, a:focus {
  color: #226f1c;
}

/* Header */
@media (min-width: 768px) {
  .light-theme {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .dark-theme {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}

.light-theme .u-header__logo-img {
  height: 20px !important;
}

.dark-theme .u-header__logo-img {
  height: 20px !important;
}

/* Hamburger */
.hamburger {
  padding: 0;
}

.hamburger-box,
.hamburger-inner,
.hamburger-inner::after,
.hamburger-inner::before {
  width: 25px;
}

.light-theme .hamburger-inner, .light-theme
.hamburger-inner::after, .light-theme
.hamburger-inner::before {
  background-color: #fff;
}

.u-header__section--dark .hamburger-inner,
.u-header__section--dark .hamburger-inner::after,
.u-header__section--dark .hamburger-inner::before {
  background-color: #226f1c;
}

.hamburger:hover .u-header__section--dark .hamburger-inner, .hamburger:hover
.u-header__section--dark .hamburger-inner::after, .hamburger:hover
.u-header__section--dark .hamburger-inner::before {
  background-color: #226f1c;
}

.hamburger-box {
  height: 17px;
}

.hamburger--slider .hamburger-inner {
  top: 0;
}

.hamburger--slider .hamburger-inner:before {
  top: 8px;
}

.hamburger--slider .hamburger-inner:after {
  top: 16px;
}

.hamburger--slider.is-active .hamburger-inner:after {
  transform: translate3d(0, -16px, 0) rotate(-90deg);
}

/* Main navigation */
.light-theme .nav-item .nav-link {
  color: #fff;
}

.light-theme .nav-item .nav-link:hover,
.light-theme .nav-item.active .nav-link {
  color: #226f1c;
}

.dark-theme .nav-item .nav-link {
  color: #777;
}

.dark-theme .nav-item .nav-link:hover,
.dark-theme .nav-item.active .nav-link {
  color: #226f1c;
}

@media all and (max-width: 576px) {
  .light-theme .nav-item .nav-link {
    color: #777;
  }
}

/* Colors */
.g-theme-color-gray-dark-v1 {
  color: #adadad !important;
}

/* Background */
.g-theme-bg-gray-light-v1 {
  background-color: #f5f5f5 !important;
}

/* Border */
.g-brd-primary--hover:hover {
  border-color: #226f1c !important;
}

/* Carousel indicators */
.u-carousel-indicators-v1 span {
  background-color: #e6e6e6;
}

.u-carousel-indicators-v1 li.slick-active span {
  background-color: #226f1c;
}
